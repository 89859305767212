import { mirrorX, strokeWidth } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var PlantPot = /** @class */ (function () {
    function PlantPot() {
    }
    PlantPot.prototype.render = function (appearance) {
        var radius = appearance.size / 2;
        var height = appearance.size;
        var pot = new Path(Segments([[radius, 5]], [[radius - 10, height]]));
        mirrorX(pot);
        pot.closePath();
        pot.withGradientFill("right", Gradients.cylinder("#8b5e46".color(), 2))
            .withStroke(strokeWidth("default"), "#674837");
        var top = Shape.Rectangle(R(0, 0, radius * 2 + 10, 5 + appearance.size / 8, Pivot.TOP_CENTER));
        top.withGradientFill("right", Gradients.cylinder("#8b5e46".color(), 2))
            .withStroke(strokeWidth("default"), "#674837");
        var graphic = new Group([pot, top]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: pot.placeable(),
        };
    };
    PlantPot.properties = {
        label: "Plant pot",
        defaultAppearance: {
            size: 90
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 60, max: 140
            }
        ],
    };
    return PlantPot;
}());
export { PlantPot };
