import { Gradients } from "apparatus/gradients";
import { P, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
import { Random } from "random/random";
var Dirt = /** @class */ (function () {
    function Dirt() {
    }
    Dirt.prototype.render = function (appearance) {
        var shape = new Path(Segments([[-52, -52], [-18, 3], [-5, -15]], [[-12, -55], [-5, -14], [15, -14]], [[29, -47], [0, -10], [10, -17]], [[63, -34], [12, -13], [8, 4]], [[60, -3], [20, 0], [11, 9]], [[56, 29], [16, -5], [9, 4]], [[44, 50], [13, 4], [-6, 11]], [[9, 46], [11, 10], [-18, 15]], [[-36, 47], [9, 8], [-14, 11]], [[-64, 40], [-1, 11], [-20, 0]], [[-62, 11], [-8, 7], [-20, 0]], [[-67, -19], [-13, 9], [-9, -5]]));
        shape.closePath();
        shape.fillColor = "#8c5c4a";
        var fill = shape.clone();
        var graphic = new Group([shape, fill]);
        graphic.clipped = true;
        var r = new Random();
        for (var x = shape.bounds.left; x < shape.bounds.right; x += 40) {
            for (var y = shape.bounds.top; y < shape.bounds.bottom; y += 40) {
                var dirt = Shape.Circle(P(x, y).add(r.randomUniformVector(20)), r.randomBetween(10, 30));
                dirt.setRadialGradientFill(Gradients.fade("#774f40".color()));
                graphic.addChild(dirt);
            }
        }
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    Dirt.properties = {
        label: "Dirt",
        flippable: true,
        defaultAppearance: {
            scale: 0.5
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.25, max: 1.0, step: 0.01
            }
        ],
    };
    return Dirt;
}());
export { Dirt };
