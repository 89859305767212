var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedGradients } from "apparatus/library/common";
import { Group, Path, PointText, Shape } from "paper";
var ORANGE_CYLINDER = Gradients.cylinder("#e8a271".color());
var BLACK_CYLINDER = Gradients.cylinder("#444444".color());
var ORANGE_BOX = Gradients.box("#e8a271".color());
var BLACK_BOX = Gradients.box("#444444".color());
var Battery = /** @class */ (function () {
    function Battery() {
    }
    Battery.prototype.render = function (appearance) {
        var scale = appearance.scale;
        var strokeWidth = Math.min(5.0, 2.0 * scale);
        var _a = (function () {
            switch (appearance.type) {
                case "aa": return batteryCylinder(15, 51, strokeWidth);
                case "aaa": return batteryCylinder(11, 45, strokeWidth);
                case "c": return batteryCylinder(26, 50, strokeWidth);
                case "d": return batteryCylinder(34, 62, strokeWidth);
                case "pp3": return batteryRectangle(27, 49, strokeWidth, "terminal", appearance);
                case "4.5v": return batteryRectangle(62, 67, strokeWidth, "strip", appearance);
                case "lantern6v": return batteryRectangle(68, 115, strokeWidth, "spring", appearance);
            }
        })(), graphic = _a[0], pivot = _a[1];
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            pivot: pivot,
            hitShape: graphic.bounds.expand(10).toShape()
        };
    };
    Battery.properties = {
        label: "Battery",
        defaultAppearance: {
            scale: 1.0,
            type: "aa",
            stripLAngle: 9,
            stripRAngle: 11,
            showPlusMinus: false,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 1.0, max: 4.0, step: 0.1, unit: "%",
            },
            {
                spec: "radio", key: "type", label: "Battery Type",
                options: [
                    { value: "aa", label: "AA (1.5V)" },
                    { value: "aaa", label: "AAA (1.5V)" },
                    { value: "pp3", label: "PP3 (9V)" },
                    { value: "c", label: "C (1.5V)" },
                    { value: "d", label: "D (1.5V)" },
                    { value: "4.5v", label: "4.5V", tier: "boost" },
                    { value: "lantern6v", label: "Lantern (6V)", tier: "boost" },
                ],
                allowSelectingTierLockedOptions: true,
            },
            {
                spec: "header", header: "Terminals", icon: "plus-minus",
                visible: function (a) { return a.type === "4.5v"; }
            },
            {
                spec: "checkbox", key: "showPlusMinus", label: "Show",
                visible: function (a) { return a.type === "4.5v"; }
            },
            {
                spec: "slider", key: "stripLAngle", label: "Left",
                min: 1.0, max: 180, step: 1, unit: "°",
                visible: function (a) { return a.type === "4.5v"; }
            },
            {
                spec: "slider", key: "stripRAngle", label: "Right",
                min: 1.0, max: 180, step: 1, unit: "°",
                visible: function (a) { return a.type === "4.5v"; }
            },
        ],
        isBoostRequired: function (a) { return a.type === "lantern6v" || a.type === "4.5v"; }
    };
    return Battery;
}());
export { Battery };
function batteryCylinder(width, height, strokeWidth) {
    var orangeBitHeight = height * 0.3;
    var up = Shape.Rectangle(R(0, 0, width, orangeBitHeight, Pivot.TOP_CENTER), 2.0);
    var below = Shape.Rectangle(R(0, orangeBitHeight - 4, width, height - orangeBitHeight + 4, Pivot.TOP_CENTER), 2.0);
    var tip = Shape.Rectangle(R(0, 2, 6, 4, Pivot.BOTTOM_CENTER), 1);
    var outline = up.toPath(false).unite(below.toPath(false)).unite(tip.toPath(false));
    up.withGradientFill("right", ORANGE_CYLINDER);
    up.position.y += 0.5;
    below.withGradientFill("right", BLACK_CYLINDER);
    tip.withGradientFill("right", SharedGradients.cylinderMetal);
    outline.withStroke(strokeWidth, "#333333");
    return [new Group([tip, below, up, outline])];
}
function batteryRectangle(width, height, strokeWidth, top, a) {
    var orangeBitHeight = width * 0.32;
    var extrasPre = [];
    var extrasPost = [];
    var up = Shape.Rectangle(R(0, 0, width, orangeBitHeight, Pivot.TOP_CENTER), 2.0);
    var below = Shape.Rectangle(R(0, orangeBitHeight - 4, width, height - orangeBitHeight + 4, Pivot.TOP_CENTER), 2.0);
    var outline = up.toPath(false)
        .unite(below.toPath(false));
    if (top == "terminal") {
        var tip = Shape.Rectangle(R(-7, 2, 5, 4, Pivot.BOTTOM_CENTER), 0);
        var tip2 = Shape.Rectangle(R(-7, -1, 7, 2, Pivot.BOTTOM_CENTER), 0);
        var tip3 = Shape.Rectangle(R(8, 2, 6, 4, Pivot.BOTTOM_CENTER), 1);
        outline = outline.unite(tip.toPath(false))
            .unite(tip2.toPath(false))
            .unite(tip3.toPath(false));
        tip.withGradientFill("right", SharedGradients.cylinderMetal);
        tip2.withGradientFill("right", SharedGradients.cylinderMetal);
        tip3.withGradientFill("right", SharedGradients.cylinderMetal);
        extrasPre.push(tip, tip2, tip3);
    }
    up.withGradientFill("right", ORANGE_BOX);
    up.position.y += 0.5;
    below.withGradientFill("right", BLACK_BOX);
    var pivot;
    if (top == "strip") {
        var leftStrip = new Path.Line(P(0, 0), P(width / 2 - 1, 0));
        leftStrip.pivot = P(0, 0);
        leftStrip.position = P(-width / 2 + 3, -0.5);
        leftStrip.rotate(-a.stripLAngle);
        leftStrip.strokeCap = "round";
        leftStrip.withStroke(strokeWidth * 1.5, "#333333");
        var rightStrip = new Path.Line(P(0, 0), P(width / 2 - 9, 0));
        rightStrip.pivot = P(0, 0);
        rightStrip.position = P(width / 2 - 3, -0.5);
        rightStrip.rotate(180 + a.stripRAngle);
        rightStrip.strokeCap = "round";
        rightStrip.withStroke(strokeWidth * 1.5, "#333333");
        extrasPost.push(leftStrip, rightStrip);
        if (a.showPlusMinus) {
            var plusMinus = new PointText({
                point: P(0, 14),
                content: "-           +",
                fillColor: "black",
                fontFamily: "Rubik",
                fontWeight: "Bold",
                fontSize: 14,
                justification: "center"
            });
            plusMinus.opacity = 0.4;
            extrasPost.push(plusMinus);
        }
        // Override pivot as well.
        pivot = outline.bounds.center;
    }
    else if (top == "spring") {
        var spring = new Path(Segments([[0, 0], [0, 2.5], [0, -2.5]], [[8, -3], [0, 2.5], [0, -2.5]], [[0, -6], [0, 2.5], [0, -2.5]], [[8, -9], [0, 2.5], [0, -2.5]], [[0, -12], [0, 2.5], [0, -2.5]]));
        spring.withStroke(strokeWidth, "#333333");
        var spring2 = spring.clone();
        spring.position.x = -4;
        spring2.position.x = width / 2 - 11;
        extrasPost.push(spring, spring2);
    }
    outline.withStroke(strokeWidth, "#333333");
    return [new Group(__spreadArray(__spreadArray(__spreadArray([], extrasPre), [below, up, outline]), extrasPost)), pivot];
}
