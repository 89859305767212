var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P, Segments } from "apparatus/library/common";
import { BasicShapes } from "apparatus/library/shapes/common";
import { Path } from "paper";
var Tick = /** @class */ (function () {
    function Tick() {
        this.drivers = [
            // Length.
            {
                type: "rectangle",
                minX: 40, maxX: 250,
                minY: 40, maxY: 250,
                toAppearance: function (f, p) { return f({
                    length: (p.x + p.y) / 2 * Math.SQRT2,
                }); },
                fromAppearance: function (a) {
                    var x = a.length / Math.SQRT2;
                    return P(x, -x);
                },
                scale: 1.0,
            },
            {
                type: "rectangle",
                minX: 0, maxX: 0,
                minY: 5, maxY: 50,
                toAppearance: function (f, p) { return f({
                    thickness: p.y / Math.SQRT2 * 2,
                }); },
                fromAppearance: function (a) { return P(0, a.thickness / 2 * Math.SQRT2); },
                scale: 1.0,
            }
        ];
    }
    Tick.prototype.render = function (appearance) {
        var thickness = appearance.thickness, length = appearance.length;
        var semiLength = length * 0.5;
        var halfThick = thickness * 0.5;
        var graphic = new Path(Segments([[-halfThick, -halfThick]], [[-halfThick, -length]], [[halfThick, -length]], [[halfThick, halfThick]], [[-semiLength, halfThick]], [[-semiLength, -halfThick]]));
        graphic.closePath();
        graphic.rotate(45, P(0, 0));
        var shape = graphic.clone();
        BasicShapes.applyStyle(graphic, appearance);
        return {
            graphic: graphic,
            hitShape: shape,
        };
    };
    Tick.properties = {
        label: "Tick",
        defaultAppearance: __assign({ thickness: 40, length: 120 }, BasicShapes.defaultAppearance),
        appearanceSpecs: __spreadArray([], BasicShapes.specs),
    };
    return Tick;
}());
export { Tick };
