var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { g } from "analytics";
import { commandProcessor } from "command/command";
import { Align } from "editing/arrange/align";
import { Distribute } from "editing/arrange/distribute";
import { getDescendantIDs } from "editing/grouping/operations";
import { createSingleLayerLiquidPropsSections } from "panel/liquid_panel_ui";
import { createOrderingButtons } from "panel/ordering_ui";
export function sectionsForMultipleItems(selected, app) {
    var elements = [];
    if ((selected.standaloneObjectsCount + selected.groupsCount) > 1) {
        elements.push.apply(elements, createAlignAndDistributeIcons(selected, app));
    }
    // Reordering should happen on items only, on the uncategorised items
    // so that items in groups, can be moved to.
    if ((selected.standaloneObjectsCount + selected.groupsCount) > 0) {
        elements.push(createOrderingButtons(selected.items.map(function (i) { return i.id; })));
    }
    if (selected.shouldAllowGrouping || selected.shouldAllowUngrouping) {
        if (selected.shouldAllowGrouping) {
            elements.push({
                spec: "button",
                key: "groupObjects",
                label: "Grouping",
                buttonLabel: "Group objects",
                icon: "object-group",
                onClick: function () {
                    var command = selected.createGroupObjectsCommand();
                    if (command) {
                        g("Grouping", "Group");
                        commandProcessor.execute(command);
                    }
                }
            });
        }
        if (selected.shouldAllowUngrouping) {
            elements.push({
                spec: "button",
                key: "ungroupObjects",
                label: "Grouping",
                buttonLabel: "Ungroup objects",
                icon: "object-ungroup",
                onClick: function () {
                    var command = selected.createUngroupObjectsCommand();
                    if (command) {
                        g("Grouping", "Ungroup");
                        commandProcessor.execute(command);
                    }
                }
            });
        }
    }
    return {
        title: function () { return selected.displayString; },
        icon: "mouse-pointer",
        tabs: __spreadArray([
            {
                type: "tools",
                sections: [
                    {
                        header: "Tools",
                        icon: "wrench",
                        hideHeaderIfFirst: true,
                        elements: elements,
                    },
                ],
            }
        ], (selected.isItemsWithLiquidOnly ? [
            {
                type: "liquid",
                colorDot: selected.getConsistentItemsLiquidColor(),
                sections: createSingleLayerLiquidPropsSections(selected.items, app, selected.isItemsSupportingMeniscus)
            }
        ] : []))
    };
}
function createAlignAndDistributeIcons(selected, app) {
    // Align is only supported for items and arrows and groups.
    // If an arrow is inside a group, the arrow will be ignored for purposes of alignment.
    var itemComponents = selected.standaloneItems.map(function (i) { return app.getObjectComponent(i.id); });
    var arrowComponents = selected.standaloneArrows.map(function (a) { return app.getObjectComponent(a.id); });
    var groupAlignables = selected.rootGroups.map(function (g) { return new AlignableGroup(g, app); });
    var alignables = __spreadArray(__spreadArray(__spreadArray([], itemComponents), arrowComponents), groupAlignables);
    return [{
            spec: "horizontal-icon-buttons",
            label: "Align",
            key: "HorizontalAlign",
            buttons: [
                {
                    buttonLabel: "Align left edges", icon: "k,align-h-left",
                    onClick: function () {
                        var command = Align.align(alignables, "left");
                        if (command) {
                            g("AlignItems", "left");
                            commandProcessor.execute(command);
                        }
                    }
                },
                {
                    buttonLabel: "Align horizontal center", icon: "k,align-h-center",
                    onClick: function () {
                        var command = Align.alignCenters(alignables, "x");
                        if (command) {
                            g("AlignItems", "horizontal-centers");
                            commandProcessor.execute(command);
                        }
                    }
                },
                {
                    buttonLabel: "Align right edges", icon: "k,align-h-right",
                    onClick: function () {
                        var command = Align.align(alignables, "right");
                        if (command) {
                            g("AlignItems", "right");
                            commandProcessor.execute(command);
                        }
                    }
                }
            ]
        }, {
            spec: "horizontal-icon-buttons",
            label: " ",
            key: "VerticalAlign",
            buttons: [
                {
                    buttonLabel: "Align top edges", icon: "k,align-v-top",
                    onClick: function () {
                        var command = Align.align(alignables, "top");
                        if (command) {
                            g("AlignItems", "top");
                            commandProcessor.execute(command);
                        }
                    }
                },
                {
                    buttonLabel: "Align vertical centers", icon: "k,align-v-center",
                    onClick: function () {
                        var command = Align.alignCenters(alignables, "y");
                        if (command) {
                            g("AlignItems", "vertical-centers");
                            commandProcessor.execute(command);
                        }
                    }
                },
                {
                    buttonLabel: "Align bottom edges", icon: "k,align-v-bottom",
                    onClick: function () {
                        var command = Align.align(alignables, "bottom");
                        if (command) {
                            g("AlignItems", "bottom");
                            commandProcessor.execute(command);
                        }
                    }
                }
            ]
        }, {
            spec: "horizontal-icon-buttons",
            label: "Distribute",
            key: "Distribute",
            buttons: [
                {
                    buttonLabel: "Distribute centers horizontally", icon: "k,distribute-h-center",
                    onClick: function () {
                        var command = Distribute.distributeCenters(alignables, "x");
                        if (command) {
                            g("AlignItems", "distribute-horizontal-centers");
                            commandProcessor.execute(command);
                        }
                    }
                },
                {
                    buttonLabel: "Distribute horizontal gaps", icon: "k,distribute-h-gap",
                    onClick: function () {
                        var command = Distribute.distributeGaps(alignables, "x");
                        if (command) {
                            g("AlignItems", "distribute-horizontal-gaps");
                            commandProcessor.execute(command);
                        }
                    }
                },
                {
                    buttonLabel: "Distribute centers vertically", icon: "k,distribute-v-center",
                    onClick: function () {
                        var command = Distribute.distributeCenters(alignables, "y");
                        if (command) {
                            g("AlignItems", "distribute-vertical-centers");
                            commandProcessor.execute(command);
                        }
                    }
                },
                {
                    buttonLabel: "Distribute vertical gaps", icon: "k,distribute-v-gap",
                    onClick: function () {
                        var command = Distribute.distributeGaps(alignables, "y");
                        if (command) {
                            g("AlignItems", "distribute-vertical-gaps");
                            commandProcessor.execute(command);
                        }
                    }
                },
            ]
        }];
}
/** Provides Alignable implementation for a group. */
var AlignableGroup = /** @class */ (function () {
    function AlignableGroup(group, app) {
        this.group = group;
        this.app = app;
    }
    Object.defineProperty(AlignableGroup.prototype, "alignmentBounds", {
        get: function () {
            var _this = this;
            // Retrieve objects. Only select apparatus items.
            var childIds = getDescendantIDs(this.group).filter(function (i) { return i.type === "apparatus"; });
            var allBounds = childIds.map(function (id) { return _this.app.getObjectComponent(id); }).map(function (c) { return c.bounds; });
            // Merge all bounds.
            var bounds = allBounds[0];
            for (var _i = 0, allBounds_1 = allBounds; _i < allBounds_1.length; _i++) {
                var bound = allBounds_1[_i];
                bounds.united(bound);
            }
            return bounds;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AlignableGroup.prototype, "id", {
        get: function () {
            return this.group.id;
        },
        enumerable: false,
        configurable: true
    });
    return AlignableGroup;
}());
