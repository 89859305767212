var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { breakPath, setDefaultStyle, smoothCornersIndividual } from "apparatus/draw";
import { Segments } from "apparatus/library/common";
import { Path } from "paper";
import { staticImplements } from "types";
var EurekaCan = /** @class */ (function () {
    function EurekaCan() {
    }
    EurekaCan.prototype.render = function (appearance) {
        var width = appearance.width, height = appearance.height;
        var spoutLength = height / 2;
        var shape = new Path(Segments([[0, 0]], [[0, height]], [[width, height]], [[width, 30]], [[width + spoutLength - 8, 20 + spoutLength]], [[width + spoutLength, 15 + spoutLength]], [[width, 8]], [[width, 0]]));
        smoothCornersIndividual(shape, { 1: 7, 2: 7 });
        var liquidMask = shape.clone();
        if (!appearance.fillSpout) {
            liquidMask.removeSegments(6, 8);
        }
        var graphic = breakPath(shape, 7);
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.bounds.toShape(),
            snapping: [
                shape.placeable(),
            ],
            liquidDrainOpening: appearance.fillSpout
                ? { start: liquidMask.segments[6].point, end: liquidMask.segments[7].point }
                : undefined
        };
    };
    EurekaCan.properties = {
        label: "Eureka can",
        canContainLiquids: true,
        meniscus: true,
        flippable: true,
        drainable: true,
        defaultAppearance: {
            width: 90,
            height: 140,
            fillSpout: false,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Width",
                min: 70, max: 110
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 80, max: 180
            },
            { spec: "checkbox", key: "fillSpout", label: "Fill spout" }
        ],
    };
    EurekaCan = __decorate([
        staticImplements()
    ], EurekaCan);
    return EurekaCan;
}());
export { EurekaCan };
