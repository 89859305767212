var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// UI representation for a single diagram file.
import { Menu, MenuItem } from "@szhsin/react-menu";
import { formatDistance } from "date-fns";
import { CloudFiles, openDiagram } from "file/api";
import { getDateFnsLocale } from "i18n_utils";
import * as React from 'react';
import { useTranslation } from "react-i18next";
import appStore from "store/store";
export var DiagramFile = function (props) {
    var t = useTranslation().t;
    function openFile() {
        openDiagram(props.diagram, /*isTemplate*/ false);
        props.onOpen(props.diagram.id);
    }
    // Don't put onClick on the overall container because Action menu does not capture clicks!
    return React.createElement("div", { className: "file-grid-item col-12 col-sm-4 col-md-3 col-lg-2 mb-2 pr-2", onClick: openFile },
        React.createElement("div", { className: "thumbnail mb-1" },
            React.createElement(ActionMenu, __assign({}, props)),
            React.createElement("img", { src: props.diagram.thumbnail, className: "img-thumbnail" })),
        props.diagram.title,
        React.createElement("br", null),
        React.createElement("small", { className: "text-muted" },
            React.createElement("i", { className: "fa fa-clock fa-fw" }),
            " ",
            t("ui.ago", { time: formatDistance(props.diagram.modified, props.now, { locale: getDateFnsLocale() }) })));
};
var ActionMenu = function (props) {
    var t = useTranslation().t;
    function deleteFile() {
        var loggedIn = appStore.loggedInState();
        if (!loggedIn)
            return;
        var diagram = props.diagram;
        if (!confirm(t("cloud.delete_confirm", { name: diagram.title }))) {
            return;
        }
        CloudFiles.remove(loggedIn, diagram, props.onDelete);
    }
    function renameFile() {
        var loggedIn = appStore.loggedInState();
        if (!loggedIn)
            return;
        var title = prompt(t('cloud.rename_prompt'), props.diagram.title);
        if (title === null)
            return;
        CloudFiles.rename(loggedIn, props.diagram, title, props.onRename);
    }
    // Stop propagation is required otherwise click events will bubble up.
    return React.createElement("div", { className: "text-white overlayButton", onClick: function (e) { return e.stopPropagation(); } },
        React.createElement(Menu, { menuButton: React.createElement("button", { type: "button", className: "btn btn-sm btn-outline-secondary" },
                React.createElement("i", { className: "fa fa-ellipsis-h" })) },
            React.createElement(MenuItem, { onClick: renameFile },
                React.createElement("i", { className: "fas fa-pencil-alt" }),
                " ",
                t('Rename')),
            React.createElement(MenuItem, { className: "text-danger", onClick: deleteFile },
                React.createElement("i", { className: "fas fa-trash" }),
                " ",
                t('Delete'))));
};
