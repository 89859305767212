var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { setDefaultStyle, smoothCornersIndividual } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var LiquidReagentBottle = /** @class */ (function () {
    function LiquidReagentBottle() {
    }
    LiquidReagentBottle.prototype.render = function (appearance) {
        var width = appearance.width, height = appearance.height;
        var neckRadius = 6 + width * 0.05;
        var narrowingY = height + (width / 2 - neckRadius) * 0.4;
        var shape = new Path(Segments([[neckRadius, -narrowingY - 15]], [[neckRadius, -narrowingY]], [[width / 2, -height]], [[width / 2, 0]])).mirrorX();
        smoothCornersIndividual(shape, { 1: 3, 2: 3, 3: 6, 4: 6, 5: 3, 6: 3 });
        setDefaultStyle(shape);
        var liquidMask = shape.clone();
        var capColor = appearance.capColor.color();
        var graphic = new Group([shape]);
        var cap1 = new Path.Rectangle(R(0, 0, neckRadius * 2 + 6, 18, Pivot.BOTTOM_CENTER), 2.0)
            .withGradientFill("right", Gradients.cylinder(capColor.ladd(-4), 5));
        var cap2 = new Path(Segments([[neckRadius - 2, -14]], [[2, -60]])).mirrorX().close().withGradientFill("right", Gradients.cylinder(capColor, 8));
        cap1.position.y -= narrowingY;
        cap2.position.y -= narrowingY;
        graphic.addChildren([cap2, cap1]);
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.rectHitShape(),
            pivot: shape.bounds.center,
            watermark: P(neckRadius, -height),
            snapping: shape.placeable(),
        };
    };
    LiquidReagentBottle.properties = {
        label: "Plastic Reagent Bottle (Liquid)",
        canContainLiquids: true,
        defaultAppearance: {
            width: 50,
            height: 100,
            capColor: "#c40915"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Diameter",
                min: 40, max: 90, unit: "mm",
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 80, max: 150, unit: "mm",
            },
            {
                spec: "color-wells", key: "capColor", label: "Cap colour",
                colors: [
                    { value: "#c40915", label: "Red" },
                    { value: "#333333", label: "Black" },
                ]
            }
        ],
    };
    LiquidReagentBottle = __decorate([
        staticImplements()
    ], LiquidReagentBottle);
    return LiquidReagentBottle;
}());
export { LiquidReagentBottle };
