import { strokeWidth } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { hsl } from "color";
import { Group, Path, Shape } from "paper";
var LIGHT_OUTLINE = hsl(0, 0, 40).string();
var LIGHT_CYLINDER = Gradients.cylinder(hsl(252, 3, 80));
var LIGHT_GRADIENT = Gradients.simple(hsl(252, 3, 80));
var DARK_CYLINDER = Gradients.cylinder(hsl(252, 2, 30));
var METAL = Gradients.cylinder(hsl(252, 8, 70), 10);
var Microscope = /** @class */ (function () {
    function Microscope() {
    }
    Microscope.prototype.render = function (appearance) {
        var body = new Path(Segments([[6, -30]], [[56, -25], , [19, 15]], [[113, 100], [6, -60], [-7, 80]], [[27, 206], [43, -12]], [[30, 142]], [[84, 91], [-2, 61], [2, -54]], [[9, 36], [37, 0]]));
        body.closePath();
        body.withGradientFill("right", LIGHT_GRADIENT)
            .withStroke(strokeWidth("default"), LIGHT_OUTLINE);
        var leg = new Path(Segments([[57, 145], [-20, 3], [20, -3]], [[103, 231], [-30, -53]], [[47, 239]]));
        leg.closePath();
        leg.withGradientFill("right", LIGHT_GRADIENT)
            .withStroke(strokeWidth("default"), LIGHT_OUTLINE);
        var base = Shape.Rectangle(R(-80, 225, 200, 20), 5)
            .withGradientFill("right", Gradients.simple("#cccccc".color(), 30))
            .withStroke(strokeWidth("default"), LIGHT_OUTLINE);
        var ocularCase = Shape.Rectangle(R(0, 0, 35, 90, Pivot.CENTER), 2.0)
            .withGradientFill("right", LIGHT_CYLINDER)
            .withStroke(strokeWidth("default"), LIGHT_OUTLINE);
        var ocularViewtube = Shape.Rectangle(R(0, -10, 25, 150, Pivot.CENTER), 2.0)
            .withGradientFill("right", DARK_CYLINDER);
        var ocularTurret = Shape.Rectangle(R(-10, 60, 54, 12, Pivot.TOP_CENTER), 2.0)
            .withGradientFill("right", DARK_CYLINDER);
        ocularTurret.rotate(10);
        var lens = Shape.Rectangle(R(2, 65, 18, 25, Pivot.TOP_CENTER))
            .withGradientFill("right", METAL);
        lens.rotate(-5);
        var lens2 = Shape.Rectangle(R(-22, 60, 18, 25, Pivot.TOP_CENTER))
            .withGradientFill("right", METAL);
        lens2.rotate(20, P(-22, 60));
        var ocular = new Group([ocularViewtube, lens, lens2, ocularTurret, ocularCase]);
        ocular.rotate(5, P(0, 0));
        var stage = Shape.Rectangle(R(-10, 132, 120, 12, Pivot.TOP_CENTER), 2.0)
            .withGradientFill("right", DARK_CYLINDER);
        // .withStroke(strokeWidth("default"), DARK_OUTLINE)
        var lightInlet = Shape.Rectangle(R(-10, 143, 30, 20, Pivot.TOP_CENTER))
            .withGradientFill("right", DARK_CYLINDER);
        // .withStroke(strokeWidth("default"), DARK_OUTLINE)
        var lightHolder = Shape.Rectangle(R(-10, 190, 40, 8))
            .withGradientFill("right", DARK_CYLINDER);
        // .withStroke(strokeWidth("default"), DARK_OUTLINE)
        var light = Shape.Rectangle(R(-15, 180, 20, 20))
            .withGradientFill("right", LIGHT_CYLINDER)
            .withStroke(strokeWidth("thinner"), LIGHT_OUTLINE);
        light.rotate(-5);
        var graphic = new Group([light, lightHolder, body, leg, base, ocular, stage, lightInlet]);
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: [
                base.placeable(),
                stage.topEdgeAsPlaceableSurface(),
            ]
        };
    };
    Microscope.properties = {
        label: "Microscope",
        flippable: true,
        defaultAppearance: {
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.5, max: 1.25, step: 0.05, unit: "%",
            }
        ],
    };
    return Microscope;
}());
export { Microscope };
