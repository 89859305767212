var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P, Segments, SharedColors, SharedGradients } from "apparatus/library/common";
import { Ray } from "apparatus/library/physics/optical/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var MIN_FOCAL_LENGTH = 60;
var MAX_FOCAL_LENGTH = 500;
var Lens = /** @class */ (function () {
    function Lens() {
        this.drivers = [
            {
                type: "rectangle",
                minX: MIN_FOCAL_LENGTH, maxX: MAX_FOCAL_LENGTH,
                minY: 0, maxY: 0,
                fromAppearance: function (a) { return P(a.focalLength, 0); },
                toAppearance: function (f, p) { return f({ focalLength: p.x }); },
                scale: 1.0,
                visible: function (a) { return isConvex(a.type) && a.showRay; }
            },
            {
                type: "rectangle",
                maxX: -MIN_FOCAL_LENGTH, minX: -MAX_FOCAL_LENGTH,
                minY: 0, maxY: 0,
                fromAppearance: function (a) { return P(-a.focalLength, 0); },
                toAppearance: function (f, p) { return f({ focalLength: -p.x }); },
                scale: 1.0,
                visible: function (a) { return !isConvex(a.type) && a.showRay; }
            },
        ];
    }
    Lens.prototype.render = function (appearance) {
        var focalLength = appearance.focalLength, type = appearance.type, rays = appearance.rays, dottedLine = appearance.dottedLine;
        var lens = createLens(appearance.type).mirrorY().close()
            .withGradientFill("right-down", SharedGradients.glass)
            .withStroke("thin", SharedColors.glassEdge);
        var shooter = new RayShooter(focalLength, lens, appearance);
        if (appearance.showRay) {
            if (type === "biconvex") {
                if (rays === "five") {
                    [40, 20, 0, -20, -40].map(function (y) { return shooter.shootConvex(-100, y); });
                }
                else if (rays === "two") {
                    [40, -40].map(function (y) { return shooter.shootConvex(-100, y); });
                }
            }
            else if (type === "biconcave") {
                if (rays === "five") {
                    [-36, -18, 0, 18, 36].map(function (y) { return shooter.shootConcave(-100, y, 200, dottedLine); });
                }
                else if (rays === "two") {
                    [-36, 36].map(function (y) { return shooter.shootConcave(-100, y, 200, dottedLine); });
                }
            }
        }
        var graphic = new Group([shooter.rays, lens]);
        return {
            graphic: graphic,
            hitShape: lens.bounds.expand(10).toShape(),
            pivot: P(0, 0),
        };
    };
    Lens.properties = {
        label: "Lens",
        flippable: true,
        defaultAppearance: __assign(__assign(__assign({ type: "biconcave", focalLength: 200 }, Ray.defaultAppearanceRayOn), Ray.defaultAppearanceArrow), { rays: "five", dottedLine: false }),
        appearanceSpecs: __spreadArray(__spreadArray([
            {
                spec: "radio", key: "type", label: "Type",
                options: [
                    { value: "biconvex", label: "Biconvex" },
                    { value: "biconcave", label: "Biconcave" },
                ],
            },
            {
                spec: "slider", key: "focalLength", label: "Focal length",
                min: MIN_FOCAL_LENGTH, max: MAX_FOCAL_LENGTH, unit: "mm"
            }
        ], Ray.specs), [
            {
                spec: "radio", key: "rays", label: "Ray type",
                options: [
                    { value: "two", label: "2 rays" },
                    { value: "five", label: "5 rays" },
                ],
                visible: function (a) { return a.showRay; },
            },
            {
                spec: "checkbox-group", label: "Show",
                checkboxes: [
                    { key: "dottedLine", label: "Virtual rays", visible: function (a) { return a.showRay && !isConvex(a.type); } },
                    { key: "showRayArrow", label: "Arrows", visible: function (a) { return a.showRay; } },
                ]
            }
        ]),
    };
    Lens = __decorate([
        staticImplements()
    ], Lens);
    return Lens;
}());
export { Lens };
var RayShooter = /** @class */ (function () {
    function RayShooter(focalLength, path, appearance) {
        this.focalLength = focalLength;
        this.path = path;
        this.appearance = appearance;
        this.rays = new Group();
    }
    RayShooter.prototype.shootConvex = function (x, y) {
        var path1 = new Path([[x, y], [0, y]]);
        var ints1 = this.path.getIntersections(path1);
        var path2 = new Path([[0, y], [this.focalLength, 0]]);
        var ints2 = this.path.getIntersections(path2);
        if (ints1.length == 1 && ints2.length == 1) {
            var ray = new Path([[x, y], ints1[0].point, ints2[0].point, [this.focalLength, 0]]);
            Ray.applyStyle(ray, this.appearance);
            this.rays.addChild(ray);
            this.maybeAddArrows(ray, [0.5, null, 0.3]);
        }
        path1.remove();
        path2.remove();
    };
    RayShooter.prototype.shootConcave = function (x, y, distance, dottedLine) {
        var path1 = new Path([[x, y], [0, y]]);
        var ints1 = this.path.getIntersections(path1);
        var path2 = new Path([[0, y], [this.focalLength, y * 2]]);
        var ints2 = this.path.getIntersections(path2);
        if (ints1.length == 1 && ints2.length == 1) {
            var ray = new Path([[x, y], ints1[0].point, ints2[0].point]);
            var emergent = P(this.focalLength, y);
            if (dottedLine) {
                var ray2 = new Path([[-this.focalLength, 0], ints2[0].point]);
                ray2.dashArray = [this.appearance.rayThickness, this.appearance.rayThickness * 2];
                Ray.applyStyle(ray2, this.appearance);
                this.rays.addChild(ray2);
            }
            emergent.length = distance;
            ray.lineBy(emergent);
            Ray.applyStyle(ray, this.appearance);
            this.rays.addChild(ray);
            this.maybeAddArrows(ray, [0.5, null, 1.0]);
        }
        path1.remove();
        path2.remove();
    };
    RayShooter.prototype.maybeAddArrows = function (ray, positions) {
        Ray.maybeAddArrows(this.appearance, ray, positions, this.rays);
    };
    return RayShooter;
}());
function createLens(type) {
    switch (type) {
        case "biconvex":
            return new Path(Segments([[-5, -50], [-15, 25]], [[5, -50], , [15, 25]]));
        case "biconcave":
            return new Path(Segments([[-15, -50], [12, 25]], [[15, -50], , [-12, 25]]));
    }
}
function isConvex(type) {
    switch (type) {
        case "biconcave":
            return false;
        case "biconvex":
            return true;
    }
}
