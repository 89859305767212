var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Segments, SharedColors } from "apparatus/library/common";
import { DEFAULT_SWATCHES } from "components/colorpicker";
import { Path } from "paper";
var PaperClip = /** @class */ (function () {
    function PaperClip() {
    }
    PaperClip.prototype.render = function (appearance) {
        var graphic = new Path(Segments([[-20, 49]], [[-20, -68], , [0, -22]], [[19, -68], [0, -22]], [[19, 105], , [0, 30]], [[-40, 105], [0, 30]], [[-40, -105], , [0, -45]], [[38, -105], [0, -45]], [[38, 48]])).withStroke(appearance.scale * 2, appearance.color);
        graphic.strokeCap = "round";
        graphic.scale(appearance.scale * 0.125);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(5).toShape()
        };
    };
    PaperClip.properties = {
        label: "Paper Clip",
        flippable: true,
        defaultAppearance: {
            scale: 1.0,
            color: SharedColors.stroke,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Zoom",
                min: 1, max: 5, step: 0.01, unit: "%",
            },
            {
                spec: "color", key: "color", label: "Colour", tier: "boost",
                demoSwatches: { "Default": SharedColors.stroke, "Metal": "#999999" },
                swatches: __assign({ "Default": SharedColors.stroke, "Metal": "#999999" }, DEFAULT_SWATCHES)
            }
        ],
    };
    return PaperClip;
}());
export { PaperClip };
