import { setDefaultStyle } from "apparatus/draw";
import { Segments } from "apparatus/library/common";
import { Path } from "paper";
import { addEdgeLipsReversed, mirrorX } from "../../draw";
var PearFlask = /** @class */ (function () {
    function PearFlask() {
    }
    PearFlask.prototype.render = function (appearance) {
        var s = appearance.size / 100;
        var shape = new Path(Segments([[10, -20]], [[10, 0], , [15 * s, 5 * s]], [[30 * s, 30 * s], [0, -15 * s], [0, 20 * s]], [[0, 90 * s], [10 * s, 0]]));
        mirrorX(shape);
        addEdgeLipsReversed(shape);
        var liquidMask = shape.clone();
        var graphic = shape;
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.bounds.toShape()
        };
    };
    PearFlask.properties = {
        label: "Pear-shaped flask",
        canContainLiquids: true,
        defaultAppearance: {
            size: 120,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 80, max: 180,
            }
        ],
    };
    return PearFlask;
}());
export { PearFlask };
