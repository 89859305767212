import { P, Pivot, R, Segments, SharedColors, SharedGradients } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
import { mirrorX, setDefaultStyleWithStroke } from "../../draw";
export var Tap;
(function (Tap) {
    Tap.defaultAppearance = {
        tapOpening: 1.0,
        tapFacing: "front",
    };
    function appearanceSpecFor(key, label) {
        if (label === void 0) { label = "Tap position"; }
        return { spec: "slider", key: key, label: label, min: 0.0, max: 1.0, step: 0.25, pipsStep: 1 };
    }
    Tap.appearanceSpecFor = appearanceSpecFor;
    Tap.appearanceSpec = appearanceSpecFor("tapOpening");
    Tap.appearanceSpecsWithFacing = [
        Tap.appearanceSpec,
        {
            spec: "toggle-buttons", label: "Facing", size: "text", key: "tapFacing", options: [
                { value: "left", label: "Left" },
                { value: "front", label: "Front" },
                { value: "right", label: "Right" },
            ]
        }
    ];
    Tap.isClosed = function (opening) { return opening <= 0.5; };
    Tap.create = createTap;
})(Tap || (Tap = {}));
/**
 * Create a tap/stop cock commonly seen on burettes,
 * adddition funnels etc.
 */
export function createTap(appearance, reverseShading) {
    if (reverseShading === void 0) { reverseShading = false; }
    var opening = appearance.tapOpening, facing = appearance.tapFacing;
    var body = new Path(Segments([[9.5, -5]], [[9.5, 14]], [[6.5, 24]]));
    mirrorX(body);
    body.closePath();
    setDefaultStyleWithStroke("thin", body);
    body.setGradientFill(reverseShading ? "left" : "right", [
        ["#c6c6c6", 0.2],
        ["#a0a0a0", 0.3],
        ["#868686", 0.7],
        ["#686868", 0.8],
    ]);
    var graphic = new Group();
    if (facing === "front") {
        var knob = Shape.Circle(P(0, 0), 6);
        // knob.fillColor = "#888888"
        knob.setRadialGradientFill([["#dddddd", 0.4], ["#aaaaaa", 0.7]]);
        var handle1 = Shape.Rectangle(R(4, 0, 18, 6, Pivot.CENTER_LEFT), 2.0);
        setDefaultStyleWithStroke("thinner", knob);
        // handle1.strokeColor = "#950309"
        handle1.setGradientFill("right", [["#950309", 0.2], ["#ba0206", 0.6]]);
        // handle1.fillColor = "#ba0206"
        var handle2 = handle1.clone();
        handle2.rotate(180, P(0, 0));
        var knobAndHandles = new Group([handle1, handle2, knob]);
        knobAndHandles.position.y += 8;
        knobAndHandles.rotate(-90 * opening);
        graphic.addChildren([body, knobAndHandles]);
    }
    else {
        var knobGradientDirection = facing === "right" ? "down" : "up";
        var handleGradientDirection = facing === "right" ? "left" : "right";
        var knob = Shape.Rectangle(R(9.5 - 2, 0, 18, 12, Pivot.CENTER_LEFT), 2.0)
            .withStroke("thinner", SharedColors.stroke)
            .withGradientFill(knobGradientDirection, SharedGradients.cylinderMetal);
        // Apparent handle length. Calculate this from the supposed angle.
        var angleInRadians = (90 * opening).toRadians();
        var handleLength = 18 * Math.sin(angleInRadians);
        var yStart = 4 * Math.sin(angleInRadians);
        var x = 9.5 + 8;
        var bottomHandle = Shape.Rectangle(R(x, yStart, 6, handleLength, Pivot.TOP_CENTER), 2.0)
            .withGradientFill(handleGradientDirection, [["#a5030a", 0.2], ["#c20206", 0.6]]);
        var handleCap = Shape.Circle(P(x, yStart + 16 * Math.sin(angleInRadians)), 3.0)
            .withFill("#8b0205");
        handleCap.scale(1.0, Math.cos(angleInRadians));
        var topHandle = Shape.Rectangle(R(x, -yStart, 6, handleLength, Pivot.BOTTOM_CENTER), 2.0)
            .withGradientFill(handleGradientDirection, [["#a5030a", 0.2], ["#c20206", 0.6]]);
        var knobAndHandles = new Group();
        if (opening > 0.8) {
            knobAndHandles.addChildren([knob, topHandle, bottomHandle, handleCap]);
        }
        else {
            knobAndHandles.addChildren([topHandle, knob, bottomHandle, handleCap]);
        }
        // Flip if on the left.
        if (facing === "left") {
            knobAndHandles.rotate(180, P(0, 0));
        }
        knobAndHandles.position.y += 8;
        graphic.addChildren([knobAndHandles, body]);
    }
    graphic.pivot = P(0, 0);
    return graphic;
}
