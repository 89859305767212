import { strokeWidth } from "apparatus/draw";
import { clamp } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
var Tree = /** @class */ (function () {
    function Tree() {
    }
    Tree.prototype.render = function (appearance) {
        var graphic = SVG.tree();
        graphic.scale(appearance.scale);
        var strWidth = clamp(strokeWidth("thin"), strokeWidth("default") * appearance.scale, strokeWidth("thicker"));
        // Trunk outline.
        graphic.children[2].strokeWidth = strWidth;
        // Crown outline.
        graphic.children[6].strokeWidth = strWidth;
        graphic.children[6].miterLimit = 10;
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            watermark: graphic.bounds.center,
        };
    };
    Tree.properties = {
        label: "Tree",
        flippable: true,
        defaultAppearance: {
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.25, max: 4.0, step: 0.01, unit: "%",
            }
        ],
    };
    return Tree;
}());
export { Tree };
