var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { strokeWidth } from "apparatus/draw";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, Symbol } from "paper";
import { Random } from "random/random";
import { staticImplements } from "types";
var IceBath = /** @class */ (function () {
    function IceBath() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 37.5, maxX: 250,
                minY: 100, maxY: 300,
                fromAppearance: function (a) { return P(a.width / 2, a.height); },
                toAppearance: function (f, p) { return f({ width: p.x * 2, height: p.y }); },
                scale: P(1.0, 2.0),
            }
        ];
    }
    IceBath.prototype.render = function (appearance) {
        var width = appearance.width, height = appearance.height, size = appearance.size;
        var spacing = size * appearance.spacing;
        var maxLayers = Math.floor(height / spacing + 0.5);
        var layers = Math.min(maxLayers, appearance.layers);
        var r = new Random();
        var container = new Path(Segments([[-width / 2, 0]], [[-width / 2, height]], [[width / 2, height]], [[width / 2, 0]])).withStroke("default");
        var iceGroup = new Group([]);
        var mask = new Path.Rectangle(R(0, height, width, height + spacing, Pivot.BOTTOM_CENTER));
        iceGroup.addChild(mask);
        iceGroup.clipped = true;
        var iceGraphic = new Path.Rectangle(R(0, 0, size, size, Pivot.CENTER), 4.0).withDefaultStyle()
            .withStroke("thin", "#bbf0f2")
            .setRadialGradientFill([["#f2ffffee", 0.2], ["#e6fcfcaa", 1]]);
        var ice = new Symbol(iceGraphic, true);
        var boundx = width / 2 - size / 2 - spacing * 0.1;
        for (var i = 0; i < layers; i++) {
            var iterBound = void 0;
            if (i % 2 == 0) {
                iterBound = Math.floor(boundx / spacing);
            }
            else {
                iterBound = Math.floor((boundx / spacing) - 0.5) + 0.5;
            }
            for (var j = -iterBound; j <= iterBound; j++) {
                var point = P((j * spacing), height - size * 0.6 - i * spacing)
                    .add(r.randomUniformVector(spacing * 0.17));
                var newIce = ice.place(point);
                newIce.rotate(r.randomBetween(-30, 30));
                iceGroup.addChild(newIce);
            }
        }
        var graphic = new Group([iceGroup, container]);
        var strokeWidthAdjustment = P(0, -strokeWidth("default") / 2);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            pivot: container.bounds.center,
            snapping: [
                graphic.placeable(),
                {
                    type: "placeable_surface",
                    start: container.bounds.bottomLeft.add(strokeWidthAdjustment),
                    end: container.bounds.bottomRight.add(strokeWidthAdjustment)
                }
            ]
        };
    };
    IceBath.properties = {
        label: "Ice Bath",
        defaultAppearance: {
            width: 300,
            height: 160,
            spacing: 1.18,
            layers: 4,
            size: 20,
            randomization: 0.18
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Width",
                min: 75, max: 500
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 100, max: 300
            },
            { spec: "header", header: "Ice", icon: "dice-d6" },
            {
                spec: "slider", key: "layers", label: "Layers",
                min: 1, max: 8,
            },
            {
                spec: "slider", key: "size", label: "Size",
                min: 15, max: 30, unit: "mm"
            },
            {
                spec: "slider", key: "spacing", label: "Spacing",
                min: 0.9, max: 1.5, step: 0.01
            },
        ],
    };
    IceBath = __decorate([
        staticImplements()
    ], IceBath);
    return IceBath;
}());
export { IceBath };
