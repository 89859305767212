var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { P, R, SharedColors } from "apparatus/library/common";
import { Interactable } from "editing/interaction";
import { clog } from "log";
import { Group, Point, PointText, Shape } from "paper";
var DEBUG_LOGS = false;
var DEBUG_CURSOR = false;
/** Spacing between lines, as a multiple of font size. */
var LINE_SPACING = 1.25;
/**
 * Class for rendering advanced text.
 *
 * Underneath, it opaquely uses Paper.js's PointText and Groups.
 * For performance reasons, these may be reused. The internal
 * paper structure should not be messed with.
 */
var AdvancedText = /** @class */ (function () {
    function AdvancedText(container) {
        this.container = new Group();
        this.localAlignmentBounds = R(0, 0, 0, 0);
        this.pointTextRecycler = new PointTextRecycler();
        this.container = container !== null && container !== void 0 ? container : new Group();
        this.container.applyMatrix = false;
        this.container.selectedColor = SharedColors.selectionGreen;
    }
    /** Constructor that allows the container to be interactable. */
    AdvancedText.withInteraction = function (interaction) {
        var interactableGroupClass = Interactable(Group, interaction);
        return new AdvancedText(new interactableGroupClass());
    };
    AdvancedText.prototype.setText = function (text, style) {
        var _a;
        // First recycle all current items.
        (_a = this.pointTextRecycler).recycle.apply(_a, this.container.children);
        // Empty text leads to rendering bugs. If the text has no segments, render
        // a space.
        if (text.segments.length == 0) {
            text.segments.push({ type: "normal", text: " " });
        }
        if (text.segments.length == 1 && text.segments[0].type == "normal" && text.segments[0].text == "") {
            text.segments[0].text = " ";
        }
        // Convert style to internal style.
        var internalStyle = __assign(__assign({}, style), { justification: "left" });
        // Set up tracking of lines so that we can do text align properly at the end.
        var lines = [];
        var maxLineWidth = 0;
        var cursor = P(0, 0);
        for (var _i = 0, _b = text.segments; _i < _b.length; _i++) {
            var segment = _b[_i];
            if (DEBUG_CURSOR) {
                var point = Shape.Circle(cursor, 5.0);
                point.fillColor = "#ff0000";
                this.container.addChild(point);
            }
            if (segment.type == "newline") {
                var lineWidth_1 = cursor.x;
                lines.push([this.container.children.length, lineWidth_1]);
                maxLineWidth = Math.max(maxLineWidth, lineWidth_1);
            }
            var items = renderSegment(segment, cursor, this.pointTextRecycler, internalStyle);
            if (items.length > 0) {
                // Check length as newlines don't return anything.
                this.container.addChildren(items);
            }
        }
        // Track the last line.
        var lineWidth = cursor.x;
        lines.push([this.container.children.length, lineWidth]);
        maxLineWidth = Math.max(maxLineWidth, lineWidth);
        // Iterate through children and align each line to match alignment.
        if (style.textAlign == "left" || lines.length == 1) {
            // Nothing to do, by default, we left-align. Also if the text
            // consists of 1 line, nothing to do either.
        }
        else {
            var i = 0;
            for (var _c = 0, lines_1 = lines; _c < lines_1.length; _c++) {
                var _d = lines_1[_c], maxIndex = _d[0], lineWidth_2 = _d[1];
                // If this is the longest line, nothing to do.
                if (lineWidth_2 == maxLineWidth) {
                    i = maxIndex;
                    continue;
                }
                // Compute gap.
                var gap = maxLineWidth - lineWidth_2;
                if (style.textAlign == "center")
                    gap /= 2.0;
                // Shift all elements on the line by the gap.
                while (i < maxIndex) {
                    this.container.children[i].position.x += gap;
                    i++;
                }
            }
        }
        // Pre-compute alignment bounds.
        // For line height, don't quite take the full font-size.
        var lineHeight = style.fontSize * 0.7;
        var lineSpacing = LINE_SPACING * style.fontSize;
        this.localAlignmentBounds = R(0, -lineHeight, maxLineWidth, lineHeight + (lines.length - 1) * lineSpacing);
    };
    AdvancedText.prototype.setPosition = function (x, y) {
        this.container.position = new Point(x, y);
    };
    Object.defineProperty(AdvancedText.prototype, "bounds", {
        get: function () {
            return this.container.bounds;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AdvancedText.prototype, "alignmentBounds", {
        get: function () {
            // Return rectangle for text, ignoring subscripts etc.
            var origin = this.container.localToGlobal(P(0, 0));
            var alignmentBounds = this.localAlignmentBounds.clone();
            alignmentBounds.point.x += origin.x;
            alignmentBounds.point.y += origin.y;
            return alignmentBounds;
        },
        enumerable: false,
        configurable: true
    });
    AdvancedText.prototype.remove = function () {
        this.pointTextRecycler.dispose();
        this.container.remove();
    };
    return AdvancedText;
}());
export { AdvancedText };
/**
 * Recycles PointText instances. To put stale instances into the recycle,
 * use recycle(). To request a recycled item, use request(). If the recycler
 * has run out of recycled instances, a new instance is returned.
 *
 * Note recycled instances are returned without modification and so all
 * relevant properties must be set of them during use.
 */
var PointTextRecycler = /** @class */ (function () {
    function PointTextRecycler() {
        this.recycled = [];
    }
    PointTextRecycler.prototype.recycle = function () {
        var _a;
        var items = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            items[_i] = arguments[_i];
        }
        for (var _b = 0, items_1 = items; _b < items_1.length; _b++) {
            var item = items_1[_b];
            item.remove();
        }
        (_a = this.recycled).push.apply(_a, items);
    };
    PointTextRecycler.prototype.request = function (position) {
        // REMEMBER: Set position only once, before text is set!
        var item = this.recycled.pop();
        if (item) {
            item.pivot = P(0, 0);
            item.position = position;
            return item;
        }
        var newInstance = new PointText(position);
        // By setting the pivot we ensure the set
        // position does not move around.
        newInstance.pivot = position;
        newInstance.selectedColor = SharedColors.selectionGreen;
        return newInstance;
    };
    PointTextRecycler.prototype.dispose = function () {
        for (var _i = 0, _a = this.recycled; _i < _a.length; _i++) {
            var recycled = _a[_i];
            recycled.remove();
        }
        this.recycled.length = 0;
    };
    return PointTextRecycler;
}());
/**
 * Renders a segment, returning items.
 * @param cursor Position to render at. Must be updated after rendering.
 */
function renderSegment(segment, cursor, pointTextRecycler, style) {
    var fontSize = style.fontSize;
    switch (segment.type) {
        case "normal":
        case "subscript":
        case "superscript":
            var pointText = pointTextRecycler.request(cursor);
            if (DEBUG_LOGS)
                clog("before: ", pointText.position);
            pointText.content = segment.text;
            pointText.style = style;
            pointText.fontSize = fontSize;
            if (DEBUG_LOGS)
                clog("after: ", pointText.position);
            if (segment.type == "subscript") {
                formatSubscript(pointText, style);
            }
            else if (segment.type == "superscript") {
                formatSuperscript(pointText, style);
            }
            cursor.x += pointText.bounds.width;
            return [pointText];
        case "subsuperscript":
            var superText = pointTextRecycler.request(cursor);
            superText.content = segment.super;
            var subText = pointTextRecycler.request(cursor);
            subText.content = segment.sub;
            formatSuperscript(superText, style);
            formatSubscript(subText, style);
            cursor.x += Math.max(superText.bounds.width, subText.bounds.width);
            return [subText, superText];
        case "newline":
            cursor.x = 0;
            cursor.y += style.fontSize * LINE_SPACING;
            return [];
    }
}
function formatSubscript(text, style) {
    text.style = style;
    text.fontSize = style.fontSize * 0.75;
    text.position.y += style.fontSize * 0.27;
}
function formatSuperscript(text, style) {
    text.style = style;
    text.fontSize = style.fontSize * 0.75;
    text.position.y -= style.fontSize * 0.42;
}
