import { commandProcessor } from "command/command";
import { UpdateRenderAreaBox } from "editing/render_area";
import { Size } from "paper";
import { setThemeStrokeThickness } from "store/actions";
import { appStore } from "store/store";
import { Consts } from "vars";
var strokeThicknessThemeButtons = [
    { name: "Thin", value: "s", icon: "k,stroke-1" },
    { name: "Medium", value: "m", icon: "k,stroke-2" },
    { name: "Strong", value: "l", icon: "k,stroke-3" },
];
var exportAreaButtons = [
    { name: "Automatic", value: "all", icon: "r,expand-arrows-alt", hint: "Entire diagram will be exported" },
    { name: "Frame", value: "area", icon: "r,expand-wide", hint: "Only the area selected by the red frame will be exported" },
];
export function sectionsForDiagram(theme, app) {
    var themeButtonIndex = 0;
    for (var i = 0; i < strokeThicknessThemeButtons.length; i++) {
        if (strokeThicknessThemeButtons[i].value == theme.strokeThickness) {
            themeButtonIndex = i;
            break;
        }
    }
    return {
        icon: "drafting-compass",
        title: "Diagram settings",
        tabs: [
            {
                type: "property",
                sections: [
                    {
                        header: "Theme",
                        icon: "paint-brush",
                        elements: [
                            {
                                spec: "toggle-horizontal-buttons",
                                key: "strokeThicknessTheme",
                                label: "Stroke Thickness",
                                size: "icon",
                                highlightIndex: themeButtonIndex,
                                buttons: strokeThicknessThemeButtons.map(function (s) { return ({
                                    icon: s.icon,
                                    buttonLabel: s.name,
                                    onClick: function () {
                                        appStore.dispatch(setThemeStrokeThickness(s.value));
                                    }
                                }); })
                            }
                        ]
                    },
                    {
                        header: "Export",
                        icon: "camera-alt",
                        elements: [
                            {
                                spec: "toggle-horizontal-buttons",
                                key: "exportAreaOptions",
                                label: "Export area",
                                size: "small",
                                highlightIndex: appStore.getState().renderArea ? 1 : 0,
                                buttons: exportAreaButtons.map(function (s) { return ({
                                    icon: s.icon,
                                    buttonLabel: s.name,
                                    hint: s.hint,
                                    onClick: function () {
                                        if (s.value === "all") {
                                            commandProcessor.execute(new UpdateRenderAreaBox(undefined));
                                        }
                                        else {
                                            // Enable the render area.
                                            var projectBounds = app.getProjectBounds();
                                            if (projectBounds.isEmpty()) {
                                                projectBounds.set(paper.project.view.center, new Size(0, 0));
                                                projectBounds = projectBounds.expand(300, 200);
                                            }
                                            else {
                                                projectBounds = projectBounds.expand(5.0);
                                            }
                                            commandProcessor.execute(new UpdateRenderAreaBox(projectBounds));
                                        }
                                    }
                                }); })
                            }
                        ]
                    },
                    {
                        header: "Tools",
                        icon: "wrench",
                        elements: [
                            {
                                spec: "vertical-buttons",
                                key: "tools-buttons",
                                label: "Action",
                                buttons: [
                                    {
                                        buttonLabel: "Move items to center", icon: "arrows-to-circle",
                                        onClick: function () {
                                            var projectBounds = app.getProjectBounds();
                                            paper.project.view.center = projectBounds.center.add([Consts.sidebarWidth * -0.5, 0]);
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                ]
            }
        ]
    };
}
