import { smoothCornersIndividual } from "apparatus/draw";
import { P, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path, PointText, Shape } from "paper";
/**
 * https://www.youtube.com/watch?v=JzNe-Bk2shQ
 */
var Spectrophotometer = /** @class */ (function () {
    function Spectrophotometer() {
    }
    Spectrophotometer.prototype.render = function (appearance) {
        var outerColor = SharedColors.deviceGrey.color();
        var outer = new Path(Segments([[0, 0]], // 0
        [[110, 0]], // 1 - Start well
        [[110, 100]], [[185, 100]], [[185, 0]], // 4 - End well
        [[200, 0]], [[200, 110]], [[0, 110]])).close().withStroke("default")
            .withGradientFill("down", [
            [outerColor.hex(), 0.62],
            [outerColor.ladd(-10).hex(), 0.68],
            [outerColor.ladd(-20).hex(), 1.0]
        ]);
        smoothCornersIndividual(outer, { 0: 8, 2: 5, 3: 5, 5: 8, 6: 8, 7: 8 });
        var screenCase = Shape.Rectangle(R(10, 10, 90, 50), 5.0)
            .withStroke("default", SharedColors.devicePanel.stroke)
            .withFill(SharedColors.devicePanel.fill);
        var screen = Shape.Rectangle(R(15, 15, 40, 27), 3.0)
            .withStroke(2.0, "white").withFill(SharedColors.lcdScreen);
        var dpad = this.dpad();
        dpad.position.x = 77;
        dpad.position.y = 30;
        dpad.rotate(45);
        var textAbs = new PointText({
            point: P(50, 27),
            content: appearance.absorbance,
            fillColor: "black",
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            fontSize: 10,
            justification: "right",
        });
        var textWavelength = new PointText({
            point: P(50, 37),
            content: appearance.wavelength + " nm",
            fillColor: "black",
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            fontSize: 8,
            justification: "right",
        });
        var graphic = new Group([outer, screenCase, screen, dpad, textAbs, textWavelength]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            watermark: screen.bounds.bottomRight.add([5, 5]),
            snapping: [
                outer.placeable(),
                { type: "placeable_surface", start: P(110, 100), end: P(185, 100) }
            ]
        };
    };
    Spectrophotometer.prototype.dpad = function () {
        var button = Shape.Rectangle(R(0, 0, 9, 9), 2.0).withFill("white");
        var button2 = button.clone();
        var button3 = button.clone();
        var button4 = button.clone();
        button2.position.x += 12;
        button3.position.y += 12;
        button4.position.x += 12;
        button4.position.y += 12;
        return new Group([button, button2, button3, button4]);
    };
    Spectrophotometer.properties = {
        label: "Spectrophotometer",
        defaultAppearance: {
            wavelength: "540",
            absorbance: "0.042",
        },
        appearanceSpecs: [
            { spec: "text", key: "absorbance", label: "Absorbance", maxLength: 5 },
            { spec: "text", key: "wavelength", label: "Wavelength", maxLength: 4, numbersOnly: true }
        ],
    };
    return Spectrophotometer;
}());
export { Spectrophotometer };
