var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { SVG } from "apparatus/svg";
import { staticImplements } from "types";
var LabCoat = /** @class */ (function () {
    function LabCoat() {
    }
    LabCoat.prototype.render = function (appearance) {
        var graphic = SVG.labcoat();
        graphic.scale(1.0 * appearance.scale);
        return { graphic: graphic, hitShape: graphic.bounds.toShape() };
    };
    LabCoat.properties = {
        label: "Lab coat",
        defaultAppearance: {
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.3, max: 3, step: 0.1
            }
        ],
    };
    LabCoat = __decorate([
        staticImplements()
    ], LabCoat);
    return LabCoat;
}());
export { LabCoat };
