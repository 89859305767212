import { UpdateArrowProperties } from "arrows/command";
import { commandProcessor, Sequence } from "command/command";
import { MoveIndividual } from "editing/move";
import { RotateItems } from "editing/rotation/rotate_command";
import * as paper from "paper";
var RotationHandler = /** @class */ (function () {
    function RotationHandler() {
        /** Point object, helps with calculating angles. */
        this.vector = new paper.Point(0, 0);
        /** Offset to apply before snapping. */
        this.snapOffset = 0;
        this.pivot = new paper.Point(0, 0);
    }
    RotationHandler.prototype.onStart = function () {
        if (this.isNothingSelected())
            return;
        // Set up pivot and reset last angle.
        this.pivot.set(this.selection.pivot.x, this.selection.pivot.y);
        if (this.selectedApparatus.length == 1) {
            // If there is one selected apparatus, set up the snap offset.
            this.snapOffset = this.selectedApparatus[0].currentRotation;
            return "rotation";
        }
        else if (this.selectedArrows.length == 1) {
            this.snapOffset = 0;
            return "delta";
        }
        else {
            this.snapOffset = 0;
            return "delta";
        }
    };
    RotationHandler.prototype.onRotate = function (x, y, snaps) {
        var _this = this;
        if (this.isNothingSelected())
            return 0;
        var angle = this.getAngle(x, y);
        if (snaps > 0) {
            angle = this.snap(angle + this.snapOffset, snaps) - this.snapOffset;
        }
        this.selectedApparatus.map(function (apparatus) { return apparatus.setRotationDelta(angle, _this.pivot); });
        this.selectedArrows.map(function (arrow) { return arrow.setRotationDelta(angle, _this.pivot); });
        // If there is only one apparatus, report its overall rotation,
        // otherwise report the delta
        if (this.selectedApparatus.length == 1) {
            return (this.selectedApparatus[0].currentRotation + angle).anglify();
        }
        else {
            return angle;
        }
    };
    RotationHandler.prototype.onEnd = function () {
        if (this.isNothingSelected())
            return;
        // Commit these changes. This relies on original values still
        // being set on the apparatus components as props.
        var values = this.selectedApparatus.map(function (apparatus) { return ({
            id: apparatus.id,
            rotation: apparatus.visibleRotation.anglify(),
            originalRotation: apparatus.currentRotation,
        }); });
        var moves = new MoveIndividual(this.selectedApparatus.map(function (a) { return ({ id: a.id, delta: a.visiblePosition.subtract(a.currentPosition) }); }));
        var rotateArrowCommand = new UpdateArrowProperties(this.selectedArrows.map(function (a) { return ({
            id: a.id,
            value: { start: a.state.start, end: a.state.end },
            previousValue: { start: a.props.start, end: a.props.end },
        }); }));
        var sequence = new Sequence([new RotateItems(values), moves, rotateArrowCommand]);
        commandProcessor.execute(sequence);
    };
    RotationHandler.prototype.isNothingSelected = function () {
        return this.selectedApparatus.length + this.selectedArrows.length == 0;
    };
    /** Returns angle from given direction vector, in degrees. */
    RotationHandler.prototype.getAngle = function (x, y) {
        // Compute the delta angle, with up-pointing vector as 0 degrees.
        // We can use paper's Point, angle is measured against x-axis so
        // we just need to swap the coordinates
        this.vector.set(-y, x);
        return this.vector.angle;
    };
    RotationHandler.prototype.snap = function (angle, snaps) {
        return Math.round(angle / 360 * snaps) / snaps * 360;
    };
    return RotationHandler;
}());
export { RotationHandler };
