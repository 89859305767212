import { P } from "apparatus/library/common";
import "ext/color";
import { Shape } from "paper";
var TLCSpot = /** @class */ (function () {
    function TLCSpot() {
    }
    TLCSpot.prototype.render = function (appearance) {
        var baseColor = appearance.color;
        // Create a circle of diameter <size> * 4, i.e radius of <size> * 2
        var graphic = Shape.Circle(P(0, 0), appearance.size * 2);
        graphic.scale(1.0, appearance.thin);
        if (appearance.type == "solid") {
            graphic.fillColor = baseColor;
        }
        else {
            var gradient = [
                [baseColor, 1.0 - appearance.blurAmount],
                [baseColor + "00", 1.0]
            ];
            graphic.setRadialGradientFill(gradient);
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(5).toShape(),
            snapping: { type: "tlc_spot", at: P(0, 0) }
        };
    };
    TLCSpot.properties = {
        label: "Chromatography Spot",
        defaultAppearance: {
            type: "solid",
            blurAmount: 0.5,
            color: "#47aa31",
            size: 6,
            thin: 1.0,
        },
        appearanceSpecs: [
            {
                spec: "color", key: "color", label: "Colour",
            },
            {
                spec: "slider", key: "size", label: "Size",
                min: 2, max: 10, unit: "mm"
            },
            {
                spec: "slider", key: "thin", label: "Thin/Thick",
                min: 0.4, max: 4.0, step: 0.1, unit: "%",
            },
            {
                spec: "radio", key: "type", label: "Presentation",
                options: [
                    { value: "solid", label: "Solid" },
                    { value: "blur", label: "Blurred" },
                ],
                // allowSelectingTierLockedOptions: true,
            },
            {
                spec: "slider", key: "blurAmount", label: "Blur amount", tier: "boost",
                min: 0.0, max: 1.0, step: 0.05, unit: "%", visible: function (a) { return a.type == "blur"; }
            },
        ],
    };
    return TLCSpot;
}());
export { TLCSpot };
