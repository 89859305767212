var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
/**
 * https://gbr.grandado.com/products/25ml-pycnometer3-3-borosilicate-glass-density-bottle-single?gclid=CjwKCAiAvaGRBhBlEiwAiY-yMD4G1i-cFym_ElepYHOerT4snQilwECHNPV0WeUpralX2zaIQ29L7RoCPekQAvD_BwE&variant=UHJvZHVjdFZhcmlhbnQ6NjE5MTE4Mg
 * https://www.youtube.com/watch?v=5w3IKnovlng
 * https://chemix.kampsite.co/suggestions/f2f607e5-b99f-4753-b815-070fd7f611d9
 */
var Pycnometer = /** @class */ (function () {
    function Pycnometer() {
    }
    Pycnometer.prototype.render = function (appearance) {
        var scaleCenter = P(0, 0);
        var scale = appearance.size / 100;
        var stopperHeight = 40 + scale * 20;
        // Diameter reduction.
        var dr = 0;
        if (appearance.size < 90) {
            dr = -1;
        }
        var bottle = new Path(Segments([
            P(9 + dr, -18), , [3, 0]
        ], 
        /* If updating this shape, also update liquidMaskPart1. */
        [
            P(8 + dr, 0),
            P(0, -17),
            P(17, 31).multiply(scale)
        ], [
            P(19, 63).scale(scale, scaleCenter),
            P(20, -12).multiply(scale),
            P(-20, 0).multiply(scale)
        ])).mirrorX().close().withStroke("default");
        var fold = new Path(Segments([[4, 0]], [[-4, 0]])).withStroke("thinner", SharedColors.glassFold);
        var graphic = new Group([bottle, fold]);
        var liquidMask;
        if (appearance.stopper) {
            var stopper = new Path(Segments([[6 + dr, -stopperHeight]], [[6 + dr, -22]], [[9 + dr, -18], , [3, 0]], [[8 + dr, 0], [0, -17]])).mirrorX().close().withStroke("default");
            var innerTube = new Path.Rectangle(R(0, -stopperHeight, 4, stopperHeight, Pivot.TOP_CENTER))
                .withStroke("thinner", SharedColors.glassFold);
            graphic.addChildren([stopper, innerTube]);
            var liquidMaskPart1 = new Path(Segments([
                [8 + dr, 0],
                /* no incoming */ ,
                P(17, 31).multiply(scale)
            ], [
                P(19, 63).scale(scale, scaleCenter),
                P(20, -12).multiply(scale),
                P(-20, 0).multiply(scale)
            ])).mirrorX().close();
            var liquidMaskPart2 = innerTube.clone();
            liquidMask = liquidMaskPart1.unite(liquidMaskPart2);
            liquidMaskPart1.remove();
            liquidMaskPart2.remove();
        }
        else {
            liquidMask = bottle.clone();
        }
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.rectHitShape(),
            pivot: bottle.bounds.center,
            snapping: [
                graphic.placeable()
            ]
        };
    };
    Pycnometer.properties = {
        label: "Pycnometer",
        canContainLiquids: true,
        defaultAppearance: {
            stopper: true,
            size: 100,
        },
        appearanceSpecs: [
            { spec: "checkbox", key: "stopper", label: "Stopper", subLabel: "Show" },
            {
                spec: "slider", key: "size", label: "Size",
                min: 55, max: 120, step: 1
            }
        ],
    };
    Pycnometer = __decorate([
        staticImplements()
    ], Pycnometer);
    return Pycnometer;
}());
export { Pycnometer };
