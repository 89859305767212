import { strokeWidth } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var HIGHLIGHT = [
    ["#fde9ae60", 0.0],
    ["#fde9ae00", 1.0],
];
var Potato = /** @class */ (function () {
    function Potato() {
    }
    Potato.prototype.render = function (appearance) {
        var graphic = renderFns[appearance.type](appearance);
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.strokeBounds.toShape(),
        };
    };
    Potato.properties = {
        label: "Potato",
        defaultAppearance: {
            type: "whole",
            scale: 1.0,
            length: 70,
            diameter: 15,
        },
        flippable: true,
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.5, max: 2.0, step: 0.01, unit: "%",
            },
            {
                spec: "radio", key: "type", label: "Type",
                options: [
                    { value: "whole", label: "Whole potato" },
                    { value: "chip", label: "Chip" },
                    { value: "cylinder", label: "Cylinder (Core)" },
                ],
            },
            {
                spec: "slider", key: "length", label: "Length",
                min: 20, max: 100, unit: "mm",
                visible: function (a) { return a.type === "chip" || a.type === "cylinder"; }
            },
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 10, max: 30, unit: "mm"
            }
        ],
    };
    return Potato;
}());
export { Potato };
var renderFns = {
    "whole": renderWhole,
    "chip": renderChip,
    "cylinder": renderCore,
};
function renderWhole(_) {
    var shape = new Path(Segments([[-30, -30], [-21, 15], [27, -20]], [[53, -40], [-18, -13], [26, 19]], [[53, 17], [10, -4], [-43, 18]], [[-40, 32], [45, 22], [-22, -11]]));
    shape.closePath();
    shape.setGradientFill("right-down", [
        ["#e2b15d", 0.0],
        ["#d5a14d", 0.4],
        ["#a56314", 0.9],
    ]);
    shape.withStroke(strokeWidth("default"), "#775025");
    var highlight1 = Shape.Circle(P(-25, -12), 30);
    highlight1.setRadialGradientFill(HIGHLIGHT);
    highlight1.scale(1.0, 0.5);
    highlight1.rotate(-30);
    var highlight2 = Shape.Circle(P(30, -26), 20);
    highlight2.setRadialGradientFill(HIGHLIGHT);
    highlight2.scale(1.0, 0.8);
    highlight2.rotate(40);
    return new Group([shape, highlight1, highlight2]);
}
function renderChip(a) {
    var diameter = a.diameter, length = a.length;
    return new Group([
        // Light shade.
        Shape.Rectangle(R(0, 0, 0.625 * diameter, length)).withFill("#edd090"),
        // Dark shade.
        Shape.Rectangle(R(0.624 * diameter, 0, 0.376 * diameter, length)).withFill("#d9b462"),
        // Outline.
        Shape.Rectangle(R(0, 0, diameter, length), 2.0).withStroke(strokeWidth("thin"), "#9a7744"),
    ]);
}
function renderCore(a) {
    var diameter = a.diameter, length = a.length;
    var top = new Path.Ellipse(R(0, 0, diameter, diameter / 2, Pivot.CENTER));
    var bottom = top.clone();
    bottom.position.y += length;
    var body = new Path.Rectangle(R(0, 0, diameter, length, Pivot.TOP_CENTER));
    top.fillColor = "#f7e1b0";
    var body3d = body.unite(bottom);
    var outline = body3d.unite(top);
    body3d.withGradientFill("right", Gradients.simple("#edd090".color()));
    bottom.remove();
    body.remove();
    outline.withStroke(strokeWidth("default"), "#9a7744");
    return new Group([
        body3d, top, outline
    ]);
}
