var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { setDefaultStyle } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R } from "apparatus/library/common";
import { TestTube } from "apparatus/library/testTube";
import { CompoundPath, Group, Shape } from "paper";
var WOOD_STROKE = "#382b1e";
var TestTubeRack = /** @class */ (function () {
    function TestTubeRack() {
    }
    TestTubeRack.convertToLatest = function (a) {
        if (!a.type) {
            a.type = "simple";
        }
        return __assign(__assign({}, TestTubeRack.properties.defaultAppearance), a);
    };
    TestTubeRack.prototype.render = function (appearance) {
        return (function () {
            switch (appearance.type) {
                case "simple": return renderSimple(appearance);
                case "wooden": return renderWooden(appearance);
            }
        })();
    };
    TestTubeRack.properties = {
        label: "Test Tube Rack",
        defaultAppearance: {
            slots: 4,
            gap: TestTube.properties.defaultAppearance.width,
            height: 100,
            type: "wooden"
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "type", label: "Type",
                options: [
                    { value: "wooden", label: "Wooden" },
                    { value: "simple", label: "Simple" },
                ],
            },
            {
                spec: "slider", key: "slots", label: "Number of slots",
                min: 4, max: 8, step: 1
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 60, max: 200,
            },
            {
                spec: "slider", key: "gap", label: "Gap size",
                min: 8, max: 30, visible: function (a) { return a.type === "simple"; }
            }
        ],
    };
    return TestTubeRack;
}());
export { TestTubeRack };
function renderSimple(appearance) {
    var height = appearance.height;
    var slots = appearance.slots;
    var step = 40;
    var gap = appearance.gap;
    var wall = step - gap;
    var sideWall = 20;
    var graphic = new CompoundPath({});
    graphic.moveTo(P(0, 0));
    graphic.lineBy(P(0, -height));
    graphic.lineBy(P(sideWall, 0));
    for (var i = 0; i < slots; i++) {
        graphic.moveBy(P(gap, 0));
        graphic.lineBy(P(wall, 0));
    }
    graphic.lastSegment.remove();
    graphic.lineBy(P(sideWall, 0));
    graphic.lineBy(P(0, height)),
        graphic.lineTo(P(0, 0));
    setDefaultStyle(graphic);
    graphic.strokeCap = "butt";
    return {
        graphic: graphic,
        hitShape: graphic.bounds.toShape(),
        snapping: graphic.placeable(),
    };
}
function renderWooden(appearance) {
    var height = appearance.height;
    var width = 30 + (appearance.slots - 1) * 50;
    var horizontalPartThickness = 8;
    var verticalPartThickness = 12;
    // Calculate the offset needed for the gradient,
    // to give the horizontal bars have a subtle shadow
    var gradientOffset = 18 / width;
    var top = Shape.Rectangle(R(0, 20, width, horizontalPartThickness, Pivot.TOP_LEFT), 3.0)
        .withStroke("default", WOOD_STROKE)
        .withGradientFill("right", Gradients.reflected(["#704e2f", gradientOffset], ["#916943", gradientOffset + 0.03]));
    var bottom = top.clone();
    bottom.position.y = height - horizontalPartThickness;
    var left = Shape.Rectangle(R(0, 0, verticalPartThickness, height, Pivot.TOP_LEFT), 3.0)
        .withStroke("default", WOOD_STROKE)
        .withGradientFill("right", [
        ["#ce9158", 0.2],
        ["#ad7847", 0.21],
        ["#704e2f", 1.0]
    ]);
    var right = left.clone();
    right.position.x += width - verticalPartThickness;
    var graphic = new Group([top, bottom, left, right]);
    return {
        graphic: graphic,
        hitShape: graphic.bounds.toShape(),
        snapping: graphic.placeable()
    };
}
