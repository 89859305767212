import { breakPath, breakPathClosed, glassFold, setDefaultStyle } from "apparatus/draw";
import { Pivot, R, Segments } from "apparatus/library/common";
import { Path } from "paper";
var SoxhletExtractor = /** @class */ (function () {
    function SoxhletExtractor() {
    }
    SoxhletExtractor.prototype.render = function (appearance) {
        var topInlet = new Path(Segments([[-11, -30]], [[11, -30]], [[9, 20]], [[-9, 20]]));
        topInlet.closePath();
        var body = new Path.Rectangle(R(0, -10, 40, 151, Pivot.TOP_CENTER), 15);
        var outerArm = outerArmPath();
        outerArm.closePath();
        var innerArmHalf = armFirstHalfPath();
        innerArmHalf.closePath();
        var joined = body.unite(outerArm).unite(topInlet).unite(innerArmHalf);
        var graphic = breakPathClosed(joined, 5, 14, 28);
        setDefaultStyle(graphic);
        // Insert, at bottom, the rest of the arm. Since the arm
        // overlaps itself, add a small white shape in between.
        var whiteOverlapSheet = armFirstHalfPath();
        whiteOverlapSheet.closePath();
        whiteOverlapSheet.fillColor = "#ffffff80";
        graphic.insertChild(0, whiteOverlapSheet);
        var secondArmHalf = breakPath(armSecondHalfPath(), 6);
        setDefaultStyle(secondArmHalf);
        graphic.insertChild(0, secondArmHalf);
        // Add wrinkles.
        graphic.addChild(glassFold(0, -8));
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    SoxhletExtractor.properties = {
        label: "Soxhlet Extractor",
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return SoxhletExtractor;
}());
export { SoxhletExtractor };
function outerArmPath() {
    return new Path(Segments([[0, 0], , [10, -10]], [[40, 5], , [7, 0]], [[50, 15]], [[50, 180], , [0, 20]], [[12, 205]], [[12, 215], , [2, 0]], [[8, 240]], // Bottom.
    [[-8, 240]], [[-12, 215], [-2, 0]], [[-12, 140], , [5, 3]], [[12, 140], [-5, 3]], [[15, 158], [0, -4], [0, 4]], [[12, 190], [0, -20], [0, 10]], [[42, 180], [0, 10]], [[42, 20]], [[35, 12], [7, 0]], [[0, 12]]));
}
// function fullArm(): Path {
//     return new Path(Segments(
//         [[15,125]],
//         [[25,136],[-5,5],[5,-5]],
//         [[20, 120],[0,5]],
//         [[20, 55],,[0, -13]],
//         [[36, 55],[0, -13]],
//         [[36, 150],,[0,20]],
//         [[4, 180],[0, -20]],
//         [[4, 230]],
//         [[-4,230]],
//         [[-4,175],,[0, -20]],
//         [[28, 145],[0, 20]],
//         [[28, 55]],
//         [[28, 116],,[0, 10]],
//         [[30,142],[12, -12],[-12,12]],
//         [[6, 130]]
//     ))
// }
function armFirstHalfPath() {
    return new Path(Segments([[15, 125]], [[25, 136], [-5, 5], [5, -5]], [[20.1, 120], [0, 5]], [[28, 116], , [0, 10]], [[30, 142], [12, -12], [-12, 12]], [[6, 130]]));
}
function armSecondHalfPath() {
    return new Path(Segments([[20, 120], [0, 5]], [[20, 55], , [0, -13]], [[36, 55], [0, -13]], [[36, 150], , [0, 20]], [[4, 180], [0, -20]], [[4, 230]], [[-4, 230]], [[-4, 175], , [0, -20]], [[28, 145], [0, 20]], [[28, 55]], [[28, 116], , [0, 10]]));
}
