import { P, R } from "apparatus/library/common";
import { Group, Path } from "paper";
import { addEdgeLips, breakPath, glassFold, mirrorX, setDefaultStyle, smoothCorner } from "../draw";
import { Segments } from "./common";
var FOURTH_NECK_ROTATION = 10;
var RoundBottomFlask = /** @class */ (function () {
    function RoundBottomFlask() {
    }
    RoundBottomFlask.prototype.render = function (appearance) {
        var neckWidth = 12 + appearance.size / 12;
        var neckTaper = 2;
        var neckHeight = 20 + appearance.size / 15;
        var radius = appearance.size / 2;
        var glassFoldSize = neckWidth * 0.48;
        var cornerSmoothing = radius / 8;
        // Round bottom flask is drawn using path union. First draw
        // the bottom part, then the neck and then assemble.
        var bulb = new Path.Circle(P(0, radius), radius);
        var neck = new Path(Segments([[-neckWidth / 2, -neckHeight]], [[-neckWidth / 2 + neckTaper, 20]] // Use a higher number to make sure it attaches to the bottom.
        ));
        mirrorX(neck);
        addEdgeLips(neck);
        // Snap point, which is placed at the top of the neck, slightly inside.
        var connectableSnapPoint = P(0, -neckHeight + 10);
        // Snap point for bung, placed at the height of the neck.
        var neckSnapPoint = P(0, -neckHeight);
        var snapPoints = [
            { type: "placeable", at: bulb.strokeBounds.bottomCenter },
        ];
        var combinedShape;
        var neckPivot = P(0, radius * 2);
        if (appearance.necks == "one") {
            combinedShape = neck.unite(bulb);
            snapPoints.push({ type: "connectable", at: connectableSnapPoint, facing: 0 });
            snapPoints.push({ type: "neck", top: neckSnapPoint, diameter: neckWidth, facing: 0 });
            combinedShape.closed = false;
        }
        else if (appearance.necks == "three" || appearance.necks == "four") {
            var neckLeft = neck.clone();
            var neckRight = neck.clone();
            neckLeft.rotate(-20, neckPivot);
            neckRight.rotate(20, neckPivot);
            if (appearance.necks == "four") {
                neck.rotate(-3, neckPivot);
                snapPoints.push({ type: "connectable", at: connectableSnapPoint.rotate(-3, neckPivot), facing: -3 });
                snapPoints.push({ type: "neck", top: neckSnapPoint.rotate(-3, neckPivot), diameter: neckWidth, facing: -3 });
                snapPoints.push({ type: "connectable", at: connectableSnapPoint.rotate(FOURTH_NECK_ROTATION, neckPivot), facing: FOURTH_NECK_ROTATION });
                snapPoints.push({ type: "neck", top: neckSnapPoint.rotate(FOURTH_NECK_ROTATION, neckPivot), diameter: neckWidth, facing: FOURTH_NECK_ROTATION });
            }
            else {
                snapPoints.push({ type: "connectable", at: connectableSnapPoint, facing: 0 });
                snapPoints.push({ type: "neck", top: neckSnapPoint, diameter: neckWidth, facing: 0 });
            }
            combinedShape = neck.unite(bulb).unite(neckLeft).unite(neckRight);
            smoothCorner(combinedShape.segments[12], cornerSmoothing);
            smoothCorner(combinedShape.segments[8], cornerSmoothing);
            snapPoints.push({ type: "connectable", at: connectableSnapPoint.rotate(-20, neckPivot), facing: -20 });
            snapPoints.push({ type: "neck", top: neckSnapPoint.rotate(-20, neckPivot), diameter: neckWidth, facing: -20 });
            snapPoints.push({ type: "connectable", at: connectableSnapPoint.rotate(20, neckPivot), facing: 20 });
            snapPoints.push({ type: "neck", top: neckSnapPoint.rotate(20, neckPivot), diameter: neckWidth, facing: 20 });
        }
        else { // Two-neck cases.
            var secondNeck = neck.clone();
            var angle = 20;
            secondNeck.rotate(angle, neckPivot);
            combinedShape = neck.unite(bulb).unite(secondNeck);
            snapPoints.push({ type: "connectable", at: connectableSnapPoint, facing: 0 });
            snapPoints.push({ type: "neck", top: neckSnapPoint, diameter: neckWidth, facing: 0 });
            snapPoints.push({ type: "connectable", at: connectableSnapPoint.rotate(angle, neckPivot), facing: angle });
            snapPoints.push({ type: "neck", top: neckSnapPoint.rotate(angle, neckPivot), diameter: neckWidth, facing: angle });
            smoothCorner(combinedShape.segments[8], cornerSmoothing);
        }
        // Liquid mask.
        var liquidMask = combinedShape.clone();
        // For 3-neck, decompose the shape into separate paths.
        var graphic;
        if (appearance.necks == "one") {
            setDefaultStyle(combinedShape);
            graphic = new Group([
                combinedShape,
                glassFold(0, 5, { size: glassFoldSize })
            ]);
        }
        else if (appearance.necks == "three") {
            graphic = breakPath(combinedShape, 6, 17);
            setDefaultStyle(graphic);
            graphic.addChildren([
                glassFold(0, 5, { size: glassFoldSize }),
                glassFold(radius * 0.61, radius * 0.3, { rotation: 35, size: glassFoldSize }),
                glassFold(-radius * 0.61, radius * 0.3, { rotation: -35, size: glassFoldSize })
            ]);
        }
        else if (appearance.necks == "four") {
            // Break at the same points as three but create a fourth neck
            // that is just overlayed on top of everything.
            var fourthNeck = neck.clone();
            // +3 is required because of earlier -3 on the neck.
            fourthNeck.rotate(FOURTH_NECK_ROTATION + 3, neckPivot);
            var fourthNeckGlass = fourthNeck.clone();
            fourthNeckGlass.strokeWidth = 0.0;
            fourthNeckGlass.fillColor = "#ffffffaa";
            graphic = new Group([
                breakPath(combinedShape, 6, 17),
                fourthNeckGlass,
                glassFold(radius * -0.1, 6, { size: glassFoldSize }),
                glassFold(radius * 0.61, radius * 0.3, { rotation: 35, size: glassFoldSize }),
                glassFold(-radius * 0.61, radius * 0.3, { rotation: -35, size: glassFoldSize }),
                breakPath(fourthNeckGlass, 3),
                glassFold(radius * 0.36 - 6, 27, { rotation: 13, size: glassFoldSize }),
            ]);
            setDefaultStyle(graphic.children[0], graphic.children[5]);
        }
        else {
            // Two-neck case. (R)
            graphic = breakPath(combinedShape, 6);
            setDefaultStyle(graphic);
            graphic.addChildren([
                glassFold(0, 5, { size: glassFoldSize }),
                glassFold(radius * 0.61, radius * 0.3, { rotation: 35, size: glassFoldSize }),
            ]);
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: P(0, radius),
            liquidMask: liquidMask,
            snapping: snapPoints
        };
    };
    RoundBottomFlask.properties = {
        label: "Round bottom flask",
        canContainLiquids: true,
        defaultAppearance: {
            size: 115,
            necks: "three"
        },
        flippable: true,
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 80, max: 200,
            },
            {
                spec: "toggle-buttons",
                size: "text",
                key: "necks",
                label: "Necks",
                options: [
                    { value: "one", label: "1" },
                    { value: "two-r", label: "2" },
                    { value: "three", label: "3" },
                    { value: "four", label: "4" },
                ]
            },
        ],
    };
    return RoundBottomFlask;
}());
export { RoundBottomFlask };
// Sizes: https://www.ebay.co.uk/itm/222654821188?var=&ul_noapp=true
var BoilingFlask = /** @class */ (function () {
    function BoilingFlask() {
    }
    BoilingFlask.prototype.render = function (appearance) {
        var neckWidth = 10 + appearance.size / 10;
        var neckHeight = 20 + appearance.size / 3;
        var radius = appearance.size / 2;
        var bulb = new Path.Circle(P(0, radius), radius);
        var neck = new Path(Segments([[-neckWidth / 2, -neckHeight]], [[-neckWidth / 2, 30]] // Use a higher number to make sure it attaches to the bottom.
        ));
        mirrorX(neck);
        addEdgeLips(neck);
        var combinedShape = neck.unite(bulb);
        // If the bottom is flat, create one by subtracting a rectangle.
        if (appearance.bottom == "flat" || appearance.bottom == "very flat") {
            var flatness = appearance.bottom == "flat" ? 0.07 : 0.2;
            var flatBottomNegative = new Path.Rectangle(R(-radius, (2.0 - flatness) * radius, radius * 2, radius));
            combinedShape = combinedShape.subtract(flatBottomNegative);
        }
        var liquidMask = combinedShape.clone();
        var hitShape = combinedShape.clone();
        setDefaultStyle(combinedShape);
        combinedShape.closed = false;
        var graphic = new Group([combinedShape]);
        graphic.addChild(glassFold(0, 5, { size: neckWidth * 0.7 }));
        return {
            graphic: graphic,
            hitShape: hitShape,
            liquidMask: liquidMask,
            liquidMaskOpening: { start: P(-neckWidth / 2, -neckHeight), end: P(neckWidth / 2, -neckHeight) },
            snapping: [
                { type: "placeable", at: combinedShape.bounds.bottomCenter },
                { type: "neck", top: P(0, -neckHeight), diameter: neckWidth, facing: 0 },
            ]
        };
    };
    BoilingFlask.properties = {
        label: "Boiling Flask",
        canContainLiquids: true,
        pourable: true,
        defaultAppearance: {
            size: 120,
            bottom: "flat",
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 45, max: 180,
            },
            {
                spec: "radio", key: "bottom", label: "Base",
                options: [
                    { value: "round", label: "Round" },
                    { value: "flat", label: "Rather Flat" },
                    { value: "very flat", label: "Very flat" }
                ],
            }
        ],
    };
    return BoilingFlask;
}());
export { BoilingFlask };
