import { addMarkers, mirrorX, setDefaultStyle } from "apparatus/draw";
import { P, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
var Hydrometer = /** @class */ (function () {
    function Hydrometer() {
    }
    Hydrometer.prototype.render = function (appearance) {
        var glass = new Path(Segments([[5, -140], [0, -7]], [[5, 0], , [0, 5]], [[10, 10], [0, -5]], [[10, 70], , [0, 14]]));
        mirrorX(glass);
        glass.closePath();
        var ballast = new Path(Segments([[10, 60], [0, -2]], [[10, 70], , [0, 14]]));
        mirrorX(ballast);
        ballast.close();
        var outline = glass.clone();
        // Style everything.
        setDefaultStyle(outline);
        glass.fillColor = "#ffffffaa";
        ballast.fillColor = "#555555";
        var graphic = new Group([glass, ballast, outline]);
        addMarkers(graphic, P(3, 0), -140, P(0, -5), 4.0);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    Hydrometer.properties = {
        label: "Hydrometer",
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return Hydrometer;
}());
export { Hydrometer };
