import { LIQUID_OPACITY_RANGE, P, Segments, SharedColors } from "apparatus/library/common";
import { DEFAULT_SWATCHES } from "components/colorpicker";
import { Group, Path } from "paper";
var Drop = /** @class */ (function () {
    function Drop() {
    }
    Drop.prototype.render = function (appearance) {
        var shape = appearance.shape;
        var dropShape = (function () {
            switch (shape) {
                case "drop":
                    var plumpness = appearance.plumpness;
                    var path = new Path(Segments([[0, 0], [-plumpness, 4], [plumpness, 4]], [[0, 10], [plumpness, 0], [-plumpness, 0]]));
                    path.closePath();
                    return path;
                case "round":
                    return new Path.Circle(P(0, 0), 5);
            }
        })();
        dropShape.scale(appearance.size);
        dropShape.fillColor = appearance.color.color().alpha(appearance.opacity).toString();
        dropShape.withStroke("thinner", appearance.color);
        var graphic;
        if (appearance.count > 1) {
            graphic = new Group([dropShape]);
            dropShape.position.y = 0;
            for (var i = 1; i < appearance.count; i++) {
                var drop = dropShape.clone();
                drop.position.y = i * (10 + appearance.dropSpacing) * appearance.size;
                graphic.addChild(drop);
            }
        }
        else {
            graphic = dropShape;
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(20).toShape(),
            snapping: appearance.shape == "round"
                ? { type: "spotting_tile_placeable", at: P(0, 0) }
                : undefined
        };
    };
    Drop.properties = {
        label: "Drop",
        defaultAppearance: {
            color: SharedColors.defaultLiquid,
            opacity: SharedColors.defaultLiquidAlpha,
            size: 2,
            shape: "drop",
            plumpness: 5.0,
            count: 1,
            dropSpacing: 5,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 1.5, max: 4, step: 0.1
            },
            {
                spec: "radio", key: "shape", label: "Shape",
                options: [
                    { value: "drop", label: "Drop" },
                    { value: "round", label: "Round" },
                ]
            },
            {
                spec: "slider", key: "count", label: "Number of drops",
                min: 1, max: 5, tier: "boost",
            },
            {
                spec: "slider", key: "dropSpacing", label: "Drop spacing",
                min: 1, max: 15, visible: function (a) { return a.count > 1; },
            },
            {
                spec: "slider", key: "plumpness", label: "Plumpness",
                min: 3.0, max: 6.0, step: 0.1,
                visible: function (a) { return a.shape == "drop"; }
            },
            {
                spec: "color", key: "color", label: "Liquid colour",
                swatches: DEFAULT_SWATCHES,
                opacity: { key: "opacity", range: LIQUID_OPACITY_RANGE }
            }
        ],
    };
    return Drop;
}());
export { Drop };
