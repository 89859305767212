import { CenterR, P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, PointText, Shape } from "paper";
import { setDefaultStyle } from "../../draw";
var PhProbe = /** @class */ (function () {
    function PhProbe() {
        this.drivers = [
            {
                type: "rectangle",
                minX: -150, maxX: 150,
                minY: 0, maxY: 200,
                toAppearance: function (f, p) { return f({ probeX: p.x, probeY: p.y }); },
                fromAppearance: function (a) { return P(a.probeX, a.probeY); },
                scale: 1.0,
            }
        ];
    }
    PhProbe.prototype.render = function (appearance) {
        var probeOffsetX = appearance.probeX;
        var probeOffsetY = appearance.probeY;
        // Create a reader
        var casing = Shape.Rectangle(R(0, 0, 60, 100, Pivot.TOP_CENTER), 4);
        casing.fillColor = "#dddddd";
        setDefaultStyle(casing);
        var screen = Shape.Rectangle(R(0, 7, 46, 25, Pivot.TOP_CENTER), 2);
        screen.strokeWidth = 2.0;
        screen.strokeColor = "#777777";
        screen.fillColor = "white";
        var button = Shape.Circle(P(-10, 80), 7);
        button.fillColor = "#54b347";
        button.strokeWidth = 2.0;
        button.strokeColor = "#498241";
        var button2 = Shape.Circle(P(10, 80), 7);
        button2.fillColor = "#54b347";
        button2.strokeWidth = 2.0;
        button2.strokeColor = "#498241";
        var display = new PointText({
            point: P(0, 25),
            content: appearance.reading,
            fillColor: 'black',
            fontFamily: 'sans-serif',
            fontSize: 15,
            justification: "center",
        });
        var phLabel = new PointText({
            point: P(-20, 50),
            content: "pH",
            fillColor: "#888888",
            fontFamily: 'sans-serif',
            fontSize: 14,
            justification: "left",
        });
        var reader = new Group([casing, screen, display, phLabel, button, button2]);
        // Create the probe
        var probeRod = Shape.Rectangle(CenterR(0, 60, 10, 120));
        probeRod.setGradientFill("right", [["#cccccc", 0.0], ["#aaaaaa", 1.0]]);
        var probeTop = Shape.Rectangle(CenterR(0, 15, 15, 30));
        probeTop.fillColor = "#333333";
        var probe = new Group([probeRod, probeTop]);
        probe.position = probe.position.add(P(probeOffsetX, probeOffsetY));
        // Create the wire.
        var wire = new Path(Segments([[0, 100], undefined, [0, 50]], // Connects to the casing.
        [[probeOffsetX, probeOffsetY], [0, -50]]));
        setDefaultStyle(wire);
        var graphic = new Group([reader, probe, wire]);
        var hitShapes = [
            probe.bounds.toShape(),
            reader.bounds.toShape(),
        ];
        return {
            graphic: graphic, hitShape: hitShapes, pivot: casing.bounds.center,
        };
    };
    PhProbe.convertToLatest = function (old) {
        if (!old.probeX) {
            old.probeX = old.position == "right" ? 150 : -150;
            old.probeY = 100;
        }
        return old;
    };
    PhProbe.properties = {
        label: "pH Probe",
        defaultAppearance: {
            reading: "7.0",
            probeX: 150,
            probeY: 100,
        },
        appearanceSpecs: [
            {
                spec: "text", key: "reading", label: "Reading",
                numbersOnly: true, maxLength: 5,
            },
        ],
    };
    return PhProbe;
}());
export { PhProbe };
