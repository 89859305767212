/**
 * Everything that has to do with saving and loading a ChemixDocument.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { apparatusClass } from "apparatus/library";
import { DEFAULT_LIQUID_DATA } from "apparatus/library/common";
import { Arrow } from "arrows/type";
import { CURRENT_CHEMIX_DOCUMENT_VERSION } from "file/type";
import { ID } from "store/id";
import { appStore, DEFAULT_CHEM_MARK_CONFIG, DEFAULT_THEME } from "store/store";
export function convertItem(item) {
    var _a, _b;
    var klass = apparatusClass(item.type);
    if (klass.convertToLatest) {
        item.appearance = klass.convertToLatest(item.appearance);
    }
    else {
        item.appearance = __assign(__assign({}, klass.properties.defaultAppearance), item.appearance);
    }
    if (item.liquid) {
        item.liquid = __assign(__assign(__assign({}, DEFAULT_LIQUID_DATA), item.liquid), { pouring: __assign(__assign({}, DEFAULT_LIQUID_DATA.pouring), (_a = item.liquid) === null || _a === void 0 ? void 0 : _a.pouring), meniscus: __assign(__assign({}, DEFAULT_LIQUID_DATA.meniscus), (_b = item.liquid) === null || _b === void 0 ? void 0 : _b.meniscus) });
    }
    return item;
}
export function convertArrow(arrow) {
    // Fix bugs:
    //  1) Iterate over arrows to check start and end are {x,y}
    //     instead of the serialised Point object.
    return __assign(__assign(__assign({}, Arrow.defaultArrow), arrow), { start: ensurePosition(arrow.start), end: ensurePosition(arrow.end) });
}
export var DiagramDatas;
(function (DiagramDatas) {
    /**
     * Converts stored (in JSON) document to {@link DiagramData}, matching
     * latest specs.
     */
    function parseJson(data) {
        var doc = JSON.parse(data);
        doc.arrows = doc.arrows.map(convertArrow);
        doc.items = doc.items.map(convertItem);
        // Run migrations.
        if (!doc.version || doc.version == 1) {
            migrateFrom1To2(doc);
        }
        if (doc.version == 2) {
            migrateFrom2To3(doc);
        }
        if (doc.version == 3) {
            migrateFrom3To4(doc);
        }
        // Make sure same IDs use the same reference.
        graftIds(doc);
        doc.version = CURRENT_CHEMIX_DOCUMENT_VERSION;
        return doc;
    }
    DiagramDatas.parseJson = parseJson;
    /**
     * Extract current saveable state as JSON. Note this does not
     * return deep copies so conversion to JSON immediately is advised.
     */
    function getCurrentDocument() {
        var currentState = appStore.getState();
        return {
            version: CURRENT_CHEMIX_DOCUMENT_VERSION,
            zoom: currentState.zoom,
            items: currentState.items,
            arrows: currentState.arrows,
            groups: currentState.groups,
            theme: currentState.theme,
            chemMark: currentState.chemMark,
        };
    }
    DiagramDatas.getCurrentDocument = getCurrentDocument;
    /** Returns a blank document, based on the current settings. */
    function getBlankDocument() {
        return __assign(__assign({}, getCurrentDocument()), { items: [], arrows: [], groups: [] });
    }
    DiagramDatas.getBlankDocument = getBlankDocument;
})(DiagramDatas || (DiagramDatas = {}));
function migrateFrom1To2(doc) {
    doc.version = 2;
    doc.groups = [];
}
function migrateFrom2To3(doc) {
    doc.version = 3;
    doc.theme = __assign({}, DEFAULT_THEME);
}
function migrateFrom3To4(doc) {
    doc.version = 4;
    doc.chemMark = __assign({}, DEFAULT_CHEM_MARK_CONFIG);
}
/** Converts definitely to plain Position */
function ensurePosition(position) {
    return Array.isArray(position) && position[0] === "Point" ? { x: position[1], y: position[2] } : position;
}
/**
 * IDs rely on object equality. Loading from JSON creates new instances of IDs,
 * so object equality would be broken. Re-graft referenced IDs to same instance
 * as the owned ID.
 */
function graftIds(doc) {
    // Collect all owned IDs.
    var ownedIds = {
        "arrow": {},
        "apparatus": {},
        "group": {}
    };
    doc.items.forEach(function (i) { return ownedIds["apparatus"][i.id.id] = i.id; });
    doc.arrows.forEach(function (i) { return ownedIds["arrow"][i.id.id] = i.id; });
    doc.groups.forEach(function (i) { return ownedIds["group"][i.id.id] = i.id; });
    // Now regraft each reference ID to the owned instance.
    doc.items.forEach(function (i) {
        if (i.parentGroup) {
            i.parentGroup = ownedIds.group[i.parentGroup.id];
        }
    });
    doc.arrows.forEach(function (a) {
        if (a.parentGroup) {
            a.parentGroup = ownedIds.group[a.parentGroup.id];
        }
    });
    doc.groups.forEach(function (g) {
        if (g.parentGroup) {
            g.parentGroup = ownedIds.group[g.parentGroup.id];
        }
        g.items =
            g.items
                // Filter out null and undefined values.
                .filter(function (i) { return i != null; })
                .map(function (i) { return ownedIds[i.type][i.id]; })
                // Filter afterwards too, some groups may be broken
                // and contain invalid children.
                .filter(function (i) { return i != null; });
    });
    // SINGLE CHILD COLLAPSE:
    // If the group only has 1 child, collapse it: if the group has a child, reparent
    // it to its parents.
    doc.groups.filter(function (g) { return g.items.length == 1; }).forEach(function (g) {
        var childId = g.items[0];
        var newParentId = g.parentGroup;
        // Assign child to parent.
        if (newParentId) {
            var parentGroup = doc.groups.filter(ID.matching(newParentId))[0];
            parentGroup.items = parentGroup.items.map(function (id) { return id == g.id ? childId : id; });
        }
        // Assign parent to child.
        var child = getChildIn(doc, childId);
        child.parentGroup = newParentId;
    });
    // Don't keep empty groups or groups with 1 child.
    doc.groups = doc.groups.filter(function (g) { return g.items.length > 1; });
}
function getChildIn(doc, childId) {
    switch (childId.type) {
        case "apparatus":
            return doc.items.filter(ID.matching(childId))[0];
        case "arrow":
            return doc.arrows.filter(ID.matching(childId))[0];
        case "group":
            return doc.groups.filter(ID.matching(childId))[0];
    }
}
