import { setDefaultStyle } from "apparatus/draw";
import { R } from "apparatus/library/common";
import { Shape } from "paper";
var BenchMat = /** @class */ (function () {
    function BenchMat() {
    }
    BenchMat.prototype.render = function (appearance) {
        var size = appearance.size;
        var graphic = (function () {
            switch (appearance.view) {
                case "top":
                    return Shape.Rectangle(R(0, 0, size, size), 4);
                case "side":
                    return Shape.Rectangle(R(0, 0, size, 10), 3);
            }
        })();
        setDefaultStyle(graphic);
        switch (appearance.color) {
            case "ceramic":
                graphic.strokeColor = "#c2a690";
                graphic.fillColor = "#d9c8b9";
                break;
            case "white":
                graphic.strokeColor = "#bbbbbb";
                graphic.fillColor = "#eeeeee";
                break;
        }
        var hitBounds = graphic.bounds;
        if (appearance.view == "side") {
            hitBounds = hitBounds.expand(10);
        }
        return {
            graphic: graphic,
            hitShape: hitBounds.toShape(),
            snapping: appearance.view == "side"
                ? [graphic.topEdgeAsPlaceableSurface(), graphic.placeable()]
                : undefined
        };
    };
    BenchMat.properties = {
        label: "Bench Mat (Tile)",
        defaultAppearance: {
            view: "side",
            color: "ceramic",
            size: 150,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 70, max: 200
            },
            {
                spec: "radio", key: "color", label: "Colour",
                options: [
                    { value: "ceramic", label: "Ceramic" },
                    { value: "white", label: "White" },
                ],
            },
            {
                spec: "radio", key: "view", label: "View",
                options: [
                    { value: "top", label: "From top" },
                    { value: "side", label: "From side" },
                ],
            }
        ],
    };
    return BenchMat;
}());
export { BenchMat };
