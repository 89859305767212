import { SVG } from "apparatus/svg";
var Bug = /** @class */ (function () {
    function Bug() {
    }
    Bug.prototype.render = function (appearance) {
        var index = appearance.type.split(",");
        var allBugs = SVG.bugs();
        var graphic = allBugs.children[+index[1] + 1].clone();
        graphic.scale(appearance.scale);
        allBugs.remove();
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            watermark: graphic.bounds.bottomRight,
        };
    };
    Bug.properties = {
        label: "Bug",
        defaultAppearance: {
            type: "0,0",
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.5, max: 3, step: 0.01, unit: "%"
            },
            {
                spec: "radio", key: "type", label: "Bug type",
                options: [
                    { value: "0,0", label: "Generic" },
                    { value: "0,2", label: "Ant" },
                    { value: "0,6", label: "Spider" },
                    { value: "0,7", label: "Ladybug" },
                ],
            }
        ],
    };
    return Bug;
}());
export { Bug };
