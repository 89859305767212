var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { INFINITE_BOUNDS } from "apparatus/driver";
import { P } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var MINIMUM_ARM_LENGTH = 50;
/** Radius of the arc. This should be shorter than {@link MINIMUM_ARM_LENGTH} */
var ARC_SIZE = 40;
var AngleLabel = /** @class */ (function () {
    function AngleLabel() {
        this.drivers = [
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ startX: p.x, startY: p.y }); }, fromAppearance: function (a) { return P(a.startX, a.startY); }, scale: 1.0, constraints: function (a, _) {
                    // Constraint to minimum arm length
                    var point = constrainToMinimumLength(P(a.startX, a.startY));
                    return { startX: point.x, startY: point.y };
                } }),
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ endX: p.x, endY: p.y }); }, fromAppearance: function (a) { return P(a.endX, a.endY); }, scale: 1.0, constraints: function (a, _) {
                    // Constraint to minimum arm length
                    var point = constrainToMinimumLength(P(a.endX, a.endY));
                    return { endX: point.x, endY: point.y };
                } }),
        ];
    }
    AngleLabel.prototype.render = function (appearance) {
        var start = P(appearance.startX, appearance.startY);
        var end = P(appearance.endX, appearance.endY);
        var origin = P(0, 0);
        var graphic = new Group([]);
        // Add lines
        var lines = new Group([
            new Path([origin, start]),
            new Path([origin, end])
        ]).withStroke("default", "black");
        graphic.addChild(lines);
        // Add angle arc.
        var arcStart = start.clone();
        arcStart.length = ARC_SIZE;
        var arcEnd = end.clone();
        arcEnd.length = ARC_SIZE;
        var arcThrough = arcStart.clone();
        var arcEndAngle = arcEnd.angle;
        if (arcEndAngle > arcStart.angle) {
            arcEndAngle -= 360;
        }
        arcThrough.angle = (arcStart.angle + arcEndAngle) / 2;
        graphic.addChild(new Path.Arc(arcStart, arcThrough, arcEnd).withStroke("thin", "black"));
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            pivot: P(0, 0)
        };
    };
    AngleLabel.properties = {
        label: "Angle Label",
        defaultAppearance: {
            startX: 100,
            startY: 0,
            endX: 70,
            endY: -70,
        },
        appearanceSpecs: [],
    };
    AngleLabel = __decorate([
        staticImplements()
    ], AngleLabel);
    return AngleLabel;
}());
export { AngleLabel };
function constrainToMinimumLength(p) {
    if (p.length > MINIMUM_ARM_LENGTH)
        return p;
    if (p.length == 0)
        return P(MINIMUM_ARM_LENGTH, 0);
    p.length = MINIMUM_ARM_LENGTH;
    return p;
}
