import { P } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
import { Group, Path } from "paper";
import { Random } from "random/random";
var FLAME_COLOR_SWATCHES = {
    "Green": "#5FAA5C",
    "Blue": "#5C6FAA",
    "Yellow": "#E9DC8C",
};
var Flame = /** @class */ (function () {
    function Flame() {
    }
    Flame.prototype.render = function (appearance) {
        var flame = SVG.redFlame();
        var baseColor = "#F67C01".color();
        var highlightColor = baseColor.ladd(30);
        if (appearance.colorMode == "custom") {
            baseColor = appearance.color.color();
            highlightColor = baseColor.ladd(30);
            // Outer flame shape.
            flame.children[0].children[0].setGradientFill("down", [
                [highlightColor.hex(), 0.0],
                [baseColor.hex(), 1.0]
            ]);
            // Inner flame shape.
            flame.children[0].children[1].setGradientFill("down", [
                [highlightColor.ladd(50).hex(), 0.0],
                [highlightColor.ladd(20).hex(), 1.0]
            ]);
            flame.opacity = 0.9;
        }
        var flames = new Group();
        var glows = new Group();
        var graphic = new Group([glows, flames]);
        var glow;
        if (appearance.glow) {
            glow = new Path.Circle(P(6, 8), 20)
                .setRadialGradientFill([
                [highlightColor.alpha(0.6).string(), 0.1],
                [highlightColor.alpha(0).string(), 1.0],
            ], { highlight: P(6, 21) });
            glow.applyMatrix = false;
            glow.scale(1.0, 2.7);
            glows.addChild(glow);
        }
        graphic.addChild(flame);
        var r = new Random();
        var position = flame.position;
        for (var i = 1; i < appearance.peaks; i++) {
            var newFlame = flame.clone();
            position = position.add(P(appearance.spacing, 0)).add(r.randomUniformVector(5.0));
            newFlame.position = position;
            graphic.addChild(newFlame);
            if (glow) {
                var newGlow = glow.clone();
                newGlow.position = position.add(P(0, -39));
                glows.addChild(newGlow);
            }
        }
        graphic.scale(appearance.scale);
        return { graphic: graphic, hitShape: graphic.bounds.toShape(), pivot: flames.bounds.center };
    };
    Flame.properties = {
        label: "Flame",
        defaultAppearance: {
            peaks: 1,
            scale: 1.0,
            spacing: 12,
            colorMode: "default",
            color: FLAME_COLOR_SWATCHES["Green"],
            glow: false,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.5, max: 2.0, step: 0.05, unit: "%",
            },
            {
                spec: "slider", key: "peaks", label: "Number of peaks",
                min: 1, max: 7, step: 1,
                tier: "boost"
            },
            {
                spec: "slider", key: "spacing", label: "Spacing between peaks",
                min: 12, max: 20, tier: "boost",
                visible: function (a) { return a.peaks > 1; }
            },
            {
                spec: "radio", key: "colorMode", label: "Flame colour",
                options: [
                    { value: "default", label: "Default red" },
                    { value: "custom", label: "Custom" },
                ],
            },
            {
                spec: "color", key: "color", label: "Custom colour",
                visible: function (a) { return a.colorMode === "custom"; },
                swatches: FLAME_COLOR_SWATCHES, demoSwatches: FLAME_COLOR_SWATCHES,
                tier: "boost",
            },
            { spec: "checkbox", key: "glow", label: "Glow", useSwitch: true, tier: "boost" },
        ],
    };
    return Flame;
}());
export { Flame };
