var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, SharedGradients } from "apparatus/library/common";
import { Mounted } from "apparatus/library/physics/electric/mounted/utils";
import { Group, Path } from "paper";
var METAL_STROKE = "#444444";
var KnifeSwitch = /** @class */ (function () {
    function KnifeSwitch() {
    }
    KnifeSwitch.prototype.render = function (appearance) {
        var _a = Mounted.renderPlate({ appearance: appearance, offset: P(0, 7) }), base = _a.base, jacks = _a.jacks, snaps = _a.snaps;
        var hinge = new Path.Rectangle(R(20, -5, 12, 30, Pivot.TOP_CENTER), 2.0)
            .withGradientFill("down", [["#aaaaaa", 0.27], ["#eeeeee", 0.35], ["#cccccc", 0.42], ["#cccccc", 0.8], ["#777777", 1.0]])
            .withStroke("thin", METAL_STROKE);
        var contactBack = new Path.Rectangle(R(80, -5, 12, 7, Pivot.BOTTOM_CENTER), 2.0)
            .withGradientFill("down", [["#eeeeee", 0.35], ["#555555", 0.5], ["#eeeeee", 0.65]])
            .withStroke("thin", METAL_STROKE);
        var contactFront = new Path.Rectangle(R(80, -5, 12, 30, Pivot.TOP_CENTER), 2.0)
            .withGradientFill("down", [["#cccccc", 0.0], ["#ffffff", 0.07], ["#999999", 0.2], ["#ffffff", 0.27], ["#cccccc", 0.35], ["#cccccc", 0.8], ["#777777", 1.0]])
            .withStroke("thin", METAL_STROKE);
        var knife = new Group([
            // Blade.
            new Path.Rectangle(R(15, 0, 77, 10, Pivot.CENTER_LEFT))
                .withStroke("thin", METAL_STROKE).withGradientFill("right-down", SharedGradients.cylinderMetal),
            // Handle outside.
            new Path.Rectangle(R(93, 0, 25, 12, Pivot.CENTER_LEFT), 4.0)
                .withGradientFill("down", Gradients.cylinder("#222222".color())),
            // Handle thick.
            new Path.Rectangle(R(90, 0, 7, 20, Pivot.CENTER_LEFT), 2.0)
                .withGradientFill("down", Gradients.cylinder("#222222".color())),
        ]);
        knife.pivot = P(20, 0);
        if (!appearance.on) {
            knife.rotate(-30);
        }
        var graphic = new Group([base, contactBack, knife, hinge, contactFront, jacks]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: base.bounds.center,
            snapping: __spreadArray([], snaps)
        };
    };
    KnifeSwitch.properties = {
        label: "Knife switch",
        flippable: true,
        defaultAppearance: __assign(__assign({}, Mounted.defaultAppearance), { on: false }),
        appearanceSpecs: __spreadArray(__spreadArray([], Mounted.appearanceSpecs), [
            { spec: "checkbox", key: "on", label: "Switch position", subLabel: "On" },
        ]),
    };
    return KnifeSwitch;
}());
export { KnifeSwitch };
