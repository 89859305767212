var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { g } from "analytics";
import { P } from "apparatus/library/common";
import { AddArrow } from "arrows/command";
import { Arrow } from "arrows/type";
import { Deselect, Select } from "command/basic";
import { commandProcessor, Sequence } from "command/command";
import { Key, Tool } from "paper";
import { ID } from "store/id";
import store from "store/store";
import { crumb } from "utils";
/** Minimum length of the arrow before the arrow is shown. */
var MIN_DELTA_LENGTH_SHOW_ARROW = 20.0;
/** Arrow offset, from start to end, when no arow is shown.*/
var NO_ARROW_END_OFFSET = P(0, 15);
var ArrowTool = /** @class */ (function (_super) {
    __extends(ArrowTool, _super);
    function ArrowTool(layers, app) {
        var _this = _super.call(this) || this;
        /** Template arrow data to use when creating new diagrams. */
        _this.templateArrow = Arrow.defaultArrow;
        _this.onMouseDown = function (event) {
        };
        _this.onMouseDrag = function (event) {
            _this.appInterface.setArrow(_this.createArrow(event, ID.TEMP_ARROW));
        };
        _this.onMouseUp = function (event) {
            crumb("ui", "Add arrow");
            g("Add arrow");
            var id = ID.mint("arrow");
            var newArrowCommand = new AddArrow([_this.createArrow(event, id)]);
            commandProcessor.execute(new Sequence([
                newArrowCommand,
                new Deselect(store.selectedObjectsID()),
                new Select([id]),
            ]));
            // Clean up temporary arrow.
            _this.appInterface.setArrow();
        };
        _this.appInterface = app;
        return _this;
    }
    ArrowTool.prototype.createArrow = function (event, id) {
        var deltaVector = event.point.subtract(event.downPoint);
        // Checks if arrow is too short.
        var showArrow = deltaVector.length > MIN_DELTA_LENGTH_SHOW_ARROW;
        // Calculates angle of arrow.
        var angle = deltaVector.angle; //Calculates angle of arrow
        if (Key.modifiers.shift) {
            // Snaps arrow to multiples of 45 degrees.
            var roundedAngle = ((angle / 45).round()) * 45;
            deltaVector.angle = roundedAngle;
            event.point = event.downPoint.add(deltaVector);
        }
        return __assign(__assign({}, this.templateArrow), { id: id, start: event.downPoint, end: showArrow ? event.point : event.downPoint.add(NO_ARROW_END_OFFSET), showArrow: showArrow, selected: false });
    };
    ArrowTool.prototype.updateTemplateArrow = function (data) {
        this.templateArrow = __assign(__assign({}, this.templateArrow), data);
    };
    return ArrowTool;
}(Tool));
export default ArrowTool;
