// Based on:
// https://codeburst.io/react-authentication-with-twitter-google-facebook-and-github-862d59583105
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { g } from "analytics";
import { BACKEND_API } from "api/api";
import { t } from "i18next";
import { clog } from "log";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { logIn } from "store/actions";
import store from "store/store";
import { Toast } from "toast";
import { isIEOrEdge } from "utils";
export function loginProviderIcon(provider) {
    if (provider === "facebook") {
        return "fab fa-facebook-f";
    }
    else if (provider === "code") {
        return "fas fa-key";
    }
    return "fab fa-" + provider;
}
export function loginProviderDisplayName(provider) {
    return provider !== "code" ? provider.capitalise() : "access code";
}
var LoginButton = /** @class */ (function (_super) {
    __extends(LoginButton, _super);
    function LoginButton(props) {
        var _this = _super.call(this, props) || this;
        _this.render = function () {
            var providerName = _this.props.providerName;
            return React.createElement("button", { className: "ui-button mx-auto mt-2 " + providerName, onClick: function () { return _this.onButtonClick(); } },
                React.createElement("i", { className: "mr-2 " + loginProviderIcon(providerName) }),
                React.createElement(SignInLabel, { providerName: providerName }));
        };
        _this.state = {};
        var self = _this;
        _this.messageHandler = function (ev) {
            // Filter any messages that are not from our API,
            // don't relate to this provider or while
            // the button is not active.
            if (ev.origin != BACKEND_API)
                return;
            if (!_this.props.active)
                return;
            var response = ev.data;
            if (response.provider != _this.props.providerName)
                return;
            self.onReceiveLoginResponse(ev.data);
        };
        return _this;
    }
    LoginButton.prototype.componentDidMount = function () {
        window.addEventListener("message", this.messageHandler, true);
    };
    LoginButton.prototype.componentWillUnmount = function () {
        window.removeEventListener("message", this.messageHandler, true);
    };
    LoginButton.prototype.onReceiveLoginResponse = function (response) {
        if (response.type == "success") {
            // Successfully signed in.
            store.dispatch(logIn({
                provider: this.props.providerName,
                jwtToken: response.jwt,
                user: response.user,
                firstTimeLogin: response.firstTimeLogin,
                tier: response.tier,
            }));
            this.props.onSuccess(response.jwt, response.tier);
            g("SignIn", this.props.providerName);
        }
        else if (response.type == "error") {
            Toast.error(t("login.error.description"), t("login.error.title"));
            throw new Error("Failed to sign in: Error");
        }
        else {
            Toast.error(t("login.error.unknown"), t("login.error.title"));
            throw new Error("Failed to sign in: Unknown response.");
        }
    };
    LoginButton.prototype.onButtonClick = function () {
        var provider = this.props.providerName;
        var loginUrl = BACKEND_API + "/auth/" + provider;
        if (this.props.onClick)
            this.props.onClick();
        var width = 800;
        var height = 660;
        var x = window.outerWidth / 2 + window.screenX - (width / 2);
        var y = window.screenY + 20;
        // IE/EDGE have a problem with permission denied, so use a login_redirect.html.
        var url = isIEOrEdge() ? "safe_redirect.html?to=" + loginUrl : loginUrl;
        var loginWindow = window.open(url, '', "toolbar=no, location=no, directories=no, status=no, menubar=no,\n            scrollbars=no, resizable=no, copyhistory=no\n            width=" + width + " height=" + height + " top=" + y + " left=" + x + "\n            ");
        loginWindow.onunload = function () {
            clog("Log in window closed.");
        };
    };
    return LoginButton;
}(React.Component));
export { LoginButton };
var SignInLabel = function (_a) {
    var providerName = _a.providerName;
    var t = useTranslation().t;
    return React.createElement("span", null, t('login.sign_in_with', { provider: t(loginProviderDisplayName(providerName)) }));
};
