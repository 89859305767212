import { P } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
import { Group, PointText } from "paper";
var Timer = /** @class */ (function () {
    function Timer() {
    }
    Timer.prototype.render = function (appearance) {
        var graphic = new Group([
            SVG.timer().withScale(0.08, P(0, 0)),
            new PointText({
                point: P(45, 30),
                content: appearance.label,
                fillColor: 'black',
                fontFamily: 'sans-serif',
                fontSize: 20,
            })
        ]);
        return { graphic: graphic, hitShape: graphic.bounds.toShape() };
    };
    Timer.properties = {
        label: "Timer / Stopwatch",
        defaultAppearance: {
            label: "1:00"
        },
        appearanceSpecs: [
            {
                spec: "text", key: "label", label: "Label",
            }
        ],
    };
    return Timer;
}());
export { Timer };
