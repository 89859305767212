var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { g } from "analytics";
import { apparatusClass } from "apparatus/library";
import { LIQUID_OPACITY_RANGE } from "apparatus/library/common";
import { UpdateLiquidAlpha, UpdateLiquidAmount, UpdateLiquidColor } from "command/basic";
import { commandProcessor } from "command/command";
import { Button } from "components/button";
import { Icon } from "components/icon";
import { MultiLiquid, UpdateLiquidLayers } from "editing/liquid/multilayer";
import { isLiquidLevelSufficientForDraining, isWithinDrainingAngles, isWithinPouringAngles, LIQUID_DRAINING_MAX_ROTATION_ANGLE, LIQUID_DRAINING_MIN_LIQUID_LEVEL, LIQUID_POURING_MAX_ROTATION_ANGLE, LIQUID_POURING_MIN_ROTATION_ANGLE, UpdateLiquidDrainingEnabled, UpdateLiquidDrainingFade, UpdateLiquidDrainingFlowLength, UpdateLiquidDrainingThinning, UpdateLiquidMeniscusConvex, UpdateLiquidMeniscusEnabled, UpdateLiquidMeniscusRadius, UpdateLiquidPouringEnabled, UpdateLiquidPouringFade, UpdateLiquidPouringFlowLength, UpdateLiquidPouringFlowStrength } from "editing/liquid/surface";
import { t } from "i18next";
import * as React from "react";
import { Articles } from "support/beacon";
var MAX_TOTAL_EXTRA_LAYERS = 4;
var MAX_EXTRA_LAYERS_FOR_FREE_TIER = 1;
export function createSingleLayerLiquidPropsSections(items, app, meniscus) {
    var liquidData = items[0].liquid; // Take liquid data of first item.
    var elements = [
        {
            spec: "slider", label: "Amount",
            min: 0, max: 1, step: 0.001, initialValue: liquidData.amountRatio,
            key: "liquid_amount", unit: "%",
            onChange: function (value) {
                for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
                    var item = items_1[_i];
                    var component = app.getApparatusComponent(item.id);
                    if (!component || !component.state.liquid)
                        continue;
                    component === null || component === void 0 ? void 0 : component.updateLiquidData(__assign(__assign({}, component.state.liquid), { amountRatio: value }));
                }
            },
            onEnd: function (previous, final) {
                g("UpdateAppearance", items[0].type + "::liquid::amount");
                var command = new UpdateLiquidAmount(items.map(function (i) { return ({
                    id: i.id,
                    value: final,
                    previousValue: i.liquid.amountRatio
                }); }));
                commandProcessor.execute(command);
            }
        },
    ];
    // The next section can only be added if there is a single item, or if multiple items all
    // don't have extra layers.
    if (items.length == 1 || items.every(function (i) { var _a; return ((_a = i.liquid) === null || _a === void 0 ? void 0 : _a.layers.length) == 0; })) {
        elements.push({
            spec: "color", label: "Colour",
            key: "liquid_color" + "_" + items[0].id.id,
            value: liquidData.color,
            onColorChange: function (value) {
                for (var _i = 0, items_2 = items; _i < items_2.length; _i++) {
                    var item = items_2[_i];
                    var component = app.getApparatusComponent(item.id);
                    if (!component || !item.liquid)
                        continue;
                    component.updateLiquidData(__assign(__assign({}, item.liquid), { color: value }));
                }
            },
            onColorEnd: function (final) {
                if (final == items[0].liquid.color)
                    return;
                g("UpdateAppearance", items[0].type + "::liquid::colour");
                var command = new UpdateLiquidColor(items.filter(function (i) { return i.liquid !== undefined; }).map(function (i) { return ({
                    id: i.id,
                    value: final,
                    previousValue: i.liquid.color
                }); }));
                commandProcessor.execute(command);
            },
            opacity: {
                value: liquidData.alpha,
                range: LIQUID_OPACITY_RANGE,
                onChange: function (value) {
                    for (var _i = 0, items_3 = items; _i < items_3.length; _i++) {
                        var item = items_3[_i];
                        var component = app.getApparatusComponent(item.id);
                        if (!component || !component.state.liquid)
                            continue;
                        component.updateLiquidData(__assign(__assign({}, component.state.liquid), { alpha: value }));
                    }
                },
                onEnd: function (_, final) {
                    g("UpdateAppearance", items[0].type + "::liquid::alpha");
                    var command = new UpdateLiquidAlpha(items.map(function (i) { return ({
                        id: i.id,
                        value: final,
                        previousValue: i.liquid.alpha
                    }); }));
                    commandProcessor.execute(command);
                }
            }
        });
    }
    var pourElements = [];
    var supportsPouring = items.every(function (i) { return apparatusClass(i.type).properties.pourable; });
    if (supportsPouring && liquidData.pouring.enabled) {
        pourElements.push({
            spec: "slider", label: "Flow length", key: "flow-size", unit: "mm",
            min: 10, max: 400, initialValue: liquidData.pouring.flowLength,
            onChange: function (value) {
                for (var _i = 0, items_4 = items; _i < items_4.length; _i++) {
                    var item = items_4[_i];
                    var component = app.getApparatusComponent(item.id);
                    if (!component || !component.state.liquid)
                        continue;
                    component.updateLiquidData(__assign(__assign({}, component.state.liquid), { pouring: __assign(__assign({}, component.state.liquid.pouring), { flowLength: value }) }));
                }
            },
            onEnd: function (_, final) {
                commandProcessor.execute(new UpdateLiquidPouringFlowLength(items.map(function (i) { return ({
                    id: i.id, value: final, previousValue: i.liquid.pouring.flowLength,
                }); })));
            }
        }, {
            spec: "slider", label: "Flow strength", key: "flow-size", unit: "%",
            min: 0, max: 1, step: 0.01, initialValue: liquidData.pouring.flowStrength,
            onChange: function (value) {
                for (var _i = 0, items_5 = items; _i < items_5.length; _i++) {
                    var item = items_5[_i];
                    var component = app.getApparatusComponent(item.id);
                    if (!component || !component.state.liquid)
                        continue;
                    component.updateLiquidData(__assign(__assign({}, component.state.liquid), { pouring: __assign(__assign({}, component.state.liquid.pouring), { flowStrength: value }) }));
                }
            },
            onEnd: function (_, final) {
                commandProcessor.execute(new UpdateLiquidPouringFlowStrength(items.map(function (i) { return ({
                    id: i.id, value: final, previousValue: i.liquid.pouring.flowStrength,
                }); })));
            }
        }, {
            spec: "checkbox-group", label: "Fading", key: "pouring-fading",
            checkboxes: [
                {
                    label: "", value: liquidData.pouring.fade, id: "pouring-fading",
                    onCheckedChange: function (value) {
                        commandProcessor.execute(new UpdateLiquidPouringFade(items.map(function (i) { return ({
                            id: i.id, value: value, previousValue: i.liquid.pouring.fade,
                        }); })));
                    }
                }
            ]
        });
        // Show warning if set up is incorrect.
        if (!isWithinPouringAngles(items[0].rotation)) {
            pourElements.push(React.createElement(WarningHint, { key: "pour-warn", text: t('info.pour_warn_angle', { angle1: LIQUID_POURING_MIN_ROTATION_ANGLE, angle2: LIQUID_POURING_MAX_ROTATION_ANGLE }) }));
        }
        // Show warning if multiple layers are used.
        if (liquidData.layers.length > 0) {
            pourElements.push(React.createElement(WarningHint, { key: "pour-warn-2", text: t('info.pour_warn_layers') }));
        }
    }
    var meniscusElements = [];
    if (meniscus && liquidData.meniscus.enabled) {
        meniscusElements.push({
            spec: "toggle-horizontal-buttons", label: "Type", key: "meniscus-type",
            size: "small", highlightIndex: liquidData.meniscus.convex ? 1 : 0,
            buttons: [
                {
                    buttonLabel: "Concave", icon: "k,meniscus-concave", onClick: function () {
                        commandProcessor.execute(new UpdateLiquidMeniscusConvex(items.map(function (i) { return ({
                            id: i.id, value: false, previousValue: i.liquid.meniscus.convex,
                        }); })));
                    }
                },
                {
                    buttonLabel: "Convex", icon: "k,meniscus-convex", onClick: function () {
                        commandProcessor.execute(new UpdateLiquidMeniscusConvex(items.map(function (i) { return ({
                            id: i.id, value: true, previousValue: i.liquid.meniscus.convex,
                        }); })));
                    }
                },
            ]
        }, {
            spec: "slider", label: "Radius", key: "meniscus-radius", unit: "mm",
            min: 2, max: 20, step: 1, initialValue: liquidData.meniscus.radius,
            onChange: function (radius) {
                for (var _i = 0, items_6 = items; _i < items_6.length; _i++) {
                    var item = items_6[_i];
                    var component = app.getApparatusComponent(item.id);
                    if (!component || !component.state.liquid)
                        continue;
                    component.updateLiquidData(__assign(__assign({}, component.state.liquid), { meniscus: __assign(__assign({}, component.state.liquid.meniscus), { radius: radius }) }));
                }
            },
            onEnd: function (_, final) {
                commandProcessor.execute(new UpdateLiquidMeniscusRadius(items.map(function (i) { return ({
                    id: i.id, value: final, previousValue: i.liquid.meniscus.radius,
                }); })));
            }
        });
        // Show warning if set up is incorrect.
        if (items[0].rotation != 0) {
            meniscusElements.push(React.createElement(WarningHint, { key: "meniscus-warn", text: t("info.meniscus_hint") }));
        }
    }
    var drainingElements = [];
    var supportsDraining = items.every(function (i) { return apparatusClass(i.type).properties.drainable; });
    if (supportsDraining && liquidData.draining.enabled) {
        drainingElements.push({
            spec: "slider", label: "Flow length", key: "drain-flow-length", unit: "mm",
            min: 20, max: 250, initialValue: liquidData.draining.flowLength,
            onChange: function (value) {
                for (var _i = 0, items_7 = items; _i < items_7.length; _i++) {
                    var item = items_7[_i];
                    var component = app.getApparatusComponent(item.id);
                    if (!component || !component.state.liquid)
                        continue;
                    component.updateLiquidData(__assign(__assign({}, component.state.liquid), { draining: __assign(__assign({}, component.state.liquid.draining), { flowLength: value }) }));
                }
            },
            onEnd: function (_, final) {
                commandProcessor.execute(new UpdateLiquidDrainingFlowLength(items.map(function (i) { return ({
                    id: i.id, value: final, previousValue: i.liquid.draining.flowLength,
                }); })));
            }
        }, {
            spec: "checkbox-group", label: "Options", key: "draining-fading",
            checkboxes: [
                {
                    label: "Fade", value: liquidData.draining.fade, id: "draining-fading",
                    onCheckedChange: function (value) {
                        commandProcessor.execute(new UpdateLiquidDrainingFade(items.map(function (i) { return ({
                            id: i.id, value: value, previousValue: i.liquid.draining.fade,
                        }); })));
                    }
                },
                {
                    label: "Thinning", value: liquidData.draining.thinning, id: "draining-thinning",
                    onCheckedChange: function (value) {
                        commandProcessor.execute(new UpdateLiquidDrainingThinning(items.map(function (i) { return ({
                            id: i.id, value: value, previousValue: i.liquid.draining.thinning,
                        }); })));
                    }
                }
            ]
        });
        // Show warning if set up is incorrect.
        if (!isWithinDrainingAngles(items[0].rotation)) {
            drainingElements.push(React.createElement(WarningHint, { key: "meniscus-warn", text: "The rotation must be less than " + LIQUID_DRAINING_MAX_ROTATION_ANGLE + "\u00B0." }));
        }
        if (!isLiquidLevelSufficientForDraining(items[0].liquid)) {
            drainingElements.push(React.createElement(WarningHint, { key: "meniscus-warn", text: "Set the liquid amount to at least " + Math.round(LIQUID_DRAINING_MIN_LIQUID_LEVEL * 100) + "%." }));
        }
    }
    return [
        {
            hideHeaderIfFirst: true,
            icon: "tint",
            header: "Liquid",
            elements: elements,
        },
        // Pouring section.
        {
            icon: "fill-drip",
            header: "Pouring",
            elements: pourElements,
            helpArticleId: Articles.POURING,
            toggle: supportsPouring ? {
                id: "pouring-enabled",
                value: liquidData.pouring.enabled,
                onCheckedChange: function (value) {
                    commandProcessor.execute(new UpdateLiquidPouringEnabled(items.map(function (i) { return ({
                        id: i.id, value: value, previousValue: i.liquid.pouring.enabled,
                    }); })));
                }
            } : undefined
        },
        // Draining section.
        {
            header: "Draining",
            icon: "filter",
            elements: drainingElements,
            toggle: supportsDraining ? {
                id: "draining-enabled",
                value: liquidData.draining.enabled,
                onCheckedChange: function (value) {
                    commandProcessor.execute(new UpdateLiquidDrainingEnabled(items.map(function (i) { return ({
                        id: i.id, value: value, previousValue: i.liquid.draining.enabled,
                    }); })));
                }
            } : undefined
        },
        // Meniscus.
        {
            icon: "k,meniscus-concave",
            header: "Meniscus",
            elements: meniscusElements,
            helpArticleId: Articles.MENISCUS,
            toggle: meniscus ? {
                id: "meniscus-enabled",
                value: liquidData.meniscus.enabled,
                onCheckedChange: function (value) {
                    commandProcessor.execute(new UpdateLiquidMeniscusEnabled(items.map(function (i) { return ({
                        id: i.id, value: value, previousValue: i.liquid.meniscus.enabled,
                    }); })));
                }
            } : undefined
        }
    ];
}
export function createLiquidPropsSection(item, liquidData, app, meniscus, tiers) {
    var _a;
    var elements = [];
    var _loop_1 = function (i) {
        var layer = liquidData.layers[i];
        var index = i;
        elements.push(React.createElement("hr", { key: "liquid_hr_" + i }), React.createElement("h4", { key: "liquid_label_" + i }, t("ui.layer_n", { count: liquidData.layers.length - i })));
        elements.push({
            spec: "slider", label: "Position",
            min: 0, max: 1, step: 0.01, initialValue: layer.position,
            key: "liquid_position_" + i, unit: "%",
            onChange: function (value) {
                // CALLBACK! Do NOT refer to "i".
                var component = app.getApparatusComponent(item.id);
                if (!component || !component.state.liquid)
                    return;
                var newLayers = MultiLiquid.adjustNumeric(liquidData.layers, index, "position", value);
                component.updateLiquidData(__assign(__assign({}, component.state.liquid), { layers: newLayers }));
            },
            onEnd: function (previous, final) {
                if (previous == final)
                    return;
                // CALLBACK! Do NOT refer to "i".
                var newLayers = MultiLiquid.adjustNumeric(liquidData.layers, index, "position", final);
                commandProcessor.execute(new UpdateLiquidLayers(item.id, newLayers, liquidData.layers));
            }
        }, {
            spec: "color", label: "Colour",
            key: "liquid_color_" + item.id.id + "_" + i,
            value: layer.color,
            onColorChange: function (value) {
                // CALLBACK! Do NOT refer to "i".
                var component = app.getApparatusComponent(item.id);
                if (!component || !component.state.liquid)
                    return;
                var newLayers = MultiLiquid.adjustString(liquidData.layers, index, "color", value);
                component.updateLiquidData(__assign(__assign({}, component.state.liquid), { layers: newLayers }));
            },
            onColorEnd: function (final) {
                // Careful here! We need to compare lowercase because the output
                // will be uppercase from the bootstrap color picker! Also
                // onColorEnd is called when the component is unmounted, so it's
                // possible that this layer no longer exists. Be careful!
                if (final.toLowerCase() == layer.color.toLowerCase())
                    return;
                // CALLBACK! Do NOT refer to "i".
                var newLayers = MultiLiquid.adjustString(liquidData.layers, index, "color", final);
                commandProcessor.execute(new UpdateLiquidLayers(item.id, newLayers, liquidData.layers));
            },
            opacity: {
                value: layer.alpha,
                range: LIQUID_OPACITY_RANGE,
                onChange: function (value) {
                    // CALLBACK! Do NOT refer to "i".
                    var component = app.getApparatusComponent(item.id);
                    if (!component || !component.state.liquid)
                        return;
                    var newLayers = MultiLiquid.adjustNumeric(liquidData.layers, index, "alpha", value);
                    component.updateLiquidData(__assign(__assign({}, component.state.liquid), { layers: newLayers }));
                },
                onEnd: function (previous, final) {
                    if (previous == final)
                        return;
                    // CALLBACK! Do NOT refer to "i".
                    var newLayers = MultiLiquid.adjustNumeric(liquidData.layers, index, "alpha", final);
                    commandProcessor.execute(new UpdateLiquidLayers(item.id, newLayers, liquidData.layers));
                }
            }
        }, {
            spec: "slider", label: "Blending",
            min: 0, max: 1, step: 0.01, initialValue: layer.blending,
            key: "liquid_blending_" + i, unit: "%",
            onChange: function (value) {
                // CALLBACK! Do NOT refer to "i".
                var component = app.getApparatusComponent(item.id);
                if (!component || !component.state.liquid)
                    return;
                var newLayers = MultiLiquid.adjustNumeric(liquidData.layers, index, "blending", value);
                component.updateLiquidData(__assign(__assign({}, component.state.liquid), { layers: newLayers }));
            },
            onEnd: function (previous, final) {
                if (previous == final)
                    return;
                // CALLBACK! Do NOT refer to "i".
                var newLayers = MultiLiquid.adjustNumeric(liquidData.layers, index, "blending", final);
                commandProcessor.execute(new UpdateLiquidLayers(item.id, newLayers, liquidData.layers));
            }
        });
    };
    // Present the layers in reverse order, so that it visually matches
    // with how the layers are rendered.
    for (var i = liquidData.layers.length - 1; i >= 0; i--) {
        _loop_1(i);
    }
    var buttons = [];
    var currentExtraLayers = liquidData.layers.length;
    if (currentExtraLayers < MAX_TOTAL_EXTRA_LAYERS) {
        buttons.push(React.createElement(Button, { buttonLabel: "Add layer", icon: "plus", key: "addLayer", tier: currentExtraLayers < MAX_EXTRA_LAYERS_FOR_FREE_TIER ? undefined : "boost", onClick: function () {
                g("UpdateAppearance", item.type + "::liquid::add_layer");
                var newLayers = MultiLiquid.addLayer(liquidData.layers);
                commandProcessor.execute(new UpdateLiquidLayers(item.id, newLayers, liquidData.layers));
            } }));
    }
    if (liquidData.layers.length === 0) {
        buttons.push(React.createElement(Button, { buttonLabel: "Quick blend", icon: "fill-drip", key: "quick_blend", onClick: function () {
                g("UpdateAppearance", item.type + "::liquid::quick_blend");
                var newLayers = MultiLiquid.createSimpleGradient(liquidData.color, liquidData.alpha);
                commandProcessor.execute(new UpdateLiquidLayers(item.id, newLayers, liquidData.layers));
            } }));
    }
    if (liquidData.layers.length > 0) {
        buttons.push(React.createElement(Button, { buttonLabel: "Remove layer", icon: "minus", key: "removeLayer", onClick: function () {
                g("UpdateAppearance", item.type + "::liquid::remove_layer");
                var newLayers = MultiLiquid.removeBottomLayer(liquidData.layers);
                commandProcessor.execute(new UpdateLiquidLayers(item.id, newLayers, liquidData.layers));
            } }));
    }
    if (buttons.length > 0) {
        elements.push(React.createElement("div", { className: "flex-group", key: "liquidLayerButtons" },
            React.createElement("div", { className: "label" }),
            React.createElement("div", { className: "verticalButtonGroup mt-2", key: "liquidLayerButtons" }, buttons)));
    }
    // Attach elements to the first section.
    var panels = createSingleLayerLiquidPropsSections([item], app, meniscus);
    (_a = panels[0].elements).push.apply(_a, elements);
    return panels;
}
var WarningHint = function (props) {
    return React.createElement("div", { className: "boxed-hint border-warning text-warning d-flex" },
        React.createElement(Icon, { name: "exclamation-triangle", extraClass: "mr-2 mt-1" }),
        props.text);
};
