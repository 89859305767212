var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { addEdgeLips, mirrorX, setDefaultStyle, smoothCornersIndividual } from "apparatus/draw";
import { DEFAULT_LIQUID_DATA, P, Pivot, R, Segments } from "apparatus/library/common";
import { SharedAppearance } from "apparatus/shared/appearance";
import { Group, Path } from "paper";
var TLCChamber = /** @class */ (function () {
    function TLCChamber() {
    }
    TLCChamber.prototype.render = function (appearance) {
        var size = appearance.size;
        var path = new Path(Segments([[-size * 0.4, 0]], [[-size * 0.4, size]], [[size * 0.4, size]], [[size * 0.4, 0]]));
        setDefaultStyle(path);
        smoothCornersIndividual(path, { 1: 5, 2: 5 });
        addEdgeLips(path);
        var liquidMask = path.clone();
        var graphic = new Group([path]);
        if (appearance.lid == "flat") {
            graphic.addChild(new Path.Line(P(-size * 0.5, 0), P(size * 0.5, 0)).withStroke("thick"));
        }
        else if (appearance.lid == "handle") {
            var lid = new Path(Segments([[-path.strokeBounds.topLeft.x - 1, 10]], [[-path.strokeBounds.topLeft.x - 1, 0]])).withStroke("default");
            mirrorX(lid);
            var handle = new Path.Rectangle(R(0, 0, 18, 9, Pivot.BOTTOM_CENTER)).withStroke("default");
            smoothCornersIndividual(handle, { 1: 2, 2: 2 });
            graphic.addChildren([handle, lid]);
        }
        if (appearance.view == "zoom") {
            var pivot = path.bounds.center;
            graphic.scale(4.0, pivot);
            liquidMask.scale(4.0, pivot);
        }
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            pivot: path.bounds.center,
            hitShape: graphic.bounds.toShape(),
            snapping: path.placeable(),
        };
    };
    TLCChamber.properties = {
        label: "Chromatography Chamber",
        canContainLiquids: true,
        defaultLiquidData: __assign(__assign({}, DEFAULT_LIQUID_DATA), { amountRatio: 0.1 }),
        defaultAppearance: {
            view: "zoom",
            size: 100,
            lid: "flat"
        },
        appearanceSpecs: [
            SharedAppearance.viewRealZoom,
            {
                spec: "slider", key: "size", label: "Size",
                min: 80, max: 150, unit: "mm",
            },
            {
                spec: "radio", key: "lid", label: "Lid",
                options: [
                    { value: "none", label: "None" },
                    { value: "flat", label: "Flat" },
                    { value: "handle", label: "With handle" },
                ],
            }
        ],
    };
    return TLCChamber;
}());
export { TLCChamber };
