import { breakPath, setDefaultStyle, smoothCorner } from "apparatus/draw";
import { DEFAULT_LIQUID_DATA, P, Segments } from "apparatus/library/common";
import { Path } from "paper";
var Potometer = /** @class */ (function () {
    function Potometer() {
    }
    Potometer.prototype.render = function (appearance) {
        var length = appearance.length;
        var path = new Path(Segments([[0, -80]], [[0, 0]], [[130 + length, 0]], [[130 + length, 35]], [[130 + length + 10, 35]], [[130 + length + 10, -10]], [[120, -10]], [[120, -50]], [[130, -70], [0, 10]], [[130, -120]], [[100, -120]], [[100, -70], , [0, 10]], [[110, -50]], [[110, -10]], [[50, -10]], [[50, -80]]));
        smoothCorner(path.segments[14], 5);
        smoothCorner(path.segments[5], 10);
        smoothCorner(path.segments[2], 5);
        smoothCorner(path.segments[1], 10);
        var liquid = path.clone();
        var hitShape = path.clone();
        var graphic = breakPath(path, 6, 13);
        setDefaultStyle(graphic);
        // Adjust the liquid level
        liquid.segments[13].point.y += 10;
        liquid.segments[12].point.y += 10;
        liquid.fillColor = DEFAULT_LIQUID_DATA.color.color().alpha(DEFAULT_LIQUID_DATA.alpha).string();
        graphic.insertChild(0, liquid);
        return {
            graphic: graphic,
            hitShape: hitShape,
            watermark: P(-5, -10)
        };
    };
    Potometer.properties = {
        label: "Potometer",
        flippable: true,
        defaultAppearance: {
            length: 160
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "length", label: "Length",
                min: 100, max: 300, unit: "mm"
            }
        ],
    };
    return Potometer;
}());
export { Potometer };
