var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { g } from "analytics";
import { unitVector } from "apparatus/draw";
import { stepLeq } from "apparatus/library/common";
import ArrowTool from "arrows/tool";
import { commandProcessor } from "command/command";
import { Move } from "editing/move";
import MultiTool from "editing/multitool";
import { PathEditTool } from "editing/pathedit/tool";
import { RotationOverlay } from "editing/rotation/rotation_overlay";
import { ZoomSlider } from "editing/zoom";
import { FreehandTool } from "freehand/tool";
import { focusMainApp, Keys, keyToDirection, registerKeyboardShortcutHandler } from "keyboard";
import { EditorCanvasComponent } from "layout/editor/editor_canvas";
import { SelectionOverlay } from "layout/editor/selection_overlay";
import * as React from "react";
import { ID } from "store/id";
import store from "store/store";
import { convertFromPaperToHTMLCoordinates } from "types";
import { PanTool } from "view/pan";
import { add } from "../../types";
/*

Zoom      25 ---  50 --- 100 --- 200 --- 300

Small     10       5       2      1       0.5
Medium    50      20      10      5       1
Large    200     100      50     25      15

*/
/** How much to multiply the distance by when moving by small increments. */
var moveByKeyDistanceSmall = function (zoom) { return stepLeq(zoom, [[0.33, 10], [0.75, 5], [1.5, 2], [2.5, 1]], 0.5); };
/** How many pixels to move by when moving items using keyboard. */
var moveByKeyDistanceNormal = function (zoom) { return stepLeq(zoom, [[0.33, 50], [0.75, 20], [1.5, 10], [2.5, 5]], 1); };
/** How much to multiply the distance by when moving by large increments. */
var moveByKeyDistanceLarge = function (zoom) { return stepLeq(zoom, [[0.33, 200], [0.75, 100], [1.5, 50], [2.5, 25]], 15); };
/**
 * Container for the area that contains the editor, i.e.
 * the canvas, zoom slider, property panel but e.g.
 * not the sidebar.
 */
var EditorContainer = /** @class */ (function (_super) {
    __extends(EditorContainer, _super);
    function EditorContainer(props) {
        var _this = _super.call(this, props) || this;
        /** Ref to the canvas editor. */
        _this.editorCanvasRef = React.createRef();
        /** Ref to the rotation overlay. */
        _this.rotationOverlayRef = React.createRef();
        /** Ref to the selection overlay. */
        _this.selectionOverlayRef = React.createRef();
        _this.state = {
            isInteracting: false,
            isEditingText: false,
        };
        return _this;
    }
    EditorContainer.prototype.componentDidMount = function () {
        var _this = this;
        var editorCanvas = this.editorCanvasRef.current;
        // Update zoom.
        this.setZoom(this.props.zoom);
        // Set up tools and activate.
        this.arrowTool = new ArrowTool(editorCanvas.layers, this),
            this.allTools = {
                select: new MultiTool(editorCanvas.layers, this),
                arrow: this.arrowTool,
                pan: new PanTool(this),
                freehand: new FreehandTool(this),
                pathedit: new PathEditTool(this)
            };
        // Activate tool.
        this.currentTool = this.allTools[this.props.tool];
        this.currentTool.activate();
        // Set up keyboard shortcuts.
        registerKeyboardShortcutHandler(function (e) {
            // Moving by key. Hold shift to make big jumps.
            if (e.which >= Keys.LEFT && e.which <= Keys.DOWN) {
                var direction = keyToDirection(e.which);
                var distanceFn = ((e.ctrlKey || e.metaKey) && e.shiftKey)
                    ? moveByKeyDistanceSmall
                    : (e.shiftKey
                        ? moveByKeyDistanceLarge
                        : moveByKeyDistanceNormal);
                var distance = distanceFn(_this.props.zoom);
                _this.moveByKey(direction, distance);
            }
        });
    };
    // TODO: Clean this up, this is needed to compute the selection
    // box correctly but does not grant access to arrows.
    EditorContainer.prototype.getApparatusComponent = function (id) {
        return this.editorCanvasRef.current.getApparatusComponent(id);
    };
    EditorContainer.prototype.getObjectComponent = function (id) {
        return this.editorCanvasRef.current.getObjectComponent(id);
    };
    EditorContainer.prototype.setArrow = function (data) {
        this.editorCanvasRef.current.setState({
            arrow: data
        });
    };
    EditorContainer.prototype.setRenderArea = function (delta) {
        if (this.editorCanvasRef.current.renderAreaRef.current) {
            this.editorCanvasRef.current.renderAreaRef.current.moveByDelta(delta);
        }
    };
    EditorContainer.prototype.componentDidUpdate = function (prevProps) {
        var _this = this;
        // Activate current tool, if changed.
        if (prevProps.tool != this.props.tool) {
            this.currentTool = this.allTools[this.props.tool];
            this.currentTool.activate();
        }
        // Update the selection and rotation overlays.
        var selectionOverlay = this.selectionOverlayRef.current;
        var selectedItems = this.props.selected.items;
        var selectionMetrics = this.editorCanvasRef.current.computeSelectionMetrics();
        // Notify rotation handler of components corresponding to currently selected items.
        selectionOverlay.rotationHandler.selection = selectionMetrics;
        selectionOverlay.rotationHandler.selectedApparatus = selectedItems.map(function (item) { return _this.getApparatusComponent(item.id); }).filter(function (a) { return a !== null; });
        selectionOverlay.rotationHandler.selectedArrows = this.props.selected.arrows.map(function (a) { return (_this.getObjectComponent(a.id)); });
        // Convert from Paper to HTML coordinates. These are used by the
        // overlay components that are in HTML DOM rather than canvas.
        var convertedMetrics = convertFromPaperToHTMLCoordinates(selectionMetrics);
        // Update state on the selection overlay.
        this.selectionOverlayRef.current.setState({
            isVisible: !this.props.selected.isEmpty,
            center: convertedMetrics.pivot,
            bounds: convertedMetrics.bounds,
        });
        // Update state on the rotation overlay.
        this.rotationOverlayRef.current.setState({
            pivot: convertedMetrics.pivot
        });
    };
    EditorContainer.prototype.render = function () {
        var _this = this;
        // Resolve final interaction state from multiple states.
        var isInteracting = this.state.isInteracting;
        if (!isInteracting && this.state.isEditingText) {
            isInteracting = "general";
        }
        return React.createElement("div", { id: "editor-container", key: "editorContainer" },
            React.createElement(EditorCanvasComponent, __assign({}, this.props, { key: "editor_canvas", tool: this.props.tool, ref: this.editorCanvasRef, onPaperReady: this.props.onPaperReady, isInteracting: isInteracting })),
            React.createElement(RotationOverlay, { key: "rotationOverlay", ref: this.rotationOverlayRef }),
            React.createElement(SelectionOverlay, { key: "selectionOverlay", ref: this.selectionOverlayRef, hideDueToInteraction: isInteracting !== false, onStartRotation: function (t) { return _this.onStartRotation(t); }, onUpdateRotation: function (a) { return _this.onUpdateRotation(a); }, onEndRotation: function () { return _this.onEndRotation(); } }),
            React.createElement(ZoomSlider, { key: "zoomSlider", zoom: this.props.zoom, onStartZoom: function () { return _this.onStartZoom(); }, onUpdateZoom: function (z) { return _this.onUpdateZoom(z); }, onEndZoom: function () { return _this.onEndZoom(); } }));
    };
    // Temporarily sets the zoom to this value.
    EditorContainer.prototype.setZoom = function (zoom) {
        var canvasEditor = this.editorCanvasRef.current;
        if (canvasEditor) {
            canvasEditor.setZoom(zoom);
        }
    };
    EditorContainer.prototype.onStartRotation = function (type) {
        this.setIsInteracting(true);
        this.rotationOverlayRef.current.setState({
            type: type,
            angle: 0
        });
    };
    EditorContainer.prototype.onUpdateRotation = function (angle) {
        this.rotationOverlayRef.current.setState({ angle: angle });
    };
    EditorContainer.prototype.onEndRotation = function () {
        this.setIsInteracting(false);
        this.rotationOverlayRef.current.setState({ type: undefined });
        focusMainApp();
    };
    // Zoom.
    EditorContainer.prototype.onStartZoom = function () {
        this.setState({ isInteracting: "zoom" });
    };
    EditorContainer.prototype.onUpdateZoom = function (zoom) {
        this.editorCanvasRef.current.setZoom(zoom);
    };
    EditorContainer.prototype.onEndZoom = function () {
        this.setState({ isInteracting: false });
    };
    EditorContainer.prototype.setIsInteracting = function (isInteracting) {
        this.setState({ isInteracting: isInteracting ? "general" : false });
    };
    EditorContainer.prototype.setIsMovingItems = function (isMoving) {
        this.setState({ isInteracting: isMoving ? "move" : false });
    };
    EditorContainer.prototype.setIsEditingText = function (value) {
        this.setState({ isEditingText: value });
    };
    EditorContainer.prototype.moveByKey = function (direction, distance) {
        var selectedItemIds = store.selectedObjectsID();
        if (selectedItemIds.length == 0)
            return;
        g("MoveByKey");
        var delta = unitVector(direction).multiply(distance);
        commandProcessor.executeReplacingLastCommand(function (lastCommand) {
            if (!(lastCommand instanceof Move))
                return false;
            if (!ID.equals(lastCommand.ids, selectedItemIds))
                return false;
            var combinedDelta = add(delta, lastCommand.delta);
            return new Move(selectedItemIds, combinedDelta);
        }, new Move(selectedItemIds, delta));
    };
    /** Exports current canvas contents to various image formats. */
    EditorContainer.prototype.exportImages = function () {
        return this.editorCanvasRef.current.export();
    };
    EditorContainer.prototype.exportThumbnail = function () {
        return this.editorCanvasRef.current.exportThumbnail();
    };
    EditorContainer.prototype.setArrowTemplate = function (data) {
        this.arrowTool.updateTemplateArrow(data);
    };
    EditorContainer.prototype.getProjectBounds = function () {
        return this.editorCanvasRef.current.getProjectBounds();
    };
    return EditorContainer;
}(React.Component));
export { EditorContainer };
