var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { P } from "apparatus/library/common";
import { DEMO_COLOUR_SWATCHES, FONT_FAMILY_OPTIONS, TEXT_COLOUR_SWATCHES } from "apparatus/text/appearance";
import { Group, PointText, Shape } from "paper";
import { appStore } from "store/store";
import { ChemMark } from "text/parse";
import { AdvancedText } from "../../../text/render";
var Label = /** @class */ (function () {
    function Label() {
        this.advancedText = new AdvancedText();
        this.advancedText2 = new AdvancedText();
    }
    Label.prototype.render = function (appearance) {
        var fontFamily = appearance.fontFamily, fontSize = appearance.fontSize, textColor = appearance.textColor, text = appearance.text, text2 = appearance.text2, isChemMarkEnabled = appearance.isChemMarkEnabled;
        var textItem1 = this.renderText(this.advancedText, text, fontFamily, fontSize, textColor, P(0, 0), isChemMarkEnabled);
        var graphic = new Group([textItem1]);
        // Add a second, subtitle line. Note the font size is slightly smaller.
        if (appearance.text2.length > 0) {
            var textItem2 = this.renderText(this.advancedText2, text2, fontFamily, fontSize * 0.8, textColor, P(0, fontSize * 2), isChemMarkEnabled);
            graphic.addChild(textItem2);
            // To center, shift each item by half its width. Only if chemMark is not enabled.
            if (!isChemMarkEnabled) {
                textItem1.position.x -= textItem1.bounds.width / 2;
                textItem2.position.x -= textItem2.bounds.width / 2;
            }
        }
        if (appearance.showBorder) {
            var radius = appearance.roundedCorners ? 4.0 : 0.0;
            var sticker = Shape.Rectangle(graphic.bounds.expand(10.0), radius);
            sticker.fillColor = "#ffffffcc"; // Transparent white
            sticker.strokeColor = "#444444";
            sticker.strokeWidth = 3.0;
            graphic.insertChild(0, sticker);
        }
        return { graphic: graphic, hitShape: graphic.rectHitShape(), watermark: graphic.bounds.bottomRight };
    };
    Label.prototype.renderText = function (field, text, fontFamily, fontSize, color, position, isChemMarkEnabled) {
        var sharedStyle = {
            fontFamily: fontFamily,
            fontSize: fontSize * 2,
            fillColor: color,
        };
        if (isChemMarkEnabled) {
            // TODO: This is hacky, accessing appstore directly for tier.
            var config = appStore.getState().chemMark;
            var formattedText = appStore.getState().tier ?
                ChemMark.parseSmart(text, config) :
                ChemMark.parseSmartLimited(text, config);
            var style = __assign(__assign({}, sharedStyle), { textAlign: "left" });
            field.setText(formattedText, style);
            field.setPosition(position.x, position.y);
            return field.container;
        }
        else {
            return new PointText(__assign({ point: position, content: text }, sharedStyle));
        }
    };
    Label.properties = {
        label: "Label",
        chemMarkAppearanceKey: "isChemMarkEnabled",
        defaultAppearance: {
            text: "Label",
            text2: "",
            fontSize: 10,
            fontFamily: "sans-serif",
            isChemMarkEnabled: false,
            textColor: "#000000",
            textAlign: "left",
            showBorder: true,
            roundedCorners: false,
        },
        appearanceSpecs: [
            {
                spec: "text", key: "text", label: "Label text",
                maxLength: 100, enableReplacements: true,
            },
            {
                spec: "text", key: "text2", label: "Subtitle text",
                maxLength: 100, enableReplacements: true, tier: "boost",
            },
            {
                spec: "slider", key: "fontSize", label: "Text size",
                min: 4, max: 16, step: 0.5
            },
            {
                spec: "select", key: "fontFamily", label: "Font",
                options: FONT_FAMILY_OPTIONS
            },
            {
                spec: "color", key: "textColor", label: "Text colour",
                swatches: TEXT_COLOUR_SWATCHES,
                tier: "boost", demoSwatches: DEMO_COLOUR_SWATCHES,
            },
            {
                spec: "checkbox-group", label: "Border", checkboxes: [
                    { key: "showBorder", label: "Show", tier: "boost" },
                    { key: "roundedCorners", label: "Round", tier: "boost", visible: function (a) { return a.showBorder; } },
                ]
            }
        ],
        isBoostRequired: function (a) { return a.isChemMarkEnabled && a.text.length > ChemMark.FREE_TIER_CHAR_LIMIT; }
    };
    Label.convertToLatest = convertToLatest;
    return Label;
}());
export { Label };
function convertToLatest(a) {
    return __assign(__assign({}, Label.properties.defaultAppearance), a);
}
