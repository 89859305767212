var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// Mixin that extends an existing class, to be Interactable.
// Usage: Interactable(paper.Shape, this)(...)
export function Interactable(Base, interaction) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var _this = _super.apply(this, args) || this;
            _this.interaction = interaction;
            return _this;
        }
        return class_1;
    }(Base));
}
export function graftInteraction(item, interaction) {
    item.interaction = interaction;
}
// Match function that can be used for paper's hitTest or getItems
// to retrieve an interactable.
export function InteractableMatcher(hit) {
    var item = ('item' in hit ? hit.item : hit);
    return 'interaction' in item && item.visible === true;
}
// Helper method to create a selectAndMove interaction
export function SelectAndMove(c) {
    return { type: "selectAndMove", component: c };
}
// Helper method to create an adjustable interaction.
export function Adjustable(c) {
    return { type: "adjust", component: c };
}
