var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { mirrorX, strokeWidth } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { interpolate, P, Pivot, R, Segments } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
import { Group, Path, Shape } from "paper";
import { staticImplements } from "types";
var HeatingMantle = /** @class */ (function () {
    function HeatingMantle() {
    }
    HeatingMantle.prototype.render = function (appearance) {
        var graphic;
        if (appearance.transparency === "none") {
            graphic = SVG.mantle();
        }
        else {
            var mantle = SVG.mantle();
            var mask = mantle.bounds.toShape().toPath();
            var masking = void 0;
            if (appearance.transparency === "clip") {
                var whiteout = Shape.Circle(mantle.bounds.topCenter.add(P(0, 30)), 90);
                whiteout.setRadialGradientFill([["#ffffffdd", 0.7], ["#ffffff00", 1.0]]);
                masking = new Group([mask, whiteout]);
                masking.clipped = true;
                graphic = new Group([mantle, masking]);
            }
            else {
                var cutOut = new Path(Segments([[65, 0]], [[65, 30], , [0, 30]], [[0, 90], [30, 0]]));
                mirrorX(cutOut);
                cutOut.position.x += 87;
                cutOut.fillColor = "white";
                cutOut.withStroke(strokeWidth("default"), "#aaaaaa");
                graphic = new Group([mantle, cutOut]);
            }
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: graphic.placeable(),
        };
    };
    HeatingMantle.properties = {
        label: "Heating Mantle",
        defaultAppearance: {
            transparency: "none"
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "transparency", label: "Display",
                options: [
                    { value: "none", label: "Normal" },
                    { value: "clip", label: "Transparent", tier: "boost" },
                    { value: "xsection", label: "Cross-section", tier: "boost" },
                ],
                allowSelectingTierLockedOptions: true,
            }
        ],
        isBoostRequired: function (a) { return a.transparency === "clip" || a.transparency == "xsection"; }
    };
    return HeatingMantle;
}());
export { HeatingMantle };
var SoftHeatingMantle = /** @class */ (function () {
    function SoftHeatingMantle() {
    }
    SoftHeatingMantle.prototype.render = function (appearance) {
        var innerRadius = appearance.diameter / 2;
        var thickness = 20;
        // For small size, should be "= radius", to half radius for larger sizes.
        var height = interpolate(innerRadius, [40, 100], [40, 60]) + thickness;
        var outerRadius = innerRadius + thickness;
        // Pivot = Origin is at the bottom.
        // Shell
        var outerShell1 = new Path.Circle(P(0, -outerRadius), outerRadius);
        var mask = new Path.Rectangle(R(0, 0, 400, height * 0.75, Pivot.BOTTOM_CENTER));
        var outerShell2 = outerShell1.intersect(mask);
        outerShell1.remove();
        outerShell2.remove();
        mask.remove();
        var innerCircleMask = new Path.Circle(P(0, -outerRadius), innerRadius);
        if (appearance.view == "xsection") {
            outerShell2 = outerShell2.subtract(innerCircleMask);
        }
        var outerShell = outerShell2;
        var outerShellShade = outerShell.clone();
        outerShell
            .withStroke("default", "#B59C86")
            .setGradientFill("right-down", Gradients.cylinder("#D6B89E".color(), 3));
        outerShellShade.withGradientFill("down", [["#00000020", 0.0], ["#00000000", 0.3]]);
        var graphic = new Group([outerShell, outerShellShade]);
        // Top part.
        var topPart = new Path.Rectangle(R(0, -height, outerShell.bounds.width + 2, height * 0.3, Pivot.TOP_CENTER), 4)
            .withGradientFill("right", Gradients.cylinder("#E9E7E4".color(), 3))
            .withStroke("default", "#CCCAC8");
        if (appearance.view == "xsection") {
            topPart.remove();
            graphic.addChild(topPart.subtract(innerCircleMask));
        }
        else {
            graphic.addChild(topPart);
        }
        innerCircleMask.remove();
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            pivot: P(0, 0),
            snapping: [
                { type: "placeable", at: P(0, 0) }
            ]
        };
    };
    SoftHeatingMantle.properties = {
        label: "Soft Heating Mantle",
        defaultAppearance: {
            diameter: 115,
            view: "normal"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Size",
                min: 80, max: 200
            },
            {
                spec: "radio", key: "view", label: "Display",
                options: [
                    { value: "normal", label: "Normal" },
                    { value: "xsection", label: "Cross-section" },
                ],
                // allowSelectingTierLockedOptions: true,
            }
        ],
    };
    SoftHeatingMantle = __decorate([
        staticImplements()
    ], SoftHeatingMantle);
    return SoftHeatingMantle;
}());
export { SoftHeatingMantle };
