var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { strokeWidth } from "apparatus/draw";
import { clamp, P, Pivot, R } from "apparatus/library/common";
import { Group, Path } from "paper";
import { Random } from "random/random";
import { staticImplements } from "types";
var Daisy = /** @class */ (function () {
    function Daisy() {
    }
    Daisy.prototype.render = function (appearance) {
        var r = new Random();
        var strWidth = clamp(appearance.scale * strokeWidth("thin") * 0.5, 1.0, strokeWidth("thick"));
        var center = new Path.Circle(P(0, 0), 7)
            .setRadialGradientFill([["#ffdb0c", 0.3], ["#e0c00b", 1.0]])
            .withStroke(strWidth, "#a28b08");
        var graphic = new Group();
        var petalMaster = new Path.Ellipse(R(3, 0, 15, 4, Pivot.CENTER_LEFT));
        for (var a = 0; a < 360; a += 10) {
            var petal = petalMaster.clone();
            petal.scale(1.0 + r.randomUniform(0.1), 1.0, P(3, 0));
            petal.rotate(a * 11 + r.randomCenteredValue(3), P(0, 0));
            petal.withStroke(strWidth, "#999999").withFill("white");
            graphic.addChild(petal);
        }
        petalMaster.remove();
        graphic.addChild(center);
        graphic.scale(appearance.scale, P(0, 0));
        return {
            graphic: graphic,
            pivot: P(0, 0),
            hitShape: graphic.rectHitShape()
        };
    };
    Daisy.properties = {
        label: "Daisy",
        defaultAppearance: {
            scale: 1.5
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 3, step: 0.01, unit: "%",
            }
        ],
    };
    Daisy = __decorate([
        staticImplements()
    ], Daisy);
    return Daisy;
}());
export { Daisy };
