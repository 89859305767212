import { breakPath, mirrorX, setDefaultStyle, strokeWidth } from "apparatus/draw";
import { P, Segments, SharedColors } from "apparatus/library/common";
import { Tap } from "apparatus/library/common/tap";
import { Group, Path } from "paper";
var Hofmann = /** @class */ (function () {
    function Hofmann() {
    }
    Hofmann.prototype.render = function (appearance) {
        var shape = new Path(Segments(
        // Start at the top.
        [[10, -410]], [[10, -400], , [15, 7]], [[30, -375], [0, -9], [0, 22]], [[5, -345]], [[5, -8]], 
        // Burette on one side.
        [[70, -8]], [[70, -280], , [0, -15]], [[80, -310]], [[80, -350]], // Peak
        [[90, -350]], [[90, -310]], [[100, -280], [0, -15]], [[100, 50]], [[70, 50]], [[70, 8]]));
        mirrorX(shape);
        shape.closePath();
        var customLiquidMask = shape.clone();
        var leftLiquidHeight = -10 - appearance.leftLevel * 300;
        var middleLiquidHeight = -10 - appearance.waterLevel * 400;
        var rightLiquidHeight = -10 - appearance.rightLevel * 300;
        var liquid = new Path(Segments(
        // Left column.
        [[-100, leftLiquidHeight]], [[-70, leftLiquidHeight]], 
        // Middle column.
        [[-40, middleLiquidHeight]], [[40, middleLiquidHeight]], 
        // Right column.
        [[70, rightLiquidHeight]], [[100, rightLiquidHeight]], 
        // Fixed bottom level.
        [[100, 50]], [[-100, 50]]));
        liquid.fillColor = SharedColors.defaultLiquid + "4D";
        liquid.withStroke(strokeWidth("thin"), SharedColors.defaultLiquid);
        var liquidGroup = new Group([customLiquidMask, liquid]);
        liquidGroup.clipped = true;
        var glassWalls = breakPath(shape, 9, 13, 17, 21);
        setDefaultStyle(glassWalls);
        var tap1 = Tap.create({ tapOpening: appearance.rightTap, tapFacing: "front" }, true).withPosition(P(85, -310));
        tap1.rotation = 180;
        var tap2 = Tap.create({ tapOpening: appearance.leftTap, tapFacing: "front" }, true).withPosition(P(-85, -310));
        tap2.rotation = 180;
        var graphic = new Group([liquidGroup, glassWalls, tap1, tap2]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: glassWalls.bounds.center,
        };
    };
    Hofmann.properties = {
        label: "Hoffman Voltameter",
        defaultAppearance: {
            leftTap: 1.0,
            rightTap: 1.0,
            leftLevel: 0.75,
            rightLevel: 0.5,
            waterLevel: 0.9,
        },
        appearanceSpecs: [
            { spec: "slider", key: "leftLevel", label: "Left water level", min: 0.0, max: 1.0, step: 0.01, unit: "%" },
            { spec: "slider", key: "waterLevel", label: "Middle water level", min: 0.0, max: 1.0, step: 0.01, unit: "%" },
            { spec: "slider", key: "rightLevel", label: "Right water level", min: 0.0, max: 1.0, step: 0.01, unit: "%" },
            Tap.appearanceSpecFor("leftTap", "Left tap"),
            Tap.appearanceSpecFor("rightTap", "Right tap"),
        ],
    };
    return Hofmann;
}());
export { Hofmann };
