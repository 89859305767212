import { P, SharedColors, SharedGradients } from "apparatus/library/common";
import { Group, Path, PointText } from "paper";
var MARKING_COLOR = "#666666";
var Protractor = /** @class */ (function () {
    function Protractor() {
    }
    Protractor.prototype.render = function (appearance) {
        var markings = appearance.labels;
        var base = new Path();
        base.moveTo(P(-50, 10));
        base.lineTo(P(-50, 0));
        base.arcTo(P(50, 0));
        base.lineTo(P(50, 10));
        base.close();
        base.withStroke("default", SharedColors.stroke).withGradientFill("right-down", SharedGradients.glass);
        var graphic = new Group([
            base,
            new Path.Arc(P(-10, 0), P(0, -10), P(10, 0)).withStroke(1.0, MARKING_COLOR),
            new Path.Arc(P(-35, 0), P(0, -35), P(35, 0)).withStroke(1.0, MARKING_COLOR),
        ]);
        // Add markings.
        var innerLineBase = new Path.Line(P(-10, 0), P(-35, 0)).withStroke(1.0, MARKING_COLOR);
        var outerLineBase = new Path.Line(P(-47, 0), P(-50, 0)).withStroke(1.0, MARKING_COLOR);
        for (var i = 0; i <= 18; i++) {
            var angle = i * 10;
            var innerLine = innerLineBase.clone();
            if (i % 9 == 0)
                innerLine.segments[0].point.x = 0;
            innerLine.rotate(angle, P(0, 0));
            var outerLine = outerLineBase.clone();
            outerLine.rotate(angle, P(0, 0));
            graphic.addChild(innerLine);
            graphic.addChild(outerLine);
            if (markings == "all" || (markings == "every-second" && i % 2 == 0)) {
                // Larger font if only every second marking is shown.
                var fontSize = markings == "all" ? 4 : 5.5;
                var compression = markings == "all" ? 0.8 : 0.9;
                var label = new PointText({
                    point: P(0, -40),
                    content: angle,
                    fillColor: 'black',
                    fontFamily: 'sans-serif',
                    fontSize: fontSize,
                    justification: 'center'
                });
                if (i == 9) {
                    label.fontWeight = 'bold';
                }
                label.scale(compression, 1.0);
                label.rotate(-90 + angle, P(0, 0));
                graphic.addChild(label);
            }
        }
        if (appearance.degree45) {
            var degree45 = innerLineBase.clone();
            degree45.segments[0].point.x = 0;
            degree45.dashArray = [5, 3];
            degree45.rotate(45, P(0, 0));
            var degree135 = degree45.clone();
            degree135.rotate(90, P(0, 0));
            graphic.addChildren([degree45, degree135]);
        }
        innerLineBase.remove();
        outerLineBase.remove();
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    Protractor.properties = {
        label: "Protractor",
        defaultAppearance: {
            scale: 2.0,
            labels: "every-second",
            degree45: true,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 1.0, max: 3.0, step: 0.1, unit: "%",
            },
            {
                spec: "radio", key: "labels", label: "Labels",
                options: [
                    { value: "off", label: "None" },
                    { value: "every-second", label: "protractor.20" },
                    { value: "all", label: "protractor.10" },
                ],
            },
            {
                spec: "checkbox-group", label: "Markings", checkboxes: [
                    { key: "degree45", label: "protractor.45-135" }
                ]
            },
        ],
    };
    return Protractor;
}());
export { Protractor };
