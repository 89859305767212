var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { mirrorX } from "apparatus/draw";
//import { setDefaultStyle } from "apparatus/draw";
import { Group, Path } from "paper";
import { setDefaultStyleWithStroke } from "../draw";
import { DEFAULT_LIQUID_DATA, Segments } from "./common";
var Cuvette = /** @class */ (function () {
    function Cuvette() {
    }
    Cuvette.prototype.render = function (appearance) {
        // Standard cuvettes are 45 by 12.5mm. We round here for clarity.
        var width = 10;
        var height = 50;
        var graphic;
        var liquidMask;
        if (appearance.view == "side") {
            // This is simple, just draw  abox
            var depth = appearance.depth;
            var outline = new Path(Segments([[0, 0]], [[0, height]], [[depth, height]], [[depth, 0]]));
            liquidMask = outline.clone();
            graphic = outline;
        }
        else {
            var outside = new Path(Segments([[0, 0]], [[0, height]], [[width, height]], [[width, 0]]));
            graphic = new Group([outside]);
            var inside = (function () {
                switch (appearance.type) {
                    case "macro":
                        return new Path(Segments([[0, 0]], [[0, height]]));
                    case "semi-micro":
                        return new Path(Segments([[0, 0]], [[0, 20]], [[2.5, 23]], [[2.5, 46]]));
                    case "micro":
                        return new Path(Segments([[0, 0]], [[0, 20]], [[3.5, 24]], [[3.5, 46]]));
                }
            })();
            mirrorX(inside, width / 2);
            liquidMask = inside.clone();
            graphic.addChild(inside);
        }
        setDefaultStyleWithStroke("thinner", graphic);
        graphic.scale(appearance.scale);
        liquidMask.scale(appearance.scale, graphic.bounds.center);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(20).toShape(),
            liquidMask: liquidMask,
            snapping: graphic.placeable()
        };
    };
    Cuvette.properties = {
        label: "Cuvette",
        canContainLiquids: true,
        defaultAppearance: {
            depth: 20,
            scale: 1.5,
            type: "semi-micro",
            view: "side"
        },
        defaultLiquidData: __assign(__assign({}, DEFAULT_LIQUID_DATA), { color: "#ff2020", amountRatio: 0.85 }),
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 1.0, max: 5.0, step: 0.1,
            },
            {
                spec: "radio", key: "view", label: "View",
                options: [
                    { value: "side", label: "From the side" },
                    { value: "front", label: "Front view" },
                ],
            },
            {
                spec: "radio", key: "type", label: "Type",
                options: [
                    { value: "macro", label: "Macro" },
                    { value: "semi-micro", label: "Semi-Micro" },
                    { value: "micro", label: "Micro", tier: "boost" },
                ],
                visible: function (a) { return a.view == "front"; }
            },
            {
                spec: "slider", key: "depth", label: "Depth",
                min: 5, max: 100,
                visible: function (a) { return a.view == "side"; }
            },
        ],
    };
    return Cuvette;
}());
export { Cuvette };
