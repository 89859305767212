import { Arrays } from "ext/array";
import * as paper from "paper";
import { addEdgeLips, addMarkers, breakPath, mirrorX, removeLastSegment, setDefaultStyle, smoothCorner } from "../draw";
import { markerSpacing, P, Segments } from "./common";
var Beaker = /** @class */ (function () {
    function Beaker() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 30, maxX: 120,
                minY: 40, maxY: 160,
                fromAppearance: function (a) { return P(a.width, a.height); },
                toAppearance: function (f, p) { return f({ width: p.x, height: p.y }); },
                scale: 2.0,
            }
        ];
    }
    Beaker.prototype.render = function (appearance) {
        var beakerPath = createBasicBeaker(appearance);
        addEdgeLips(beakerPath, { spout: appearance.spout });
        // Clone this path as a liquid mask too.
        var liquidMask = beakerPath.clone();
        // Create a container for the graphic
        var beakerGraphic = new paper.Group([beakerPath]);
        markers(appearance, beakerGraphic);
        setDefaultStyle(beakerGraphic);
        var hitShape = paper.Shape.Rectangle(beakerPath.bounds);
        return {
            graphic: beakerGraphic,
            hitShape: hitShape,
            liquidMask: liquidMask,
            liquidMaskOpening: { start: liquidMask.segments[0].point, end: Arrays.last(liquidMask.segments).point },
            snapping: [
                { type: "neck", top: P(appearance.width / 2, 0), diameter: appearance.width, facing: 0 },
                beakerGraphic.placeable(),
            ]
        };
    };
    Beaker.properties = {
        label: "Beaker",
        canContainLiquids: true,
        meniscus: true,
        pourable: true,
        flippable: true,
        defaultAppearance: {
            width: 80,
            height: 100,
            spout: false,
        },
        appearanceSpecs: [
            {
                spec: "slider",
                key: "width",
                label: "Width",
                min: 30,
                max: 120,
            },
            {
                spec: "slider",
                key: "height",
                label: "Height",
                min: 40,
                max: 160,
            },
            { spec: "checkbox", key: "spout", label: "Spout" }
        ]
    };
    return Beaker;
}());
export { Beaker };
var DisplacementBeaker = /** @class */ (function () {
    function DisplacementBeaker() {
    }
    DisplacementBeaker.prototype.render = function (appearance) {
        var beakerPath = createBasicBeaker(appearance);
        var width = appearance.width;
        // let height = appearance.height
        // Add a spout, winding anti clockwise
        beakerPath.insertSegments(5, Segments([[width, 25]], [[width + 15, 28]], [[width + 15, 18]], [[width, 10]]));
        addEdgeLips(beakerPath);
        // Clone this path as a liquid mask too.
        var liquidMask = beakerPath.clone();
        // Create a container for the graphic
        var beakerGraphic = breakPath(beakerPath, 8);
        setDefaultStyle(beakerGraphic);
        markers(appearance, beakerGraphic);
        var hitShape = paper.Shape.Rectangle(beakerPath.bounds);
        return {
            graphic: beakerGraphic,
            hitShape: hitShape,
            liquidMask: liquidMask,
            snapping: [
                beakerGraphic.placeable(),
                { type: "neck", top: P(appearance.width / 2, 0), diameter: appearance.width, facing: 0 },
            ]
        };
    };
    DisplacementBeaker.properties = {
        label: "Displacement Beaker",
        canContainLiquids: true,
        flippable: true,
        defaultAppearance: Beaker.properties.defaultAppearance,
        appearanceSpecs: Beaker.properties.appearanceSpecs,
    };
    return DisplacementBeaker;
}());
export { DisplacementBeaker };
/** Creates a basic beaker shape, without styles or edge lips. */
function createBasicBeaker(appearance) {
    var width = appearance.width;
    var height = appearance.height;
    var beakerPath = new paper.Path(Segments(
    // beaker side
    [[0, 0]], [[0, height]], 
    // beaker bottom
    [[width / 2, height]]));
    smoothCorner(beakerPath.segments[1]);
    removeLastSegment(beakerPath);
    mirrorX(beakerPath, width / 2);
    return beakerPath;
}
function markers(appearance, group) {
    addMarkers(group, new paper.Point(appearance.width - 5, appearance.height), 8, new paper.Point(0, markerSpacing));
}
