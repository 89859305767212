// Component for group of radios.
import * as React from "react";
import { useTranslation } from "react-i18next";
import { TierContext } from "tiers/context";
/** Renders a group of radios. */
export var Radio = function (props) {
    var tiers = React.useContext(TierContext);
    var t = useTranslation().t;
    return React.createElement(React.Fragment, null, props.options.map(function (optionProps) {
        var value = optionProps.value, label = optionProps.label;
        var id = props.id + "_" + value;
        var allowSelection = tiers.allow(optionProps) || (props.allowSelectingTierLockedOptions);
        return React.createElement("div", { key: value, className: "custom-control custom-radio" },
            React.createElement("input", { type: "radio", key: value + "_radio", id: id, value: value, disabled: !allowSelection, checked: props.value == value, name: props.id, className: "custom-control-input", onChange: function (e) { return props.onChange(props.value, value); } }),
            React.createElement("label", { key: value + "_label", htmlFor: id, className: "custom-control-label", onClick: !allowSelection ? function () { return tiers.popup(id); } : function () { } },
                t(["props." + label, label], label),
                tiers.badge(optionProps, "ml-1")));
    }));
};
