var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { setDefaultStyle } from "apparatus/draw";
import { P, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { CommonCondenser, condenserCaseShape } from "../condensers";
var GrahamCondenser2 = /** @class */ (function () {
    function GrahamCondenser2() {
    }
    GrahamCondenser2.prototype.render = function (appearance) {
        var ports = appearance.ports, height = appearance.height;
        var _a = condenserCaseShape(height, 15, ports), casing = _a.group, snapping = _a.snap;
        var insideTube1 = new Path(Segments([[7.5, 0]], [[7.5, 10]], [[5, 25]]));
        var insideTube2 = new Path(Segments([[-7.5, 0]], [[-7.5, 10]], [[-5, 25]]));
        var twists = Math.floor((height - 70) / 10);
        for (var i = 0; i < twists; i++) {
            insideTube1.addSegments(Segments([[3, 10 * i + 38],
                i == 0 ? [0, 0] : [10, -5],
                i == twists - 1 ? [0, 0] : [10, 5]]));
            insideTube2.addSegments(Segments([[-3, 10 * i + 43],
                i == 0 ? [0, 0] : [-10, -5],
                i == twists - 1 ? [0, 0] : [-10, 5]]));
        }
        insideTube1.addSegments(Segments([[5, height - 25]], [[7.5, height - 10]], [[7.5, height]]));
        insideTube2.addSegments(Segments([[-5, height - 25]], [[-7.5, height - 10]], [[-7.5, height]]));
        var graphic = new Group([casing, insideTube1, insideTube2]);
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: snapping,
            pivot: P(0, height / 2)
        };
    };
    GrahamCondenser2.properties = {
        label: "Graham Condenser",
        flippable: true,
        defaultAppearance: __assign({}, CommonCondenser.defaultAppearance),
        appearanceSpecs: __spreadArray([], CommonCondenser.appearanceSpecs),
        isBoostRequired: function (a) { return CommonCondenser.isBoostRequired(a); }
    };
    return GrahamCondenser2;
}());
export { GrahamCondenser2 };
