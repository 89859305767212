var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { mirrorX } from "apparatus/draw";
import { INFINITE_BOUNDS } from "apparatus/driver";
import { P, Segments } from "apparatus/library/common";
import { BasicShapes } from "apparatus/library/shapes/common";
import { Path } from "paper";
/** Minimum gap in paper pixels, between the two arrow heads or head and an end. */
var MINIMUM_GAP = 10;
function isV1(a) {
    return a.length !== undefined;
}
var Arrow = /** @class */ (function () {
    function Arrow() {
        this.drivers = [
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ end1x: p.x, end1y: p.y }); }, fromAppearance: function (a) { return P(a.end1x, a.end1y); }, scale: 1.0 }),
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ end2x: p.x, end2y: p.y }); }, fromAppearance: function (a) { return P(a.end2x, a.end2y); }, scale: 1.0 }),
        ];
        this.shouldUseBoundsCenter = true;
    }
    Arrow.convertToLatest = function (input) {
        if (isV1(input)) {
            var length_1 = input.length, rest = __rest(input, ["length"]);
            return __assign(__assign({}, rest), { end1x: -length_1 / 2, end1y: 0, end2x: length_1 / 2, end2y: 0 });
        }
        return input;
    };
    Arrow.prototype.render = function (appearance) {
        // The arrow is drawn vertically, centered at (0, 0).
        // Hence, compute the transformation required, then draw the
        // arrow, then apply the transform.
        var startVector = P(appearance.end1x, appearance.end1y);
        var endVector = P(appearance.end2x, appearance.end2y);
        var deltaVector = endVector.subtract(startVector);
        var length = deltaVector.length;
        var rotation = deltaVector.angle;
        var midPoint = startVector.add(endVector).divide(2);
        var width = appearance.width, headScale = appearance.headScale, headThickness = appearance.headThickness, bothEnds = appearance.bothEnds;
        var halfLength = length / 2;
        var maxHeadSize = Math.max(4, length - MINIMUM_GAP) / (bothEnds ? 2 : 1);
        var headSize = width * headScale * 2;
        var headFlare = headSize * headThickness;
        var effectiveHeadSize = Math.min(maxHeadSize, headSize);
        var sharpShift = effectiveHeadSize * (appearance.arrow === "sharp" ? 0.15 : 0.0);
        var graphic = new Path(Segments([[halfLength - effectiveHeadSize + sharpShift, -width / 2]], [[halfLength - effectiveHeadSize, -headFlare]], [[halfLength, 0]], // Tip
        [[halfLength - effectiveHeadSize, headFlare]], [[halfLength - effectiveHeadSize + sharpShift, width / 2]]));
        if (bothEnds) {
            mirrorX(graphic);
        }
        else {
            graphic.addSegments(Segments([[-halfLength, width / 2]], [[-halfLength, -width / 2]]));
        }
        graphic.closePath();
        graphic.rotate(rotation);
        graphic.position.x += midPoint.x;
        graphic.position.y += midPoint.y;
        BasicShapes.applyStyle(graphic, appearance);
        return {
            graphic: graphic,
            pivot: P(0, 0),
            hitShape: graphic.rectHitShape(),
        };
    };
    Arrow.properties = {
        label: "Arrow",
        defaultAppearance: __assign({ end1x: -75, end1y: 0, end2x: 75, end2y: 0, width: 30, bothEnds: false, headScale: 1.0, headThickness: 0.5, arrow: "flat" }, BasicShapes.defaultAppearance),
        appearanceSpecs: __spreadArray(__spreadArray([], BasicShapes.specs), [
            BasicShapes.header,
            {
                spec: "slider", key: "width", label: "Width",
                min: 4, max: 50,
            },
            {
                spec: "radio", key: "arrow", label: "Arrow style",
                options: [
                    { value: "flat", label: "Flat" },
                    { value: "sharp", label: "Sharp", tier: "boost" },
                ],
                allowSelectingTierLockedOptions: true,
            },
            {
                spec: "slider", key: "headScale", label: "Arrow scale",
                min: 1.0, max: 2, step: 0.01, unit: "%"
            },
            {
                spec: "slider", key: "headThickness", label: "Arrow thickness",
                min: 0.25, max: 1.0, step: 0.01, unit: "%"
            },
            {
                spec: "checkbox", key: "bothEnds", label: "Arrows on both ends"
            },
        ]),
        isBoostRequired: function (a) { return a.arrow === "sharp"; }
    };
    return Arrow;
}());
export { Arrow };
