import { strokeWidth } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { interpolate, Segments } from "apparatus/library/common";
import { hsl } from "color";
import "ext/color";
import { Group, Path } from "paper";
export var DEFAULT_COLOR = hsl(124, 57, 40).hex();
var Leaf = /** @class */ (function () {
    function Leaf() {
    }
    Leaf.prototype.render = function (appearance) {
        var graphic = Leaf.createLeaf(appearance);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
        };
    };
    Leaf.createLeaf = function (appearance) {
        var baseColor = appearance.color, length = appearance.length, shape = appearance.shape, stemLength = appearance.stemLength;
        var leaf = new Path(getShape(shape));
        var strWidth = length < 50 ? interpolate(length, [15, 50], [1, strokeWidth("default")]) : strokeWidth("default");
        var outlineColor = baseColor.color().ladd(-20).hex();
        leaf.closePath();
        leaf.withStroke(strWidth, outlineColor);
        leaf.setGradientFill("up", Gradients.simple(baseColor.color()));
        var stem = new Path(Segments([[-15, -15]], [[0, 0], [-5, -2], [15, 6]], stemLength == "full" ? [[60, 0]] : [[35, 2]]));
        stem.withStroke(strWidth, outlineColor);
        var group = new Group([leaf, stem]);
        group.scale(length / 60);
        return group;
    };
    Leaf.properties = {
        label: "Leaf",
        flippable: true,
        defaultAppearance: {
            color: DEFAULT_COLOR,
            length: 60,
            shape: "pointy",
            stemLength: "full",
        },
        appearanceSpecs: [
            {
                spec: "toggle-buttons", key: "shape", size: "text", label: "Shape", options: [
                    { value: "pointy", label: "Pointy" },
                    { value: "round", label: "Round" },
                ]
            },
            {
                spec: "color", key: "color", label: "Colour",
                swatches: {
                    "Green": DEFAULT_COLOR,
                    "Yellow": "#f3f338",
                },
            },
            {
                spec: "slider", key: "length", label: "Length",
                min: 15, max: 120, unit: "mm",
            },
            {
                spec: "toggle-buttons", key: "stemLength", size: "text", label: "Stem length", options: [
                    { value: "full", label: "Full" },
                    { value: "short", label: "Short" },
                ]
            },
        ],
    };
    return Leaf;
}());
export { Leaf };
function getShape(shape) {
    switch (shape) {
        case "pointy": return Segments([[0, 0], [20, -20], [20, 30]], [[60, 0], , [-30, -20]]);
        case "round": return Segments([[0, 0], [4, -20], [0, 22]], [[60, 0], [0, 22], [-2, -15]]);
    }
}
