var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Tippy from "@tippyjs/react";
import { Icon } from "components/icon";
import Flags from "flags";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { selectTool } from "tools";
import { Consts } from "vars";
import { Hint } from "../../view/tooltips";
var toolMenuItems = [
    {
        name: "select",
        icon: "mouse-pointer",
        shortcut: { key: "1" },
        shortcut2: { key: "V" },
    },
    {
        name: "pan",
        icon: "arrows-alt",
        shortcut: { key: "2" },
        shortcut2: { key: "P" },
    },
    {
        name: "arrow",
        icon: "font",
        shortcut: { key: "3" },
        shortcut2: { key: "T" },
    },
];
if (Flags.isFreeHandToolEnabled) {
    toolMenuItems.push({
        name: "freehand",
        icon: "pencil-alt",
        shortcut: { key: "4" },
        shortcut2: { key: "D" }
    });
}
if (Consts.isDev) {
    toolMenuItems.push({
        name: "pathedit",
        icon: "bezier-curve",
    });
}
/**
 * Toolbox is a button group that allows the user
 * to select a tool. Unlike a regular "group of buttons",
 * toolbox highlights the currently selected tool and only
 * one tool can be selected.
 */
export var Toolbox = function (props) { return (React.createElement("div", { id: "toolbox", className: "horizontal-button-group", key: "toolbox" }, toolMenuItems.map(function (i) { return React.createElement(Item, __assign({ key: i.name }, i, { active: props.tool === i.name })); }))); };
var Item = function (item) {
    var t = useTranslation().t;
    return React.createElement(Tippy, { content: React.createElement(Hint, { text: t("tool." + item.name), shortcut: item.shortcut, shortcut2: item.shortcut2 }) },
        React.createElement("div", { key: item.name, id: "tool-" + item.name, className: "ui-button " + (item.active ? "highlight" : ""), onClick: function () { return selectTool(item.name, "click"); } },
            React.createElement(Icon, { name: item.icon, fullWidth: true })));
};
