export var ID;
(function (ID) {
    function equals(a, b) {
        if (Array.isArray(a) && Array.isArray(b)) {
            if (a.length !== b.length)
                return false;
            for (var i = 0; i < a.length; i++) {
                if (a[i].id !== b[i].id)
                    return false;
                if (a[i].type !== b[i].type)
                    return false;
            }
            return true;
        }
        else if (!Array.isArray(a) && !Array.isArray(b) && a && b) {
            return a.id === b.id && a.type === b.type;
        }
        // Mixed types => not equal.
        return false;
    }
    ID.equals = equals;
    /** To be used as callback to .filter() */
    function matching(id) {
        var ids = Array.isArray(id) ? id : [id];
        return function (hasId) {
            return ids.indexOf(hasId.id) !== -1;
        };
    }
    ID.matching = matching;
    function notMatching(id) {
        var ids = Array.isArray(id) ? id : [id];
        return function (hasId) {
            return ids.indexOf(hasId.id) === -1;
        };
    }
    ID.notMatching = notMatching;
    function string(id) {
        return id.type + "_" + id.id;
    }
    ID.string = string;
    /** ID to use for currently drawn arrows. */
    function mint(type) {
        return { type: type, id: idCounters[type]++ };
    }
    ID.mint = mint;
    ID.TEMP_ARROW = { type: "arrow", id: -1 };
    /**
     * Resets the current ID counters so they can be used
     * when editing given document.
     */
    function resetFor(doc) {
        var maxArrowId = doc.arrows.reduce(function (id, a) { return Math.max(id, a.id.id); }, 0);
        var maxApparatusId = doc.items.reduce(function (id, a) { return Math.max(id, a.id.id); }, 0);
        var maxGroupId = doc.groups.reduce(function (id, a) { return Math.max(id, a.id.id); }, 0);
        idCounters["arrow"] = maxArrowId + 1;
        idCounters["apparatus"] = maxApparatusId + 1;
        idCounters["group"] = maxGroupId + 1;
    }
    ID.resetFor = resetFor;
    function __resetForTesting() {
        idCounters.arrow = 0;
        idCounters.apparatus = 0;
        idCounters.group = 0;
    }
    ID.__resetForTesting = __resetForTesting;
})(ID || (ID = {}));
/** Tracks unique IDs across different types. */
var idCounters = {
    "arrow": 0,
    "apparatus": 0,
    "group": 0,
};
