// This file contains shimmy types for libraries that don't provide typescript typings
export var IntroJs;
(function (IntroJs) {
    var Position;
    (function (Position) {
        Position["LEFT"] = "left";
        Position["RIGHT"] = "right";
        Position["TOP"] = "top";
    })(Position = IntroJs.Position || (IntroJs.Position = {}));
})(IntroJs || (IntroJs = {}));
