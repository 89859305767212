var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { lampAppearanceSpecs, renderLightBulb } from "apparatus/library/lamp";
import { Mounted } from "apparatus/library/physics/electric/mounted/utils";
import { Group } from "paper";
var MountedBulb = /** @class */ (function () {
    function MountedBulb() {
    }
    MountedBulb.prototype.render = function (appearance) {
        var _a = Mounted.renderPlate({ appearance: appearance, width: 80 }), base = _a.base, jacks = _a.jacks, snaps = _a.snaps;
        var lampGraphic = renderLightBulb(appearance, "3d");
        lampGraphic.position.x += 35;
        lampGraphic.position.y += -17;
        var graphic = new Group([base, jacks, lampGraphic]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: base.bounds.center,
            snapping: __spreadArray([], snaps)
        };
    };
    MountedBulb.properties = {
        label: "Bulb",
        defaultAppearance: __assign(__assign({}, Mounted.defaultAppearance), { color: "yellow", glow: "small", on: false, scale: 1 }),
        appearanceSpecs: __spreadArray(__spreadArray([], Mounted.appearanceSpecs), lampAppearanceSpecs),
    };
    return MountedBulb;
}());
export { MountedBulb };
