import * as paper from "paper";
import { Debug } from "vars";
export function drawPivotAndCenter(group, color, size) {
    if (size === void 0) { size = 5.0; }
    if (!Debug.CENTERS)
        return;
    var center = paper.Shape.Circle(new paper.Point(0, 0), size);
    center.fillColor = color;
    group.addChild(center);
    var pivotRect = new paper.Rectangle(group.pivot, new paper.Size(size * 2, size * 2));
    var pivot = paper.Shape.Rectangle(pivotRect);
    pivot.fillColor = color;
    group.addChild(pivot);
}
export function drawCircle(point, color) {
    var circle = paper.Shape.Circle(point, 5);
    circle.fillColor = color;
}
