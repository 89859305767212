import { SVG } from "apparatus/svg";
/**
 * Peg drawn according to https://edulab.com/product/test-tube-holder-peg-type-pk10/.
 * Default size is 18cm.
 */
var Peg = /** @class */ (function () {
    function Peg() {
    }
    Peg.prototype.render = function (appearance) {
        var graphic = svgs[appearance.view]();
        graphic.scale(appearance.length / 18, 1.0);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    Peg.properties = {
        label: "Test Tube Holder Peg",
        defaultAppearance: {
            view: "side",
            length: 20
        },
        flippable: true,
        appearanceSpecs: [
            {
                spec: "radio", key: "view", label: "View",
                options: [
                    { value: "top", label: "From top", tier: "boost" },
                    { value: "side", label: "From side" },
                ],
                allowSelectingTierLockedOptions: true,
            },
            {
                spec: "slider", key: "length", label: "Length",
                min: 18, max: 25, unit: "cm",
            }
        ],
        isBoostRequired: function (a) { return a.view === "top"; }
    };
    return Peg;
}());
export { Peg };
var svgs = {
    "top": SVG.Peg.top,
    "side": SVG.Peg.side,
};
