import { strokeWidth } from "apparatus/draw";
import { P } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
var HotPlate = /** @class */ (function () {
    function HotPlate() {
    }
    HotPlate.prototype.render = function (appearance) {
        var graphic = SVG.hotPlate();
        graphic.scale(2.0 * appearance.scale, P(0, 0));
        graphic.children[0].children[3].strokeWidth = strokeWidth("thin");
        graphic.children[0].children[6].strokeWidth = strokeWidth("thin");
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: [
                graphic.topEdgeAsPlaceableSurface(),
                graphic.placeable()
            ]
        };
    };
    HotPlate.properties = {
        label: "Hot Plate",
        defaultAppearance: {
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.8, max: 1.75, step: 0.01
            }
        ],
    };
    return HotPlate;
}());
export { HotPlate };
