export function strokeWidth(style) {
    return currentStrokeWidths[style];
}
/*
 * Stroke Thickness Overriding
 */
var strokeWidthsForTheme = {
    s: {
        thicker: 4.0,
        thick: 3.0,
        default: 2.0,
        thin: 1.5,
        thinner: 1.0,
        liquid: 1.0,
    },
    m: {
        thicker: 6.0,
        thick: 4.0,
        default: 3.0,
        thin: 2.0,
        thinner: 1.5,
        liquid: 1.5,
    },
    l: {
        thicker: 8.0,
        thick: 5.0,
        default: 4.0,
        thin: 3.0,
        thinner: 2.0,
        liquid: 2.0,
    }
};
var currentStrokeWidths = strokeWidthsForTheme.m;
/** Call this to globally update thte current thickness theme */
export function setGloballyStrokeThicknessTheme(theme) {
    currentStrokeWidths = strokeWidthsForTheme[theme];
}
/** Returns an anchor of given name. First match is returned if there are multiple matches */
export function getFirstAnchorWithName(anchors, name) {
    for (var _i = 0, anchors_1 = anchors; _i < anchors_1.length; _i++) {
        var anchor = anchors_1[_i];
        if (anchor.name === name) {
            return anchor;
        }
    }
    return undefined;
}
