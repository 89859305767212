import { setDefaultStyle, strokeWidth } from "apparatus/draw";
import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path, Segment, Shape } from "paper";
import { mirrorX } from "../../draw";
var DialysisTube = /** @class */ (function () {
    function DialysisTube() {
    }
    DialysisTube.prototype.render = function (appearance) {
        var length = appearance.length, width = appearance.diameter, shapeOption = appearance.shape;
        var shape = new Path(Segments([[0, -8], , [3, -5]], [[5, -6], [3, -5], [4, -1]], [[6, 0], [5, -1], [-2, 6]], shapeOption == "uniform" ? [[width / 2, 21], [0, -16]] : [[width / 2, length / 2], [0, -length / 3]]));
        // Create bottom part.
        var bottomSegments = shape.segments.map(function (s) { return new Segment(P(s.point.x, -s.point.y + length), s.handleOut.multiply([1, -1]), s.handleIn.multiply([1, -1])); });
        bottomSegments.reverse();
        shape.addSegments(bottomSegments);
        mirrorX(shape);
        shape.close();
        setDefaultStyle(shape);
        var graphic = new Group([
            shape,
            Shape.Rectangle(R(0, 0, 14, 4, Pivot.TOP_CENTER), 2).withStroke(strokeWidth("thinner"), SharedColors.stroke),
            Shape.Rectangle(R(0, length, 14, 4, Pivot.BOTTOM_CENTER), 2).withStroke(strokeWidth("thinner"), SharedColors.stroke),
        ]);
        var liquidMask = new Path(shape.segments.slice(2, 6).map(function (s) { return s.clone(); }));
        liquidMask.segments[0].point.y += 3;
        liquidMask.segments[3].point.y -= 3;
        mirrorX(liquidMask);
        liquidMask.close();
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: shape.bounds.toShape()
        };
    };
    DialysisTube.properties = {
        label: "Dialysis Tube",
        canContainLiquids: true,
        defaultAppearance: {
            diameter: 26,
            length: 80,
            shape: "uniform"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 15, max: 35, unit: "mm"
            },
            {
                spec: "slider", key: "length", label: "Length",
                min: 50, max: 150, unit: "mm"
            },
            {
                spec: "radio", key: "shape", label: "Shape",
                options: [
                    { value: "uniform", label: "Uniform" },
                    { value: "bulge", label: "Bulge", tier: "boost" },
                ],
                allowSelectingTierLockedOptions: true
            }
        ],
        isBoostRequired: function (a) { return a.shape == "bulge"; }
    };
    return DialysisTube;
}());
export { DialysisTube };
