import { mirrorY, softShade } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, PointText, Shape } from "paper";
var OUTLINE = "#8C847F";
/**
 * https://www.youtube.com/watch?v=BAf6HoVK6JI
 * https://www.ourweigh.co.uk/mechanical-balances/TBB2610T.html?gclid=Cj0KCQiAkuP9BRCkARIsAKGLE8VCz7xsk1Y2EBnukCDJ5z60JKoC9wxaY7sg_YSDZI4ipA6s-Ai9vYkaAtHyEALw_wcB
 * https://www.analytics-shop.com/gb/oh80252052-gb.html?utm_source=google_shopping&utm_medium=cpc&gclid=Cj0KCQiAkuP9BRCkARIsAKGLE8WP2n9EjbjIT_n1M-0A0CCU82JB9BtMBpQht3DY1xyRmEiqzzvwAHoaAg-sEALw_wcB
 * https://us.ohaus.com/Ohaus/media/assets/images/product/main-product-fully-labeled.png
 *
 * Dimensions: https://www.amazon.co.uk/Ohaus-Mechanical-Balance-Capacity-Readability/dp/B0051W8YBE
 * - 45.21 x 16.26 x 17.02 cm
 * - Weighing plate: 14.3cm
 */
var TripleBeamBalance = /** @class */ (function () {
    function TripleBeamBalance() {
    }
    TripleBeamBalance.prototype.render = function (appearance) {
        var magnifyBeam = appearance.magnifyBeam;
        var base = new Path(Segments([[-115, 48]], // Start
        [[-48, 48]], [[-24, 36]], // Pivot base
        [[10, 36]], [[18, 95]], // Midsection
        [[277, 97]], [[277, -30]], [[312, -29]], [[327, 80]], [[336, 101]], [[335, 120]], [[-144, 120]], [[-128, 100]])).close().withGradientFill("right", [
            ["#BCB7AE", 0.03],
            ["#C3BEB6", 0.07],
            ["#BCB7AE", 0.09],
            ["#B5B1A8", 0.091],
            ["#B5B1A8", 0.139],
            ["#C5C2BC", 0.14],
            ["#C5C2BC", 0.26],
            ["#D2D1CD", 0.261],
            ["#D2D1CD", 0.29],
            ["#CCCBC6", 0.3],
            ["#CCCBC6", 0.9],
            ["#B8B3AA", 1.0],
        ]).withStroke("default", OUTLINE);
        var support = new Path(Segments([[24, 6], , [-37, 0]], [[-24, 17], [20, 0]], [[-106, 17]], // Left edge.
        [[-106, 0]], [[-64, 0], , [30, 0]], [[19, -17], [-63, -1]], [[21, -9]], [[213, -9]], [[213, -14]], [[261, -14]], [[275, -7]], [[262, 0]], [[213, 0]], [[213, -5]], [[22, -5]])).close().withFill("#777777");
        var startX = magnifyBeam ? 20 : 25;
        var startY = magnifyBeam ? -40 : -30;
        var stepY = magnifyBeam ? 25 : 15;
        var beam1 = this.beam(25, 0, 10, 10, appearance.tens, magnifyBeam);
        beam1.position = P(startX, startY);
        var beam2 = this.beam(35, 10, 100, 5, appearance.hundreds, magnifyBeam);
        beam2.position = P(startX, startY + stepY);
        var beam3 = this.beam(15, 20, 1, 10, appearance.units, magnifyBeam);
        beam3.position = P(startX, startY + stepY * 2);
        var tiltShift = -Math.sin(appearance.tilt.toRadians()) * 72;
        var plate = Shape.Rectangle(R(0, 0, 140, 10), 2.0)
            .withGradientFill("right", Gradients.cylinder("#cccccc".color()))
            .withStroke("thin", "#888888");
        plate.position = P(-72, -28 + tiltShift);
        var zeroKnobScrew = Shape.Rectangle(R(-106, 9, 10, 5, Pivot.CENTER_RIGHT)).withFill("#aaaaaa");
        var zeroKnob = Shape.Rectangle(R(-116, 9, 8, 12, Pivot.CENTER_RIGHT)).withGradientFill("down", Gradients.cylinder("#999999".color()));
        var poise = new Group([support, beam1, beam2, beam3, zeroKnobScrew, zeroKnob]);
        poise.rotate(appearance.tilt, P(0, 0));
        var fixedPiston = Shape.Rectangle(R(-70, 50, 8, 60, Pivot.BOTTOM_CENTER)).withGradientFill("right", Gradients.cylinder("#666666".color()));
        var movablePiston = Shape.Rectangle(R(-70, 0, 8, 30, Pivot.BOTTOM_CENTER)).withGradientFill("right", Gradients.cylinder("#666666".color()));
        var column1 = Shape.Rectangle(R(-70, 40, 15, 42, Pivot.BOTTOM_CENTER)).withGradientFill("right", Gradients.cylinder("#aaaaaa".color()));
        var column2 = Shape.Rectangle(R(0, 37, 15, 40, Pivot.BOTTOM_CENTER)).withGradientFill("right", Gradients.cylinder("#aaaaaa".color()));
        movablePiston.position.y += tiltShift;
        column1.position.y += tiltShift;
        var pointBg = Shape.Rectangle(R(282, -7, 11, 40, Pivot.CENTER)).withFill("#ffffff");
        var point = new Path(Segments([[0, -20]], [[10, -20]], [[3, -1]], [[1, -1]])).close().withFill("#222222");
        mirrorY(point);
        point.position = P(282, -7);
        var graphic = new Group([
            poise,
            fixedPiston,
            movablePiston,
            column1,
            column2,
            plate,
            base,
            pointBg,
            point,
            softShade("edge", "default", base.segments[0].point, base.segments[1].point, OUTLINE + "60", 5.0),
            softShade("edge", "default", base.segments[3].point, base.segments[4].point, "#ffffff80", 6.0)
        ]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: base.bounds.center,
            snapping: [
                base.placeable(),
                plate.topEdgeAsPlaceableSurface(),
            ]
        };
    };
    TripleBeamBalance.prototype.beam = function (riderSize, extraSize, step, multiple, value, magnify) {
        var beamSize = (magnify ? 220 : 190) + extraSize;
        var riderOffset = riderSize / 2;
        var riderRange = beamSize - riderSize;
        var max = step * multiple;
        // No need to add riderOffset, since position is taken at center.
        var riderX = (value / max) * riderRange;
        var beamHeight = magnify ? 21 : 15;
        var beam = Shape.Rectangle(R(0, 0, beamSize, beamHeight)).withFill("#ffffff").withStroke(1.0, "black");
        var group = new Group([beam]);
        // Labels.
        for (var i = 0; i <= multiple; i++) {
            var value_1 = i * step;
            group.addChild(new PointText({
                point: P(riderOffset + (value_1 / max) * riderRange, magnify ? 18 : 13),
                content: value_1.toString(),
                fillColor: "black",
                fontFamily: 'sans-serif',
                fontSize: magnify ? 9 : 7,
                justification: "center",
            }));
        }
        // Add rider.
        var riderHeight = magnify ? 7 : 5;
        var rider = new Path(Segments([[0, -1]], [[riderSize, -1]], [[riderSize, riderHeight]], [[0, riderHeight]])).close().withFill("#bbbbbb").withStroke(1.0, "#777777");
        var highlight = new Path(Segments([[riderSize / 2 - 2, -1]], [[riderSize / 2, riderHeight - 1]])).withStroke(1.0, "#888888");
        var highlight2 = new Path(Segments([[riderSize / 2, riderHeight - 1]], [[riderSize / 2 + 2, -1]
        ])).withStroke(1.0, "#cccccc");
        var riderGroup = new Group([rider, highlight, highlight2]);
        riderGroup.position.x += riderX;
        group.addChild(riderGroup);
        group.pivot = P(0, 0);
        return group;
    };
    TripleBeamBalance.properties = {
        label: "Triple Beam Balance",
        defaultAppearance: {
            hundreds: 200,
            tens: 40,
            units: 1.1,
            tilt: 0,
            magnifyBeam: false,
        },
        appearanceSpecs: [
            { spec: "checkbox", key: "magnifyBeam", label: "Beams", subLabel: "Magnify" },
            {
                spec: "slider", key: "hundreds", label: "Hundreds",
                min: 0, max: 500, step: 100, unit: "g"
            },
            {
                spec: "slider", key: "tens", label: "Tens",
                min: 0, max: 100, step: 10, unit: "g"
            },
            {
                spec: "slider", key: "units", label: "balance.Units",
                min: 0, max: 10, step: 0.1, unit: "g"
            },
            {
                spec: "slider", key: "tilt", label: "Tilt",
                min: -4, max: 4, step: 0.1,
            },
        ],
    };
    return TripleBeamBalance;
}());
export { TripleBeamBalance };
