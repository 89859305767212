var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ControlledMenu, MenuDivider, MenuItem, SubMenu } from "@szhsin/react-menu";
import { g } from "analytics";
import { commandProcessor } from "command/command";
import clipboard from "editing/clipboard";
import { deleteSelected } from "editing/deleting";
import { t } from "i18next";
import { ReorderItems } from "ordering/actions";
import React from "react";
var EditorContextMenu = /** @class */ (function (_super) {
    __extends(EditorContextMenu, _super);
    function EditorContextMenu() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.reorderAction = function (action) {
            var command = ReorderItems.create(_this.extractSelectedIds(), action);
            if (command) {
                g("LayerOrdering", action.capitalise() + "-ContextMenu");
                commandProcessor.execute(command);
            }
        };
        return _this;
    }
    EditorContextMenu.prototype.render = function () {
        var _this = this;
        var itemsSelected = !this.props.selected.isEmpty;
        return React.createElement(ControlledMenu, { key: "ContextMenu", anchorPoint: this.props.contextMenu ? this.props.contextMenu.position : { x: 0, y: 0 }, state: this.props.contextMenu !== undefined ? "open" : "closed", onClose: function () { return _this.props.onClose(); } },
            React.createElement(MenuItem, { key: "Cut", onClick: function () { return clipboard.cut("contextmenu"); }, disabled: !itemsSelected },
                React.createElement("i", { className: "fas fa-cut" }),
                t("Cut")),
            React.createElement(MenuItem, { key: "Copy", onClick: function () { return clipboard.copy("contextmenu"); }, disabled: !itemsSelected },
                React.createElement("i", { className: "far fa-copy" }),
                t("Copy")),
            React.createElement(MenuItem, { key: "Paste", onClick: function () { return clipboard.paste("contextmenu"); } },
                React.createElement("i", { className: "far fa-clipboard" }),
                t("Paste")),
            React.createElement(MenuItem, { key: "PasteInPlace", onClick: function () { return clipboard.paste("contextmenu", { inPlace: true }); } }, t("Paste in place")),
            React.createElement(MenuDivider, null),
            this.groupUngroupSection(),
            React.createElement(MenuDivider, null),
            React.createElement(SubMenu, { label: t("Order"), disabled: !itemsSelected, offsetX: -4 },
                React.createElement(MenuItem, { onClick: function () { return _this.reorderAction("bringToFront"); } },
                    React.createElement("i", { className: "fad fa-bring-front" }),
                    t("ui.Bring to front")),
                React.createElement(MenuItem, { onClick: function () { return _this.reorderAction("bringForward"); } },
                    React.createElement("i", { className: "fad fa-bring-forward" }),
                    t("ui.Bring forward")),
                React.createElement(MenuItem, { onClick: function () { return _this.reorderAction("sendBackward"); } },
                    React.createElement("i", { className: "fad fa-send-backward" }),
                    t("ui.Send backward")),
                React.createElement(MenuItem, { onClick: function () { return _this.reorderAction("sendToBack"); } },
                    React.createElement("i", { className: "fad fa-send-back" }),
                    t("ui.Send to back"))),
            React.createElement(MenuDivider, null),
            React.createElement(MenuItem, { key: "Delete", onClick: function () { return deleteSelected("contextmenu"); }, disabled: !itemsSelected },
                React.createElement("i", { className: "far fa-trash-alt" }),
                t("Delete")));
    };
    EditorContextMenu.prototype.groupUngroupSection = function () {
        var _this = this;
        var children = [];
        if (this.props.selected.shouldAllowUngrouping) {
            children.push(React.createElement(MenuItem, { key: "Ungroup", onClick: function () {
                    var command = _this.props.selected.createUngroupObjectsCommand();
                    if (command) {
                        g("Grouping", "Ungroup-ContextMenu");
                        commandProcessor.execute(command);
                    }
                } },
                React.createElement("i", { className: "far fa-object-ungroup" }),
                t("ui.Ungroup objects")));
        }
        if (this.props.selected.shouldAllowRegrouping) {
            children.push(React.createElement(MenuItem, { key: "Regroup", onClick: function () {
                    var command = _this.props.selected.createRegroupObjectsCommand();
                    if (command) {
                        g("Grouping", "Regroup-ContextMenu");
                        commandProcessor.execute(command);
                    }
                } },
                React.createElement("i", { className: "fad fa-object-group" }),
                t("ui.Regroup object")));
        }
        if (children.length == 0 || this.props.selected.shouldAllowGrouping) {
            // Ensure there is at least one button: Group.
            children.unshift(React.createElement(MenuItem, { key: "Group", disabled: !this.props.selected.shouldAllowGrouping, onClick: function () {
                    var command = _this.props.selected.createGroupObjectsCommand();
                    if (command) {
                        g("Grouping", "Group-ContextMenu");
                        commandProcessor.execute(command);
                    }
                } },
                React.createElement("i", { className: "far fa-object-group" }),
                t("ui.Group objects")));
        }
        return children;
    };
    EditorContextMenu.prototype.extractSelectedIds = function () {
        return this.props.selected.items.map(function (a) { return a.id; });
    };
    return EditorContextMenu;
}(React.Component));
export { EditorContextMenu };
