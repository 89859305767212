import { setDefaultStyleWithStroke } from "apparatus/draw";
import { P } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
import { Shape } from "paper";
var GlassWool = /** @class */ (function () {
    function GlassWool() {
    }
    GlassWool.prototype.render = function (appearance) {
        var graphic = SVG.glassWool();
        graphic.scale(1.5 * appearance.scale, P(0, 0));
        graphic.scale(1.0, appearance.squish, P(0, 0));
        setDefaultStyleWithStroke("thinner", graphic);
        graphic.fillColor = appearance.color + '80';
        var hitShape = Shape.Rectangle(graphic.bounds);
        return { graphic: graphic, hitShape: hitShape };
    };
    GlassWool.properties = {
        label: "Glass Wool",
        defaultAppearance: {
            scale: 1.0,
            squish: 1.0,
            color: "#dddddd"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.5, max: 2.0, step: 0.1,
            },
            {
                spec: "slider", key: "squish", label: "Squish",
                min: 0.6, max: 1.0, step: 0.01,
            },
            {
                spec: "color", key: "color", label: "Colour",
                swatches: {
                    "White": "#ffffff",
                    "Grey": "#dddddd",
                    "Light blue": "#D1E4EB",
                }
            }
        ],
    };
    return GlassWool;
}());
export { GlassWool };
