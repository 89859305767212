import { lerp, P, Polar, SharedColors } from "apparatus/library/common";
import { Group, Path, Segment } from "paper";
var Spring = /** @class */ (function () {
    function Spring() {
    }
    Spring.prototype.render = function (appearance) {
        var compression = appearance.compression;
        var spring = new Path().withStroke("thick", appearance.shade ? "#777777" : SharedColors.stroke);
        var radius = appearance.diameter / 2;
        var distance = radius * lerp(5, 0.0, Math.pow(compression, 0.5)) + 7;
        var handle = distance * lerp(0.15, 0.3, compression);
        var handle2 = distance * lerp(0.3, 0.5, compression);
        var loops = appearance.loops;
        for (var i = 0; i < loops; i++) {
            spring.addSegments([
                new Segment(P(-radius, i * distance), Polar(handle, -90), Polar(handle, 90)),
                new Segment(P(radius, (i + 0.5) * distance), Polar(handle2, -90), Polar(handle2, 90))
            ]);
        }
        var graphic = new Group([spring]);
        if (appearance.shade) {
            var shade1 = spring.clone();
            var gradient1 = [];
            var gradientStep = 1.0 / (loops * 2 - 1) * 2.0;
            for (var i = 0; i < loops; i++) {
                gradient1.push(["#00000000", (i + 0.0) * gradientStep], ["#000000cc", Math.min(1, (i + 0.15) * gradientStep)], ["#000000cc", Math.min(1, (i + 0.35) * gradientStep)], ["#00000000", Math.min(1, (i + 0.5) * gradientStep)]);
            }
            shade1.setGradientStroke("down", gradient1);
            var shade2 = spring.clone();
            shade2.strokeWidth = Math.max(1, shade2.strokeWidth - 2);
            shade2.setGradientStroke("right", [["#ffffff00", 0.1], ["#ffffff60", 0.4], ["#ffffff00", 0.7]]);
            graphic.addChildren([shade1, shade2]);
        }
        spring.addSegments([new Segment(P(0, loops * distance + 7.5), P(0, -15))]);
        spring.insertSegments(0, [new Segment(P(0, -distance * 0.5 - 7.5), undefined, P(0, 15))]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(5).toShape()
        };
    };
    Spring.properties = {
        label: "Spring",
        flippable: true,
        defaultAppearance: {
            diameter: 15,
            loops: 6,
            compression: 0.3,
            shade: false,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "loops", label: "Loops",
                min: 3, max: 20, step: 1,
            },
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 10, max: 50, step: 1.0, unit: "mm"
            },
            {
                spec: "slider", key: "compression", label: "Compression",
                min: 0, max: 1, step: 0.01, unit: "%",
            },
            {
                spec: "checkbox", key: "shade", label: "Shading", subLabel: "3D", allowWhenLocked: true,
                tier: "boost"
            }
        ],
        isBoostRequired: function (a) { return a.shade; }
    };
    return Spring;
}());
export { Spring };
