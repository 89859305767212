import { strokeWidth } from "apparatus/common";
import { extrude, minBounds, mirrorX, mirrorY } from "apparatus/draw";
import { P, SharedColors } from "apparatus/library/common";
import "ext/js";
import { CompoundPath, Item, Path, Point, Rectangle, Shape } from "paper";
Item.prototype.withScale = function (scale, center) {
    this.scale(scale, center);
    return this;
};
// Gradient fill an item.
Item.prototype.setGradientFill = function (direction, stops) {
    var _a = startStop(this.bounds, direction), start = _a.start, end = _a.end;
    var gradient = new paper.Gradient(stops);
    this.fillColor = new paper.Color(gradient, start, end);
};
Item.prototype.withGradientFill = function (direction, stops) {
    this.setGradientFill(direction, stops);
    return this;
};
Item.prototype.setGradientStroke = function (direction, stops) {
    var _a = startStop(this.bounds, direction), start = _a.start, end = _a.end;
    var gradient = new paper.Gradient(stops);
    this.strokeColor = new paper.Color(gradient, start, end);
    return this;
};
Item.prototype.setRadialGradientFill = function (stops, opts) {
    var _a, _b;
    var start = (_a = opts === null || opts === void 0 ? void 0 : opts.center) !== null && _a !== void 0 ? _a : this.bounds.center;
    var end = (_b = opts === null || opts === void 0 ? void 0 : opts.edge) !== null && _b !== void 0 ? _b : this.bounds.rightCenter;
    var gradient = new paper.Gradient(stops, true);
    this.fillColor = new paper.Color(gradient, start, end, opts === null || opts === void 0 ? void 0 : opts.highlight);
    return this;
};
Item.prototype.setRadialGradientStroke = function (stops) {
    var start = this.bounds.center;
    var end = this.bounds.rightCenter;
    var gradient = new paper.Gradient(stops, true);
    this.strokeColor = new paper.Color(gradient, start, end);
};
Item.prototype.enableScalableGradient = function () {
    this.applyMatrix = false;
    return this;
};
Item.prototype.withStroke = function (width, color) {
    var widthNumber = (typeof width === "string") ? strokeWidth(width) : width;
    this.strokeColor = color !== null && color !== void 0 ? color : SharedColors.stroke;
    this.strokeWidth = widthNumber;
    return this;
};
Item.prototype.withFill = function (color) {
    this.fillColor = color;
    return this;
};
Item.prototype.withPivot = function (pivot) {
    this.pivot = pivot;
    return this;
};
Item.prototype.withPosition = function (position) {
    if (position.x !== undefined)
        this.position.x = position.x;
    if (position.y !== undefined)
        this.position.y = position.y;
    return this;
};
Item.prototype.withPositionDelta = function (delta) {
    var _a, _b;
    this.position.x += (_a = delta.x) !== null && _a !== void 0 ? _a : 0;
    this.position.y += (_b = delta.y) !== null && _b !== void 0 ? _b : 0;
    return this;
};
Item.prototype.withRotation = function (angle) {
    this.rotate(angle);
    return this;
};
Item.prototype.placeable = function () {
    return {
        type: "placeable",
        at: this.strokeBounds.bottomCenter,
    };
};
Item.prototype.topEdgeAsPlaceableSurface = function () {
    return {
        type: "placeable_surface",
        start: this.strokeBounds.topLeft,
        end: this.strokeBounds.topRight,
    };
};
Item.prototype.withDefaultStyle = function () {
    this.strokeWidth = strokeWidth("default");
    this.strokeColor = "#333333";
    this.fillColor = "#ddddddcc";
    return this;
};
Item.prototype.rectHitShape = function () {
    return minBounds(this);
};
Path.prototype.close = function () {
    this.closePath();
    return this;
};
Path.prototype.mirrorY = function (y) {
    if (y === void 0) { y = 0; }
    mirrorY(this, y);
    return this;
};
Path.prototype.mirrorX = function (x) {
    if (x === void 0) { x = 0; }
    mirrorX(this, x);
    return this;
};
Path.prototype.extrude = function (direction) {
    extrude(this, direction);
    return this;
};
Point.prototype.toPlain = function () {
    return { x: this.x, y: this.y };
};
Point.prototype.toArray = function () {
    return [this.x, this.y];
};
Point.prototype.scale = function (scale, center) {
    var delta = this.subtract(center);
    // If scale = 1, this should be no op.
    return this.add(delta.multiply(scale - 1));
};
/**
 * Utility function that uses a compact syntax to create a CompoundPath. E.g.
 * instead of:
 *    let path = new CompoundPath({})
 *    path.moveTo(new Point(10, 10))
 *    path.lineTo(new Point(20, 20))
 * It is possible to write:
 *    let path = createCompoundPath(
 *       ["moveTo", 10, 10],
 *       ["lineTo", 20, 20],
 *    )
 */
export function createCompoundPath() {
    var instructions = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        instructions[_i] = arguments[_i];
    }
    var path = new CompoundPath({});
    for (var _a = 0, instructions_1 = instructions; _a < instructions_1.length; _a++) {
        var instruction = instructions_1[_a];
        var command = instruction[0], x = instruction[1], y = instruction[2];
        var point = P(x, y);
        switch (command) {
            case "lineBy":
                path.lineBy(point);
                break;
            case "moveBy":
                path.moveBy(point);
                break;
            case "moveTo":
                path.moveTo(point);
                break;
            case "lineTo":
                path.lineTo(point);
                break;
        }
    }
    return path;
}
function startStop(bounds, direction) {
    switch (direction) {
        case "up":
            return { start: bounds.bottomCenter, end: bounds.topCenter };
        case "right-up":
            return { start: bounds.bottomLeft, end: bounds.topRight };
        case "right":
            return { start: bounds.leftCenter, end: bounds.rightCenter };
        case "right-down":
            return { start: bounds.topLeft, end: bounds.bottomRight };
        case "down":
            return { start: bounds.topCenter, end: bounds.bottomCenter };
        case "left-down":
            return { start: bounds.topRight, end: bounds.bottomLeft };
        case "left":
            return { start: bounds.rightCenter, end: bounds.leftCenter };
        case "left-up":
            return { start: bounds.bottomRight, end: bounds.topLeft };
    }
}
// Expands the current rectangle, in place, to include the other rectangle.
Rectangle.prototype.united = function (rect) {
    this.top = Math.min(this.top, rect.top);
    this.left = Math.min(this.left, rect.left);
    this.right = Math.max(this.right, rect.right);
    this.bottom = Math.max(this.bottom, rect.bottom);
};
Rectangle.prototype.toShape = function () {
    return Shape.Rectangle(this);
};
