import { mirrorX, setDefaultStyleWithStroke } from "apparatus/draw";
import { R, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var Dropper = /** @class */ (function () {
    function Dropper() {
    }
    Dropper.prototype.render = function (appearance) {
        var height = appearance.size;
        var width = 8 + height / 15;
        var handle = this.handle();
        setDefaultStyleWithStroke("thin", handle);
        handle.fillColor = "#555555";
        handle.closePath();
        var body = this.body(width, height);
        // Center the pipette body.
        body.position.x += handle.bounds.center.x - body.bounds.center.x;
        var liquidMask = body.clone();
        setDefaultStyleWithStroke("thin", body);
        var graphic = new Group([body, handle]);
        var hitShape = Shape.Rectangle(R(0, -handle.bounds.height, handle.bounds.width, graphic.bounds.height));
        return {
            graphic: graphic,
            hitShape: hitShape,
            liquidMask: liquidMask,
            snapping: graphic.placeable(),
        };
    };
    Dropper.prototype.body = function (width, height) {
        var mouthSize = 5;
        var path = new Path(Segments([[width / 2 - mouthSize / 2, height + 12]], [[width / 2 - mouthSize / 2, height + 7]], [[0, height], [0, 7]], [[0, 0]]));
        mirrorX(path, width / 2);
        return path;
    };
    Dropper.prototype.handle = function () {
        var height = 30;
        var width = 20;
        var path = new Path(Segments([[0, 0]], [[0, -5], undefined, [10, -5]], [[width / 2, -height], [-12, 0]]));
        mirrorX(path, width / 2);
        return path;
    };
    Dropper.properties = {
        label: "Dropper Pipette",
        canContainLiquids: true,
        defaultAppearance: {
            size: 60
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 30, max: 80
            }
        ],
    };
    return Dropper;
}());
export { Dropper };
