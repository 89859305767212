var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P, Pivot, R } from "apparatus/library/common";
import { Mounted } from "apparatus/library/physics/electric/mounted/utils";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var MountedBuzzer = /** @class */ (function () {
    function MountedBuzzer() {
    }
    MountedBuzzer.prototype.render = function (appearance) {
        var _a = Mounted.renderPlate({ appearance: appearance, width: 80, height: 55 }), base = _a.base, jacks = _a.jacks, snaps = _a.snaps;
        // Draw buzzer
        var width = 40;
        var height = 25;
        var depth = 15;
        var buzzer = new Group([
            // Shade.
            new Path.Rectangle(R(0, 0, width, height + depth, Pivot.TOP_CENTER), 4.0).withFill("#888888"),
            // Top.
            new Path.Rectangle(R(0, 0, width, height, Pivot.TOP_CENTER), 4.0).withFill("#aaaaaa"),
            // Outline.
            new Path.Rectangle(R(0, 0, width, height + depth, Pivot.TOP_CENTER), 4.0).withStroke("default"),
        ]);
        // Add grills
        for (var y = 5; y <= height - 4; y += 5) {
            buzzer.addChild(new Path.Line(P(-14, y), P(14, y)).withStroke(2, "#666666"));
        }
        buzzer.withPositionDelta(P(40, -10));
        var graphic = new Group([base, jacks, buzzer]);
        var hitShape = graphic.bounds.toShape();
        var pivot = hitShape.bounds.center;
        return {
            graphic: graphic,
            hitShape: hitShape,
            pivot: pivot,
            snapping: __spreadArray([], snaps)
        };
    };
    MountedBuzzer.properties = {
        label: "Buzzer",
        defaultAppearance: __assign({}, Mounted.defaultAppearance),
        appearanceSpecs: __spreadArray([], Mounted.appearanceSpecs),
    };
    MountedBuzzer = __decorate([
        staticImplements()
    ], MountedBuzzer);
    return MountedBuzzer;
}());
export { MountedBuzzer };
