import { strokeWidth } from "apparatus/common";
import { mirrorX } from "apparatus/draw";
import { Segments, SharedColors } from "apparatus/library/common";
import { Group, Path } from "paper";
var ThermosFlask = /** @class */ (function () {
    function ThermosFlask() {
    }
    ThermosFlask.prototype.render = function (appearance) {
        var wallThickness = 8;
        var neckSize = appearance.neckSize;
        var radius = appearance.width / 2;
        var height = appearance.height;
        var outline = new Path(Segments([[neckSize, 0]], [[neckSize, 10], , [0, 15]], [[radius, 40], [0, -15]], [[radius, height - 20], , [0, 10]], [[radius - 20, height], [10, 0]]));
        mirrorX(outline);
        // Create the liquid mask first. The mask is closed but the
        // graphics is not.
        var liquidMask = outline.clone();
        liquidMask.closePath();
        // Reuse the outline as the thicker outline, forming the walls.
        outline.strokeCap = "round";
        var innerWall = outline.clone();
        outline.withStroke(strokeWidth("default") * 2 + wallThickness, SharedColors.stroke);
        innerWall.withStroke(wallThickness, "white");
        var graphic = new Group([outline, innerWall]);
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: outline.strokeBounds.toShape(),
            snapping: outline.placeable()
        };
    };
    ThermosFlask.properties = {
        label: "Vacuum Flask",
        canContainLiquids: true,
        meniscus: true,
        defaultAppearance: {
            neckSize: 18,
            height: 240,
            width: 80,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Diameter",
                min: 60, max: 90,
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 160, max: 250,
            },
            {
                spec: "slider", key: "neckSize", label: "Neck Size",
                min: 12, max: 20, step: 0.5
            }
        ],
    };
    return ThermosFlask;
}());
export { ThermosFlask };
