var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { captureMessage } from "@sentry/react";
import { g } from "analytics";
import { reauthFromCurrentLogin } from "api/auth/reauth";
import { CommonModal } from "components/common_modal";
import Flags from "flags";
import { t } from "i18next";
import * as React from 'react';
import { Toastr } from "setup";
import appStore from "store/store";
import { Disclaimer, PlansGrid } from "tiers/promo_plus";
import { ComparisonTable } from "tiers/promo_table";
import { Toast } from "toast";
import { Consts } from "vars";
import { callApi } from "../api/api";
import { isSafari } from "../utils";
/** Maximum number of liquid layers that Boost users can add to a container. */
export var MAX_BOOST_DIAGRAM_LAYERS = 5;
/** Maximum number of diagrams that Boost users can store in cloud. */
export var BOOST_TOTAL_DIAGRAMS = 75;
/** Maximum number of diagrams that free users can store in cloud. */
export var FREE_TOTAL_DIAGRAMS = 3;
/** Number of item customisations */
export var ITEM_CUSTOMISATIONS = "200+";
var PlusPromoModal = /** @class */ (function (_super) {
    __extends(PlusPromoModal, _super);
    function PlusPromoModal(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            schoolPlanStudents: 0,
            schoolPlanMaxDiagrams: 0,
        };
        return _this;
    }
    PlusPromoModal.prototype.checkout = function (plan, currency, interval) {
        var _this = this;
        g("CheckoutButtonClick", this.props.analyticsTag);
        var request = {
            plan: plan,
            currency: currency,
            interval: interval,
            scheme: Flags.isNewPricingEnabled ? "2022" : "original",
        };
        // On Safari, we need to open the window immediately, otherwise it gets
        // blocked and it's hard to open it.
        // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
        var windowReferenceForSafari;
        if (isSafari()) {
            windowReferenceForSafari = window.open();
            windowReferenceForSafari === null || windowReferenceForSafari === void 0 ? void 0 : windowReferenceForSafari.document.write("<html><body><center style='margin-top:5em'>Retrieving information, please wait...</center></body></html>");
        }
        callApi("plans/createCheckoutSession", "POST", appStore.loggedInState(), request, {
            success: function (response) {
                var _a;
                // There are two cases:
                if (response.sessionId) {
                    // 1) User does not yet have a plan. Checkout session has been created.
                    var sessionId = response.sessionId;
                    var checkoutUrl = "./checkout.html?sessionId=" + sessionId + "&stripePk=" + Consts.stripePublicKey;
                    if (windowReferenceForSafari) {
                        // For Safari, just assign the location.
                        windowReferenceForSafari.location.href = checkoutUrl;
                    }
                    else {
                        // Otherwise use window.open.
                        window.open(checkoutUrl);
                    }
                    g("CheckoutWindowOpen", _this.props.analyticsTag);
                }
                else if (response.tier) {
                    var tier = response.tier;
                    reauthFromCurrentLogin();
                    Toast.success(t("promo.checkout_success.description"), t("promo.checkout_success.title", { tier: (_a = tier === null || tier === void 0 ? void 0 : tier.capitalise()) !== null && _a !== void 0 ? _a : "" }));
                    _this.props.setIsOpen(false);
                    g("CheckoutWindowOpen", "RecoverSuccess");
                }
                else {
                    // Unknown case.
                    captureMessage("Unexpected response from createCheckoutSession", "error");
                }
            },
            special: function (error) {
                if (error.error_type === "conflicting_currency") {
                    Toast.warning(t("promo.currency_error.description")
                        + Toastr.contactSupportButton("openRequestCurrencyFix"), t("promo.currency_error.title"));
                    return true;
                }
                return false;
            }
        });
    };
    PlusPromoModal.prototype.render = function () {
        var _this = this;
        return React.createElement(CommonModal, { isOpen: this.props.isOpen, setIsOpen: this.props.setIsOpen, extraClass: "promo-modal wide", title: t("promo.title"), icon: "fa-bolt-lightning" },
            React.createElement(PlansGrid, { currentTier: this.props.tier, defaultCurrency: this.props.defaultCurrency, onCheckout: function (plan, currency, interval) { return _this.checkout(plan, currency, interval); }, signedIn: this.props.auth.state == "logged_in", onClose: function () { return _this.props.setIsOpen(false); }, onUpdateSchoolPlan: function (_a) {
                    var students = _a.students, maxDiagrams = _a.maxDiagrams;
                    return _this.setState({ schoolPlanStudents: students, schoolPlanMaxDiagrams: maxDiagrams });
                }, analyticsTag: this.props.analyticsTag }),
            React.createElement(Disclaimer, null),
            React.createElement(ComparisonTable, { students: this.state.schoolPlanStudents, maxDiagrams: this.state.schoolPlanMaxDiagrams }));
    };
    return PlusPromoModal;
}(React.PureComponent));
export { PlusPromoModal };
export var fadColors = function (primary, secondary) { return ({
    "--fa-primary-color": primary,
    "--fa-secondary-color": secondary,
    "--fa-secondary-opacity": 1.0,
}); };
