var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { g } from "analytics";
import { UpdateAppearance, UpdateAppearanceProperties, UpdateItemsAppearance } from "command/basic";
import { commandProcessor } from "command/command";
import { convertDemoSwatchesToRadioOptions } from "panel/shared";
import * as React from "react";
import { Articles } from "support/beacon";
import { ChemMark } from "text/parse";
import { createChemMarkGlobalConfigElements, PropertyPanelHint } from "text/ui";
import { crumb } from "utils";
/** Sentinel "indeterminate" value used for a group of radio buttons. A random string unlikely to be used as an actual value. */
export var SENTINEL_INDETERMINATE_RADIO = "@_x/.!";
/** Text to display as placeholder for text inputs with indeterminate values. */
export var SENTINEL_TEXT_PLACEHOLDER = "Multiple values";
function createUpdateCommand(items, key, newValue) {
    if (items.length > 1) {
        var values = items.map(function (i) { return ({ id: i.id, value: newValue, previousValue: i.appearance[key] }); });
        return new UpdateItemsAppearance(key, values);
    }
    else {
        var item = items[0];
        return new UpdateAppearance(item.id, key, newValue, item.appearance[key]);
    }
}
function getConsistentValue(items, key) {
    var value = items[0].appearance[key];
    if (items.length == 1)
        return value;
    for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
        var item = items_1[_i];
        if (item.appearance[key] !== value)
            return;
    }
    return value;
}
export function convertAppearanceSpecs(specs, item, items, app, tiers) {
    return specs.filter(function (spec) { var _a; return ((_a = spec.visible) !== null && _a !== void 0 ? _a : (function (a) { return true; }))(item.appearance); }) // Filter out specs set to invisible, if visibility fn is defined.
        .map(function (spec) {
        var _a, _b, _c;
        switch (spec.spec) {
            case "slider":
                // Create a slider.
                // Sliders are not reusable and so need a unique key.
                return __assign(__assign({}, spec), { spec: "slider", key: item.type + "." + spec.key, initialValue: item.appearance[spec.key], onStart: function () { app.setIsInteracting(true); }, onChange: function (value) {
                        var _a;
                        for (var _i = 0, items_2 = items; _i < items_2.length; _i++) {
                            var item_1 = items_2[_i];
                            (_a = app.getApparatusComponent(item_1.id)) === null || _a === void 0 ? void 0 : _a.updateAppearanceAttributes(spec.key, value);
                        }
                    }, onEnd: function (_, final) {
                        app.setIsInteracting(false);
                        g("UpdateAppearance", item.type + "::" + spec.key);
                        crumb("ui.props", item.type + ": " + spec.key + " -> " + final);
                        commandProcessor.execute(createUpdateCommand(items, spec.key, final));
                    } });
            case "radio": {
                // Create a group of radio buttons.
                var currentValue_1 = (_a = getConsistentValue(items, spec.key)) !== null && _a !== void 0 ? _a : SENTINEL_INDETERMINATE_RADIO;
                return __assign(__assign({}, spec), { id: spec.key, value: currentValue_1, onChange: function (_, final) {
                        g("UpdateAppearance", item.type + "::" + spec.key);
                        crumb("ui.props", item.type + ": " + spec.key + " -> " + final);
                        commandProcessor.execute(createUpdateCommand(items, spec.key, final));
                    } });
            }
            case "select":
                // Create a group of radio buttons.
                return __assign(__assign({}, spec), { id: spec.key, value: item.appearance[spec.key], onSelectChange: function (_, final) {
                        crumb("ui.props", item.type + ": " + spec.key + " -> " + final);
                        commandProcessor.execute(createUpdateCommand(items, spec.key, final));
                    } });
            case "toggle-buttons": {
                // Identify the highlight index.
                var currentValue_2 = getConsistentValue(items, spec.key);
                var highlightIndex = -1;
                if (currentValue_2 !== undefined) {
                    for (var i = 0; i < spec.options.length; i++) {
                        if (spec.options[i].value == currentValue_2) {
                            highlightIndex = i;
                            break;
                        }
                    }
                }
                return {
                    spec: "toggle-horizontal-buttons",
                    id: spec.key,
                    key: item.id.id + "_" + spec.key,
                    label: spec.label,
                    highlightIndex: highlightIndex,
                    size: spec.size,
                    buttonsPerRow: spec.buttonsPerRow,
                    allowSelectingTierLockedOptions: spec.allowSelectingTierLockedOptions,
                    buttons: spec.size === "text" ? spec.options.map(function (o) { return ({
                        icon: "",
                        buttonLabel: o.label,
                        hint: o.hint,
                        tier: o.tier,
                        onClick: function () {
                            crumb("ui.props", item.type + ": " + spec.key + " -> " + o.value);
                            commandProcessor.execute(createUpdateCommand(items, spec.key, o.value));
                        }
                    }); }) : spec.options.map(function (o) { return ({
                        icon: o.icon,
                        buttonLabel: o.label,
                        hint: o.hint,
                        tier: o.tier,
                        onClick: function () {
                            crumb("ui.props", item.type + ": " + spec.key + " -> " + o.value);
                            commandProcessor.execute(createUpdateCommand(items, spec.key, o.value));
                        }
                    }); })
                };
            }
            case "text": {
                var currentValue_3 = getConsistentValue(items, spec.key);
                return __assign(__assign({}, spec), { key: item.id.id + "_" + spec.key + "_" + currentValue_3, spec: "text", id: spec.key, text: currentValue_3 !== null && currentValue_3 !== void 0 ? currentValue_3 : "", placeholder: SENTINEL_TEXT_PLACEHOLDER.if(currentValue_3 === undefined), onFocus: function () { app.setIsEditingText(true); }, onChange: function (value) {
                        var _a;
                        for (var _i = 0, items_3 = items; _i < items_3.length; _i++) {
                            var item_2 = items_3[_i];
                            (_a = app.getApparatusComponent(item_2.id)) === null || _a === void 0 ? void 0 : _a.updateAppearanceAttributes(spec.key, value);
                        }
                    }, onEnd: function (previous, final) {
                        app.setIsEditingText(false);
                        if (previous == final)
                            return;
                        g("UpdateAppearance", item.type + "::" + spec.key);
                        crumb("ui.props", item.type + ": " + spec.key + " -> " + final);
                        commandProcessor.execute(createUpdateCommand(items, spec.key, final));
                    } });
            }
            case "textarea":
                var currentValue = getConsistentValue(items, spec.key);
                return __assign(__assign({}, spec), { key: item.id.id + "_" + spec.key + "_" + currentValue, spec: "textarea", id: spec.key, text: currentValue !== null && currentValue !== void 0 ? currentValue : "", placeholder: SENTINEL_TEXT_PLACEHOLDER.if(currentValue === undefined), onFocus: function () { app.setIsEditingText(true); }, onChange: function (value) {
                        var _a;
                        for (var _i = 0, items_4 = items; _i < items_4.length; _i++) {
                            var item_3 = items_4[_i];
                            (_a = app.getApparatusComponent(item_3.id)) === null || _a === void 0 ? void 0 : _a.updateAppearanceAttributes(spec.key, value);
                        }
                    }, onEnd: function (previous, final) {
                        app.setIsEditingText(false);
                        if (previous == final)
                            return;
                        g("UpdateAppearance", item.type + "::" + spec.key);
                        crumb("ui.props", item.type + ": " + spec.key + " -> " + final);
                        commandProcessor.execute(createUpdateCommand(items, spec.key, final));
                    } });
            case "color":
                var startColor_1 = item.appearance[spec.key];
                // Depending on whether this is supported or not on the
                // current plan, return either a full color picker
                // or a limited radio list from demo swatches.
                if (!tiers.allow(spec) && spec.demoSwatches) {
                    // Return a limited set of choices as radios.
                    return {
                        spec: "radio",
                        id: spec.key,
                        key: spec.key,
                        label: spec.label,
                        visible: spec.visible,
                        options: convertDemoSwatchesToRadioOptions(spec.demoSwatches),
                        value: startColor_1,
                        onChange: function (_, final) {
                            crumb("ui.props", item.type + ": " + spec.key + " -> " + final);
                            commandProcessor.execute(createUpdateCommand(items, spec.key, final));
                        }
                    };
                }
                // Return a normal color picker.
                return __assign(__assign({}, spec), { id: spec.key, 
                    // Colorpicker needs a unique key per instance.
                    key: spec.key + "_" + item.id.id, value: startColor_1, onColorChange: function (value) {
                        var _a;
                        for (var _i = 0, items_5 = items; _i < items_5.length; _i++) {
                            var item_4 = items_5[_i];
                            // TODO: Fix this bug where component may be null
                            (_a = app.getApparatusComponent(item_4.id)) === null || _a === void 0 ? void 0 : _a.updateAppearanceAttributes(spec.key, value);
                        }
                    }, onColorEnd: function (final) {
                        if (final == startColor_1)
                            return;
                        g("UpdateAppearance", item.type + "::" + spec.key);
                        crumb("ui.props", item.type + ": " + spec.key + " -> " + final);
                        commandProcessor.execute(createUpdateCommand(items, spec.key, final));
                    }, opacity: spec.opacity
                        ? opacitySettingsForColorpicker(items, app, spec.opacity)
                        : undefined });
            case "checkbox":
                /** Convert to a checkbox-group with 1 check box. */
                var cg = {
                    spec: "checkbox-group",
                    label: spec.label,
                    key: spec.key,
                    checkboxes: [
                        __assign(__assign({}, spec), { id: spec.key, label: (_b = spec.subLabel) !== null && _b !== void 0 ? _b : "", value: getConsistentValue(items, spec.key), useSwitch: spec.useSwitch, onCheckedChange: function (newValue) {
                                g("UpdateAppearance", item.type + "::" + spec.key);
                                crumb("ui.props", item.type + ": " + spec.key + " -> " + newValue);
                                commandProcessor.execute(createUpdateCommand(items, spec.key, newValue));
                            } }),
                    ]
                };
                return cg;
            case "checkbox-group": {
                var checkboxSpecs = spec.checkboxes
                    .filter(function (cSpec) { var _a; return ((_a = cSpec.visible) !== null && _a !== void 0 ? _a : (function (a) { return true; }))(item.appearance); })
                    .map(function (cSpec) { return (__assign(__assign({}, cSpec), { id: cSpec.key, value: getConsistentValue(items, cSpec.key), useSwitch: cSpec.useSwitch, onCheckedChange: function (newValue) {
                        g("UpdateAppearance", item.type + "::" + cSpec.key);
                        crumb("ui.props", item.type + ": " + cSpec.key + " -> " + newValue);
                        commandProcessor.execute(createUpdateCommand(items, cSpec.key, newValue));
                    } })); });
                if (checkboxSpecs.length == 0)
                    return React.createElement("div", { key: spec.label + "_empty" });
                return __assign(__assign({}, spec), { key: spec.label, visible: checkboxSpecs.length > 0, checkboxes: checkboxSpecs });
            }
            case "header": {
                var toggleKey_1 = spec.toggleKey;
                return __assign(__assign({ key: "__fakeKey" + spec.header, label: "" }, spec), { toggle: toggleKey_1
                        ? {
                            id: toggleKey_1,
                            value: getConsistentValue(items, toggleKey_1),
                            useSwitch: true,
                            onCheckedChange: function (newValue) {
                                g("UpdateAppearance", item.type + "::" + toggleKey_1);
                                crumb("ui.props", item.type + ": " + toggleKey_1 + " -> " + newValue);
                                commandProcessor.execute(createUpdateCommand(items, toggleKey_1, newValue));
                            }
                        }
                        : undefined });
            }
            case "button": {
                return {
                    spec: "button",
                    key: spec.label,
                    label: "",
                    buttonLabel: spec.label,
                    onClick: function () {
                        // TODO: Support this for multiple items.
                        var newValues = spec.onClick(item.appearance);
                        var prevValues = {};
                        for (var key in newValues) {
                            prevValues[key] = item.appearance[key];
                        }
                        var command = new UpdateAppearanceProperties(item.id, newValues, prevValues);
                        commandProcessor.execute(command);
                    }
                };
            }
            case "color-wells": {
                var currentValue_4 = (_c = getConsistentValue(items, spec.key)) !== null && _c !== void 0 ? _c : undefined;
                return __assign(__assign({}, spec), { value: currentValue_4, onColorSelected: function (color) {
                        commandProcessor.execute(createUpdateCommand(items, spec.key, color));
                    } });
            }
            case "array":
                return {
                    spec: "text",
                    id: spec.key,
                    key: spec.key,
                    label: "Hello",
                    text: "NOT WORKING",
                    onChange: function (value) {
                    },
                    onEnd: function (previous, final) {
                    }
                };
        }
    });
}
function opacitySettingsForColorpicker(items, app, spec) {
    return {
        value: items[0].appearance[spec.key],
        range: spec.range,
        onChange: function (value) {
            var _a;
            for (var _i = 0, items_6 = items; _i < items_6.length; _i++) {
                var item = items_6[_i];
                (_a = app.getApparatusComponent(item.id)) === null || _a === void 0 ? void 0 : _a.updateAppearanceAttributes(spec.key, value);
            }
        },
        onEnd: function (_, final) {
            g("UpdateAppearance", items[0].type + "::" + spec.key);
            commandProcessor.execute(createUpdateCommand(items, spec.key, final));
        }
    };
}
export function chemMarkPropsSection(items, key) {
    return {
        header: "ChemText",
        icon: ChemMark.faIconName,
        helpArticleId: Articles.CHEMTEXT,
        elements: __spreadArray([
            {
                spec: "checkbox-group",
                key: "is_chem_mark_enabled",
                label: ChemMark.uiDescription(),
                wideLabel: true,
                checkboxes: [{
                        label: "",
                        useSwitch: true,
                        id: "is_chem_mark_enabled",
                        value: items[0].appearance[key],
                        tier: "boost",
                        allowWhenLocked: true,
                        onCheckedChange: function (value) {
                            g("UpdateAppearance", items[0].type + "::ChemMark");
                            commandProcessor.execute(createUpdateCommand(items, key, value));
                        }
                    }],
            },
            React.createElement(PropertyPanelHint, { key: "chemmark_hint", showUpgradeHint: items[0].appearance[key] })
        ], createChemMarkGlobalConfigElements(items[0].appearance[key]))
    };
}
