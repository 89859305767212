import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var SlottedWeight = /** @class */ (function () {
    function SlottedWeight() {
    }
    SlottedWeight.prototype.render = function (appearance) {
        var showHook = appearance.hook, hookLength = appearance.hookLength, count = appearance.count, width = appearance.width, height = appearance.height;
        var graphic = new Group([]);
        for (var i = 0; i < count; i++) {
            graphic.addChild(Shape.Rectangle(R(0, i * height, width, height, Pivot.TOP_CENTER), 3.0)
                .withGradientFill("right", Gradients.cylinder(SharedColors.lightMetal.color()))
                .withStroke("thin", SharedColors.mediumMetal));
        }
        if (showHook) {
            var hook = new Path(Segments([[0, 0]], [[0, -hookLength], , [10, -5]], [[0, -hookLength - 20], [10, 0], [-10, 0]], [[-10, -hookLength - 10]])).withStroke("default");
            graphic.addChild(hook);
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: showHook ? P(0, -hookLength - 20) : undefined,
            snapping: [
                { type: "placeable", at: P(0, height * count) },
                { type: "placeable_surface", start: P(-width / 2, 0), end: P(width / 2, 0) }
            ]
        };
    };
    SlottedWeight.properties = {
        label: "Slotted Mass",
        flippable: true,
        defaultAppearance: {
            hook: true,
            hookLength: 40,
            count: 1,
            width: 60,
            height: 18,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "count", label: "Number of weights",
                min: 1, max: 10,
            },
            {
                spec: "slider", key: "width", label: "Weight diameter",
                min: 20, max: 120, unit: "mm"
            },
            {
                spec: "slider", key: "height", label: "Weight thickness",
                min: 8, max: 25, unit: "mm"
            },
            { spec: "checkbox", key: "hook", label: "Hanger hook", subLabel: "Show" },
            {
                spec: "slider", key: "hookLength", label: "",
                min: 10, max: 100, unit: "mm"
            }
        ],
    };
    return SlottedWeight;
}());
export { SlottedWeight };
