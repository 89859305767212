var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// Wrapper for Bootstrap color picker 3.1.2
import { ComboSlider } from "components/combo_slider";
import { t } from "i18next";
import * as React from "react";
/** CSS class to locate the element that is used to set up the color picker. */
export var BOOTSTRAP_COLORPICKER_CSS_SELECTOR = ".ui-colorpicker2 .colorpicker-element";
export var DEFAULT_SWATCHES = {
    'Blue': '#80cce6',
    'Red': '#e33d4d',
    'Green': '#3ac433',
    'Yellow': '#d7d725',
    'Grey': '#a0a0a0',
    'Purple': '#ca57a4',
};
var ColorPicker = /** @class */ (function (_super) {
    __extends(ColorPicker, _super);
    function ColorPicker(props) {
        var _this = _super.call(this, props) || this;
        _this.ref = React.createRef();
        _this.state = { value: props.value };
        _this.swatches = props.swatches || DEFAULT_SWATCHES;
        _this.translatedSwatches = {};
        for (var name_1 in _this.swatches) {
            var translatedName = t(["props." + name_1, "ui." + name_1, name_1], name_1);
            _this.translatedSwatches[translatedName] = _this.swatches[name_1];
        }
        return _this;
    }
    ColorPicker.prototype.componentDidMount = function () {
        var jqElement = $(this.ref.current);
        var self = this;
        // 1. If adding any event handlers, make sure to remove them
        //    in componentWillUnmount
        // 2. When invoking callbacks with color values, remember convert
        //    color values to lower case, so that colors can be compared.
        jqElement
            .val(this.props.value)
            .colorpicker({
            color: this.state.value,
            popover: {
                placement: "left",
                boundary: "window",
            },
            extensions: [
                {
                    name: 'swatches',
                    options: {
                        colors: this.translatedSwatches,
                        nameAsValues: true,
                    }
                },
                { name: "manualinput" },
                { name: "extrabuttons" },
            ],
        })
            .on('colorpickerChange', function (e) {
            if (e.color) {
                var color = e.color.toString().toLowerCase();
                self.setState({ value: color });
                self.props.onColorChange(color);
            }
        })
            .on('colorpickerHide', function (e) {
            if (e.color) {
                var color = e.color.toString().toLowerCase();
                self.setState({ value: color });
                self.props.onColorEnd(color);
            }
        });
    };
    ColorPicker.prototype.componentDidUpdate = function (prevProps, prevState) {
        // Only update on props changes.
        if (prevProps.value != this.props.value) {
            var jqElement = $(this.ref.current);
            jqElement.colorpicker('setValue', this.props.value);
        }
    };
    ColorPicker.prototype.componentWillUnmount = function () {
        var jqElement = $(this.ref.current);
        var color = jqElement.colorpicker('getValue');
        if (color) {
            this.props.onColorEnd(color.toString().toLowerCase());
        }
        // Hide & Remove event handlers.
        jqElement.off('colorpickerChange');
        jqElement.off('colorpickerHide');
        jqElement.colorpicker('hide');
    };
    ColorPicker.prototype.renderOld = function () {
        return React.createElement("div", { className: "ui-colorpicker" },
            React.createElement("input", { ref: this.ref, style: { "borderRightColor": this.state.value } }));
    };
    ColorPicker.prototype.maybeMapValueToTranslatedSwatchName = function () {
        var value = this.state.value;
        for (var name_2 in this.translatedSwatches) {
            if (this.translatedSwatches[name_2] == value) {
                return name_2;
            }
        }
        return value;
    };
    ColorPicker.prototype.render = function () {
        var _a, _b;
        var opacity = this.props.opacity;
        return React.createElement("div", { className: "ui-colorpicker2" },
            React.createElement("div", { ref: this.ref },
                React.createElement("div", { className: "colorpicker-input-addon", style: { "backgroundColor": this.state.value } }),
                React.createElement("input", { className: "d-none" })),
            opacity == undefined
                ? this.maybeMapValueToTranslatedSwatchName()
                : React.createElement(ComboSlider, { key: "opacitySlider", min: ((_a = opacity.range) !== null && _a !== void 0 ? _a : [0, 1])[0], max: ((_b = opacity.range) !== null && _b !== void 0 ? _b : [0, 1])[1], step: 0.01, unit: "%", initialValue: opacity.value, onChange: opacity.onChange, onEnd: opacity.onEnd }));
    };
    return ColorPicker;
}(React.Component));
export { ColorPicker };
