import { P, R } from "apparatus/library/common";
import { Color, Gradient, Group, Shape } from "paper";
var SpottingTile = /** @class */ (function () {
    function SpottingTile() {
    }
    SpottingTile.prototype.render = function (appearance) {
        var wellDiameter = 20 * appearance.scale;
        var gap = 5 * appearance.scale;
        var shadowOffset = 5 * appearance.scale;
        var margin = 10 * appearance.scale;
        var rounded = 3 * appearance.scale;
        var _a = getDimensions(appearance), x = _a[0], y = _a[1];
        // Create tray.
        var tray = Shape.Rectangle(R(-margin - wellDiameter / 2, -margin - wellDiameter / 2, x * wellDiameter + (x - 1) * gap + 2 * margin, y * wellDiameter + (y - 1) * gap + 2 * margin), rounded);
        tray.fillColor = "#e0e0e0";
        tray.strokeColor = "#aaaaaa";
        tray.strokeWidth = 2.0;
        var graphic = new Group([tray]);
        // Gradient for the wells.
        var wellGradient = new Gradient([["#f3f3f3", 0.3], ["#bbbbbb", 1.0]], true);
        var snapPoints = [];
        // Create wells.
        for (var i = 0; i < x; i++) {
            for (var j = 0; j < y; j++) {
                var well = Shape.Circle(P(0, 0), wellDiameter / 2);
                well.fillColor = new Color(wellGradient, P(0, shadowOffset), P(0, wellDiameter * 1.2));
                var x_1 = i * (wellDiameter + gap);
                var y_1 = j * (wellDiameter + gap);
                well.position.set(x_1, y_1);
                graphic.addChild(well);
                snapPoints.push(P(x_1, y_1 + 1));
            }
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: {
                type: "spotting_tile",
                at: snapPoints
            }
        };
    };
    SpottingTile.properties = {
        label: "Spotting Tile",
        defaultAppearance: {
            size: "twelve",
            scale: 1.5,
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "size", label: "Wells", options: [
                    { value: "six", label: "6" },
                    { value: "nine", label: "9" },
                    { value: "twelve", label: "12" },
                    { value: "sixteen", label: "16", tier: "boost" },
                ]
            },
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 1.0, max: 2.0, step: 0.05, unit: "%",
            },
        ],
    };
    return SpottingTile;
}());
export { SpottingTile };
function getDimensions(appearance) {
    switch (appearance.size) {
        case "six": return [3, 2];
        case "nine": return [3, 3];
        case "sixteen": return [4, 4];
        case "twelve": return [4, 3];
    }
}
