var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from "components/button";
import { CommonModal } from "components/common_modal";
import { SearchBox } from "components/searchbox";
import { t } from "i18next";
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { Badge } from "tiers/badge";
import { TierContext } from "tiers/context";
import { callApi } from "../api/api";
import { toPercent } from "../view/formatters";
import { DiagramFile } from "./diagram";
var DIAGRAMS_PER_PAGE = 12;
var UNLIMITED_TIER_DIAGRAM_LIMIT = 1000000;
/** "About Chemix" modal dialog. */
export var FileOpenModal = function (_a) {
    var auth = _a.auth, modalState = __rest(_a, ["auth"]);
    var _b = React.useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var t = useTranslation().t;
    return React.createElement(CommonModal, __assign({}, modalState, { extraClass: "cloud-browse-modal", title: t("cloud.modal_title"), icon: isLoading ? "loading" : "fa-cloud", iconSwapOpacity: true }), auth.state === "logged_in" ? React.createElement(ShowFiles, { auth: auth, close: function () { return modalState.setIsOpen(false); }, isLoading: setIsLoading }) : null);
};
var ShowFiles = /** @class */ (function (_super) {
    __extends(ShowFiles, _super);
    function ShowFiles(props) {
        var _this = _super.call(this, props) || this;
        _this.isLoading = function () { return _this.state.currentFetchToken !== undefined; };
        _this.onOpen = function (diagramId) {
            _this.props.close();
            // Invoke the API to mark the diagram as opened.
            callApi("diagram/opened", "POST", _this.props.auth, { id: diagramId }, {
                success: function () { },
                generic: function () { }
            });
        };
        _this.state = { diagrams: [], page: 0, totalPages: 0, search: "" };
        return _this;
    }
    ShowFiles.prototype.componentDidMount = function () {
        this.fetch({ page: 1 });
        this.setState({ page: 1, search: "" });
    };
    ShowFiles.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var now = new Date();
        return React.createElement(React.Fragment, null,
            ((_a = this.state.usage) === null || _a === void 0 ? void 0 : _a.total) === 0 ?
                React.createElement("p", null, "You don't have any diagrams stored in the cloud yet.")
                : this.state.diagrams.length === 0 && !this.isLoading() && this.state.search ?
                    React.createElement("p", null,
                        "No diagrams matching \"",
                        this.state.search,
                        "\"")
                    : undefined,
            React.createElement("div", { className: "row mb-2 " + (((_b = this.state.usage) === null || _b === void 0 ? void 0 : _b.total) == 0 ? "d-none" : "") },
                React.createElement("div", { className: "col-12 col-md-6 col-lg-4" },
                    React.createElement(SearchBox, { value: this.state.search, onChange: function (value) { return _this.setState({ search: value }, function () { return _this.fetch({ page: _this.state.page }); }); }, placeholder: t("cloud.search"), throttleMs: 500 }))),
            React.createElement("div", { className: "row no-gutters file-grid " + (this.isLoading() ? "loading" : "") }, this.state.diagrams.map(function (d) {
                return React.createElement(DiagramFile, { key: "" + d.created, now: now, diagram: d, 
                    // TODO: Fetch correct page after deletion/rename
                    onDelete: function () { return _this.fetch({ page: _this.state.page }); }, onRename: function () { _this.setState({ page: 1 }); _this.fetch({ page: 1 }); }, onOpen: _this.onOpen });
            })),
            (this.state.usage && this.state.usage.limit != UNLIMITED_TIER_DIAGRAM_LIMIT) ?
                React.createElement(UsageMeter, { limit: this.state.usage.limit, total: this.state.usage.total })
                : undefined,
            this.state.totalPages > 1 ?
                React.createElement("div", { className: "text-center" },
                    React.createElement(Pager, { currentPage: this.state.page, totalPages: this.state.totalPages, onSelectPage: function (page) { return _this.fetch({ page: page }); } }))
                : undefined);
    };
    ShowFiles.prototype.setCurrentFetchToken = function (token) {
        if (token !== undefined) {
            this.setState({ currentFetchToken: token });
            this.props.isLoading(true);
        }
        else {
            this.setState({ currentFetchToken: undefined });
            this.props.isLoading(false);
        }
    };
    ShowFiles.prototype.fetch = function (options) {
        var _this = this;
        var page = options.page;
        var search = this.state.search;
        // Generate a fetch token.
        var fetchToken = (Math.random() * 10000).round();
        this.setCurrentFetchToken(fetchToken);
        var request = {
            pageSize: DIAGRAMS_PER_PAGE,
            page: page - 1,
            search: search,
        };
        callApi("diagrams", "GET", this.props.auth, request, {
            success: function (response) {
                if (_this.state.currentFetchToken != fetchToken)
                    return;
                var diagrams = response.diagrams.map(function (d) { return ({
                    id: d.id,
                    title: d.title,
                    thumbnail: d.thumbnailBase64,
                    rawData: d.data,
                    created: new Date(d.created),
                    modified: new Date(d.modified),
                }); });
                _this.setState({
                    diagrams: diagrams,
                    page: response.page + 1,
                    totalPages: response.totalPages,
                    usage: response.usage,
                });
                // Only reset the fetch token if the tokens matched.
                _this.setCurrentFetchToken();
            },
        });
    };
    return ShowFiles;
}(React.Component));
var Pager = function (props) {
    var buttons = [];
    var _loop_1 = function () {
        if (props.currentPage == i) {
            buttons.push(React.createElement(Button, { key: "page_" + i, extraClass: "narrower highlight", buttonLabel: i.toString(), onClick: function () { } }));
        }
        else {
            var page_1 = i;
            buttons.push(React.createElement(Button, { key: "page_" + i, extraClass: "narrower", buttonLabel: i.toString(), onClick: function () { return props.onSelectPage(page_1); } }));
        }
    };
    for (var i = 1; i <= props.totalPages; i++) {
        _loop_1();
    }
    return React.createElement("div", { className: "horizontal-button-group" }, buttons);
};
var UsageMeter = function (props) {
    var t = useTranslation().t;
    var percent = toPercent(Math.min(props.limit, props.total) / props.limit);
    var color = "secondary";
    var barColor = "info";
    var textClass = "";
    if (props.total >= props.limit) {
        color = "danger";
        textClass = "text-danger";
        barColor = "danger";
    }
    return React.createElement("div", { className: "border border-" + color + " rounded text-left p-2", style: { maxWidth: 220 } },
        React.createElement("div", { className: textClass },
            t('cloud.diagrams_used'),
            ": ",
            props.total,
            " / ",
            props.limit),
        React.createElement("div", { className: "progress bg-secondary", style: { height: 8 } },
            React.createElement("div", { className: "progress-bar bg-" + barColor, role: "progressbar", style: { width: percent } })),
        React.createElement(MaybeUpgradeButton, null));
};
var MaybeUpgradeButton = function () {
    return React.createElement(TierContext.Consumer, null, function (tiers) { return (!tiers.tier
        ? React.createElement(React.Fragment, null,
            "Need more storage? ",
            React.createElement("div", { className: "ui-button", onClick: function () { return tiers.popup('cloud'); } },
                "Upgrade ",
                React.createElement(Badge, { tier: "boost" })))
        : null); });
};
