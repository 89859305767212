import { setDefaultStyle } from "apparatus/draw";
import { Segments } from "apparatus/library/common";
import { Path } from "paper";
var EvaporatingDish = /** @class */ (function () {
    function EvaporatingDish() {
    }
    EvaporatingDish.prototype.render = function (appearance) {
        var graphic = getGraphicPath(appearance);
        var hitShape = graphic.clone();
        var liquidShape = graphic.clone();
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            hitShape: hitShape,
            liquidMask: liquidShape,
            snapping: graphic.placeable(),
        };
    };
    EvaporatingDish.properties = {
        label: "Evaporating Dish",
        canContainLiquids: true,
        defaultAppearance: {
            diameter: 65,
            style: "default"
        },
        flippable: true,
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 50, max: 100
            },
            {
                spec: "radio", key: "style", label: "Style",
                options: [
                    { value: "default", label: "Default" },
                    { value: "simple", label: "Simple" },
                ],
            }
        ],
    };
    return EvaporatingDish;
}());
export { EvaporatingDish };
function getGraphicPath(appearance) {
    var diameter = appearance.diameter;
    var height;
    switch (appearance.style) {
        case "default":
            height = diameter * 0.5;
            return new Path(Segments([[0, 0]], [[diameter / 4, height], [-diameter / 5, 0]], [[diameter / 4 * 3, height], undefined, [diameter / 5, 0]], [[diameter - 3, 5]], [[diameter + 3, -1], [-1, 3]]));
        case "simple":
            height = diameter * 0.36;
            return new Path(Segments([[0, 0], , [0, height / 3]], [[diameter / 2, height], [-diameter * 0.36, 0], [diameter * 0.36, 0]], [[diameter, 0], [0, height / 3]]));
    }
}
