var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { breakPath, mirrorX, setDefaultStyle } from "apparatus/draw";
import { DEFAULT_LIQUID_DATA, interpolate, P, Segments } from "apparatus/library/common";
import { Path } from "paper";
var GasWashBottle = /** @class */ (function () {
    function GasWashBottle() {
    }
    GasWashBottle.prototype.render = function (appearance) {
        var shape = appearance.shape, height = appearance.size;
        var tubeHWidth = 5;
        var flipped = appearance.endsFacing == "same";
        var bottleRadius = interpolate(height, [120, 200], [20, 30]);
        // Radius of the top part.
        var smallerRadius = interpolate(height, [120, 200], [11, 15]);
        // Height of the top bit.
        var topHeight = interpolate(height, [120, 200], [30, 52]);
        var bottle = new Path(Segments([[tubeHWidth, 0], undefined, [5, 0]], [[smallerRadius, 12], [0, -7]], [[smallerRadius, topHeight], undefined, [0, 3]]));
        if (shape == "cylinder") {
            bottle.addSegments(Segments([[bottleRadius, topHeight + 8], [0, -5]], [[bottleRadius, height], undefined, [20, 1]]));
        }
        else {
            bottle.addSegments(Segments([[bottleRadius, 60], undefined, [20, 1]]));
        }
        mirrorX(bottle);
        var bottomSnap;
        var pivot;
        var tubeLength;
        var shift;
        if (shape == "round") {
            var radius = appearance.roundRadius;
            var center = P(0, 55 + radius);
            var bottom = new Path.Circle(center, radius);
            var newBottle = bottle.unite(bottom);
            bottle.remove();
            bottle = newBottle;
            bottomSnap = bottom.placeable();
            pivot = center;
            tubeLength = radius * 2 + 45;
            shift = appearance.extension * (radius * 2 - 50);
        }
        else {
            bottomSnap = bottle.placeable();
            pivot = bottle.bounds.center;
            tubeLength = height - 10;
            shift = appearance.extension * 80;
        }
        // Center location of the tube.
        var tubeY = interpolate(height, [120, 200], [15, 25]); // 25
        bottle.insertSegments(2, Segments([[smallerRadius, tubeY - 5]], // A
        [[20, tubeY - 5], undefined, [10, 0]], // B
        [[30, tubeY - 15]], // C
        [[30, -10], undefined, [0, -14]], // D
        [[50, -30], [-13, 0]], // E
        [[60, -30]], // F (mouth)
        [[60, -20]], // F
        [[50, -20], undefined, [-10, 0]], // E
        [[40, -10]], // D
        [[40, tubeY - 15], undefined, [0, 13]], // C
        [[20, tubeY + 5], [13, 0]], // B
        [[smallerRadius, tubeY + 5]]));
        var liquidMask = bottle.clone();
        var graphic = breakPath(bottle, 8);
        var tube = new Path(Segments([[-5, tubeLength]], // C
        [[-5, -10], undefined, [0, -14]], // D
        [[15, -30], [-13, 0]], // E
        [[25, -30]], // F (mouth)
        [[25, -20]], // F
        [[15, -20], undefined, [-10, 0]], // E
        [[5, -10]], // D
        [[5, tubeLength], undefined, [0, 13]]));
        tube.scale(flipped ? 1 : -1, 1, P(0, 0));
        tube.position.y -= shift;
        graphic.addChildren(breakPath(tube, 4).children);
        tube.remove();
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.bounds.toShape(),
            pivot: pivot,
            snapping: [
                { type: "connectable", at: P(60, -25), facing: 90 },
                { type: "connectable", at: P(-25 * (flipped ? -1 : 1), -25 - shift), facing: flipped ? 90 : -90 },
                bottomSnap,
            ]
        };
    };
    GasWashBottle.properties = {
        label: "Gas Wash Bottle",
        canContainLiquids: true,
        flippable: true,
        defaultAppearance: {
            endsFacing: "opposite",
            shape: "cylinder",
            size: 200,
            roundRadius: 60,
            extension: 0.1
        },
        defaultLiquidData: __assign(__assign({}, DEFAULT_LIQUID_DATA), { amountRatio: 0.35 }),
        appearanceSpecs: [
            {
                spec: "toggle-buttons", key: "shape", label: "Shape type", size: "text",
                options: [
                    { value: "cylinder", label: "gas_wash_bottle.cylinder" },
                    { value: "round", label: "gas_wash_bottle.round" },
                ],
            },
            {
                spec: "slider", key: "roundRadius", label: "Radius",
                min: 40, max: 75, unit: "mm", visible: function (a) { return a.shape == "round"; }
            },
            {
                spec: "slider", key: "size", label: "Size",
                min: 120, max: 200
            },
            {
                spec: "radio", key: "endsFacing", label: "Ends facing",
                options: [
                    { value: "opposite", label: "Opposite direction" },
                    { value: "same", label: "Same direction" },
                ],
            },
            {
                spec: "slider", key: "extension", label: "Extension",
                min: 0, max: 1, step: 0.01
            }
        ],
    };
    return GasWashBottle;
}());
export { GasWashBottle };
