import { SVG } from "apparatus/svg";
var Glove = /** @class */ (function () {
    function Glove() {
    }
    Glove.prototype.render = function (appearance) {
        var graphic = SVG.glove();
        graphic.scale(0.2 * appearance.scale);
        return { graphic: graphic, hitShape: graphic.bounds.toShape() };
    };
    Glove.properties = {
        label: "Glove",
        flippable: true,
        defaultAppearance: {
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.5, max: 3, step: 0.1
            }
        ],
    };
    return Glove;
}());
export { Glove };
