import { g } from "analytics";
import { createApparatus } from "apparatus/library";
import { categoryToModuleMap, moduleDisplayIcon } from "apparatus/specs";
import { AddApparatus, Deselect, Select } from "command/basic";
import { commandProcessor, Sequence } from "command/command";
import * as React from "react";
import { useTranslation } from "react-i18next";
import store from "store/store";
import { TierContext } from "tiers/context";
import { crumb } from "utils";
import { Consts } from "vars";
/** Clickable item that displays a single apparatus item.  */
export var ApparatusItem = function (props) {
    var tiers = React.useContext(TierContext);
    var t = useTranslation().t;
    /** Adds the apparatus. */
    var addApparatus = function () {
        g("Add apparatus" + (props.isSearchMode ? ":search" : ""), props.type);
        crumb("ui", "Add " + props.type);
        var position = paper.project.view.center.add([Consts.sidebarWidth * 0.5, 0]);
        var apparatusData = createApparatus(props.type, position);
        var commands = [
            new AddApparatus([apparatusData]),
            new Deselect(store.selectedObjectsID()),
            new Select([apparatusData.id])
        ];
        commandProcessor.execute(new Sequence(commands));
    };
    var spritePositionX, spritePositionY;
    var extraClass = "";
    if (props.sprite.length == 3) {
        // New specification for multiple sheets.
        spritePositionX = -props.sprite[1] * 40;
        spritePositionY = -props.sprite[2] * 40;
        extraClass = "sprite-sheet-" + props.sprite[0];
    }
    else {
        spritePositionX = -props.sprite[0] * 40;
        spritePositionY = -props.sprite[1] * 40;
    }
    function onClick() {
        // Always add apparatus. If it's tier-locked,
        // this is handled by the editor_canvas via watermarking.
        addApparatus();
    }
    return React.createElement("div", { className: "apparatus-item", key: "item", onClick: onClick },
        React.createElement("img", { className: "apparatus-preview " + extraClass, key: "preview", src: "./images/1.png", style: {
                backgroundPosition: spritePositionX + "px " + spritePositionY + "px"
            } }),
        React.createElement("span", { className: "name", key: "text" },
            React.createElement("span", { className: "apparatus-name", key: "name" }, props.name),
            props.showCategory
                ? React.createElement("span", { className: "sub d-block", key: "sub" },
                    React.createElement("i", { className: "fa fa-" + moduleDisplayIcon(categoryToModuleMap[props.category]) }),
                    "\u00A0",
                    t("apparatus_module." + categoryToModuleMap[props.category]),
                    " > ",
                    t("category." + props.category))
                : null),
        tiers.badge(props, "mr-1", { style: "lite" }));
};
export var UsageVisualiser = function (props) {
    return React.createElement("span", { style: { height: "20px", width: props.widthRatio * 100 + "%", background: "red", display: "block", position: "absolute" } });
};
