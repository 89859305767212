var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P, RP, stepLeq } from "apparatus/library/common";
import { Adjustable, Interactable } from "editing/interaction";
import { Group, Key, Path, PointText } from "paper";
import * as React from "react";
import store, { appStore } from "store/store";
var MIN_EDGE_SIZE = 100;
var COLOR = "#d22209";
var ALMOST_TRANSPARENT = "#ffffff01";
var VISIBLE_STROKE_WIDTH = 4.0;
var HITTABLE_STROKE_WIDTH = 14.0;
var FONT_SIZE = 16.0;
var RenderAreaBox = /** @class */ (function (_super) {
    __extends(RenderAreaBox, _super);
    function RenderAreaBox(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { left: props.left, bottom: props.bottom, right: props.right, top: props.top };
        _this.moveAllAdjustableComponent = new EdgeResizeComponent(_this, ["top", "right", "left", "bottom"]);
        _this.visibleRect = new (Interactable(Path, Adjustable(_this.moveAllAdjustableComponent)))([[0, 0], [1, 0], [1, 1], [0, 1]])
            .close().withStroke(VISIBLE_STROKE_WIDTH, COLOR);
        _this.visibleRect.strokeCap = "square";
        _this.text = new (Interactable(PointText, Adjustable(_this.moveAllAdjustableComponent)))({
            point: P(0, 0),
            content: "0x0",
            fillColor: COLOR,
            fontFamily: 'Rubik',
            fontWeight: 'bold',
            fontSize: FONT_SIZE,
            justification: 'left'
        });
        _this.addCursor(_this.text, "move");
        _this.text.visible = false;
        _this.group = new Group([_this.visibleRect, _this.text]);
        // Add transparent items.
        _this.movableRect = new (Interactable(Path, Adjustable(_this.moveAllAdjustableComponent)))([[0, 0], [1, 0], [1, 1], [0, 1]])
            .withStroke(HITTABLE_STROKE_WIDTH, ALMOST_TRANSPARENT).close();
        _this.addCursor(_this.movableRect, "move");
        _this.cornerTopLeft = new (Interactable(Path.Circle, Adjustable(new EdgeResizeComponent(_this, ["top", "left"]))))(P(0, 0), 5)
            .withFill(COLOR).withStroke(9, ALMOST_TRANSPARENT);
        _this.addCursor(_this.cornerTopLeft, "nwse-resize");
        _this.cornerTopRight = new (Interactable(Path.Circle, Adjustable(new EdgeResizeComponent(_this, ["top", "right"]))))(P(0, 0), 5)
            .withFill(COLOR).withStroke(9, ALMOST_TRANSPARENT);
        _this.addCursor(_this.cornerTopRight, "nesw-resize");
        _this.cornerBottomRight = new (Interactable(Path.Circle, Adjustable(new EdgeResizeComponent(_this, ["right", "bottom"]))))(P(0, 0), 5)
            .withFill(COLOR).withStroke(9, ALMOST_TRANSPARENT);
        _this.addCursor(_this.cornerBottomRight, "nwse-resize");
        _this.cornerBottomLeft = new (Interactable(Path.Circle, Adjustable(new EdgeResizeComponent(_this, ["left", "bottom"]))))(P(0, 0), 5)
            .withFill(COLOR).withStroke(9, ALMOST_TRANSPARENT);
        _this.addCursor(_this.cornerBottomLeft, "nesw-resize");
        _this.cornerTop = new (Interactable(Path.Circle, Adjustable(new EdgeResizeComponent(_this, ["top"]))))(P(0, 0), 5)
            .withFill(COLOR).withStroke(9, ALMOST_TRANSPARENT);
        _this.addCursor(_this.cornerTop, "ns-resize");
        _this.cornerRight = new (Interactable(Path.Circle, Adjustable(new EdgeResizeComponent(_this, ["right"]))))(P(0, 0), 5)
            .withFill(COLOR).withStroke(9, ALMOST_TRANSPARENT);
        _this.addCursor(_this.cornerRight, "ew-resize");
        _this.cornerBottom = new (Interactable(Path.Circle, Adjustable(new EdgeResizeComponent(_this, ["bottom"]))))(P(0, 0), 5)
            .withFill(COLOR).withStroke(9, ALMOST_TRANSPARENT);
        _this.addCursor(_this.cornerBottom, "ns-resize");
        _this.cornerLeft = new (Interactable(Path.Circle, Adjustable(new EdgeResizeComponent(_this, ["left"]))))(P(0, 0), 5)
            .withFill(COLOR).withStroke(9, ALMOST_TRANSPARENT);
        _this.addCursor(_this.cornerLeft, "ew-resize");
        _this.cornerHandles = [
            _this.cornerBottomLeft,
            _this.cornerBottomRight,
            _this.cornerTopRight,
            _this.cornerTopLeft,
            _this.cornerLeft,
            _this.cornerRight,
            _this.cornerBottom,
            _this.cornerTop,
        ];
        _this.cornerHandles.forEach(function (i) { return i.applyMatrix = false; });
        _this.group.addChildren(__spreadArray([
            _this.movableRect
        ], _this.cornerHandles));
        props.layer.addChild(_this.group);
        return _this;
    }
    RenderAreaBox.prototype.onStart = function () {
        this.text.visible = true;
    };
    RenderAreaBox.prototype.onEnd = function () {
        this.text.visible = false;
    };
    RenderAreaBox.prototype.addCursor = function (item, cursor) {
        var _this = this;
        item.onMouseEnter = function () { return _this.props.setCursorHint(cursor); };
        item.onMouseLeave = function () { return _this.props.setCursorHint(); };
    };
    RenderAreaBox.prototype.moveByDelta = function (delta) {
        this.moveAllAdjustableComponent.setDelta(delta);
    };
    RenderAreaBox.prototype.componentWillUnmount = function () {
        this.group.remove();
    };
    RenderAreaBox.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (prevProps.left != this.props.left || prevProps.right != this.props.right || prevProps.top != this.props.top || prevProps.bottom != this.props.bottom) {
            this.setState({ left: this.props.left, bottom: this.props.bottom, right: this.props.right, top: this.props.top });
        }
    };
    RenderAreaBox.prototype.setRect = function (path, bounds) {
        path.segments[0].point = bounds.topLeft;
        path.segments[1].point = bounds.topRight;
        path.segments[2].point = bounds.bottomRight;
        path.segments[3].point = bounds.bottomLeft;
    };
    RenderAreaBox.prototype.render = function () {
        var _this = this;
        var bounds = RP(this.state.left.round(), this.state.top.round(), this.state.right.round(), this.state.bottom.round());
        this.setRect(this.movableRect, bounds);
        this.setRect(this.visibleRect, bounds);
        this.cornerTopLeft.position = bounds.topLeft;
        this.cornerBottomLeft.position = bounds.bottomLeft;
        this.cornerBottomRight.position = bounds.bottomRight;
        this.cornerTopRight.position = bounds.topRight;
        this.cornerTop.position = bounds.topCenter;
        this.cornerLeft.position = bounds.leftCenter;
        this.cornerRight.position = bounds.rightCenter;
        this.cornerBottom.position = bounds.bottomCenter;
        this.text.pivot = P(0, 10 / this.props.zoom);
        this.text.content = bounds.width + " \u00D7 " + bounds.height;
        this.text.position = bounds.topLeft;
        this.text.fontSize = FONT_SIZE / this.props.zoom;
        // Set interactivity and size appropriately for zoom level.
        this.cornerHandles.forEach(function (h) {
            h.visible = _this.props.interactable;
            h.scaling = P(1 / _this.props.zoom, 1 / _this.props.zoom);
        });
        this.movableRect.strokeWidth = HITTABLE_STROKE_WIDTH / this.props.zoom;
        this.visibleRect.strokeWidth = VISIBLE_STROKE_WIDTH / this.props.zoom;
        return null;
    };
    return RenderAreaBox;
}(React.Component));
export { RenderAreaBox };
var EdgeResizeComponent = /** @class */ (function () {
    function EdgeResizeComponent(renderAreaBox, edges) {
        this.renderAreaBox = renderAreaBox;
        this.modifyEdge = { bottom: false, left: false, right: false, top: false };
        for (var _i = 0, edges_1 = edges; _i < edges_1.length; _i++) {
            var edge = edges_1[_i];
            this.modifyEdge[edge] = true;
        }
    }
    EdgeResizeComponent.prototype.onStart = function () {
        if ("onStart" in this.renderAreaBox)
            this.renderAreaBox.onStart();
        return {};
    };
    EdgeResizeComponent.prototype.setDelta = function (delta) {
        var props = this.renderAreaBox.props;
        // Adjust delta if shift is held.
        if (Key.modifiers.shift) {
            var snapStep = stepLeq(this.renderAreaBox.props.zoom, [[0.33, 100], [0.75, 50], [1.5, 20]], 10);
            if (this.modifyEdge.left != this.modifyEdge.right) {
                // Adjust delta to snap for x.
                var width = props.right - props.left;
                delta.x = (delta.x - (delta.x % snapStep)) - (width % snapStep);
            }
            if (this.modifyEdge.top != this.modifyEdge.bottom) {
                // Adjust delta to snap for x.
                var height = props.bottom - props.top;
                delta.y = (delta.y - (delta.y % snapStep)) - (height % snapStep);
            }
        }
        this.renderAreaBox.setState({
            left: !this.modifyEdge.left ? props.left : this.modifyEdge.right ? props.left + delta.x : Math.min(props.left + delta.x, props.right - MIN_EDGE_SIZE),
            right: !this.modifyEdge.right ? props.right : this.modifyEdge.left ? props.right + delta.x : Math.max(props.right + delta.x, props.left + MIN_EDGE_SIZE),
            top: !this.modifyEdge.top ? props.top : this.modifyEdge.bottom ? props.top + delta.y : Math.min(props.top + delta.y, props.bottom - MIN_EDGE_SIZE),
            bottom: !this.modifyEdge.bottom ? props.bottom : this.modifyEdge.top ? props.bottom + delta.y : Math.max(props.bottom + delta.y, props.top + MIN_EDGE_SIZE),
        });
    };
    EdgeResizeComponent.prototype.onEnd = function (delta) {
        this.renderAreaBox.onEnd();
        return new UpdateRenderAreaBox(this.renderAreaBox.state);
    };
    EdgeResizeComponent.prototype.onNoop = function () {
        this.renderAreaBox.onEnd();
        return undefined;
    };
    return EdgeResizeComponent;
}());
var UpdateRenderAreaBox = /** @class */ (function () {
    function UpdateRenderAreaBox(value) {
        var current = appStore.getState().renderArea;
        this.previous = current ? __assign({}, current) : undefined;
        if (value) {
            if ("width" in value) {
                value = value.expand(Math.max(0, MIN_EDGE_SIZE - value.width), Math.max(0, MIN_EDGE_SIZE - value.height));
                // It's a paper.Rectangle
                this.value = { left: value.left, right: value.right, bottom: value.bottom, top: value.top };
            }
            else {
                this.value = __assign({}, value);
            }
            // Round all the values.
            var key = void 0;
            for (key in this.value) {
                this.value[key] = this.value[key].round();
            }
        }
        else {
            this.value = undefined;
        }
    }
    UpdateRenderAreaBox.prototype.execute = function () {
        var _this = this;
        store.dispatchFn("UpdateRenderAreaBox", function (store) { return mutate(store, _this.value); });
    };
    UpdateRenderAreaBox.prototype.undo = function () {
        var _this = this;
        21;
        store.dispatchFn("UpdateRenderAreaBox", function (store) { return mutate(store, _this.previous); });
    };
    return UpdateRenderAreaBox;
}());
export { UpdateRenderAreaBox };
function mutate(store, renderArea) {
    return __assign(__assign({}, store), { renderArea: renderArea });
}
