var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { createApparatus } from "apparatus/library";
import { P } from "apparatus/library/common";
import { AddApparatus, Deselect, Select } from "command/basic";
import { commandProcessor, Sequence } from "command/command";
import { Path, Tool } from "paper";
import store from "store/store";
var FreehandTool = /** @class */ (function (_super) {
    __extends(FreehandTool, _super);
    function FreehandTool(app) {
        var _this = _super.call(this) || this;
        _this.app = app;
        _this.onMouseDown = function () {
            var _a;
            _this.app.setIsInteracting(true);
            (_a = _this.path) === null || _a === void 0 ? void 0 : _a.remove();
            _this.path = new Path();
            _this.path.selected = true;
        };
        _this.onMouseDrag = function (event) {
            if (!_this.path)
                return;
            _this.path.add(event.point);
        };
        _this.onMouseUp = function () {
            _this.app.setIsInteracting(false);
            var path = _this.path;
            if (!path)
                return;
            // Clean up the path first.
            path.simplify();
            path.smooth();
            // Convert path points to segments as array.
            var points = [];
            for (var _i = 0, _a = path.segments; _i < _a.length; _i++) {
                var segment = _a[_i];
                var point = P(segment.point.x, segment.point.y);
                var handleOut = segment.handleOut.x == 0 && segment.handleOut.y == 0 ? undefined : P(segment.handleOut.x, segment.handleOut.y);
                var handleIn = segment.handleIn.x == 0 && segment.handleIn.y == 0 ? undefined : P(segment.handleIn.x, segment.handleIn.y);
                if (handleOut) {
                    points.push([p(point), p(handleIn), p(handleOut)]);
                }
                else if (handleIn) {
                    points.push([p(point), p(handleIn)]);
                }
                else {
                    points.push([p(point)]);
                }
            }
            // Find the center of the path.
            var center = path.bounds.center;
            // Apparatus data.
            var apparatusData = createApparatus("__freehand", center);
            apparatusData.appearance["points"] = points;
            commandProcessor.execute(new Sequence([
                new AddApparatus([apparatusData]),
                new Deselect(store.selectedObjectsID()),
                new Select([apparatusData.id])
            ]));
            // Remove path.
            path.remove();
            _this.path = undefined;
        };
        return _this;
    }
    return FreehandTool;
}(Tool));
export { FreehandTool };
function p(point) {
    if (point === undefined)
        return undefined;
    return [point.x.round(), point.y.round()];
}
