import { breakPath, glassFold, mirrorX, setDefaultStyle, setDefaultStyleWithStroke } from "apparatus/draw";
import { P, Segments } from "apparatus/library/common";
import { Path } from "paper";
var SplashHead = /** @class */ (function () {
    function SplashHead() {
    }
    SplashHead.prototype.render = function (appearance) {
        var shape = new Path(Segments([[9, 0]], [[6, 17]], [[8, 17]], [[8, 31], [-1, -4], [7, 3]], [[26, 58], [0, -16], [0, 17]], [[7, 84], [2, -2]], [[7, 97]], [[9, 98]], [[6, 117]]));
        mirrorX(shape);
        var graphic = breakPath(shape, 9);
        setDefaultStyle(graphic);
        var innerSpout = breakPath(new Path(Segments([[4, 34], [0, -20], [0, 13]], [[-16, 54], [15, 0]], [[-16, 47], , [12, 0]], [[-3, 34], [0, 10], [0, -20]])), 2);
        setDefaultStyleWithStroke("thin", innerSpout);
        graphic.addChild(innerSpout);
        graphic.addChild(glassFold(0, 79, { rotation: 180 }));
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: [
                { type: "connectable", at: P(0, 0), facing: 0 },
                { type: "connectable", at: P(0, 117), facing: 180 },
            ]
        };
    };
    SplashHead.properties = {
        label: "Splash head",
        flippable: true,
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return SplashHead;
}());
export { SplashHead };
