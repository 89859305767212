import { smoothCorner, strokeWidth } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, R, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var WaterBath = /** @class */ (function () {
    function WaterBath() {
    }
    WaterBath.prototype.render = function (appearance) {
        var width = appearance.width, height = appearance.height;
        var box = new Path(Segments([[0, 0]], [[width, 0]], [[width, height]], [[0, height]]));
        box.closePath();
        smoothCorner(box.segments[3], 10.0);
        smoothCorner(box.segments[2], 10.0);
        var liquidSpace = new Path(Segments([[10, -20]], [[width - 90, -20]], [[width - 90, height - 20]], [[10, height - 20]]));
        liquidSpace.closePath();
        var container = box.subtract(liquidSpace);
        box.remove();
        var panel = createPanel();
        panel.position = P(width - 45, height - 50);
        container
            .withStroke(strokeWidth("default"), "#666666")
            .withGradientFill("down", Gradients.box("#cccccc".color()));
        var graphic = new Group([container, panel]);
        return {
            graphic: graphic,
            liquidMask: liquidSpace,
            hitShape: graphic.bounds.toShape(),
            snapping: [
                {
                    type: "placeable_surface",
                    start: liquidSpace.strokeBounds.bottomLeft,
                    end: liquidSpace.strokeBounds.bottomRight,
                },
                container.placeable(),
            ]
        };
    };
    WaterBath.properties = {
        label: "Water bath",
        canContainLiquids: true,
        defaultAppearance: {
            width: 490,
            height: 180,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Width",
                min: 280, max: 600
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 120, max: 240
            },
        ],
    };
    return WaterBath;
}());
export { WaterBath };
function createPanel() {
    var outside = Shape.Rectangle(R(0, 0, 70, 45), 4)
        .withStroke(strokeWidth("default"), "#888888")
        .withFill("#f2f2f2");
    var lcd = Shape.Rectangle(R(10, 10, 50, 10))
        .withStroke(strokeWidth("thinner"), "#578596")
        .withFill("#88c4db");
    var button1 = Shape.Rectangle(R(10, 25, 10, 10), 2)
        .withFill("#7a7a7a");
    var button2 = button1.clone();
    button2.position.x += 15;
    var button3 = button1.clone();
    button3.position.x += 30;
    return new Group([outside, lcd, button1, button2, button3]);
}
