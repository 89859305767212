var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { addEdgeLips, breakPath, mirrorX, setDefaultStyle } from "apparatus/draw";
import { interpolate, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
import { createTap, Tap } from "./common/tap";
var SeparatoryFunnel = /** @class */ (function () {
    function SeparatoryFunnel() {
    }
    SeparatoryFunnel.prototype.render = function (appearance) {
        var size = appearance.size;
        var outflowHeight = 50;
        var glass;
        var closeTap;
        var breakPathIndex;
        if (appearance.shape === "long") {
            var halfRadius = size * 0.25;
            glass = new Path(Segments([[0, -20]], [[0, 0], undefined, [-halfRadius, 10]], // Start of arc
            [[-halfRadius, halfRadius * 0.7], undefined, [0, 20]], // end of arc
            [[7, size]], [[7, size + outflowHeight]]));
            mirrorX(glass, 12);
            closeTap = [5, 3];
            breakPathIndex = 6;
        }
        else {
            // Round - boost the size
            size += 40;
            var midRadiusOffset = interpolate(size, [100 + 40, 200 + 40], [6, 0]);
            var radius = size * 0.15 + 8;
            glass = new Path(Segments([[0, -20]], [[0, 0], undefined, [-radius * 0.4, radius * 0.1]], // Start of bulb
            [[-radius + 12, radius], [0, -radius * 0.55], [0, radius * 0.4]], [[midRadiusOffset, 2.4 * radius], [0, -radius * 0.15],], // end of arc
            [[midRadiusOffset, size - 15], , [0, 5]], [[7, size]], // Close tap here
            [[7, size + outflowHeight]]));
            mirrorX(glass, 12);
            closeTap = [7, 5];
            breakPathIndex = 8;
        }
        var liquidMask = glass.clone();
        // Adjust liquidMask based on opening.
        if (Tap.isClosed(appearance.tapOpening)) {
            liquidMask.segments[closeTap[0]].point = liquidMask.segments[closeTap[1]].point;
        }
        addEdgeLips(glass);
        var glassWalls = breakPath(glass, breakPathIndex);
        setDefaultStyle(glassWalls);
        var stopCock = createTap(appearance);
        stopCock.position.x += 12;
        stopCock.position.y = size + 2;
        var graphic = new Group([glassWalls, stopCock]);
        var hitShapes = [
            Shape.Rectangle(glass.bounds),
            Shape.Rectangle(stopCock.bounds)
        ];
        return { graphic: graphic, hitShape: hitShapes, liquidMask: liquidMask };
    };
    SeparatoryFunnel.properties = {
        label: "Separatory Funnel",
        canContainLiquids: true,
        defaultAppearance: __assign({ size: 150, shape: "long" }, Tap.defaultAppearance),
        appearanceSpecs: __spreadArray([
            {
                spec: "slider", key: "size", label: "Size",
                min: 100, max: 200
            },
            {
                spec: "toggle-buttons", key: "shape", label: "Shape", size: "text",
                allowSelectingTierLockedOptions: true,
                options: [
                    { value: "long", label: "Long", },
                    { value: "round", label: "Round", tier: "boost" },
                ]
            }
        ], Tap.appearanceSpecsWithFacing),
        isBoostRequired: function (a) { return a.shape === "round"; }
    };
    return SeparatoryFunnel;
}());
export { SeparatoryFunnel };
