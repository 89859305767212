// Pouring, Meniscus and Draining
// For draining, see DesignDoc_004.
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MultiApparatusEditCommand } from "command/basic";
export var LIQUID_POURING_MIN_STREAM_WIDTH = 3;
export var LIQUID_POURING_MAX_ROTATION_ANGLE = 100;
export var LIQUID_POURING_MIN_ROTATION_ANGLE = 3;
export var LIQUID_DRAINING_MAX_ROTATION_ANGLE = 10;
export var LIQUID_DRAINING_MIN_LIQUID_LEVEL = 0.1;
export var MENISCUS_RADIUS_TO_HEIGHT = 0.6;
export function isWithinPouringAngles(rotation) {
    var absRotation = Math.abs(rotation.anglify());
    return absRotation >= LIQUID_POURING_MIN_ROTATION_ANGLE && absRotation <= LIQUID_POURING_MAX_ROTATION_ANGLE;
}
export function isWithinDrainingAngles(rotation) {
    var absRotation = Math.abs(rotation.anglify());
    return absRotation <= LIQUID_DRAINING_MAX_ROTATION_ANGLE;
}
export function isLiquidLevelSufficientForDraining(data) {
    return data.amountRatio >= LIQUID_DRAINING_MIN_LIQUID_LEVEL;
}
export var UpdateLiquidPouringEnabled = MultiApparatusEditCommand.createClass("UpdateLiquidPouringEnabled", function (i, enabled) { return i.liquid
    ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { pouring: __assign(__assign({}, i.liquid.pouring), { enabled: enabled }) }) }) : i; });
export var UpdateLiquidPouringFade = MultiApparatusEditCommand.createClass("UpdateLiquidPouringFade", function (i, fade) { return i.liquid
    ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { pouring: __assign(__assign({}, i.liquid.pouring), { fade: fade }) }) }) : i; });
export var UpdateLiquidPouringFlowLength = MultiApparatusEditCommand.createClass("UpdateLiquidPouringFlowLength", function (i, flowLength) { return i.liquid
    ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { pouring: __assign(__assign({}, i.liquid.pouring), { flowLength: flowLength }) }) }) : i; });
export var UpdateLiquidPouringFlowStrength = MultiApparatusEditCommand.createClass("UpdateLiquidPouringFlowStrength", function (i, flowStrength) { return i.liquid
    ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { pouring: __assign(__assign({}, i.liquid.pouring), { flowStrength: flowStrength }) }) }) : i; });
// Meniscus.
export var UpdateLiquidMeniscusEnabled = MultiApparatusEditCommand.createClass("UpdateLiquidMeniscusEnabled", function (i, enabled) { return i.liquid
    ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { meniscus: __assign(__assign({}, i.liquid.meniscus), { enabled: enabled }) }) }) : i; });
export var UpdateLiquidMeniscusConvex = MultiApparatusEditCommand.createClass("UpdateLiquidMeniscusConvex", function (i, convex) { return i.liquid
    ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { meniscus: __assign(__assign({}, i.liquid.meniscus), { convex: convex }) }) }) : i; });
export var UpdateLiquidMeniscusRadius = MultiApparatusEditCommand.createClass("UpdateLiquidMeniscusRadius", function (i, radius) { return i.liquid
    ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { meniscus: __assign(__assign({}, i.liquid.meniscus), { radius: radius }) }) }) : i; });
export var UpdateLiquidDrainingEnabled = MultiApparatusEditCommand.createClass("UpdateLiquidDrainingEnabled", function (i, enabled) { return i.liquid
    ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { draining: __assign(__assign({}, i.liquid.draining), { enabled: enabled }) }) }) : i; });
export var UpdateLiquidDrainingFade = MultiApparatusEditCommand.createClass("UpdateLiquidPouringFade", function (i, fade) { return i.liquid
    ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { draining: __assign(__assign({}, i.liquid.draining), { fade: fade }) }) }) : i; });
export var UpdateLiquidDrainingThinning = MultiApparatusEditCommand.createClass("UpdateLiquidPouringThinning", function (i, thinning) { return i.liquid
    ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { draining: __assign(__assign({}, i.liquid.draining), { thinning: thinning }) }) }) : i; });
export var UpdateLiquidDrainingFlowLength = MultiApparatusEditCommand.createClass("UpdateLiquidDrainingFlowLength", function (i, flowLength) { return i.liquid
    ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { draining: __assign(__assign({}, i.liquid.draining), { flowLength: flowLength }) }) }) : i; });
