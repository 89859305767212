import Tippy from "@tippyjs/react";
import { Icon } from "components/icon";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Hint } from "../../view/tooltips";
/**
 * Group of buttons that are displayed together.
 */
export var ButtonGroup = function (props) { return (React.createElement("div", { className: "horizontal-button-group", id: props.id },
    " ",
    props.children)); };
export var Button = function (item) {
    var _a;
    var t = useTranslation().t;
    var highlight = (_a = item.highlight) !== null && _a !== void 0 ? _a : false;
    return React.createElement(Tippy, { content: React.createElement(Hint, { text: t(item.hint), shortcut: item.shortcut, shortcut2: item.shortcut2 }) },
        React.createElement("div", { className: "ui-button" + (highlight ? " highlight" : ""), onClick: function () { return item.onClick(); } },
            React.createElement(Icon, { name: item.icon, fullWidth: true })));
};
