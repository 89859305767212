import { removeDiacritics } from "diacritics";
export function searchApparatus(term, inList) {
    if (term.length == 0) {
        return [];
    }
    // Search is case-insensitive.
    term = term.trim().toLowerCase();
    var results = [];
    var _loop_1 = function (a) {
        if (a.type.indexOf(term) !== -1) {
            // Lower score means better match.
            a.searchScore = 0;
            results.push(a);
        }
        else if (a.lowerCaseName.indexOf(term) !== -1) {
            // Search in the name as well.
            // Lower score means better match.
            a.searchScore = 0;
            results.push(a);
        }
        else {
            // search in additional terms
            a.search.some(function (searchTerms, i) {
                if (searchTerms.indexOf(term) !== -1) {
                    a.searchScore = 1 + i;
                    results.push(a);
                    return true;
                }
                return false;
            });
        }
    };
    for (var _i = 0, inList_1 = inList; _i < inList_1.length; _i++) {
        var a = inList_1[_i];
        _loop_1(a);
    }
    // Sort the results
    results.sort(function (a, b) {
        // Compare by search score first
        var scoreDiff = (a.searchScore || 0) - (b.searchScore || 0);
        if (scoreDiff !== 0) {
            return scoreDiff;
        }
        // Compare based on name
        if (a.type < b.type) {
            return -1;
        }
        return 1; // Equal names are (technically) not possible
    });
    return results;
}
export var NO_SEARCH_TERM_SENTINEL = '__nosearchtermsentinel__';
/** Converts a comma separated string of search terms, into a string[] */
export function compileSearchTerms(searchTermsString, apparatusName) {
    var terms = [];
    if (searchTermsString !== NO_SEARCH_TERM_SENTINEL) {
        for (var _i = 0, _a = searchTermsString.split(","); _i < _a.length; _i++) {
            var rawTerm = _a[_i];
            var term = rawTerm.trim().toLowerCase();
            terms.push(term);
            var simplified = removeDiacritics(term);
            if (simplified !== term) {
                terms.push(simplified);
            }
        }
    }
    // Also add apparatus name if it's got a simplified form.
    var simplifiedName = removeDiacritics(apparatusName);
    if (simplifiedName !== apparatusName) {
        terms.push(simplifiedName.toLowerCase());
    }
    return terms;
}
