import { Gradients } from "apparatus/gradients";
import { P, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path } from "paper";
var metal = Gradients.cylinder(SharedColors.lightMetal.color());
var VernierCaliper = /** @class */ (function () {
    function VernierCaliper() {
    }
    VernierCaliper.prototype.render = function (appearance) {
        // Note the caliper is initially drawn at 2x.
        var cms = appearance.cms;
        var mainBody = new Path(Segments([[0, -48]], [[15, 0], [1, -43]], [[415, 0]], [[415, 34]], [[0, 34]], [[0, 128], , [-23, 0]], [[-38, 41], [17, 62]], [[-38, -10]], [[0, -10]])).close()
            .withStroke("default")
            .withGradientFill("right-down", metal);
        var inchSlider = new Path(Segments([[0, -48]], [[0, -10]], [[110, -10]], [[110, -0]], [[-10, -0], [20, 0], [-8, -33]])).close()
            .withStroke("default")
            .withGradientFill("right-down", metal);
        inchSlider.pivot = P(0, 0);
        inchSlider.position = P(cms * 10 * 2, 0);
        var cmSlider = new Path(Segments([[0, 0]], [[110, 0]], [[110, 19]], [[40, 19], [20, 1], [-13, 0]], [[0, 93], [27, -5]])).close()
            .withStroke("default")
            .withGradientFill("right-down", metal);
        cmSlider.pivot = P(0, 0);
        cmSlider.position = P(cms * 10 * 2, 34);
        var graphic = new Group([inchSlider, cmSlider, mainBody]);
        graphic.scale(0.5 * appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            watermark: P(0, 0)
        };
    };
    VernierCaliper.properties = {
        label: "Vernier Caliper",
        defaultAppearance: {
            cms: 3,
            scale: 1
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Size",
                min: 1, max: 2, step: 0.1, unit: "x"
            },
            {
                spec: "slider", key: "cms", label: "Centimetres",
                min: 0, max: 15, step: 0.1, unit: "cm"
            }
        ],
    };
    return VernierCaliper;
}());
export { VernierCaliper };
