import { P, R, Segments } from "apparatus/library/common";
import { Group, Path, PointText, Shape } from "paper";
import { mirrorX } from "../../draw";
var DigitalThermometer = /** @class */ (function () {
    function DigitalThermometer() {
    }
    DigitalThermometer.prototype.render = function (appearance) {
        var displayString = appearance.reading + " " + appearance.unit;
        switch (appearance.brand) {
            case "flinn":
                return renderFlinn(displayString);
            case "vernier":
                return renderVernier(displayString);
        }
    };
    DigitalThermometer.properties = {
        label: "Digital thermometer",
        defaultAppearance: {
            brand: "vernier",
            reading: "20",
            unit: "C"
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "brand", label: "Brand",
                options: [
                    { value: "flinn", label: "Flinn" },
                    { value: "vernier", label: "Vernier" },
                ],
            },
            {
                spec: "text", key: "reading", label: "Reading",
            },
            {
                spec: "toggle-buttons", key: "unit", label: "Units", size: "text",
                options: [
                    { value: "C", label: "C" },
                    { value: "F", label: "F" },
                    { value: "K", label: "K" },
                    { value: "", label: "-" },
                ],
            }
        ],
    };
    return DigitalThermometer;
}());
export { DigitalThermometer };
// https://www.flinnsci.com/flinn-digital-thermometer/ap8716/
// Probe length 8 in (20cm), overall length 11 in (28cm)
function renderFlinn(displayString) {
    var probe = Shape.Rectangle(R(-4, 80, 8, 200));
    probe.setGradientFill("right", [
        ["#d0232f", 0.1],
        ["#e75753", 0.6],
        ["#d0232f", 0.9],
    ]);
    var handle = new Path(Segments([[4, 80], undefined, [0, -6]], [[12, 70], [0, 6]], [[12, 5], undefined, [0, -4]]));
    mirrorX(handle);
    handle.fillColor = "#111122";
    var screen = Shape.Rectangle(R(-9, 16, 18, 46), 9);
    screen.fillColor = "#eeeeee";
    var decor1 = Shape.Rectangle(R(-12, 10, 24, 2));
    decor1.fillColor = "#d0232f";
    var decor2 = Shape.Rectangle(R(-12, 66, 24, 2));
    decor2.fillColor = "#d0232f";
    var display = new PointText({
        point: P(0, 43),
        content: displayString,
        fillColor: 'black',
        fontFamily: 'sans-serif',
        fontSize: 14,
        justification: "center",
    });
    display.rotate(-90, P(0, 38));
    var graphic = new Group([probe, handle, screen, decor1, decor2, display]);
    return { graphic: graphic, hitShape: graphic.bounds.toShape() };
}
// https://www.vernier.com/products/sensors/temperature-sensors/irt-bta/
function renderVernier(displayString) {
    var casing = new Path(Segments([[20, 0], [-2, -2]], [[20, 37], undefined, [-4, 7]], [[12, 55], [0, -7]], [[12, 150], undefined, [0, 2]]));
    mirrorX(casing);
    casing.fillColor = "#333344";
    var screen = Shape.Rectangle(R(-16, 12, 32, 18));
    screen.fillColor = "#eeeeee";
    var button = Shape.Circle(P(0, 46), 7);
    button.fillColor = "#d0232f";
    var display = new PointText({
        point: P(0, 26),
        content: displayString,
        fillColor: 'black',
        fontFamily: 'sans-serif',
        fontSize: 12,
        justification: "center",
    });
    var graphic = new Group([casing, button, screen, display]);
    return { graphic: graphic, hitShape: graphic.bounds.toShape() };
}
