var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Tippy from "@tippyjs/react";
import { P, R } from "apparatus/library/common";
import { RotationHandler } from "editing/rotation/rotation_handler";
import { t } from "i18next";
import * as React from "react";
import { Trans } from "react-i18next";
import { Debug } from "vars";
import { CtrlKey, Key } from "view/keyboard_shortucts";
import { Nl } from "view/tooltips";
/**
 * Component to display a rectangle around the current selection
 * plus an icon that can be clicked on to perform rotation.
 * To use this component, instantiate it and update by setting state.
 */
var SelectionOverlay = /** @class */ (function (_super) {
    __extends(SelectionOverlay, _super);
    function SelectionOverlay(props) {
        var _this = _super.call(this, props) || this;
        /** Ref to the rotation handle. */
        _this.rotatioHandleRef = React.createRef();
        /** Helper for handling updating items when the rotation handler is used */
        _this.rotationHandler = new RotationHandler();
        _this.state = { isVisible: false, center: P(0, 0), bounds: R(0, 0, 0, 0) };
        return _this;
    }
    SelectionOverlay.prototype.componentDidMount = function () {
        this.setUpRotationHandler();
    };
    SelectionOverlay.prototype.render = function () {
        var _a = this.state, isVisible = _a.isVisible, center = _a.center, bounds = _a.bounds;
        var shouldHide = this.props.hideDueToInteraction;
        var presentationModeStyles = Debug.PRESENTATION_MODE ? { opacity: 0.0 } : {};
        return React.createElement("div", { id: "selection-center", key: "selectionCenter", style: {
                visibility: isVisible && !shouldHide ? "visible" : "hidden",
                left: center.x,
                top: center.y
            } },
            React.createElement("div", { id: "selection-box", key: "box", style: __assign({ left: bounds.x - center.x - 5, top: bounds.y - center.y - 5, width: bounds.width + 10, height: bounds.height + 10 }, presentationModeStyles) }),
            React.createElement(Tippy, { content: React.createElement(RotationHint, null), delay: [1000, 0] },
                React.createElement("div", { id: "rotation-handle", key: "handle_image", ref: this.rotatioHandleRef, style: __assign({ top: bounds.y - center.y - 60 }, presentationModeStyles) },
                    React.createElement("i", { className: "fas fa-sync-alt" }))));
    };
    /** Sets up the rotation handle to be draggable, using jQuery. */
    SelectionOverlay.prototype.setUpRotationHandler = function () {
        var handle = $(this.rotatioHandleRef.current);
        var self = this;
        handle.draggable({
            revert: true,
            revertDuration: 0,
            cursorAt: { top: 14, left: 0 },
            start: function (e, ui) {
                var rotationType = self.rotationHandler.onStart();
                self.props.onStartRotation(rotationType);
            },
            drag: function (e, ui) {
                var pos = $(this).position();
                // How many snap points?
                var snaps = 72;
                if (e.ctrlKey) {
                    snaps = 360;
                }
                else if (e.shiftKey) {
                    snaps = 16;
                }
                else if (e.altKey) {
                    snaps = 0;
                }
                var angle = self.rotationHandler.onRotate(pos.left, pos.top, snaps);
                self.props.onUpdateRotation(angle);
            },
            stop: function (e, ui) {
                self.rotationHandler.onEnd();
                self.props.onEndRotation();
            }
        }).on('click', function () {
            toastr.info(t("info.rotate_popup"));
        });
    };
    return SelectionOverlay;
}(React.Component));
export { SelectionOverlay };
var RotationHint = function () { return React.createElement(React.Fragment, null,
    t("info.rotation.hint.title"),
    React.createElement("div", { className: "shortcut" },
        React.createElement(Nl, null,
            React.createElement(Trans, { i18nKey: "info.rotation.hint.description" },
                "By default, rotates in 5\u00B0 steps. Hold ",
                React.createElement(CtrlKey, null),
                " for 1\u00B0 steps Hold ",
                React.createElement(Key, { k: "Shift" }),
                " for 22.5\u00B0 steps Hold ",
                React.createElement(Key, { k: "Alt" }),
                " for free rotation")))); };
