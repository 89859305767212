// Common measurements and values and other utilities.
import { Gradients } from "apparatus/gradients";
import * as color from "color";
import { Point, Rectangle } from "paper";
export var lipSize = 3;
export var cornerSize = 12;
// Measurement markers on test tubes, beakers..
export var markerSize = 8;
export var markerSpacing = 15;
/** Commonly used colours when drawing items. */
export var SharedColors;
(function (SharedColors) {
    SharedColors.stroke = "#333333";
    SharedColors.mark = "#66666660";
    SharedColors.darkMetal = "#444444";
    SharedColors.mediumMetal = "#777777";
    SharedColors.lightMetal = "#aaaaaa";
    SharedColors.copper = "#b87333";
    SharedColors.aluminum = "#848789";
    SharedColors.gold = "#e5d326";
    SharedColors.selectionGreen = "#62af3b";
    SharedColors.defaultLiquid = "#80cce6";
    SharedColors.defaultLiquid2 = "#f75e5e";
    SharedColors.defaultLiquid3 = "#89df7c";
    SharedColors.defaultLiquidAlpha = 0.3;
    SharedColors.glassEdge = "#6bd9d79f";
    SharedColors.transparent = "#00000000";
    /** Colour of the "glass-fold" visual hints, to make items look more 3-D. */
    SharedColors.glassFold = "#48838c30";
    /** Colour of LCD screens. */
    SharedColors.lcdScreen = "#b5e38d";
    /** Device/Equipment panel colours. */
    var devicePanel;
    (function (devicePanel) {
        devicePanel.fill = "#8094a8";
        devicePanel.stroke = "#57697b";
    })(devicePanel = SharedColors.devicePanel || (SharedColors.devicePanel = {}));
    SharedColors.deviceGrey = "#dee3e8";
    var wood;
    (function (wood) {
        wood.stroke = "#382b1e";
        wood.gradient = [
            ["#ce9158", 0.2],
            ["#ad7847", 0.21],
            ["#704e2f", 1.0]
        ];
    })(wood = SharedColors.wood || (SharedColors.wood = {}));
    SharedColors.ray = "#eb3636";
})(SharedColors || (SharedColors = {}));
export var SharedGradients;
(function (SharedGradients) {
    SharedGradients.metal = [
        ["#dddddd", 0.3],
        ["#aaaaaa", 0.31],
        ["#888888", 1.0]
    ];
    SharedGradients.filterPaper = [
        ["#ffffffCC", 0.0],
        ["#eeeeee", 1.0],
    ];
    SharedGradients.cylinderMetal = Gradients.cylinder(color("#999999"), 10);
    SharedGradients.cylinderDarkMetal = Gradients.cylinder(color("#555555"), 10);
    SharedGradients.glass = [["#cccccc50", 0.2], ["#cccccc30", 0.21], ["#cccccc40", 0.36], ["#cccccc50", 0.37]];
})(SharedGradients || (SharedGradients = {}));
/** Commonly used colours in the interface. */
export var UIColors;
(function (UIColors) {
    UIColors.hintFill = "#f2cd66";
    UIColors.hintOutline = "#bf8e06";
})(UIColors || (UIColors = {}));
/** Defaut liquid data for newly created apparatus. */
export var DEFAULT_LIQUID_DATA = {
    amountRatio: 0.45,
    color: SharedColors.defaultLiquid,
    alpha: SharedColors.defaultLiquidAlpha,
    layers: [],
    pouring: {
        enabled: false,
        fade: true,
        flowLength: 120,
        flowStrength: 0.7,
    },
    meniscus: {
        enabled: false,
        radius: 6,
        convex: false,
    },
    draining: {
        enabled: false,
        fade: true,
        flowLength: 100,
        thinning: true,
    }
};
export var LIQUID_OPACITY_MIN = 0.0;
export var LIQUID_OPACITY_MAX = 0.8;
export var LIQUID_OPACITY_RANGE = [LIQUID_OPACITY_MIN, LIQUID_OPACITY_MAX];
// Paper utils.
export function P(x, y) {
    return new Point(x, y);
}
export function Polar(length, angle) {
    var p = new Point(0, -length);
    p.angle = angle;
    return p;
}
/** Creates a paper {@link Point} from plain {@link Position} */
export function Pp(p) {
    return P(p.x, p.y);
}
/** Describes a position of a pivot in a rectangle. */
/** Describes a position of a pivot in a rectangle. */
export var Pivot;
(function (Pivot) {
    /** ↖ */
    Pivot[Pivot["TOP_LEFT"] = 0] = "TOP_LEFT";
    /** ↑ */
    Pivot[Pivot["TOP_CENTER"] = 1] = "TOP_CENTER";
    /** ↗ */
    Pivot[Pivot["TOP_RIGHT"] = 2] = "TOP_RIGHT";
    /** ← */
    Pivot[Pivot["CENTER_LEFT"] = 10] = "CENTER_LEFT";
    /** + */
    Pivot[Pivot["CENTER"] = 11] = "CENTER";
    /** → */
    Pivot[Pivot["CENTER_RIGHT"] = 12] = "CENTER_RIGHT";
    /** ↙ */
    Pivot[Pivot["BOTTOM_LEFT"] = 20] = "BOTTOM_LEFT";
    /** ↓ */
    Pivot[Pivot["BOTTOM_CENTER"] = 21] = "BOTTOM_CENTER";
    /** ↘ */
    Pivot[Pivot["BOTTOM_RIGHT"] = 22] = "BOTTOM_RIGHT";
    Pivot[Pivot["RIGHT_CENTER"] = 23] = "RIGHT_CENTER";
})(Pivot || (Pivot = {}));
/** Returns a paper.Rectangle. */
export function R(x, y, width, height, pivot) {
    if (pivot === void 0) { pivot = Pivot.TOP_LEFT; }
    // Split up the enum component into a|b.
    var b = pivot % 10;
    var a = (pivot - b) / 10;
    var leftX = x - (width * b / 2);
    var topY = y - (height * a / 2);
    return new Rectangle(leftX, topY, width, height);
}
/** Returns a rectangle from origin point. */
export function RO(origin, width, height, pivot) {
    if (pivot === void 0) { pivot = Pivot.TOP_LEFT; }
    return R(origin.x, origin.y, width, height, pivot);
}
/** Returns a rectangle from point to point. */
export function RP(x, y, x2, y2) {
    return new Rectangle(x, y, x2 - x, y2 - y);
}
/** Returns a rectangle expanded on all sides by values specified by second rectangle. */
export function expandBy(r, left, top, right, bottom) {
    return RP(r.left - left, r.top - top, r.right + right, r.bottom + bottom);
}
/** Returns a paper.Rectangle, but the origin is the center of the rectangle. */
export function CenterR(centerX, centerY, width, height) {
    return R(centerX, centerY, width, height, Pivot.CENTER);
}
/** Converts paper.Point or array to a paper.Point. */
function p(a) {
    if (a === undefined)
        return undefined;
    return "x" in a ? a : P.apply(void 0, a);
}
/** Helper function to create an array of segments. */
export function Segments() {
    var segments = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        segments[_i] = arguments[_i];
    }
    return segments.map(function (seg) { return S(seg[0], seg[1], seg[2]); });
}
/** Moves all segments by given distance. */
export function moveSegments(segments, point) {
    segments.forEach(function (seg) { return seg.point = seg.point.add(point); });
}
export function cloneSegments(segments) {
    return segments.map(function (seg) { return seg.clone(); });
}
export function rotateSegments(segments, pivot, angle) {
    segments.forEach(function (seg) {
        seg.point = seg.point.rotate(angle, pivot);
        if (seg.handleIn) {
            seg.handleIn = seg.handleIn.rotate(angle);
        }
        if (seg.handleOut) {
            seg.handleOut = seg.handleOut.rotate(angle);
        }
    });
}
export function reverseSegments(segments) {
    // Swap the order of handle in and out
    segments.forEach(function (seg) {
        var handleOut = seg.handleOut.clone();
        seg.handleOut = seg.handleIn;
        seg.handleIn = handleOut;
    });
    segments.reverse();
}
export function mirrorSegmentsX(segments) {
    segments.forEach(function (seg) {
        seg.point.x *= -1;
        if (seg.handleIn) {
            seg.handleIn.x *= -1;
        }
        if (seg.handleOut) {
            seg.handleOut.x *= -1;
        }
    });
    reverseSegments(segments);
}
export function S(point, handleIn, handleOut) {
    return new paper.Segment(p(point), p(handleIn), p(handleOut));
}
// Linear interpolation.
export function lerp(start, end, ratio) {
    return start + (end - start) * ratio;
}
export function interpolate(x, domain, range) {
    if (typeof (range[0]) === "number" && typeof (range[1]) === "number") {
        return lerp(range[0], range[1], (x - domain[0]) / (domain[1] - domain[0]));
    }
    else {
        // Interpolate 1D to 2D
        var positions = range;
        return P(interpolate(x, domain, [positions[0].x, positions[1].x]), interpolate(x, domain, [positions[0].y, positions[1].y]));
    }
}
export function clamp(value, min, max) {
    return value < max ? (value > min ? value : min) : max;
}
export function quantize(value, min, step) {
    if (step == 1) {
        return Math.floor(value);
    }
    if (step < 1) {
        var invertStep = 1 / step;
        return Math.round((value - min) * invertStep) / invertStep + min;
    }
    return Math.floor((value - min) / step) * step + min;
}
/**
 * Step function. E.g. step(x, [1,10], [2,20], 30) returns
 *   10 if x <= 1
 *   20 if x > 1 and x <= 2
 *   30 otherwise (x > 1)
 * The step thresholds must be monotically increasing. This is not checked.
 */
export function stepLeq(x, steps, maxY) {
    for (var _i = 0, steps_1 = steps; _i < steps_1.length; _i++) {
        var _a = steps_1[_i], xThreshold = _a[0], y = _a[1];
        if (x <= xThreshold)
            return y;
    }
    return maxY;
}
/** Convenince class for performing interpolation from fixed domain to fixed range. */
var Interpolator = /** @class */ (function () {
    function Interpolator(domain, range) {
        this.domain = domain;
        this.range = range;
    }
    Interpolator.prototype.interpolate = function (x) {
        return interpolate(x, this.domain, this.range);
    };
    return Interpolator;
}());
export { Interpolator };
