var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { g } from "analytics";
import clipboard from "editing/clipboard";
import { deleteSelected } from "editing/deleting";
import { redo, undo } from "editing/undo_redo";
import { useLoginModal } from "layout/modals/shared_modals";
import { AccountBar } from "layout/top_bar/account";
import { Logo } from "layout/top_bar/extras";
import { MainMenu } from "layout/top_bar/main_menu";
import * as React from "react";
import { Consts } from "vars";
import { AboutModal2 } from "../modals/about";
import { Button, ButtonGroup } from "./button_group";
import { Toolbox } from "./toolbox";
/**
 * Application Top Bar, displays:
 *  - for main actions (such as Save Image, Help...),
 *  - tool selection (move, pan, label...),
 *  - common operations (cut/copy/paste, undo/redo...)
 *  - various links (e.g. social links, link to "What's new" page)
 */
export var TopBar = function (props) {
    var _a = React.useState(false), isAboutModalOpen = _a[0], setIsAboutModalOpen = _a[1];
    var openLogin = useLoginModal().openLogin;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { id: "topBar", key: "topBar" },
            React.createElement(Logo, { key: "logo", onClick: function () { g("Click logo"); setIsAboutModalOpen(true); } }),
            React.createElement("div", { className: "top-bar-scrollable-part", "data-simplebar": true },
                React.createElement(MainMenu, { key: "menubar", file: props.file, auth: props.auth, openLogin: openLogin, cloudDiagramTitle: props.cloudDiagramTitle }),
                React.createElement("div", { className: "secondTopBarRow" },
                    React.createElement(Toolbox, { key: "toolbox", tool: props.tool }),
                    React.createElement(ButtonGroup, { key: "undo_redo", id: "history-toolbox" }, UNDO_REDO_BUTTONS.map(function (b, i) { return React.createElement(Button, __assign({ key: "b_" + i }, b)); })),
                    React.createElement(ButtonGroup, { key: "clipboard" }, CLIPBOARD_BUTTONS.map(function (b, i) { return React.createElement(Button, __assign({ key: "b_" + i }, b)); })),
                    React.createElement(ButtonGroup, { key: "delete" },
                        React.createElement(Button, { key: "delete", icon: "r,trash-alt", hint: "Delete", shortcut: { key: "Del" }, shortcut2: { key: "⌫" }, onClick: function () { deleteSelected("click"); } })),
                    React.createElement(ButtonGroup, null,
                        React.createElement(Button, { icon: "up-right-and-down-left-from-center", hint: "tool.maximize", shortcut: { key: "Q" }, highlight: props.isMaximized, onClick: function () { props.setMaximized(!props.isMaximized); } })))),
            React.createElement(AccountBar, { auth: props.auth })),
        React.createElement(AboutModal2, { key: "about", version: Consts.version, isOpen: isAboutModalOpen, setIsOpen: function (open) { return setIsAboutModalOpen(open); } }));
};
var UNDO_REDO_BUTTONS = [
    {
        hint: "Undo",
        icon: "undo",
        shortcut: { ctrl: true, key: "Z" },
        onClick: function () { undo("click"); }
    },
    {
        hint: "Redo",
        icon: "redo",
        shortcut: { ctrl: true, key: "Y" },
        onClick: function () { redo("click"); }
    },
];
var CLIPBOARD_BUTTONS = [
    {
        hint: "Cut",
        icon: "cut",
        shortcut: { ctrl: true, key: "X" },
        onClick: function () { clipboard.cut("click"); }
    },
    {
        hint: "Copy",
        icon: "r,copy",
        shortcut: { ctrl: true, key: "C" },
        onClick: function () { clipboard.copy("click"); }
    },
    {
        hint: "Paste",
        icon: "r,clipboard",
        shortcut: { ctrl: true, key: "V" },
        onClick: function () { clipboard.paste("click"); }
    }
];
