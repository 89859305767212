import * as React from "react";
/**
 * "square" -> "fas fa-square"
 * "r,square" -> "far fa-square"
 * Also works:
 *  - "square fa-rotate"
 */
function fontAwesomeCss(input) {
    if (input[1] === ",") {
        return "fa" + input[0] + " fa-" + input.substring(2);
    }
    else {
        return "fas fa-" + input;
    }
}
/** Render a font-awesome icon */
export var Icon = function (props) {
    var _a, _b;
    return React.createElement("i", { className: fontAwesomeCss(props.name) + "\n        " + (((_a = props.fullWidth) !== null && _a !== void 0 ? _a : true) ? "fa-fw" : "") + "\n        " + (props.spacing === true ? "mr-hem" : "") + "\n        " + ((_b = props.extraClass) !== null && _b !== void 0 ? _b : "") });
};
