// Component for group of radios
import * as React from "react";
import { useTranslation } from "react-i18next";
import { TierContext } from "tiers/context";
var TIER_VALUE_PLACEHOLDER = "____LOCKED_ITEM____";
export var Select = function (props) {
    var _a;
    var tiers = React.useContext(TierContext);
    var t = useTranslation().t;
    function onChange(e) {
        if (e.target.value === TIER_VALUE_PLACEHOLDER) {
            tiers.popup("PP:" + props.id);
            return;
        }
        props.onSelectChange(props.value, e.target.value);
    }
    return React.createElement("select", { className: (_a = "custom-select " + props.extraClass) !== null && _a !== void 0 ? _a : "", id: props.id, value: props.value, onChange: function (e) { return onChange(e); } }, props.options.map(function (o) {
        var label = t(["props." + o.label, "ui." + o.label, o.label], o.label);
        return tiers.allow(o) ?
            React.createElement("option", { key: o.value, value: o.value }, label)
            : React.createElement("option", { key: o.value, value: TIER_VALUE_PLACEHOLDER },
                label,
                " - \u26A1");
    }));
};
