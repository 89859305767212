// Selection of tools to visually indicate keyboard shorcuts.
import * as React from 'react';
/** Converts an array of strings representing keys to look like keyboard keys. */
export function formatShortcuts() {
    var shortcuts = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        shortcuts[_i] = arguments[_i];
    }
    return shortcuts.map(function (s) { return formatShortcut(s); }).join(" or ");
}
/** Formats a single keyboard shortcutp. */
function formatShortcut(shortcut) {
    var formatted = "";
    if (shortcut.ctrl) {
        formatted += "<span class=\"keyboardKey\">" + ctrlKey() + "</span> + ";
    }
    formatted += "<span class=\"keyboardKey\">" + shortcut.key + "</span>";
    return formatted;
}
/** Returns true if the current system is a Mac. */
export function isMac() {
    return navigator.userAgent.indexOf('Mac') !== -1;
}
/** Returns ⌘ for Macs, Ctrl otherwise. */
export function ctrlKey() {
    return isMac() ? "⌘" : "Ctrl";
}
export var Key = function (props) { return (React.createElement(React.Fragment, null,
    props.ctrl ? React.createElement(React.Fragment, null,
        React.createElement(CtrlKey, null),
        " + ") : null,
    React.createElement("span", { className: "keyboardKey" }, props.k))); };
export var FAKey = function (props) { return (React.createElement(React.Fragment, null,
    React.createElement("span", { className: "keyboardKey" },
        React.createElement("i", { className: "fa" + props.icon })))); };
export var CtrlKey = function () { return (React.createElement("span", { className: "keyboardKey" }, ctrlKey())); };
