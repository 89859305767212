import { P } from "apparatus/library/common";
import { Group, Shape } from "paper";
import { Random } from "random/random";
var COLOR_SWATCHES = {
    "Default": "#cccccc",
    "Red": "#E16060",
    "Green": "#5EC63D",
    "Blue": "#68B7D7",
};
var Bubbles = /** @class */ (function () {
    function Bubbles() {
    }
    Bubbles.prototype.render = function (appearance) {
        var rows = appearance.rows;
        var bubblesPerRow = appearance.bubblesPerRow;
        var spacing = appearance.spacing;
        var baseSize = appearance.bubbleSize;
        var r = new Random();
        var graphic = new Group();
        for (var y = 0; y < rows; y++) {
            var xShift = y % 2 == 1 ? 0.5 : 0;
            for (var x = 0; x < (bubblesPerRow - xShift * 2); x++) {
                var position = P((x + xShift) * spacing, y * spacing);
                var size = baseSize + r.randomCenteredValue();
                var bubble = Shape.Circle(position.add(r.randomUniformVector(spacing / 4)), size);
                graphic.addChild(bubble);
            }
        }
        graphic
            .withStroke("thinner", appearance.color)
            .withFill(appearance.color.color().lighten(0.3).alpha(0.5).toString());
        return { graphic: graphic, hitShape: graphic.bounds.toShape() };
    };
    Bubbles.properties = {
        label: "Bubbles",
        defaultAppearance: {
            rows: 3,
            bubblesPerRow: 4,
            spacing: 18,
            bubbleSize: 4,
            color: "#cccccc"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "rows", label: "Rows",
                min: 1, max: 5,
            },
            {
                spec: "slider", key: "bubblesPerRow", label: "Bubbles per row",
                min: 1, max: 10,
            },
            {
                spec: "slider", key: "spacing", label: "Spacing",
                min: 12, max: 30
            },
            {
                spec: "slider", key: "bubbleSize", label: "Bubble size",
                min: 3.0, max: 8.0, step: 0.1,
            },
            {
                spec: "color", key: "color", label: "Colour",
                swatches: COLOR_SWATCHES,
                demoSwatches: COLOR_SWATCHES,
                tier: "boost"
            }
        ],
    };
    return Bubbles;
}());
export { Bubbles };
