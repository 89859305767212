import { setDefaultStyle, strokeWidth } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
var Tweezers = /** @class */ (function () {
    function Tweezers() {
    }
    Tweezers.prototype.render = function (appearance) {
        if (appearance.style == "3d") {
            return render3d(appearance);
        }
        else {
            return renderSimple(appearance);
        }
    };
    Tweezers.properties = {
        label: "Tweezers",
        flippable: true,
        defaultAppearance: {
            style: "3d",
            opening: 0.5
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "style", label: "Style",
                options: [
                    { value: "3d", label: "Default" },
                    { value: "simple", label: "Simple" },
                ],
            },
            {
                spec: "slider", key: "opening", label: "Opening",
                min: 0.0, max: 1.0, step: 0.1,
            },
        ],
    };
    return Tweezers;
}());
export { Tweezers };
function render3d(appearance) {
    var top = new Path(Segments([[0, 0]], [[16, -9], [-10, 2], [10, -2]], [[40, -8], [-3, 2]], [[45, -11]], [[154, -10], , [9, 0]], [[155, 1], [8, 0]], [[44, -2], , [-10, 0]], [[17, -5], [10, 0], [-7, 0]]));
    top.closePath();
    var bottom = top.clone();
    top.withGradientFill("right", Gradients.cylinder("#aaaaaa".color(), 5))
        .withStroke(strokeWidth("thinner"), "#999999");
    bottom.fillColor = "#666666";
    bottom.shear(-0.08 * appearance.opening, P(155, -5));
    var graphic = new Group([bottom, top]);
    return {
        graphic: graphic,
        hitShape: graphic.bounds.expand(10).toShape()
    };
}
function renderSimple(appearance) {
    var oneSide = new Path(Segments([[-120, 0], , [5, -13]], [[0, 0], [-120, -7]]));
    var otherSide = oneSide.clone();
    var pivot = P(0, 0);
    otherSide.scale(1.0, -1.0, pivot);
    var angle = 5 * appearance.opening + 1;
    oneSide.rotate(angle, P(0, 0));
    otherSide.rotate(-angle, pivot);
    var graphic = new Group([oneSide, otherSide]);
    setDefaultStyle(graphic);
    return {
        graphic: graphic,
        hitShape: graphic.bounds.expand(10).toShape()
    };
}
