/** Deliberately broken apparatus. */
var Borked = /** @class */ (function () {
    function Borked() {
    }
    Borked.prototype.render = function (appearance) {
        return {
            graphic: undefined,
            hitShape: undefined
        };
    };
    Borked.properties = {
        label: "Borked",
        canContainLiquids: true,
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return Borked;
}());
export { Borked };
