import * as React from "react";
import { useTranslation } from "react-i18next";
import { Tip } from "view/tooltips";
/**
 * Shows the badge to indicate this feature requires an upgrade.
 * Showing the promo popup must be handled by the parent.
 * @note Plus tier not yet supported.
 */
export var Badge = function (props) {
    var _a, _b;
    var t = useTranslation().t;
    var badgeClasses = ((_a = props.style) !== null && _a !== void 0 ? _a : "lite") == "classic" ? "fak fa-boost" : "fa fa-bolt-lightning";
    return React.createElement(Tip, { content: props.hideHint ? undefined : t("promo.badge.hint") },
        React.createElement("i", { className: "upgrade-badge " + badgeClasses + " boost-text-gradient " + ((_b = props.extraClass) !== null && _b !== void 0 ? _b : "") }));
};
export var SchoolBadge = function () { return React.createElement("span", { className: "fa-stack" },
    React.createElement("i", { className: "fas fa-badge fa-stack-2x school-text-gradient", style: { lineHeight: "1.11em" } }),
    React.createElement("i", { className: "fas fa-stack-1x icon-inside text-white fa-university" })); };
