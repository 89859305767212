var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var EllipticalStirringBar = /** @class */ (function () {
    function EllipticalStirringBar() {
    }
    EllipticalStirringBar.prototype.render = function (appearance) {
        var shape = new Path(Segments([[25, -5], [-10, -11]], [[27, -4]])).close()
            .mirrorY()
            .mirrorX();
        var center = new Path(Segments([[27, 3], [0, 2]], [[27, -4], , [0, 2]])).mirrorX()
            .close()
            .withGradientFill("down", [
            ["#eeeeee", 0.1],
            ["#e0e0e0", 0.7],
            ["#e0e0e0", 0.4],
            ["#eeeeee", 0.9],
        ]);
        var outline = shape.clone();
        outline.withStroke(appearance.size > 50 ? "default" : "thin");
        shape.withGradientFill("down", [
            ["#eeeeee", 0.1],
            ["#aaaaaa", 0.5],
            ["#eeeeee", 0.9],
        ]);
        // const dot = new Path.Circle(P(0, -12), 4.0)
        //     .withFill("#111111")
        // dot.scale(1, 0.3)
        var graphic = new Group([shape, center, outline]);
        graphic.scale(appearance.size / 50);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape()
        };
    };
    EllipticalStirringBar.properties = {
        label: "Elliptical Stir Bar",
        defaultAppearance: {
            size: 50
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 40, max: 70, unit: "mm"
            }
        ],
    };
    EllipticalStirringBar = __decorate([
        staticImplements()
    ], EllipticalStirringBar);
    return EllipticalStirringBar;
}());
export { EllipticalStirringBar };
