var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Group, Path } from "paper";
import { addMarkers, mirrorX, setDefaultStyle } from "../draw";
import { DEFAULT_LIQUID_DATA, markerSpacing, P, Segments } from "./common";
var GasSyringe = /** @class */ (function () {
    function GasSyringe() {
    }
    GasSyringe.prototype.render = function (appearance) {
        var length = appearance.size;
        var diameter = length / 4 + 4;
        var outletLength = 15;
        var radius = diameter / 2;
        var plungerRadius = radius - 2;
        var outletRadius = 4;
        var body = new Path(Segments(
        // Start from the tip
        [[outletRadius, length + outletLength]], [[outletRadius, length]], [[radius, length - 3], [0, 3]], // Slight rounded corner
        [[radius, 0], undefined, [20, -2]]));
        mirrorX(body);
        var liquidMask = body.clone();
        setDefaultStyle(body);
        // Plunger.
        var plunger = new Path(Segments([[plungerRadius, length - 5], [0, 3]], [[plungerRadius, -2], undefined, [30, -1]]));
        mirrorX(plunger);
        plunger.closePath();
        setDefaultStyle(plunger);
        plunger.fillColor = "#eeeeee";
        plunger.position.y -= appearance.plungerSlide * length;
        var graphic = new Group([body, plunger]);
        addMarkers(graphic, P(plungerRadius - 5, length), 0, P(0, markerSpacing));
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: [body.bounds.toShape(), plunger.bounds.toShape()],
            pivot: body.bounds.center,
            snapping: {
                type: "connectable", at: P(0, length + outletLength), facing: 180,
            }
        };
    };
    GasSyringe.properties = {
        label: "Gas Syringe",
        canContainLiquids: true,
        defaultLiquidData: __assign(__assign({}, DEFAULT_LIQUID_DATA), { amountRatio: 0.3 }),
        defaultAppearance: {
            plungerSlide: 0.4,
            size: 120,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "plungerSlide", label: "Plunger slide",
                min: 0.0, max: 0.95, step: 0.01,
            },
            {
                spec: "slider", key: "size", label: "Size",
                min: 80, max: 200
            }
        ],
    };
    return GasSyringe;
}());
export { GasSyringe };
