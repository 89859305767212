import { SVG } from "apparatus/svg";
var TestTubeHolder = /** @class */ (function () {
    function TestTubeHolder() {
    }
    TestTubeHolder.prototype.render = function (appearance) {
        var graphic = SVG.testTubeHolder();
        graphic.scale(appearance.scale, 1.0);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    TestTubeHolder.properties = {
        label: "Test Tube Holder (Metal)",
        flippable: true,
        defaultAppearance: {
            scale: 1
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.8, max: 1.25, step: 0.01, unit: "%"
            }
        ],
    };
    return TestTubeHolder;
}());
export { TestTubeHolder };
