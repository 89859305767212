var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path } from "paper";
var MetalStrip = /** @class */ (function () {
    function MetalStrip() {
    }
    MetalStrip.prototype.render = function (appearance) {
        var baseColor = appearance.color;
        // Set the stroke by darkening the base color.
        var strokeColor = baseColor.color().darken(0.2).hex();
        // Create a gradient composed of:
        // baseColor -------- highlightColor ------ baseColor
        // where the highlightColor is baseColor lightened up.
        // Make the highlight section tighter by moving the
        // baseColor stops to position closer to 0.5.
        var fillGradient = [
            [baseColor, 0.3],
            [baseColor.color().lighten(0.2).hex(), 0.5],
            [baseColor, 0.7]
        ];
        var graphic = createShape(appearance, strokeColor, fillGradient);
        return { graphic: graphic, hitShape: graphic.rectHitShape() };
    };
    MetalStrip.properties = {
        label: "Metal Strip",
        defaultAppearance: {
            length: 60,
            width: 20,
            size: 65,
            shape: "bent",
            color: SharedColors.copper,
        },
        flippable: true,
        appearanceSpecs: [
            {
                spec: "radio", key: "shape", label: "Shape",
                options: [
                    { value: "straight", label: "Straight" },
                    { value: "bent", label: "Bent" },
                    { value: "coiled", label: "Coiled", tier: "boost" },
                ],
                allowSelectingTierLockedOptions: true,
            },
            {
                spec: "slider", key: "length", label: "Length",
                min: 40, max: 100, visible: function (a) { return a.shape !== "coiled"; }
            },
            {
                spec: "slider", key: "size", label: "Size",
                min: 20, max: 80, visible: function (a) { return a.shape === "coiled"; }
            },
            {
                spec: "slider", key: "width", label: "Width",
                min: 5, max: 50
            },
            {
                spec: "color", key: "color", label: "Color/Material",
                swatches: {
                    "Zinc": "#bac4c8",
                    "Silver": SharedColors.lightMetal,
                    "Aluminium": SharedColors.aluminum,
                    "Gold": SharedColors.gold,
                    "Copper": SharedColors.copper,
                    "Iron": SharedColors.darkMetal,
                }
            },
        ],
        isBoostRequired: function (appearance) {
            return appearance.shape === "coiled";
        },
    };
    return MetalStrip;
}());
export { MetalStrip };
function createShape(appearance, strokeColor, fillGradient) {
    var length = appearance.length;
    var width = appearance.width;
    switch (appearance.shape) {
        case "straight":
            return new Path(Segments([[0, 0]], [[0, length]], [[width, length]], [[width, 0]])).close()
                .withStroke("thinner", strokeColor)
                .withGradientFill("right-down", fillGradient);
        case "bent":
            var bendheight = length * 0.6;
            var twistOffset = width * 1.25;
            var curveHandleLength = bendheight * 0.4;
            return new Path(Segments([[0, 0]], [[width, 0]], [[width, bendheight], undefined, [0, curveHandleLength]], [[twistOffset, length]], [[twistOffset - width, length]], [[0, bendheight], [0, curveHandleLength]])).close()
                .withStroke("thinner", strokeColor)
                .withGradientFill("right-down", fillGradient);
        case "coiled":
            return createCoiledShape(appearance, strokeColor, fillGradient);
    }
}
function createCoiledShape(appearance, strokeColor, fillGradient) {
    var coilHeight = appearance.size;
    var extrusion = appearance.width * 0.7;
    var darkerGradient = __spreadArray([], fillGradient);
    darkerGradient.unshift([fillGradient[1][0].color().ladd(-30).hex(), 1.0]);
    var coil1 = new Path(Segments([[0, 0], , [-coilHeight * 0.45, 0]], [[0, coilHeight], [-coilHeight * 0.45, 0]])).close().extrude(P(extrusion, 0))
        .withStroke("thinner", strokeColor)
        .withGradientFill("right-down", fillGradient);
    var coil2 = new Path(Segments([[coilHeight * 0.05, coilHeight * 0.25], , [coilHeight * 0.26, 0]], [[0, coilHeight], [coilHeight * 0.3, 0]])).close().extrude(P(extrusion, 0))
        .withStroke("thinner", strokeColor)
        .withGradientFill("left-up", darkerGradient);
    var coil3 = new Path(Segments([coil2.segments[0].point, , [-coilHeight * 0.15, 0]], [[-coilHeight * 0.15, coilHeight * 0.7], [-coilHeight * 0.05, -coilHeight * 0.1]])).close().extrude(P(extrusion, 0))
        .withStroke("thinner", strokeColor)
        .withGradientFill("left-down", darkerGradient);
    return new Group([coil2, coil3, coil1]);
}
