import { Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
var SteelWool = /** @class */ (function () {
    function SteelWool() {
    }
    SteelWool.prototype.render = function (appearance) {
        var scale = appearance.scale, squish = appearance.squish;
        var wire1 = new Path(Segments([[16.5, 13.9], , [16.0, 11.0]], [[38.5, 24.9], [-1.0, 0.0], [0.0, 0.0]], [[12.5, 29.9], [11.2, 4.8], [-3.3, -1.4]], [[21.5, 4.9], [-6.8, 5.2], [9.3, -7.1]], [[31.5, 22.9], [9.6, -10.9], [-5.0, 5.7]], [[14.5, 20.9], [2.1, -0.9], [-2.9, 1.2]], [[30.5, 33.9], [-16.5, 1.1], [13.0, -0.8]], [[33.5, 10.9], [12.0, -1.8], [-26.5, 4.0]], [[1.5, 26.9], [5.3, 1.1], [-2.8, -0.6]], [[17.5, 19.9], [-12.0, -0.3], [19.0, 0.5]], [[44.5, 12.9], [1.2, 5.0], [-1.5, -6.2]], [[17.5, 23.9], [10.0, -6.8], [-1.9, 1.3]], [[27.5, 15.9], [-11.2, 3.1], [5.8, -1.6]], [[44.5, 14.9], [-5.3, 4.7], [3.1, -2.8]], [[35.5, 10.9], [6.7, -5.3], [-15.3, 12.0]], [[21.5, 29.9], [0.7, 1.2], [-2.0, -3.6]], [[22.5, 9.9], [-8.2, 0.9], [4.4, -0.5]], [[26.5, 29.9], [1.4, -1.4], [-0.7, 0.7]], [[23.5, 12.9], [-3.9, 11.4], [3.7, -10.8]], [[39.5, 19.9], [4.0, -13.6], [-3.0, 10.2]], [[11.5, 17.9], [7.2, 12.2], [-3.7, -6.3]], [[10.5, 6.9], [-2.8, 0.0], [11.3, 0.0]], [[36.5, 29.9], [-5.6, 1.5], [9.9, -2.7]], [[41.5, 7.9], [4.9, 6.4], [-3.3, -4.3]], [[12.5, 28.9], [8.8, -7.2], [-1.7, 1.4]], [[18.5, 19.9], [-6.5, 4.3], [7.5, -4.9]], [[43.5, 11.9], [-6.6, 5.5], [4.5, -3.7]], [[27.5, 14.9], [11.5, -13.5], [-7.0, 8.2]], [[17.5, 34.9], [2.0, -7.0], [0.0, 0.0]], [[17.5, 19.9], [0.0, 5.0]])).withStroke(1.5, "#777777");
        var wire2 = new Path(Segments([[27.5, 13.6], , [-9.0, 15.0]], [[12.5, 35.6], [2.6, -1.1], [-5.5, 2.3]], [[20.5, 19.6], [-14.9, 5.3], [12.3, -4.4]], [[45.5, 19.6], [-4.2, 4.2], [0.7, -0.7]], [[34.5, 13.6], [9.5, 0.0], [-12.6, 0.0]], [[12.5, 34.6], [1.0, -6.0], [0.0, 0.0]], [[14.5, 32.6], [-1.0, 0.2], [5.3, -1.0]], [[34.5, 28.6], [-4.4, 5.8], [5.4, -7.2]], [[26.5, 9.6], [3.2, 0.1], [-4.1, -0.2]], [[26.5, 29.6], [-8.4, -3.9], [9.1, 4.2]], [[35.5, 16.6], [5.0, 5.7], [-3.8, -4.4]], [[20.5, 31.6], [-4.2, -7.9], [3.4, 6.4]], [[33.5, 25.6], [2.6, 7.6], [-5.0, -14.7]], [[15.5, 13.6], [1.5, -1.3], [-7.9, 6.5]], [[30.5, 38.6], [-10.6, 3.5], [4.2, -1.4]], [[24.5, 13.6], [3.4, 2.6], [-6.4, -4.9]], [[8.5, 27.6], [-7.4, -14.2], [5.6, 10.8]], [[36.5, 32.6], [-1.6, 3.9], [1.1, -2.8]], [[23.5, 21.6], [11.4, -0.7], [-10.8, 0.6]], [[17.5, 34.6], [-4.9, -1.0], [4.0, 0.8]], [[25.5, 28.6], [-1.2, 1.9], [2.6, -4.3]], [[34.5, 29.6], [-0.7, -6.4], [-1.1, -7.0]], [[41.5, 14.6], [-6.3, 1.3], [7.1, -1.4]], [[49.5, 31.6], [5.4, -4.9], [-3.7, 3.5]], [[40.5, 25.6], [0.3, 4.1], [-1.1, -13.0]], [[53.5, 9.6], [-5.6, -5.8], [8.3, 8.7]], [[39.5, 40.6], [3.0, -3.0]])).withStroke(1.5, "#999999bb");
        wire2.rotate(-20);
        wire2.position.x -= 5;
        wire2.position.y -= 2;
        var graphic = new Group([wire1, wire2]);
        graphic.rotate(20);
        switch (appearance.state) {
            case "normal": break;
            case "burning":
                wire2.setRadialGradientStroke([["#F7CA6Cbb", 0.15], ["#F4D76Abb", 0.6], ["#7B6D6Dbb", 1.0]]);
                var glow = new Path.Circle(wire1.bounds.center, 22.0).setRadialGradientFill([["#feee8dff", 0.0], ["#f1990d80", 0.5], ["#f9e57b00", 1.0]]);
                glow.enableScalableGradient();
                glow.scale(1.2, 1.0);
                glow.blendMode = "lighten";
                graphic.addChild(glow);
                break;
            case "burnt":
                wire1.strokeColor = "#333333";
                wire2.strokeColor = "#222222bb";
        }
        graphic.scale(scale, scale * (1 - squish));
        // const bounds = graphic.bounds.clone().expand(-20)
        // const r = new Random()
        // for (var i = 0; i < 15; i++) {
        //     const p = r.randomInBounds(bounds)
        //     const spark = Shape.Circle(p, 5.0)
        //     spark.setRadialGradientFill([["white", 0.0],["#e7c924ff", 0.4], ["#f1990d00", 1.0]])
        //     spark.blendMode = "hard-light"
        //     graphic.addChild(spark)
        // }
        // wire1.strokeColor = new Color(
        //     new Gradient([["#ffffe2", 0.0], ["#fcaf3a", 0.5],["#777777", 0.9]], true),
        //     wire1.bounds.center,
        //     wire1.bounds.rightCenter,
        // )
        // wire2.strokeColor = new Color(
        //     new Gradient([["#fff99ebb", 0.0], ["#999999bb", 1.0]], true),
        //     wire2.bounds.center,
        //     wire2.bounds.rightCenter,
        // )
        // const spark = Shape.Circle(P(25, 25), 20.0)
        // spark.setRadialGradientFill([["white", 0.0],["#e7c924ff", 0.4], ["#ffd37a00", 1.0]])
        // spark.blendMode = "overlay"
        // spark.opacity = 0.3
        // graphic.addChild(spark)
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(10).toShape(),
            pivot: wire1.bounds.center,
        };
    };
    SteelWool.properties = {
        label: "Steel Wool",
        defaultAppearance: {
            scale: 1.0,
            squish: 0.0,
            state: "normal"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Size",
                min: 0.75, max: 1.5, step: 0.01, unit: "%"
            },
            {
                spec: "slider", key: "squish", label: "Squish",
                min: 0.0, max: 0.5, step: 0.01, unit: "%"
            },
            {
                spec: "radio", key: "state", label: "",
                options: [
                    { value: "normal", label: "Normal" },
                    { value: "burning", label: "Burning" },
                    { value: "burnt", label: "Burnt" },
                ],
            }
        ],
    };
    return SteelWool;
}());
export { SteelWool };
