import { de, es, fr } from "date-fns/locale";
import i18n from "i18n";
import { t } from "i18next";
export function getDateFnsLocale() {
    var mainLanguage = i18n.language.substring(0, 2);
    if (mainLanguage == "de") {
        return de;
    }
    else if (mainLanguage == "es") {
        return es;
    }
    else if (mainLanguage == "fr") {
        return fr;
    }
    return undefined;
}
/** Returns a "Add <item>" translated string. */
export function t_add(item) {
    return t("ui.add", { item: t('apparatus.' + item + '.name') });
}
