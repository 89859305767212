var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PropertyPanel } from "components/propertyPanel";
import { sectionsForOneApparatus } from "panel/apparatus_panel_ui";
import { arrowPanelUI } from "panel/arrow_panel_ui";
import { sectionsForDiagram } from "panel/diagram_panel_ui";
import { sectionsForMultipleItems } from "panel/propertyPanel";
import { sectionsForMultipleItemsOfSameType } from "panel/same_type_apparatus";
import * as React from "react";
import { TierContext } from "tiers/context";
/**
 * Adapter element that basically contians a PropertyPanel
 * and is responsible for creating elements for the property
 * panel based on the current state of the app.
 */
var PropertyPanelAdapter = /** @class */ (function (_super) {
    __extends(PropertyPanelAdapter, _super);
    function PropertyPanelAdapter(props) {
        return _super.call(this, props) || this;
    }
    // Creates PropertyPanel props from current selection.
    PropertyPanelAdapter.prototype.createPropsForChild = function (tiers) {
        var _a = this.props, selected = _a.selected, app = _a.app;
        if (this.props.selected.isEmpty) {
            return sectionsForDiagram(this.props.theme, app);
        }
        else if (selected.items.length == 1 && selected.arrows.length == 0) {
            return sectionsForOneApparatus(selected.items[0], app, tiers, selected);
        }
        else if (selected.items.length == 0 && selected.arrows.length == 1) {
            return arrowPanelUI(selected, selected.arrows, app, tiers);
        }
        else if (selected.items.length == 0 && selected.arrows.length >= 1) {
            return arrowPanelUI(selected, selected.arrows, app, tiers);
        }
        else if (selected.items.length > 1 && (selected.sameApparatusType || selected.sameApparatusKind)) {
            return sectionsForMultipleItemsOfSameType(selected, app, tiers);
        }
        else {
            return sectionsForMultipleItems(selected, app);
        }
    };
    PropertyPanelAdapter.prototype.render = function () {
        var _this = this;
        return React.createElement(TierContext.Consumer, { key: "PropPanelConsumer" }, function (tiers) { return (React.createElement(PropertyPanel, __assign({}, _this.createPropsForChild(tiers), { key: "PropertyPanel", onToggleCollapsed: _this.props.onToggleCollapsed, collapsed: _this.props.collapsed }))); });
    };
    return PropertyPanelAdapter;
}(React.Component));
export { PropertyPanelAdapter };
