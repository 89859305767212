import { addMarkers, mirrorX, setDefaultStyle } from "apparatus/draw";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var EppendorfTube = /** @class */ (function () {
    function EppendorfTube() {
    }
    EppendorfTube.prototype.render = function (appearance) {
        var shape = new Path(Segments([[8, 0]], [[8, appearance.size - 25]], [[0, appearance.size], appearance.bottom == "round" ? [10, 0] : [5, 0]]));
        mirrorX(shape);
        var liquidMask = shape.clone();
        setDefaultStyle(shape);
        var graphic = new Group();
        addMarkers(graphic, P(2, 45), 0, P(0, 10));
        // Create a cap and the linking plastic.
        var capTop = new Path.Line(P(-8, -4), P(8, -4));
        capTop.strokeWidth = 5.0 * appearance.zoom;
        capTop.strokeCap = "round";
        capTop.setGradientStroke("right", [["#aaaaaa", 0.0], ["#999999", 1.0]]);
        var capInsert = Shape.Rectangle(R(0, -4, 14, 10, Pivot.TOP_CENTER), 2.0);
        capInsert.setGradientFill("right", [["#666666", 0.0], ["#444444", 1.0]]);
        capInsert.opacity = 0.45;
        var linking = new Path(Segments([[-8, 0], undefined, [-10, 0]], appearance.cap == "open" ? [[-18, -9]] : [[-8, -4], [-10, -3]]));
        linking.withStroke(3.0 * appearance.zoom, "#cccccc");
        if (appearance.cap == "open") {
            capTop.rotate(-130, P(-14, -4));
            capInsert.rotate(-130, P(-14, -4));
        }
        graphic.addChildren([linking, shape, capInsert, capTop]);
        // Finally, zoom the graphic.
        graphic.scale(appearance.zoom);
        liquidMask.scale(appearance.zoom, graphic.bounds.center);
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.bounds.toShape(),
            pivot: shape.bounds.center,
        };
    };
    EppendorfTube.properties = {
        label: "Eppendorf Tube",
        canContainLiquids: true,
        flippable: true,
        defaultAppearance: {
            size: 50,
            zoom: 1.0,
            cap: "open",
            bottom: "sharp"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 50, max: 70, step: 1,
            },
            {
                spec: "radio", key: "cap", label: "Cap",
                options: [
                    { value: "open", label: "Open" },
                    { value: "closed", label: "Closed" },
                ],
            },
            {
                spec: "radio", key: "bottom", label: "Bottom shape",
                options: [
                    { value: "sharp", label: "Sharp" },
                    { value: "round", label: "Round" },
                ],
            },
            {
                spec: "slider", key: "zoom", label: "Zoom",
                min: 1.0, max: 2.0, step: 0.01, unit: "%"
            },
        ],
    };
    return EppendorfTube;
}());
export { EppendorfTube };
