var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Menu, MenuDivider, MenuItem } from "@szhsin/react-menu";
import Tippy from "@tippyjs/react";
import { g } from "analytics";
import { DeleteArrows } from "arrows/command";
import { Cookie } from "browser/cookie";
import { DeleteApparatus, Deselect } from "command/basic";
import { commandProcessor, Sequence } from "command/command";
import Flags from "flags";
import { Keys } from "keyboard";
import { HelpModal2 } from "layout/modals/help";
import { useLoginModal, useSharedModals } from "layout/modals/shared_modals";
import { SocialWidgets } from "layout/top_bar/extras";
import { LanguagePicker } from "layout/top_bar/lang";
import * as React from "react";
import { useTranslation } from "react-i18next";
import store from "store/store";
import { Articles, Bcn } from "support/beacon";
import { Badge } from "tiers/badge";
import { TierContext } from "tiers/context";
import { Consts } from "vars";
import { Hint, Tip } from "view/tooltips";
import { registerKeyboardShortcutHandler } from "../../keyboard";
import { setCloudDiagram } from "../../store/actions";
import { appStore } from "../../store/store";
import { formatShortcuts } from "../../view/keyboard_shortucts";
/**
 * Main menu for main actions: New, Save Image etc.
 */
export var MainMenu = function (props) {
    var _a = React.useState(false), showHelp = _a[0], setShowHelp = _a[1];
    var t = useTranslation().t;
    var tier = React.useContext(TierContext).tier;
    var exportImage = useSharedModals().exportImage;
    function openHelp(source) {
        g("Help", source);
        setShowHelp(true);
    }
    function createNew(source) {
        // TODO!
        // if (Flags.isNewTemplatesEnabled) {
        //     this.newModalRef.current!.open()
        //     return
        // }
        if (confirm(t("info.new_erase_confirm")) == true) {
            var commands = [
                new Deselect(store.selectedObjectsID()),
                new DeleteApparatus(store.items()),
                new DeleteArrows(store.arrows()),
            ];
            commandProcessor.execute(new Sequence(commands));
            commandProcessor.markClean();
            appStore.dispatch(setCloudDiagram(undefined));
            g("New", source);
        }
        else {
            g("New-Cancelled");
        }
    }
    React.useEffect(function () {
        registerKeyboardShortcutHandler(function (e) {
            var isCtrlDown = e.ctrlKey || e.metaKey;
            if (e.keyCode == Keys.QUESTION_MARK) {
                openHelp("keyboard");
            }
            else if (e.keyCode == Keys.S && isCtrlDown) {
                e.preventDefault();
                toastr.info(t("info.ctrl_s_prompt") + " " + formatShortcuts({ ctrl: true, key: "E" }));
            }
            else if (e.keyCode == Keys.E && isCtrlDown) {
                e.preventDefault();
                exportImage("keyboard");
            }
            else if (e.keyCode == Keys.N && isCtrlDown) {
                e.preventDefault();
                createNew("keyboard");
            }
        });
    }, []);
    return (React.createElement("div", { id: "menubar", key: "menubar" },
        React.createElement(Item, { key: "new", title: t("menu.new.title"), icon: "r fa-plus", hint: t("menu.new.hint") + (Flags.isNewTemplatesEnabled ? "<br/>or a diagram from a template" : ""), shortcut: { ctrl: true, key: "N" }, onClick: function () { return createNew("click"); } }),
        React.createElement(Item, { key: "download", title: t("menu.download.title"), icon: "s fa-arrow-down-to-bracket", hint: t("menu.download.hint"), shortcut: { ctrl: true, key: "E" }, onClick: function () { return exportImage("click"); }, id: "menu-export" }),
        props.auth.state == "logged_in" ? React.createElement(CloudMenu, __assign({}, props)) : React.createElement(SignedOutCloudMenu, __assign({}, props)),
        React.createElement(Item, { key: "help", title: t("menu.help.title"), icon: "s fa-question", hint: t("menu.help.hint"), shortcut: { key: "?" }, onClick: function () { return openHelp("click"); }, cssClass: "help" }),
        React.createElement(WhatsNew, { key: "whats_new" }),
        React.createElement(LanguagePicker, null),
        React.createElement("div", { style: { flexGrow: 1 } }),
        " ",
        tier === undefined ? React.createElement(SocialWidgets, { key: "widgets" }) : null,
        React.createElement("div", { style: { width: 16, flexShrink: 0 } }),
        " ",
        React.createElement(HelpModal2, { isOpen: showHelp, setIsOpen: setShowHelp })));
};
var Item = function (item) {
    var _a;
    return (React.createElement(Tip, { content: (item.hint || item.shortcut) ? React.createElement(Hint, { text: (_a = item.hint) !== null && _a !== void 0 ? _a : "", shortcut: item.shortcut }) : undefined },
        React.createElement("a", { id: item.id, className: item.cssClass + " menuBarItem", onClick: function () { return (item.onClick != undefined ? item.onClick() : {}); }, href: item.href, target: "_blank" },
            React.createElement("i", { className: "fa" + item.icon, style: item.iconStyle }),
            React.createElement("span", null,
                item.title,
                " "),
            item.badge ? React.createElement(Badge, { tier: item.badge }) : null)));
};
var SignedOutCloudMenu = function (props) {
    var openLogin = useLoginModal().openLogin;
    var saveToCloud = useSharedModals().saveToCloud;
    var t = useTranslation().t;
    return React.createElement(Menu, { menuButton: React.createElement(DropdownTarget, { title: t("menu.save_to_cloud.title"), icon: "s fa-cloud-upload", hint: "" }), portal: true, transition: true },
        React.createElement(Tippy, { content: t('menu.save_to_cloud.sign_in_hint') },
            React.createElement(MenuItem, { onClick: function () { return openLogin(function () { return saveToCloud(); }); } },
                React.createElement("i", { className: "fas fa-square-user" }),
                t('menu.save_to_cloud.sign_in_prompt'))));
};
var CloudMenu = function (props) {
    var _a = useSharedModals(), openFiles = _a.openFiles, saveToCloud = _a.saveToCloud;
    var t = useTranslation().t;
    return React.createElement(Menu, { menuButton: React.createElement(DropdownTarget, { title: t("menu.cloud.title"), icon: "s fa-cloud", hint: "Save your diagrams and access previously saved diagrams." }), portal: true, transition: true },
        React.createElement(MenuItem, { onClick: function () { return openFiles(); } },
            React.createElement("i", { className: "fas fa-folder-open" }),
            t('Open')),
        React.createElement(MenuDivider, null),
        React.createElement(MenuItem, { key: "save", onClick: function () { return saveToCloud(); } },
            React.createElement("i", { className: "fas fa-cloud-upload" }),
            t('Save')),
        React.createElement(MenuItem, { key: "save_new", onClick: function () { return saveToCloud({ forceNew: true }); } },
            React.createElement("i", { className: "fas fa-cloud-upload" }),
            t('Save as new')),
        props.cloudDiagramTitle ?
            React.createElement("div", { className: "bg-secondary small mt-1 py-1 pl-4 pr-2" },
                React.createElement("b", null,
                    t("cloud.current_file"),
                    ":"),
                React.createElement("br", null),
                props.cloudDiagramTitle)
            : undefined);
};
/**
 * // Disable hint on iOS
 */
var DropdownTarget = React.forwardRef(function (props, ref) {
    return React.createElement(Tip, { content: props.hint },
        React.createElement("div", __assign({ className: "menuBarItem" }, props, { title: undefined, ref: ref, id: "cloud-menu" }),
            React.createElement("i", { className: "fa" + props.icon }),
            props.title,
            props.badge ? React.createElement("span", { className: "ml-1 badge badge-secondary align-text-top" }, props.badge) : null,
            React.createElement("i", { className: "fas fa-caret-right dropdown-indicator" })));
});
function shouldHighlightWhatsNew() {
    var lastSeenVersion = Cookie.read("LAST_SEEN_VERSION");
    if (lastSeenVersion) {
        lastSeenVersion = lastSeenVersion.match("[0-9]*\.[0-9]*")[0];
    }
    return lastSeenVersion != Consts.majorVersion;
}
var WhatsNew = function () {
    var _a = React.useState(shouldHighlightWhatsNew()), isHighlighted = _a[0], setIsHighlighted = _a[1];
    var t = useTranslation().t;
    /** Called when the "What's new" link is clicked */
    function onClick() {
        Bcn.article(Articles.NEW, "modal");
        Cookie.write("LAST_SEEN_VERSION", Consts.majorVersion);
        setIsHighlighted(false);
    }
    return React.createElement(Item, { cssClass: isHighlighted ? "text-glowing" : "", title: t("menu.whats_new.title"), icon: "s fa-bolt", onClick: function () { return onClick(); } });
};
