var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { addMarkers2 } from "apparatus/draw";
import { interpolate, P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var Dynamometer = /** @class */ (function () {
    function Dynamometer() {
    }
    Dynamometer.prototype.render = function (appearance) {
        // Origin = beginning of the plunger at 0.
        var casing = new Path.Rectangle(R(0, -15, 30, 150, Pivot.TOP_CENTER), 6.0);
        var backing = casing.clone()
            .withFill("#555555");
        var window = new Path.Rectangle(R(0, 0, 8, 120, Pivot.TOP_CENTER), 3.0);
        var casingWithWindow = casing.subtract(window);
        casingWithWindow
            .withFill("#dddddd")
            .withStroke("default", SharedColors.stroke);
        casing.remove();
        window.remove();
        var hookLength = 10;
        var hook = new Path(Segments([[0, 0]], [[0, -hookLength], , [10, -5]], [[0, -hookLength - 20], [10, 0], [-10, 0]], [[-10, -hookLength - 10]])).withStroke("default");
        hook.rotate(180, P(0, 0));
        hook.position.y = 145;
        var plunger = new Path.Rectangle(R(0, 0, 10, 135, Pivot.TOP_CENTER), 2.0)
            .withFill(SharedColors.lightMetal);
        var slidable = new Group([hook, plunger]);
        slidable.position.y += interpolate(appearance.value, [0, 5], [0, 120]) + 0.5;
        var graphic = new Group([backing, slidable, casingWithWindow]);
        addMarkers2(graphic, {
            start: P(-3, 0),
            step: P(0, 120 / 20),
            count: 21,
            majorEvery: 4
        });
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            pivot: casingWithWindow.bounds.center,
        };
    };
    Dynamometer.properties = {
        label: "Dynamometer",
        flippable: true,
        defaultAppearance: {
            value: 0,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "value", label: "Measurement",
                min: 0, max: 5, step: 0.01, unit: "N"
            }
        ],
    };
    Dynamometer = __decorate([
        staticImplements()
    ], Dynamometer);
    return Dynamometer;
}());
export { Dynamometer };
