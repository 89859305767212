import { t } from "i18next";
export function currencySymbol(currency) {
    switch (currency) {
        case "gbp": return "£";
        case "eur": return "€";
        case "usd": return "$";
        case "aud": return "A$";
        case "sgd": return "S$";
        case "mxn": return "Mex$";
        case "cop": return "$";
        case "cad": return "$";
        case "myr": return "RM";
        case "inr": return "₹";
        case "nzd": return "NZ$";
    }
}
export function currencyLabel(currency) {
    return t('currency.' + currency);
}
/**
 * Converts an ISO Country code to the currency they use.
 * https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
 * Or http://country.io/names.json
 */
export function countryCodeToCurrency(country) {
    switch (country) {
        case "GB":
            return "gbp";
        case "AT":
        case "BE":
        case "CY":
        case "EE":
        case "FI":
        case "FR":
        case "DE":
        case "GR":
        case "IE":
        case "IT":
        case "LV":
        case "LT":
        case "LU":
        case "MT":
        case "NL":
        case "PT":
        case "SK":
        case "SI":
        case "ES":
            return "eur";
        case "AU":
            return "aud";
        case "SG":
            return "sgd";
        case "MX":
            return "mxn";
        case "CO":
            return "cop";
        case "CA":
            return "cad";
        case "MY":
            return "myr";
        case "IN":
            return "inr";
        case "NZ":
            return "nzd";
        default:
            return "usd";
    }
}
export function currencyBasedRounding(currency) {
    switch (currency) {
        case "mxn": return 0;
        case "cop": return -2;
        default: return 2;
    }
}
