// Driver is a feature that allows on-screen elements to drive
// changes to appearance. A simple example is a draggable circle
// that can be dragged to change the width and height of a rectangle.
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
    Notes on Snapping

    Snapping is only supported on select few snap types. Snapping ignores
    bounds so best to use it on driver types that have INFINITE_BOUNDS.
*/
import { g } from "analytics";
import { clamp, P } from "apparatus/library/common";
import { UpdateAppearance } from "command/basic";
import { Sequence } from "command/command";
import { createInteractionHandleFor } from "editing/handle";
import { Adjustable } from "editing/interaction";
import { transform } from "editing/snapping/type";
import { Key } from "paper";
export var INFINITE_BOUNDS = { minX: -10000, maxX: 10000, minY: -10000, maxY: 10000 };
function restrainRectangleDriver(position, driver) {
    var x = Math.round(clamp(position.x, driver.minX, driver.maxX));
    var y = Math.round(clamp(position.y, driver.minY, driver.maxY));
    return P(x, y);
}
/** Computes the delta in the component's coordinate system, from a screen-space delta. */
function adjustDriverDelta(rawDelta, driver, component) {
    var rotation = -component.currentRotation;
    var flipped = component.props.flipped;
    return rawDelta.rotate(rotation).multiply([flipped ? -1.0 : 1.0, 1.0]).multiply(toPoint(driver.scale));
}
/** Converts a number or Point to a Point */
function toPoint(i) {
    return typeof (i) === "number" ? P(i, i) : i;
}
/** Applies the constraint, if present. */
function maybeApplyConstraint(position, driver, component) {
    if (!driver.constraints)
        return position;
    // Workaround for return type widening.
    var partialAppearance = {};
    driver.toAppearance(function (a) { partialAppearance = a; }, position, component.props.appearance);
    var maybeNewAppearance = driver.constraints(__assign(__assign({}, component.props.appearance), partialAppearance), Key.modifiers);
    if (!maybeNewAppearance)
        return position;
    return driver.fromAppearance(maybeNewAppearance);
}
export function createRectangleDriver(driver, component) {
    var currentPosition = driver.fromAppearance(component.props.appearance);
    var handle;
    handle = createInteractionHandleFor(Adjustable({
        onStart: function () {
            if (!driver.snapping)
                return {};
            // Driver snapping has to be evaluated every time in case
            // component appearance has changed.
            var snapping = driver.snapping(component.props.appearance, currentPosition);
            if (!snapping)
                return {};
            return { snappableComponent: new AdjustableSnappableComponent(component, snapping) };
        },
        setDelta: function (delta) {
            // Compute new position and then apply the appearance changes.
            delta = adjustDriverDelta(delta, driver, component);
            var restrainedPosition = restrainRectangleDriver(currentPosition.add(delta), driver);
            var finalPosition = maybeApplyConstraint(restrainedPosition, driver, component);
            handle.position = finalPosition;
            driver.toAppearance(function (a) { return component.updateMultipleAppearanceAttributes(a); }, finalPosition, component.props.appearance);
        },
        onEnd: function (delta) {
            g("UpdateAppearance", component.apparatusType + "::{driver}");
            delta = adjustDriverDelta(delta, driver, component);
            var restrainedPosition = restrainRectangleDriver(currentPosition.add(delta), driver);
            var finalPosition = maybeApplyConstraint(restrainedPosition, driver, component);
            var newAppearance = {};
            driver.toAppearance(function (a) { newAppearance = a; }, finalPosition, component.props.appearance);
            var commands = [];
            for (var key in newAppearance) {
                commands.push(new UpdateAppearance(component.id, key, newAppearance[key], component.props.appearance[key]));
            }
            return new Sequence(commands);
        },
    }));
    return {
        handle: handle,
        onPropsChanged: function (newAppearance) {
            currentPosition = driver.fromAppearance(newAppearance);
            handle.position = currentPosition;
            handle.visible = driver.visible ? driver.visible(newAppearance) : true;
        },
        onStateChanged: function (newAppearance) {
            handle.position = driver.fromAppearance(newAppearance);
        }
    };
}
/** Implements the SnappableComponent for drivers. */
var AdjustableSnappableComponent = /** @class */ (function () {
    function AdjustableSnappableComponent(component, snap) {
        this.component = component;
        this.globalSnapping = [
            transform(snap, component.props.flipped, function (p) { return component.localToGlobal(p); })
        ];
    }
    Object.defineProperty(AdjustableSnappableComponent.prototype, "rotation", {
        // Proxy remaining methods.
        get: function () { return this.component.rotation; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AdjustableSnappableComponent.prototype, "id", {
        get: function () { return this.component.id; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AdjustableSnappableComponent.prototype, "isSelected", {
        get: function () { return this.component.isSelected; },
        enumerable: false,
        configurable: true
    });
    AdjustableSnappableComponent.prototype.setSnapVisualisation = function (filter) {
        this.component.setSnapVisualisation(filter);
    };
    return AdjustableSnappableComponent;
}());
