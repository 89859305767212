import { P, Segments } from "apparatus/library/common";
import { DEFAULT_SWATCHES } from "components/colorpicker";
import { Group, Path } from "paper";
import { createCompoundPath } from "../../../paper_ext";
var crystalOutlineStrokeWidth = "thinner";
var crystalInlineStrokeWidth = 1.0;
var Crystals = /** @class */ (function () {
    function Crystals() {
    }
    Crystals.prototype.render = function (appearance) {
        var color = appearance.color;
        var count = appearance.count;
        // The appearance colour is used for the stroke.
        // Figure out the fill colour by slightly lightening it.
        var colorLightness = color.color().lightness();
        // Lightness runs from 0 (dark) to 100 (light). Compute
        // the new lightness as midway between current lightness and max light.
        var newLightness = (100 + colorLightness) / 2;
        var fillColor = color.color().lightness(newLightness).hex();
        var crystal = createCrystal(appearance.type, color, fillColor);
        var graphic = new Group([crystal]);
        for (var i = 1; i < count; i++) {
            var _a = OFFSETS[i - 1], offset = _a[0], rotation = _a[1];
            var copy = crystal.clone();
            copy.position = copy.position.add(offset);
            copy.rotation = rotation;
            graphic.addChild(copy);
        }
        graphic.scale(appearance.scale);
        return { graphic: graphic, hitShape: graphic.bounds.expand(15).toShape() };
    };
    Crystals.properties = {
        label: "Crystals",
        defaultAppearance: {
            type: "octahedron",
            count: 1,
            color: "#6ca6ba",
            scale: 1.5,
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "type", label: "Type / Shape",
                options: [
                    { value: "octahedron", label: "Octahedral" },
                    { value: "cubic", label: "Cubic", tier: "boost" },
                    { value: "rhombic", label: "Rhombic", tier: "boost" },
                ],
                allowSelectingTierLockedOptions: true,
            },
            {
                spec: "slider", key: "count", label: "Number of crystals",
                min: 1, max: 6,
            },
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 1.0, max: 3.0, step: 0.1, unit: "%"
            },
            {
                spec: "color", key: "color", label: "Colour",
                swatches: DEFAULT_SWATCHES,
            }
        ],
        isBoostRequired: function (a) { return a.type == "cubic" || a.type == "rhombic"; }
    };
    return Crystals;
}());
export { Crystals };
function createCrystal(type, strokeColor, fillColor) {
    switch (type) {
        case "octahedron":
            return createOctagonalCrystal(strokeColor, fillColor);
        case "cubic":
            return createCubicCrystal(strokeColor, fillColor);
        case "rhombic":
            return createRhombicCrystal(strokeColor, fillColor);
    }
}
function createOctagonalCrystal(strokeColor, fillColor) {
    var outline = new Path(Segments([[0, 0]], [[6, 8]], [[0, 16]], [[-6, 8]]));
    outline.closePath();
    var fill = outline.clone();
    fill.fillColor = fillColor;
    outline.withStroke(crystalOutlineStrokeWidth, strokeColor);
    var centerPoint = P(2, 10);
    var crystalInsideHorizontal = new Path(Segments([[-6, 8]], [centerPoint], [[6, 8]]));
    crystalInsideHorizontal.strokeWidth = crystalInlineStrokeWidth;
    crystalInsideHorizontal.strokeColor = strokeColor;
    var crystalInsideVertical = new Path(Segments([[0, 0]], [centerPoint], [[0, 16]]));
    crystalInsideVertical.strokeWidth = crystalInlineStrokeWidth;
    crystalInsideVertical.strokeColor = strokeColor;
    // Lighter shade the top left face.
    var shade1 = new Path(Segments([[0, 0]], [[-6, 8]], [centerPoint]));
    shade1.fillColor = fillColor.color().lighten(0.1).hex();
    // Darker shade the bottom right face.
    var shade2 = new Path(Segments([[6, 8]], [[0, 16]], [centerPoint]));
    shade2.fillColor = fillColor.color().darken(0.1).hex();
    var crystal = new Group([fill, shade1, shade2, crystalInsideHorizontal, crystalInsideVertical, outline]);
    return crystal;
}
function createRhombicCrystal(strokeColor, fillColor) {
    // Draw a skewed hexagonal shape, start with topCorner, go clockwise
    var outline = new Path(Segments([[0, 0]], [[6, 8]], [[6, 12]], [[0, 20]], [[-6, 12]], [[-6, 8]]));
    outline.closePath();
    var fill = outline.clone();
    fill.fillColor = fillColor;
    outline.withStroke(crystalOutlineStrokeWidth, strokeColor);
    var inlines = createCompoundPath(["moveTo", -6, 12], ["lineTo", 0, 4], /* center point */ ["lineTo", 6, 12], ["moveTo", 0, 4], ["lineTo", 0, 0]);
    inlines.strokeWidth = crystalInlineStrokeWidth;
    inlines.strokeColor = strokeColor;
    var shade1 = new Path(Segments([[0, 4]], [[6, 12]], [[6, 8]], [[0, 0]]));
    shade1.fillColor = fillColor.color().darken(0.1).hex();
    var shade2 = new Path(Segments([[0, 4]], [[-6, 12]], [[-6, 8]], [[0, 0]]));
    shade2.fillColor = fillColor.color().lighten(0.1).hex();
    var crystal = new Group([fill, shade1, shade2, inlines, outline]);
    return crystal;
}
function createCubicCrystal(strokeColor, fillColor) {
    // Draw a hexagonal shape for outline, start with top corner, go clockwise.
    var outline = new Path(Segments([[0, 0]], [[10, 6]], [[10, 16]], [[0, 22]], [[-10, 16]], [[-10, 6]]));
    outline.closePath();
    var fill = outline.clone();
    fill.fillColor = fillColor;
    outline.withStroke(crystalOutlineStrokeWidth, strokeColor);
    var inlines = createCompoundPath(["moveTo", -10, 6], ["lineTo", 0, 12], /* center */ ["lineTo", 0, 22], ["moveTo", 0, 12], ["lineTo", 10, 6]);
    inlines.strokeWidth = crystalInlineStrokeWidth;
    inlines.strokeColor = strokeColor;
    var shade1 = new Path(Segments([[-10, 6]], [[0, 0]], [[10, 6]], [[0, 12]]));
    shade1.fillColor = fillColor.color().lighten(0.1).hex();
    var shade2 = new Path(Segments([[10, 6]], [[10, 16]], [[0, 22]], [[0, 12]]));
    shade2.fillColor = fillColor.color().darken(0.1).hex();
    var crystal = new Group([fill, shade1, shade2, inlines, outline]);
    crystal.scale(0.8);
    return crystal;
}
// Position offsets and rotations for subsequent crystals
var OFFSETS = [
    [P(10, 7), 62],
    [P(-6, 10), -28],
    [P(9, -6), 208],
    [P(11, 13), -43],
    [P(17, -2), 82],
];
