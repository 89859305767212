import Color, * as color from "color";
String.prototype.color = function () {
    // Have to cast to any, since casting to color & Color does not work.
    return color(this);
};
Color.prototype.ladd = function (delta) {
    return this.l(this.l() + delta);
};
Color.prototype.sadd = function (delta) {
    return this.saturationl(this.saturationl() + delta);
};
