import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path } from "paper";
import { createOutlinedGroup, setLiquidStyle } from "../../draw";
var Faucet = /** @class */ (function () {
    function Faucet() {
    }
    Faucet.prototype.render = function (appearance) {
        var body = new Path(Segments([[-70, 40]], [[-70, 25], , [0, -22]], [[-35, -10], [-22, 0]], [[40, -10]], [[40, 10]], [[-35, 10], , [-8, 0]], [[-50, 25], [0, -8]], [[-50, 40]])).close().withFill("#dddddd");
        var spoutPosition = P(-60, 40);
        var bulge = new Path.Rectangle(R(0, 0, 35, 36, Pivot.CENTER), 2.0).withFill("#cccccc");
        var handleAxle = new Path.Rectangle(R(0, -44, 6, 30, Pivot.TOP_CENTER), 3.0).withFill("#999999");
        var handle = new Path.Rectangle(R(0, -36, 50, 8, Pivot.CENTER), 2.0).withFill("#cccccc");
        var graphic = new Group([
            createOutlinedGroup("default", SharedColors.stroke, body, bulge),
            createOutlinedGroup("default", SharedColors.stroke, handleAxle, handle),
        ]);
        if (appearance.showStream) {
            var streamWidth = appearance.streamStrength, streamLength = appearance.streamLength, bend = appearance.streamBend;
            var water = new Path(Segments([[streamWidth / 2 + streamLength * bend, streamLength]], [[streamWidth / 2, 0], [0, streamLength / 2]], [[-streamWidth / 2, 0], , [0, streamLength / 2]], [[-streamWidth / 2 + streamLength * bend, streamLength]]));
            water.position.x += spoutPosition.x;
            water.position.y += spoutPosition.y;
            setLiquidStyle(water, SharedColors.defaultLiquid);
            graphic.insertChild(0, water);
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: P(0, 0)
        };
    };
    Faucet.properties = {
        label: "Tap / Faucet",
        flippable: true,
        defaultAppearance: {
            showStream: true,
            streamLength: 120,
            streamStrength: 8,
            streamBend: 0.0,
        },
        appearanceSpecs: [
            { spec: "checkbox", key: "showStream", label: "Water flow", subLabel: "Show" },
            {
                spec: "slider", key: "streamStrength", label: "Flow Strength",
                min: 3, max: 18, visible: function (a) { return a.showStream; }
            },
            {
                spec: "slider", key: "streamLength", label: "Flow Size",
                min: 10, max: 300, visible: function (a) { return a.showStream; }
            },
            {
                spec: "slider", key: "streamBend", label: "Flow Bend",
                min: -0.5, max: 0.5, step: 0.05, visible: function (a) { return a.showStream; }
            }
        ],
    };
    return Faucet;
}());
export { Faucet };
