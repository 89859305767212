export var Toast;
(function (Toast) {
    function info(message, title, overrides) {
        return toastr.info(message, title !== null && title !== void 0 ? title : undefined, overrides);
    }
    Toast.info = info;
    function warning(message, title, overrides) {
        return toastr.warning(message, title !== null && title !== void 0 ? title : undefined, overrides);
    }
    Toast.warning = warning;
    function success(message, title, overrides) {
        return toastr.success(message, title !== null && title !== void 0 ? title : undefined, overrides);
    }
    Toast.success = success;
    function error(message, title, overrides) {
        return toastr.error(message, title !== null && title !== void 0 ? title : undefined, overrides);
    }
    Toast.error = error;
})(Toast || (Toast = {}));
