import { setDefaultStyle } from "apparatus/draw";
import { P } from "apparatus/library/common";
import { CompoundPath } from "paper";
var Beehive = /** @class */ (function () {
    function Beehive() {
    }
    Beehive.prototype.render = function (appearance) {
        var height = appearance.height;
        var graphic = new CompoundPath({});
        graphic.moveTo(P(0, height));
        graphic.lineBy(P(3, -height / 3));
        graphic.moveBy(P(3, -height / 3));
        graphic.lineBy(P(3, -height / 3));
        graphic.lineBy(P(25, 0));
        graphic.moveBy(P(25, 0));
        graphic.lineBy(P(25, 0));
        graphic.lineBy(P(9, height));
        setDefaultStyle(graphic);
        var hitShape = graphic.bounds.toShape();
        return {
            graphic: graphic,
            hitShape: hitShape,
            snapping: [
                graphic.placeable(),
                graphic.topEdgeAsPlaceableSurface(),
            ],
            watermark: P(85, 0)
        };
    };
    Beehive.properties = {
        label: "Beehive stand",
        flippable: true,
        defaultAppearance: {
            height: 40
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "height", label: "Height",
                min: 30, max: 50
            }
        ],
    };
    return Beehive;
}());
export { Beehive };
