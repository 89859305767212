var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var FONT_FAMILY_OPTIONS = [
    { value: "sans-serif", label: "Default" },
    { value: "Arial", label: "Arial" },
    { value: "Calibri", label: "Calibri" },
    { value: "Comic Sans MS", label: "Comic Sans" },
    { value: "Courier New", label: "Courier New" },
    { value: "Georgia", label: "Georgia" },
    { value: "Rubik", label: "Rubik" },
    { value: "Times New Roman", label: "Times New Roman" },
    { value: "Verdana", label: "Verdana" },
];
export var TEXT_COLOUR_SWATCHES = {
    "Black": "#000000",
    "Red": "#FF3B2D",
    "Blue": "#4353EB",
    "Green": "#43882E",
};
export var DEMO_COLOUR_SWATCHES = __assign({}, TEXT_COLOUR_SWATCHES);
