var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CommonModal } from "components/common_modal";
import * as React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Bcn } from "support/beacon";
import { TierContext } from "tiers/context";
import { runTutorial } from "tutorial";
import { CtrlKey, FAKey, Key } from "view/keyboard_shortucts";
import { g } from "../../analytics";
export var HelpModal2 = function (modalState) {
    var close = function () { modalState.setIsOpen(false); };
    var t = useTranslation().t;
    return React.createElement(CommonModal, __assign({}, modalState, { title: t("menu.help.title"), extraClass: "help-modal", icon: "fa-question-circle", iconSwapOpacity: true }),
        React.createElement("div", { className: "row text-left mb-4" },
            React.createElement(HelpItemWrapper, null,
                React.createElement("button", { onClick: function () { runTutorial(); close(); }, 
                    // data-remodal-action="confirm"
                    className: "ui-button" },
                    React.createElement("i", { className: "fas fa-fw fa-lg fa-wand-magic-sparkles mr-2" }),
                    t("help.quick_tutorial"))),
            React.createElement(HelpItemWrapper, null,
                React.createElement("a", { href: "https://help.chemix.org", onClick: function () { return g('HelpDlg:HelpCenter'); }, className: "ui-button", target: "help" },
                    React.createElement("i", { className: "fas fa-fw fa-lg fa-book mr-2" }),
                    t('help.help_center'))),
            React.createElement(HelpItemWrapper, null,
                React.createElement("a", { href: "https://help.chemix.org/faq", onClick: function () { return g('HelpDlg:FAQ'); }, className: "ui-button", target: "help" },
                    React.createElement("i", { className: "fas fa-fw fa-lg fa-question mr-2" }),
                    t('help.faq'))),
            React.createElement(TierContext.Consumer, null, function (tiers) {
                return React.createElement(HelpItemWrapper, null,
                    React.createElement("button", { onClick: function () { tiers.popup("Help"); close(); }, className: "ui-button w-100" },
                        React.createElement("i", { className: "fas fa-fw fa-lg fa-bolt-lightning mr-2" }),
                        t('Upgrade')));
            }),
            React.createElement(HelpItemWrapper, null,
                React.createElement("a", { href: "https://chemix.kampsite.co/", onClick: function () { return g('HelpDlg:FAQ'); }, className: "ui-button", target: "_blank" },
                    React.createElement("i", { className: "fas fa-fw fa-lg fa-comment-plus mr-2" }),
                    t('help.suggest'))),
            React.createElement(HelpItemWrapper, null,
                React.createElement("button", { "data-remodal-action": "confirm", onClick: function () { g('HelpDlg:OpenChat'); Bcn.ask(); close(); }, className: "ui-button w-100" },
                    React.createElement("i", { className: "fas fa-fw fa-lg fa-exclamation-triangle mr-2" }),
                    t('help.report')))),
        React.createElement("h3", null, t('Keyboard shortcuts')),
        React.createElement(KeyboardShortcuts, null));
};
export var KeyboardShortcuts = function () {
    var t = useTranslation().t;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row text-left" },
            React.createElement("div", { className: "col-12 col-md-6" },
                React.createElement("table", { className: "table text-light table-sm table-borderless" },
                    React.createElement("tbody", null,
                        React.createElement(Heading, null, t('Tools')),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "text-right" },
                                React.createElement(Key, { k: "1" }),
                                " or ",
                                React.createElement(Key, { k: "V" })),
                            React.createElement("td", null, t('tool.select'))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "text-right" },
                                React.createElement(Key, { k: "2" }),
                                " or ",
                                React.createElement(Key, { k: "P" })),
                            React.createElement("td", null, t('tool.pan'))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "text-right" },
                                React.createElement(Key, { k: "3" }),
                                " or ",
                                React.createElement(Key, { k: "T" })),
                            React.createElement("td", null, t('tool.arrow'))),
                        React.createElement(Heading, null, t('Actions')),
                        React.createElement(Shortcut, { ctrl: true, k: "F" }, t("search.apparatus")),
                        React.createElement(Shortcut, { ctrl: true, k: "E" }, t("menu.download.title")),
                        React.createElement(Shortcut, { ctrl: true, k: "N" }, t("help.create_new")),
                        React.createElement(Heading, null, t('Editing')),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "text-right" },
                                React.createElement(CtrlKey, null),
                                " + ",
                                React.createElement(Key, { k: "Z" }),
                                React.createElement(Key, { k: "Y" })),
                            React.createElement("td", null,
                                t("Undo"),
                                " ",
                                React.createElement(MutedSlash, null),
                                " ",
                                t("Redo"))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "text-right" },
                                React.createElement(CtrlKey, null),
                                " + ",
                                React.createElement(Key, { k: "X" }),
                                React.createElement(Key, { k: "C" }),
                                React.createElement(Key, { k: "V" })),
                            React.createElement("td", null,
                                t("Cut"),
                                " ",
                                React.createElement(MutedSlash, null),
                                " ",
                                t("Copy"),
                                " ",
                                React.createElement(MutedSlash, null),
                                " ",
                                t("Paste"))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "text-right" },
                                React.createElement(CtrlKey, null),
                                " + ",
                                React.createElement(Shift, null),
                                " + ",
                                React.createElement(Key, { k: "V" })),
                            React.createElement("td", null, t("Paste in place")))))),
            React.createElement("div", { className: "col-12 col-md-6" },
                React.createElement("table", { className: "table text-light table-sm table-borderless" },
                    React.createElement("tbody", null,
                        React.createElement(Heading, null, t('Moving')),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "text-right" },
                                t('Hold'),
                                " ",
                                React.createElement(Shift, null)),
                            React.createElement("td", null, t("help.move_vh"))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "text-right" },
                                t('Hold'),
                                " ",
                                React.createElement(CtrlKey, null)),
                            React.createElement("td", null, t('help.move_disable_snap'))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "text-right" },
                                "\u00A0",
                                React.createElement(Arrows, null)),
                            React.createElement("td", null,
                                React.createElement(Trans, { i18nKey: "help.move_by_step", values: { size: t("medium") } },
                                    "Move items by a ",
                                    React.createElement("b", null, "medium"),
                                    " step"))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "text-right" },
                                React.createElement(NoWrap, null,
                                    React.createElement(Shift, null),
                                    " + "),
                                React.createElement(Arrows, null)),
                            React.createElement("td", null,
                                React.createElement(Trans, { i18nKey: "help.move_by_step", values: { size: t("large") } },
                                    "Move items by a ",
                                    React.createElement("b", null, "large"),
                                    " step"))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "text-right" },
                                React.createElement(NoWrap, null,
                                    React.createElement(CtrlKey, null),
                                    " + ",
                                    React.createElement(Shift, null),
                                    " + "),
                                React.createElement(Arrows, null)),
                            React.createElement("td", null,
                                React.createElement(Trans, { i18nKey: "help.move_by_step", values: { size: t("small") } },
                                    "Move items by a ",
                                    React.createElement("b", null, "small"),
                                    " step"))),
                        React.createElement(Heading, null, t('Rotating')),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "text-right" },
                                React.createElement(Rotate, null),
                                " + ",
                                t('Hold'),
                                " ",
                                React.createElement(Shift, null)),
                            React.createElement("td", null, t('help.rotate.increment', { degree: 22.5 }))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "text-right" },
                                React.createElement(Rotate, null),
                                " + ",
                                t('Hold'),
                                " ",
                                React.createElement(CtrlKey, null)),
                            React.createElement("td", null, t('help.rotate.increment', { degree: 1 }))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "text-right" },
                                React.createElement(Rotate, null),
                                " + ",
                                t('Hold'),
                                " ",
                                React.createElement(Key, { k: "Alt" })),
                            React.createElement("td", null, t('help.rotate.free'))))))));
};
var HelpItemWrapper = function (props) { return React.createElement("div", { className: "col-12 col-sm-6 col-md-4 mb-2" }, props.children); };
var Rotate = function () { return React.createElement("i", { className: "fa fa-sync-alt" }); };
var Shift = function () { return React.createElement("span", { className: "keyboardKey" },
    React.createElement("i", { className: "far fa-arrow-alt-up" })); };
var Arrows = function () { return (React.createElement(React.Fragment, null,
    React.createElement(NoWrap, null,
        React.createElement(FAKey, { icon: "s fa-arrow-left" }),
        React.createElement(FAKey, { icon: "s fa-arrow-up" }),
        React.createElement(FAKey, { icon: "s fa-arrow-right" }),
        React.createElement(FAKey, { icon: "s fa-arrow-down" })))); };
export var Shortcut = function (props) { return (React.createElement("tr", null,
    React.createElement("td", { className: "text-right" },
        React.createElement(Key, { k: props.k, ctrl: props.ctrl })),
    React.createElement("td", null,
        props.children,
        " "))); };
var NoWrap = function (_a) {
    var children = _a.children;
    return (React.createElement("span", { style: { whiteSpace: "nowrap" } }, children));
};
var Heading = function (_a) {
    var children = _a.children;
    return (React.createElement("tr", null,
        React.createElement("td", null),
        React.createElement("td", { className: "pt-3 pb-0" },
            React.createElement("b", { className: "text-muted" }, children))));
};
var MutedSlash = function () { return React.createElement("span", { className: "text-muted" }, "/"); };
