var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { setDefaultStyle, smoothCornersIndividual } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { interpolate, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
import { staticImplements } from "types";
var DEFAULT_CAP_COLOR = "#6574e5";
/**
 * Comes in X sizes:
 *  - 2mL, 11.6mm outer diameter, 32mm height
 *  - 4mL, 14.7mm outer diameter, 45mm height, 13mm diameter cap
 *  - 10mL, 20mm outer diameter, 46mm height, 18mm diameter cap,
 *  - 20mL, 20mm outer diameter, 75mm height, 18mm diameter cap
 *  -
 *
 * Colours:
 *  - Amber (darker glass)
 *  - Clear
 *
 * Cap types:
 *  - Crimp (Tends to be shorter, larger diameter)
 *  - Screw
 *  - Snap
 *
 * References:
 *  - https://www.thermofisher.com/uk/en/home/industrial/chromatography/chromatography-consumables/autosampler-vials-caps-hplc-gc.html
 *  - https://www.fishersci.co.uk/shop/products/11mm-snap-ring-glass-vial-wide-opening-clear-5/p-7113935#?keyword=
 *  - 10mL Crimp vs Screw:
 *      - https://www.fishersci.co.uk/shop/products/surestart-10-ml-glass-crimp-top-headspace-vials-level-2-high-throughput-applications/p-7237204#?keyword=autosampler%20vial%2010mL
 *      - https://www.fishersci.co.uk/shop/products/surestart-10-ml-glass-screw-top-headspace-vials-level-2-high-throughput-applications/p-7237207#?keyword=autosampler%20vial%2010mL
 *
 *  - https://www.agilent.com/en/product/vials-sample-containment/vials-caps-inserts-septa/autosampler-vial-compatiblity
 */
var AutosamplerVial = /** @class */ (function () {
    function AutosamplerVial() {
    }
    AutosamplerVial.prototype.render = function (appearance) {
        var _a = getDimensions(appearance), diameter = _a.diameter, neckDiameter = _a.neckDiameter, height = _a.height;
        var neckHeight = height * 0.12;
        var diameterDiff = diameter - neckDiameter;
        var bodyStartY = neckHeight + diameterDiff * 1.3;
        var bottle = new Path(Segments([[neckDiameter / 2, 0]], [[neckDiameter / 2, neckHeight], , [0, diameterDiff * 1.0]], [[diameter / 2, bodyStartY], [0, -diameterDiff * 1.0]], [[diameter / 2, height]])).mirrorX();
        // Smooth the bottom corners
        var cornerSmooth = Math.min(8, diameter * 0.2);
        smoothCornersIndividual(bottle, { 3: cornerSmooth, 4: cornerSmooth });
        var liquidMask = bottle.clone();
        setDefaultStyle(bottle);
        var graphic = new Group([bottle]);
        if (appearance.cap === "screw") {
            var capColor = appearance.capColor.color();
            var cap = Shape.Rectangle(R(0, -2, diameter, interpolate(diameter, [12, 20], [8, 12]), Pivot.CENTER), 2.0)
                .withGradientFill("right", Gradients.cylinder(capColor))
                .withStroke("thin", capColor.ladd(-10).hex());
            graphic.addChild(cap);
        }
        // addMarkers(graphic, P(diameter / 2 - 8, height), bodyStartY, P(0, -10))
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.rectHitShape(),
            pivot: bottle.bounds.center,
            snapping: bottle.placeable(),
            // liquidMaskOpening: appearance.cap
            //     ? undefined
            //     : { start: P(-neckDiameter / 2, 0), end: P(neckDiameter / 2, 0) }
        };
    };
    AutosamplerVial.properties = {
        label: "Autosampler Vial",
        defaultAppearance: {
            size: "2",
            cap: "screw",
            capColor: DEFAULT_CAP_COLOR,
        },
        canContainLiquids: true,
        pourable: true,
        appearanceSpecs: [
            {
                spec: "toggle-buttons", size: "text", label: "Size (mL)", key: "size",
                buttonsPerRow: 4,
                options: [
                    { value: "2", label: "2" },
                    { value: "4", label: "4" },
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                ]
            },
            {
                spec: "toggle-buttons", size: "text", label: "Cap", key: "cap",
                options: [
                    { value: "off", label: "Off" },
                    { value: "screw", label: "On" },
                ]
            },
            {
                spec: "color-wells", key: "capColor", label: "Colour",
                colors: [
                    { value: DEFAULT_CAP_COLOR, label: "Blue" },
                    { value: "#333333", label: "Black" },
                    { value: "#f1df47", label: "Yellow", tier: "boost" },
                    { value: "#d72213", label: "Red", tier: "boost" },
                    { value: "#dddddd", label: "Metal", tier: "boost" },
                ],
                visible: function (a) { return a.cap !== "off"; },
            },
        ],
    };
    AutosamplerVial = __decorate([
        staticImplements()
    ], AutosamplerVial);
    return AutosamplerVial;
}());
export { AutosamplerVial };
function getDimensions(appearance) {
    switch (appearance.size) {
        case "2": return { diameter: 12, neckDiameter: 8, height: 32 }; // Neck diameter is technically 10 but making it smaller for better look
        case "4": return { diameter: 15, neckDiameter: 11, height: 45 }; // Real life Neck diameter is 12
        case "10": return { diameter: 20, neckDiameter: 14, height: 46 }; // Real life neck diameter is 18
        case "20": return { diameter: 20, neckDiameter: 14, height: 75 }; // Real life neck diameter is 18
    }
}
