import { strokeWidth } from "apparatus/draw";
import { Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
var CottonWool = /** @class */ (function () {
    function CottonWool() {
    }
    CottonWool.prototype.render = function (appearance) {
        var path = new Path(Segments([[-15, -20], [-10, -1], [7, -9]], [[11, -23], [0, -5], [10, 4]], [[21, 3], [6, -7], [4, 11]], [[8, 28], [20, 3], [-13, 8]], [[-17, 7], [-1, 14], [-13, -5]]));
        path.closePath();
        var stroke = appearance.wet ? "#8596ad" : "#aaaaaa";
        var outside = appearance.wet ? "#b1c2d8" : "#eeeeee";
        var inside = appearance.wet ? "#dde2e9" : "#ffffff";
        var outline = path.clone();
        outline.withStroke(strokeWidth("thin"), stroke);
        path.setRadialGradientFill([[inside, 0.6], [outside, 1.0]]);
        path.scale(appearance.scale / 2);
        outline.scale(appearance.scale / 2);
        var graphic = new Group([path, outline]);
        path.applyMatrix = false;
        graphic.scale(appearance.squish, 1.0);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    CottonWool.properties = {
        label: "Cotton Wool",
        defaultAppearance: {
            scale: 1.0,
            squish: 1.0,
            wet: false
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.5, max: 2.0, step: 0.01, unit: "%"
            },
            {
                spec: "slider", key: "squish", label: "Squish",
                min: 0.4, max: 1.0, step: 0.01
            },
            {
                spec: "checkbox", key: "wet", label: "Wet"
            }
        ],
    };
    return CottonWool;
}());
export { CottonWool };
