// The App's possible actions to modify the store.
import { createAction, createStandardAction } from "typesafe-actions";
// Following tutorials:
//  - https://github.com/piotrwitek/typesafe-actions#behold-the-mighty-tutorial
//  - https://github.com/piotrwitek/react-redux-typescript-guide
// Actions
// 1. Define an action here.
// 2. Define how it's resolved in terms of modifying the store, in reducer
export var addItems = createAction("chemix/ADD_ITEM", function (resolve) {
    return function (data) { return resolve(data); };
});
export var addItemsAtIndices = createAction("chemix/ADD_ITEMS_AT_INDEX", function (resolve) {
    return function (data) { return resolve(data); };
});
/** Updates the appearance by modifying a single property. */
export var updateAppearance = createAction("chemix/UPDATE_APPEARANCE", function (resolve) {
    return function (id, key, value) { return resolve({ id: id, key: key, value: value }); };
});
/** Updates appearance by modifying a single property of multiple items of the same type. */
export var updateItemsAppearance = createAction("chemix/UPDATE_APPEARANCE_MULTI_ITEMS", function (resolve) {
    return function (key, values) {
        var idsToValuesMap = {};
        for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
            var value = values_1[_i];
            idsToValuesMap[value.id.id] = value.value;
        }
        return resolve({ key: key, idsToValuesMap: idsToValuesMap });
    };
});
/**
 * Updates the appearance by modifying multiple properties of a single item. Works on Apparatus only.
 */
export var updateAppearanceProperties = createAction("chemix/UPDATE_APPEARANCE_PROPS", function (resolve) {
    return function (id, props) { return resolve({ id: id, props: props }); };
});
/** Updates the appearance of multiple properties of apparatus of the same type. Works on apparatus only. */
export var updateItemsAppearanceProperties = createAction("chemix/UPDATE_APPEARANCE_PROPS_MULTI_ITEMS", function (resolve) {
    return function (ids, props) { return resolve({ ids: ids, props: props }); };
});
export var removeItems = createAction("chemix/REMOVE_ITEMS", function (resolve) {
    return function (ids) { return resolve({ ids: ids }); };
});
export var removeFromGroup = createAction("chemix/REMOVE_FROM_GROUP", function (resolve) {
    return function (data) { return resolve(data); };
});
export var addToGroup = createAction("chemix/ADD_TO_GROUP", function (resolve) {
    return function (data) { return resolve(data); };
});
/**
 * Note this only rotates apparatus, not arrows. It takes in an unsorted
 * dictionary of apparatus ID numbers desired rotation values.
 */
export var setItemsRotation = createAction("chemix/SET_ITEMS_ROTATION", function (resolve) {
    return function (rotations) { return resolve({ rotations: rotations }); };
});
export var generic = createAction("chemix/GENERIC", function (resolve) {
    return function (name, actionFn) { return resolve({ name: name, actionFn: actionFn }); };
});
/** Switch the current active tool. */
export var switchTool = createAction("chemix/SWITCH_TOOL", function (resolve) {
    return function (tool) { return resolve(tool); };
});
/**
 * Erases items and arrows.
 * TODO: This is needed because refs stored in editor canvas
 * are based on ID only but not type. This means the same ID
 * could be reused for a different apparatus type, so the
 * refs are now pointing to old objects.
 */
export var eraseItems = createAction("chemix/CLEAR_ALL", function (resolve) {
    return function () { return resolve(); };
});
// File-related operations.
/** Replace current state with given document. */
export var replace = createAction("chemix/REPLACE", function (resolve) {
    return function (diagram, isTemplate) { return resolve({ diagram: diagram, isTemplate: isTemplate }); };
});
/** Sets cloud information about current diagram, or resets it to null. */
export var setCloudDiagram = createStandardAction("chemix/SET_CLOUD_DIAGRAM")();
/** Updates the current theme thickness. */
export var setThemeStrokeThickness = createStandardAction("chemix/SET_THEME_STROKE")();
// ChemMark config
export var updateChemMarkConfigStateOfMatterStyle = createStandardAction("chemix/UPDATE_CHEM_MARK_CONFIG")();
/** Resets state completely, should only be used for tests. */
export var _TESTONLY_resetState = createAction("chemix/TEST_ONLY_RESET_STATE", function (resolve) {
    return function (state) { return resolve(state); };
});
// Auth actions.
export var logIn = createAction("user/LOG_IN", function (resolve) {
    return function (data) { return resolve(data); };
});
export var logOut = createAction("user/LOG_OUT", function (resolve) {
    return function () { return resolve(); };
});
