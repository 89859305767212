var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { breakPath, setDefaultStyle } from "apparatus/draw";
import { DEFAULT_LIQUID_DATA, Segments } from "apparatus/library/common";
import { Path } from "paper";
import { mirrorX } from "../draw";
var BuchnerFunnel = /** @class */ (function () {
    function BuchnerFunnel() {
    }
    BuchnerFunnel.prototype.render = function (appearance) {
        var radius = appearance.size;
        var upperHeight = radius * 0.5 + 30;
        var taperHeight = 0.5 * radius;
        var stem = 40;
        var mouthRadius = 6;
        var shape = new Path(Segments([[-radius, -taperHeight - upperHeight]], [[-radius, -taperHeight]], [[0, 0]], [[0, stem]]));
        mirrorX(shape, mouthRadius);
        var hitShape = shape.clone();
        var liquidMask = shape.clone();
        var graphic = breakPath(shape, 4);
        // Add filter layer.
        var filterLayer = new Path(Segments([[-radius, -taperHeight - 2]], [[radius + mouthRadius * 2, -taperHeight - 2]]));
        filterLayer.strokeWidth = 3;
        filterLayer.strokeCap = 'butt';
        filterLayer.dashArray = [3, 6];
        graphic.addChild(filterLayer);
        setDefaultStyle(graphic);
        return {
            graphic: graphic, hitShape: hitShape, liquidMask: liquidMask,
            liquidDrainOpening: { start: liquidMask.segments[3].point, end: liquidMask.segments[4].point }
        };
    };
    BuchnerFunnel.properties = {
        label: "Buchner Funnel",
        canContainLiquids: true,
        drainable: true,
        defaultAppearance: {
            size: 40
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 30, max: 60,
            }
        ],
        defaultLiquidData: __assign(__assign({}, DEFAULT_LIQUID_DATA), { amountRatio: 0.7 })
    };
    return BuchnerFunnel;
}());
export { BuchnerFunnel };
