import { Group, Path, Point, Segment } from "paper";
import { addEdgeLipsReversed, addMarkers, breakPath, mirrorX, removeLastSegment, setDefaultStyle, smoothCorner } from "../draw";
import { markerSpacing, P, Segments } from "./common";
var ConicalFlask = /** @class */ (function () {
    function ConicalFlask() {
    }
    ConicalFlask.prototype.render = function (appearance) {
        var _a = baseFlaskGraphic(appearance), graphic = _a.graphic, markers = _a.markers, neckWidth = _a.neckWidth, neckHeight = _a.neckHeight;
        addEdgeLipsReversed(graphic);
        var liquidMask = graphic.clone();
        setDefaultStyle(graphic);
        var graphicWithMarkers = new Group([graphic, markers]);
        return {
            graphic: graphicWithMarkers,
            hitShape: graphic.bounds.toShape(),
            liquidMaskOpening: { start: P(-neckWidth / 2, -neckHeight), end: P(neckWidth / 2, -neckHeight) },
            liquidMask: liquidMask,
            snapping: [
                graphic.placeable(),
                { type: "neck", top: P(0, -neckHeight), diameter: neckWidth, facing: 0 },
            ]
        };
    };
    // Based on: https://www.amazon.co.uk/dp/B07CQNWN4C/ref=twister_B07CQ1ZX9L?_encoding=UTF8&th=1
    ConicalFlask.properties = {
        label: "Conical flask",
        canContainLiquids: true,
        meniscus: true,
        pourable: true,
        defaultAppearance: {
            size: 100,
        },
        appearanceSpecs: [{
                spec: "slider", key: "size", label: "Size",
                min: 80, max: 200,
            }]
    };
    return ConicalFlask;
}());
export { ConicalFlask };
var ConicalFlaskWithSideArm = /** @class */ (function () {
    function ConicalFlaskWithSideArm() {
    }
    ConicalFlaskWithSideArm.prototype.render = function (appearance) {
        var holeRadius = 5;
        var sideArmLength = 15;
        var offset = -17;
        var _a = baseFlaskGraphic(appearance, 35), graphic = _a.graphic, markers = _a.markers, neckWidth = _a.neckWidth, neckHeight = _a.neckHeight;
        // Insert the spout
        graphic.insertSegments(1, Segments([[neckWidth / 2, offset - holeRadius]], [[neckWidth / 2 + sideArmLength, offset - holeRadius]], [[neckWidth / 2 + sideArmLength, offset + holeRadius]], [[neckWidth / 2, offset + holeRadius]]));
        addEdgeLipsReversed(graphic);
        var liquidMask = graphic.clone();
        var parts = breakPath(graphic, 4);
        setDefaultStyle(parts);
        var graphicWithMarkers = new Group([parts, markers]);
        return {
            graphic: graphicWithMarkers,
            hitShape: graphic.bounds.toShape(),
            liquidMask: liquidMask,
            snapping: [
                graphic.placeable(),
                { type: "connectable", at: P(neckWidth / 2 + sideArmLength, offset), facing: 90 },
                { type: "neck", top: P(0, -neckHeight), diameter: neckWidth, facing: 0 },
            ]
        };
    };
    ConicalFlaskWithSideArm.properties = {
        label: "Conical flask with side arm",
        canContainLiquids: true,
        flippable: true,
        defaultAppearance: ConicalFlask.properties.defaultAppearance,
        appearanceSpecs: ConicalFlask.properties.appearanceSpecs,
    };
    return ConicalFlaskWithSideArm;
}());
export { ConicalFlaskWithSideArm };
function baseFlaskGraphic(appearance, minNeckHeight) {
    if (minNeckHeight === void 0) { minNeckHeight = 0; }
    var ratio = appearance.size / 150;
    var neckHeight = Math.max(minNeckHeight, 40 * ratio);
    var neckWidth = 20 + Math.sqrt(ratio) * 20;
    var baseHeight = 150 * ratio;
    var baseWidth = 145 * ratio;
    var corner = 10.0 + ratio * 20;
    var graphic = new Path();
    graphic.addSegments([
        // Neck.
        new Segment(new Point(neckWidth / 2, -neckHeight)),
        new Segment(new Point(neckWidth / 2, 0)),
        // Base
        new Segment(new Point(baseWidth / 2, baseHeight)),
        new Segment(new Point(0, baseHeight))
    ]);
    smoothCorner(graphic.segments[2], corner);
    removeLastSegment(graphic);
    mirrorX(graphic, 0);
    // Create markers.
    var markers = new Group();
    var bottomRightCorner = new Point((baseWidth - neckWidth) / 2 - 3.0, baseHeight);
    var scaleRatio = bottomRightCorner.y / markerSpacing;
    var markSpacingVector = bottomRightCorner.divide(scaleRatio);
    var markStart = bottomRightCorner.add([neckWidth / 2 - 7.0, 0]);
    addMarkers(markers, markStart, 0, markSpacingVector);
    return { graphic: graphic, markers: markers, neckWidth: neckWidth, neckHeight: neckHeight };
}
