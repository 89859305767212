var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { addMarkers, setDefaultStyle, smoothCornersIndividual } from "apparatus/draw";
import { clamp, P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Caps } from "apparatus/library/shared/cap";
import { Group, Path, Shape } from "paper";
import { staticImplements } from "types";
var GlassReagentBottle = /** @class */ (function () {
    function GlassReagentBottle() {
    }
    GlassReagentBottle.prototype.render = function (appearance) {
        var height = appearance.size;
        var diameter = Math.max(appearance.size * 0.4, 32);
        var neckDiameter = clamp(diameter * 0.42, 20, 40);
        var neckHeight = 15;
        var bodyStartY = neckHeight + (diameter - neckDiameter) * 0.5;
        var bottle = new Path(Segments([[neckDiameter / 2, 0]], [[neckDiameter / 2, neckHeight], , [0, 5]], [[diameter / 2, bodyStartY], [0, -5]], [[diameter / 2, height]])).mirrorX();
        smoothCornersIndividual(bottle, { 3: 8, 4: 8 });
        var liquidMask = bottle.clone();
        setDefaultStyle(bottle);
        var undercap = Shape.Rectangle(R(0, 12, neckDiameter + 4, 3, Pivot.CENTER), 2.0)
            .withFill("#ffffff80")
            .withStroke("thinner", SharedColors.stroke);
        var graphic = new Group([bottle, undercap]);
        if (appearance.cap) {
            var cap = Caps.createCap({ radius: neckDiameter / 2, appearance: appearance }).withPosition({ x: 0, y: 6 });
            graphic.addChild(cap);
        }
        addMarkers(graphic, P(diameter / 2 - 8, height), bodyStartY, P(0, -10));
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.rectHitShape(),
            pivot: bottle.bounds.center,
            snapping: bottle.placeable(),
            liquidMaskOpening: appearance.cap
                ? undefined
                : { start: P(-neckDiameter / 2, 0), end: P(neckDiameter / 2, 0) }
        };
    };
    GlassReagentBottle.properties = {
        label: "Glass Reagent Bottle",
        canContainLiquids: true,
        pourable: true,
        defaultAppearance: __assign({ size: 100, cap: true }, Caps.defaultAppearance),
        appearanceSpecs: __spreadArray([
            {
                spec: "slider", key: "size", label: "Size",
                min: 50, max: 200,
            },
            { spec: "checkbox", key: "cap", label: "Show cap" }
        ], Caps.specs),
    };
    GlassReagentBottle = __decorate([
        staticImplements()
    ], GlassReagentBottle);
    return GlassReagentBottle;
}());
export { GlassReagentBottle };
