var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { Gradients } from "apparatus/gradients";
import { P, Segments, SharedGradients } from "apparatus/library/common";
import { Group, Path } from "paper";
/**
 * http://unisciencelab.com/product/combustion-spoon-nuffield/
 * https://www.sciencelab.co.ke/products/deflagrating-spoon-with-lid
 */
var CombustionSpoon = /** @class */ (function () {
    function CombustionSpoon() {
    }
    CombustionSpoon.prototype.render = function (appearance) {
        var bend = appearance.bend, cover = appearance.cover, shaded = appearance.shaded, largeSpoon = appearance.largeSpoon;
        var spoon = new Path(largeSpoon
            ? Segments([[0, 380], [0, 0], [0, 16]], [[22, 380], [0, 16], [0, 0]])
            : Segments([[0, 380], [0, 0], [0, 3]], [[7, 380], [0, 3], [0, 0]])).withStroke("default");
        if (largeSpoon && shaded) {
            spoon.close().withGradientFill("right", SharedGradients.cylinderMetal);
        }
        var handle = new Path(Segments.apply(void 0, __spreadArray(__spreadArray([[[0, 0]]], (!bend ? [] :
            [[[0, 240], , [0, 10]],
                [[-12, 280], [0, -10]],
                [[-12, 370], , [0, 5]]])), [[[0, 380], [0, -5]]]))).withStroke("default");
        var graphic = new Group([spoon, handle]);
        if (cover) {
            graphic.addChild(new Path.Line(P(-35, 0), P(35, 0))
                .withStroke("thick")
                .setGradientStroke("right", Gradients.cylinder("#f7ca6c".color()))
                .withPosition(P(0, appearance.coverPosition)));
        }
        return {
            graphic: graphic,
            pivot: P(0, 200),
            hitShape: graphic.bounds.expand(10).toShape(),
            watermark: P(-5, 370)
        };
    };
    CombustionSpoon.properties = {
        label: "Combustion spoon",
        flippable: true,
        defaultAppearance: {
            bend: false,
            cover: false,
            shaded: true,
            largeSpoon: true,
            coverPosition: 200,
        },
        appearanceSpecs: [
            {
                spec: "checkbox-group", label: "Spoon", checkboxes: [
                    { key: "largeSpoon", label: "Large" },
                    { key: "shaded", label: "Shaded", visible: function (a) { return a.largeSpoon; } },
                ]
            },
            {
                spec: "checkbox-group", label: "Shaft", checkboxes: [
                    { key: "bend", label: "Show bend" },
                    { key: "cover", label: "Show cover" },
                ]
            },
            {
                spec: "slider", key: "coverPosition", label: "Cover position",
                min: 160, max: 230, visible: function (a) { return a.cover; }
            },
        ],
    };
    return CombustionSpoon;
}());
export { CombustionSpoon };
