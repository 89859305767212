import { Group, Path } from "paper";
import { mirrorX } from "../../draw";
import { P, Segments } from "../common";
var styrofoamGradient = [
    ["#999999", 0.0],
    ["#bbbbbb", 0.1],
    ["#bbbbbb", 0.9],
    ["#888888", 1.0],
];
var CalorimetryCup = /** @class */ (function () {
    function CalorimetryCup() {
    }
    CalorimetryCup.prototype.render = function (appearance) {
        var size = appearance.size;
        var radius = size / 4 + 10; // original size is 120, bottom radius 25
        var cup = new Path(Segments([[radius + 1, 12]], [[radius + 2, 0]], [[radius, 0]], [[radius - 15, size]]));
        mirrorX(cup);
        var liquidMask = new Path(Segments([[radius, 0]], [[radius - 15, size]]));
        mirrorX(liquidMask);
        cup.strokeWidth = 3.0;
        cup.setGradientStroke("right", styrofoamGradient);
        var cupOutline = cup.clone();
        cupOutline.strokeWidth = 5.0;
        cupOutline.strokeColor = "#666666";
        var graphic = new Group([cupOutline, cup]);
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            liquidMaskOpening: { start: P(-radius, 0), end: P(radius, 0) },
            hitShape: graphic.bounds.toShape(),
            snapping: [
                graphic.placeable(),
                { type: "neck", top: P(0, -2), diameter: (radius) * 2, facing: 0 },
            ]
        };
    };
    CalorimetryCup.properties = {
        label: "Calorimetry cup",
        canContainLiquids: true,
        pourable: true,
        defaultAppearance: {
            size: 120
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Height",
                min: 60, max: 150, unit: "mm"
            }
        ],
    };
    return CalorimetryCup;
}());
export { CalorimetryCup };
