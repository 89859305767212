var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P, Pivot, R, SharedColors, SharedGradients } from "apparatus/library/common";
import { Ray } from "apparatus/library/physics/optical/common";
import { Group, Path, Shape } from "paper";
var MIN_WIDTH = 20;
var MAX_WIDTH = 200;
var MIN_LENGTH = 80;
var MAX_LENGTH = 400;
var GlassBlock = /** @class */ (function () {
    function GlassBlock() {
        this.drivers = [
            {
                type: "rectangle",
                minX: MIN_LENGTH / 2, maxX: MAX_LENGTH / 2,
                minY: MIN_WIDTH, maxY: MAX_WIDTH,
                toAppearance: function (f, p) { return f({ length: p.x * 2, width: p.y }); },
                fromAppearance: function (a) { return P(a.length / 2, a.width); },
                scale: 1.0,
            }
        ];
    }
    GlassBlock.prototype.render = function (appearance) {
        var ior = appearance.ior, width = appearance.width, length = appearance.length, incidentLength = appearance.incidentLength, emergentLength = appearance.emergentLength, rayThickness = appearance.rayThickness;
        var incidenceAngleD = appearance.incidenceAngleD;
        var glass = Shape.Rectangle(R(0, 0, length, width, Pivot.TOP_CENTER))
            .withGradientFill("right-down", SharedGradients.glass)
            .withStroke("thin", SharedColors.glassEdge);
        var graphic = new Group();
        if (appearance.showRay) {
            // Precalculate max incidenceAngle.
            var maxRefractionAngleD = Math.atan((length - 10) / width).toDegrees();
            var maxIncidenceAngleD = Math.asin(Math.sin(maxRefractionAngleD.toRadians()) * ior).toDegrees();
            if (maxIncidenceAngleD) {
                incidenceAngleD = Math.min(maxIncidenceAngleD, incidenceAngleD);
            }
            // IOR = sin(i) / sin(r)
            var refractionAngleD = Math.asin(Math.sin(incidenceAngleD.toRadians()) / ior).toDegrees();
            // Calculate the point of entry (top) and exit (top) such that the ray passes through
            // rectangle center.
            var entryPoint = P(-Math.tan(refractionAngleD.toRadians()) * width / 2, 0);
            var exitPoint = P(-entryPoint.x, width);
            var ray = new Path();
            ray.addSegments([
                entryPoint.add(P(0, -incidentLength).rotate(-incidenceAngleD)),
                entryPoint,
                exitPoint,
                exitPoint.add(P(0, emergentLength).rotate(-incidenceAngleD))
            ]);
            ray.withStroke(rayThickness, appearance.rayColor);
            graphic.insertChild(0, ray);
            Ray.maybeAddArrows(appearance, ray, [0.5, 0.5, 1.0], graphic);
        }
        graphic.addChild(glass);
        return {
            graphic: graphic,
            hitShape: glass.bounds.expand(5).toShape(),
            pivot: glass.bounds.center,
        };
    };
    GlassBlock.properties = {
        label: "Glass Block",
        flippable: true,
        defaultAppearance: __assign(__assign({ incidenceAngleD: 40, ior: 1.517, length: 100, width: 40, incidentLength: 100, emergentLength: 100 }, Ray.defaultAppearanceRayOn), Ray.defaultAppearanceArrow),
        appearanceSpecs: __spreadArray(__spreadArray(__spreadArray([
            {
                spec: "slider", key: "width", label: "Width",
                min: MIN_WIDTH, max: MAX_WIDTH,
            },
            {
                spec: "slider", key: "length", label: "Length",
                min: MIN_LENGTH, max: MAX_LENGTH,
            }
        ], Ray.specs), [
            {
                spec: "slider", key: "incidenceAngleD", label: "Angle of incidence",
                min: 0, max: 89, visible: function (a) { return a.showRay; }, unit: "°", pipsStep: 45
            },
            {
                spec: "slider", key: "ior", label: "Index of refraction",
                min: 1.0, max: 3.0, visible: function (a) { return a.showRay; }, step: 0.001, pipsStep: 1000,
            },
            {
                spec: "slider", key: "incidentLength", label: "Incident ray",
                min: 10, max: 300, visible: function (a) { return a.showRay; }
            },
            {
                spec: "slider", key: "emergentLength", label: "Emergent ray",
                min: 10, max: 300, visible: function (a) { return a.showRay; }
            }
        ]), Ray.arrowSpecs),
    };
    return GlassBlock;
}());
export { GlassBlock };
