import { mirrorX } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { lerp, P, Segments } from "apparatus/library/common";
import { Color, Gradient, Path, Point, Segment } from "paper";
var DEFLATED_SHAPE = Segments(
// v1
// [[39, 40],[-16, -6],[24, -3]],
// [[48, -15],[25, 20],[-42, -2]],
// v2
// [[46, 29],[-33, 13],[22, 1]],
// [[48, -15],[27, 13],[-45, -8]]
// v3
// [[42, 39],[-40, -28],[18, 13]],
// [[48, -15],[18, 12],[-38, -26]],
// v4
[[40, 30], [-33, -7], [15, 3]], [[35, -30], [19, 4], [-40, -9]]);
var Balloon = /** @class */ (function () {
    function Balloon() {
    }
    Balloon.prototype.render = function (appearance) {
        var size = appearance.size * 10 / 1.833333;
        var lipRadius = appearance.lipDiameter / 2;
        var neckLength = appearance.neckLength;
        var fillColor = appearance.color.color();
        var strokeColor = fillColor.darken(0.4);
        var graphic = new Path(Segments([[lipRadius, neckLength], , [5, 0]], [[lipRadius, neckLength - 5]], [[lipRadius, 0]], [[size * appearance.roundness, -size], [0, size / 70 * 40], [0, -size / 70 * 80]]));
        mirrorX(graphic);
        graphic.close();
        graphic.withStroke("default", strokeColor.hex());
        interpolateSegment(graphic.segments[3], DEFLATED_SHAPE[0], 1.0 - appearance.inflation);
        interpolateSegment(graphic.segments[4], DEFLATED_SHAPE[1], 1.0 - appearance.inflation);
        graphic.fillColor = new Color(new Gradient(Gradients.simple(fillColor, 20), true), P(graphic.bounds.right * lerp(0.8, -0.7, appearance.inflation), graphic.bounds.top * 0.7), P(0, 0));
        return {
            graphic: graphic,
            pivot: P(0, 0),
            hitShape: graphic.bounds.toShape(),
            watermark: P(5, -5)
        };
    };
    Balloon.properties = {
        label: "Balloon",
        flippable: true,
        defaultAppearance: {
            size: 14,
            neckLength: 20,
            lipDiameter: 16,
            roundness: 0.7,
            color: "#6ca5ba",
            inflation: 1.0,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 4, max: 25, step: 0.1, unit: "cm"
            },
            {
                spec: "slider", key: "roundness", label: "Roundness",
                min: 0.5, max: 0.9, step: 0.01, tier: "boost"
            },
            {
                spec: "slider", key: "neckLength", label: "Neck length",
                min: 15, max: 50, unit: "mm", tier: "boost"
            },
            {
                spec: "slider", key: "lipDiameter", label: "Lip diameter",
                min: 10, max: 40, unit: "mm",
            },
            {
                spec: "color", key: "color", label: "Colour",
            },
            // {
            //     spec: "slider", key: "inflation", label: "Air inflation",
            //     min: 0.0, max: 1.0, step: 0.01, unit: "%"
            // },
            {
                spec: "radio", key: "inflation", label: "Shape",
                options: [
                    { value: 1.0, label: "Inflated" },
                    { value: 0.81, label: "Almost inflated", tier: "boost" },
                    { value: 0.42, label: "Semi-inflated", tier: "boost" },
                    { value: 0.08, label: "Deflated", tier: "boost" },
                ],
                allowSelectingTierLockedOptions: true,
            }
        ],
        isBoostRequired: function (a) { return a.inflation < 1.0; }
    };
    return Balloon;
}());
export { Balloon };
/** Interpolate between 2 segments. Assumes that both handleIn and handleOut are set.  */
function interpolateSegment(from, to, ratio) {
    var newSegment = new Segment(interpolatePointLinear(from.point, to.point, ratio), interpolatePointPolar(from.handleIn, to.handleIn, ratio), interpolatePointPolar(from.handleOut, to.handleOut, ratio));
    from.handleIn = newSegment.handleIn;
    from.handleOut = newSegment.handleOut;
    from.point = newSegment.point;
    return newSegment;
}
function interpolatePointLinear(start, end, ratio) {
    return P(lerp(start.x, end.x, ratio), lerp(start.y, end.y, ratio));
}
function interpolatePointPolar(start, end, ratio) {
    var point = new Point([0, 1]);
    point.angle = lerp(start.angle, end.angle + (end.angle < 0 ? 360 : 0), ratio);
    point.length = lerp(start.length, end.length, ratio);
    return point;
}
/**
 * Deflated shape, original.
 * [[40, -20],[-29, -7],[23, 6]],
 * [[76, 30],[1, -19],[-1, 21]],
 * [[35, 32],[8, 15],[-10, -18]],
 */
/**
 * Deflated shape. 2 points only.
 * [[40, -19],[-29, -6],[20, 4]],
 * [[35, 32],[20, 0],[-21, 0]],
 */ 
