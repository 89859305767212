import * as paper from "paper";
import rawVersion from "version";
/** Stripe public keys */
var StripePublicKeys;
(function (StripePublicKeys) {
    StripePublicKeys.test = "pk_test_yDkVHgKFZSODA9gfm2TfLXbI00rCtT94nG";
    StripePublicKeys.live = "pk_live_AcpjKxoOR2Yj08DBTLm1dlaW00iCYYHpDq";
})(StripePublicKeys || (StripePublicKeys = {}));
export var Consts;
(function (Consts) {
    Consts.isDev = location.hostname == "localhost" && location.hash != "#public";
    Consts.isProd = !Consts.isDev;
    Consts.isBeta = location.pathname.lastIndexOf("/beta", 0) === 0;
    Consts.version = rawVersion + (Consts.isDev ? "-dev" : (Consts.isBeta ? "-beta" : ""));
    Consts.majorVersion = Consts.version.match("[0-9]*\.[0-9]*")[0];
    Consts.epsilon = 0.0001;
    Consts.googleAnalyticsCategory = "Chemix" + Consts.version;
    Consts.firebaseAppUrl = "https://chemixapp.firebaseio.com/chemix/diagrams/";
    /** Maximum number of pixels to render in H igh Quality. */
    Consts.pngMaxRenderPixels = 3800000;
    /** Max number of pixels to use when rendering in Standard quality. */
    Consts.pngMaxRenderPixelsMedium = 480000;
    // Sentry-related configuration
    Consts.sentryDsn = "https://d95a293d03b14780a9c5051cd44a0e7e@sentry.io/154256";
    Consts.stripePublicKey = Consts.isDev ? StripePublicKeys.test : StripePublicKeys.live;
    /** Canvas extends fully under the sidebar. Knowing the width is useful in adjusting things. */
    Consts.sidebarWidth = 250;
})(Consts || (Consts = {}));
// Debug consts
export var Debug;
(function (Debug) {
    Debug.HIT_SHAPES = false;
    Debug.CENTERS = false;
    Debug.LIQUID_MASK = false;
    Debug.SHAPES = false;
    Debug.PRESENTATION_MODE = false;
})(Debug || (Debug = {}));
if (Consts.isDev) {
    window["Debug"] = Debug;
    window["paper"] = paper;
}
