var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import store from "store/store";
var ZoomCommand = /** @class */ (function () {
    function ZoomCommand(from, to) {
        this.from = from;
        this.to = to;
    }
    ZoomCommand.prototype.execute = function () {
        store.dispatchFn("Zoom", mutator(this.to));
    };
    ZoomCommand.prototype.undo = function () {
        store.dispatchFn("Zoom", mutator(this.from));
    };
    return ZoomCommand;
}());
export { ZoomCommand };
function mutator(zoom) {
    return function (state) { return (__assign(__assign({}, state), { zoom: zoom })); };
}
