import { currencyBasedRounding } from "tiers/currency";
var PricingScheme2022 = /** @class */ (function () {
    function PricingScheme2022() {
        this.PRICES = {
            boost: {
                gbp: { month: 3.49, year: 2.79 },
                usd: { month: 4.49, year: 3.59 },
                cad: { month: 5.49, year: 4.39 },
                aud: { month: 5.99, year: 4.79 },
                eur: { month: 3.99, year: 3.19 },
                sgd: { month: 4.99, year: 3.99 },
                mxn: { month: 49, year: 39 },
                cop: { month: 8999, year: 7199 },
                myr: { month: 9.99, year: 7.99 },
                inr: { month: 209, year: 169 },
                nzd: { month: 6.99, year: 5.59 },
            }
        };
        // https://docs.google.com/spreadsheets/d/17rcxpODwO_md07ANRoxol9uDBR5-LtWlZ7e7ohaGwJk/edit#gid=1712682288
        this.SCHOOL_DISCOUNTS_BREAKS = [
            [100, -150],
            [200, 0],
            [500, 25],
            [1000, 45],
            [2000, 60],
            [5000, 70],
            [10000, 75],
            [20000, 80],
            [50000, 85],
        ];
    }
    PricingScheme2022.prototype.price = function (currency, period) {
        return this.PRICES.boost[currency][period];
    };
    PricingScheme2022.prototype.schoolPrice = function (students, currency, period, showFor) {
        if (showFor === void 0) { showFor = "month"; }
        var baseMonthlyPrice = this.PRICES["boost"][currency]["month"];
        // Discount Factor = 10
        var pricePerUser = (baseMonthlyPrice / 10).round(4);
        return discountBreakBasedPricing(students, pricePerUser, this.SCHOOL_DISCOUNTS_BREAKS, currency, period, showFor);
    };
    return PricingScheme2022;
}());
var OriginalPricingScheme = /** @class */ (function () {
    function OriginalPricingScheme() {
        this.PRICES = {
            boost: {
                gbp: { month: 2.99, year: 2.39 },
                eur: { month: 3.49, year: 2.79 },
                usd: { month: 3.49, year: 2.79 },
                cad: { month: 4.49, year: 3.59 },
                aud: { month: 4.99, year: 3.99 },
                sgd: { month: 4.49, year: 3.59 },
                mxn: { month: 49, year: 39 },
                cop: { month: 8999, year: 7199 },
                myr: { month: 8.99, year: 7.19 },
                // These were added later and so values don't matter
                inr: { month: 209, year: 169 },
                nzd: { month: 6.99, year: 5.59 },
            },
        };
        // From https://docs.google.com/spreadsheets/d/1LNK9C4RMyJNWCBTed5ByMtxC3DTq-7JqrJyCvslwzLo/edit#gid=1712682288
        this.SCHOOL_DISCOUNTS_BREAKS = [
            [100, -150],
            [200, -10],
            [500, 10],
            [1000, 30],
            [2000, 50],
            [5000, 55],
            [10000, 60],
            [20000, 65],
        ];
    }
    OriginalPricingScheme.prototype.price = function (currency, period) {
        return this.PRICES.boost[currency][period];
    };
    OriginalPricingScheme.prototype.schoolPrice = function (students, currency, period, showFor) {
        if (showFor === void 0) { showFor = "month"; }
        var baseMonthlyPrice = this.PRICES["boost"][currency]["month"];
        var pricePerUser = (baseMonthlyPrice / 20).round(4);
        return discountBreakBasedPricing(students, pricePerUser, this.SCHOOL_DISCOUNTS_BREAKS, currency, period, showFor);
    };
    return OriginalPricingScheme;
}());
function discountBreakBasedPricing(seats, baseMonthlyPricePerUser, breaks, currency, period, showFor) {
    var totalPrice = 0;
    var previousBreak = 0;
    for (var _i = 0, breaks_1 = breaks; _i < breaks_1.length; _i++) {
        var _a = breaks_1[_i], currentBreak = _a[0], percentDiscount = _a[1];
        // Max students in this band.
        var maxStudents = currentBreak - previousBreak;
        var pricePerUser = (baseMonthlyPricePerUser * (1 - percentDiscount / 100)).round(4);
        totalPrice += Math.min(maxStudents, seats) * pricePerUser;
        seats -= maxStudents;
        if (seats <= 0)
            break;
        previousBreak = currentBreak;
    }
    if (showFor == "month") {
        totalPrice /= 12;
    }
    if (period == "month") {
        totalPrice *= 1.25;
    }
    return totalPrice.round(currencyBasedRounding(currency));
}
export function getPricingScheme(scheme) {
    switch (scheme) {
        case "original": return new OriginalPricingScheme();
        case "2022": return new PricingScheme2022();
    }
}
