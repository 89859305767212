var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { clamp } from "apparatus/library/common";
import { commandProcessor } from "command/command";
import * as React from "react";
import store from "store/store";
import { ZoomCommand } from "view/zoom";
/**
 * Component to display the zoom slider.
 */
var ZoomSlider = /** @class */ (function (_super) {
    __extends(ZoomSlider, _super);
    function ZoomSlider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /** Ref to the DOM element. */
        _this.elementRef = React.createRef();
        return _this;
    }
    ZoomSlider.prototype.executeZoomCommand = function (zoom) {
        if (store.zoom() != zoom) {
            // Don't set zero zoom.
            zoom = Math.max(0.01, zoom);
            // BUG: updating the zoom externally causes slidechange to trigger which may trigger another
            // zoom command, so we have to make sure to merge with previous command.
            // Unfortunately solving this by guarding against miniscule differences between store.zoom()
            // and zoom don't work.
            commandProcessor.executeReplacingLastCommand(function (lastCommand) {
                if (!(lastCommand instanceof ZoomCommand))
                    return false;
                return new ZoomCommand(lastCommand.from, zoom);
            }, new ZoomCommand(this.props.zoom, zoom));
        }
    };
    ZoomSlider.prototype.componentDidMount = function () {
        var slider = $(this.elementRef.current);
        var self = this;
        slider.slider({
            value: getSliderValueFromZoom(this.props.zoom),
            min: -2,
            max: 2,
            step: 0.01,
            animate: "fast",
            // orientation: "vertical"
        }).slider("pips", {
            rest: "label",
            step: 100,
            labels: ["25%", "50%", "100%", "200%", "300%"]
        }).slider("float", {
            formatLabel: function (value) {
                return Math.floor(getZoomFromSliderValue(value) * 100) + "%";
            }
        }).on("slidestart", function (e, ui) {
            self.props.onStartZoom();
        }).on("slide", function (e, ui) {
            // Convert to zoom level.
            var zoomLevel = getZoomFromSliderValue(ui.value);
            self.props.onUpdateZoom(zoomLevel);
        }).on("slidestop", function (e, ui) {
            var newZoom = getZoomFromSliderValue(ui.value);
            self.executeZoomCommand(newZoom);
            self.props.onEndZoom();
        }).on("slidechange", function (e, ui) {
            var newZoom = getZoomFromSliderValue(ui.value);
            self.executeZoomCommand(newZoom);
            self.props.onEndZoom();
        });
    };
    ZoomSlider.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (prevProps.zoom != this.props.zoom) {
            var currentSliderValue = $(this.elementRef.current).slider("value");
            var currentZoom = getZoomFromSliderValue(currentSliderValue);
            if (currentZoom != this.props.zoom) {
                var newZoomSlider = getSliderValueFromZoom(this.props.zoom);
                $(this.elementRef.current).slider("value", newZoomSlider);
            }
        }
    };
    ZoomSlider.prototype.render = function () {
        return React.createElement("div", { id: "zoom-slider", key: "zoomSlider", ref: this.elementRef });
    };
    return ZoomSlider;
}(React.Component));
export { ZoomSlider };
/** Converts a slider value to Zoom value.  */
function getZoomFromSliderValue(value) {
    return (value < 0 ? Math.pow(2, value) : 1 + value);
}
/** Converts zoom value to slider value. */
function getSliderValueFromZoom(zoom) {
    return zoom <= 1 ? Math.log(zoom) / Math.log(2) : zoom - 1;
}
export function clampZoomValue(zoom) {
    return clamp(zoom, 0.25, 3);
}
