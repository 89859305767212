var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { breakPath } from "apparatus/draw";
import { Segments } from "apparatus/library/common";
import { SharedAppearance } from "apparatus/shared/appearance";
import { Path } from "paper";
import { staticImplements } from "types";
var SPECartridge = /** @class */ (function () {
    function SPECartridge() {
    }
    SPECartridge.prototype.render = function (_a) {
        var diameter = _a.diameter, length = _a.length, view = _a.view;
        var scale = view === "zoom" ? 4.0 : 1.0;
        var nozzleDiameter = view === "zoom" ? 2 : 2.5;
        var radius = diameter / 2;
        var shape = new Path(Segments([[radius + 5, 0]], [[radius, 0]], [[radius, length - 3]], [[nozzleDiameter, length]], [[nozzleDiameter, length + 7]])).mirrorX();
        shape.scale(scale);
        var liquidMask = shape.clone();
        var graphic = breakPath(shape, 5)
            .withStroke("default");
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.rectHitShape(),
            // IDEA: Drop spot and assistant action to add drop
        };
    };
    SPECartridge.properties = {
        label: "SPE Cartridge",
        defaultAppearance: {
            view: "real",
            diameter: 10,
            length: 70
        },
        canContainLiquids: true,
        appearanceSpecs: [
            SharedAppearance.viewRealZoom,
            {
                spec: "slider", key: "diameter", label: "Diameter", min: 8, max: 14, unit: "mm",
            },
            {
                spec: "slider", key: "length", label: "length", min: 50, max: 100, unit: "mm",
            },
        ],
    };
    SPECartridge = __decorate([
        staticImplements()
    ], SPECartridge);
    return SPECartridge;
}());
export { SPECartridge };
