import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, SharedColors } from "apparatus/library/common";
import { Group, Path } from "paper";
var WALL_COLOR = "#d9ab5f";
var Pulley = /** @class */ (function () {
    function Pulley() {
    }
    Pulley.prototype.render = function (appearance) {
        var radius = appearance.radius, distance = appearance.distance, wall = appearance.wall;
        var pulley = new Path.Circle(P(0, 0), radius)
            .withStroke("default").withFill("#cccccc");
        var attachment = new Path.Rectangle(R(0, 10, 20, radius + distance + 15, Pivot.BOTTOM_CENTER), 5.0)
            .withStroke("default")
            .withGradientFill("right-down", Gradients.cylinder("#999999".color()));
        var nut = new Path.Circle(P(0, 0), 3.0).withFill(SharedColors.stroke);
        var graphic = new Group([pulley, attachment, nut]);
        if (wall) {
            var y = -(radius + distance);
            graphic.addChildren([
                new Path.Rectangle(R(0, y, 100, 30, Pivot.BOTTOM_CENTER))
                    .withGradientFill("down", [[WALL_COLOR + "00", 0.0], [WALL_COLOR, 0.8]]),
                new Path.Line(P(-50, y), P(50, y)).withStroke("default"),
            ]);
        }
        return {
            graphic: graphic,
            pivot: P(0, 0),
            hitShape: graphic.bounds.toShape()
        };
    };
    Pulley.properties = {
        label: "Pulley",
        defaultAppearance: {
            radius: 50,
            distance: 50,
            wall: false
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "radius", label: "Pulley radius",
                min: 30, max: 80
            },
            {
                spec: "slider", key: "distance", label: "Distance from wall",
                min: 20, max: 200
            },
            { spec: "checkbox", label: "Wall", subLabel: "Show", key: "wall" }
        ],
    };
    return Pulley;
}());
export { Pulley };
