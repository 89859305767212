var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { breakPath, setDefaultStyle } from "apparatus/draw";
import { P, Segments } from "apparatus/library/common";
import { Path } from "paper";
import { staticImplements } from "types";
var DryingTube = /** @class */ (function () {
    function DryingTube() {
    }
    DryingTube.prototype.render = function (appearance) {
        var outline = new Path(Segments([[-8, 0]], [[-8, -30], [-5, -3]], [[-8, -64], , [0, -10]], [[20, -85], [-17, -5], [8, -11]], [[49, -81], [-2, -13]], [[129, -67]], [[126, -49]], [[46, -63], , [-6, 10]], [[18, -68], [4, 15], [-8, -1]], [[8, -60]], [[8, -30], , [5, -3]], [[8, 0]]));
        var graphic = breakPath(outline, 6);
        var outletMidpoint = outline.segments[5].point.add(outline.segments[6].point).divide(2);
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: [
                { type: "connectable", at: P(0, 0), facing: 180 },
                { type: "connectable", at: outletMidpoint, facing: 100 },
            ]
        };
    };
    DryingTube.properties = {
        label: "Drying tube",
        flippable: true,
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    DryingTube = __decorate([
        staticImplements()
    ], DryingTube);
    return DryingTube;
}());
export { DryingTube };
