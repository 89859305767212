var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P, Segments } from "apparatus/library/common";
import { BasicShapes } from "apparatus/library/shapes/common";
import { Path } from "paper";
var Triangle = /** @class */ (function () {
    function Triangle() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 8, maxX: 250,
                minY: 8, maxY: 250,
                toAppearance: function (f, p) { return f({
                    width: p.x * 2,
                    height: p.y * 2
                }); },
                fromAppearance: function (a) { return P(a.width / 2, a.height / 2); },
                scale: 1.0,
            }
        ];
    }
    Triangle.prototype.render = function (appearance) {
        var width = appearance.width, height = appearance.height;
        var apexPosition = P(appearance.apex * width / 2, -height / 2);
        var graphic = new Path(Segments([[apexPosition.x, apexPosition.y]], [[width / 2, height / 2]], [[-width / 2, height / 2]]));
        graphic.close();
        BasicShapes.applyStyle(graphic, appearance);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
        };
    };
    Triangle.properties = {
        label: "Triangle",
        defaultAppearance: __assign({ width: 200, height: 200, apex: 0 }, BasicShapes.defaultAppearance),
        appearanceSpecs: __spreadArray(__spreadArray([], BasicShapes.specs), [
            BasicShapes.header,
            {
                spec: "slider", key: "apex", label: "Apex position",
                min: -1, max: 1, step: 0.01,
            }
        ]),
    };
    return Triangle;
}());
export { Triangle };
