import { P, R } from "apparatus/library/common";
import { CompoundPath, Group, Path } from "paper";
var Ruler = /** @class */ (function () {
    function Ruler() {
    }
    Ruler.prototype.render = function (appearance) {
        var length = appearance.length;
        var height = 25;
        var margin = 15;
        var spacing = appearance.unit == "metric" ? 10 : 12.7;
        var bigStep = appearance.unit == "metric" ? 10 : 2; // Draw a big tick every <bigStep> mark.
        var markings = new CompoundPath({});
        var x = 0;
        var i = 0;
        while (x <= length) {
            markings.moveTo(P(x, 1.5));
            markings.lineTo(P(x, i % bigStep == 0 ? 10 : 7));
            i += 1;
            x += spacing;
        }
        markings.strokeColor = "#666666";
        markings.strokeWidth = 2.0;
        var ruler = new Path.Rectangle(R(-margin, 0, x - spacing + margin * 2, height));
        var _a = rulerColor(appearance.color), fill = _a.fill, stroke = _a.stroke;
        ruler.fillColor = fill;
        ruler.strokeWidth = 3.0;
        ruler.strokeColor = stroke;
        return { graphic: new Group([ruler, markings]), hitShape: ruler.bounds.toShape() };
    };
    Ruler.properties = {
        label: "Ruler",
        defaultAppearance: {
            unit: "metric",
            length: 300,
            color: "beige"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "length", label: "Length",
                min: 100, max: 600,
            },
            {
                spec: "radio", key: "unit", label: "Units",
                options: [
                    { value: "metric", label: "Metric (cm)" },
                    { value: "imperial", label: "Imperial (in)" },
                ],
            },
            {
                spec: "radio", key: "color", label: "Colour",
                options: [
                    { value: "beige", label: "Beige" },
                    { value: "white", label: "White" },
                    { value: "blue", label: "Blue" },
                ],
            }
        ],
    };
    return Ruler;
}());
export { Ruler };
function rulerColor(color) {
    switch (color) {
        case "beige":
            return { fill: "#ede6d7", stroke: "#969082" };
        case "white":
            return { fill: "#eeeeee", stroke: "#444444" };
        case "blue":
            return { fill: "#becfea", stroke: "#7586a0" };
    }
}
