import { breakPath, glassFold, setDefaultStyle } from "apparatus/draw";
import { P, Segments } from "apparatus/library/common";
import { Path } from "paper";
var ReceiverAdaptor = /** @class */ (function () {
    function ReceiverAdaptor() {
    }
    ReceiverAdaptor.prototype.render = function (appearance) {
        var path = new Path(Segments([[0, 0]], [[22, 7]], [[24, 6]], [[50, 13], , [20, 7]], [[71, 40], [0, -5]], [[71, 91]], // Bottom. Break after this
        [[62, 100]], [[62, 52], , [0, -11]], [[50, 38], [6, 2]], [[18, 30]], [[16, 28]], [[-6, 23]]));
        var hitShape = path.bounds.toShape();
        var graphic = breakPath(path, 6);
        setDefaultStyle(graphic);
        graphic.addChild(glassFold(20, 18, { size: 12, rotation: -73 }));
        return {
            graphic: graphic,
            hitShape: hitShape,
            watermark: P(0, 10),
            snapping: [
                { type: "connectable", at: P(66, 91), facing: 180 },
                { type: "connectable", at: P(0, 12), facing: -73 },
            ]
        };
    };
    ReceiverAdaptor.properties = {
        label: "Receiver Adaptor",
        flippable: true,
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return ReceiverAdaptor;
}());
export { ReceiverAdaptor };
