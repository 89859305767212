import { setDefaultStyleWithStroke } from "apparatus/draw";
import { R, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
var CapillaryTube = /** @class */ (function () {
    function CapillaryTube() {
    }
    CapillaryTube.prototype.render = function (appearance) {
        var width = appearance.width;
        var length = appearance.length;
        var shape = new Path.Rectangle(R(0, 0, width, length));
        var liquidMask = shape.clone();
        shape.remove();
        var graphic = new Group([
            new Path(Segments([[0, 0]], [[0, length]])),
            new Path(Segments([[width, 0]], [[width, length]]))
        ]);
        setDefaultStyleWithStroke("thinner", graphic);
        return { graphic: graphic, hitShape: graphic.bounds.expand(20).toShape(), liquidMask: liquidMask };
    };
    CapillaryTube.properties = {
        label: "Capillary Tube",
        canContainLiquids: true,
        meniscus: true,
        defaultAppearance: {
            width: 6,
            length: 150
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Diameter",
                min: 4, max: 8, step: 0.5
            },
            {
                spec: "slider", key: "length", label: "Length",
                min: 60, max: 250,
            }
        ],
    };
    return CapillaryTube;
}());
export { CapillaryTube };
