import { breakPathClosed, glassFold, setDefaultStyle } from "apparatus/draw";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Path } from "paper";
import { smoothCorner } from "../../draw";
var DistillationHead = /** @class */ (function () {
    function DistillationHead() {
    }
    DistillationHead.prototype.render = function (appearance) {
        // Distillation head is similar to a 3-way tube. We will
        // draw this by unioning two rectangles, a "vertical" one
        // and a tilted "outlet" one.
        var topGroundJoint = new Path(Segments([[-10, -10]], [[-7.5, 10]], [[7.5, 10]], [[10, -10]]));
        var bottomGroundJoint = new Path(Segments([[-10, 60]], [[-7, 85]], [[7, 85]], [[10, 60]]));
        bottomGroundJoint.closePath();
        var vertical = new Path.Rectangle(R(0, 10, 18, 51, Pivot.TOP_CENTER));
        var pivot = P(0, 30);
        var angle = 15;
        var outletSize = 50;
        var outlet = new Path.Rectangle(R(pivot.x, pivot.y, outletSize, 15, Pivot.CENTER_LEFT));
        outlet.rotate(15, P(0, 40));
        var shape = (vertical.unite(outlet).unite(topGroundJoint).unite(bottomGroundJoint));
        // Smooth out the joints between the outlet and the vertical pipe.
        smoothCorner(shape.segments[9], 3.0);
        smoothCorner(shape.segments[6], 5.0);
        var graphic = breakPathClosed(shape, 3, 9, 15);
        setDefaultStyle(graphic);
        graphic.addChildren([
            glassFold(5, 31, { rotation: 90 }),
            glassFold(0, 12, { size: 6 }),
            glassFold(0, 63, { size: 8 }),
        ]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: [
                { type: "connectable", at: P(0, -3), facing: 0 },
                { type: "connectable", at: P(0, 83), facing: 180 },
                { type: "connectable", at: pivot.add(P(outletSize, 0)).rotate(15, pivot), facing: 90 + angle },
            ]
        };
    };
    DistillationHead.properties = {
        label: "Distillation Head",
        flippable: true,
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return DistillationHead;
}());
export { DistillationHead };
