var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { mirrorX } from "apparatus/draw";
import { P, Segments } from "apparatus/library/common";
import { BasicShapes } from "apparatus/library/shapes/common";
import { Path } from "paper";
var Cross = /** @class */ (function () {
    function Cross() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 10, maxX: 80,
                minY: 5, maxY: 30,
                toAppearance: function (f, p) { return f({
                    thickness: Math.min(p.y, p.x - 10),
                    length: p.x,
                }); },
                fromAppearance: function (a) { return P(a.length, a.thickness); },
                scale: 1.0,
            }
        ];
    }
    Cross.prototype.render = function (appearance) {
        var thickness = appearance.thickness, length = appearance.length;
        var graphic = new Path(Segments([[thickness, length]], [[thickness, thickness]], [[length, thickness]], [[length, -thickness]], [[thickness, -thickness]], [[thickness, -length]]));
        graphic.closePath();
        mirrorX(graphic);
        var shape = graphic.clone();
        // Styling.
        BasicShapes.applyStyle(graphic, appearance);
        return {
            graphic: graphic,
            hitShape: shape,
        };
    };
    Cross.properties = {
        label: "Cross",
        defaultAppearance: __assign({ thickness: 10, length: 50 }, BasicShapes.defaultAppearance),
        appearanceSpecs: __spreadArray([], BasicShapes.specs),
    };
    return Cross;
}());
export { Cross };
