var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Tippy from "@tippyjs/react";
import { g } from "analytics";
import { LoginButton } from "api/auth/login_button";
import { storeToken } from "api/auth/storage";
import { CommonModal } from "components/common_modal";
import { clog } from "log";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
export var LoginModal2 = function (_a) {
    var auth = _a.auth, onLoginSuccessCallback = _a.onLoginSuccessCallback, modalState = __rest(_a, ["auth", "onLoginSuccessCallback"]);
    var _b = React.useState(false), staySignedIn = _b[0], setStaySignedIn = _b[1];
    var close = function () { return modalState.setIsOpen(false); };
    var t = useTranslation().t;
    function onLoginSuccess(token, tier) {
        if (staySignedIn) {
            clog("Token written to storage");
            storeToken(token);
        }
        if (onLoginSuccessCallback) {
            setTimeout(function () { return onLoginSuccessCallback(tier); }, 500);
        }
        close();
    }
    return React.createElement(CommonModal, __assign({}, modalState, { extraClass: "account-login-modal", title: t('login.sign_in'), icon: "fa-square-user" }),
        React.createElement("p", null, t('login.prompt')),
        React.createElement(LoginButton, { providerName: "google", key: "google", auth: auth, active: true, onClick: function () { return g("SignInButton", "account:google"); }, onSuccess: function (token, tier) { return onLoginSuccess(token, tier); } }),
        React.createElement(LoginButton, { providerName: "microsoft", key: "microsoft", auth: auth, active: true, onClick: function () { return g("SignInButton", "account:microsoft"); }, onSuccess: function (token, tier) { return onLoginSuccess(token, tier); } }),
        React.createElement(LoginButton, { key: "facebook", providerName: "facebook", auth: auth, active: true, onClick: function () { return g("SignInButton", "account:facebook"); }, onSuccess: function (token, tier) { return onLoginSuccess(token, tier); } }),
        React.createElement(LoginButton, { key: "code", providerName: "code", auth: auth, active: true, onClick: function () { return g("SignInButton", "account:code"); }, onSuccess: function (token, tier) { return onLoginSuccess(token, tier); } }),
        React.createElement("div", { className: "mx-3 text-left" },
            React.createElement(StaySignedInCheckbox, { key: "stayLoggedIn", value: staySignedIn, onChange: function (v) { return setStaySignedIn(v); } })),
        React.createElement("hr", null),
        React.createElement("div", { className: "mx-3 text-muted hint" },
            React.createElement("a", { href: "https://help.chemix.org/article/87-twitter-issues/", target: "_blank" }, "What happened to \"Sign in with Twitter\"?")));
};
export var StaySignedInCheckbox = function (props) {
    var t = useTranslation().t;
    var id = "stay_signed_in_" + Math.round(Math.random() * 100);
    var hint = React.createElement(Trans, { i18nKey: "login.stay_signed_in_hint" },
        "Tick this if you'd like to be automatically signed in on each visit. To keep your account secure, use this option ",
        React.createElement("b", null, "only on your personal devices."));
    return React.createElement(Tippy, { content: hint },
        React.createElement("div", { className: "custom-control custom-checkbox my-2" },
            React.createElement("input", { type: "checkbox", className: "custom-control-input", checked: props.value, onChange: function (e) { return props.onChange(e.target.checked); }, id: id }),
            React.createElement("label", { className: "stay-signed-in custom-control-label", htmlFor: id }, t('login.stay_signed_in'))));
};
