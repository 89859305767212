import { Path } from "paper";
import { mirrorX, setDefaultStyle } from "../../draw";
import { Segments } from "../common";
var PipetteBottle = /** @class */ (function () {
    function PipetteBottle() {
    }
    PipetteBottle.prototype.render = function (appearance) {
        var bottle = new Path(Segments([[9, 0]], [[10, 5], undefined, [0, 5]], [[20, 25], [0, -10]], [[20, 100]], [[0, 100]]));
        mirrorX(bottle);
        var liquidMask = bottle.clone();
        setDefaultStyle(bottle);
        return {
            graphic: bottle,
            liquidMask: liquidMask,
            hitShape: bottle.bounds.toShape(),
            snapping: bottle.placeable(),
        };
    };
    PipetteBottle.properties = {
        label: "Pipette Bottle",
        canContainLiquids: true,
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return PipetteBottle;
}());
export { PipetteBottle };
