var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { createApparatus } from "apparatus/library";
import { requestDiameter } from "apparatus/library/bung";
import { Arrays } from "ext/array";
import { t_add } from "i18n_utils";
import { Path, Rectangle, Shape } from "paper";
import { staticImplements } from "types";
import { addEdgeLips, breakPath, mirrorX, setDefaultStyle } from "../draw";
import { P, Pp, Segments } from "./common";
var TestTube = /** @class */ (function () {
    function TestTube() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 8, maxX: 30,
                minY: 75, maxY: 200,
                fromAppearance: function (a) { return P(a.width, a.height); },
                toAppearance: function (f, p) { return f({ width: p.x, height: p.y }); },
                scale: 2.0,
            }
        ];
    }
    TestTube.prototype.render = function (appearance) {
        var width = appearance.width;
        var height = appearance.height;
        var graphic = new Path();
        graphic.addSegments(Segments([[0, 0]], [[0, height - width / 2], undefined, [0, width / 1.55]]));
        mirrorX(graphic, width / 2);
        addEdgeLips(graphic, { lipSize: 2.0 });
        var liquidMask = graphic.clone();
        setDefaultStyle(graphic);
        var hitShape = Shape.Rectangle(new Rectangle(0, 0, width, height));
        return {
            graphic: graphic,
            hitShape: hitShape,
            liquidMask: liquidMask,
            liquidMaskOpening: { start: liquidMask.segments[0].point, end: Arrays.last(liquidMask.segments).point },
            snapping: { type: "neck", top: P(width / 2, 0), diameter: width, facing: 0 }
        };
    };
    TestTube.properties = {
        label: "Test tube",
        canContainLiquids: true,
        meniscus: true,
        pourable: true,
        defaultAppearance: {
            // Size of a regular test tube = 16, according to
            // https://www.homesciencetools.com/product/glass-test-tubes/
            // However we use 24 so it's more visible.
            width: 24,
            height: 150,
        },
        appearanceSpecs: [
            {
                spec: "slider",
                key: "width",
                label: "Width",
                i18nLabel: "ui.props.appearance.width",
                min: 8,
                max: 30,
            },
            {
                spec: "slider",
                key: "height",
                label: "Height",
                i18nLabel: "ui.props.appearance.height",
                min: 75,
                max: 200,
            },
        ],
        actions: [
            {
                type: "add",
                label: function () { return t_add("bung"); },
                icon: "plus",
                action: function (a, p, r) {
                    // Minus height/2 because there is no specified pivot.
                    var position = P(0, -a.height / 2).rotate(r).add(Pp(p));
                    var apparatus = createApparatus("bung", position);
                    var bung = __assign(__assign({}, apparatus.appearance), requestDiameter(a.width));
                    apparatus.appearance = bung;
                    apparatus.rotation = r;
                    return apparatus;
                }
            }
        ]
    };
    TestTube = __decorate([
        staticImplements()
    ], TestTube);
    return TestTube;
}());
export { TestTube };
var TestTubeWithSideArm = /** @class */ (function () {
    function TestTubeWithSideArm() {
    }
    TestTubeWithSideArm.prototype.render = function (appearance) {
        var width = appearance.width;
        var height = appearance.height;
        var offset = 30;
        var holeRadius = appearance.holeDiameter / 2;
        var sideArmLength = 15;
        var tube = new Path();
        tube.addSegments(Segments([[0, 0]], [[0, height - width / 2], undefined, [0, width / 1.55]]));
        mirrorX(tube, width / 2);
        tube.insertSegments(3, Segments([[width, offset + holeRadius]], [[width + sideArmLength, offset + holeRadius]], [[width + sideArmLength, offset - holeRadius]], [[width, offset - holeRadius]]));
        addEdgeLips(tube, { lipSize: 2.0 });
        var liquidMask = tube.clone();
        var graphics = breakPath(tube, 6);
        setDefaultStyle(graphics);
        var hitShape = Shape.Rectangle(new Rectangle(0, 0, width + sideArmLength, height));
        return {
            graphic: graphics,
            hitShape: hitShape,
            liquidMask: liquidMask,
            snapping: [
                { type: "connectable", at: P(width + sideArmLength, offset), facing: 90, },
                { type: "neck", top: P(width / 2, 0), diameter: width, facing: 0 }
            ]
        };
    };
    TestTubeWithSideArm.properties = {
        label: "Test tube with side arm",
        canContainLiquids: true,
        flippable: true,
        defaultAppearance: __assign(__assign({}, TestTube.properties.defaultAppearance), { holeDiameter: 10 }),
        appearanceSpecs: __spreadArray(__spreadArray([], TestTube.properties.appearanceSpecs), [
            {
                spec: "slider", key: "holeDiameter", label: "Hole diameter",
                min: 8, max: 20,
            }
        ]),
    };
    return TestTubeWithSideArm;
}());
export { TestTubeWithSideArm };
function ApparatusClass(ApparatusClass) {
    throw new Error("Function not implemented.");
}
