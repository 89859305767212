var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { R } from "apparatus/library/common";
import { Path } from "paper";
import { staticImplements } from "types";
var RED = "#d74144".color();
var BLUE = "#6286b3".color();
var BLEACHED = "#dddddd".color();
var LitmusPaper = /** @class */ (function () {
    function LitmusPaper() {
    }
    LitmusPaper.prototype.render = function (appearance) {
        var dipped = appearance.dipped, scale = appearance.scale, length = appearance.length, color = appearance.color, bleached = appearance.bleached;
        var graphic = new Path.Rectangle(R(0, 0, 15, length));
        var baseColor = color === "blue" ? BLUE : RED;
        if (!dipped) {
            graphic.withFill(baseColor.string());
        }
        else {
            var dipColor = color === "blue" ? RED : BLUE;
            var front = 1 - appearance.front;
            if (color === "blue" && bleached) {
                var bleachedFront = 1 - appearance.front * appearance.bleachFront;
                graphic.withGradientFill("down", [
                    [baseColor.string(), front],
                    [dipColor.ladd(-10).string(), front + 0.01],
                    [dipColor.ladd(5).string(), front + 0.05],
                    [dipColor.string(), front + 0.1],
                    [BLEACHED.string(), bleachedFront + 0.05]
                ]);
            }
            else {
                graphic.withGradientFill("down", [
                    [baseColor.string(), front],
                    [dipColor.ladd(-10).string(), front + 0.01],
                    [dipColor.ladd(5).string(), front + 0.05],
                    [dipColor.string(), front + 0.1],
                ]);
            }
        }
        graphic.scale(scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(15).toShape()
        };
    };
    LitmusPaper.properties = {
        label: "Litmus paper",
        defaultAppearance: {
            color: "blue",
            dipped: false,
            front: 0.4,
            length: 70,
            scale: 1.0,
            bleached: false,
            bleachFront: 0.5,
        },
        appearanceSpecs: [
            {
                spec: "toggle-buttons", size: "text", key: "color", label: "Colour",
                options: [
                    { value: "blue", label: "Blue" },
                    { value: "red", label: "Red" },
                ]
            },
            {
                spec: "slider", key: "length", label: "Length",
                min: 30, max: 100, unit: "mm",
            },
            { spec: "checkbox", key: "dipped", label: "Dipped" },
            {
                spec: "slider", key: "front", label: "Front distance",
                min: 0.2, max: 0.7, step: 0.01, unit: "%", visible: function (a) { return a.dipped; }
            },
            {
                spec: "checkbox", key: "bleached", label: "Bleached",
                visible: function (a) { return a.color === "blue" && a.dipped; }
            },
            {
                spec: "slider", key: "bleachFront", label: "Bleach distance",
                min: 0.2, max: 0.7, step: 0.01, unit: "%",
                visible: function (a) { return a.color === "blue" && a.dipped && a.bleached; }
            },
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 3.0, step: 0.1, unit: "%",
            }
        ],
    };
    LitmusPaper = __decorate([
        staticImplements()
    ], LitmusPaper);
    return LitmusPaper;
}());
export { LitmusPaper };
