var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P } from "apparatus/library/common";
import { Ray } from "apparatus/library/physics/optical/common";
import { Group, Path, Symbol } from "paper";
import { staticImplements } from "types";
import { Lazy } from "utils";
var Mirror = /** @class */ (function () {
    function Mirror() {
    }
    Mirror.prototype.render = function (appearance) {
        var height = appearance.size;
        var mirror = new Group();
        for (var y = 0; y < height - 6; y += 10) {
            mirror.addChild(hashSymbol.value.place(P(0, y)));
        }
        var line = new Path.Line(P(0, 0), P(0, height)).withStroke("thick");
        line.strokeCap = "square";
        mirror.addChild(line);
        var graphic = new Group([mirror]);
        if (appearance.showRay) {
            var midPoint = P(0, height / 2);
            var incidentVector = P(-appearance.incidentLength, 0);
            incidentVector.angle += appearance.incidentAngleD;
            var emergentVector = incidentVector.clone();
            emergentVector.y *= -1;
            emergentVector.length = appearance.emergentLength;
            var ray1 = new Path([midPoint.add(incidentVector), midPoint])
                .withStroke(appearance.rayThickness, appearance.rayColor);
            var ray2 = new Path([midPoint, midPoint.add(emergentVector)])
                .withStroke(appearance.rayThickness, appearance.rayColor);
            graphic.insertChildren(0, [ray1, ray2]);
            Ray.maybeAddArrows(appearance, ray1, [0.3], graphic);
            Ray.maybeAddArrows(appearance, ray2, [0.7], graphic);
        }
        return {
            graphic: graphic,
            pivot: mirror.bounds.center,
            hitShape: mirror.rectHitShape(),
        };
    };
    Mirror.properties = {
        label: "Mirror",
        flippable: true,
        defaultAppearance: __assign(__assign(__assign({ size: 250 }, Ray.defaultAppearanceRayOff), Ray.defaultAppearanceArrow), { incidentAngleD: 45, incidentLength: 140, emergentLength: 80 }),
        appearanceSpecs: __spreadArray(__spreadArray(__spreadArray([
            {
                spec: "slider", key: "size", label: "Size",
                min: 50, max: 1000, unit: "mm",
            }
        ], Ray.specs), [
            {
                spec: "slider", key: "incidentAngleD", label: "Angle of incidence",
                min: 0, max: 89, visible: function (a) { return a.showRay; }, unit: "°", pipsStep: 45
            },
            {
                spec: "slider", key: "incidentLength", label: "Incident ray",
                min: 1, max: 300, visible: function (a) { return a.showRay; }
            },
            {
                spec: "slider", key: "emergentLength", label: "Emergent ray",
                min: 1, max: 300, visible: function (a) { return a.showRay; }
            }
        ]), Ray.arrowSpecs),
    };
    return Mirror;
}());
export { Mirror };
var CurvedMirror = /** @class */ (function () {
    function CurvedMirror() {
        this.drivers = [
            {
                type: "rectangle",
                minX: CurvedMirror_1.MIN_FOCAL_LENGTH, maxX: CurvedMirror_1.MAX_FOCAL_LENGTH,
                minY: 0, maxY: 0,
                fromAppearance: function (a) { return P(a.focalLength, 0); },
                toAppearance: function (f, p) { return f({ focalLength: p.x }); },
                scale: 1.0,
                visible: function (a) { return a.type === "convex"; }
            },
            {
                type: "rectangle",
                maxX: -CurvedMirror_1.MIN_FOCAL_LENGTH, minX: -CurvedMirror_1.MAX_FOCAL_LENGTH,
                minY: 0, maxY: 0,
                fromAppearance: function (a) { return P(-a.focalLength, 0); },
                toAppearance: function (f, p) { return f({ focalLength: -p.x }); },
                scale: 1.0,
                visible: function (a) { return a.type === "concave"; }
            },
        ];
    }
    CurvedMirror_1 = CurvedMirror;
    CurvedMirror.prototype.render = function (appearance) {
        var size = 150;
        var focalLength = appearance.focalLength;
        var twoF = focalLength * 2;
        // 0, 0 should be the mirror's surface on the axis line.
        var center = P(appearance.type == "concave" ? -twoF : twoF, 0);
        var angle = Math.asin(size / 2 / twoF).toDegrees();
        var from = P(0, 0).rotate(-angle, center);
        var to = P(0, 0).rotate(angle, center);
        var surface = new Path.Arc(from, P(0, 0), to).withStroke("thick");
        // Shade it.
        var shadeThickness = 10;
        var shadeMask = surface.clone().withFill("red");
        var surface2 = new Path.Arc(P(shadeThickness, 0).rotate(angle, center), P(shadeThickness, 0), P(shadeThickness, 0).rotate(-angle, center));
        shadeMask.addSegments(surface2.segments);
        surface2.remove();
        shadeMask.close();
        var shade = new Group([shadeMask]);
        shade.clipped = true;
        shadeMask.clipMask = true;
        if (appearance.type === "concave") {
            for (var y = -size / 2 - 20; y < size / 2 + 20; y += 10) {
                shade.addChild(hashSymbolLong.value.place(P(from.x, y)));
            }
        }
        else {
            for (var y = -size / 2 - 50; y < size / 2; y += 10) {
                shade.addChild(hashSymbolLong.value.place(P(0, y)));
            }
        }
        var rays = new Group([]);
        var emergentRayDistance = 100;
        if (appearance.showRay) {
            for (var y = -50; y <= 50; y += 25) {
                var ray = new Path([[-120, y], [100, y]]);
                var ints = surface.getIntersections(ray);
                if (ints.length != 1)
                    continue;
                ray.segments[1].point = ints[0].point;
                if (appearance.type == "concave") {
                    // Line to focal point.
                    ray.lineTo(P(-focalLength, 0));
                }
                else {
                    // Line from focal point.
                    var emergentRayN = ints[0].point.subtract(P(focalLength, 0)).normalize();
                    ray.lineBy(emergentRayN.multiply(emergentRayDistance));
                    // Maybe add dotted line.
                    if (appearance.dottedLine) {
                        var ray2 = new Path([[focalLength, 0], ints[0].point]);
                        ray2.dashArray = [appearance.rayThickness, appearance.rayThickness * 2];
                        Ray.applyStyle(ray2, appearance);
                        rays.addChild(ray2);
                    }
                }
                Ray.applyStyle(ray, appearance);
                rays.addChild(ray);
                Ray.maybeAddArrows(appearance, ray, [0.2, 0.3], rays);
            }
        }
        // Assemble.
        var graphic = new Group([rays, shade, surface]);
        return {
            graphic: graphic,
            pivot: P(0, 0),
            hitShape: surface.rectHitShape(),
        };
    };
    var CurvedMirror_1;
    CurvedMirror.MIN_FOCAL_LENGTH = 40;
    CurvedMirror.MAX_FOCAL_LENGTH = 250;
    CurvedMirror.properties = {
        label: "Curved mirror",
        defaultAppearance: __assign(__assign(__assign({ type: "concave", focalLength: 60 }, Ray.defaultAppearanceRayOff), Ray.defaultAppearanceArrow), { dottedLine: false }),
        appearanceSpecs: __spreadArray(__spreadArray([
            {
                spec: "toggle-buttons", key: "type", label: "Type", size: "text",
                options: [
                    { value: "concave", label: "Concave" },
                    { value: "convex", label: "Convex" },
                ],
            },
            {
                spec: "slider", key: "focalLength", label: "Focal length",
                min: CurvedMirror_1.MIN_FOCAL_LENGTH, max: CurvedMirror_1.MAX_FOCAL_LENGTH, unit: "mm",
            }
        ], Ray.specs), [
            {
                spec: "checkbox-group", label: "Show",
                checkboxes: [
                    { key: "dottedLine", label: "Virtual rays", visible: function (a) { return a.showRay && a.type === "convex"; } },
                    { key: "showRayArrow", label: "Arrows", visible: function (a) { return a.showRay; } },
                ]
            }
        ]),
    };
    CurvedMirror = CurvedMirror_1 = __decorate([
        staticImplements()
    ], CurvedMirror);
    return CurvedMirror;
}());
export { CurvedMirror };
var hashSymbol = new Lazy(function () {
    var hash = new Path.Line(P(0, 0), P(8, 8)).withStroke("thin", "#666666");
    hash.pivot = P(0, 0);
    return new Symbol(hash, false);
});
var hashSymbolLong = new Lazy(function () {
    var hash = new Path.Line(P(0, 0), P(70, 70)).withStroke("thin", "#666666");
    hash.pivot = P(0, 0);
    return new Symbol(hash, false);
});
