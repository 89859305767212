var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
/**
 * Returns a shallow copy of the input, with applied reorderings.
 * Both "from" and "to" arguments MUST be in increasing order.
 */
export function reorder(input, from, to) {
    var copy = __spreadArray([], input);
    var toMove = [];
    for (var i = 0; i < from.length; i++) {
        toMove[i] = copy.splice(from[i] - i, 1)[0];
    }
    for (var i = 0; i < from.length; i++) {
        copy.splice(to[i], 0, toMove[i]);
    }
    return copy;
}
export function createSendToBackIndices(indices) {
    var c = [];
    for (var i = 0; i < indices.length; i++) {
        c[i] = i;
    }
    return c;
}
export function createMoveToFrontIndices(indices, length) {
    var c = [];
    for (var i = 0; i < indices.length; i++) {
        c[i] = length - indices.length + i;
    }
    return c;
}
export function createMoveForwardIndices(indices, length) {
    var max = indices[indices.length - 1];
    return createMoveToFrontIndices(indices, Math.min(max + 2, length));
}
export function createSendBackwardIndices(indices) {
    var min = Math.max(0, indices[0] - 1);
    var c = [];
    for (var i = 0; i < indices.length; i++) {
        c[i] = min + i;
    }
    return c;
}
