var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { mirrorX } from "apparatus/draw";
import { INFINITE_BOUNDS } from "apparatus/driver";
import { createApparatus } from "apparatus/library";
import { P, Segments } from "apparatus/library/common";
import { BasicShapes } from "apparatus/library/shapes/common";
import Flags from "flags";
import { CompoundPath, Group, Path } from "paper";
var Curve = /** @class */ (function () {
    function Curve() {
        this.shouldUseBoundsCenter = true;
        this.drivers = Curve.drivers;
    }
    Curve.prototype.render = function (a) {
        var _a = drawCurve(a), path = _a.path, xray = _a.xray;
        BasicShapes.StrokeOnly.apply(path, a);
        return {
            graphic: path,
            hitShape: { type: "stroke", path: path.clone() },
            xray: xray,
            pivot: P(0, 0),
            snapping: !Flags.isShapeSnappingEnabled ? undefined : [
                { type: "shape_end", at: P(a.end1x, a.end1y) },
                { type: "shape_end", at: P(a.end2x, a.end2y) },
            ]
        };
    };
    Curve.properties = {
        label: "Curve",
        defaultAppearance: __assign({ end1x: -150, end1y: 0, pivot1x: -40, pivot1y: -80, end2x: 200, end2y: 0, pivot2x: 140, pivot2y: 40 }, BasicShapes.StrokeOnly.defaultAppearance),
        appearanceSpecs: __spreadArray([], BasicShapes.StrokeOnly.specs),
        actions: [
            {
                type: "appearance", label: "Straighten", icon: "pipe",
                action: function (a) {
                    // Determine the direction from end1 to end2
                    var p1 = P(a.end1x, a.end1y);
                    var p2 = P(a.end2x, a.end2y);
                    var delta = p2.subtract(p1);
                    delta.length = 50; // Set distance of the handles from their parent node.
                    var pivot1 = p1.add(delta);
                    var pivot2 = p2.subtract(delta);
                    return {
                        pivot1x: pivot1.x,
                        pivot1y: pivot1.y,
                        pivot2x: pivot2.x,
                        pivot2y: pivot2.y,
                    };
                }
            },
            {
                type: "replace", label: "Convert to line", icon: "horizontal-rule",
                action: function (a, p) {
                    var apparatus = createApparatus("line", p);
                    var line = {
                        end1x: a.end1x,
                        end1y: a.end1y,
                        end2x: a.end2x,
                        end2y: a.end2y,
                        dashed: apparatus.appearance["dashed"],
                        stroke: a.stroke,
                        strokeThickness: a.strokeThickness,
                    };
                    apparatus.appearance = line;
                    return apparatus;
                }
            }
        ]
    };
    Curve.drivers = [
        __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ end1x: p.x, end1y: p.y }); }, fromAppearance: function (a) { return P(a.end1x, a.end1y); }, scale: 1.0, snapping: !Flags.isShapeSnappingEnabled ? undefined
                : function (_, point) { return ({ type: "shape_end", at: point }); } }),
        __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ pivot1x: p.x, pivot1y: p.y }); }, fromAppearance: function (a) { return P(a.pivot1x, a.pivot1y); }, scale: 1.0 }),
        __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ end2x: p.x, end2y: p.y }); }, fromAppearance: function (a) { return P(a.end2x, a.end2y); }, scale: 1.0, snapping: !Flags.isShapeSnappingEnabled ? undefined
                : function (_, point) { return ({ type: "shape_end", at: point }); } }),
        __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ pivot2x: p.x, pivot2y: p.y }); }, fromAppearance: function (a) { return P(a.pivot2x, a.pivot2y); }, scale: 1.0 }),
    ];
    return Curve;
}());
export { Curve };
var CurvedArrow = /** @class */ (function () {
    function CurvedArrow() {
        this.shouldUseBoundsCenter = true;
        this.drivers = Curve.drivers;
    }
    CurvedArrow.prototype.render = function (a) {
        var _a = drawCurve(a), path = _a.path, xray = _a.xray;
        var graphic = new Group([path]);
        var drawEndArrow = a.arrows === "both" || a.arrows === "end";
        var drawStartArrow = a.arrows === "both" || a.arrows === "start";
        if (drawEndArrow) {
            graphic.addChild(drawArrowHead(path.segments[1], "handleIn", a.size));
        }
        if (drawStartArrow) {
            graphic.addChild(drawArrowHead(path.segments[0], "handleOut", a.size));
        }
        BasicShapes.StrokeOnly.apply(graphic, a);
        return {
            graphic: graphic,
            hitShape: { type: "stroke", path: path.clone() },
            xray: xray,
            pivot: P(0, 0),
            snapping: !Flags.isShapeSnappingEnabled ? undefined : [
                { type: "shape_end", at: P(a.end1x, a.end1y) },
                { type: "shape_end", at: P(a.end2x, a.end2y) },
            ]
        };
    };
    CurvedArrow.properties = {
        label: "Curved Arrow",
        defaultAppearance: __assign(__assign({}, Curve.properties.defaultAppearance), { arrows: "end", size: 15 }),
        appearanceSpecs: __spreadArray(__spreadArray([], Curve.properties.appearanceSpecs), [
            BasicShapes.header,
            {
                spec: "toggle-buttons",
                size: "icon",
                key: "arrows",
                label: "Arrows",
                options: [
                    { icon: "long-arrow-left", label: "Start", value: "start" },
                    { icon: "long-arrow-right", label: "End", value: "end" },
                    { icon: "arrows-h", label: "Both", value: "both" },
                ]
            },
            {
                spec: "slider", key: "size", label: "Arrow size",
                min: 10, max: 50
            }
        ]),
    };
    return CurvedArrow;
}());
export { CurvedArrow };
var StirArrow = /** @class */ (function () {
    function StirArrow() {
    }
    StirArrow.prototype.render = function (a) {
        var path = new Path(Segments([[10, 0], , [19, 2]], [[16, 20], [18, -5], [-8, 2]]));
        mirrorX(path);
        var drawEndArrow = a.arrows === "both" || a.arrows === "end";
        var drawStartArrow = a.arrows === "both" || a.arrows === "start";
        var graphic = new Group([path]);
        graphic.scale(a.size / 50);
        if (drawEndArrow) {
            graphic.addChild(drawArrowHead(path.segments[path.segments.length - 1], "handleIn", 10));
        }
        if (drawStartArrow) {
            graphic.addChild(drawArrowHead(path.segments[0], "handleOut", 10));
        }
        BasicShapes.StrokeOnly.apply(graphic, a);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            pivot: path.bounds.center,
        };
    };
    StirArrow.properties = {
        label: "Stirring motion arrow",
        defaultAppearance: {
            arrows: "both",
            size: 50,
            stroke: "#777777",
            strokeThickness: 3.0,
        },
        appearanceSpecs: __spreadArray(__spreadArray([], BasicShapes.StrokeOnly.specs), [
            BasicShapes.header,
            {
                spec: "toggle-buttons",
                size: "icon",
                key: "arrows",
                label: "Arrows",
                options: [
                    { icon: "long-arrow-right", label: "Clockwise", value: "end" },
                    { icon: "long-arrow-left", label: "Anti-clockwise", value: "start" },
                    { icon: "arrows-h", label: "Both", value: "both" },
                ]
            },
            {
                spec: "slider", key: "size", label: "Size",
                min: 30, max: 120
            }
        ]),
    };
    return StirArrow;
}());
export { StirArrow };
export function drawCurve(a, opts) {
    var _a;
    var joinPivots = (_a = opts === null || opts === void 0 ? void 0 : opts.joinPivots) !== null && _a !== void 0 ? _a : true;
    var path = new Path(Segments([[a.end1x, a.end1y], , [a.pivot1x - a.end1x, a.pivot1y - a.end1y]], [[a.end2x, a.end2y], [a.pivot2x - a.end2x, a.pivot2y - a.end2y]]));
    var xray = new CompoundPath({});
    xray.moveTo(P(a.end1x, a.end1y));
    xray.lineTo(P(a.pivot1x, a.pivot1y));
    if (joinPivots) {
        xray.lineTo(P(a.pivot2x, a.pivot2y));
    }
    else {
        xray.moveTo(P(a.pivot2x, a.pivot2y));
    }
    xray.lineTo(P(a.end2x, a.end2y));
    return { path: path, xray: xray };
}
export function drawArrowHead(segment, normalKey, size, opts) {
    var _a;
    var normal = segment[normalKey].normalize();
    var adjustAngle = (_a = opts === null || opts === void 0 ? void 0 : opts.adjustAngle) !== null && _a !== void 0 ? _a : 0;
    var p1 = normal.rotate(45 + adjustAngle).multiply(size).add(segment.point);
    var p2 = normal.rotate(-45 + adjustAngle).multiply(size).add(segment.point);
    return new Path([p1, segment.point, p2]);
}
