import { mirrorX, mirrorY } from "apparatus/draw";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, PointText } from "paper";
import { S } from "../common";
var DEFAULT_YELLOW = "#f5c50a";
var PH_COLORS_1 = [
    "#cb3b53",
    "#dc4949",
    "#e04d2f",
    "#d56d47",
    "#d18c34",
    "#d29d23",
    "#b39541",
    "#757a3b",
    "#3e5248",
    "#30323c",
    "#343242",
    "#393157",
    "#352342",
    "#3f254b", /*14*/
];
var PhPaper = /** @class */ (function () {
    function PhPaper() {
    }
    PhPaper.prototype.render = function (appearance) {
        var dipped = appearance.dipped, ph = appearance.ph, scale = appearance.scale, length = appearance.length;
        var graphic = new Path.Rectangle(R(0, 0, 15, length));
        if (dipped) {
            var phColor = PH_COLORS_1[ph - 1].color();
            var front = 1 - appearance.front;
            graphic.withGradientFill("down", [
                [DEFAULT_YELLOW, front],
                [phColor.ladd(-10).string(), front + 0.01],
                [phColor.ladd(5).string(), front + 0.05],
                [phColor.string(), front + 0.1],
            ]);
        }
        else {
            graphic.withFill(DEFAULT_YELLOW);
        }
        graphic.scale(scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(15).toShape()
        };
    };
    PhPaper.properties = {
        label: "pH Paper Strip",
        defaultAppearance: {
            dipped: false,
            ph: 5,
            front: 0.4,
            length: 70,
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "length", label: "Length",
                min: 30, max: 100, unit: "mm",
            },
            { spec: "checkbox", key: "dipped", label: "Dipped" },
            {
                spec: "slider", key: "ph", label: "pH",
                min: 1, max: 14, step: 1, visible: function (a) { return a.dipped; }
            },
            {
                spec: "slider", key: "front", label: "Front distance",
                min: 0.2, max: 0.7, step: 0.01, unit: "%", visible: function (a) { return a.dipped; }
            },
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 3.0, step: 0.1, unit: "%",
            }
        ],
    };
    return PhPaper;
}());
export { PhPaper };
var PhPaperPack = /** @class */ (function () {
    function PhPaperPack() {
    }
    PhPaperPack.prototype.render = function (appearance) {
        switch (appearance.type) {
            case "roll": return this.renderRoll(appearance);
            case "book": return this.renderBook(appearance);
        }
    };
    PhPaperPack.prototype.renderBook = function (appearance) {
        var scale = appearance.scale, showLabels = appearance.showLabels;
        var outsideCase = new Path.Rectangle(R(0, 0, 148, 45, Pivot.TOP_CENTER), 2.0)
            .close()
            .withDefaultStyle()
            .withGradientFill("right", [["#999999", 0.0], ["#eeeeee", 0.08]]);
        var innerFlap = new Path(Segments([[-74, 2]], [[-64, 5]], [[-5, 5]])).close().withDefaultStyle()
            .withGradientFill("right", [["#eeeeee", 0.14], ["#dddddd", 0.15]]);
        mirrorY(innerFlap, 45 / 2);
        var innerPaper1 = new Path.Rectangle(R(0, 7, 20, 14, Pivot.TOP_RIGHT)).withFill(DEFAULT_YELLOW);
        var innerPaper2 = new Path.Rectangle(R(0, 24, 20, 14, Pivot.TOP_RIGHT)).withFill(DEFAULT_YELLOW);
        var xStep = 60 / 7;
        var sampleHeight = 16;
        var sample = new Path.Rectangle(R(0, 1, xStep - 1, sampleHeight)).withFill(PH_COLORS_1[0]);
        var graphic = new Group([outsideCase, innerPaper1, innerPaper2, innerFlap, sample]);
        for (var i = 0; i < 14; i++) {
            var x = Math.floor(i / 2) * xStep + 15;
            var dy = (i % 2 == 0) ? 0 : 45 - 2 - sampleHeight;
            var ty = (i % 2 == 0) ? 20 : 27;
            // Label
            if (showLabels) {
                graphic.addChild(new PointText({
                    point: P(x, ty),
                    content: (i + 1).toString(),
                    fillColor: "black",
                    fontFamily: 'sans-serif',
                    fontSize: 3,
                    justification: "center",
                }));
            }
            if (i > 0) {
                var cloneSample = sample.clone().withFill(PH_COLORS_1[i]);
                cloneSample.position.x = x;
                cloneSample.position.y += dy;
                graphic.addChild(cloneSample);
            }
            else {
                sample.position.x = x;
            }
        }
        graphic.scale(scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(10).toShape()
        };
    };
    PhPaperPack.prototype.renderRoll = function (appearance) {
        var scale = appearance.scale, showLabels = appearance.showLabels;
        var outsideCase = new Path.Circle(P(0, 0), 30.0)
            .withDefaultStyle();
        outsideCase.setRadialGradientFill([
            ["#f0f0f0", 0.3],
            ["#eeeeee", 0.9],
            ["#bbbbbb", 1.0],
        ]);
        var angleStep = 360 / 14;
        var sample = new Path([
            S(P(0.0, -27.0).rotate(angleStep / 2 - 2), P(-2, -1.3)),
            S(P(0.0, -16.0).rotate(angleStep / 2 - 2))
        ]).withFill(PH_COLORS_1[0]).close();
        mirrorX(sample);
        var graphic = new Group([outsideCase, sample]);
        for (var i = 0; i < 14; i++) {
            // Label
            if (showLabels) {
                graphic.addChild(new PointText({
                    point: P(0, -13).rotate(angleStep * i).add([0, 1.2]),
                    content: (i + 1).toString(),
                    fillColor: "black",
                    fontFamily: 'sans-serif',
                    fontSize: 3,
                    justification: "center",
                }));
            }
            if (i > 0) {
                var cloneSample = sample.clone().withFill(PH_COLORS_1[i]);
                cloneSample.rotate(angleStep * i, P(0, 0));
                graphic.addChild(cloneSample);
            }
        }
        graphic.scale(scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(10).toShape()
        };
    };
    PhPaperPack.properties = {
        label: "pH Paper Pack",
        defaultAppearance: {
            type: "roll",
            scale: 1.0,
            showLabels: false,
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "type", label: "Type",
                options: [
                    { value: "roll", label: "Roll" },
                    { value: "book", label: "Booklet" },
                ],
            },
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 1.0, max: 3.0, step: 0.1, unit: "%"
            },
            { spec: "checkbox", key: "showLabels", label: "Show pH labels" }
        ],
    };
    return PhPaperPack;
}());
export { PhPaperPack };
