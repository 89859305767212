var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Tippy from "@tippyjs/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Key } from "./keyboard_shortucts";
export var Hint = function (_a) {
    var text = _a.text, shortcut = _a.shortcut, shortcut2 = _a.shortcut2;
    var t = useTranslation().t;
    return React.createElement(React.Fragment, null,
        text && React.createElement("span", { dangerouslySetInnerHTML: { __html: text } }),
        shortcut &&
            React.createElement("div", { className: "shortcut" },
                t('keyboard_shortcut'),
                ":\u00A0",
                React.createElement(Key, { k: shortcut.key, ctrl: shortcut.ctrl }),
                shortcut2 && React.createElement(React.Fragment, null,
                    " ",
                    t('or'),
                    " ",
                    React.createElement(Key, { k: shortcut2.key, ctrl: shortcut2.ctrl }))));
};
/** custom version of Tippy that hides itself if content is empty */
export var Tip = function (props) { return props.content ? React.createElement(Tippy, __assign({}, props), props.children) : React.createElement(React.Fragment, null, props.children); };
/** Allows raw html to be passed. */
export var TipHtml = function (props) {
    return props.content
        ? React.createElement(Tippy, __assign({}, props, { content: React.createElement("span", { dangerouslySetInnerHTML: { __html: props.content } }) }), props.children)
        : React.createElement(React.Fragment, null, props.children);
};
export var BetaTag = function (_a) {
    var _b = _a.extraClass, extraClass = _b === void 0 ? "" : _b;
    return React.createElement("div", { className: "badge badge-secondary " + extraClass }, "BETA");
};
/** Converts \n to <br/> */
export var Nl = function (_a) {
    var children = _a.children;
    return React.createElement("span", { style: { whiteSpace: "pre-line" } }, children);
};
