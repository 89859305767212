import { CompoundPath, Group, Path, Rectangle, Shape } from "paper";
import { addMarkers, setDefaultStyle } from "../draw";
import { lerp, markerSpacing, P } from "./common";
var Thermometer = /** @class */ (function () {
    function Thermometer() {
    }
    Thermometer.prototype.measurements = function (size) {
        switch (size) {
            case "tiny":
                return { stemWidth: 6, bulbRadius: 6, mercuryBulbGap: 2.0, mercuryStemGap: 0.0, markerSize: 4, };
            case "small":
                return { stemWidth: 8, bulbRadius: 6, mercuryBulbGap: 2.0, mercuryStemGap: 0.0, markerSize: 6 };
            case "medium":
                return { stemWidth: 11, bulbRadius: 9, mercuryBulbGap: 3.5, mercuryStemGap: 1.0 };
            case "large":
                return { stemWidth: 15, bulbRadius: 12, mercuryBulbGap: 5.0, mercuryStemGap: 2.0 };
        }
    };
    Thermometer.prototype.render = function (appearance) {
        var stemHeight = appearance.length;
        var _a = this.measurements(appearance.size), stemWidth = _a.stemWidth, bulbRadius = _a.bulbRadius, mercuryBulbGap = _a.mercuryBulbGap, mercuryStemGap = _a.mercuryStemGap, markerSize = _a.markerSize;
        var glassColor = "#ffffffb0";
        var mercuryColor = "#f43d50";
        var mercuryAmountRatio = appearance.mercuryAmountRatio;
        // Draw glassware.
        var stem = new Path.Rectangle(new Rectangle(-stemWidth / 2, 0, stemWidth, stemHeight), stemWidth / 2);
        var bulb = new Path.Circle(P(0, stemHeight), bulbRadius);
        var body = new CompoundPath(stem).unite(bulb);
        setDefaultStyle(body);
        body.fillColor = glassColor;
        // Draw mercury.
        var mercuryBulb = new Path.Circle(P(0, stemHeight), bulbRadius - mercuryBulbGap);
        mercuryBulb.fillColor = mercuryColor;
        var mercuryStem = new Path.Line(P(0, lerp(stemHeight, stemWidth / 2, mercuryAmountRatio)), P(0, stemHeight));
        mercuryStem.strokeColor = mercuryColor;
        mercuryStem.strokeWidth = stemWidth / 2.0 - mercuryStemGap;
        mercuryStem.strokeCap = "round";
        // Assemble.
        var graphic = new Group([body]);
        addMarkers(graphic, P(stemWidth / 2, stemHeight), stemWidth / 2, P(0, markerSpacing), markerSize);
        graphic.addChildren([mercuryStem, mercuryBulb]);
        // Set up hit shape.
        var hitShape = Shape.Rectangle(new Rectangle(-bulbRadius, 0, bulbRadius * 2, stemHeight + bulbRadius));
        return {
            graphic: graphic,
            hitShape: hitShape,
        };
    };
    Thermometer.properties = {
        label: "Thermometer",
        defaultAppearance: {
            length: 180,
            size: "large",
            mercuryAmountRatio: 0.3,
        },
        appearanceSpecs: [
            {
                spec: "slider",
                key: "length",
                label: "Length",
                min: 100,
                max: 320,
            },
            {
                spec: "slider",
                key: "mercuryAmountRatio",
                label: "Temperature",
                min: 0.0,
                max: 1.0,
                step: 0.05,
            },
            {
                spec: "toggle-buttons",
                key: "size",
                label: "Overall size",
                size: "text",
                options: [
                    { value: "tiny", label: "XS", tier: "boost" },
                    { value: "small", label: "S" },
                    { value: "medium", label: "M", tier: "boost" },
                    { value: "large", label: "L" },
                ],
                allowSelectingTierLockedOptions: true,
            },
        ],
        isBoostRequired: function (a) { return a.size == "medium" || a.size == "tiny"; }
    };
    return Thermometer;
}());
export { Thermometer };
