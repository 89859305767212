// Wraper around <input type="text" />
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TextReplacements } from "components/text_replacements";
import * as React from "react";
var TextAreaInput = /** @class */ (function (_super) {
    __extends(TextAreaInput, _super);
    function TextAreaInput(props) {
        var _this = _super.call(this, props) || this;
        _this.ref = React.createRef();
        _this.state = { text: _this.props.text };
        return _this;
    }
    TextAreaInput.prototype.componentWillUnmount = function () {
        this.props.onEnd(this.props.text, this.state.text);
    };
    TextAreaInput.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        this.setState({ text: nextProps.text });
    };
    TextAreaInput.prototype.onChange = function (event) {
        var value = event.target.value;
        this.setState({ text: event.target.value });
        this.props.onChange(value);
    };
    TextAreaInput.prototype.onKeyPress = function (event) {
        if (this.props.enableReplacements === true)
            TextReplacements.onKeyPress(this, event);
    };
    TextAreaInput.prototype.onKeyDown = function (event) {
        if (this.props.enableReplacements === true)
            TextReplacements.onKeyDown(this, event);
    };
    TextAreaInput.prototype.manuallySetText = function (newText, newCursorPosition) {
        var _this = this;
        this.setState({ text: newText }, function () { return _this.ref.current.setSelectionRange(newCursorPosition, newCursorPosition); });
        this.props.onChange(newText);
    };
    TextAreaInput.prototype.onBlur = function (event) {
        this.props.onEnd(this.props.text, this.state.text);
    };
    TextAreaInput.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("textarea", { value: this.state.text, rows: (_a = this.props.rows) !== null && _a !== void 0 ? _a : 8, className: "ui-input", onFocus: function (e) { var _a; return (_a = _this.props.onFocus) === null || _a === void 0 ? void 0 : _a.call(null); }, onChange: function (e) { return _this.onChange(e); }, onKeyPress: function (e) { return _this.onKeyPress(e); }, onKeyDown: function (e) { return _this.onKeyDown(e); }, onBlur: function (e) { return _this.onBlur(e); }, ref: this.ref, placeholder: this.props.placeholder });
    };
    return TextAreaInput;
}(React.Component));
export { TextAreaInput };
