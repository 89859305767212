var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { smoothCornersIndividual } from "apparatus/draw";
import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
import { staticImplements } from "types";
var Spectronic20 = /** @class */ (function () {
    function Spectronic20() {
    }
    Spectronic20.prototype.render = function (appearance) {
        var casing = new Path(Segments([[147, 0]], [[150, 150 * 0.47]], [[160, 150 * 0.60]], [[160, 150]])).mirrorX().close()
            .withStroke("default", SharedColors.stroke)
            .withGradientFill("down", [
            ["#F0F2F4", 0.0],
            ["#E8EAEC", 0.45],
            ["#F2F4F6", 0.47],
            ["#E0E0E0", 0.49],
            ["#E3E3E5", 0.58],
            ["#F2F4F6", 0.60],
            ["#C0BFC2", 0.62],
            ["#C0BFC2", 1.00],
        ]);
        smoothCornersIndividual(casing, { 0: 5, 3: 5, 4: 5, 7: 5 });
        var knob1 = new Path.Circle(P(-50, 120), 13)
            .withFill("#444444").withStroke("default", "#222222");
        var knob2 = knob1.clone().withPosition({ x: 50 });
        var button1 = new Path.Rectangle(R(-80, 70, 25, 15), 2.0)
            .withDefaultStyle().withGradientFill("down", [["#777777", 0.4], ["#555555", 0.7]]);
        var knob3 = new Path(Segments([[13, 0], [0, -5]], [[13, 12], , [0, 5]])).close().mirrorX().withStroke("default", SharedColors.stroke)
            .withGradientFill("down", [["#777777", 0.4], ["#555555", 0.7]])
            .withPosition({ x: 80, y: 75 });
        var panel = new Path.Rectangle(R(0, 10, 200, 55, Pivot.TOP_CENTER), 2.0)
            .withStroke("default", SharedColors.stroke)
            .withFill("#3a3a3a");
        var screen = Shape.Rectangle(R(0, 15, 100, 45, Pivot.TOP_CENTER), 2.0)
            .withStroke("default", SharedColors.stroke)
            .withFill("#F1FBFD");
        var scale = new Path(Segments([[45, 45], , [-15, -20]])).mirrorX().withStroke(4.0, "black");
        var gaugeMask = screen.clone();
        var gauge = new Path.Line(P(0, 60), P(0, 18)).withStroke("thin", "red");
        // Angle range is [-50, 50]
        gauge.rotate(16, P(0, 60));
        var gaugeGroup = new Group([gaugeMask, gauge]);
        gaugeGroup.clipped = true;
        var light = new Path.Circle(P(-75, 38), 3.0).withStroke("thin", "#830000").withFill("#ad0000");
        var graphic = new Group([casing, knob1, knob2, button1, knob3, panel, screen, scale, gaugeGroup, light]);
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: graphic.placeable(),
            watermark: graphic.bounds.center,
        };
    };
    Spectronic20.properties = {
        label: "Spectronic 20",
        defaultAppearance: {
            scale: 1
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.6, max: 1.2, step: 0.01, unit: "%",
            }
        ],
    };
    Spectronic20 = __decorate([
        staticImplements()
    ], Spectronic20);
    return Spectronic20;
}());
export { Spectronic20 };
