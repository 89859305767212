var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { addGroundJoint, breakPathClosed, glassFold, setDefaultStyle, smoothCornersIndividual } from "apparatus/draw";
import { Segments } from "apparatus/library/common";
import { Path } from "paper";
import { staticImplements } from "types";
var ClaisenHead = /** @class */ (function () {
    function ClaisenHead() {
    }
    ClaisenHead.prototype.render = function (_a) {
        var type = _a.type, gap = _a.gap, length = _a.length;
        var ay = length - 20;
        var path = (function () {
            switch (type) {
                case "3-way":
                    return new Path(Segments([[-8, -25 - ay]], [[8, -25 - ay]], 
                    // Start of the bridge (top part)
                    [[8, -8]], [[36, -17]], 
                    // End of the bridge (top part)
                    [[36, -42 - ay]], [[52, -42 - ay]], 
                    // Start of the bridge (top part)
                    [[52, -4]], [[8, 10]], 
                    // End of the bridge (top part)
                    [[8, 25 + ay]], [[-8, 25 + ay]])).close();
                case "4-way":
                    return new Path(Segments([[-8, -25 - ay]], [[8, -25 - ay]], [[8, -8]], [[36, -17]], [[36, -42 - ay]], [[52, -42 - ay]], [[52, -22]], [[76, -15]], [[72, -1]], [[50, -7]], [[42, -1]], [[8, 10]], [[8, 25 + ay]], [[-8, 25 + ay]])).close();
            }
        })();
        // Lengthen gap
        // Gradient of the middle bridge is:
        // -17 - (-8)    -9
        // ----------  = -- = -0.3214285714
        //   36 - 8      28                     <--- min of the slider!
        var dx = gap - 30;
        var dy = dx * -0.3214285714;
        if (type === "3-way") {
            for (var i = 3; i < 7; i++) {
                path.segments[i].point.x += dx;
                path.segments[i].point.y += dy;
            }
        }
        else {
            for (var i = 3; i < 11; i++) {
                path.segments[i].point.x += dx;
                path.segments[i].point.y += dy;
            }
        }
        // Smoothen
        if (type === "3-way") {
            smoothCornersIndividual(path, { 2: 5, 3: 5, 6: 10, 7: 6 });
        }
        else {
            smoothCornersIndividual(path, { 2: 5, 3: 5, 6: 5, 9: 3, 10: 3, 11: 6 });
        }
        // Add ground joints
        var j1 = addGroundJoint(path, { type: "inlet", index: 0, facing: 0 });
        var j2 = addGroundJoint(path, { type: "inlet", index: 10, facing: 0 });
        var j3 = addGroundJoint(path, { type: "outlet", index: path.segments.length - 2, facing: 180 });
        var snapping = [j1.snapping, j2.snapping, j3.snapping];
        if (type === "4-way") {
            var j4 = addGroundJoint(path, { type: "outlet", index: 18, facing: 105 });
            snapping.push(j4.snapping);
        }
        // Break path.
        var graphic;
        if (type === "3-way") {
            graphic = breakPathClosed(path, 3, 13, 23);
        }
        else {
            graphic = breakPathClosed(path, 3, 13, 21, 33);
        }
        setDefaultStyle(graphic);
        // Add ground joints folds.
        graphic.addChildren([
            glassFold(0, -25 - ay, { size: 6 }),
            glassFold(44 + dx, -42 + dy - ay, { size: 6 }),
            glassFold(8, 0, { rotation: 90, size: 8 }),
            glassFold(0, 30 + ay), // left bottom arm
        ]);
        if (type === "4-way") {
            graphic.addChild(glassFold(48 + dx, -15 + dy, { rotation: 105, size: 8 }));
            graphic.addChild(glassFold(73 + dx, -8 + dy, { rotation: 105, size: 6 }));
        }
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: snapping,
        };
    };
    ClaisenHead.properties = {
        label: "Claisen head",
        flippable: true,
        defaultAppearance: {
            type: "3-way",
            gap: 28,
            length: 20
        },
        appearanceSpecs: [
            {
                spec: "toggle-buttons", label: "Type", key: "type", size: "text", options: [
                    { value: "3-way", label: "3-way", },
                    { value: "4-way", label: "4-way", }
                ],
            },
            {
                spec: "slider", key: "gap", label: "Gap",
                min: 30, max: 70, // 30 is the dx of the bridge size.
            },
            {
                spec: "slider", key: "length", label: "Length",
                min: 20, max: 50, // Arbitrary start at 20
            },
        ],
    };
    ClaisenHead = __decorate([
        staticImplements()
    ], ClaisenHead);
    return ClaisenHead;
}());
export { ClaisenHead };
