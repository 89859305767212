import { ID } from "store/id";
import store from "store/store";
/** Returns the ID of the root group for given item. */
export function getRootGroup(groupOrId) {
    var group;
    if (isID(groupOrId)) {
        group = store.groups().filter(ID.matching(groupOrId))[0];
        if (!group) {
            console.error("Group could not be found!");
        }
    }
    else {
        group = groupOrId;
    }
    // Iterate up the chain, to the root group.
    while (group.parentGroup) {
        group = store.groups().filter(ID.matching(group.parentGroup))[0];
    }
    return group;
}
function isID(arg) {
    return typeof arg.id === "number";
}
/**
 * Returns IDs of all descendants of a given group, except for
 * other groups, which are unpacked.
 */
export function getDescendantIDs(group) {
    var ids = [];
    for (var _i = 0, _a = group.items; _i < _a.length; _i++) {
        var id = _a[_i];
        if (id.type === "group") {
            var childGroup = store.groups().filter(ID.matching(id))[0];
            ids.push.apply(ids, getDescendantIDs(childGroup));
        }
        else {
            ids.push(id);
        }
    }
    return ids;
}
