var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { querystring } from "api/querystringlite";
import { isSpecialError } from "api/specialErrorHandler";
import { t } from "i18next";
import { Toastr } from "setup";
import { Toast } from "toast";
import { Consts } from "vars";
export var BACKEND_API = Consts.isDev ? "http://localhost:3000" : "https://chemixapp.appspot.com";
/**
 * Helper method to call the API. It's a thin wrapper around fetch, adding:
 *  - type safety for request and response types
 *  - conversion of request data into stringified URL for GET methods
 */
export function callApi(path, method, auth, request, handlers) {
    var _this = this;
    if (auth === void 0) { auth = null; }
    var headers = {};
    headers["Content-Type"] = "application/json";
    if (auth) {
        if (typeof auth === "string") {
            headers["Authorization"] = "Bearer " + auth;
        }
        else if (auth.state == "logged_in") {
            var jwtToken = auth.jwtToken;
            headers["Authorization"] = "Bearer " + jwtToken;
        }
    }
    var fetchOptions = {
        headers: headers,
        method: method,
    };
    // Add request data, depending on method.
    if (method == "GET") {
        // Stringify
        path += '?' + querystring.stringify(request);
    }
    else {
        fetchOptions["body"] = JSON.stringify(request);
    }
    fetch(BACKEND_API + '/' + path, fetchOptions)
        .then(function (r) { return __awaiter(_this, void 0, void 0, function () {
        var parsedJson, isSuccess, handled, errorJson;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, r.json()
                    // Determine if this should be handled as an error or as a success.
                ];
                case 1:
                    parsedJson = _a.sent();
                    isSuccess = r.status == 200 && !isSpecialError(parsedJson);
                    if (isSuccess) {
                        handlers.success(parsedJson);
                    }
                    else {
                        handled = void 0;
                        if (isSpecialError(parsedJson) && handlers.special) {
                            handled = handlers.special(parsedJson);
                        }
                        else if (handlers.common) {
                            errorJson = parsedJson;
                            handlers.common(errorJson.error);
                            handled = true;
                        }
                        else {
                            handled = false;
                        }
                        if (!handled) {
                            if (handlers.generic) {
                                handlers.generic();
                            }
                            else {
                                console.error("API: " + path + ". Unhandled error: ", parsedJson);
                                Toast.error(t('error.api_generic') + " " + Toastr.contactSupportButton(), t('error.oops'));
                            }
                        }
                    }
                    // Invoke final callback if specified.
                    if (handlers.finally)
                        handlers.finally();
                    return [2 /*return*/, parsedJson];
            }
        });
    }); })
        .catch(function (e) {
        console.error(e);
        if (handlers.generic) {
            handlers.generic();
        }
        else {
            toastr.error(e, "Error making request.");
        }
        if (handlers.finally)
            handlers.finally();
    });
}
