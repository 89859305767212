/** Lightweight implementation of querystring */
export var querystring;
(function (querystring) {
    querystring.stringify = function (obj) {
        if (!obj) {
            return "";
        }
        var arrayFormatter = function (key, value) {
            return value === null
                ? encode(key)
                : [encode(key), "=", encode(value)].join("");
        };
        return Object.keys(obj)
            .map(function (key) {
            var val = obj[key];
            if (val === undefined) {
                return "";
            }
            if (val === null) {
                return encode(key);
            }
            if (Array.isArray(val)) {
                var result = [];
                val.slice().forEach(function (val2) {
                    if (val2 === undefined) {
                        return;
                    }
                    result.push(arrayFormatter(key, val2));
                });
                return result.join("&");
            }
            return encode(key) + "=" + encode(val);
        })
            .filter(function (x) {
            return x.length > 0;
        })
            .join("&");
    };
})(querystring || (querystring = {}));
function encode(value) {
    return strictUriEncode(value);
}
function strictUriEncode(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (x) {
        return "%" + x
            .charCodeAt(0)
            .toString(16)
            .toUpperCase();
    });
}
