import { g } from "analytics";
import { commandProcessor } from "command/command";
import { CloudFiles } from "file/api";
import { FileContext } from "file/context";
import { FileOpenModal } from "file/open_modal";
import { ExportImage2 } from "layout/modals/export_image";
import { LoginModal2 } from "layout/modals/login_modal";
import { MyAccountModal } from "layout/modals/my_account";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import store, { appStore } from "store/store";
export var SharedModalsContext = createContext({
    exportImage: function () { },
    openMyAccount: function () { },
    openFiles: function () { },
    saveToCloud: function () { }
});
export var SharedModalsProvider = function (_a) {
    var auth = _a.auth, children = _a.children;
    var _b = useState(false), isExportOpen = _b[0], setIsExportOpen = _b[1];
    var _c = useState(), imageExportOutput = _c[0], setImageExportOutput = _c[1];
    var fileContext = useContext(FileContext);
    var _d = useState(false), isMyAccountOpen = _d[0], setIsMyAccountOpen = _d[1];
    var _e = useState(false), isFilesOpen = _e[0], setIsFilesOpen = _e[1];
    // This is required so latest auth can be accessed from the saveToCloud callback.
    var authRef = useRef(auth);
    useEffect(function () {
        authRef.current = auth;
    }, [auth]);
    var saveImage = function (source) {
        if (store.items().length + store.arrows().length == 0) {
            toastr.error("Image is empty. Add some items before saving");
            return;
        }
        g('ExportToImage', source);
        /** Log some stats about the image. */
        var state = appStore.getState();
        g('ExportedImageStats::strokeThickness', state.theme.strokeThickness);
        if (state.renderArea) {
            g('ExportedImageStats::area');
        }
        setImageExportOutput(undefined);
        setIsExportOpen(true);
        // Execute on background thread.
        setTimeout(function () {
            // This will potentially take a long time.
            var imageExportOutput = fileContext.exportImages();
            setImageExportOutput(imageExportOutput);
        }, 100);
    };
    var contextValue = useMemo(function () { return ({
        exportImage: function (source) { return saveImage(source); },
        openMyAccount: function () { return setIsMyAccountOpen(true); },
        openFiles: function () { return setIsFilesOpen(true); },
        saveToCloud: function (opts) {
            var _a;
            var auth = authRef.current;
            if (auth.state !== "logged_in")
                return;
            if (store.items().length == 0 && store.arrows().length == 0) {
                toastr.warning("Cannot save an empty diagram");
                return;
            }
            // Extract thumbnail and current document data.
            var thumbnail = fileContext.exportThumbnail();
            CloudFiles.save(auth, thumbnail, (_a = opts === null || opts === void 0 ? void 0 : opts.forceNew) !== null && _a !== void 0 ? _a : false, {
                onDiagramsLimitExceeded: function () { return setIsFilesOpen(true); }
            });
            commandProcessor.markClean();
        }
    }); }, [authRef, fileContext, setIsFilesOpen]);
    return React.createElement(SharedModalsContext.Provider, { value: contextValue },
        React.createElement(ExportImage2, { isOpen: isExportOpen, setIsOpen: setIsExportOpen, exportedImages: imageExportOutput }),
        React.createElement(MyAccountModal, { auth: auth, isOpen: isMyAccountOpen, setIsOpen: setIsMyAccountOpen }),
        React.createElement(FileOpenModal, { auth: auth, isOpen: isFilesOpen, setIsOpen: setIsFilesOpen }),
        children);
};
export var LoginModalContext = createContext({
    openLogin: function () { },
});
export var LoginModalProvider = function (_a) {
    var auth = _a.auth, children = _a.children;
    var _b = useState(false), isLoginOpen = _b[0], setIsLoginOpen = _b[1];
    var _c = useState(), successCallback = _c[0], setSuccessCallback = _c[1];
    var contextValue = useMemo(function () { return ({
        openLogin: function (callback) {
            // Need to set as a function that returns the actual callback.
            setSuccessCallback(function () { return callback; });
            setIsLoginOpen(true);
        },
    }); }, [setSuccessCallback, setIsLoginOpen]);
    return React.createElement(LoginModalContext.Provider, { value: contextValue },
        React.createElement(LoginModal2, { auth: auth, isOpen: isLoginOpen, setIsOpen: setIsLoginOpen, onLoginSuccessCallback: successCallback }),
        children);
};
// Exports
export var useSharedModals = function () { return useContext(SharedModalsContext); };
export var useLoginModal = function () { return useContext(LoginModalContext); };
