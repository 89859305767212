var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useTranslation } from "react-i18next";
import { TierContext } from "tiers/context";
import { Tip } from "view/tooltips";
export var ColorWells = function (_a) {
    var colors = _a.colors, selected = _a.value, onColorSelected = _a.onColorSelected;
    return React.createElement("div", null, colors.map(function (a) {
        return React.createElement(Well, __assign({}, a, { key: a.value, onClick: function () { return onColorSelected(a.value); }, selected: a.value === selected }));
    }));
};
var Well = function (props) {
    var label = props.label, hex = props.hex, value = props.value, onClick = props.onClick, selected = props.selected;
    var tiers = React.useContext(TierContext);
    var t = useTranslation().t;
    var allowed = tiers.allow(props);
    return React.createElement(Tip, { content: t(["props." + label], label) },
        React.createElement("div", { className: "color-well " + (selected ? "selected" : ""), style: { backgroundColor: hex !== null && hex !== void 0 ? hex : value }, onClick: allowed ? onClick : function () { return tiers.popup("colorwell"); } }, tiers.badge(props, "fa-fw")));
};
