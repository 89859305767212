import { g } from "analytics";
import * as React from "react";
import { Badge } from "tiers/badge";
import { meets, TierContext } from "tiers/context";
import { countryCodeToCurrency } from "tiers/currency";
import { PlusPromoModal } from "tiers/promo";
var MAX_WAIT_FOR_CURRENCY_MS = 300;
/** Provides the promo popup as well as various functions to work with tiers. */
export var TierContextProvider = function (_a) {
    var auth = _a.auth, tier = _a.tier, children = _a.children;
    var _b = React.useState(false), isPromoOpen = _b[0], setIsPromoOpen = _b[1];
    var _c = React.useState(), analyticsTag = _c[0], setAnalyticsTag = _c[1];
    var _d = React.useState(), defaultCurrency = _d[0], setDefaultCurrency = _d[1];
    // Fetch default currency.
    React.useEffect(function () {
        // Check for #pricing
        if (window.location.hash == "#pricing") {
            setIsPromoOpen(true);
            // Clear hash.
            window.history.pushState("", document.title, window.location.pathname + window.location.search);
        }
    }, []);
    var allow = React.useCallback(function (itemOrTier) {
        if (typeof itemOrTier === "string") {
            return meets(itemOrTier, tier);
        }
        else {
            // If the item does not have a tier, it's considered basic.
            var itemTier = itemOrTier.tier;
            if (!itemTier)
                return true;
            return meets(itemTier, tier);
        }
    }, [tier]);
    var contextValue = React.useMemo(function () {
        return {
            tier: tier,
            allow: allow,
            badge: function (itemOrTier, extraClass, opts) {
                if (allow(itemOrTier)) {
                    return null;
                }
                if (typeof itemOrTier === "string") {
                    return React.createElement(Badge, { tier: itemOrTier, extraClass: extraClass, style: opts === null || opts === void 0 ? void 0 : opts.style });
                }
                else {
                    var itemTier = itemOrTier.tier;
                    if (!itemTier)
                        return null;
                    return React.createElement(Badge, { tier: itemTier, extraClass: extraClass, style: opts === null || opts === void 0 ? void 0 : opts.style });
                }
            },
            popup: function (analyticsLabel) {
                g("PromoPopup", analyticsLabel !== null && analyticsLabel !== void 0 ? analyticsLabel : "");
                setAnalyticsTag(analyticsLabel);
                if (defaultCurrency) {
                    // Currency already fetched. Open immediately
                    setIsPromoOpen(true);
                }
                else {
                    console.log("Fetch currency");
                    $.get("https://ipinfo.io?token=a91f57b3b0b7ec", function (response) {
                        var countryCode = response.country;
                        var selectedCurrency = countryCodeToCurrency(countryCode);
                        setDefaultCurrency(selectedCurrency);
                        setIsPromoOpen(true);
                    }, "jsonp");
                    setTimeout(function () { return setIsPromoOpen(true); }, MAX_WAIT_FOR_CURRENCY_MS);
                }
            }
        };
    }, [tier, setIsPromoOpen, allow, defaultCurrency, defaultCurrency, setAnalyticsTag]);
    return React.createElement(TierContext.Provider, { value: contextValue },
        React.createElement(PlusPromoModal, { auth: auth, tier: tier, isOpen: isPromoOpen, setIsOpen: setIsPromoOpen, analyticsTag: analyticsTag, defaultCurrency: defaultCurrency }),
        children);
};
