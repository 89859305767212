import { Gradients } from "apparatus/gradients";
import { P, Pivot, R } from "apparatus/library/common";
import { Group, Shape } from "paper";
import { Random } from "random/random";
var Bacteria = /** @class */ (function () {
    function Bacteria() {
    }
    Bacteria.prototype.render = function (appearance) {
        var size = appearance.size;
        var color = appearance.color;
        var graphic = new Group();
        var bounds;
        if (appearance.clipped) {
            var shape = Shape.Circle(P(0, 0), size / 2);
            graphic.addChild(shape);
            graphic.clipped = true;
            bounds = shape.bounds;
        }
        else {
            bounds = R(0, 0, size, size, Pivot.CENTER);
        }
        var step = 7;
        var r = new Random();
        var r2 = new Random();
        for (var x = bounds.left; x < bounds.right; x += step) {
            for (var y = bounds.top; y < bounds.bottom; y += step) {
                var size_1 = r.randomBetween(3, 7);
                var offset = r.randomUniformVector(step / 2);
                if (r2.randomBetween(0, 1) > appearance.density)
                    continue;
                var bacteria = Shape.Circle(P(x, y).add(offset), size_1);
                bacteria.setRadialGradientFill(Gradients.fade(color.color(), { offset: 0.3, finalAlpha: 0.3 }));
                graphic.addChild(bacteria);
            }
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: P(0, 0)
        };
    };
    Bacteria.properties = {
        label: "Bacteria culture",
        defaultAppearance: {
            size: 45,
            density: 0.5,
            clipped: false,
            clipShape: "circle",
            color: "#cccccc"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 35, max: 150, unit: "mm"
            },
            {
                spec: "slider", key: "density", label: "Culture density",
                min: 0.05, max: 0.95, step: 0.01, unit: "%",
            },
            {
                spec: "checkbox", key: "clipped", label: "Clip to circle"
            },
            {
                spec: "color", key: "color", label: "Colour",
            }
        ],
    };
    return Bacteria;
}());
export { Bacteria };
