import { createOutlinedGroup, mirrorX } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { CIRCULAR_RATIO } from "apparatus/library/transportation/osmosis_tube";
import { Group, Path } from "paper";
var RED_NORTH_COLOR = "#f12a2a";
var BLUE_SOUTH_COLOR = "#1482f5";
var GREEN_SOUTH_COLOR = "#66df44";
var Magnet = /** @class */ (function () {
    function Magnet() {
    }
    Magnet.prototype.render = function (appearance) {
        switch (appearance.shape) {
            case "horseshoe": return this.renderHorseshoe(appearance);
            case "bar": return this.renderBar(appearance);
        }
    };
    Magnet.prototype.renderBar = function (appearance) {
        var northPole = new Path.Rectangle(R(0, 0, 45, 22, Pivot.CENTER_RIGHT))
            .withGradientFill("right-down", Gradients.cylinder(RED_NORTH_COLOR.color()));
        var southPole = new Path.Rectangle(R(0, 0, 45, 22, Pivot.CENTER_LEFT))
            .withGradientFill("right-down", Gradients.cylinder(southColor(appearance).color()));
        var graphic = createOutlinedGroup("default", SharedColors.stroke, northPole, southPole);
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    Magnet.prototype.renderHorseshoe = function (appearance) {
        var thickness = 18;
        var halfShape = [
            [[0, 90 - thickness], , [CIRCULAR_RATIO * (40 - thickness), 0]],
            [[40 - thickness, 50], [0, CIRCULAR_RATIO * (40 - thickness)]],
            [[40 - thickness, 0]],
            [[40, 0]],
            [[40, 50], , [0, CIRCULAR_RATIO * 40]],
            [[0, 90], [CIRCULAR_RATIO * 40, 0]]
        ];
        var southPole = new Path(Segments.apply(void 0, halfShape)).close();
        var northPole = southPole.clone();
        northPole.scale(-1.0, 1.0, P(0, 0));
        northPole.withGradientFill("right-down", Gradients.cylinder(RED_NORTH_COLOR.color()));
        southPole.withGradientFill("right-down", Gradients.cylinder(southColor(appearance).color()));
        var outline = new Path(Segments.apply(void 0, halfShape)).close().withStroke("default");
        mirrorX(outline);
        var graphic = new Group([northPole, southPole, outline]);
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    Magnet.properties = {
        label: "Magnet",
        flippable: true,
        defaultAppearance: {
            shape: "bar",
            colors: "red-blue",
            scale: 1.0,
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "shape", label: "Shape",
                options: [
                    { value: "bar", label: "magnet.bar" },
                    { value: "horseshoe", label: "Horseshoe" },
                ],
            },
            {
                spec: "radio", key: "colors", label: "Colours",
                options: [
                    { value: "red-blue", label: "Red / Blue" },
                    { value: "red-green", label: "Red / Green" },
                ],
            },
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.5, max: 3.0, step: 0.01, unit: "%"
            }
        ],
    };
    return Magnet;
}());
export { Magnet };
function southColor(appearance) {
    switch (appearance.colors) {
        case "red-blue": return BLUE_SOUTH_COLOR;
        case "red-green": return GREEN_SOUTH_COLOR;
    }
}
