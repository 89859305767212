import { strokeWidth } from "apparatus/draw";
import { P, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
var LEAF_COLOR = "#56bd6e";
var OUTLINE_COLOR = LEAF_COLOR.color().ladd(-10).string();
var STEM_COLOR = "#cbd676";
var PondWeed = /** @class */ (function () {
    function PondWeed() {
    }
    PondWeed.prototype.render = function (appearance) {
        var length = appearance.length;
        var stem = new Path(Segments([[0, 0]], [[0, length]]));
        stem.strokeWidth = 4.0;
        stem.strokeColor = STEM_COLOR;
        stem.strokeCap = "round";
        var leaf = new Path(Segments([[0, 0], , [5, -5]], [[12, 0], [0, -5], [0, 5]]));
        leaf.scale(0.8, P(0, 0));
        leaf.closePath();
        leaf.withStroke(strokeWidth("thinner"), OUTLINE_COLOR).withFill(LEAF_COLOR);
        leaf.rotate(-10, P(0, 0));
        var graphic = new Group([stem]);
        for (var y = 0; y < length - 15; y += 12) {
            var r = leaf.clone();
            r.position = P(6, y);
            var l = r.clone();
            l.scale(-1.0, 1.0, P(0, 0));
            graphic.addChildren([r, l]);
        }
        leaf.remove();
        return {
            graphic: graphic,
            hitShape: stem.strokeBounds.expand(30).toShape()
        };
    };
    PondWeed.properties = {
        label: "Pond weed",
        defaultAppearance: {
            length: 70
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "length", label: "Length",
                min: 50, max: 120, unit: "mm",
            }
        ],
    };
    return PondWeed;
}());
export { PondWeed };
