import { strokeWidth } from "apparatus/draw";
import { SVG } from "apparatus/svg";
var Root = /** @class */ (function () {
    function Root() {
    }
    Root.prototype.render = function (appearance) {
        var scale = appearance.scale * 2.0;
        var graphic = SVG.root();
        graphic.scale(scale);
        // Create and move outline to the back, so that it will not thin out the graphic.
        // This means strokes need to be twice as thick
        var maxStroke = scale * 4;
        var outline = graphic.children[1].clone()
            .withStroke(Math.min(maxStroke, strokeWidth("default") * 2), "#836B43")
            .withFill(null);
        outline.strokeJoin = "miter";
        outline.miterLimit = 20;
        graphic.insertChild(0, outline);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            watermark: graphic.bounds.center,
        };
    };
    Root.properties = {
        label: "Root",
        defaultAppearance: {
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.5, max: 2.0, step: 0.01, unit: "%",
            }
        ],
    };
    return Root;
}());
export { Root };
