import { mirrorX, setDefaultStyle } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Segments } from "apparatus/library/common";
import { Color as PColor, Gradient, Group, Path } from "paper";
var SuctionPear = /** @class */ (function () {
    function SuctionPear() {
    }
    SuctionPear.prototype.render = function (appearance) {
        var fillColor = appearance.color.color();
        var strokeColor = fillColor.ladd(-35);
        var graphic = (function () {
            switch (appearance.type) {
                case "simple": return simple;
                case "3-way": return threeWay;
            }
        })()(fillColor, strokeColor);
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    SuctionPear.properties = {
        label: "Suction Pear",
        defaultAppearance: {
            type: "simple",
            color: "#2243a7",
            scale: 1.0
        },
        flippable: true,
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.6, max: 1.25, step: 0.01, unit: "%"
            },
            {
                spec: "toggle-buttons", size: "text", key: "type", label: "Type",
                allowSelectingTierLockedOptions: true,
                options: [
                    { value: "simple", label: "suction_pear" },
                    { value: "3-way", label: "suction_threeway", tier: "boost" },
                ],
            },
            {
                spec: "color-wells", key: "color", label: "Colour",
                colors: [
                    { value: "#2243a7", label: "Blue" },
                    { value: "#9f2529", label: "Red", tier: "boost" },
                    { value: "#c95a22", label: "Orange", tier: "boost" },
                    { value: "#488b27", label: "Green", tier: "boost" },
                ],
            },
        ],
        isBoostRequired: function (a) { return a.type == "3-way"; }
    };
    return SuctionPear;
}());
export { SuctionPear };
function simple(fillColor, strokeColor) {
    var graphic = new Path(Segments([[0, 0], [-20, 0], [20, 0]], [[37, 35], [0, -21], [0, 20]], [[20, 67], [5, -7], [-9, 12]], [[11, 112], [1, -22]]));
    mirrorX(graphic);
    graphic.closePath();
    setDefaultStyle(graphic);
    graphic.fillColor = new PColor(new Gradient(Gradients.simple(fillColor, 25), true), P(0, 35), P(0, 0), P(-20, 18));
    graphic.withStroke("default", strokeColor.hex());
    return graphic;
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQf3rceGIKp0oaNoFTW-jup1lzBkXEDoozGfA&usqp=CAU
function threeWay(fillColor, strokeColor) {
    var main = new Path(Segments([[8, -29], [0, -2]], [[8, -1], , [20, 4]], [[41, 36], [0, -17], [0, 20]], [[16, 75], [11, -5], [0, 12]], [[16, 96]], [[8, 98]], [[8, 155], , [0, 2]]));
    mirrorX(main);
    main.close();
    var side = new Path(Segments([[11, -64]], [[10, -58], [-1, -3], [11, 5]], [[7, -34], [10, -3]], [[7, -7], , [0, 6]]));
    mirrorX(side);
    side.close();
    side.pivot = P(0, 0);
    side.withGradientFill("right", Gradients.cylinder(fillColor, -8));
    side.rotate(-90);
    side.position = P(0, 130);
    var outline = main.unite(side);
    outline.withStroke("default", strokeColor.hex());
    outline.fillColor = null;
    main.fillColor = new PColor(new Gradient(Gradients.simple(fillColor, 25), true), P(0, 35), P(0, 0), P(-20, 18));
    return new Group([main, side, outline]);
}
