var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { strokeWidth } from "apparatus/draw";
import { SVG } from "apparatus/svg";
import { staticImplements } from "types";
var Mouse = /** @class */ (function () {
    function Mouse() {
    }
    Mouse.prototype.render = function (appearance) {
        var graphic = SVG.mouse().withScale(appearance.scale);
        // All strokeable items are the first 5 children.
        var strokeW = Math.min(appearance.scale * 2.2, strokeWidth("thin"));
        for (var i = 0; i < 5; i++) {
            graphic.children[i].withStroke(strokeW, "#493547");
        }
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            watermark: graphic.bounds.topLeft,
        };
    };
    Mouse.properties = {
        label: "Mouse",
        flippable: true,
        defaultAppearance: {
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 2.0, step: 0.01, unit: "%"
            }
        ],
    };
    Mouse = __decorate([
        staticImplements()
    ], Mouse);
    return Mouse;
}());
export { Mouse };
