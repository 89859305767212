import { strokeWidth } from "apparatus/draw";
import { SVG } from "apparatus/svg";
import { Group, Shape } from "paper";
var Pump = /** @class */ (function () {
    function Pump() {
    }
    Pump.prototype.render = function (appearance) {
        var _a = SVG.pump(), body = _a.body, insert = _a.insert, cog = _a.cog;
        body.children[0].children[2].strokeWidth = strokeWidth("default");
        insert.children[0].children[1].strokeWidth = strokeWidth("thin");
        cog.children[0].children[1].strokeWidth = strokeWidth("thin");
        insert.rotate(90);
        insert.position.x -= 21;
        insert.position.y -= -18 + 55 * appearance.insertion;
        cog.position.x += 3;
        cog.position.y += 5;
        var graphic = new Group([insert, cog, body]);
        graphic.scale(1.5);
        var hitShape = Shape.Rectangle(graphic.bounds);
        return {
            graphic: graphic, hitShape: hitShape, pivot: body.bounds.center
        };
    };
    Pump.properties = {
        label: "Pump",
        defaultAppearance: {
            insertion: 0.5
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "insertion", label: "Volume",
                min: 0, max: 1, step: 0.01, unit: "%",
            }
        ],
    };
    return Pump;
}());
export { Pump };
