import { g } from "analytics";
import { commandProcessor } from "command/command";
import { ReorderItems } from "ordering/actions";
export function createOrderingButtons(itemIds) {
    return {
        spec: "horizontal-icon-buttons",
        label: "Ordering",
        key: "orderingButtons",
        buttons: [
            {
                buttonLabel: "Bring to front", icon: "d,bring-front",
                onClick: function () {
                    var command = ReorderItems.create(itemIds, "bringToFront");
                    if (command) {
                        g("LayerOrdering", "BringToFront");
                        commandProcessor.execute(command);
                    }
                },
            },
            {
                buttonLabel: "Bring forward", icon: "d,bring-forward",
                onClick: function () {
                    var command = ReorderItems.create(itemIds, "bringForward");
                    if (command) {
                        g("LayerOrdering", "BringForward");
                        commandProcessor.execute(command);
                    }
                },
            },
            {
                buttonLabel: "Send backward", icon: "d,send-backward",
                onClick: function () {
                    var command = ReorderItems.create(itemIds, "sendBackward");
                    if (command) {
                        g("LayerOrdering", "SendBackward");
                        commandProcessor.execute(command);
                    }
                },
            },
            {
                buttonLabel: "Send to back", icon: "d,send-back",
                onClick: function () {
                    var command = ReorderItems.create(itemIds, "sendToBack");
                    if (command) {
                        g("LayerOrdering", "SendToBack");
                        commandProcessor.execute(command);
                    }
                },
            },
        ],
    };
}
