var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { MoreShapes } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Mounted } from "apparatus/library/physics/electric/mounted/utils";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var Capacitor = /** @class */ (function () {
    function Capacitor() {
    }
    Capacitor.prototype.render = function (appearance) {
        var graphic = renderCapacitor(appearance).graphic;
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: [
                { type: "wire", at: P(7, 50) },
                { type: "wire", at: P(-7, 50) },
            ]
        };
    };
    Capacitor.properties = {
        label: "Capacitor",
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    Capacitor = __decorate([
        staticImplements()
    ], Capacitor);
    return Capacitor;
}());
export { Capacitor };
var MountedCapacitor = /** @class */ (function () {
    function MountedCapacitor() {
    }
    MountedCapacitor.prototype.render = function (appearance) {
        var capacitor = renderCapacitor(appearance).graphic;
        var _a = Mounted.renderPlate({ appearance: appearance, height: 40, width: 70 }), base = _a.base, jacks = _a.jacks, snaps = _a.snaps;
        var graphic = new Group([base, capacitor, jacks]);
        capacitor.bounds.bottomCenter = P(35, 16);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: __spreadArray([], snaps)
        };
    };
    MountedCapacitor.properties = {
        label: "Capacitor",
        defaultAppearance: __assign(__assign({}, Capacitor.properties.defaultAppearance), Mounted.defaultAppearance),
        appearanceSpecs: __spreadArray(__spreadArray([], Capacitor.properties.appearanceSpecs), Mounted.appearanceSpecs),
    };
    MountedCapacitor = __decorate([
        staticImplements()
    ], MountedCapacitor);
    return MountedCapacitor;
}());
export { MountedCapacitor };
function renderCapacitor(appearance) {
    var diameter = 20;
    var height = 32;
    var _a = MoreShapes.cylinder3d({ diameter: diameter, height: height }), body = _a.body, top = _a.top, outline = _a.outline;
    body.withGradientFill("right", Gradients.cylinder("#444444".color()));
    top.withFill("#444444");
    outline.withStroke("default");
    var capDiameter = diameter - 8;
    var cap = new Path.Ellipse(R(0, 0, capDiameter, capDiameter / 2, Pivot.CENTER))
        .withPosition(top.position);
    cap.withFill("#bbbbbb");
    var shade = new Path(Segments([[-diameter / 2, height / 2 - 6], , [0, diameter * 0.3]])).mirrorX().withStroke(2.0, "#ffffff40");
    shade.strokeCap = "round";
    var shade2 = shade.clone();
    shade.withPositionDelta({ y: -2 }).withStroke(2.0, "#00000040");
    // Combine and add legs
    var graphic = new Group([
        new Path.Line(P(3, 8), P(7, 50)).withStroke("default"),
        new Path.Line(P(-3, 8), P(-7, 50)).withStroke("default"),
        body, top, cap, outline, shade2, shade
    ]);
    return { graphic: graphic };
}
