import { clamp, P, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path } from "paper";
var BAFFLE_COLOR = "#999999";
var FumeHood = /** @class */ (function () {
    function FumeHood() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 300, maxX: 600,
                minY: 450, maxY: 1200,
                fromAppearance: function (a) { return P(a.width, a.height); },
                toAppearance: function (f, p) { return f({ width: p.x, height: p.y }); },
                constraints: function (a, modifiers) {
                    // Maintain an aspect ratio somewhere between these two values.
                    var ratio = clamp(a.height / a.width, 1.5, 2.0);
                    var delta = P(a.width, a.height);
                    var axis = P(1, ratio);
                    var modifiedDelta = delta.project(axis);
                    return { width: modifiedDelta.x, height: modifiedDelta.y };
                },
                scale: 2.0,
            }
        ];
    }
    FumeHood.prototype.render = function (appearance) {
        var strokeThickness = 10.0;
        var width = appearance.width;
        var height = appearance.height;
        var fumeX = width - 170;
        var sashHeight = height / 2;
        var sashSlide = appearance.sashOpening * (sashHeight - 30);
        var outer1 = new Path(Segments([[0, height / 2 + 20]], [[-5, height / 2 + 20]], [[-30, height / 2 - 20]], [[-30, 0]], [[fumeX, 0]], [[fumeX, -100]])).withStroke(strokeThickness, SharedColors.stroke);
        outer1.strokeCap = "butt";
        var outer2 = new Path(Segments([[fumeX + 80, -100]], [[fumeX + 80, 0]], [[width, 0]], [[width, height]])).withStroke(strokeThickness, SharedColors.stroke);
        var baffle1 = new Path.Line(P(width / 6, 20), P(width - 100, height / 3 - 40))
            .withStroke("thick", BAFFLE_COLOR);
        var baffle2 = new Path.Line(P(width - 50, height / 3), P(width - 50, height * 2 / 3 - 50))
            .withStroke("thick", BAFFLE_COLOR);
        var baffle3 = new Path.Line(P(width - 50, height * 2 / 3), P(width - 50, height - 50))
            .withStroke("thick", BAFFLE_COLOR);
        var sash = new Path.Rectangle(R(1, height - sashHeight - strokeThickness / 2, 10, sashHeight), 3.0)
            .withStroke("thinner", "#56acd1").withFill("#7bcbed50");
        sash.position.y -= sashSlide;
        var workbench = new Path.Rectangle(R(0, height, width, 200))
            .withStroke(strokeThickness, SharedColors.stroke)
            .withFill("#777777");
        var fumeHood = new Group([outer1, outer2, baffle1, baffle2, baffle3, sash, workbench]);
        var graphic;
        switch (appearance.display) {
            case "full":
                graphic = fumeHood;
                break;
            case "clipped":
                var mask = new Path.Rectangle(R(-50, height / 2 - 100, width + 100, height / 2 + 200));
                var faders = mask.clone();
                mask.withFill("black");
                faders.setGradientFill("down", [
                    ["#ffffffff", 0.0],
                    ["#ffffff00", 0.05],
                    ["#ffffff00", 0.95],
                    ["#ffffffff", 1.0],
                ]);
                graphic = new Group([mask, fumeHood, faders]);
                graphic.clipped = true;
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: fumeHood.bounds.center,
            snapping: [
                { type: "placeable_surface", start: workbench.strokeBounds.topLeft, end: workbench.strokeBounds.topRight }
            ]
        };
    };
    FumeHood.properties = {
        label: "Fume Hood",
        flippable: true,
        defaultAppearance: {
            sashOpening: 0.5,
            display: "clipped",
            width: 600,
            height: 1200,
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "display", label: "Display",
                options: [
                    { value: "full", label: "Full" },
                    { value: "clipped", label: "Clipped" },
                ],
            },
            {
                spec: "slider", key: "sashOpening", label: "Sash Opening",
                min: 0.0, max: 1.0, step: 0.01,
            }
        ],
    };
    return FumeHood;
}());
export { FumeHood };
