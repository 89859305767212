var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { P } from "apparatus/library/common";
import { UpdateAppearance } from "command/basic";
import { commandProcessor, Sequence } from "command/command";
import { Move } from "editing/move";
import { clog } from "log";
import { Key, Tool } from "paper";
import appStore from "store/store";
var PathEditTool = /** @class */ (function (_super) {
    __extends(PathEditTool, _super);
    function PathEditTool(app) {
        var _this = _super.call(this) || this;
        _this.app = app;
        _this.onMouseDown = function (event) {
            var TOLERANCE = 10 / paper.project.view.scaling.x;
            // Get first selected item.
            var selectedFirstItem = appStore.selectedItems()[0];
            if (!selectedFirstItem || selectedFirstItem.type !== "__live edit__")
                return;
            var component = _this.app.getApparatusComponent(selectedFirstItem.id);
            if (!component)
                return;
            if (Key.modifiers.alt && Key.modifiers.shift && Key.modifiers.control) {
                var path = getPath(component);
                var delta_1 = event.downPoint.subtract(P(component.props.x, component.props.y));
                path.segments.forEach(function (s) { s.point = s.point.subtract(delta_1); });
                _this.editedLiveEditComponent = component;
                _this.op = { type: "set-center", delta: delta_1 };
            }
            else if (Key.modifiers.shift && Key.modifiers.control) {
                // Align centers.
                var destination_1 = P(component.props.x, component.props.y);
                commandProcessor.execute(new Sequence(appStore.selectedItems().map(function (i) { return new Move([i.id], destination_1.subtract(P(i.x, i.y))); })));
            }
            else if (Key.modifiers.alt) {
                var hitResult = component.item.hitTest(event.downPoint, { segments: true, tolerance: TOLERANCE });
                if (hitResult) {
                    _this.editedLiveEditComponent = component;
                    if (hitResult.segment.handleIn.length == 0 && hitResult.segment.handleOut.length == 0) {
                        // Straight point. Switch to having both handles
                        hitResult.segment.handleIn = P(20, 0);
                        hitResult.segment.handleOut = P(-20, 0);
                    }
                    else if (hitResult.segment.handleIn.length == 0) {
                        // Only has handleOut. Switch to handleIn.
                        hitResult.segment.handleIn = hitResult.segment.handleOut.multiply(-1);
                        hitResult.segment.handleOut = P(0, 0);
                    }
                    else if (hitResult.segment.handleOut.length == 0) {
                        // Only has handleIn set. Switch to none.
                        hitResult.segment.handleOut = P(0, 0);
                        hitResult.segment.handleIn = P(0, 0);
                    }
                    else {
                        // Has both handles, switch to handleOut only.
                        hitResult.segment.handleIn = P(0, 0);
                    }
                }
            }
            else if (Key.modifiers.shift) {
                // Add a point to path.
                var hitResult = component.item.hitTest(event.downPoint, { stroke: true, tolerance: TOLERANCE });
                clog(hitResult);
                if (hitResult && hitResult.location) {
                    var path = getPath(component);
                    path.insert(hitResult.location.index + 1, event.point.subtract(P(component.props.x, component.props.y)));
                    _this.editedLiveEditComponent = component;
                }
            }
            else if (Key.modifiers.control) {
                // Remove a point.
                var hitResult = component.item.hitTest(event.downPoint, { segments: true, tolerance: TOLERANCE });
                if (hitResult) {
                    var path = getPath(component);
                    path.removeSegment(hitResult.segment.index);
                    _this.editedLiveEditComponent = component;
                }
            }
            else {
                var hitResult = component.item.hitTest(event.downPoint, {
                    segments: true,
                    handles: true,
                    tolerance: TOLERANCE,
                });
                if (hitResult) {
                    if (hitResult.type === "segment") {
                        _this.op = {
                            type: "move-segment",
                            segment: hitResult.segment
                        };
                        _this.editedLiveEditComponent = component;
                    }
                    else if (hitResult.type === "handle-in") {
                        _this.op = {
                            type: "move-handle-in",
                            segment: hitResult.segment
                        };
                        _this.editedLiveEditComponent = component;
                    }
                    else if (hitResult.type === "handle-out") {
                        _this.op = {
                            type: "move-handle-out",
                            segment: hitResult.segment
                        };
                        _this.editedLiveEditComponent = component;
                    }
                }
            }
        };
        _this.onMouseDrag = function (event) {
            if (!_this.editedLiveEditComponent)
                return;
            if (!_this.op)
                return;
            switch (_this.op.type) {
                case "move-segment":
                    _this.op.segment.point = _this.op.segment.point.add(event.delta);
                    break;
                case "move-handle-in":
                    _this.op.segment.handleIn = _this.op.segment.handleIn.add(event.delta);
                    if (Key.modifiers.shift) {
                        _this.op.segment.handleOut.angle = _this.op.segment.handleIn.angle + 180;
                    }
                    break;
                case "move-handle-out":
                    _this.op.segment.handleOut = _this.op.segment.handleOut.add(event.delta);
                    if (Key.modifiers.shift) {
                        _this.op.segment.handleIn.angle = _this.op.segment.handleOut.angle + 180;
                    }
                    break;
            }
        };
        _this.onMouseUp = function (event) {
            var _a;
            if (!_this.editedLiveEditComponent)
                return;
            commandProcessor.startRecording();
            // Extract Path object.
            var path = getPath(_this.editedLiveEditComponent);
            var mirrored = _this.editedLiveEditComponent.props.appearance["mirrored"];
            var pathData = convertToData(path, mirrored ? path.segments.length / 2 : path.segments.length);
            commandProcessor.execute(new UpdateAppearance(_this.editedLiveEditComponent.id, "segments", pathData, _this.editedLiveEditComponent.props.appearance["segments"]));
            if (((_a = _this.op) === null || _a === void 0 ? void 0 : _a.type) == "set-center") {
                commandProcessor.execute(new Move([_this.editedLiveEditComponent.id], _this.op.delta));
            }
            commandProcessor.finishRecording();
            _this.editedLiveEditComponent = undefined;
            _this.op = undefined;
        };
        return _this;
    }
    return PathEditTool;
}(Tool));
export { PathEditTool };
function getPath(liveEditComponent) {
    return liveEditComponent.item.children[0].children[0];
}
function convertToData(path, limit) {
    var converted = [];
    var count = 0;
    for (var _i = 0, _a = path.segments; _i < _a.length; _i++) {
        var segment = _a[_i];
        if (count >= limit)
            break;
        var point = P(segment.point.x, segment.point.y);
        var handleOut = segment.handleOut.x == 0 && segment.handleOut.y == 0 ? undefined : P(segment.handleOut.x, segment.handleOut.y);
        var handleIn = segment.handleIn.x == 0 && segment.handleIn.y == 0 ? undefined : P(segment.handleIn.x, segment.handleIn.y);
        if (handleOut) {
            converted.push("[" + toString(point) + "," + (handleIn ? toString(handleIn) : '') + "," + toString(handleOut) + "]");
        }
        else if (handleIn) {
            converted.push("[" + toString(point) + "," + toString(handleIn) + "]");
        }
        else {
            converted.push("[" + toString(point) + "]");
        }
        count++;
    }
    return converted.join(",\n");
}
function toString(point) {
    return "[" + point.x.round() + ", " + point.y.round() + "]";
}
