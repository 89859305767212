import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var WHITE = "#e0e0e0";
var GRAY = "#999999";
var GRAY_BLUE = "#bbbbb9";
var BLACK = "#444444";
var DARK_BLUE = "#325b99";
var LIGHT_BLUE = "#477bc9";
var LIGHT_GREEN = "#289937";
var DARK_GREEN = "#206b2a";
var MAROON = "#752334";
var YELLOW = "#ebde4d";
var RED = "#d43737";
var BROWN = "#8c5f4a";
/**
 * Gas Cylinder.
 *
 * Color coding according to "EN 1089 Part 3"
 */
var GasCylinder = /** @class */ (function () {
    function GasCylinder() {
    }
    GasCylinder.prototype.render = function (appearance) {
        var height = appearance.height;
        // A gas cylinder consists of
        // 1) the container, coloured in two parts: shoulder and body.
        // 2) the outlet
        var shoulder = new Path(Segments([[0, 20]], [[0, 0], , [0, -90]], [[140, 0], [0, -90]], [[140, 20]]));
        var body = new Path(Segments([[0, 20]], [[0, height]], [[140, height]], [[140, 20]]));
        var outlet = Shape.Rectangle(R(70, -120, 35, 80, Pivot.TOP_CENTER), 17.5);
        setMetallicGradient(outlet, SharedColors.lightMetal);
        // Add highlights to create a more realistic look.
        var highlight = new Path(Segments([[70, -68]], [[45, 20], [0, -100]], [[45, height]], [[20, height]], [[20, 20], , [0, -100]]));
        highlight.closePath();
        highlight.fillColor = "#ffffff50";
        var topHighlight = shoulder.clone();
        topHighlight.setGradientFill("down", [["#ffffff80", 0.0], ["#ffffff00", 0.5]]);
        // Set up the colors of the container based on type of gas.
        var color = shoulderColor(appearance.gas);
        setMetallicGradient(shoulder, color);
        if (appearance.bodyColor == "medical") {
            setMetallicGradient(body, WHITE);
        }
        else if (appearance.bodyColor == "industrial") {
            setMetallicGradient(body, GRAY_BLUE);
        }
        else {
            // Copy shoulder colour.
            setMetallicGradient(body, color);
        }
        // Put everything together.
        var graphic = new Group([outlet, body, shoulder, highlight, topHighlight]);
        // The container can be large. Allow it to be scaleable.
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: [
                graphic.placeable(),
                { type: "connectable", at: outlet.bounds.topCenter.add(P(0, 8)), facing: 0 },
            ]
        };
    };
    GasCylinder.properties = {
        label: "Gas cylinder",
        defaultAppearance: {
            scale: 0.5,
            height: 600,
            gas: "argon",
            bodyColor: "industrial",
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.5, max: 1.0, step: 0.01,
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 400, max: 600
            },
            {
                spec: "radio", key: "bodyColor", label: "Body colour",
                options: [
                    { value: "industrial", label: "Industrial Gas" },
                    { value: "medical", label: "Medical Gas" },
                    { value: "copy-shoulder", label: "Same as shoulder" },
                ],
            },
            {
                spec: "select", key: "gas", label: "Gas type (colour)",
                options: [
                    { value: "toxic", label: "Toxic / Corrosive", tier: "boost" },
                    { value: "flammable", label: "Flammable", tier: "boost" },
                    { value: "oxidising", label: "Oxidising", tier: "boost" },
                    { value: "inert", label: "Inert", tier: "boost" },
                    { value: "acetylene", label: "Acetylene" },
                    { value: "argon", label: "Argon" },
                    { value: "co2", label: "Carbon dioxide" },
                    { value: "helium", label: "Helium" },
                    { value: "nitrogen", label: "Nitrogen" },
                    { value: "nitrous oxide", label: "Nitrous Oxide" },
                    { value: "oxygen", label: "Oxygen" },
                ],
            },
        ],
    };
    return GasCylinder;
}());
export { GasCylinder };
function setMetallicGradient(item, color) {
    var darkerColor = color.color().darken(0.18).hex();
    item.setGradientFill("right", [
        [darkerColor, 0.0],
        [color, 0.3],
        [color, 0.7],
        [darkerColor, 1.0]
    ]);
}
var shoulderColor = function (gas) {
    switch (gas) {
        case "toxic": return YELLOW;
        case "flammable": return RED;
        case "oxidising": return LIGHT_BLUE;
        case "inert": return LIGHT_GREEN;
        case "argon": return DARK_GREEN;
        case "helium": return BROWN;
        case "co2": return GRAY;
        case "nitrogen": return BLACK;
        case "oxygen": return WHITE;
        case "acetylene": return MAROON;
        case "nitrous oxide": return DARK_BLUE;
    }
};
