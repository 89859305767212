import { strokeWidth } from "apparatus/draw";
import { P, R } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var Quadrat = /** @class */ (function () {
    function Quadrat() {
    }
    Quadrat.prototype.render = function (appearance) {
        var size = appearance.scale * appearance.size;
        var grid = Number(appearance.gridType);
        var step = size / grid;
        var frame = Shape.Rectangle(R(0, 0, size, size));
        var graphic = new Group();
        // Vertical.
        for (var i = 1; i < grid; i++) {
            graphic.addChild(new Path.Line(P(i * step, 0), P(i * step, size)));
            graphic.addChild(new Path.Line(P(0, i * step), P(size, i * step)));
        }
        graphic.withStroke(strokeWidth("default"), "#aaaaaa");
        frame.withStroke(10.0, "#b37132");
        graphic.addChild(frame);
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    Quadrat.properties = {
        label: "Quadrat",
        defaultAppearance: {
            size: 300,
            scale: 0.75,
            gridType: "5"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 300, max: 600, unit: "mm"
            },
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.25, max: 1, step: 0.01, unit: "%",
            },
            {
                spec: "radio", key: "gridType", label: "Grid",
                options: [
                    { value: "3", label: "3x3" },
                    { value: "5", label: "5x5" },
                    { value: "7", label: "7x7", tier: "boost" },
                    { value: "10", label: "10x10", tier: "boost" },
                ],
            }
        ],
    };
    return Quadrat;
}());
export { Quadrat };
