import { g } from "analytics";
import store from "store/store";
import { switchTool } from "./store/actions";
/** Returns the CSS cursor name that should be used for each tool. */
export function toolCursors(tool) {
    switch (tool) {
        case "select": return "auto";
        case "pan": return "move";
        case "arrow": return "url(" + textBase64 + "), crosshair";
        case "freehand": return "url(" + pencilBase64 + ") 5 5, crosshair";
        case "pathedit": return "crosshair";
    }
}
/** Switches the current active tool. */
export function selectTool(tool, interaction) {
    g("Toolbox: " + tool, interaction);
    store.dispatch(switchTool(tool));
}
var pencilBase64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAGYktHRAD/AP8A/6C9p5MAAAEpSURBVFhH7ZRBagJBEEV7H8g6ERJMsvQQ8U4eJN7IZJNTJERBIZcQ/U+moGm6dRZVs+oPD50W+r+pnjH19FRyavAn5iI8tXJjEomylNyLb2ESzyIsNQGyEEfB2q8IkyiLCWWU/ouDCJUwgbvLVUpPgjLW9uJd7IbrEAnb/Esw9p/h2uAZWIowiVdhm9uZl1BaSri+HblEi1LC/e1Agk3L4pzwSbDZ2EmYLJ/uxzFmEvnb4S7BZrck+D10EmMkmMRq+O4uQMZIgJXDRri+HWx67cHMy02WyYT9WeWwVpYb/KM+CLeUEhSyVis31sI1JmF3zphb5eAuQJCwO+esa8WA5KMIy6eoFQPlSIaGCeTPhLEVb2KScJf5M4DQi5g0nPPHwIyFnp52UjoDlvrZbV1lPrwAAAAASUVORK5CYII=";
var textBase64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAvVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADTQ/BeAAAAPnRSTlMAAAECCAkQERYZGx8hIiMkJScoKiwtLzIzNDU2Nzg5Ojs8PUBETmFvcnl6mqy3zM3O1tfY3N3h5urs8PT6/GYLJh4AAADLSURBVHjadc7XEoJADAXQYO9iRbChYu+95/8/yxtcB3TW+5JyZidLLCEjiOp9oAAC9/demsKJDGIC6PhxOoZy5iwAewPPLuv9DeW6Xa0OzDkDaxzgu4PaBnio0QvnBQzAghAH4Eiz5KL63HMkcxNgSTPmnAI3LrMNaEiT2CQUkB8LYNM7X1CXGzqoAlo6qADapBKGEqCjAxPQ1UEB4P6Dng7KgL4Oav9u2ICmDoYAT61jAVRnO8Buav68mPAn828wO5VMMpW3Wl6dJC/vOCLvpGT5yQAAAABJRU5ErkJggg==";
