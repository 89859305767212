var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { commandProcessor, Sequence } from "command/command";
import { Move } from "editing/move";
import { UpdateRenderAreaBox } from "editing/render_area";
import { Tool } from "paper";
import store, { appStore } from "store/store";
var PanTool = /** @class */ (function (_super) {
    __extends(PanTool, _super);
    function PanTool(app) {
        var _this = _super.call(this) || this;
        _this.app = app;
        _this.components = [];
        _this.onMouseDown = function (event) {
            _this.app.setIsInteracting(true);
            var allIds = __spreadArray(__spreadArray([], store.items().map(function (i) { return i.id; })), store.arrows().map(function (a) { return a.id; }));
            _this.components = allIds.map(function (id) { return _this.app.getObjectComponent(id); });
        };
        _this.onMouseDrag = function (event) {
            var delta = event.point.subtract(event.downPoint);
            _this.components.map(function (c) { return c.setPositionDelta(delta); });
            _this.app.setRenderArea(delta);
        };
        _this.onMouseUp = function (event) {
            _this.app.setIsInteracting(false);
            var delta = event.point.subtract(event.downPoint);
            if (delta.isZero())
                return;
            var commands = [];
            commands.push(new Move(_this.components.map(function (c) { return c.id; }), delta));
            var renderArea = appStore.getState().renderArea;
            if (renderArea) {
                var newArea = __assign({}, renderArea);
                newArea.bottom += delta.y;
                newArea.top += delta.y;
                newArea.left += delta.x;
                newArea.right += delta.x;
                commands.push(new UpdateRenderAreaBox(newArea));
            }
            commandProcessor.execute(new Sequence(commands));
            _this.components.length = 0;
        };
        return _this;
    }
    return PanTool;
}(Tool));
export { PanTool };
