var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P, SharedColors } from "apparatus/library/common";
import { Path } from "paper";
var DEFAULT_SWATCHES = {
    'Red': SharedColors.ray,
    'Yellow': '#f1f18a',
    'Green': '#3ac433',
    'Blue': '#1aa9db',
    'Black': '#282828',
};
/** Minimum distance between two points before an arrow ca be drawn. */
var MIN_RAY_DISTANCE_FOR_ARROW = 50;
var ARROW_SIZE = 8;
export var Ray;
(function (Ray) {
    Ray.header = {
        spec: "header", header: "Ray", icon: "k,laser", toggleKey: "showRay",
    };
    Ray.defaultAppearanceRayOn = {
        showRay: true,
        rayColor: SharedColors.ray,
        rayThickness: 2.0,
    };
    Ray.defaultAppearanceRayOff = __assign(__assign({}, Ray.defaultAppearanceRayOn), { showRay: false });
    Ray.defaultAppearanceArrow = {
        showRayArrow: false,
    };
    Ray.specsRayOnly = [
        {
            spec: "slider", key: "rayThickness", label: "Strength",
            min: 1.0, max: 5.0, step: 0.5
        },
        {
            spec: "color", key: "rayColor", label: "Colour", swatches: DEFAULT_SWATCHES
        },
    ];
    Ray.specs = __spreadArray([
        Ray.header
    ], Ray.specsRayOnly.map(function (s) { return (__assign(__assign({}, s), { visible: function (a) { return a.showRay && (!s.visible || s.visible(a)); } })); }));
    Ray.arrowSpecs = [
        { spec: "checkbox", key: "showRayArrow", label: "Show arrows", visible: function (a) { return a.showRay; } },
    ];
    function applyStyle(ray, appearance) {
        ray.withStroke(appearance.rayThickness, appearance.rayColor);
    }
    Ray.applyStyle = applyStyle;
    function maybeAddArrows(a, path, positions, group) {
        if (!a.showRayArrow)
            return;
        for (var i = 0; i < path.segments.length - 1; i++) {
            var position = positions[Math.min(i, positions.length - 1)];
            if (position == null)
                continue;
            var start = path.segments[i].point;
            var end = path.segments[i + 1].point;
            if (start.getDistance(end) >= MIN_RAY_DISTANCE_FOR_ARROW) {
                group.addChild(drawFilledArrowHead(start, end, position, ARROW_SIZE, a.rayColor, a.rayThickness));
            }
        }
    }
    Ray.maybeAddArrows = maybeAddArrows;
    function drawFilledArrowHead(start, end, position, size, color, thickness) {
        var at = start.multiply(1 - position).add(end.multiply(position));
        var arrowSize = P(0, size + thickness * 2);
        var arrow = new Path([
            [0, 0], arrowSize.rotate(20), arrowSize.rotate(-20),
        ]).withFill(color);
        // Shift the arrow
        arrow.position = at;
        arrow.rotate(start.subtract(end).angle - 90);
        return arrow;
    }
})(Ray || (Ray = {}));
