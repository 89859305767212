import { P } from "apparatus/library/common";
import { MoveIndividual } from "../move";
// Contains methods for aligning objects.
export var Align;
(function (Align) {
    /**
     * Aligns objects to a certain direction. Returns commands to be
     * executed to align the objects. Both arrows and apparatus can be moved.
     */
    function align(components, direction) {
        components = components.filter(function (c) { return c !== undefined || c !== null; });
        if (components.length <= 1)
            return;
        var property = direction;
        var axis = direction == "left" || direction == "right" ? "x" : "y";
        var operator = direction == "left" || direction == "top" ? "min" : "max";
        // Detect left-most edge.
        var extremity = components.reduce(function (v, c) { return Math[operator](v, c.alignmentBounds[property]); }, components[0].alignmentBounds[property]);
        // Move all items towards this edge.
        return new MoveIndividual(components.map(function (c) {
            var delta = P(0, 0);
            delta[axis] = extremity - c.alignmentBounds[property];
            return { id: c.id, delta: delta };
        }));
    }
    Align.align = align;
    function alignCenters(components, axis) {
        components = components.filter(function (c) { return c !== undefined || c !== null; });
        if (components.length <= 1)
            return;
        var center = components.reduce(function (v, c) { return v + c.alignmentBounds.center[axis]; }, 0) / components.length;
        // Move all items towards this edge.
        return new MoveIndividual(components.map(function (c) {
            var delta = P(0, 0);
            delta[axis] = center - c.alignmentBounds.center[axis];
            return { id: c.id, delta: delta };
        }));
    }
    Align.alignCenters = alignCenters;
})(Align || (Align = {}));
