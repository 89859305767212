import { setDefaultStyle } from "apparatus/draw";
import { interpolate, P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var LabJack = /** @class */ (function () {
    function LabJack() {
    }
    LabJack.prototype.render = function (_a) {
        var height = _a.height, size = _a.size;
        var halfHeight = height / 2;
        // Create basic arm.
        var armLength = interpolate(size, [95, 200], [87, 140]);
        var armWidth = 10;
        var arm = Shape.Rectangle(R(-armWidth / 2, 0, armLength + armWidth, armWidth, Pivot.BOTTOM_LEFT), armWidth / 2);
        setDefaultStyle(arm);
        arm.fillColor = SharedColors.lightMetal;
        // Calculate how much to rotate the arm by to reach desired (total) height
        var rotateBy = Math.asin(halfHeight / armLength).toDegrees();
        arm.rotate(rotateBy, P(armLength, -armWidth / 2));
        // Clone to create the second arm.
        var arm2 = arm.clone();
        arm2.position.y -= halfHeight;
        // Clone the arms to create the flipped arms, forming the "X" pattern.
        var arm3 = arm.clone();
        arm3.scale(-1.0, 1.0);
        var arm4 = arm2.clone();
        arm4.scale(-1.0, 1.0);
        // arm3 + 4 are at the front, brighten the colour.
        arm3.fillColor = SharedColors.lightMetal.color().lighten(0.2).hex();
        arm4.fillColor = arm3.fillColor;
        // Put all the arms together and place in correct position.
        var arms = new Group([arm, arm2, arm3, arm4]);
        // Add base and top.
        var base = Shape.Rectangle(R(armLength / 2, -5, size, 15, Pivot.TOP_CENTER));
        setDefaultStyle(base);
        base.fillColor = "#3c82c9";
        base.strokeColor = "#26527f";
        var platform = base.clone();
        platform.position.y -= height + 10;
        // Turn knob + bar.
        var bar = new Path(Segments([[-7, -halfHeight - 5]], [[base.bounds.right + 10, -halfHeight - 5]]));
        bar.strokeWidth = 5.0;
        bar.strokeColor = SharedColors.mediumMetal;
        var knob = Shape.Rectangle(R(base.bounds.right + 10, -halfHeight - 5, 12, 20, Pivot.CENTER_LEFT));
        setDefaultStyle(knob);
        knob.fillColor = "#444444";
        // Assemble together.
        var jack = new Group([arms, base, platform]);
        var graphic = new Group([bar, knob, jack]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: P(50, 0),
            watermark: P(25, -18),
            snapping: [
                platform.placeable(),
                jack.topEdgeAsPlaceableSurface(),
            ]
        };
    };
    LabJack.properties = {
        label: "Scissor Jack",
        flippable: true,
        defaultAppearance: {
            height: 50,
            size: 120,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "height", label: "Height",
                min: 30, max: 150
            },
            {
                spec: "slider", key: "size", label: "Size",
                min: 95, max: 200, unit: "mm",
            },
        ],
    };
    return LabJack;
}());
export { LabJack };
