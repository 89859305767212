var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { addEdgeLips, breakPath, glassFold, setDefaultStyle, smoothCorner } from "apparatus/draw";
import { DEFAULT_LIQUID_DATA, P, Segments } from "apparatus/library/common";
import { createTap, Tap } from "apparatus/library/common/tap";
import { Path } from "paper";
var DeanStarTrap = /** @class */ (function () {
    function DeanStarTrap() {
    }
    DeanStarTrap.prototype.render = function (appearance) {
        var bottomPart = [
            [[-10, 76]],
            [[-10, 150], , [0, 10]],
            [[-5, 200], [0, -40]],
            [[5, 200], , [0, -40]],
            [[10, 150], [0, 10]]
        ];
        var basicShape = new Path(Segments.apply(void 0, __spreadArray(__spreadArray([[[-10, 0]],
            [[-10, 57]],
            [[-107, 85]],
            [[-107, 142], , [-3, 0]],
            [[-105, 172]],
            [[-91, 172]],
            [[-89, 142], [3, 0]],
            [[-89, 99]]], bottomPart), [[[10, 0]]])));
        var liquidMask = new Path(Segments.apply(void 0, __spreadArray(__spreadArray([], bottomPart), [[[10, 76]]])));
        smoothCorner(basicShape.segments[8], 4);
        smoothCorner(basicShape.segments[7], 4);
        smoothCorner(basicShape.segments[2], 10);
        smoothCorner(basicShape.segments[1], 4);
        addEdgeLips(basicShape);
        var graphic = breakPath(basicShape, 8, 16);
        setDefaultStyle(graphic);
        // Add a glass fold at the T-junction.
        graphic.addChild(glassFold(-8, 67, { rotation: -90 }));
        // Add a tap.
        var tap = createTap(appearance);
        graphic.addChild(tap);
        tap.position = P(0, 165);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            liquidMask: liquidMask,
            pivot: basicShape.bounds.center
        };
    };
    DeanStarTrap.properties = {
        label: "Dean-Stark Trap",
        canContainLiquids: true,
        flippable: true,
        defaultAppearance: __assign({}, Tap.defaultAppearance),
        appearanceSpecs: [
            Tap.appearanceSpec,
        ],
        defaultLiquidData: __assign(__assign({}, DEFAULT_LIQUID_DATA), { amountRatio: 0.75 })
    };
    return DeanStarTrap;
}());
export { DeanStarTrap };
