var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { g } from "analytics";
import { clamp, P, Pp, Segments, SharedColors } from "apparatus/library/common";
import { UpdateArrowProperties } from "arrows/command";
import { createInteractionHandleFor } from "editing/handle";
import { Key, Path, Point } from "paper";
import * as React from "react";
import { appStore } from "store/store";
import { ChemMark } from "text/parse";
import { AdvancedText } from "text/render";
import { add } from "types";
import { Debug } from "vars";
import { Adjustable, Interactable, SelectAndMove } from "../editing/interaction";
/**
 * React component to draw an arrow, accompanied by a text label.
 * The "start" is one extremity and where the label is placed.
 * The "end" is the other, and is where the arrow head is placed.
 */
var ArrowComponent = /** @class */ (function (_super) {
    __extends(ArrowComponent, _super);
    function ArrowComponent(props) {
        var _this = _super.call(this, props) || this;
        /**
         * The last zoom level for which the UI elements, such as interaction handles, were updated.
         */
        _this.lastUpdatedUiForZoom = -1;
        /** Text cache. */
        _this.textCache = {
            mode: "basic",
            input: "",
            result: { segments: [] }
        };
        var start = new Point(props.start);
        _this.advancedText = AdvancedText.withInteraction(SelectAndMove(_this));
        _this.textStyle = {
            fontFamily: "sans-serif",
            fontSize: _this.props.fontSize,
            textAlign: _this.props.textAlign,
            fillColor: "black",
        };
        _this.line = new Path([start, start]);
        _this.line.strokeWidth = 3.0;
        _this.line.selectedColor = SharedColors.selectionGreen;
        _this.hittableLine = new (Interactable(Path, SelectAndMove(_this)))([start, start]);
        _this.hittableLine.strokeWidth = 20.0;
        _this.hittableLine.strokeColor = "#ffffff20";
        _this.arrowHead = _this.createArrowHead();
        _this.arrowHead.applyMatrix = false;
        // Create an interaction handler for adjusting the head.
        _this.arrowHeadHint = createInteractionHandleFor(Adjustable({
            setDelta: function (delta) {
                _this.setState({ end: _this.editArrowHead(props, delta) });
            },
            onEnd: function (delta) {
                g("UpdateAppearance", "Arrow::{driver}_head");
                return new UpdateArrowProperties([{
                        id: _this.id,
                        value: { start: _this.props.start, end: _this.editArrowHead(props, delta) },
                        previousValue: { start: _this.props.start, end: _this.props.end },
                    }]);
            },
        }));
        // Create an interaction handler for adjusting the tail.
        _this.arrowTailHint = createInteractionHandleFor(Adjustable({
            setDelta: function (delta) {
                _this.setState({ start: _this.editArrowTail(props, delta) });
            },
            onEnd: function (delta) {
                g("UpdateAppearance", "Arrow::{driver}_start");
                return new UpdateArrowProperties([{
                        id: _this.id,
                        value: { start: _this.editArrowTail(props, delta), end: _this.props.end },
                        previousValue: { start: _this.props.start, end: _this.props.end },
                    }]);
            },
        }));
        _this.state = {
            start: _this.props.start,
            end: _this.props.end,
            label: _this.props.label,
            fontSize: _this.props.fontSize,
            textColor: _this.props.textColor,
            arrowThickness: _this.props.arrowThickness,
        };
        return _this;
    }
    ArrowComponent.prototype.editArrowHead = function (props, delta) {
        var deltaVectorPosition = add(this.props.end, delta);
        if (Key.modifiers.shift) {
            var arrowStartPoint = Pp(this.props.start);
            var arrowEndPoint = Pp(add(this.props.end, delta));
            var deltaVector = arrowEndPoint.subtract(arrowStartPoint);
            var angle = deltaVector.angle;
            var roundedAngle = ((angle / 45).round()) * 45;
            deltaVector.angle = roundedAngle;
            deltaVectorPosition = add(this.props.start, deltaVector);
        }
        return deltaVectorPosition;
    };
    ArrowComponent.prototype.editArrowTail = function (props, delta) {
        var deltaVectorPosition = add(this.props.start, delta);
        if (Key.modifiers.shift) {
            var arrowStartPoint = Pp(add(this.props.start, delta));
            var arrowEndPoint = Pp(this.props.end);
            var deltaVector = arrowStartPoint.subtract(arrowEndPoint);
            var angle = deltaVector.angle;
            var roundedAngle = ((angle / 45).round()) * 45;
            deltaVector.angle = roundedAngle;
            deltaVectorPosition = add(this.props.end, deltaVector);
        }
        return deltaVectorPosition;
    };
    Object.defineProperty(ArrowComponent.prototype, "currentPosition", {
        get: function () {
            return Pp(this.props.end).add(Pp(this.props.start)).divide(2.0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ArrowComponent.prototype, "bounds", {
        get: function () {
            if (this.props.showArrow) {
                return this.line.bounds.unite(this.advancedText.bounds);
            }
            // Without arrow, only the text forms the bounding box.
            return this.advancedText.bounds;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ArrowComponent.prototype, "alignmentBounds", {
        get: function () {
            var _a;
            // For alignment, only use the text's bounds.
            // Use the special alignment bounds, which ignore the subscript etc.
            // Fallback on normal bounds, sometimes alignmentBounds can be undefined for some reason?
            // https://sentry.io/share/issue/2c94edea5df7495f8ed74e31c23bf496/
            return (_a = this.advancedText.alignmentBounds) !== null && _a !== void 0 ? _a : this.advancedText.bounds;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ArrowComponent.prototype, "parentGroup", {
        get: function () {
            return this.props.parentGroup;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ArrowComponent.prototype, "id", {
        get: function () {
            return this.props.id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ArrowComponent.prototype, "isSelected", {
        get: function () {
            return this.props.selected;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ArrowComponent.prototype, "currentRotation", {
        get: function () {
            return 0;
        },
        enumerable: false,
        configurable: true
    });
    ArrowComponent.prototype.setPositionDelta = function (delta) {
        this.setState({
            start: add(this.props.start, delta),
            end: add(this.props.end, delta)
        });
    };
    ArrowComponent.prototype.setRotationDelta = function (delta, pivot) {
        var newStart = Pp(this.props.start).rotate(delta, pivot);
        var newEnd = Pp(this.props.end).rotate(delta, pivot);
        this.setState({
            start: newStart,
            end: newEnd,
        });
    };
    ArrowComponent.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        this.setState({
            start: nextProps.start,
            end: nextProps.end,
            label: nextProps.label,
            fontSize: nextProps.fontSize,
            textColor: nextProps.textColor,
            arrowThickness: nextProps.arrowThickness,
        });
    };
    ArrowComponent.prototype.componentWillUnmount = function () {
        // Remove all paper elements.
        this.arrowHead.remove();
        this.line.remove();
        this.hittableLine.remove();
        this.advancedText.remove();
        this.arrowHeadHint.remove();
        this.arrowTailHint.remove();
    };
    ArrowComponent.prototype.render = function () {
        var start = Pp(this.state.start);
        var end = Pp(this.state.end);
        // Displacement vector, points from start to end.
        var vector = end.subtract(start);
        this.line.segments[0].point = start;
        // Shorten the end by a bit so it does not overlap the arrow head.
        var lineLength = Math.max(0, vector.length - 10.0);
        this.line.segments[1].point = start.add(vector.normalize().multiply(lineLength));
        // Copy the segments over to the hittable line.
        this.hittableLine.segments[0].point = this.line.segments[0].point;
        this.hittableLine.segments[1].point = this.line.segments[1].point;
        this.arrowHead.position = end;
        this.arrowHead.rotation = vector.angle;
        this.arrowHead.scaling.x = clamp(vector.length / 20.0, 0.1, 1.0);
        // Set color.
        var arrowColor = "black";
        this.line.strokeColor = arrowColor;
        this.arrowHead.fillColor = arrowColor;
        // Set text colour.
        var textColor = this.state.textColor;
        this.textStyle.fillColor = textColor;
        // Set alignment.
        this.textStyle.textAlign = this.props.textAlign;
        // Selection outline.
        this.advancedText.container.selected = this.isSelected && !Debug.PRESENTATION_MODE;
        // Update label text. This needs to be done first before we update its position.
        // Note: Since this relies on setting the style, it needs to be called
        // after the line that sets up the text color.
        this.textStyle.fontSize = this.state.fontSize * 2;
        this.textStyle.fontFamily = this.props.fontFamily;
        var formattedText = getFormattedText(this.textCache, this.state.label, this.props.isChemMarkEnabled);
        this.advancedText.setText(formattedText, this.textStyle);
        // Place the text at the start of the arrow.
        // Note: This has to happen *after* setText.
        this.advancedText.setPosition(start.x, start.y);
        this.adjustLabel(vector.angle);
        if (this.props.endStyle == "none") {
            this.arrowHead.fillColor = SharedColors.transparent;
        }
        // Set thickness.
        this.line.strokeWidth = this.state.arrowThickness;
        this.hittableLine.strokeWidth = this.state.arrowThickness + 17.0;
        var arrowScale = this.state.arrowThickness / 3.0;
        this.arrowHead.scaling = P(arrowScale, arrowScale);
        // Adjust hint. Only show it when the arrow is selected.
        this.arrowHeadHint.position = end;
        this.arrowHeadHint.visible = this.props.showAdjustables;
        this.arrowTailHint.position = start;
        this.arrowTailHint.visible = this.props.showAdjustables;
        this.maybeUpdateUiElementsForZoom();
        // Show/Hide arrow.
        if (this.props.showArrow) {
            this.line.visible = true;
            this.hittableLine.visible = true;
            this.arrowHead.visible = true;
        }
        else {
            this.line.visible = false;
            this.hittableLine.visible = false;
            this.arrowHead.visible = false;
            this.arrowHeadHint.visible = false;
            this.arrowTailHint.visible = false;
        }
        return null;
    };
    // Adjust the label's properties based on the arrow's angle,
    // so that it does not overlap the arrow.
    ArrowComponent.prototype.adjustLabel = function (angle) {
        // Add some padding around the label.
        var bounds = this.advancedText.bounds.expand(P(20, 8));
        var vector = intersectCenterRayWithBox(bounds.width, bounds.height, angle);
        this.advancedText.container.position.x -= vector.x;
        this.advancedText.container.position.y -= vector.y;
    };
    // Draw an arrow, pointing to the right.
    ArrowComponent.prototype.createArrowHead = function () {
        var path = new Path(Segments([[0, -8]], [[0, 8]], [[20, 0]]));
        path.pivot = path.segments[2].point;
        path.closePath();
        return path;
    };
    ArrowComponent.prototype.maybeUpdateUiElementsForZoom = function () {
        if (this.props.zoom != this.lastUpdatedUiForZoom) {
            var zoom = this.props.zoom;
            var newScaling = P(1 / zoom, 1 / zoom);
            this.arrowHeadHint.scaling = newScaling;
            this.arrowTailHint.scaling = newScaling;
            this.lastUpdatedUiForZoom = zoom;
        }
    };
    ArrowComponent.prototype.forceUpdate = function (callback) {
        _super.prototype.forceUpdate.call(this, callback);
        // Reset text cache to allow rerender.
        this.textCache.input = "";
    };
    return ArrowComponent;
}(React.Component));
export default ArrowComponent;
/**
 * Returns the formatted text to render.
 */
function getFormattedText(cache, text, isChemMarkEnabled) {
    if (!isChemMarkEnabled) {
        // Check the cache.
        if (cache.mode != "basic" || cache.input != text) {
            cache.mode = "basic";
            cache.input = text;
            cache.result = ChemMark.parseBasic(text);
        }
        return cache.result;
    }
    var config = appStore.getState().chemMark;
    if (!appStore.getState().tier) {
        if (cache.mode != "cmlimited" || cache.input != text) {
            cache.mode = "cmlimited";
            cache.input = text;
            cache.result = ChemMark.parseSmartLimited(text, config);
        }
        return cache.result;
    }
    if (cache.mode != "cm" || cache.input != text) {
        cache.mode = "cm";
        cache.input = text;
        cache.result = ChemMark.parseSmart(text, config);
    }
    return cache.result;
}
/**
 * Computes the vector from the center of a box to its edge.
 * Used to position the text box at the end of an arrow
 * @param angle Angle in degrees, range = [-180, 180]
 */
function intersectCenterRayWithBox(width, height, angleDeg) {
    // Assuming fixed x, compute what y would be.
    var candidateX = (angleDeg >= -90 && angleDeg <= 90) ? width / 2 : -width / 2;
    var candidateY = Math.tan(angleDeg.toRadians()) * candidateX;
    // If resulting y is within the box, the intersection occurs on the left or right side.
    if (candidateY <= height / 2 && candidateY >= -height / 2) {
        return P(candidateX, candidateY);
    }
    // Otherwise it occurs on the top or bottom side.
    var y = (angleDeg >= 0) ? height / 2 : -height / 2;
    var x = y / Math.tan(angleDeg.toRadians());
    return P(x, y);
}
