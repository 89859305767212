var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Gradients } from "apparatus/gradients";
import { P, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var GummyBear = /** @class */ (function () {
    function GummyBear() {
    }
    GummyBear.prototype.render = function (appearance) {
        var scale = appearance.scale, color = appearance.color;
        var baseColor = color.color().alpha(0.93);
        var fillGradient = Gradients.cylinder(baseColor, 3);
        var outlineColor = baseColor.darken(0.5).string();
        var stroke = scale > 4
            ? "thicker"
            : scale > 3
                ? "thick"
                : scale > 1.8
                    ? "default"
                    : scale > 1.25
                        ? "thin"
                        : "thinner";
        var top = new Path(Segments([[23, 174], [16, 44], [-28, -14]], [[42, 110], [-39, -5], [-44, -6]], [[56, 181], [-70, 3], [20, -1]], [[98, 141], [-3, 20], [7, -38]], [[64, 62], [1, 20], [0, -16]], [[83, 11], [-1, 19], [1, -19]], [[29, -16], [30, -20], [-23, 16]])).mirrorX()
            .withStroke(stroke, outlineColor)
            .withGradientFill("right", fillGradient);
        var bottom = new Path(Segments([[19, 259], [-2, 4], [-19, -17]], [[53, 192], [-44, -6], [-44, -6]], [[31, 269], [-40, -19], [21, 10]], [[91, 248], [-8, 25], [14, -41]], [[72, 175], [-3, 28], [2, -16]], [[45, 138], [23, 16], [-30, -20]])).close().mirrorX()
            .withStroke(stroke, outlineColor)
            .withGradientFill("right", fillGradient);
        var eyeGradient = Gradients.box(baseColor.darken(0.3), { shift: 0.3, delta: 30 });
        var eye1 = new Path.Circle(P(27, 40), 12)
            .withGradientFill("right", eyeGradient);
        var eye2 = new Path.Circle(P(-27, 40), 12)
            .withGradientFill("right", eyeGradient);
        var nose = new Path(Segments([[10, 55], [-10, 0], [0, 15]])).mirrorX().close().withGradientFill("right", eyeGradient);
        var mouth = new Path(Segments([[17, 70], [-2, -9], [3, 10]], [[0, 66], [5, 11]])).withStroke(stroke, outlineColor).mirrorX();
        var highlights = new Group([
            new Path.Circle(P(48, 160), 18),
            new Path.Circle(P(52, 250), 25),
            new Path.Circle(P(-48, 160), 18),
            new Path.Circle(P(-52, 250), 25),
        ]);
        for (var _i = 0, _a = highlights.children; _i < _a.length; _i++) {
            var h = _a[_i];
            h.scale(1.0, 0.5);
            h.withGradientFill("down", [["#ffffff80", 0.0], ["#ffffff60", 1.0]]);
        }
        var graphic = new Group([bottom, top, eye1, eye2, mouth, nose, highlights]);
        graphic.scale(appearance.scale * 0.15);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(10).toShape(),
            watermark: graphic.bounds.bottomRight,
        };
    };
    GummyBear.properties = {
        label: "Gummy bear",
        defaultAppearance: {
            scale: 1.5,
            color: "#dc1610",
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 4.0, step: 0.1, unit: "%",
            },
            {
                spec: "color-wells", key: "color", label: "Colour",
                colors: [
                    { value: "#dc1610", label: "Red" },
                    { value: "#2ca6dc", label: "Blue" },
                    { value: "#f1e130", label: "Yellow" },
                    { value: "#7ad21a", label: "Green" },
                    { value: "#f19426", label: "Orange" },
                    { value: "#f1f1ef", label: "White" },
                ]
            },
        ],
    };
    GummyBear = __decorate([
        staticImplements()
    ], GummyBear);
    return GummyBear;
}());
export { GummyBear };
