var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApparatus } from "apparatus/library";
import { P } from "apparatus/library/common";
import { addArrowMutator } from "arrows/command";
import { Arrow } from "arrows/type";
import { addItems } from "store/actions";
import { ID } from "store/id";
import wrappedAppStore from "store/store";
import { Consts } from "vars";
export function setUpInitialDiagram() {
    // Place first items slightly off center so that new items
    // added by the user are visible.
    // Also account for the size of the sidebar
    var center = paper.project.view.center.add(P(Consts.sidebarWidth * 0.5 - 70, -30));
    var beaker = createApparatus("beaker", center);
    beaker.selected = true;
    beaker.liquid.meniscus.radius = 8;
    beaker.liquid.meniscus.enabled = true;
    var flask = createApparatus("conical flask", center.add(P(80, -120)));
    flask.rotation = -80;
    flask.liquid.pouring = {
        enabled: true, flowLength: 140, flowStrength: 0.8, fade: true
    };
    flask.liquid.amountRatio = 0.66;
    wrappedAppStore.dispatch(addItems([
        createApparatus("table", center.add(P(10, 72))),
        flask,
        beaker,
    ]));
    wrappedAppStore.dispatchFn("addArrow", addArrowMutator([__assign(__assign({}, Arrow.defaultArrow), { id: ID.mint("arrow"), start: center.subtract(P(130, 90)).toPlain(), end: center.subtract(P(60, 30)).toPlain(), isChemMarkEnabled: true, label: "H2O" })]));
}
