var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { mirrorY } from "apparatus/draw";
import { INFINITE_BOUNDS } from "apparatus/driver";
import { Gradients } from "apparatus/gradients";
import { P, Polar, R, Segments, SharedColors } from "apparatus/library/common";
import { Curve, drawCurve } from "apparatus/library/shapes/curve";
import { Arrays } from "ext/array";
import { Group, Path } from "paper";
var RED_COLOR = "#BD1E0C";
var BLACK_COLOR = "#333333";
var BLUE = "#338EDF";
var YELLOW = "#DFC934";
var DISABLE_SNAP_FOR = ["banana-side"];
var PLUG_OPTIONS = [
    { value: "none", label: "None", icon: "k,empty" },
    { value: "simple", label: "Simple", icon: "k,small-dot" },
    { value: "banana-side", label: "Banana (side)", icon: "k,banana-plug" },
    { value: "banana-back", label: "Banana (plugged)", icon: "k,banana-plug-side" },
];
var Cable = /** @class */ (function () {
    function Cable() {
        this.drivers = [
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ end1x: p.x, end1y: p.y }); }, fromAppearance: function (a) { return P(a.end1x, a.end1y); }, scale: 1.0, snapping: function (a, point) { return (!Arrays.includes(DISABLE_SNAP_FOR, a.plugL)
                    ? { type: "wire", at: point }
                    : undefined); } }),
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ pivot1x: p.x, pivot1y: p.y }); }, fromAppearance: function (a) { return P(a.pivot1x, a.pivot1y); }, scale: 1.0 }),
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ end2x: p.x, end2y: p.y }); }, fromAppearance: function (a) { return P(a.end2x, a.end2y); }, scale: 1.0, snapping: function (a, point) { return (!Arrays.includes(DISABLE_SNAP_FOR, a.plugL)
                    ? { type: "wire", at: point }
                    : undefined); } }),
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ pivot2x: p.x, pivot2y: p.y }); }, fromAppearance: function (a) { return P(a.pivot2x, a.pivot2y); }, scale: 1.0 }),
        ];
        this.shouldUseBoundsCenter = true;
    }
    Cable.prototype.render = function (appearance) {
        var _a = drawCurve(appearance), path = _a.path, xray = _a.xray;
        var hitPath = path.clone();
        path.withStroke("thick", appearance.color);
        path.strokeCap = "round";
        var graphic = new Group([path]);
        var color = appearance.color.color();
        {
            var startPlug = this.createPlug(appearance.plugL, color);
            if (startPlug) {
                if (appearance.plugL == "banana-side") {
                    var angle = path.segments[0].handleOut.angle + 180;
                    startPlug.rotation = angle;
                    // Extend the hit path.
                    hitPath.insertSegments(0, [
                        path.segments[0].point.add(Polar(startPlug.bounds.width, angle))
                    ]);
                }
                startPlug.position = path.segments[0].point;
                if (appearance.plugL == "banana-back") {
                    graphic.insertChild(-1, startPlug);
                }
                else {
                    graphic.addChild(startPlug);
                }
            }
        }
        {
            var endPlug = this.createPlug(appearance.plugR, color);
            if (endPlug) {
                var lastSegment = path.segments[path.segments.length - 1];
                if (appearance.plugR == "banana-side") {
                    var angle = lastSegment.handleIn.angle + 180;
                    endPlug.rotation = angle;
                    // Extend the hit path.
                    hitPath.addSegments([
                        lastSegment.point.add(Polar(endPlug.bounds.width, angle))
                    ]);
                }
                endPlug.position = lastSegment.point;
                if (appearance.plugR == "banana-back") {
                    graphic.insertChild(-1, endPlug);
                }
                else {
                    graphic.addChild(endPlug);
                }
            }
        }
        // Ends snap except for certain plug types.
        var snapping = [];
        if (!Arrays.includes(DISABLE_SNAP_FOR, appearance.plugL)) {
            snapping.push({ type: "wire", at: P(appearance.end1x, appearance.end1y) });
        }
        if (!Arrays.includes(DISABLE_SNAP_FOR, appearance.plugR)) {
            snapping.push({ type: "wire", at: P(appearance.end2x, appearance.end2y) });
        }
        return {
            graphic: graphic,
            xray: xray,
            hitShape: { type: "stroke", path: hitPath },
            pivot: P(0, 0),
            snapping: snapping,
        };
    };
    Cable.prototype.createPlug = function (plug, color) {
        if (plug === "none")
            return;
        if (plug === "banana-side") {
            return this.createBananaClip(color);
        }
        var darkerColor = color.darken(0.1);
        if (plug === "banana-back") {
            return this.createBananaClipSide(darkerColor);
        }
        // Simple.
        return new Path.Circle(P(0, 0), 5.0).withFill(darkerColor.hex());
    };
    Cable.prototype.createBananaClip = function (color) {
        var casing = new Path(Segments([[0, 0]], [[24, 0]], [[32, 8]], [[32, 14]], [[0, 14]])).close()
            .withGradientFill("down", Gradients.box(color));
        var connector = new Path(Segments([[0, -2]], [[7, -2]], [[15, -2], [-5, -2]], [[18, -2], , [1, 2]])).close();
        mirrorY(connector);
        connector.withGradientFill("down", Gradients.cylinder(SharedColors.gold.color()));
        connector.pivot = P(0, 0);
        connector.position = P(32, 11);
        var group = new Group([connector, casing]);
        group.pivot = P(0, 4);
        return group;
    };
    Cable.prototype.createBananaClipSide = function (color) {
        var darkerColor = color.darken(0.3).hex();
        var item = new Path.Rectangle(R(0, 0, 6, 14), 2.0).withStroke("thin", darkerColor);
        item.setRadialGradientFill([[darkerColor, 0.3], [color.hex(), 0.7]], { center: P(3, 3), edge: P(6, 14) });
        item.pivot = P(3, 3);
        return item;
    };
    Cable.properties = {
        label: "Cable",
        defaultAppearance: __assign(__assign({}, Curve.properties.defaultAppearance), { color: RED_COLOR, plugL: "none", plugR: "none" }),
        appearanceSpecs: [
            {
                spec: "radio", key: "color", label: "Colour",
                options: [
                    { value: RED_COLOR, label: "Red" },
                    { value: BLACK_COLOR, label: "Black" },
                    { value: YELLOW, label: "Yellow" },
                    { value: BLUE, label: "Blue" },
                ],
            },
            {
                spec: "toggle-buttons", key: "plugL", label: "End 1", size: "icon",
                options: PLUG_OPTIONS,
            },
            {
                spec: "toggle-buttons", key: "plugR", label: "End 2", size: "icon",
                options: PLUG_OPTIONS,
            },
        ],
    };
    return Cable;
}());
export { Cable };
