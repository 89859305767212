import { strokeWidth } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { Interpolator, P, Pivot, R } from "apparatus/library/common";
import "ext/color";
import { Group, Path, Shape } from "paper";
import { Random } from "random/random";
var BASE_COLOR = "#e29866".color();
var CorkRing = /** @class */ (function () {
    function CorkRing() {
        /** Older version for calculating the diameter of the circular cutout. */
        this.diameterInterpolator = new Interpolator([80, 210], [80, 320]);
        this.cutoutOffsetInterpolator = new Interpolator([80, 210], [8, 16]);
    }
    CorkRing.prototype.render = function (appearance) {
        var width = appearance.width;
        var base = new Path.Rectangle(R(0, 0, width, 30, Pivot.BOTTOM_CENTER), 2.0);
        var shape;
        if (appearance.crossSection) {
            // Calculate the circular hole cutout as follows:
            // https://math.stackexchange.com/questions/564058/calculate-the-radius-of-a-circle-given-the-chord-length-and-height-of-a-segment
            // where h = 30 - 8 = 22
            // and l = width - (some small offset)
            var h = 22, l = width - this.cutoutOffsetInterpolator.interpolate(width);
            var radius = (4 * h * h + l * l) / (8 * h);
            var hole1 = new Path.Rectangle(R(0, 10, width - 50, 50, Pivot.BOTTOM_CENTER));
            var hole2 = new Path.Circle(P(0, -8 - radius), radius);
            shape = base.subtract(hole1).subtract(hole2);
        }
        else {
            shape = base;
        }
        var graphic = applyCorkTexture(shape);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: [
                { type: "placeable_surface", start: P(-1, -8), end: P(1, -8) },
                graphic.placeable(),
            ],
            watermark: P(width / 2, -5)
        };
    };
    CorkRing.properties = {
        label: "Cork ring",
        defaultAppearance: {
            width: 120,
            crossSection: false
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Width",
                min: 80, max: 210, unit: "mm"
            },
            {
                spec: "checkbox", key: "crossSection", label: "Cross-section", subLabel: "Show"
            }
        ],
    };
    return CorkRing;
}());
export { CorkRing };
/**
 * Creates a cork-looking object from input of plain shapes.
 * Output contains the original input shape, modified.
 */
function applyCorkTexture(shape) {
    var r = new Random(166212);
    var mask = shape.clone();
    // Create a clipped group, containing the cork texture
    var corkLayer = new Group([mask]);
    corkLayer.clipped = true;
    // Generate cork texture
    for (var x = mask.bounds.left; x < mask.bounds.right; x += 20) {
        for (var y = mask.bounds.top; y < mask.bounds.bottom; y += 20) {
            var size = r.randomCenteredValue(2.0) + 4.0;
            var corkSpot = Shape.Circle(P(x, y).add(r.randomUniformVector(8.0)), size);
            corkSpot.fillColor = "#00000010";
            corkLayer.addChild(corkSpot);
        }
    }
    // Set up shape.
    shape.setGradientFill("right", Gradients.cylinder(BASE_COLOR));
    shape.withStroke(strokeWidth("default"), BASE_COLOR.ladd(-20).sadd(-20).hex());
    // Assemble.
    var finalGroup = new Group([shape, corkLayer]);
    return finalGroup;
}
