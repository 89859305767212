import { mirrorX, setDefaultStyleWithStroke } from "apparatus/draw";
import { R, Segments, SharedColors, SharedGradients } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var Nail = /** @class */ (function () {
    function Nail() {
    }
    Nail.prototype.render = function (appearance) {
        var length = appearance.length;
        var thickness = appearance.thickness;
        var nail = new Path(Segments([[thickness * 2 + 3, 0]], [[thickness, 3]], [[thickness, length - 10]], [[0, length]]));
        mirrorX(nail);
        nail.closePath();
        setDefaultStyleWithStroke("thinner", nail);
        nail.fillColor = (function () {
            switch (appearance.color) {
                case "light":
                    return SharedColors.lightMetal;
                case "dark":
                    return SharedColors.mediumMetal;
                case "rusty":
                    return "#bc7832";
            }
        })();
        var graphic = new Group([nail]);
        if (appearance.additional == "metal strip") {
            var stripThickness = thickness * 2 + 4;
            var metalStrip = Shape.Rectangle(R(-stripThickness / 2, length / 2 - 25, stripThickness, 40))
                .withGradientFill("right", SharedGradients.metal)
                .withStroke("thinner", SharedColors.mediumMetal);
            graphic.addChild(metalStrip);
        }
        return { graphic: graphic, hitShape: graphic.bounds.expand(10).toShape() };
    };
    Nail.properties = {
        label: "Nail",
        defaultAppearance: {
            length: 75,
            thickness: 2,
            color: "light",
            additional: "none"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "length", label: "Length",
                min: 60, max: 120
            },
            {
                spec: "slider", key: "thickness", label: "Thickness",
                min: 2, max: 4, step: 0.1
            },
            {
                spec: "radio", key: "color", label: "Colour",
                options: [
                    { value: "light", label: "Light metal" },
                    { value: "dark", label: "Dark metal" },
                    { value: "rusty", label: "Rusty" }
                ]
            },
            {
                spec: "radio", key: "additional", label: "Additions",
                options: [
                    { value: "none", label: "Nothing" },
                    { value: "metal strip", label: "Metal strip" },
                ]
            }
        ],
    };
    return Nail;
}());
export { Nail };
