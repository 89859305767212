export var SharedAppearance;
(function (SharedAppearance) {
    /** Toggle buttons for toggling between real and zoomed view */
    SharedAppearance.viewRealZoom = {
        spec: "toggle-buttons", key: "view", size: "large", label: "View size",
        options: [
            { value: "real", label: "Real size", icon: "k,1to1" },
            { value: "zoom", label: "Zoomed", icon: "r,magnifying-glass-plus" },
        ],
    };
})(SharedAppearance || (SharedAppearance = {}));
