import { Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
/** Thickness of the connecting band. */
var BAND_THICKNESS = 6;
/**
 * A plastic joint clip, also known as Keck clip.
 * See http://www.sciencemadness.org/smwiki/index.php/Keck_clip
 */
var PlasticJointClip = /** @class */ (function () {
    function PlasticJointClip() {
    }
    PlasticJointClip.prototype.render = function (appearance) {
        // Diameter of top ring.
        var topDiameter = appearance.diameter;
        // Diameter of bottom ring.
        var bottomDiameter = appearance.diameter + 5;
        // Distance between the rings.
        var distanceApart = 15;
        // Thickness of each ring.
        var ringThickness = 8;
        var color = baseColor(appearance.color);
        // Create the rings.
        var topRing = Shape.Rectangle(R(0, 0, topDiameter, ringThickness, Pivot.CENTER), 2.0);
        var bottomRing = Shape.Rectangle(R(0, distanceApart, bottomDiameter, ringThickness, Pivot.CENTER), 2.0);
        setRingGradient(topRing, color);
        setRingGradient(bottomRing, color);
        // Create the connecting bands.
        var bandLeft = new Path(Segments([[-topDiameter / 2, 0], , [-10, 0]], [[-bottomDiameter / 2, distanceApart], [-8, 0]]));
        setBandGradient(bandLeft, color);
        var bandMiddle = new Path(Segments([[0, 0]], [[0, distanceApart]]));
        setBandGradient(bandMiddle, color);
        bandMiddle.strokeCap = "square";
        var bandRight = new Path(Segments([[topDiameter / 2, 0], , [10, 0]], [[bottomDiameter / 2, distanceApart], [8, 0]]));
        setBandGradient(bandRight, color);
        // Assemble together.
        var graphic = new Group([topRing, bottomRing, bandLeft, bandRight, bandMiddle]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    PlasticJointClip.properties = {
        label: "Plastic Joint Clip",
        defaultAppearance: {
            diameter: 30,
            color: "red"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 20, max: 50
            },
            {
                spec: "color-wells", key: "color", label: "Colour",
                colors: [
                    { value: "red", label: "Red" },
                    { value: "green", label: "Green", tier: "boost" },
                    { value: "blue", label: "Blue", tier: "boost" },
                    { value: "yellow", label: "Yellow", tier: "boost" },
                    { value: "white", label: "White", tier: "boost" },
                ],
            }
        ],
    };
    return PlasticJointClip;
}());
export { PlasticJointClip };
function baseColor(input) {
    switch (input) {
        case "red": return "#de5a4e";
        case "green": return "#32a852";
        case "blue": return "#2266d4";
        case "yellow": return "#e0d836";
        case "white": return "#dddddd";
    }
}
function setRingGradient(item, color) {
    var darkerColor = color.color().darken(0.15).hex();
    var outlineColor = color.color().darken(0.4).hex();
    item.setGradientFill("right", [[darkerColor, 0.0], [color, 0.5], [darkerColor, 1.0]]);
    item.withStroke("thinner", outlineColor);
}
function setBandGradient(item, color) {
    var darkColor = color.color().darken(0.2).hex();
    var darkerColor = color.color().darken(0.4).hex();
    item.setGradientStroke("down", [[darkColor, 0.0], [darkerColor, 1.0]]);
    item.strokeCap = "butt";
    item.strokeWidth = BAND_THICKNESS;
}
