var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Modal from "react-overlays/Modal";
import { Transition } from "react-transition-group";
var TRANSITION_DURATION_MS = 250;
var fadeStyles = {
    entering: 'tr-fade-show',
    entered: 'tr-fade-show',
};
var Fade = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(Transition, __assign({}, props, { timeout: TRANSITION_DURATION_MS }), function (status, innerProps) {
        return React.cloneElement(children, __assign(__assign({}, innerProps), { className: "tr-fade " + fadeStyles[status] + " " + children.props.className }));
    }));
};
var growStyles = {
    entering: 'tr-grow-entering',
    entered: 'tr-grow-show',
    exiting: 'tr-grow-exiting',
    exited: 'tr-grow-default'
};
var Grow = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(Transition, __assign({}, props, { timeout: TRANSITION_DURATION_MS }), function (status, innerProps) {
        return React.cloneElement(children, __assign(__assign({}, innerProps), { className: "tr-grow " + growStyles[status] + " " + children.props.className }));
    }));
};
export var CommonModal = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, children = _a.children, title = _a.title, center = _a.center, _b = _a.icon, icon = _b === void 0 ? "" : _b, badge = _a.badge, _c = _a.iconSwapOpacity, iconSwapOpacity = _c === void 0 ? false : _c, _d = _a.extraClass, extraClass = _d === void 0 ? "" : _d, onShow = _a.onShow, onEnter = _a.onEnter;
    var Backdrop = function (props) { return React.createElement("div", __assign({}, props, { className: "common-modal-backdrop" })); };
    var iconCss = icon == "loading"
        ? "l fa-spinner fa-pulse"
        : "d " + icon + (iconSwapOpacity ? " fa-swap-opacity" : "");
    // const width = 600;
    return React.createElement(Modal, { show: isOpen, backdrop: "static", className: "common-modal-container", renderBackdrop: Backdrop, onBackdropClick: function () { return setIsOpen(false); }, onEscapeKeyDown: function () { return setIsOpen(false); }, onShow: onShow, transition: Grow, onEnter: onEnter, backdropTransition: Fade, renderDialog: function (props) {
            return React.createElement("div", __assign({}, props),
                React.createElement("div", { className: "common-modal-backdrop-click-catcher-hack", onClick: function () { return setIsOpen(false); } }),
                React.createElement("div", { className: "common-modal " + extraClass },
                    React.createElement("button", { className: "common-modal-close", onClick: function () { return setIsOpen(false); } },
                        React.createElement("i", { className: "fas fa-xmark" })),
                    title && React.createElement("div", { className: "common-modal-header" },
                        React.createElement("h2", null,
                            React.createElement("i", { className: "mr-2 fa-fw fa" + iconCss }),
                            title,
                            " ",
                            badge)),
                    React.createElement("div", { className: center ? "text-center" : "" }, children)));
        } });
};
