var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Gradients } from "apparatus/gradients";
import { clamp, P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var MAX_DIAMETER = 50;
var MIN_DIAMETER = 12;
var HOLE_GAP = 10;
var MIN_GAP = 2;
var SeptumSeal = /** @class */ (function () {
    function SeptumSeal() {
    }
    SeptumSeal.prototype.render = function (appearance) {
        var diameter = appearance.diameter;
        var colorStr = appearance.color;
        var color = colorStr.color();
        var radius = diameter / 2;
        var bottomHeight = 16 + diameter / 4;
        var topHeight = bottomHeight + 5;
        var graphic = new Group();
        if (appearance.state == "up") {
            var topRing = new Path.Rectangle(R(0, -topHeight, diameter + 8, 4, Pivot.CENTER), 1)
                .withGradientFill("right", Gradients.cylinder(color.darken(0.1)));
            var top_1 = new Path(Segments([[radius + 3, -topHeight], , [0, topHeight * 0.6]], [[radius, 0], [3, -1]])).mirrorX().withGradientFill("right", Gradients.cylinder(color, 4));
            var bottom = new Path(Segments([[radius, -1]], [[radius, bottomHeight - 3]], [[radius - 3, bottomHeight]])).mirrorX().withGradientFill("right", Gradients.cylinder(color, 7));
            graphic.addChildren([bottom, top_1, topRing]);
            for (var i = 0; i < 4; i++) {
                graphic.addChild(new Path(Segments([[-radius - 1, bottomHeight * (i * 0.2 + 0.15)]], [[radius + 1, bottomHeight * (i * 0.2 + 0.25)]])).withStroke(2.0, color.darken(0.05).hex()));
            }
        }
        else {
            var topRing = new Path.Rectangle(R(0, topHeight, diameter + 12, 4, Pivot.CENTER), 1)
                .withGradientFill("right", Gradients.cylinder(color.darken(0.1)));
            var top_2 = new Path(Segments([[radius + 2, -4], , [5, 0]], [[radius + 4, topHeight], [0, -topHeight]])).mirrorX().withGradientFill("right", Gradients.cylinder(color, 4));
            graphic.addChildren([top_2, topRing,]);
        }
        var hitShape = graphic.rectHitShape();
        // Add holes.
        if (appearance.hole > 0) {
            var maskYTop = hitShape.bounds.top - 2;
            var maskYBottom = hitShape.bounds.bottom + 2;
            var mask = new Path(Segments(
            // Start in the top right corner, go clock wise
            [[radius + 10, maskYTop]], [[radius + 10, maskYBottom + 2]], [[-radius - 10, maskYBottom + 2]], [[-radius - 10, maskYTop]])).close();
            if (appearance.hole == 1) {
                var holeDiameter = Math.min(appearance.holeDiameter, diameter - 5);
                var holeRadius = holeDiameter / 2;
                mask.addSegments(Segments([[-holeRadius, maskYTop]], [[-holeRadius, maskYBottom]], [[holeRadius, maskYBottom]], [[holeRadius, maskYTop]]));
            }
            else { // Two holes
                var space = diameter - 5;
                var holeDiameter = appearance.holeDiameter, holeDiameter2 = appearance.holeDiameter2;
                var excess = holeDiameter + holeDiameter2 + HOLE_GAP - space;
                var gap = HOLE_GAP;
                if (excess > 0) {
                    // Too cramped. Need to distribute the excess evenly.
                    // First, absorb it in the gap.
                    if (excess < (HOLE_GAP - MIN_GAP)) {
                        // All absorbed in the gap.
                        gap -= excess;
                    }
                    else {
                        // Absorb in the gap and distribute between the two holes evenly.
                        gap = MIN_GAP;
                        excess -= (HOLE_GAP - MIN_GAP);
                        var totalDiameter = holeDiameter + holeDiameter2;
                        holeDiameter -= excess * (holeDiameter / totalDiameter);
                        holeDiameter2 -= excess * (holeDiameter2 / totalDiameter);
                    }
                }
                var totalSpace = holeDiameter + holeDiameter2 + gap;
                mask.addSegments(Segments(
                // Hole 1
                [[-totalSpace / 2, maskYTop]], [[-totalSpace / 2, maskYBottom]], [[-totalSpace / 2 + holeDiameter, maskYBottom]], [[-totalSpace / 2 + holeDiameter, maskYTop]], 
                // Hole 2
                [[totalSpace / 2 - holeDiameter2, maskYTop]], [[totalSpace / 2 - holeDiameter2, maskYBottom]], [[totalSpace / 2, maskYBottom]], [[totalSpace / 2, maskYTop]]));
            }
            mask.fillColor = "red";
            graphic.insertChild(0, mask);
            graphic.clipped = true;
        }
        return {
            graphic: graphic,
            hitShape: hitShape,
            pivot: P(0, 0),
            snapping: {
                type: "pluggable", at: P(0, 0), requestDiameter: requestDiameter,
            }
        };
    };
    SeptumSeal.properties = {
        label: "Septum seal",
        defaultAppearance: {
            diameter: 22,
            state: "up",
            color: "#E24D49",
            hole: 0,
            holeDiameter: 15,
            holeDiameter2: 5,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: MIN_DIAMETER, max: MAX_DIAMETER, unit: "mm",
            },
            {
                spec: "radio", key: "state", label: "State",
                options: [
                    { value: "up", label: "Unfolded" },
                    { value: "down", label: "Folded down" },
                ],
            },
            {
                spec: "color-wells", key: "color", label: "Colour",
                colors: [
                    { value: "#E24D49", label: "Red" },
                    { value: "#C9C3B3", label: "White" },
                    { value: "#2A8FAD", label: "Blue", tier: "boost" },
                    { value: "#466967", label: "Green", tier: "boost" },
                ]
            },
            {
                spec: "toggle-buttons", key: "hole", label: "Holes", size: "text",
                options: [
                    { value: 0, label: "0" },
                    { value: 1, label: "1" },
                    { value: 2, label: "2" },
                ]
            },
            {
                spec: "slider", key: "holeDiameter", label: "Hole diameter",
                min: 5, max: 40,
                visible: function (a) { return a.hole == 1; },
            },
            {
                spec: "slider", key: "holeDiameter", label: "#1 diameter",
                min: 2, max: 40,
                visible: function (a) { return a.hole == 2; },
            },
            {
                spec: "slider", key: "holeDiameter2", label: "#2 diameter",
                min: 2, max: 40,
                visible: function (a) { return a.hole == 2; },
            }
        ],
    };
    SeptumSeal = __decorate([
        staticImplements()
    ], SeptumSeal);
    return SeptumSeal;
}());
export { SeptumSeal };
function requestDiameter(diameter) {
    return { diameter: clamp(diameter, MIN_DIAMETER, MAX_DIAMETER).round(0) };
}
