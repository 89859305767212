import { Group, Path } from "paper";
import { setDefaultStyleWithStroke } from "../../draw";
import { P, Segments } from "../common";
var Tongs = /** @class */ (function () {
    function Tongs() {
    }
    Tongs.prototype.render = function (appearance) {
        var wing = new Path(wingShapes[appearance.type](appearance.size));
        wing.rotate(appearance.opening, P(0, 0));
        // Create second wing by vertically flipping it.
        var wing2 = wing.clone();
        wing2.scale(1, -1.0, P(0, 0));
        var graphic = new Group([wing, wing2]);
        setDefaultStyleWithStroke("thick", graphic);
        if (appearance.view == "side") {
            graphic.scale(1, 0.6);
        }
        return { graphic: graphic, hitShape: graphic.bounds.toShape() };
    };
    Tongs.properties = {
        label: "Beaker/Crucible Tongs",
        defaultAppearance: {
            type: "beaker",
            size: 90,
            opening: 5,
            view: "top",
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "type", label: "Type",
                options: [
                    { value: "beaker", label: "Beaker tongs" },
                    { value: "crucible", label: "Crucible tongs", tier: "boost" },
                ],
                allowSelectingTierLockedOptions: true,
            },
            {
                spec: "slider", key: "size", label: "Size",
                min: 70, max: 120,
            },
            {
                spec: "slider", key: "opening", label: "Opening",
                min: 0, max: 35,
            },
            {
                spec: "radio", key: "view", label: "View angle",
                options: [
                    { value: "top", label: "From top" },
                    { value: "side", label: "Side" },
                ],
            }
        ],
        isBoostRequired: function (a) { return a.type == "crucible"; }
    };
    return Tongs;
}());
export { Tongs };
var wingShapes = {
    "beaker": function (size) {
        return Segments([[-40, -10]], [[-44, -30], [5, 0]], [[-70, -30], undefined, [-15, 0]], [[-70, -5], [-15, 0]], [[-15, -5], undefined, [8, 0]], [[15, 5], undefined, [30, 10]], [[15 + size, 3], [-30, 10]]);
    },
    "crucible": function (size) {
        var gap = size - 65;
        return Segments([[-40, -10]], [[-44, -30], [5, 0]], [[-70, -30], undefined, [-15, 0]], [[-70, -5], [-15, 0]], [[-15, -5], undefined, [8, 0]], [[15, 5], [-15, 0]], [[15 + gap, 5], undefined, [5, 0]], [[15 + gap + 25, 12], [-7, 0], [7, 0]], [[15 + gap + 50, 5], [-5, 0]], [[15 + size, 3]]);
    }
};
