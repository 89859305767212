var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { softShade } from "apparatus/draw";
import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { createJack } from "apparatus/library/physics/electric/common";
import { Group, Path, PointText, Shape } from "paper";
var DOT_COLOR = "#a7b2c5";
var Multimeter = /** @class */ (function () {
    function Multimeter() {
    }
    Multimeter.prototype.render = function (appearance) {
        var casing = new Path(Segments([[43, 4], [-30, -7], [2, 1]], [[45, 11], [0, -3], [0, 19]], [[43, 88], [0, -23], [0, 20]], [[44, 153], [2, -27], [0, 4]], [[41, 169], [4, -3], [-12, 10]])).close().mirrorX()
            .withStroke("default")
            .withFill(appearance.casingColor);
        var panel = new Path(Segments([[37, 8], [-27, -3], [2, 0]], [[35, 164], [3, -1], [-9, 6]])).close().mirrorX()
            .withFill("#535761")
            .withStroke(4.0, "#00000020");
        // Create the dial.
        var dialRing = new Path.Circle(P(0, 0), 25)
            .setRadialGradientFill([["#474b53", 0.0], ["#474b53", 0.7], ["#3c4047", 1.0]]);
        var dialHandle = new Path.Rectangle(R(0, 0, 8, 44, Pivot.CENTER), 3.0)
            .withFill("#5e636e");
        // Shade behind the handle
        var shade = softShade("full", null, P(0, -25), P(0, 25), "#3c4047", 10);
        var dot = new Path.Circle(P(0, -18), 2.0).withFill(DOT_COLOR);
        var dial = new Group([dialRing, shade, dialHandle, dot]);
        var dots = 16;
        for (var i = 0; i < dots; i++) {
            dial.addChild(new Path.Circle(P(0, 30).rotate(i * (360 / dots), P(0, 0)), 1.0)
                .withFill(DOT_COLOR));
        }
        dial.withPosition(P(0, 105));
        // Screen.
        var screen = Shape.Rectangle(R(0, 15, 64, 40, Pivot.TOP_CENTER), 2)
            .withStroke(2.0, "white")
            .withFill(SharedColors.lcdScreen);
        var text = new PointText({
            point: P(28, 40),
            content: appearance.reading,
            fillColor: 'black',
            fontFamily: 'sans-serif',
            fontSize: 15,
            justification: "right",
        });
        // Buttons.
        var buttons = [-1.5, -0.5, 0.5, 1.5].map(function (x) {
            return new Path.Rectangle(R(x * 17, 64, 14, 7, Pivot.CENTER), 3.0)
                .withFill("#df9f16")
                .withStroke(2.0, "#3c4047");
        });
        var jacks = [
            createJack("red").withPosition(P(-22, 157)),
            createJack("black").withPosition(P(0, 157)),
            createJack("red").withPosition(P(22, 157)),
        ];
        var graphic = new Group(__spreadArray(__spreadArray([
            casing, panel, dial,
            screen, text
        ], buttons), jacks));
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: jacks.map(function (j) { return ({ type: "wireport", at: j.position }); })
        };
    };
    Multimeter.properties = {
        label: "Multimeter",
        defaultAppearance: {
            reading: "12 V",
            casingColor: "#F93021"
        },
        appearanceSpecs: [
            { spec: "text", key: "reading", label: "Reading", maxLength: 10 },
            {
                spec: "color-wells", key: "casingColor", label: "Colour",
                colors: [
                    { value: "#F93021", label: "Red" },
                    { value: "#FF9C44", label: "Orange" },
                    { value: "#FFCB21", label: "Yellow" },
                    { value: "#24A877", label: "Green" },
                ],
            }
        ],
    };
    return Multimeter;
}());
export { Multimeter };
