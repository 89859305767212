var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { addEdgeLipsReversed, addMarkers, setDefaultStyle } from "apparatus/draw";
import { clamp, P, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var Eudiometer = /** @class */ (function () {
    function Eudiometer() {
    }
    Eudiometer.prototype.render = function (appearance) {
        var width = appearance.width, height = appearance.height;
        var tube = new Path(Segments([[width / 2, 0]], [[width / 2, height], , [0, width / 1.55]])).mirrorX();
        addEdgeLipsReversed(tube, { lipSize: 2 });
        var liquidMask = tube.clone();
        setDefaultStyle(tube);
        var graphic = new Group([tube]);
        addMarkers(graphic, P(0, height), 70, P(0, -10), clamp(width / 2 - 4, 3, 8));
        graphic.rotate(180);
        liquidMask.rotate(180);
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: tube.rectHitShape()
        };
    };
    Eudiometer.properties = {
        label: "Eudiometer",
        canContainLiquids: true,
        defaultAppearance: {
            width: 20,
            height: 400,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Diameter",
                min: 10, max: 30
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 150, max: 500,
            }
        ],
    };
    Eudiometer = __decorate([
        staticImplements()
    ], Eudiometer);
    return Eudiometer;
}());
export { Eudiometer };
