import { Gradients } from "apparatus/gradients";
import { P, Pivot, Segments, SharedColors } from "apparatus/library/common";
import { Color, Gradient, Group, Path, Segment, Shape } from "paper";
import { metalRod, strokeWidth } from "../draw";
import { lerp, R } from "./common";
var CLAMP_LENGTH = 180;
var clampSizeProp = {
    spec: "slider", key: "clampSize", label: "Clamp size",
    min: 30, max: 130
};
var clampColorProp = {
    spec: "radio", key: "clampColor", label: "Material",
    options: [
        { value: "metal", label: "Metal" },
        { value: "blue", label: "Blue rubber" },
    ]
};
/**
 * https://imgaz1.staticbg.com/thumb/large/oaupload/banggood/images/65/38/1992bd64-406b-476d-bb34-b6d63962c28d.JPG.webp
 */
var Clamp = /** @class */ (function () {
    function Clamp() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 20, maxX: CLAMP_LENGTH + 20,
                minY: -20, maxY: -20,
                fromAppearance: function (a) { return P(a.shift * CLAMP_LENGTH + 20, -20); },
                toAppearance: function (f, p) { return f({ shift: (p.x - 20) / CLAMP_LENGTH }); },
                scale: 1.0,
            }
        ];
    }
    Clamp.prototype.render = function (appearance) {
        var length = CLAMP_LENGTH;
        var handleThickness = 15;
        var handleWidth = appearance.clampSize;
        // Create the bolt.
        var bolt = createBolt();
        // Create the rod and the handler.
        var _a = metalRod(P(0, 0), P(length, 0)), rod = _a[0], rodHighlight = _a[1];
        var handleR = R(length, -handleThickness / 2, handleWidth, handleThickness);
        var handle = new Path.Rectangle(handleR, 4.0);
        setHandleColor(handle, appearance.clampColor);
        var handleHighlight = new Path.Line(P(6, 0), P(handleWidth - 6, 0));
        handleHighlight.position.x += length;
        handleHighlight.position.y -= handleThickness / 2 - 3;
        handleHighlight.strokeWidth = 3.0;
        handleHighlight.strokeColor = "#ffffff40";
        handleHighlight.strokeCap = "round";
        // Move the rod.
        var shiftable = new Group([rod, rodHighlight, handle, handleHighlight]);
        shiftable.position.x -= lerp(0.95, 0, appearance.shift) * length;
        // Assemble and compute hit shape.
        var graphic = new Group([shiftable, bolt]);
        var hitShape = Shape.Rectangle(graphic.bounds);
        return {
            graphic: graphic,
            hitShape: hitShape,
            pivot: P(0, 0),
            snapping: { type: "attachable", at: P(0, 0) }
        };
    };
    Clamp.properties = {
        label: "Retort clamp",
        flippable: true,
        defaultAppearance: {
            shift: 0.8,
            clampSize: 45,
            clampColor: "blue",
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "shift", label: "Shift",
                min: 0, max: 1, step: 0.01
            },
            clampSizeProp,
            clampColorProp,
        ]
    };
    return Clamp;
}());
export { Clamp };
var FrontClamp = /** @class */ (function () {
    function FrontClamp() {
    }
    FrontClamp.prototype.render = function (appearance) {
        var clampSize = appearance.clampSize, clampColor = appearance.clampColor;
        // Clamp.
        var back = Shape.Rectangle(R(0, 0, clampSize, 10, Pivot.CENTER), 5.0)
            .withGradientFill("right", Gradients.reflected(["#888888", 0.0], ["#666666", 0.25], ["#444444", 0.4]));
        var reflection = new Path.Line(P(-clampSize / 2 + 5, -2), P(clampSize / 2 - 5, -2))
            .withStroke(2.0, "#ffffff30");
        var left = Shape.Circle(P(-clampSize / 2, 0), 7.0);
        var right = Shape.Circle(P(clampSize / 2, 0), 7.0);
        setHandleColorForFront(left, clampColor);
        setHandleColorForFront(right, clampColor);
        // Bolt (side view).
        var bolt = Shape.Rectangle(R(-5, 0, 8, 20, Pivot.CENTER_RIGHT), 2.0);
        bolt.withStroke("thin", SharedColors.stroke)
            .withGradientFill("down", [["#888888", 0.0], ["#666666", 0.2], [SharedColors.darkMetal, 1.0]]);
        var graphic = new Group([bolt, back, reflection, left, right]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(15).toShape(),
            pivot: P(0, 0),
            snapping: { type: "attachable", at: P(0, 0) }
        };
    };
    FrontClamp.properties = {
        label: "Retort clamp (Front view)",
        flippable: true,
        defaultAppearance: {
            clampSize: 45,
            clampColor: "blue"
        },
        appearanceSpecs: [
            clampSizeProp,
            clampColorProp,
        ],
    };
    return FrontClamp;
}());
export { FrontClamp };
export function createBolt() {
    var steps = 7;
    var stepAngle = 360 / steps;
    var segmentA = new Segment(P(0, -10), P(-1, 2), P(2, -1));
    var segmentB = new Segment(P(0, -10).rotate(stepAngle / 2, P(0, 0)), P(-2, -1).rotate(stepAngle / 2), P(1, 2).rotate(stepAngle / 2));
    var bolt = new Path();
    for (var i = 0; i < steps; i++) {
        segmentA = segmentA.clone();
        rotateSegment(segmentA, stepAngle, P(0, 0));
        segmentB = segmentB.clone();
        rotateSegment(segmentB, stepAngle, P(0, 0));
        bolt.addSegments([segmentA, segmentB]);
    }
    bolt.closePath();
    bolt.withStroke("thin", SharedColors.stroke);
    bolt.setRadialGradientFill([
        ["#555555", 0.4],
        [SharedColors.darkMetal, 0.5],
        ["#666666", 0.6],
        [SharedColors.darkMetal, 0.7],
        ["#666666", 0.95],
    ], { center: P(0, 0) });
    return bolt;
}
function rotateSegment(segment, angle, pivot) {
    segment.point = segment.point.rotate(angle, pivot);
    if (segment.handleIn)
        segment.handleIn = segment.handleIn.rotate(angle);
    if (segment.handleOut)
        segment.handleOut = segment.handleOut.rotate(angle);
}
var Ring = /** @class */ (function () {
    function Ring() {
    }
    Ring.prototype.render = function (appearance) {
        var length = 180;
        var ringWidth = appearance.ringSize;
        var ringHeight = 5.0 + ringWidth * 0.15;
        // Create the bolt.
        var bolt = createBolt();
        // Create the rod.
        var _a = metalRod(P(0, 0), P(length, 0)), rod = _a[0], rodHighlight = _a[1];
        // Create the ring.
        var ring = Shape.Ellipse(R(length, -ringHeight / 2, ringWidth, ringHeight));
        ring.strokeWidth = 8.0;
        ring.strokeColor = SharedColors.darkMetal;
        // Create ring reflections.
        var reflectionSize = ringWidth * 0.3;
        var reflection1 = ringReflection(ring, 1, reflectionSize);
        var reflection2 = ringReflection(ring, -2.3, reflectionSize);
        reflection1.position.x += length;
        reflection2.position.x += length;
        // Ring group
        var ringGroup = new Group([ring, reflection1, reflection2]);
        if (appearance.hole) {
            // Add a mask for the back of the ring.
            var ringCenter = length + ringWidth / 2;
            var holeWidth = Math.min(appearance.ringSize - 10, appearance.holeWidth);
            ringGroup.insertChild(0, new Path(Segments(
            // Start top right corner
            [[length + ringWidth + 5, -ringHeight]], [[length + ringWidth + 5, ringHeight]], [[length - 5, ringHeight]], [[length - 5, -ringHeight]], 
            // Actual hole.
            [[ringCenter - holeWidth / 2, -ringHeight]], [[ringCenter - holeWidth / 2, 0]], [[ringCenter + holeWidth / 2, 0]], [[ringCenter + holeWidth / 2, -ringHeight]])).close().withFill("red"));
            ringGroup.clipped = true;
        }
        // Move the rod.
        var shiftable = new Group([rod, rodHighlight, ringGroup]);
        shiftable.position.x -= lerp(0.95, 0, appearance.shift) * length;
        // Assemble and compute hit shape.
        var graphic = new Group([shiftable, bolt]);
        return {
            graphic: graphic,
            hitShape: graphic.strokeBounds.toShape(),
            pivot: P(0, 0),
            snapping: { type: "attachable", at: P(0, 0) }
        };
    };
    Ring.properties = {
        label: "Retort Ring",
        flippable: true,
        defaultAppearance: {
            shift: 0.8,
            ringSize: 77,
            hole: false,
            holeWidth: 120
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "shift", label: "Shift",
                min: 0, max: 1, step: 0.01
            },
            {
                spec: "slider", key: "ringSize", label: "Clamp size",
                min: 35, max: 130
            },
            { spec: "header", header: "Mask", icon: "mask", toggleKey: "hole" },
            {
                spec: "slider", key: "holeWidth", label: "Size",
                min: 4, max: 120, visible: function (a) { return a.hole; }
            },
        ],
    };
    return Ring;
}());
export { Ring };
function ringReflection(ring, angle, width) {
    var ringWidth = ring.bounds.width;
    var ringHeight = ring.bounds.height;
    var reflectionGradient = new Gradient([["#ffffff60", 0.0], ["#ffffff00", 1.0]], true);
    var reflection = Shape.Ellipse(R(0, 0, width, 8));
    reflection.fillColor = new Color(reflectionGradient, P(width / 2, 4), P(width, 8));
    reflection.position.x = ringWidth / 2 + Math.cos(angle) * ringWidth / 2;
    reflection.position.y = Math.sin(angle) * ringHeight / 2;
    return reflection;
}
function setHandleColor(handle, color) {
    var gradient;
    switch (color) {
        case "blue":
            handle.strokeColor = "#26527f";
            gradient = new Gradient([
                ["#3c82c9", 0.1],
                ["#56a0ea", 0.3],
                ["#478cd1", 0.7],
                ["#3c82c9", 0.9],
            ]);
            break;
        case "metal":
        default:
            handle.strokeColor = SharedColors.darkMetal;
            gradient = new Gradient([
                ["#777777", 0.1],
                ["#aaaaaa", 0.22],
                ["#888888", 0.7],
                ["#444444", 1.0],
            ]);
    }
    handle.strokeWidth = strokeWidth("thin");
    handle.fillColor = new Color(gradient, handle.bounds.leftCenter, handle.bounds.rightCenter);
}
function setHandleColorForFront(item, color) {
    var stroke;
    var fill;
    switch (color) {
        case "blue":
            stroke = "#26527f";
            fill = "#3c82c9";
            break;
        case "metal":
            stroke = SharedColors.darkMetal;
            fill = "#888888";
            break;
    }
    item.withFill(fill);
    item.withStroke("thin", stroke);
}
