import { setDefaultStyle } from "apparatus/draw";
import { Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { addEdgeLips, smoothCorner } from "../draw";
var ThieleTube = /** @class */ (function () {
    function ThieleTube() {
    }
    ThieleTube.prototype.render = function (appearance) {
        var outer = new Path(Segments([[0, 0]], [[0, 130], undefined, [3, 15]], [[30, 130], [-10, 5]], [[100, 90]], [[25, 50]], [[25, 0]]));
        smoothCorner(outer.segments[3], 30.0);
        addEdgeLips(outer);
        var inner = new Path(Segments([[25, 62]], [[80, 90]], [[25, 118]]));
        inner.closePath();
        smoothCorner(inner.segments[1], 15.0);
        var tube = outer.subtract(inner);
        // Scale everything.
        var pivot = tube.bounds.center;
        tube.scale(appearance.scale, pivot);
        outer.scale(appearance.scale, pivot);
        inner.scale(appearance.scale, pivot);
        var hitShape = outer.clone();
        var liquidMask = tube;
        var graphic = new Group([inner, outer]);
        setDefaultStyle(graphic);
        return { graphic: graphic, hitShape: hitShape, liquidMask: liquidMask };
    };
    ThieleTube.properties = {
        label: "Thiele Tube",
        canContainLiquids: true,
        flippable: true,
        defaultAppearance: {
            scale: 1.0,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 1.5, step: 0.05, unit: "%"
            }
        ],
    };
    return ThieleTube;
}());
export { ThieleTube };
