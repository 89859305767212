var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { mirrorX, setDefaultStyle } from "apparatus/draw";
import { DEFAULT_LIQUID_DATA, P, Segments } from "apparatus/library/common";
import { Path } from "paper";
var WatchGlass = /** @class */ (function () {
    function WatchGlass() {
    }
    WatchGlass.prototype.render = function (appearance) {
        var radius = appearance.diameter / 2;
        var height = radius / 5;
        var graphic = new Path(Segments([[radius, -height]], [[0, 0], [radius / 1.5, 0]]));
        mirrorX(graphic);
        var liquidMask = graphic.clone();
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.strokeBounds.expand(10).toShape(),
            snapping: {
                type: "placeable",
                at: P(0, 2)
            }
        };
    };
    WatchGlass.properties = {
        label: "Watch glass",
        canContainLiquids: true,
        defaultLiquidData: __assign(__assign({}, DEFAULT_LIQUID_DATA), { amountRatio: 0.0 }),
        defaultAppearance: {
            diameter: 75,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 60, max: 100
            }
        ],
    };
    return WatchGlass;
}());
export { WatchGlass };
