import { P, R } from "apparatus/library/common";
import { createJack } from "apparatus/library/physics/electric/common";
import { Group, Path } from "paper";
/**
 * Common unitilies for drawing plate-mounted components, such as the knifeswitch.
 */
export var Mounted;
(function (Mounted) {
    var PLATE_THICKNESS = 7;
    var PORT_EDGE_OFFSET_X = 20;
    var PORT_EDGE_OFFSET_Y = 11;
    Mounted.appearanceSpecs = [
        { spec: "checkbox", key: "swapSockets", label: "Swap sockets" },
    ];
    Mounted.defaultAppearance = {
        swapSockets: false,
    };
    function renderPlate(_a) {
        var _b = _a.width, width = _b === void 0 ? 100 : _b, _c = _a.height, height = _c === void 0 ? 40 : _c, appearance = _a.appearance, _d = _a.offset, offset = _d === void 0 ? P(0, 0) : _d;
        var base = new Group([
            // Shade.
            new Path.Rectangle(R(0, 0, width, height + PLATE_THICKNESS), 4.0).withFill("#444444"),
            // Top.
            new Path.Rectangle(R(0, 0, width, height), 4.0).withFill("#666666"),
            // Outline.
            new Path.Rectangle(R(0, 0, width, height + PLATE_THICKNESS), 4.0).withStroke("default"),
        ]);
        var jackPositions = [
            P(PORT_EDGE_OFFSET_X, height - PORT_EDGE_OFFSET_Y),
            P(width - PORT_EDGE_OFFSET_X, height - PORT_EDGE_OFFSET_Y)
        ];
        var swapper = appearance.swapSockets ? 1 : 0;
        var jack1 = createJack("red").withPosition(jackPositions[swapper]);
        var jack2 = createJack("black").withPosition(jackPositions[1 - swapper]);
        var jacks = new Group([jack1, jack2]);
        // Apply offset
        base.position = base.position.add(offset);
        jacks.position = jacks.position.add(offset);
        return {
            base: base,
            jacks: jacks,
            snaps: jackPositions.map(function (j) { return ({ type: "wireport", at: j.add(offset) }); })
        };
    }
    Mounted.renderPlate = renderPlate;
})(Mounted || (Mounted = {}));
