var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, SharedColors } from "apparatus/library/common";
import { createJack } from "apparatus/library/physics/electric/common";
import { Group, Path, PointText, Shape } from "paper";
var meterAppearanceSpecs = [
    { spec: "text", key: "reading", label: "Reading", },
    {
        spec: "toggle-buttons",
        key: "terminals",
        label: "Terminals",
        size: "text",
        options: [
            { value: "-+", label: "- +" },
            { value: "+-", label: "+ -" },
        ]
    }
];
var DigitalVoltmeter = /** @class */ (function () {
    function DigitalVoltmeter() {
    }
    DigitalVoltmeter.prototype.render = function (appearance) {
        return renderMeter(appearance, "V");
    };
    DigitalVoltmeter.properties = {
        label: "Digital Voltmeter",
        defaultAppearance: {
            reading: "12.00",
            terminals: "-+",
        },
        appearanceSpecs: __spreadArray([], meterAppearanceSpecs),
    };
    return DigitalVoltmeter;
}());
export { DigitalVoltmeter };
var DigitalAmmemter = /** @class */ (function () {
    function DigitalAmmemter() {
    }
    DigitalAmmemter.prototype.render = function (appearance) {
        return renderMeter(appearance, "A");
    };
    DigitalAmmemter.properties = {
        label: "Digital Ammeter",
        defaultAppearance: {
            reading: "12.00",
            terminals: "-+",
        },
        appearanceSpecs: __spreadArray([], meterAppearanceSpecs),
    };
    return DigitalAmmemter;
}());
export { DigitalAmmemter };
function renderMeter(appearance, unit) {
    var casing = new Path.Rectangle(R(0, 0, 90, 62, Pivot.TOP_CENTER), 3.0)
        .withStroke("default")
        .withGradientFill("down", Gradients.box(SharedColors.deviceGrey.color(), { shift: 0.15 }));
    // Don't forget to update snapping too!.
    var leftPort = P(-30, 46);
    var rightPort = P(30, 46);
    var redPositiveTerminal = createJack("red");
    var blackNegativeTerminal = createJack("black");
    if (appearance.terminals === "+-") {
        redPositiveTerminal.withPosition(leftPort);
        blackNegativeTerminal.withPosition(rightPort);
    }
    else if (appearance.terminals === "-+") {
        redPositiveTerminal.withPosition(rightPort);
        blackNegativeTerminal.withPosition(leftPort);
    }
    var screen = Shape.Rectangle(R(0, 8, 70, 25, Pivot.TOP_CENTER), 2)
        .withStroke(2.0, "white")
        .withFill(SharedColors.lcdScreen);
    var backgroundText = new PointText({
        point: P(0, 59),
        content: unit,
        fillColor: '#d1d5d9',
        fontFamily: 'sans-serif',
        fontSize: 78,
        justification: "center",
    });
    var text = new PointText({
        point: P(30, 27),
        content: appearance.reading,
        fillColor: 'black',
        fontFamily: 'sans-serif',
        fontSize: 15,
        justification: "right",
    });
    var unitText = new PointText({
        point: P(-30, 27),
        content: unit,
        fillColor: "#61893f",
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        fontSize: 15,
        justification: "left",
    });
    var graphic = new Group([casing, backgroundText, redPositiveTerminal, blackNegativeTerminal, screen, text, unitText]);
    return {
        graphic: graphic,
        hitShape: casing.bounds.expand(10).toShape(),
        snapping: [
            { type: "wireport", at: leftPort },
            { type: "wireport", at: rightPort },
        ]
    };
}
