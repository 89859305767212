import { StyledTextCreator } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, R, Segments, SharedColors } from "apparatus/library/common";
import { createJack } from "apparatus/library/physics/electric/common";
import { Group, Path, PointText } from "paper";
var switchText = new StyledTextCreator({
    fontFamily: "sans-serif",
    fontSize: 7,
    fillColor: "#ffffff",
});
var markerTexts = new StyledTextCreator({
    fontFamily: "sans-serif",
    fontSize: 8,
    fillColor: "#888",
    justification: "center"
});
var PowerPack = /** @class */ (function () {
    function PowerPack() {
    }
    PowerPack.prototype.render = function (appearance) {
        var casing = new Path.Rectangle(R(0, 0, 185, 100), 3.0)
            .withStroke("default")
            .withGradientFill("down", Gradients.box(SharedColors.deviceGrey.color(), { shift: 0.1 }));
        var dial = new Path.Circle(P(0, 0), 20)
            .withStroke("default").withGradientFill("right-down", Gradients.cylinder("#555555".color()));
        var dialGroup = new Group([dial]);
        var dialRange = 270;
        var maxVoltage = 12;
        var minVoltage = 0;
        for (var voltage = minVoltage; voltage <= maxVoltage; voltage++) {
            var angle = ((voltage - minVoltage) / (maxVoltage - minVoltage) - 0.5) * dialRange;
            var marker = new Path.Line(P(0, -24), P(0, -27)).withStroke(1.0, "#888888");
            marker.rotate(angle, P(0, 0));
            dialGroup.addChild(marker);
            var text = markerTexts.create(voltage.toString(), P(0, -32).rotate(angle, P(0, 0)).add(P(0, 3)), voltage == appearance.voltage ? { fontWeight: "bold", fillColor: "#333" } : {});
            dialGroup.addChild(text);
        }
        var currentVoltageMarker = new Path.Line(P(0, -6), P(0, -18)).withStroke("default", "#ccc");
        currentVoltageMarker.rotate(((appearance.voltage - minVoltage) / (maxVoltage - minVoltage) - 0.5) * dialRange, P(0, 0));
        dialGroup.addChild(currentVoltageMarker);
        dialGroup.position = P(73, 55);
        // Connection jacks.
        // NOTE: if adjusting these DON'T FORGET to update snapping as well!.
        var acJack1 = createJack("yellow").withPosition(P(130, 40));
        var acJack2 = createJack("yellow").withPosition(P(160, 40));
        var dcJack1 = createJack("red").withPosition(P(130, 80));
        var dcJack2 = createJack("black").withPosition(P(160, 80));
        var acLabel = jackLabel("ac").withPosition(P(145, 25));
        var dcLabel = jackLabel("dc").withPosition(P(145, 65));
        // Switch.
        var switchButton = new Path.Rectangle(R(15, 20, 13, 30), 2.0)
            .withStroke(3.0, "#222222")
            .withGradientFill("down", [["#333333", 0.0], ["#555555", 0.45], ["#888888", 0.55], ["#999999", 1.0]]);
        var oText = switchText.create("O", P(22, 29), { fillColor: "#999999" });
        var iText = switchText.create("I", P(22, 44));
        var graphic = new Group([
            casing, dialGroup, acJack1, acJack2, dcJack1, dcJack2, acLabel, dcLabel, switchButton,
            oText, iText,
        ]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: [
                casing.placeable(),
                { type: "wireport", at: P(130, 40) },
                { type: "wireport", at: P(160, 40) },
                { type: "wireport", at: P(130, 80) },
                { type: "wireport", at: P(160, 80) },
            ]
        };
    };
    PowerPack.properties = {
        label: "Power pack",
        defaultAppearance: {
            voltage: 8
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "voltage", label: "Voltage",
                min: 0, max: 12
            }
        ],
    };
    return PowerPack;
}());
export { PowerPack };
var jackLabel = function (text) { return new Group([
    new PointText({
        point: P(0, 4),
        content: text.toUpperCase(),
        fillColor: "#888",
        fontFamiy: "sans-serif",
        fontSize: 9,
        justification: "center",
    }),
    new Path(Segments([[-15, 5]], [[-15, 0]], [[-9, 0]])).withStroke(1.0, "#888"),
    new Path(Segments([[15, 5]], [[15, 0]], [[9, 0]])).withStroke(1.0, "#888"),
]); };
