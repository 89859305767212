var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Sentry from "@sentry/react";
import { g } from "analytics";
import { clearToken, getCrashedFile, removeCrashedFile, retrieveToken, saveCrashFile } from "api/auth/storage";
import { disposeMarkSymbol } from "apparatus/draw";
import { commandProcessor } from "command/command";
import { IconButton } from "components/button";
import { Selected } from "editing/selection";
import { openDiagram } from "file/api";
import { DiagramDatas } from "file/loading";
import { setUpKeyboard } from "keyboard";
import { EditorContainer } from "layout/editor/editor_container";
import { LoginModalProvider, SharedModalsProvider } from "layout/modals/shared_modals";
import { TabbedSidebar } from "layout/sidebar/tabbed_sidebar";
import { ScreenTooSmallWarning } from "layout/top_bar/extras";
import { TopBar } from "layout/top_bar/top_bar";
import { clog } from "log";
import { PropertyPanelAdapter } from "panel/property_panel_adapter";
import * as React from "react";
import { Component } from "react";
import { connect, shallowEqual } from "react-redux";
import { logIn } from "store/actions";
import store, { extractDebugInfoFromStore } from "store/store";
import { Bcn } from "support/beacon";
import { TierContextProvider } from "tiers/context-provider";
import { callApi } from "../api/api";
import { setGloballyStrokeThicknessTheme } from "../apparatus/common";
import { FileContext } from "../file/context";
import { shouldShowWelcome, WelcomeModal2 } from "./modals/welcome";
var DELAY_SUPPORT_SERVICE_LOAD_MS = 500;
var SENTRY_REPORT_DIALOG_OPTIONS = {
    title: "Oops! Chemix has unexpectedly crashed.",
    subtitle: "We're sorry about this, our team has been notified. Please help us by providing additional information.",
    labelComments: "Please describe in as much detail the last steps you took before the error happened:",
};
/** Top-level App component. */
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App(props) {
        var _this = _super.call(this, props) || this;
        /** Reference to the editor container. Needed for image export. */
        _this.editorContainerRef = React.createRef();
        _this.render = function () {
            if (_this.state.hasError) {
                return React.createElement("div", { key: "error", className: "error-bar" },
                    React.createElement("i", { className: "fa fa-dizzy fa-2x mr-2" }),
                    "Oops! Unfortunately Chemix has crashed.",
                    React.createElement("div", { className: "d-inline-block" },
                        React.createElement("button", { type: "button", className: "btn btn-outline-light mx-1", onClick: function () { g('CrashBar', 'SentryUserReport'); Sentry.showReportDialog(__assign({ eventId: _this.state.errorId }, SENTRY_REPORT_DIALOG_OPTIONS)); } }, "Report crash"),
                        React.createElement("button", { type: "button", className: "btn btn-outline-light mx-1", onClick: function () { g('CrashBar', 'OpenChat'); Bcn.ask(); } }, "Chat to us"),
                        React.createElement("button", { type: "button", className: "btn btn-outline-light mx-1", onClick: function () { g('CrashBar', 'Reload'); window.location.reload(); } }, "Reload Chemix")));
            }
            return (React.createElement(FileContext.Provider, { value: _this },
                React.createElement(LoginModalProvider, { auth: _this.props.auth },
                    React.createElement(TierContextProvider, { auth: _this.props.auth, tier: _this.props.tier },
                        React.createElement(SharedModalsProvider, { auth: _this.props.auth },
                            React.createElement(TopBar, { key: "topBar", tool: _this.props.tool, auth: _this.props.auth, file: _this, cloudDiagramTitle: _this.props.cloudDiagramTitle, isMaximized: _this.state.hideApparatusPanel && _this.state.collapsePropertyPanel, setMaximized: function (state) { return _this.setState({ hideApparatusPanel: state, collapsePropertyPanel: state }); } }),
                            React.createElement(EditorContainer, __assign({ key: "editorContainer", ref: _this.editorContainerRef }, _this.props, { onPaperReady: function () { return _this.onPaperReady(); } })),
                            _this.state.isPaperReady && React.createElement(PropertyPanelAdapter, { collapsed: _this.state.collapsePropertyPanel, onToggleCollapsed: function (state) { return _this.setState({ collapsePropertyPanel: state }); }, key: "PropertyPanelAdapter", selected: _this.props.selected, app: _this.editorContainerRef.current, theme: _this.props.theme }),
                            !_this.state.hideApparatusPanel
                                && React.createElement(TabbedSidebar, { key: "Sidebar", onHidePanel: function () { return _this.setState({ hideApparatusPanel: true }); } }),
                            _this.state.hideApparatusPanel
                                && React.createElement(IconButton, { icon: "plus", extraClass: "show-sidebar-button", buttonLabel: "Show sidebar", onClick: function () { return _this.setState({ hideApparatusPanel: false }); } }),
                            React.createElement(ScreenTooSmallWarning, null),
                            React.createElement(WelcomeModal2, { isOpen: _this.state.showWelcomeScreen, setIsOpen: function (open) { return _this.setState({ showWelcomeScreen: open }); } }))))));
        };
        _this.state = { hasError: false, showWelcomeScreen: false, isPaperReady: false, hideApparatusPanel: false, collapsePropertyPanel: false };
        return _this;
    }
    App.prototype.onPaperReady = function () {
        this.setState({ isPaperReady: true });
    };
    App.prototype.componentDidMount = function () {
        var _this = this;
        // Set up keyboard short cuts.
        setUpKeyboard({
            toggleMaximized: function () {
                var isMaximized = _this.state.hideApparatusPanel && _this.state.collapsePropertyPanel;
                _this.setState({ hideApparatusPanel: !isMaximized, collapsePropertyPanel: !isMaximized });
            }
        });
        // Hide the loading message.
        $("#loading-message").addClass("loaded");
        // Welcome screen
        this.setState({ showWelcomeScreen: shouldShowWelcome() });
        // Load support service (Beacon).
        Bcn.init();
        setTimeout(function () { return Bcn.load(); }, DELAY_SUPPORT_SERVICE_LOAD_MS);
        // Check for crashed file.
        var maybeCrashedFile = getCrashedFile();
        if (maybeCrashedFile) {
            if (confirm("It looks like your previous session crashed. Would you like to recover the last diagram?")) {
                openDiagram({
                    id: "",
                    title: "",
                    thumbnail: "",
                    modified: new Date(),
                    created: new Date(),
                    rawData: maybeCrashedFile.documentSerialized,
                }, /* isTemplate */ false);
                Sentry.addBreadcrumb({ category: "app", level: "info", message: "Recover crashed file" });
                g("Crash", "Recover crashed file");
            }
            // Remove regardless of the user choosing to reload it or not.
            removeCrashedFile();
        }
        // If there is a stored token, trigger a fetch to see
        // if it is still valid. If it is, set up a session
        // from it.
        this.maybeRestoreAuthSession();
        // Animate in the app. This is done by a CSS transition,
        // by adding the "loaded" class.
        $("#app").addClass("loaded");
        // Load Twitter widgets.
        var twitterScript = document.createElement("script");
        twitterScript.src = "https://platform.twitter.com/widgets.js";
        twitterScript.async = true;
        document.body.appendChild(twitterScript);
    };
    App.prototype.componentDidUpdate = function (prevProps) {
        var forceRerenderComponents = false;
        // Update theme.
        if (prevProps.theme.strokeThickness != this.props.theme.strokeThickness) {
            setGloballyStrokeThicknessTheme(this.props.theme.strokeThickness);
            // Force re-render every component.
            disposeMarkSymbol();
            forceRerenderComponents = true;
        }
        // Check ChemMark props.
        if (!shallowEqual(prevProps.chemMark, this.props.chemMark)) {
            forceRerenderComponents = true;
        }
        // ForceRerenderComponents
        if (forceRerenderComponents) {
            clog("Force rerender components");
            for (var _i = 0, _a = this.props.items; _i < _a.length; _i++) {
                var item = _a[_i];
                var component = this.editorContainerRef.current.getApparatusComponent(item.id);
                component === null || component === void 0 ? void 0 : component.forceCompleteRerender();
                component === null || component === void 0 ? void 0 : component.forceUpdate();
            }
            for (var _b = 0, _c = this.props.arrows; _b < _c.length; _b++) {
                var arrow = _c[_b];
                var component = this.editorContainerRef.current.getObjectComponent(arrow.id);
                component.forceUpdate();
            }
        }
    };
    App.prototype.exportImages = function () {
        // This is bit hacky, we expose the export image methods on
        // the editor container so that we can call into its
        // child editor canvas.
        return this.editorContainerRef.current.exportImages();
    };
    App.prototype.exportThumbnail = function () {
        // This is also hacky.
        return this.editorContainerRef.current.exportThumbnail();
    };
    App.prototype.maybeRestoreAuthSession = function () {
        var token = retrieveToken();
        if (!token) {
            clog("No token found to restore session from.");
            return;
        }
        callApi("reauth", "GET", token, undefined, {
            success: function (response) {
                if (response.type == "success") {
                    store.dispatch(logIn({
                        provider: response.provider,
                        jwtToken: response.jwt,
                        user: response.user,
                        // This should presumably be false.
                        firstTimeLogin: response.firstTimeLogin,
                        tier: response.tier,
                    }));
                }
                else {
                    clearToken();
                    clog("Auth not restored from stored token.");
                }
            },
            generic: function () {
                // On error, clear the token.
                clearToken();
            }
        });
    };
    // Error handling.
    App.getDerivedStateFromError = function () {
        return { hasError: true, showWelcomeScreen: false, isPaperReady: true, collapsePropertyPanel: false, hideApparatusPanel: false };
    };
    App.prototype.componentDidCatch = function (error, errorInfo) {
        var _this = this;
        // Report to Sentry: https://docs.sentry.io/platforms/javascript/react/
        Sentry.withScope(function (scope) {
            scope.setExtra("componentStack", errorInfo.componentStack);
            scope.setExtra("store", extractDebugInfoFromStore());
            scope.setTag("fatalcrash", "yes");
            Sentry.addBreadcrumb({ category: "app", level: "fatal", message: "Error captured at error boundary" });
            var eventId = Sentry.captureException(error);
            // Only set the errorId once.
            if (!_this.state.errorId) {
                g("Crash");
                _this.setState({ errorId: eventId });
                Sentry.showReportDialog(__assign({ eventId: eventId }, SENTRY_REPORT_DIALOG_OPTIONS));
                // Save a crash file.
                // Revert last command and save state to local storage. Wait a bit because the crash
                // triggered by __tonngokhong__ doesn't seem to register immediately.
                // This may be called multiple times on error, so only call it once.
                setTimeout(function () {
                    commandProcessor.undo();
                    saveCrashFile(DiagramDatas.getCurrentDocument());
                }, 1000);
            }
        });
    };
    return App;
}(Component));
export { App };
// React-Redux.
/** Converts the Redux state to App component's props. */
var mapStateToProps = function (store, _) {
    var _a;
    var selectedItems = store.items.filter(function (item) { return item.selected; });
    var selectedArrows = store.arrows.filter(function (arrow) { return arrow.selected; });
    return {
        zoom: store.zoom,
        items: store.items,
        arrows: store.arrows,
        selected: new Selected(selectedItems, selectedArrows),
        tool: store.tool,
        auth: store.auth,
        tier: store.tier,
        cloudDiagramTitle: (_a = store.currentCloudDiagram) === null || _a === void 0 ? void 0 : _a.title,
        theme: store.theme,
        renderArea: store.renderArea,
        // Needed to trigger update on config change.
        chemMark: store.chemMark,
    };
};
/** Container Component for App. */
export var ConnectedApp = connect(mapStateToProps)(App);
