var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { addToGroup, removeFromGroup } from "store/actions";
import { ID } from "store/id";
import { default as store, GroupsOperationData } from "store/store";
var AddArrow = /** @class */ (function () {
    function AddArrow(arrows) {
        this.arrows = arrows;
        this.groupInformation = new GroupsOperationData();
        for (var _i = 0, arrows_1 = arrows; _i < arrows_1.length; _i++) {
            var arrow = arrows_1[_i];
            if (arrow.parentGroup) {
                this.groupInformation.add(arrow.parentGroup, arrow.id);
            }
        }
    }
    AddArrow.prototype.execute = function () {
        if (this.groupInformation.hasData()) {
            store.dispatch(addToGroup(this.groupInformation));
        }
        store.dispatchFn("AddArrow", addMutator(this.arrows));
    };
    AddArrow.prototype.undo = function () {
        store.dispatchFn("RemoveArrow", removeMutator(this.arrows.map(function (a) { return a.id; })));
        if (this.groupInformation.hasData()) {
            store.dispatch(removeFromGroup(this.groupInformation));
        }
    };
    return AddArrow;
}());
export { AddArrow };
var DeleteArrows = /** @class */ (function () {
    function DeleteArrows(arrowData) {
        this.arrowData = arrowData;
        this.groupInformation = new GroupsOperationData();
        for (var _i = 0, arrowData_1 = arrowData; _i < arrowData_1.length; _i++) {
            var arrow = arrowData_1[_i];
            if (arrow.parentGroup) {
                this.groupInformation.add(arrow.parentGroup, arrow.id);
            }
        }
    }
    DeleteArrows.prototype.execute = function () {
        store.dispatchFn("DeleteArrow", removeMutator(this.arrowData.map(function (a) { return a.id; })));
        if (this.groupInformation.hasData()) {
            store.dispatch(removeFromGroup(this.groupInformation));
        }
    };
    DeleteArrows.prototype.undo = function () {
        // Add group information first
        if (this.groupInformation.hasData()) {
            store.dispatch(addToGroup(this.groupInformation));
        }
        store.dispatchFn("Undo-DeleteArrow", addMutator(this.arrowData));
    };
    return DeleteArrows;
}());
export { DeleteArrows };
/** Generic method to update Arrow Data. Should replace all the UpdateArrowX commands. */
var UpdateArrowProperties = /** @class */ (function () {
    function UpdateArrowProperties(values) {
        this.valuesMap = {};
        this.previousValuesMap = {};
        for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
            var value = values_1[_i];
            this.valuesMap[value.id.id] = value.value;
            this.previousValuesMap[value.id.id] = value.previousValue;
        }
    }
    UpdateArrowProperties.prototype.execute = function () {
        var _this = this;
        store.dispatchFn("UpdateArrowProperties", function (store) { return mutateArrowWithPartial(store, _this.valuesMap); });
    };
    UpdateArrowProperties.prototype.undo = function () {
        var _this = this;
        store.dispatchFn("UpdateArrowProperties", function (store) { return mutateArrowWithPartial(store, _this.previousValuesMap); });
    };
    return UpdateArrowProperties;
}());
export { UpdateArrowProperties };
// Mutator methods that modify the store.
function addMutator(data) {
    return function (state) {
        var _a;
        var newState = __assign({}, state);
        (_a = newState.arrows).push.apply(_a, data);
        return newState;
    };
}
function removeMutator(ids) {
    return function (state) {
        var newState = __assign({}, state);
        newState.arrows = state.arrows.filter(ID.notMatching(ids));
        return newState;
    };
}
var mutateArrowWithPartial = function (store, mutations) { return (__assign(__assign({}, store), { arrows: store.arrows.map(function (a) { return mutations[a.id.id] !== undefined ? __assign(__assign({}, a), mutations[a.id.id]) : a; }) })); };
export var addArrowMutator = addMutator;
