import { mirrorX } from "apparatus/draw";
import { P } from "apparatus/library/common";
import { Path } from "paper";
var Sellotape = /** @class */ (function () {
    function Sellotape() {
    }
    Sellotape.prototype.render = function (appearance) {
        var width = appearance.width, length = appearance.length;
        var graphic = new Path([]);
        graphic.moveTo(P(length / 2, 0));
        var alternate = false;
        for (var y = 3; y <= width; y += 3) {
            graphic.lineBy(P(alternate ? 3 : -3, 3));
            alternate = !alternate;
        }
        graphic.lineBy(P((alternate ? 1 : -1) * width % 3, width % 3));
        mirrorX(graphic);
        graphic.close();
        switch (appearance.color) {
            case "transparent":
                graphic
                    .withStroke("thinner", "#9edef0")
                    .withGradientFill("right-down", [
                    ["#e1f6fc80", 0.3],
                    ["#ffffff80", 0.5],
                    ["#e1f6fc80", 0.7],
                ]);
                break;
            case "black":
                graphic
                    .withStroke("thinner", "#000000")
                    .withGradientFill("right-down", [
                    ["#22252f", 0.3],
                    ["#30333e", 0.5],
                    ["#23242c", 0.7],
                ]);
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(10).toShape(),
        };
    };
    Sellotape.properties = {
        label: "Adhesive Tape",
        defaultAppearance: {
            width: 12,
            length: 100,
            color: "transparent",
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Width",
                min: 10, max: 30,
            },
            {
                spec: "slider", key: "length", label: "Length",
                min: 25, max: 120
            },
            {
                spec: "radio", key: "color", label: "Color",
                options: [
                    { value: "transparent", label: "Transparent" },
                    { value: "black", label: "Black" },
                ]
            }
        ],
    };
    return Sellotape;
}());
export { Sellotape };
