import { SEARCH_BOX_ID } from "components/searchbox";
import clipboard from "editing/clipboard";
import { deleteSelected } from "editing/deleting";
import { redo, undo } from "editing/undo_redo";
import Flags from "flags";
import { Consts, Debug } from "vars";
import { selectTool } from "./tools";
/** Key codes. */
export var Keys;
(function (Keys) {
    Keys[Keys["DELETE"] = 46] = "DELETE";
    Keys[Keys["ENTER"] = 13] = "ENTER";
    Keys[Keys["ESCAPE"] = 27] = "ESCAPE";
    Keys[Keys["BACKSPACE"] = 8] = "BACKSPACE";
    Keys[Keys["_1"] = 49] = "_1";
    Keys[Keys["_2"] = 50] = "_2";
    Keys[Keys["_3"] = 51] = "_3";
    Keys[Keys["_4"] = 52] = "_4";
    Keys[Keys["C"] = 67] = "C";
    Keys[Keys["D"] = 68] = "D";
    Keys[Keys["E"] = 69] = "E";
    Keys[Keys["F"] = 70] = "F";
    Keys[Keys["N"] = 78] = "N";
    Keys[Keys["O"] = 79] = "O";
    Keys[Keys["P"] = 80] = "P";
    Keys[Keys["Q"] = 81] = "Q";
    Keys[Keys["R"] = 82] = "R";
    Keys[Keys["S"] = 83] = "S";
    Keys[Keys["V"] = 86] = "V";
    Keys[Keys["X"] = 88] = "X";
    Keys[Keys["Y"] = 89] = "Y";
    Keys[Keys["Z"] = 90] = "Z";
    Keys[Keys["LEFT"] = 37] = "LEFT";
    Keys[Keys["UP"] = 38] = "UP";
    Keys[Keys["RIGHT"] = 39] = "RIGHT";
    Keys[Keys["DOWN"] = 40] = "DOWN";
    Keys[Keys["QUESTION_MARK"] = 191] = "QUESTION_MARK";
})(Keys || (Keys = {}));
function isArrowKeys(e) {
    return (e.which >= Keys.LEFT && e.which <= Keys.DOWN);
}
/** Returns true if this keyboard event should not be handled by Chemix. */
function shouldHandleEvent(e) {
    // Key code not set.
    if (!e.keyCode)
        return false;
    // Do not handle if the target is a text input.
    if ($(e.target).is("input"))
        return false;
    // Also do not handle if the target is a text area.
    if ($(e.target).is("textarea"))
        return false;
    // Or a select.
    if ($(e.target).is("select"))
        return false;
    // Do not handle if the focus is on the slider handle.
    // and arrow keys are used.
    if ($(e.target).hasClass("ui-slider-handle") && isArrowKeys(e))
        return false;
    // Do not handle if tutorial is running.
    if ($(".introjs-overlay").length > 0)
        return false;
    // Do not handle while a context menu is focused.
    if ($(e.target).hasClass("rc-menu__item") && isArrowKeys(e))
        return false;
    // This event is safe to handle.
    return true;
}
/** Return focus to main app so shortcuts work. */
export function focusMainApp() {
    $("body").focus();
}
export function registerKeyboardShortcutHandler(handler) {
    $("body").on("keydown", function (e) {
        if (shouldHandleEvent(e)) {
            handler(e);
        }
    });
}
export function setUpKeyboard(_a) {
    var toggleMaximized = _a.toggleMaximized;
    $("body").attr('tabindex', 0).on("keydown", function (e) {
        if (!shouldHandleEvent(e))
            return;
        var isCtrlDown = e.ctrlKey || e.metaKey;
        var handled = true;
        // Note the precedence, keys with modifiers trump the same key without.
        if (e.keyCode == Keys.DELETE || e.keyCode == Keys.BACKSPACE) {
            deleteSelected("keyboard");
        }
        else if (e.keyCode == Keys.Z && isCtrlDown) {
            undo("keyboard");
        }
        else if (e.keyCode == Keys.Y && isCtrlDown) {
            redo("keyboard");
        }
        else if (e.keyCode == Keys.X && isCtrlDown) {
            clipboard.cut("keyboard");
        }
        else if (e.keyCode == Keys.C && isCtrlDown) {
            clipboard.copy("keyboard");
        }
        else if (e.keyCode == Keys.V && isCtrlDown) {
            clipboard.paste("keyboard", { inPlace: e.shiftKey });
        }
        else if (e.keyCode == Keys.F && isCtrlDown) {
            $("#" + SEARCH_BOX_ID).focus();
        }
        else if (e.key == "1" || e.key == "v" || e.keyCode == Keys.ESCAPE) {
            selectTool("select", "keyboard");
        }
        else if (e.key == "2" || e.key == "p") {
            selectTool("pan", "keyboard");
        }
        else if (e.key == "3" || e.key == "t") {
            selectTool("arrow", "keyboard");
        }
        else if (Flags.isFreeHandToolEnabled && (e.key == "4" || e.key == "d")) {
            selectTool("freehand", "keyboard");
        }
        else if (e.key == "q") {
            toggleMaximized();
        }
        else if (Consts.isDev && (e.key == "5")) {
            selectTool("pathedit", "keyboard");
        }
        else if (Consts.isDev && (isCtrlDown && e.key == ".")) {
            Debug.SHAPES = true;
        }
        else {
            handled = false;
        }
        if (handled) {
            e.preventDefault();
        }
    });
}
export function keyToDirection(key) {
    switch (key) {
        case Keys.LEFT: return "left";
        case Keys.RIGHT: return "right";
        case Keys.DOWN: return "down";
        case Keys.UP: return "up";
    }
    return "left";
}
