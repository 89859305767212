var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import Tippy from "@tippyjs/react";
import { Button, ToggleHorizontalButtons, VerticalButtons } from "components/button";
import { ColorPicker } from "components/colorpicker";
import { ColorWells } from "components/color_wells";
import { ComboSlider } from "components/combo_slider";
import { Icon } from "components/icon";
import { t } from "i18next";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { TierContext } from "tiers/context";
import { Tip } from "view/tooltips";
import { HorizontalIconButtons } from "./button";
import { LabelledCheckbox, LabelledCheckboxGroup } from "./checkbox";
import { Radio } from "./radio";
import { Select } from "./select";
import { TextAreaInput } from "./text_area";
import { TextInput } from "./text_input";
function icon(tabType) {
    switch (tabType) {
        case "text-style": return "paint-roller";
        case "property": return "sliders-simple";
        case "liquid": return "tint";
        case "text": return "i-cursor";
        case "tools": return "wrench";
    }
}
function display(tabType) {
    switch (tabType) {
        case "property": return "Properties";
        case "text-style": return "Style";
        case "liquid": return "Liquid";
        case "text": return "Text";
        case "tools": return "Tools";
    }
}
export function isReactElement(props) {
    return props.spec === undefined;
}
var PropertyPanel = /** @class */ (function (_super) {
    __extends(PropertyPanel, _super);
    function PropertyPanel(props) {
        var _this = _super.call(this, props) || this;
        _this.ref = React.createRef();
        _this.render = function () {
            // If no tabs are specified, render nothing.
            if (_this.props.tabs.length === 0)
                return null;
            var currentTabType = _this.state.currentTab;
            var currentTab = _this.props.tabs.filter(function (t) { return t.type === currentTabType; })[0];
            var collapsed = _this.props.collapsed;
            return React.createElement(TierContext.Consumer, null, function (tiers) { return (React.createElement("div", { id: "property-panel", ref: _this.ref, "data-simplebar": true },
                React.createElement("div", { className: "content-container" },
                    React.createElement("div", { className: "property-panel-header" + (collapsed ? " collapsed" : "") },
                        React.createElement(Header, { icon: _this.props.icon, title: _this.props.title }),
                        !collapsed && React.createElement("div", { className: "property-panel-tabs" }, _this.props.tabs.map(function (tab) {
                            return React.createElement(Tab, __assign({ key: tab.type, active: tab.type === _this.state.currentTab, onSelect: function () { return _this.setState({ currentTab: tab.type }); } }, tab));
                        })),
                        React.createElement(Tip, { content: (collapsed ? "Show" : "Hide") + " panel" },
                            React.createElement("div", { className: "collapse-toggle", onClick: function () { return _this.props.onToggleCollapsed(!collapsed); } },
                                React.createElement(Icon, { name: collapsed ? "arrow-down-from-line" : "arrow-up-to-line" })))),
                    !collapsed && _this.createElements(currentTab, tiers)))); });
        };
        _this.state = { currentTab: props.tabs.length > 0 ? props.tabs[0].type : "property" };
        return _this;
    }
    PropertyPanel.getDerivedStateFromProps = function (props, currentState) {
        // Check if the current panel exists in the props. If not, reset to first tab.
        var hasTab = props.tabs.filter(function (t) { return t.type === currentState.currentTab; }).length > 0;
        if (hasTab) {
            return currentState;
        }
        return { currentTab: props.tabs[0].type };
    };
    PropertyPanel.prototype.createElements = function (tab, tiers) {
        // Flat list of react children.
        var elements = [];
        var isFirstHeader = true;
        for (var _i = 0, _a = tab.sections; _i < _a.length; _i++) {
            var section = _a[_i];
            // Skip sections with no elements, but only if it's not the first header and it
            // does not contain a toggle.
            if (section.elements.length == 0 && !section.toggle)
                continue;
            // Header. Hide uf this is the first section and the section requests to hide it.
            var header = isFirstHeader && section.hideHeaderIfFirst === true
                ? null
                : React.createElement(SectionHeader, __assign({ key: "header_" + section.header }, section));
            // Create the elements.
            var propertyElements = section.elements.map(function (element) {
                // Special case: if it's a ReactElement, just return it.
                if (isReactElement(element)) {
                    return [element];
                }
                var els = [];
                var key = "label_" + element.key;
                if (element.spec == "header") {
                    els.push(createElement(element));
                }
                else if (!tiers.allow(element) && !(element.spec === "checkbox-group")) {
                    // Locked item. Show a label with a badge.
                    // Clicking opens a pop up.
                    els.push(React.createElement("div", { className: "flex-group", key: key },
                        React.createElement(PropLabel, { key: key, css: "", label: element.label }),
                        React.createElement("a", { className: "unlock", href: "#", onClick: function (e) { e.preventDefault(); tiers.popup("PP:" + key); } },
                            tiers.badge(element),
                            " ",
                            t("Unlock"))));
                }
                else {
                    var wideClass = (element.spec == "checkbox-group") && element.wideLabel ? "wide" : "";
                    els.push(React.createElement("div", { className: "flex-group", key: key },
                        React.createElement(PropLabel, { key: key, css: wideClass, label: element.label }),
                        createElement(element)));
                }
                return els;
            });
            elements.push.apply(elements, __spreadArray([header], propertyElements));
            isFirstHeader = false;
        }
        return elements;
    };
    return PropertyPanel;
}(React.Component));
export { PropertyPanel };
var createElement = function (props) {
    switch (props.spec) {
        case "slider":
            return React.createElement(ComboSlider, __assign({}, props));
        case "radio":
            return React.createElement("div", null,
                React.createElement(Radio, __assign({}, props)));
        case "select":
            return React.createElement(Select, __assign({}, props));
        case "text":
            return React.createElement(TextInput, __assign({}, props));
        case "color":
            return React.createElement(ColorPicker, __assign({}, props));
        case "button":
            return React.createElement(Button, __assign({}, props));
        case "horizontal-icon-buttons":
            return React.createElement(HorizontalIconButtons, __assign({}, props));
        case "toggle-horizontal-buttons":
            return React.createElement(ToggleHorizontalButtons, __assign({}, props));
        case "vertical-buttons":
            return React.createElement(VerticalButtons, __assign({}, props));
        case "textarea":
            return React.createElement(TextAreaInput, __assign({}, props));
        case "checkbox-group":
            return React.createElement(LabelledCheckboxGroup, __assign({}, props));
        case "header":
            return React.createElement(SectionHeader, __assign({}, props));
        case "color-wells":
            return React.createElement(ColorWells, __assign({}, props));
    }
};
var Header = function (_a) {
    var icon = _a.icon, title = _a.title;
    var t = useTranslation().t;
    var ttitle = typeof title === "string"
        ? t(["ui." + title, "apparatus." + title + ".name", title])
        : title();
    return React.createElement("h2", null,
        React.createElement("i", { className: "fa fa-" + icon + " mr-hem" }),
        ttitle);
};
/** Property, tab, e.g. "Properties", "Liquid" */
var Tab = function (_a) {
    var active = _a.active, onSelect = _a.onSelect, tab = __rest(_a, ["active", "onSelect"]);
    var t = useTranslation().t;
    var name = display(tab.type);
    return React.createElement("button", { className: active ? "active" : "", onClick: onSelect },
        React.createElement("div", null,
            React.createElement("i", { className: "fa fa-" + icon(tab.type) + " mr-1" }),
            t(["ui." + name, name]),
            tab.colorDot ? React.createElement("span", { className: "color-dot", style: { background: tab.colorDot } }) : null));
};
/** Header section, e.g. "Theme" */
var SectionHeader = function (props) {
    var t = useTranslation().t;
    return React.createElement("h3", null,
        React.createElement(Icon, { spacing: true, name: props.icon, fullWidth: true }),
        t(["ui." + props.header, props.header]),
        props.helpArticleId ? React.createElement(Tippy, { content: t("Learn more") },
            React.createElement("a", { className: "info_chip fa fa-question-circle ml-2", "data-beacon-article-sidebar": props.helpArticleId })) : undefined,
        props.toggle ? React.createElement(LabelledCheckbox, __assign({ label: "" }, props.toggle, { useSwitch: true })) : undefined);
};
/** Label for a spec, e.g. "Height" */
var PropLabel = function (_a) {
    var css = _a.css, label = _a.label;
    var t = useTranslation().t;
    return React.createElement("div", { className: "label " + css }, t(["props." + label, "ui." + label, label]));
};
