import { clamp } from "apparatus/library/common";
import { Deselect } from "command/basic";
import { clog } from "log";
import store from "store/store";
/*
 * Functions and helpers for exporting current canvas
 * into various image formats.
 */
/** Maximum zoom level to use when exporting (retina). */
var MAX_RENDER_ZOOM = 2.0;
var THUMBNAIL_WIDTH = 450;
var THUMBNAIL_HEIGHT = 300;
var THUMBNAIL_PADDING = 15;
export function exportFromCanvasJPGThumbnail(project, layers, bounds) {
    // Deselect any selected items. Do this first as this
    // will trigger a React update, setting the zoom.
    var deselectCommand = new Deselect(store.selectedObjectsID());
    deselectCommand.execute();
    // Store current view properties.
    var currentViewSize = project.view.viewSize;
    var currentCenter = project.view.center;
    var currentZoom = project.view.zoom;
    // Calculate temporary render zoom, depending on the amount of
    // space the diagram takes and what's allowed.
    var _a = getExportZoomForThumbnail(bounds), scale = _a.scale, x = _a.x, y = _a.y;
    // Shift the view center to align with center of the items.
    project.view.center = bounds.center;
    // Temporarily set zoom. If the export does not stay at this zoom, it means
    // some other update is triggering setZoom back to the correct value during
    // the export (this method is called on a background thread.)
    project.view.zoom = scale;
    // Scale down to (280, 180) using values from getExportZoomForThumbnail.
    var scaledBounds = bounds.scale(x, y);
    // Expand bounds just to be sure.
    scaledBounds = scaledBounds.expand(THUMBNAIL_PADDING);
    // Shift the items so that they are moved to the top left corner
    // as opposed to centered in the view. This is important for next step.
    // Intuition: currentViewSize is zoom-independent size of the canvas. We are trying
    // to find how much space the scaled image takes up on the canvas, so we can
    // calculate the gap we need to remove. viewCenter is zoom-dependent and so
    // we need to scale by exportZoom to get the right amount of shift.
    var shift = new paper.Point(currentViewSize.subtract(scaledBounds.size).divide(2 * scale));
    project.view.center = project.view.center.add(shift);
    // Restrict render area to the scaled bounds. Note that the top-left corner stays at 0,0
    project.view.viewSize = scaledBounds.size;
    // Add a white rectangle to the backing layer before exporting JPG.
    // Expand the regular bounds by about 30 which should cover most small
    // zooms.
    var backingRectangle = scaledBounds.scale(1 / scale).expand(30).toShape();
    backingRectangle.fillColor = "white";
    layers.exportOnlyBacking.addChild(backingRectangle);
    // Hide the render area.
    layers.overlay.visible = false;
    // Trigger render then export to JPG.
    project.view.draw();
    var jpgData = project.view.element.toDataURL("image/jpeg", 0.8);
    // Revert.
    layers.exportOnlyBacking.removeChildren();
    project.view.viewSize = currentViewSize;
    project.view.center = currentCenter;
    project.view.zoom = currentZoom;
    deselectCommand.undo();
    layers.overlay.visible = true;
    return jpgData;
}
/**
 * Exports the current canvas contents to multiple image formats.
 *
 * This function is very delicate and assumes that
 * no other processing/editing occurs at the same time.
 * It relies on the data store, the current canvas state
 * and Paper.js operations to prepare for export.
 *
 * It will make destructive edits to the canvas itself,
 * which it then attempts to revert at the end.
 *
 * @param project Paper project to export from
 * @param layers Layers of the current project
 * @param bounds Strict bounds (no padding), in paper coordinates
 *               of the area in canvas to be exported.
 */
export function exportFromCanvas(project, layers, bounds, maxPixels) {
    // Deselect any selected items. Do this first as this
    // will trigger a React update, setting the zoom.
    var deselectCommand = new Deselect(store.selectedObjectsID());
    deselectCommand.execute();
    // Store current view properties.
    var currentViewSize = project.view.viewSize;
    var currentCenter = project.view.center;
    var currentZoom = project.view.zoom;
    // Calculate temporary render zoom, depending on the amount of
    // space the diagram takes and what's allowed.
    var exportZoom = getExportZoom(bounds, maxPixels);
    clog("Rendering image at zoom level " + exportZoom);
    // Shift the view center to align with center of the items.
    project.view.center = bounds.center;
    // Temporarily set zoom. If the export does not stay at this zoom, it means
    // some other update is triggering setZoom back to the correct value during
    // the export (this method is called on a background thread.)
    project.view.zoom = exportZoom;
    // Scale the bounds to export zoom, around the view center.
    // This only works because we have adjusted the view center
    // to the bounds' center, avoiding ugly maths.
    var scaledBounds = bounds.scale(exportZoom);
    // Expand bounds just to be sure.
    scaledBounds = scaledBounds.expand(5.0);
    // Shift the items so that they are moved to the top left corner
    // as opposed to centered in the view. This is important for next step.
    // Intuition: currentViewSize is zoom-independent size of the canvas. We are trying
    // to find how much space the scaled image takes up on the canvas, so we can
    // calculate the gap we need to remove. viewCenter is zoom-dependent and so
    // we need to scale by exportZoom to get the right amount of shift.
    var shift = new paper.Point(currentViewSize.subtract(scaledBounds.size).divide(2 * exportZoom));
    project.view.center = project.view.center.add(shift);
    // Restrict render area to the scaled bounds. Note that the top-left corner stays at 0,0
    project.view.viewSize = scaledBounds.size;
    // Hide the export rectangle.
    layers.overlay.visible = false;
    // Export SVG.
    var svgElement = project.exportSVG();
    svgElement.setAttribute("viewBox", "0 0 " + scaledBounds.width + " " + scaledBounds.height);
    // Trigger render then export to PNG.
    project.view.draw();
    var pngData = project.view.element.toDataURL("image/png");
    // Add a white rectangle to the backing layer before exporting JPG.
    // Expand the regular bounds by about 30 which should cover most small
    // zooms.
    var backingRectangle = bounds.expand(30).toShape();
    backingRectangle.fillColor = "white";
    layers.exportOnlyBacking.addChild(backingRectangle);
    // Trigger render then export to JPG.
    project.view.draw();
    var jpgData = project.view.element.toDataURL("image/jpeg", 0.8);
    // Revert.
    layers.exportOnlyBacking.removeChildren();
    project.view.viewSize = currentViewSize;
    project.view.center = currentCenter;
    project.view.zoom = currentZoom;
    deselectCommand.undo();
    layers.overlay.visible = true;
    // Convert SVG element to data url.
    var seralizer = new XMLSerializer();
    var svgString = seralizer.serializeToString(svgElement);
    // btoa can sometimes fail, see
    // https://stackoverflow.com/questions/23223718/failed-to-execute-btoa-on-window-the-string-to-be-encoded-contains-characte
    var svgDataUrl = "data:image/svg;base64," + btoa(unescape(encodeURIComponent(svgString)));
    return { svgElement: svgElement, svgDataUrl: svgDataUrl, pngDataUrl: pngData, jpgDataUrl: jpgData };
}
/**
 * Computes the correct zoom for given bounds. This ensures that PNGs and JPGs
 * are not overly huge. This also adjusts for display density.
 */
function getExportZoom(bounds, maxPixels) {
    var pixelRatio = paper.project.view.pixelRatio;
    var pixelRatioSquared = pixelRatio * pixelRatio;
    var currentSize = Math.max(bounds.width * bounds.height * pixelRatioSquared, 10000);
    var rawZoom = Math.sqrt(maxPixels / currentSize);
    var zoom = Math.min(rawZoom, MAX_RENDER_ZOOM * 2 / pixelRatio);
    zoom = clamp(zoom, 0.01, 10);
    return zoom;
}
/** Compute zoom for scaling down for a thumbnail. */
function getExportZoomForThumbnail(bounds) {
    var pixelRatio = paper.project.view.pixelRatio;
    var scaleY = (THUMBNAIL_HEIGHT - THUMBNAIL_PADDING * 2) / bounds.height / pixelRatio;
    var scaleX = (THUMBNAIL_WIDTH - THUMBNAIL_PADDING * 2) / bounds.width / pixelRatio;
    var scale = Math.min(scaleY, scaleX);
    scale = clamp(scale, 0.01, 10);
    return { scale: scale, x: scaleX, y: scaleY };
}
