import * as Sentry from "@sentry/react";
import "ext/js";
import { Consts } from "./vars";
/** Returns correctly pluralized term for given count */
export function pluralize(count, term, useNo) {
    if (useNo === void 0) { useNo = false; }
    if (count === 0) {
        return (useNo ? "No" : "0") + " " + term + "s";
    }
    if (count === 1) {
        return "1 " + term;
    }
    return count + " " + term + "s";
}
export function crumb(category, message) {
    Sentry.addBreadcrumb({ category: category, message: message });
}
/** Compares two floats to be "equal" enough */
export function equals(a, b) {
    return Math.abs(a - b) < Consts.epsilon;
}
export function getUrlParameter(paramName) {
    var pageUrl = decodeURIComponent(window.location.search.substring(1));
    var params = pageUrl.split('&');
    for (var i = 0; i < params.length; i++) {
        var param = params[i].split('=');
        if (param[0] === paramName) {
            return params[1] === undefined ? true : params[1];
        }
    }
}
/** Returns true if the current browser is IE or Edge. */
export function isIEOrEdge() {
    var uaString = window.navigator.userAgent;
    var match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);
    if (match)
        return true;
    return false;
}
export var isIOS = window.navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
export function isSafari() {
    var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    // var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    return isSafari;
}
/** Execute a block of code that may open a window. Use opener to open the window. */
export function opensWindow(fn) {
    var opener;
    if (isSafari()) {
        // On Safari, we need to open the window immediately, otherwise it gets
        // blocked and it's hard to open it.
        // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
        var windowReferenceForSafari_1 = window.open();
        if (windowReferenceForSafari_1 != null) {
            windowReferenceForSafari_1.document.write("<html><body><center style='margin-top:5em'>Retrieving information, please wait...</center></body></html>");
            opener = function (url) {
                windowReferenceForSafari_1.location.href = url;
            };
        }
        else {
            opener = function (url) { return window.open(url); };
        }
    }
    else {
        opener = function (url) { return window.open(url); };
    }
    fn(opener);
}
/** IE cannot download images directly so have use this method. */
export function saveBlobHack(el, mime) {
    // Based on:
    // https://github.com/mholt/PapaParse/issues/175
    if (window.navigator.msSaveOrOpenBlob !== undefined) {
        var data = $(el).attr("href") || "";
        // Remove the data URI header. Convert to binary.
        var rawData = atob(data.replace("data:" + mime + ";base64,", ""));
        var rawLength = rawData.length;
        var dataArray = new Uint8Array(rawLength);
        for (var i = 0; i < rawLength; i++) {
            dataArray[i] = rawData.charCodeAt(i);
        }
        var blob = new Blob([dataArray], { type: mime });
        // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        var filename = (function () {
            switch (mime) {
                case "image/svg":
                    return "chemix.svg";
                case "image/png":
                default:
                    return "chemix.png";
            }
        })();
        window.navigator.msSaveBlob(blob, filename);
    }
}
/** Use after a switch to make sure all cases have been handled. */
export function assertUnreachable(e) {
    throw new Error("Non-exhaustive switch");
}
var Lazy = /** @class */ (function () {
    function Lazy(initializer) {
        this.instance = null;
        this.initializer = initializer;
    }
    Object.defineProperty(Lazy.prototype, "value", {
        get: function () {
            if (this.instance == null) {
                this.instance = this.initializer();
            }
            return this.instance;
        },
        enumerable: false,
        configurable: true
    });
    return Lazy;
}());
export { Lazy };
/**
 * https://github.com/moroshko/shallow-equal/blob/master/src/objects.js
 */
export default function shallowEqualObjects(objA, objB) {
    if (objA === objB) {
        return true;
    }
    if (!objA || !objB) {
        return false;
    }
    var aKeys = Object.keys(objA);
    var bKeys = Object.keys(objB);
    var len = aKeys.length;
    if (bKeys.length !== len) {
        return false;
    }
    for (var i = 0; i < len; i++) {
        var key = aKeys[i];
        if (objA[key] !== objB[key] || !Object.prototype.hasOwnProperty.call(objB, key)) {
            return false;
        }
    }
    return true;
}
