import { strokeWidth } from "apparatus/common";
import { P } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
import { Shape } from "paper";
var MagneticStirrer = /** @class */ (function () {
    function MagneticStirrer() {
    }
    MagneticStirrer.prototype.render = function (appearance) {
        var graphic = SVG.magneticStirrer();
        graphic.scale(2.0 * appearance.scale, P(0, 0));
        graphic.children[0].children[5].strokeWidth = strokeWidth("thin");
        graphic.children[0].children[6].strokeWidth = strokeWidth("thin");
        var hitShape = Shape.Rectangle(graphic.bounds);
        return {
            graphic: graphic,
            hitShape: hitShape,
            snapping: [
                graphic.placeable(),
                graphic.topEdgeAsPlaceableSurface(),
            ]
        };
    };
    MagneticStirrer.properties = {
        label: "Magnetic Stirrer",
        defaultAppearance: {
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.75, max: 1.5, step: 0.05, unit: "%",
            }
        ],
    };
    return MagneticStirrer;
}());
export { MagneticStirrer };
var StirBar = /** @class */ (function () {
    function StirBar() {
    }
    StirBar.prototype.render = function (appearance) {
        var graphic = SVG.stirbar();
        // Scale vertically to lesser extent compared to horizontal.
        graphic.scale(appearance.scale, (appearance.scale - 1.0) * 0.3 + 1.0);
        var hitShape = Shape.Rectangle(graphic.bounds);
        return { graphic: graphic, hitShape: hitShape };
    };
    StirBar.properties = {
        label: "Magnetic Stir Bar",
        defaultAppearance: {
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.75, max: 2.0, step: 0.05
            }
        ],
    };
    return StirBar;
}());
export { StirBar };
