import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedColors, SharedGradients } from "apparatus/library/common";
import { Group, Path } from "paper";
var wheelColor = "#444444".color();
var Trolley = /** @class */ (function () {
    function Trolley() {
    }
    Trolley.prototype.render = function (appearance) {
        var length = appearance.length, color = appearance.color, plungerLength = appearance.plungerLength;
        var height = length / 10 + 20;
        var wheelRadius = height * 0.6;
        var wheelFill = [
            [wheelColor.hex(), 0.6],
            [wheelColor.darken(0.2).hex(), 0.79],
            [wheelColor.hex(), 0.8],
        ];
        var innerWheel = new Path.Circle(P(length - wheelRadius - 10, height - 10), wheelRadius)
            .withStroke("default")
            .setRadialGradientFill(wheelFill);
        var outerWheel = new Path.Circle(P(10 + wheelRadius, height - 10), wheelRadius)
            .withStroke("default")
            .setRadialGradientFill(wheelFill);
        var body = new Path.Rectangle(R(0, 0, length, height), 2.0);
        if (color === "wood") {
            body.withStroke("default", SharedColors.wood.stroke)
                .withGradientFill("down", SharedColors.wood.gradient);
        }
        else {
            body.withStroke("default", color.color().ladd(-20).hex())
                .withGradientFill("down", Gradients.box(color.color()));
        }
        // Assemble.
        var trolleyGroup = new Group();
        if (appearance.wheels === 3) {
            trolleyGroup.addChildren([innerWheel, body, outerWheel]);
        }
        else if (appearance.wheels === 4) {
            trolleyGroup.addChildren([body, innerWheel, outerWheel]);
        }
        var graphic = new Group([trolleyGroup]);
        var hitShapes = [
            trolleyGroup.bounds.toShape(),
        ];
        if (appearance.plunger) {
            var plunger = new Group([
                new Path.Rectangle(R(0, 10, plungerLength, 10, Pivot.TOP_RIGHT)).withGradientFill("down", SharedGradients.metal),
                new Path(Segments([[-plungerLength, 9]], [[-plungerLength - 15, 7]])).mirrorY(15).close().withFill("#111111")
            ]);
            graphic.insertChild(-1, plunger);
            hitShapes.push(plunger.bounds.toShape());
        }
        if (appearance.column) {
            var bars = new Group([
                new Path.Rectangle(R(length / 2, 0, 15, length / 3, Pivot.BOTTOM_CENTER))
                    .withGradientFill("right", SharedGradients.cylinderMetal)
            ]);
            graphic.insertChild(-1, bars);
            hitShapes.push(bars.bounds.toShape());
        }
        return {
            graphic: graphic,
            hitShape: hitShapes,
            pivot: body.bounds.center,
            snapping: [
                trolleyGroup.placeable(),
                body.topEdgeAsPlaceableSurface(),
            ]
        };
    };
    Trolley.properties = {
        label: "Trolley",
        flippable: true,
        defaultAppearance: {
            length: 300,
            wheels: 3,
            plunger: true,
            plungerLength: 80,
            column: true,
            color: "wood"
        },
        appearanceSpecs: [
            {
                spec: "toggle-buttons", key: "wheels", label: "Wheels",
                size: "text",
                options: [
                    { value: 3, label: "3" },
                    { value: 4, label: "4" },
                ]
            },
            {
                spec: "slider", key: "length", label: "Length",
                min: 175, max: 350, step: 5, unit: "mm",
            },
            {
                spec: "checkbox-group", label: "Show",
                checkboxes: [
                    { key: "plunger", label: "Plunger" },
                    { key: "column", label: "Stacking column" },
                ]
            },
            {
                spec: "slider", key: "plungerLength", label: "Plunger length",
                min: 20, max: 100, unit: "mm", visible: function (a) { return a.plunger; }
            },
            {
                spec: "color-wells", key: "color", label: "Colour",
                colors: [
                    { value: "wood", label: "Wood", hex: SharedColors.wood.stroke },
                    { value: "#df4920", label: "Red", },
                    { value: "#519d27", label: "Green", },
                    { value: "#266cc1", label: "Blue", },
                ]
            }
        ],
    };
    return Trolley;
}());
export { Trolley };
