import { mirrorX, setDefaultStyle } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path, PointText, Shape } from "paper";
var OxSatProbe = /** @class */ (function () {
    function OxSatProbe() {
        this.drivers = [
            {
                type: "rectangle",
                minX: -150, maxX: 150,
                minY: 0, maxY: 200,
                toAppearance: function (f, p) { return f({ probeX: p.x, probeY: p.y }); },
                fromAppearance: function (a) { return P(a.probeX, a.probeY); },
                scale: 1.0,
            }
        ];
    }
    OxSatProbe.prototype.render = function (appearance) {
        var probeOffsetX = appearance.probeX;
        var probeOffsetY = appearance.probeY;
        // Create a reader
        var casing = Shape.Rectangle(R(0, 0, 60, 100, Pivot.TOP_CENTER), 4);
        casing.fillColor = "#dddddd";
        setDefaultStyle(casing);
        var screen = Shape.Rectangle(R(0, 15, 50, 40, Pivot.TOP_CENTER), 2)
            .withStroke(2.0, "white")
            .withFill(SharedColors.lcdScreen);
        var screenClip = screen.clone();
        var button = Shape.Circle(P(-10, 80), 7);
        button.fillColor = "#a9b2b7";
        button.strokeWidth = 2.0;
        button.strokeColor = "#777c7f";
        var button2 = Shape.Circle(P(10, 80), 7);
        button2.fillColor = "#a9b2b7";
        button2.strokeWidth = 2.0;
        button2.strokeColor = "#777c7f";
        var satText = new PointText({
            point: P(21, 32),
            content: appearance.saturation,
            fillColor: 'black',
            fontFamily: 'sans-serif',
            fontSize: 14,
            justification: "right",
        });
        var satTextLabel = new PointText({
            point: P(23, 11),
            content: "mg/L",
            fillColor: "#555555",
            fontFamily: 'sans-serif',
            fontSize: 9,
            justification: "right",
        });
        var tempText = new PointText({
            point: P(-18, 48),
            content: appearance.temperature + "°" + appearance.tempUnit,
            fillColor: 'black',
            fontFamily: 'sans-serif',
            fontSize: 11,
            justification: "left",
        });
        var screenDisplay = new Group([screenClip, satText, tempText]);
        screenDisplay.clipped = true;
        var reader = new Group([casing, screen, screenDisplay, satTextLabel, button, button2]);
        // Create the probe
        var probe = new Path(Segments([[10, 0], [0, -2]], [[10, 70]], [[5, 77]], [[5, 110], , [0, 2]])).close();
        mirrorX(probe);
        probe.withGradientFill("right", Gradients.cylinder("#333333".color()));
        probe.position = probe.position.add(P(probeOffsetX, probeOffsetY));
        // Create the wire.
        var wire = new Path(Segments([[0, 100], undefined, [0, 50]], // Connects to the casing.
        [[probeOffsetX, probeOffsetY], [0, -50]]));
        setDefaultStyle(wire);
        var graphic = new Group([reader, probe, wire]);
        var hitShapes = [
            probe.bounds.toShape(),
            reader.bounds.toShape(),
        ];
        return {
            graphic: graphic,
            hitShape: hitShapes,
            pivot: casing.bounds.center,
        };
    };
    OxSatProbe.properties = {
        label: "Oxygen Saturation Probe",
        defaultAppearance: {
            temperature: "20",
            tempUnit: "C",
            saturation: "8.5",
            probeX: 150,
            probeY: 100,
        },
        appearanceSpecs: [
            {
                spec: "text", key: "saturation", label: "Saturation",
                numbersOnly: true, maxLength: 5,
            },
            {
                spec: "text", key: "temperature", label: "Temperature",
                numbersOnly: true, maxLength: 5,
            },
            {
                spec: "toggle-buttons", key: "tempUnit", label: "Temperature unit", size: "text",
                options: [
                    { value: "C", label: "C" },
                    { value: "F", label: "F" },
                ]
            },
        ],
    };
    return OxSatProbe;
}());
export { OxSatProbe };
