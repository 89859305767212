var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import * as Sentry from '@sentry/react';
import { loginProviderDisplayName } from "api/auth/login_button";
import { clearToken } from "api/auth/storage";
import { Arrays } from "ext/array";
import { t } from "i18next";
import { ID } from "store/id";
import { Bcn } from "support/beacon";
import { Toast } from "toast";
import { getType } from 'typesafe-actions';
import * as actions from './actions';
export default (function (state, action) {
    switch (action.type) {
        case getType(actions.addItems): {
            return __assign(__assign({}, state), { items: __spreadArray(__spreadArray([], state.items), action.payload) });
        }
        case getType(actions.addItemsAtIndices): {
            return (function () {
                var newState = __assign({}, state);
                var sortedByIndex = action.payload.sort(function (a, b) { return a.index - b.index; });
                sortedByIndex.forEach(function (_a) {
                    var item = _a.item, index = _a.index;
                    newState.items.splice(index, 0, item);
                });
                return newState;
            })();
        }
        case getType(actions.updateAppearance): {
            var _a = action.payload, id_1 = _a.id, key_1 = _a.key, value_1 = _a.value;
            var newState = __assign({}, state);
            newState.items = state.items.map(function (item) {
                if (item.id === id_1) {
                    var newAppearance = __assign({}, item.appearance);
                    newAppearance[key_1] = value_1;
                    return __assign(__assign({}, item), { appearance: newAppearance });
                }
                return item;
            });
            return newState;
        }
        case getType(actions.updateItemsAppearance): {
            var _b = action.payload, key_2 = _b.key, idsToValuesMap_1 = _b.idsToValuesMap;
            var newState = __assign({}, state);
            newState.items = state.items.map(function (item) {
                var _a;
                var newValue = idsToValuesMap_1[item.id.id];
                if (newValue !== undefined) {
                    return __assign(__assign({}, item), { appearance: __assign(__assign({}, item.appearance), (_a = {}, _a[key_2] = newValue, _a)) });
                }
                return item;
            });
            return newState;
        }
        case getType(actions.updateAppearanceProperties): {
            var _c = action.payload, id_2 = _c.id, props_1 = _c.props;
            var newState = __assign({}, state);
            newState.items = state.items.map(function (item) {
                return item.id === id_2
                    ? __assign(__assign({}, item), { appearance: __assign(__assign({}, item.appearance), props_1) }) : item;
            });
            return newState;
        }
        case getType(actions.updateItemsAppearanceProperties): {
            var _d = action.payload, ids_1 = _d.ids, props_2 = _d.props;
            var newState = __assign({}, state);
            newState.items = state.items.map(function (item) {
                return Arrays.includes(ids_1, item.id)
                    ? __assign(__assign({}, item), { appearance: __assign(__assign({}, item.appearance), props_2) }) : item;
            });
            return newState;
        }
        case getType(actions.removeItems): {
            var ids = action.payload.ids;
            var newState = __assign({}, state);
            newState.items = state.items.filter(ID.notMatching(ids));
            return newState;
        }
        case getType(actions.addToGroup): {
            var groupsOperationData_1 = action.payload.data;
            var newState = __assign({}, state);
            var existingGroupIdNumbers_1 = [];
            newState.groups = newState.groups.map(function (group) {
                var _a;
                if (!(group.id.id in groupsOperationData_1)) {
                    return group;
                }
                existingGroupIdNumbers_1.push(group.id.id);
                var childIdsToAdd = groupsOperationData_1[group.id.id];
                (_a = group.items).push.apply(_a, childIdsToAdd);
                return group;
            });
            // Add any new groups.
            for (var groupIdNumber in groupsOperationData_1) {
                var groupIdNumberAsNumber = +groupIdNumber;
                if (existingGroupIdNumbers_1.indexOf(groupIdNumberAsNumber) === -1) {
                    // Group does not exist yet.
                    newState.groups.push({
                        id: action.payload.groupIds[groupIdNumber],
                        items: groupsOperationData_1[groupIdNumber]
                    });
                }
            }
            return newState;
        }
        case getType(actions.removeFromGroup): {
            var groupsOperationData = action.payload.data;
            var newState = __assign({}, state);
            var newGroups = [];
            var _loop_1 = function (group) {
                if (group.id.id in groupsOperationData) {
                    var childIdsTorRemove_1 = groupsOperationData[group.id.id];
                    group.items = group.items.filter(function (i) { return childIdsTorRemove_1.indexOf(i) === -1; });
                    // Only keep this group if it still has any children.
                    if (group.items.length > 0) {
                        newGroups.push(group);
                    }
                }
                else {
                    // This group is not concerned. Keep it.
                    newGroups.push(group);
                }
            };
            for (var _i = 0, _e = newState.groups; _i < _e.length; _i++) {
                var group = _e[_i];
                _loop_1(group);
            }
            newState.groups = newGroups;
            return newState;
        }
        case getType(actions.setItemsRotation): {
            var rotations_1 = action.payload.rotations;
            var newState = __assign({}, state);
            newState.items = state.items.map(function (item) {
                if (item.id.id in rotations_1) {
                    return __assign(__assign({}, item), { rotation: rotations_1[item.id.id] });
                }
                return item;
            });
            return newState;
        }
        case getType(actions.switchTool): {
            var toolName = action.payload;
            return __assign(__assign({}, state), { tool: toolName });
        }
        case getType(actions.setThemeStrokeThickness): {
            return __assign(__assign({}, state), { theme: __assign(__assign({}, state.theme), { strokeThickness: action.payload }) });
        }
        case getType(actions.updateChemMarkConfigStateOfMatterStyle): {
            return __assign(__assign({}, state), { chemMark: __assign(__assign({}, state.chemMark), { stateOfMatterStyle: action.payload }) });
        }
        case getType(actions.generic): {
            var actionFn = action.payload.actionFn;
            var newState = actionFn(state);
            return newState;
        }
        case getType(actions.logIn): {
            Bcn.setSessionData(action.payload.provider, action.payload.user, action.payload.tier);
            Sentry.setUser({ id: action.payload.user.id, email: action.payload.user.email });
            if (!(action.payload.silencePopup === true)) {
                var title = t(action.payload.firstTimeLogin ? "login.sign_in_success_first" : "login.sign_in_success");
                var description = t('login.current_login', { provider: t(loginProviderDisplayName(action.payload.provider)), handle: action.payload.user.handle });
                toastr.success(description, title);
            }
            return __assign(__assign({}, state), { auth: {
                    state: "logged_in",
                    provider: action.payload.provider,
                    jwtToken: action.payload.jwtToken,
                    user: action.payload.user,
                }, tier: action.payload.tier });
        }
        case getType(actions.logOut): {
            Bcn.unsetSessionData();
            Sentry.setUser(null);
            Toast.info(t('login.sign_out_description'), t('login.sign_out_title'));
            clearToken();
            return __assign(__assign({}, state), { auth: {
                    state: "logged_out",
                }, tier: undefined });
        }
        case getType(actions.eraseItems): {
            return __assign(__assign({}, state), { items: [], arrows: [], groups: [] });
        }
        case getType(actions.replace): {
            // Merge current state with the one in the given document.
            // If the diagram is a template, do not set up information
            // about current diagram.
            var _f = action.payload, diagram = _f.diagram, isTemplate = _f.isTemplate;
            return __assign(__assign(__assign({}, state), diagram.data), { currentCloudDiagram: !isTemplate
                    ? {
                        id: diagram.id,
                        title: diagram.title,
                    }
                    : undefined });
        }
        case getType(actions.setCloudDiagram): {
            return __assign(__assign({}, state), { currentCloudDiagram: action.payload });
        }
        case getType(actions._TESTONLY_resetState): {
            // Override in tests only.
            return state;
        }
        default: return state; // Needed for initial store state.
    }
});
