import { setDefaultStyle } from "apparatus/draw";
import { P, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var Spatula = /** @class */ (function () {
    function Spatula() {
    }
    Spatula.prototype.render = function (appearance) {
        var spatula = new Path(Segments([[-20, 0]], [[0, 0]], [[45, -90]]));
        setDefaultStyle(spatula);
        var graphic = new Group([spatula]);
        if (appearance.contents != "empty") {
            var contentsColor = appearance.color;
            var strokeColor = contentsColor.color().darken(0.3).hex();
            var pile = new Path(Segments([[-20, 0], undefined, [5, -15]], [[2, 0], [-5, -15]]));
            pile.strokeColor = strokeColor;
            pile.strokeWidth = 2.0;
            pile.fillColor = contentsColor;
            graphic.insertChild(0, pile);
            if (appearance.contents == "mixture") {
                // Add some dots.
                var dots = new Group([
                    Shape.Circle(P(-18, -5), 2.0),
                    Shape.Circle(P(-12, -7), 2.0),
                    Shape.Circle(P(-8, -3), 2.0),
                    Shape.Circle(P(-3, -7), 2.0),
                ]);
                dots.fillColor = strokeColor;
                graphic.addChild(dots);
            }
        }
        var hitShape = Shape.Rectangle(graphic.bounds);
        return { graphic: graphic, hitShape: hitShape };
    };
    Spatula.properties = {
        label: "Spatula",
        flippable: true,
        defaultAppearance: {
            contents: "empty",
            color: "#90cc89",
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "contents", label: "Contents",
                options: [
                    { value: "empty", label: "Empty" },
                    { value: "pure", label: "Solid" },
                    { value: "mixture", label: "Powder" }
                ]
            },
            {
                spec: "color", key: "color", label: "Contents colour",
                visible: function (a) { return a.contents != "empty"; }
            }
        ],
    };
    return Spatula;
}());
export { Spatula };
