var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedGradients } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var Kettle = /** @class */ (function () {
    function Kettle() {
    }
    Kettle.prototype.render = function (appearance) {
        var body = new Path(Segments([[0, 0], , [-11, -50]], [[-1, -153], [-6, 32], [8, -40]], [[33, -231]], [[120, -237]], [[155, -145], [-6, -63], [2, 20]], [[154, 0], [9, -42]])).close().withStroke("default").withGradientFill("right", SharedGradients.cylinderMetal);
        var spout = new Path(Segments([[1, -162], [3, -16]], [[-13, -225], [7, 30], [13, -5]], [[30, -224], [-11, -5], [-12, 18]])).withStroke("default").withGradientFill("right", SharedGradients.cylinderMetal);
        var handle = new Path(Segments([[181, -153], [4, 41], [-4, -35]], [[138, -209], [19, 1], [-21, -37]], [[33, -231], [9, 8], [8, -13]], [[102, -253], [-23, 0], [20, 0]], [[206, -164], [-11, -73], [10, 70]], [[159, -60], [20, 0]], [[159, -74], , [14, -10]])).close()
            .withStroke("default")
            .setRadialGradientFill([["#555555", 0.8], ["#777777", 1.0]], { center: P(90, -137) });
        var view = new Path.Rectangle(R(80, -110, 30, 120, Pivot.CENTER), 15)
            .withStroke("default")
            .withGradientFill("right-down", Gradients.cylinder("#a8dae1".color()));
        var graphic = new Group([body, spout, handle, view]);
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: body.placeable(),
        };
    };
    Kettle.properties = {
        label: "Kettle",
        flippable: true,
        defaultAppearance: {
            scale: 1.0,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 1.0, step: 0.01, unit: "%"
            }
        ],
    };
    Kettle = __decorate([
        staticImplements()
    ], Kettle);
    return Kettle;
}());
export { Kettle };
