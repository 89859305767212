import { addMarkers2, breakPath, mirrorX, setDefaultStyle } from "apparatus/draw";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, PointText, Shape } from "paper";
var allSizes = ["0.5", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "15", "20", "25", "30", "40", "50", "100"];
var BLACK = "#000000";
var BLUE = "#0423af";
var ORANGE = "#db720f";
var RED = "#aa1d1d";
var WHITE = "#eeeeee";
var GREEN = "#20b12f";
var YELLOW = "#eadb10";
var LABEL_COLOR = "#33333366";
/**
 * Original:
 *  - https://www.fishersci.com/shop/products/pyrex-reusable-class-a-volumetric-pipets-capacity-25ml-tolerance-0-03ml-color-code-blue/136511n
 *
 * Colour coding:
 *  - https://www.coleparmer.co.uk/p/blaubrand-bulb-pipettes/81461
 *  - https://1.bp.blogspot.com/-LUBA0vZZrxM/XBFI3Ogcv9I/AAAAAAAALUQ/eUV-Z2dC0DwjXWIfmB8zmRnt78P-Y0LFACLcBGAs/s1600/IMG_20181207_175733.jpg
 *
 * Example: https://www.sks-science.com/lab-supply-p-9503.html
 */
var Pipette = /** @class */ (function () {
    function Pipette() {
    }
    Pipette.prototype.render = function (appearance) {
        var radius = 5;
        var _a = this.params(appearance.size), length = _a[0], bulbRadius = _a[1], bulbLength = _a[2], colorCode = _a[3], doubleCode = _a[4];
        var bulbCenterRelative = 0.45;
        var bulbStart = bulbCenterRelative * length - bulbLength / 2;
        var bulbEnd = bulbStart + bulbLength;
        var radiusDiff = bulbRadius - radius;
        var oneSide = new Path(Segments([[radius, 0]], [[radius, bulbStart]], [[bulbRadius, bulbStart + bulbRadius], [0, -radiusDiff]], [[bulbRadius, bulbEnd - bulbRadius], , [0, radiusDiff]], [[radius, bulbEnd]], [[radius, length]]));
        var shape = oneSide.clone();
        mirrorX(shape);
        var liquidMask = shape.clone();
        shape.remove();
        var otherSide = oneSide.clone();
        otherSide.scale(-1, 1, P(0, 0));
        var graphic = new Group([oneSide, otherSide]);
        // Extend the lip on one side.
        oneSide.lastSegment.point.y += 5;
        setDefaultStyle(oneSide, otherSide);
        // Add marker.
        graphic.insertChild(0, new Path.Line(P(-radius, bulbStart - 20), P(radius, bulbStart - 20))
            .withStroke("thinner", LABEL_COLOR));
        // Add volume label.
        if (appearance.showLabel) {
            var fontSize = bulbRadius * 0.9;
            var label = new PointText({
                point: P(0, bulbCenterRelative * length + fontSize / 2),
                content: appearance.size,
                fillColor: LABEL_COLOR,
                fontFamily: 'sans-serif',
                fontWeight: 'bold',
                fontSize: fontSize,
                justification: "center",
            });
            graphic.addChild(label);
        }
        // Add color code.
        if (!doubleCode) {
            graphic.insertChild(0, Shape.Rectangle(R(0, 15, radius * 2, 12, Pivot.TOP_CENTER)).withFill(colorCode));
        }
        else {
            graphic.insertChildren(0, [
                Shape.Rectangle(R(0, 15, radius * 2, 5, Pivot.TOP_CENTER)).withFill(colorCode),
                Shape.Rectangle(R(0, 15 + 7, radius * 2, 5, Pivot.TOP_CENTER)).withFill(colorCode),
            ]);
        }
        // Scale everything.
        var center = graphic.bounds.center;
        graphic.scale(appearance.scale, center);
        liquidMask.scale(appearance.scale, center);
        var hitShape = graphic.bounds.expand(5).toShape();
        return {
            graphic: graphic, hitShape: hitShape, liquidMask: liquidMask, watermark: P(5, bulbEnd),
            liquidDrainOpening: { start: liquidMask.segments[6].point, end: liquidMask.segments[5].point },
        };
    };
    Pipette.prototype.params = function (size) {
        switch (size) {
            case "0.5":
                return [300, 7, 40, BLACK, true];
            case "1":
                return [300, 7, 40, BLUE, false];
            case "2":
                return [330, 8, 40, ORANGE, false];
            case "3":
                return [330, 9, 42, BLACK, false];
            case "4":
                return [400, 9, 42, RED, true];
            case "5":
                return [400, 10, 45, WHITE, false];
            case "6":
                return [400, 11, 45, ORANGE, true];
            case "7":
                return [400, 12, 50, GREEN, true];
            case "8":
                return [440, 13, 50, BLUE, false];
            case "9":
                return [440, 14, 50, BLACK, false];
            case "10":
                return [440, 15, 55, RED, false];
            case "15":
                return [510, 16, 55, GREEN, false];
            case "20":
                return [510, 16, 60, YELLOW, false];
            case "25":
                return [520, 17, 60, BLUE, false];
            case "30":
                return [520, 18, 65, BLACK, false];
            case "40":
                return [540, 18, 70, WHITE, false];
            case "50":
                return [540, 19, 75, RED, false];
            case "100":
                return [585, 20, 80, YELLOW, false];
        }
    };
    Pipette.properties = {
        label: "Pipette",
        canContainLiquids: true,
        meniscus: true,
        drainable: true,
        defaultAppearance: {
            size: "10",
            scale: 0.73,
            showLabel: false,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.6, max: 1.0, step: 0.01, unit: "%"
            },
            {
                spec: "select", key: "size", label: "Max volume",
                options: allSizes.map(function (s) { return ({ label: s + " mL", value: s }); })
            },
            { spec: "checkbox", key: "showLabel", label: "Show label", tier: "boost", allowWhenLocked: true },
        ],
        isBoostRequired: function (a) { return a.showLabel; }
    };
    return Pipette;
}());
export { Pipette };
var PlasticPipette = /** @class */ (function () {
    function PlasticPipette() {
    }
    PlasticPipette.prototype.render = function (appearance) {
        var length = appearance.length;
        var lip = 30;
        var bulb = 40;
        var bodyWidth = 8;
        var bulbWidth = 15;
        var graphic = new Path(Segments([[2, length]], [[bodyWidth / 2, length - lip]], [[bodyWidth / 2, bulb]], [[bulbWidth / 2, bulb - 10]], [[bulbWidth / 2, 0], undefined, [0, -10]]));
        mirrorX(graphic);
        var liquidMask = graphic.clone();
        setDefaultStyle(graphic);
        return { graphic: graphic, hitShape: graphic.strokeBounds.toShape(), liquidMask: liquidMask };
    };
    PlasticPipette.properties = {
        label: "Plastic Pipette",
        canContainLiquids: true,
        defaultAppearance: {
            length: 155,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "length", label: "Length",
                min: 100, max: 180
            }
        ],
    };
    return PlasticPipette;
}());
export { PlasticPipette };
var MohrPipette = /** @class */ (function () {
    function MohrPipette() {
    }
    MohrPipette.prototype.render = function (appearance) {
        var length = appearance.length;
        var shape = new Path(Segments([[4, 0]], [[4, 40]], [[8, 47], [0, -4]], [[8, length - 40], , [0, 5]], [[4, length]]));
        mirrorX(shape);
        var liquidMask = shape.clone();
        var graphic = breakPath(shape, 5);
        setDefaultStyle(graphic);
        addMarkers2(graphic, { start: P(6, length - 50), step: P(0, -4), count: (length - 150) / 4 + 1, majorEvery: 5 });
        return {
            graphic: graphic, hitShape: graphic.rectHitShape(), liquidMask: liquidMask,
            liquidDrainOpening: { start: P(-4, length), end: P(4, length) },
        };
    };
    MohrPipette.properties = {
        label: "Mohr Pipette",
        canContainLiquids: true,
        meniscus: true,
        drainable: true,
        defaultAppearance: {
            length: 350
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "length", label: "Size",
                min: 250, max: 350
            }
        ],
    };
    return MohrPipette;
}());
export { MohrPipette };
