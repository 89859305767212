var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Pp } from "apparatus/library/common";
import { getDescendantIDs, getRootGroup } from "editing/grouping/operations";
import appStore from "store/store";
import { add } from "types";
/** Move an apparatus or an arrow. */
var Move = /** @class */ (function () {
    /** IDs must be arrows or items. Groups are not supported. */
    function Move(ids, delta) {
        this.ids = ids;
        this.delta = Pp(delta);
    }
    Move.prototype.execute = function () {
        appStore.dispatchFn("Move", Move.moveItem(this.ids, this.delta));
    };
    Move.prototype.undo = function () {
        appStore.dispatchFn("Move", Move.moveItem(this.ids, this.delta.multiply(-1)));
    };
    Move.moveItem = function (ids, delta) {
        return function (state) { return (__assign(__assign({}, state), { items: state.items.map(function (item) {
                if (ids.indexOf(item.id) === -1) {
                    return item;
                }
                return __assign(__assign({}, item), add(item, delta));
            }), arrows: state.arrows.map(function (arrow) {
                if (ids.indexOf(arrow.id) === -1) {
                    return arrow;
                }
                return __assign(__assign({}, arrow), { start: add(arrow.start, delta), end: add(arrow.end, delta) });
            }) })); };
    };
    /** Moves a group. */
    Move.group = function (groupId, delta) {
        var group = appStore.groups().filter(function (g) { return g.id == groupId; })[0];
        return new Move(getDescendantIDs(group), delta);
    };
    /** Moves one item. It can be an item, arrow or a group. */
    Move.one = function (id, delta) {
        if (id.type == "group") {
            return Move.group(id, delta);
        }
        return new Move([id], delta);
    };
    return Move;
}());
export { Move };
/** Move individual items by different deltas. */
var MoveIndividual = /** @class */ (function () {
    function MoveIndividual(deltas) {
        this.itemDeltaMap = {};
        this.arrowDeltaMap = {};
        for (var _i = 0, deltas_1 = deltas; _i < deltas_1.length; _i++) {
            var _a = deltas_1[_i], id = _a.id, delta = _a.delta;
            switch (id.type) {
                case "apparatus":
                    this.itemDeltaMap[id.id] = delta;
                    break;
                case "arrow":
                    this.arrowDeltaMap[id.id] = delta;
                    break;
                case "group": {
                    for (var _b = 0, _c = getDescendantIDs(getRootGroup(id)); _b < _c.length; _b++) {
                        var child = _c[_b];
                        if (child.type === "apparatus") {
                            this.itemDeltaMap[child.id] = delta;
                        }
                        else if (child.type === "arrow") {
                            this.arrowDeltaMap[child.id] = delta;
                        }
                    }
                }
            }
        }
    }
    MoveIndividual.prototype.execute = function () {
        var _this = this;
        appStore.dispatchFn("Move", function (state) { return _this.moveItems(state, 1); });
    };
    MoveIndividual.prototype.undo = function () {
        var _this = this;
        appStore.dispatchFn("Move", function (state) { return _this.moveItems(state, -1); });
    };
    MoveIndividual.prototype.moveItems = function (state, multiplier) {
        var _this = this;
        return __assign(__assign({}, state), { items: state.items.map(function (item) {
                var delta = _this.itemDeltaMap[item.id.id];
                if (delta === undefined)
                    return item;
                return __assign(__assign({}, item), add(item, delta.multiply(multiplier)));
            }), arrows: state.arrows.map(function (arrow) {
                var delta = _this.arrowDeltaMap[arrow.id.id];
                if (delta === undefined)
                    return arrow;
                return __assign(__assign({}, arrow), { start: add(arrow.start, delta), end: add(arrow.end, delta.multiply(multiplier)) });
            }) });
    };
    return MoveIndividual;
}());
export { MoveIndividual };
