import { callApi } from "api/api";
import { logIn } from "store/actions";
import appStore from "store/store";
/**
 * Executes reauth, using currently logged in credentials.
 * A no-op if currently user is not logged in.
 *
 * Use this to update the current tier after a purchase etc.
 */
export function reauthFromCurrentLogin() {
    var state = appStore.loggedInState();
    if (!state)
        return;
    callApi("reauth", "GET", state.jwtToken, undefined, {
        success: function (response) {
            if (response.type == "success") {
                appStore.dispatch(logIn({
                    provider: response.provider,
                    jwtToken: response.jwt,
                    user: response.user,
                    // This should presumably be false.
                    firstTimeLogin: response.firstTimeLogin,
                    tier: response.tier,
                    // Silence popups here, let the parent call handle this.
                    silencePopup: true
                }));
            }
        }
    });
}
