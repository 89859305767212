import { g } from "analytics";
import { DeleteArrows } from "arrows/command";
import { DeleteApparatus } from "command/basic";
import { commandProcessor, Sequence } from "command/command";
import store from "store/store";
/** Deletes currently selected items. */
export function deleteSelected(source) {
    // Retrieve currently selected items
    var items = store.selectedItems();
    var arrows = store.selectedArrows();
    if (items.length + arrows.length == 0)
        return;
    var deleteCommand = new DeleteApparatus(items);
    var deleteArrowCommands = new DeleteArrows(arrows);
    commandProcessor.execute(new Sequence([deleteCommand, deleteArrowCommands]));
    g("DeleteSelected", source);
}
