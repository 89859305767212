var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { LIQUID_OPACITY_MAX, SharedColors } from "apparatus/library/common";
import appStore from "store/store";
export var MultiLiquid;
(function (MultiLiquid) {
    function addLayer(layers) {
        var newLayer;
        if (layers.length > 0) {
            var lastLayer = layers[0];
            newLayer = {
                position: lastLayer.position * 0.66,
                blending: 0,
                alpha: SharedColors.defaultLiquidAlpha,
                color: lastLayer.color.color().rotate(90).hex()
            };
        }
        else {
            newLayer = {
                position: 0.66,
                alpha: SharedColors.defaultLiquidAlpha,
                color: SharedColors.defaultLiquid2,
                blending: 0
            };
        }
        return __spreadArray([newLayer], layers);
    }
    MultiLiquid.addLayer = addLayer;
    function createSimpleGradient(color, alpha) {
        return [
            {
                position: 0.1,
                alpha: (alpha + LIQUID_OPACITY_MAX) / 2,
                color: color.color().darken(0.1).round().hex(),
                blending: 1.0
            }
        ];
    }
    MultiLiquid.createSimpleGradient = createSimpleGradient;
    function removeBottomLayer(data) {
        return data.length == 0 ? [] : data.slice(1, data.length);
    }
    MultiLiquid.removeBottomLayer = removeBottomLayer;
    /**
     * @param data Must have length > 0
     * @param topLayerColor Must include alpha and be a string
     */
    function toGradientStops(data, topLayerColor) {
        var stops = [];
        // Precompute all the colours.
        var colors = data.map(function (d) { return d.color.color().alpha(d.alpha).toString(); });
        colors.push(topLayerColor);
        for (var i = 0; i < data.length; i++) {
            stops.push([colors[i], data[i].position]);
            // Depending on blending, place the next stop:
            // blending == 0: No blending: place the stop as close to the current layer as possible
            // blending == 1: Maximum blending, place the stop as close to the next layer.
            var blendUpper = i == data.length - 1 ? 1 : data[i + 1].position;
            var blendRange = blendUpper - data[i].position;
            var blend = data[i].blending;
            stops.push([colors[i + 1], data[i].position + blend * blendRange]);
        }
        stops.push([topLayerColor, 1.0]);
        return stops;
    }
    MultiLiquid.toGradientStops = toGradientStops;
    function adjustNumeric(data, index, key, value) {
        var _a;
        var newData = __spreadArray([], data);
        newData[index] = __assign(__assign({}, data[index]), (_a = {}, _a[key] = value, _a));
        if (key == "position") {
            newData.sort(function (a, b) { return a.position - b.position; });
        }
        return newData;
    }
    MultiLiquid.adjustNumeric = adjustNumeric;
    function adjustString(data, index, key, value) {
        var _a;
        var newData = __spreadArray([], data);
        if (key == "color") {
            value = value.toLowerCase();
        }
        newData[index] = __assign(__assign({}, data[index]), (_a = {}, _a[key] = value, _a));
        return newData;
    }
    MultiLiquid.adjustString = adjustString;
})(MultiLiquid || (MultiLiquid = {}));
var UpdateLiquidLayers = /** @class */ (function () {
    function UpdateLiquidLayers(id, layers, unsafePreviousLayers) {
        this.id = id;
        this.layers = layers;
        // Copy previous layers.
        this.previousLayers = __spreadArray([], unsafePreviousLayers);
    }
    UpdateLiquidLayers.prototype.execute = function () {
        appStore.dispatchFn("updateLiquidLayers", this.mutator(this.layers));
    };
    UpdateLiquidLayers.prototype.undo = function () {
        appStore.dispatchFn("updateLiquidLayers", this.mutator(this.previousLayers));
    };
    UpdateLiquidLayers.prototype.mutator = function (layers) {
        var _this = this;
        return (function (store) {
            var newStore = __assign({}, store);
            newStore.items = store.items.map(function (i) { return i.id === _this.id && i.liquid
                ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { layers: layers }) }) : i; });
            return newStore;
        });
    };
    return UpdateLiquidLayers;
}());
export { UpdateLiquidLayers };
