import * as Sentry from '@sentry/react';
import { t } from "i18next";
import rawVersion from "version";
import { Consts } from "./vars";
// Set up various libraries and plugins
// Sentry
if (Consts.isProd) {
    Sentry.init({
        dsn: Consts.sentryDsn,
        release: rawVersion,
        environment: Consts.isDev ? "Dev" : (Consts.isBeta ? "Beta" : "Prod"),
        normalizeDepth: 5,
    });
}
// Toastr
export function setUpToastr() {
    toastr.options.positionClass = "toast-bottom-center";
    toastr.options.timeOut = 5000;
    toastr.options.extendedTimeOut = 4000;
    toastr.options.showDuration = 200;
    toastr.options.hideDuration = 400;
    toastr.options.showEasing = "linear";
    toastr.options.hideEasing = "linear";
    toastr.options.showMethod = "fadeIn";
    toastr.options.hideMethod = "fadeOut";
    toastr.options.preventDuplicates = true;
}
export var Toastr;
(function (Toastr) {
    function contactSupportButton(beaconAction) {
        if (beaconAction === void 0) { beaconAction = "ask"; }
        return "<div class='d-block mt-2'><button class='btn btn-outline-dark btn-sm text-bold'\n          onclick='javascript:Bcn." + beaconAction + "();'>\n          <i class='fa-fw far fa-comment fa-flip-horizontally mr-2' />\n          <b>" + t("Contact support") + "</b>\n        </button></div>";
    }
    Toastr.contactSupportButton = contactSupportButton;
})(Toastr || (Toastr = {}));
