// App's Store
// When adding new information, check ConnectedApp to see how the data is mapped.
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Sentry from "@sentry/react";
import { LOGGED_OUT_STATE } from "api/auth/types";
import { compose, createStore } from "redux";
import { generic } from "store/actions";
import { ID } from "store/id";
import reducer from "./reducers";
export var DEFAULT_THEME = {
    strokeThickness: "m"
};
/**
 * Stores information for operating on multiple groups, e.g. adding child IDs
 * to multiple groups at once. Effectively a dictionary, whose key is the
 * group ID number and children are IDs.
 */
var GroupsOperationData = /** @class */ (function () {
    function GroupsOperationData() {
        this.data = {};
        /** Maps from id number to the real Group ID. We must use these otherwise equality does not work. */
        this.groupIds = {};
        this.groupCache = {};
    }
    GroupsOperationData.prototype.add = function (groupId, child) {
        if (!(groupId.id in this.data)) {
            this.data[groupId.id] = [];
            this.groupIds[groupId.id] = groupId;
        }
        this.data[groupId.id].push(child);
    };
    /** Do NOT mix calls to remove and add! */
    GroupsOperationData.prototype.remove = function (groupId, child) {
        if (!(groupId.id in this.data)) {
            this.data[groupId.id] = [];
            this.groupIds[groupId.id] = groupId;
        }
        this.data[groupId.id].push(child);
        // If the group has a parent and would end up with
        // no children, remove that group too.
        var group = this.getGroup(groupId);
        if (group.items.length == this.data[groupId.id].length && group.parentGroup) {
            this.remove(group.parentGroup, groupId);
        }
    };
    GroupsOperationData.prototype.getGroup = function (groupId) {
        if (groupId.id in this.groupCache) {
            return this.groupCache[groupId.id];
        }
        var group = appStore.getState().groups.filter(ID.matching(groupId))[0];
        this.groupCache[groupId.id] = group;
        return group;
    };
    GroupsOperationData.prototype.hasData = function () {
        return Object.keys(this.data).length > 0;
    };
    return GroupsOperationData;
}());
export { GroupsOperationData };
export var DEFAULT_CHEM_MARK_CONFIG = {
    stateOfMatterStyle: "normal",
};
var initialState = {
    zoom: 1,
    items: [],
    arrows: [],
    groups: [],
    tool: "select",
    auth: LOGGED_OUT_STATE,
    theme: __assign({}, DEFAULT_THEME),
    chemMark: __assign({}, DEFAULT_CHEM_MARK_CONFIG)
};
var sentryReduxEnhancer = Sentry.createReduxEnhancer({});
var composeEnhancers = window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;
export var appStore = createStore(reducer, initialState, composeEnhancers(sentryReduxEnhancer));
export default {
    // Data accessors.
    items: function () { return appStore.getState().items; },
    arrows: function () { return appStore.getState().arrows; },
    groups: function () { return appStore.getState().groups; },
    // Proxy to execute actionss.
    dispatch: function (action) { appStore.dispatch(action); },
    dispatchFn: function (description, fn) { appStore.dispatch(generic(description, fn)); },
    // Helper methods.
    selectedItems: function () {
        return appStore.getState().items.filter(function (item) { return item.selected; });
    },
    selectedArrows: function () {
        return appStore.getState().arrows.filter(function (arrow) { return arrow.selected; });
    },
    zoom: function () {
        return appStore.getState().zoom;
    },
    // Filters down a list of IDs to those that exist.
    filterExisting: function (ids) {
        return ids.filter(function (id) {
            switch (id.type) {
                case "arrow":
                    return appStore.getState().arrows.some(function (a) { return a.id === id; });
                case "apparatus":
                    return appStore.getState().items.some(function (a) { return a.id === id; });
                case "group":
                    return appStore.getState().groups.some(function (g) { return g.id === id; });
            }
        });
    },
    // TODO: Clean up this as well. This was a hack to return all IDs for
    // all selecpted objects.
    selectedObjectsID: function () {
        var selectedApparatusIDs = appStore.getState().items.filter(function (i) { return i.selected; }).map(function (i) { return i.id; });
        var selectedArrowsIDs = appStore.getState().arrows.filter(function (i) { return i.selected; }).map(function (i) { return i.id; });
        var allIds = [];
        allIds.push.apply(allIds, selectedApparatusIDs);
        allIds.push.apply(allIds, selectedArrowsIDs);
        return allIds;
    },
    // Returns the logged in state, or null if not logged in.
    loggedInState: function () {
        var auth = appStore.getState().auth;
        if (auth.state == "logged_in") {
            return auth;
        }
        return;
    }
};
export function extractDebugInfoFromStore() {
    var state = appStore.getState();
    return {
        tier: state.tier,
        tool: state.tool,
        zoom: state.zoom,
        chemMark: state.chemMark,
        currentCloudDiagram: state.currentCloudDiagram,
        arrowsCount: state.arrows.length,
        itemsCount: state.items.length,
    };
}
