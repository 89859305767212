import { P, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path } from "paper";
var FreehandDrawing = /** @class */ (function () {
    function FreehandDrawing() {
    }
    FreehandDrawing.prototype.render = function (appearance) {
        var path = new Path(Segments.apply(void 0, appearance.points));
        var graphic;
        switch (appearance.drawStyle) {
            case "stroke":
                graphic = renderStroke(path, appearance);
                break;
            case "shape":
                graphic = renderShape(path, appearance);
                break;
            case "tube":
                graphic = renderTube(path, appearance);
                break;
        }
        var snapping = [];
        if (appearance.drawStyle == "tube") {
            var p1 = appearance.points[0];
            var vec1Out = P(p1[2][0], p1[2][1]);
            snapping.push({
                type: "connectable",
                at: P(p1[0][0], p1[0][1]),
                facing: vec1Out.angle - 90,
            });
            var pN = appearance.points[appearance.points.length - 1];
            var vecNOut = P(pN[1][0], pN[1][1]);
            snapping.push({
                type: "connectable",
                at: P(pN[0][0], pN[0][1]),
                facing: vecNOut.angle - 90,
            });
        }
        return {
            graphic: graphic,
            hitShape: graphic.strokeBounds.toShape(),
            snapping: snapping,
        };
    };
    FreehandDrawing.properties = {
        label: "Freehand drawing",
        defaultAppearance: {
            points: [],
            drawStyle: "stroke",
            strokeColor: SharedColors.stroke,
            strokeWidth: 4.0,
            tubeWidth: 10.0,
            tubeWallThickness: 4.0,
        },
        appearanceSpecs: [
            // {
            //     spec: "radio", key: "drawStyle", label: "Draw Style",
            //     options: [
            //         { value: "stroke", label: "Drawing" },
            //         { value: "tube", label: "Tube" },
            //         { value: "shape", label: "Shape" },
            //     ],
            // },
            {
                spec: "toggle-buttons", key: "drawStyle", label: "Draw Style",
                options: [
                    { value: "stroke", label: "Drawing", icon: "signature" },
                    { value: "shape", label: "Shape", icon: "draw-polygon" },
                    { value: "tube", label: "Tube", icon: "check-double fa-flip-horizontal" },
                ],
                size: "large",
            },
            {
                spec: "slider", key: "strokeWidth", label: "Stroke width",
                min: 1.0, max: 20.0, step: 0.05,
                visible: function (a) { return a.drawStyle == "shape" || a.drawStyle == "stroke"; },
            },
            {
                spec: "slider", key: "tubeWidth", label: "Tube width",
                min: 6.0, max: 20.0, step: 0.05,
                visible: function (a) { return a.drawStyle == "tube"; }
            },
            {
                spec: "slider", key: "tubeWallThickness", label: "Tube wall thickness",
                min: 1.0, max: 6.0, step: 0.05,
                visible: function (a) { return a.drawStyle == "tube"; }
            },
        ],
    };
    return FreehandDrawing;
}());
export { FreehandDrawing };
function renderStroke(path, appearance) {
    path.strokeColor = appearance.strokeColor;
    path.strokeWidth = appearance.strokeWidth;
    return path;
}
function renderShape(path, appearance) {
    path.closePath();
    path.strokeColor = appearance.strokeColor;
    path.strokeWidth = appearance.strokeWidth;
    path.fillColor = "red";
    return path;
}
function renderTube(path, appearance) {
    path.strokeColor = appearance.strokeColor;
    path.strokeWidth = appearance.tubeWidth + appearance.tubeWallThickness * 2;
    var inside = path.clone();
    inside.strokeColor = "white";
    inside.strokeWidth = appearance.tubeWidth;
    return new Group([path, inside]);
}
