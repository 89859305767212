import { LazyRaster } from "apparatus/draw";
import { Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var Smoke = /** @class */ (function () {
    function Smoke() {
        this.smokeLazyRaster = new LazyRaster("./images/smoke.png");
        this.vapourLazyRaster = new LazyRaster("./images/vapour.png");
    }
    Smoke.prototype.render = function (appearance) {
        switch (appearance.style) {
            case "trails": return this.renderTrails(appearance);
            case "smoke-real": return this.renderSmokeReal(appearance);
            case "vapour-real": return this.renderVapourReal(appearance);
        }
    };
    Smoke.prototype.renderTrails = function (appearance) {
        var baseColor = (appearance.color === "default" ? "#444444" : appearance.customColor).color();
        var darkerColor = baseColor.ladd(-7);
        var trail1 = new Path(Segments([[1, 3], [14, -37], [26, -19]], [[-8, -124], [-2, 47], [-11, 44]])).withGradientFill("up", [[baseColor.alpha(0.2 * appearance.alpha).string(), 0.3], [baseColor.alpha(0).string(), 1.0]]).close();
        var trail2 = new Path(Segments([[1, 2], [-15, -36], [-7, -32]], [[-1, -157], [51, 47], [44, 61]])).withGradientFill("up", [[darkerColor.alpha(0.2 * appearance.alpha).string(), 0.3], [darkerColor.alpha(0).string(), 1.0]]).close();
        var trail3 = new Path(Segments([[3, 4], [31, -38], [37, -26]], [[9, -97], [-6, 27], [-16, 25]])).withGradientFill("up", [[baseColor.alpha(0.3 * appearance.alpha).string(), 0.3], [baseColor.alpha(0).string(), 1.0]]).close();
        var graphic = new Group([trail1, trail2, trail3]);
        graphic.scale(appearance.size * 0.5 + 0.5, appearance.size);
        graphic.opacity = appearance.alpha;
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    Smoke.prototype.renderSmokeReal = function (appearance) {
        var prescale = 0.7;
        // Size of the image must be known ahead of time to set the hit shape.
        var graphic = new Group([]);
        this.smokeLazyRaster.clone(function (raster) {
            raster.scale(prescale * appearance.size);
            raster.opacity = appearance.alpha;
            graphic.addChild(raster);
            if (appearance.color === "custom") {
                var filter = Shape.Rectangle(R(0, 0, 95, 250, Pivot.CENTER));
                filter.scale(prescale * appearance.size);
                filter.fillColor = appearance.customColor;
                filter.blendMode = "source-atop";
                graphic.addChild(filter);
                var rasterised = graphic.rasterize();
                graphic.removeChildren();
                graphic.addChild(rasterised);
            }
        });
        var hitShape = Shape.Rectangle(R(0, 0, 95, 250, Pivot.CENTER));
        hitShape.scale(prescale * appearance.size);
        return {
            graphic: graphic,
            hitShape: hitShape,
        };
    };
    Smoke.prototype.renderVapourReal = function (appearance) {
        var prescale = 0.7;
        // Size of the image must be known ahead of time to set the hit shape.
        var graphic = new Group([]);
        this.vapourLazyRaster.clone(function (raster) {
            raster.scale(prescale * appearance.size);
            raster.opacity = appearance.alpha;
            graphic.addChild(raster);
            if (appearance.color === "custom") {
                var filter = Shape.Rectangle(R(0, 0, 95, 250, Pivot.CENTER));
                filter.scale(prescale * appearance.size);
                filter.fillColor = appearance.customColor;
                filter.blendMode = "source-atop";
                graphic.addChild(filter);
                var rasterised = graphic.rasterize();
                graphic.removeChildren();
                graphic.addChild(rasterised);
            }
        });
        var hitShape = Shape.Rectangle(R(0, 0, 95, 250, Pivot.CENTER));
        hitShape.scale(prescale * appearance.size);
        return {
            graphic: graphic,
            hitShape: hitShape,
        };
    };
    Smoke.properties = {
        label: "Smoke",
        flippable: true,
        defaultAppearance: {
            size: 1.0,
            style: "trails",
            alpha: 1.0,
            color: "default",
            customColor: "#e13232",
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "style", label: "Type",
                options: [
                    { value: "trails", label: "Trails" },
                    { value: "smoke-real", label: "Smoke", tier: "boost" },
                    { value: "vapour-real", label: "Vapour", tier: "boost" },
                ],
                allowSelectingTierLockedOptions: true,
            },
            {
                spec: "slider", key: "size", label: "Size",
                min: 0.5, max: 1.5, step: 0.01, unit: "%",
            },
            {
                spec: "slider", key: "alpha", label: "Opacity",
                min: 0.25, max: 1.0, step: 0.01, unit: "%"
            },
            {
                spec: "radio", key: "color", label: "Colour",
                options: [
                    { value: "default", label: "Default" },
                    { value: "custom", label: "Custom", tier: "boost" },
                ],
            },
            { spec: "color", key: "customColor", label: "", visible: function (a) { return a.color === "custom"; } }
        ],
        isBoostRequired: function (a) { return a.style == "smoke-real" || a.style == "vapour-real"; }
    };
    return Smoke;
}());
export { Smoke };
export var smokeTwirl = [
    [[0, 0], [-25, -5], [32, -5]],
    [[51, 17], [114, -7], [-9, 4]],
    [[8, 27], [-1, -8], [2, 8]],
    [[48, 31], [-6, -4], [10, 7]],
    [[17, 41], [1, -4], [-5, 10]],
    [[42, 57], [9, -15], [-15, 25]],
    [[32, 137]]
];
