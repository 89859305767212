import { mirrorX, simpleGradient } from "apparatus/draw";
import * as p from "paper";
import { smoothCorner } from "../draw";
import { P, Pivot, R, Segments, SharedColors, SharedGradients } from "./common";
var Balance = /** @class */ (function () {
    function Balance() {
    }
    Balance.prototype.render = function (appearance) {
        switch (appearance.design) {
            case "cream": return design1(appearance);
            case "modern": return designModern(appearance);
        }
    };
    Balance.properties = {
        label: "Balance",
        defaultAppearance: {
            design: "modern",
            display: "0.00 g",
            windshield: "none",
            size: 200,
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "design", label: "Design",
                options: [
                    { value: "cream", label: "Generic Cream" },
                    { value: "modern", label: "Modern White" },
                ],
            },
            {
                spec: "text", key: "display", label: "Display"
            },
            {
                spec: "slider", key: "size", label: "Size",
                min: 180, max: 300, step: 10,
            },
            {
                visible: function (a) { return a.design == "modern"; },
                spec: "radio", key: "windshield", label: "Windshield",
                options: [
                    { value: "none", label: "No windshield" },
                    { value: "enabled", label: "With windshield" },
                ],
            }
        ],
    };
    return Balance;
}());
export { Balance };
/** Older design, creamy looking */
function designModern(_a) {
    var display = _a.display, windshield = _a.windshield, size = _a.size;
    var width = size;
    var bodyColor = "#dbd7d9";
    var plate = p.Shape.Rectangle(R(25, 0, width - 50, 10)); // The weighing plate.
    var leg = p.Shape.Rectangle(R(10, 45, 30, 5));
    var body = new p.Path(Segments([[width / 2 - 20, 10], , [0, 3]], [[width / 2, 19], [0, -5]], [[width / 2, 45]]));
    mirrorX(body);
    smoothCorner(body.segments[3]);
    smoothCorner(body.segments[2]);
    body.closePath();
    body.position.x += width / 2;
    plate.setGradientFill("right", SharedGradients.metal);
    plate.withStroke("thinner", "#888888");
    body.setGradientFill("down", simpleGradient(bodyColor));
    body.withStroke("thinner", bodyColor.color().darken(0.1).hex());
    leg.fillColor = SharedColors.mediumMetal;
    var leg2 = leg.clone();
    leg2.position.x += width - 50;
    // Add buttons with labels.
    var button1 = p.Shape.Rectangle(R(15, 25, 16, 10, Pivot.CENTER_LEFT), 2.0);
    button1.fillColor = "#f5ba49";
    button1.strokeColor = "#6e6b3d";
    button1.strokeWidth = 0.0;
    var button1Text = new p.PointText(P(22, 38));
    button1Text.content = "O/I";
    button1Text.fontSize = "7";
    button1Text.justification = "center";
    button1Text.fontWeight = "bold";
    button1Text.fillColor = "#888888";
    var button2 = p.Shape.Rectangle(R(35, 25, 16, 10, Pivot.CENTER_LEFT), 2.0);
    button2.fillColor = "#f5ba49";
    button2.strokeColor = "#6e6b3d";
    button2.strokeWidth = 0.0;
    var button2Text = new p.PointText(P(42, 38));
    button2Text.content = "TARE";
    button2Text.fontSize = "7";
    button2Text.fontWeight = "bold";
    button2Text.justification = "center";
    button2Text.fillColor = "#888888";
    // Screen display.
    var displayScreenWidth = Math.min(120, width - 80);
    var displayScreen = p.Shape.Rectangle(R(60, 18, displayScreenWidth, 20, Pivot.TOP_LEFT), 3.0);
    displayScreen.fillColor = "#87cee7";
    displayScreen.strokeColor = "#53a2be";
    displayScreen.strokeWidth = 1.0;
    var displayText = new p.PointText(P(displayScreen.bounds.right - 5, 33));
    displayText.content = display;
    displayText.style = {
        fontFamily: "monospace",
        fontSize: "16",
        justification: "right"
    };
    displayText.fillColor = "#2d758f";
    var controls = new p.Group([displayScreen, displayText, button1, button2, button1Text, button2Text]);
    controls.position.x = body.position.x;
    var graphic = new p.Group([leg, leg2, plate, body, controls]);
    // Windshield.
    if (windshield == "enabled") {
        var windshieldHeight = size - 30;
        var leftWall = p.Shape.Rectangle(R(10, 30, 10, windshieldHeight, Pivot.BOTTOM_LEFT), 2.0);
        var rightWall = p.Shape.Rectangle(R(width - 10, 30, 10, windshieldHeight, Pivot.BOTTOM_RIGHT), 2.0);
        var topWall = p.Shape.Rectangle(R(width / 2, -windshieldHeight + 30, width - 20, 10, Pivot.TOP_CENTER), 2.0);
        var wallGradient = [["#dddddd", 0.0], ["#cccccc", 1.0]];
        leftWall.setGradientFill("right", wallGradient);
        rightWall.setGradientFill("right", wallGradient);
        topWall.setGradientFill("down", wallGradient);
        var glass = p.Shape.Rectangle(R(width / 2, 30, width - 25, windshieldHeight - 5, Pivot.BOTTOM_CENTER));
        glass.setGradientFill("right-down", SharedGradients.glass);
        graphic.insertChildren(0, [glass, leftWall, rightWall, topWall]);
    }
    return {
        graphic: graphic,
        hitShape: graphic.bounds.toShape(),
        snapping: [
            graphic.placeable(),
            plate.topEdgeAsPlaceableSurface(),
        ],
        pivot: body.bounds.center,
    };
}
function design1(_a) {
    var display = _a.display, windshield = _a.windshield, size = _a.size;
    var width = size;
    var bodyColor = "#c4b18c";
    var plate = p.Shape.Rectangle(R(0, 0, width, 10));
    var body = p.Shape.Rectangle(R(0, 10, width, 30));
    var leg = p.Shape.Rectangle(R(0, 40, 30, 5));
    plate.setGradientFill("down", SharedGradients.metal);
    plate.withStroke("thinner", "#888888");
    body.setGradientFill("down", simpleGradient(bodyColor));
    body.withStroke("thinner", bodyColor);
    leg.fillColor = SharedColors.darkMetal;
    var leg2 = leg.clone();
    leg2.position.x += width - 30;
    var displayText = new p.PointText(P(width - 10, 30));
    displayText.content = display;
    displayText.style = {
        fontFamily: "monospace",
        fontSize: "16",
        justification: "right"
    };
    var graphic = new p.Group([body, plate, leg, leg2, displayText]);
    return {
        graphic: graphic,
        hitShape: graphic.bounds.toShape(),
        snapping: [
            graphic.placeable(),
            graphic.topEdgeAsPlaceableSurface(),
        ]
    };
}
