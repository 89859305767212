// Tutorial
import { t } from "i18next";
import { g } from "./analytics";
import { IntroJs } from "./shim-types";
export function runTutorial() {
    var stepsCompleted = 0;
    introJs()
        .setOptions({
        showStepNumbers: false,
        tooltipPosition: true,
        overlayOpacity: 0.5,
        nextLabel: t("Next") + " <i class='fa fa-arrow-right fa-fw'></i>",
        prevLabel: "&nbsp;<i class='fa fa-arrow-left fa-fw'></i>&nbsp;",
        doneLabel: t("Done"),
        hidePrev: true,
        hideNext: true,
        steps: [
            {
                element: "#sidebar",
                intro: t("tutorial.start"),
                position: IntroJs.Position.RIGHT
            },
            {
                element: "#apparatus-search",
                intro: t("tutorial.search")
            },
            {
                element: "#property-panel .content-container",
                intro: t("tutorial.properties")
            },
            {
                element: "#toolbox",
                intro: t("tutorial.tools")
            },
            {
                element: "#history-toolbox",
                intro: t("tutorial.undo")
            },
            {
                element: "#menu-export",
                intro: t("tutorial.export"),
            },
            {
                element: "#cloud-menu",
                intro: t("tutorial.cloud"),
            },
            {
                element: ".BeaconFabButtonFrame",
                intro: t("tutorial.finish"),
                position: IntroJs.Position.LEFT,
            }
        ]
    })
        .onchange(function () {
        stepsCompleted++;
    })
        .onexit(function () {
        g("Intro2", "Exit", stepsCompleted);
    })
        .oncomplete(function () {
        g("Intro2", "Complete", 1);
    })
        .start();
    g("IntroClicked");
}
