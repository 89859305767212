import { mirrorX, setDefaultStyle } from "apparatus/draw";
import { createBolt } from "apparatus/library/clamp";
import { Group, Path, Shape } from "paper";
import { P, Segments } from "../common";
var bodyStrokeColor = "#999999";
var bodyFillColor = "#cccccc";
var rubberFillColor = "#35a1db";
var rubberStrokeColor = "#1c719e";
var BuretteHolder = /** @class */ (function () {
    function BuretteHolder() {
    }
    BuretteHolder.prototype.render = function (appearance) {
        var body = new Path(Segments([[20, -20], [0, 0], [20, 0]], [[60, -50], [0, 0], [10, -20]], [[80, -50], [5, -5], [-20, 20]], [[80, 50], [-20, -20], [5, 5]], [[60, 50], [10, 20]], [[20, 20], [20, 0]]));
        mirrorX(body);
        body.closePath();
        setDefaultStyle(body);
        body.strokeColor = bodyStrokeColor;
        body.fillColor = bodyFillColor;
        var graphic = new Group([body]);
        // Place the rubber holders on the main body.
        var rubberPositions = [P(71, 52), P(-71, 52), P(71, -52), P(-71, -52)];
        rubberPositions.forEach(function (p) {
            var rubber = createRubber(p);
            graphic.addChild(rubber);
        });
        // Adjustable arms.
        var movableHand = new Path(Segments([[0, 0]], [[50, -15]], [[100, -60], [0, 0], [10, -10]], [[112, -48], [10, -10]], [[105, -43]], [[110, -38], [-5, 0]], [[125, -45], [0, 0], [3, -3]], [[130, -40], [3, -3]], [[112, -31], [0, 0], [-5, 5]], [[100, -38]], [[55, -10]]));
        setDefaultStyle(movableHand);
        movableHand.strokeColor = bodyStrokeColor;
        movableHand.fillColor = bodyFillColor;
        var rubber2 = createRubber(P(108, -55));
        var movableRT = new Group([movableHand, rubber2]);
        var movableRB = movableRT.clone();
        movableRB.scale(1, -1, P(0, 0));
        var movableLT = movableRT.clone();
        movableLT.scale(-1, 1, P(0, 0));
        var movableLB = movableLT.clone();
        movableLB.scale(1, -1, P(0, 0));
        // Rotate the movable parts.
        var leftClamping = appearance.leftClamping - 10;
        var rightClamping = appearance.rightClamping - 10;
        movableRT.rotate(rightClamping, P(50, -15));
        movableRB.rotate(-rightClamping, P(50, 15));
        movableLT.rotate(-leftClamping, P(-50, -15));
        movableLB.rotate(leftClamping, P(-50, 15));
        graphic.insertChildren(0, [movableLT, movableRT, movableLB, movableRB]);
        // Center pivot
        var pivot = createBolt();
        graphic.addChild(pivot);
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: P(0, 0),
            snapping: { type: "attachable", at: P(0, 0) }
        };
    };
    BuretteHolder.properties = {
        label: "Burette Holder",
        defaultAppearance: {
            scale: 1,
            leftClamping: 20,
            rightClamping: 20,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 1.1, step: 0.01, unit: "%"
            },
            {
                spec: "slider", key: "leftClamping", label: "Left Clamp",
                min: 0, max: 30, step: 0.5
            },
            {
                spec: "slider", key: "rightClamping", label: "Right Clamp",
                min: 0, max: 30, step: 0.5
            },
        ],
    };
    return BuretteHolder;
}());
export { BuretteHolder };
function createRubber(p) {
    return Shape.Circle(p, 8)
        .withFill(rubberFillColor)
        .withStroke("thinner", rubberStrokeColor);
}
