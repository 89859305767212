import { mirrorX, setDefaultStyle } from "apparatus/draw";
import { Group, Path, Rectangle, Shape } from "paper";
import { P, Segments } from "./common";
var PetriDish = /** @class */ (function () {
    function PetriDish() {
    }
    PetriDish.prototype.render = function (appearance) {
        var diameter = appearance.size;
        var height = 10 + appearance.size / 20;
        var path = new Path();
        path.addSegments(Segments([[0, 0]], [[0, height]]));
        mirrorX(path, diameter / 2);
        var graphic = new Group([path]);
        setDefaultStyle(graphic);
        graphic.strokeCap = "butt";
        var hitShape = Shape.Rectangle(new Rectangle(0, 0, diameter, height));
        var liquidMask = new Path.Rectangle(new Rectangle(0, 0, diameter, height));
        return { graphic: graphic, hitShape: hitShape, liquidMask: liquidMask, snapping: { type: "placeable", at: P(diameter / 2, height) } };
    };
    PetriDish.properties = {
        label: "Petri dish",
        canContainLiquids: true,
        defaultAppearance: {
            // Standard petri dish is 100mm.
            size: 100
        },
        appearanceSpecs: [
            {
                spec: "slider",
                key: "size",
                label: "Diameter",
                min: 50,
                max: 150,
            },
        ],
    };
    return PetriDish;
}());
export { PetriDish };
