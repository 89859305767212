var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Gradients } from "apparatus/gradients";
import { interpolate, P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var colors = [
    { value: "#aaaaaacc", label: "Transparent" },
    { value: "#ff0000", label: "Red" },
    { value: "#2e68aa", label: "Blue" },
    { value: "#f7e02d", label: "Yellow", tier: "boost" },
    { value: "#55b273", label: "Green", tier: "boost" },
    { value: "#222222", label: "Black", tier: "boost" },
];
/**
 * https://www.cmscientific.com/proddetail.php?prod=Syringe+Filter+-+30mm+0.45um+PVDF+with+Luer+lock+(Colour+Coded%3A+Red)+100%2Fpk&gclid=CjwKCAiA9dGqBhAqEiwAmRpTC7-DalfUMJjqU5rHrlTZbnurhcEDFk082NjiKPeu3MayTQd0r6IyShoCEdkQAvD_BwE
 *
 */
var SyringeFilter = /** @class */ (function () {
    function SyringeFilter() {
    }
    SyringeFilter.prototype.render = function (_a) {
        var color = _a.color, diameter = _a.diameter;
        var thickness;
        if (diameter < 20) {
            thickness = 6;
        }
        else if (diameter < 30) {
            thickness = 7;
        }
        else {
            thickness = 8;
        }
        var mainColor = color.color();
        var colorRing = new Path.Rectangle(R(0, 0, diameter, thickness, Pivot.CENTER), 2)
            .withGradientFill("right", Gradients.cylinder(mainColor, 8))
            .withStroke("thin", mainColor.darken(0.3).hex());
        var inlet = new Path(Segments([interpolate(diameter, [12, 40], [P(3, -10), P(5, -14)]).toArray()], [interpolate(diameter, [12, 40], [P(2, 0), P(4, 0)]).toArray()], [interpolate(diameter, [12, 40], [P(1, 14), P(3, 18)]).toArray()]))
            .mirrorX()
            .withGradientFill("right", Gradients.cylinder("#ccccccaa".color(), 10))
            .withStroke("thin", "#aaaaaa")
            .close();
        var graphic = new Group([inlet, colorRing]);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape()
        };
    };
    SyringeFilter.properties = {
        label: "Syringe Filter",
        defaultAppearance: {
            diameter: 30,
            color: colors[0].value
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 12, max: 40, unit: "mm"
            },
            {
                spec: "color-wells", key: "color", label: "Colour",
                colors: colors
            }
        ],
    };
    SyringeFilter = __decorate([
        staticImplements()
    ], SyringeFilter);
    return SyringeFilter;
}());
export { SyringeFilter };
