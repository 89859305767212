import { P, SharedColors, SharedGradients } from "apparatus/library/common";
import { CompoundPath, Group, Path } from "paper";
/**
 * https://www.timstar.co.uk/en120975-timstar-choice-chamber.html
 */
var ChoiceChamber = /** @class */ (function () {
    function ChoiceChamber() {
    }
    ChoiceChamber.prototype.render = function (appearance) {
        var outline = new Path.Circle(P(0, 0), 100);
        var vertical = new Path.Line(P(0, -100), P(0, 100));
        var horizontal = new Path.Line(P(-100, 0), P(100, 0));
        var graphic = new Group([outline, vertical, horizontal]).withStroke("default");
        if (appearance.darkHalf) {
            graphic.insertChild(0, new Path.Arc(P(0, -100), P(-100, 0), P(0, 100)).close().withFill("#444444"));
        }
        if (appearance.lid) {
            var lid = new CompoundPath([]);
            lid.fillRule = "evenodd";
            lid.addChild(new Path.Circle(P(0, 0), 102));
            lid.addChild(new Path.Circle(P(0, 0), 20));
            lid.withStroke("default", SharedColors.glassEdge)
                .withGradientFill("right-down", SharedGradients.glass);
            graphic.addChild(lid);
        }
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    ChoiceChamber.properties = {
        label: "Choice Chamber",
        defaultAppearance: {
            scale: 1.0,
            lid: true,
            darkHalf: false,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 2, step: 0.01, unit: "%"
            },
            {
                spec: "checkbox-group", label: "Options", checkboxes: [
                    { key: "lid", label: "Add lid" },
                    { key: "darkHalf", label: "Half-dark" },
                ]
            }
        ],
    };
    return ChoiceChamber;
}());
export { ChoiceChamber };
