// Base library pieces for defining new apparatus.
//
// 1. ApparatusComponent vs Apparatus
//     - ApparatusComponent is a React component that gives access to the
//    data and lifecycle routines. Each apparatus is housed inside this,
//    regardless of its type.
//     - Apparatus is the base class that can be extended to define
//       how a specific apparatus type is rendered and styled.
// 2. Creating new Apparatus
//    Specific apparatus are defined inside the ./library folder
//    and registered by the createApparatus method.
import { TUBE_DIAMETER_SPEC } from "apparatus/library/tubes";
export function commonSpecsForApparatusKind(kind) {
    switch (kind) {
        case "tube": return [TUBE_DIAMETER_SPEC];
    }
}
