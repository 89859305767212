import { addMarkers, mirrorX, setDefaultStyle } from "apparatus/draw";
import { interpolate, P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
/**
 * Sources:
 *  - https://www.amazon.co.uk/StonyLab-Conical-Centrifuge-Polypropylene-Graduated/dp/B07MYY1WKW/ref=asc_df_B07MYY1WKW/?tag=googshopuk-21&linkCode=df0&hvadid=241063450015&hvpos=1o4&hvnetw=g&hvrand=9989796212979711613&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9045901&hvtargid=pla-757694563196&psc=1
 *  - https://www.amazon.co.uk/Uotyle-Plastic-Centrifuge-Conical-Graduated/dp/B07SQ1WVZ1/ref=asc_df_B07SQ1WVZ1/?tag=googshopuk-21&linkCode=df0&hvadid=375433463406&hvpos=1o9&hvnetw=g&hvrand=9989796212979711613&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9045901&hvtargid=pla-846557735754&psc=1&tag=&ref=&adgrpid=82457261171&hvpone=&hvptwo=&hvadid=375433463406&hvpos=1o9&hvnetw=g&hvrand=9989796212979711613&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9045901&hvtargid=pla-846557735754
 */
var CentrifugeTube = /** @class */ (function () {
    function CentrifugeTube() {
    }
    CentrifugeTube.prototype.render = function (appearance) {
        var width = interpolate(appearance.size, [10, 50], [20, 30]);
        var height = interpolate(appearance.size, [10, 50], [105, 117]);
        var tapering = interpolate(appearance.size, [10, 50], [25, 17]);
        var shape = new Path(Segments([[width / 2, 0]], [[width / 2, height - tapering]], [[3, height]]));
        mirrorX(shape);
        var liquidMask = shape.clone();
        setDefaultStyle(shape);
        var graphic = new Group([shape]);
        addMarkers(graphic, P(width / 2 - 5, height - tapering), 0, P(0, 10));
        if (appearance.cap) {
            var cap = Shape.Rectangle(R(0, -2, width + 4, 15, Pivot.TOP_CENTER));
            var capColor = capColors[appearance.capColor];
            cap.withStroke(3.0, capColor.color().darken(0.1).hex());
            cap.setGradientFill("right", [
                [capColor.color().lighten(0.1).hex(), 0.0],
                [capColor, 1.0]
            ]);
            graphic.addChild(cap);
        }
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.bounds.toShape()
        };
    };
    CentrifugeTube.properties = {
        label: "Centrifuge tube",
        canContainLiquids: true,
        defaultAppearance: {
            cap: true,
            size: 50,
            capColor: "blue",
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 10, max: 50
            },
            {
                spec: "checkbox", key: "cap", label: "Show cap"
            },
            {
                spec: "radio", key: "capColor", label: "Cap colour",
                options: [
                    { value: "blue", label: "Blue" },
                    { value: "orange", label: "Orange" },
                    { value: "white", label: "White" },
                ],
                visible: function (a) { return a.cap; },
            }
        ],
    };
    return CentrifugeTube;
}());
export { CentrifugeTube };
var capColors = {
    "orange": "#d9901a",
    "blue": "#3475b8",
    "white": "#dddddd"
};
