var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Sentry from "@sentry/react";
import { g } from "analytics";
import { commandProcessor } from "command/command";
import { DiagramDatas } from "file/loading";
import { t } from "i18next";
import { eraseItems, replace } from "store/actions";
import { ID } from "store/id";
import { Toast } from "toast";
import { callApi } from "../api/api";
import { setCloudDiagram, setThemeStrokeThickness } from "../store/actions";
import { appStore } from "../store/store";
export function openDiagram(rawFile, isTemplate) {
    g("Cloud", "Open");
    // TODO: Check if user is ok to open this file.
    commandProcessor.clear();
    appStore.dispatch(eraseItems());
    // This sucks because we have to first erase the items,
    // then use a setTimeout so that React can update the props
    // and states. This should flush out the objectRefs in
    // editor canvas. Without this, if an item is of the same
    // ID but different type, the ref might be erased, e.g.
    // if "test tube" has ID 1 and is replaced by "beaker" with
    // same ID, a different ApparatusComponent is created but
    // the ref is the same since it's based on ID only. This may
    // result in the ref being erased when the old component is removed.
    Sentry.withScope(function (scope) {
        if (!isTemplate) {
            scope.setExtra("diagram.id", rawFile.id);
            scope.setExtra("diagram.data", rawFile.rawData);
        }
        var file = __assign(__assign({}, rawFile), { data: DiagramDatas.parseJson(rawFile.rawData) });
        // TODO: This code is duplicated with app.tsx.
        // Reset IDs based on the doc.
        ID.resetFor(file.data);
        // Update the stroke thickness ahead of time.
        appStore.dispatch(setThemeStrokeThickness(file.data.theme.strokeThickness));
        setTimeout(function () {
            appStore.dispatch(replace(file, isTemplate));
        }, 0);
    });
}
export var CloudFiles;
(function (CloudFiles) {
    /**
     * Saves the current diagram to cloud. Thumbnail must be passed as base64 encoded image.
     * @param forceNew Whether a new diagram should be created regardless if the
     * current diagram is already linked to a cloud diagram.
     */
    function save(loggedIn, thumbnail, forceNew, callbacks) {
        var _a, _b;
        if (forceNew === void 0) { forceNew = false; }
        g("Cloud", "Save");
        var data = JSON.stringify(DiagramDatas.getCurrentDocument());
        // Check if this is a new diagram.
        var currentDiagramId = (_a = appStore.getState().currentCloudDiagram) === null || _a === void 0 ? void 0 : _a.id;
        var requestMethod;
        var requestPayload;
        var title;
        if (currentDiagramId === undefined || forceNew) {
            // Create new diagram.
            var inputTitle = prompt(t("cloud.save_prompt"), t("cloud.default_name"));
            if (inputTitle === null)
                return;
            title = inputTitle || "Untitled",
                requestMethod = "POST";
            requestPayload = {
                diagramSerialized: data,
                thumbnailBase64: thumbnail,
                title: title,
            };
        }
        else {
            // Save existing diagram.
            title = ((_b = appStore.getState().currentCloudDiagram) === null || _b === void 0 ? void 0 : _b.title) || "";
            requestMethod = "PATCH";
            requestPayload = {
                id: currentDiagramId,
                diagramSerialized: data,
                thumbnailBase64: thumbnail,
            };
        }
        toastr.info(t("cloud.saving"), title, { timeOut: 1000 });
        callApi("diagram", requestMethod, loggedIn, requestPayload, {
            success: function (response) {
                appStore.dispatch(setCloudDiagram(response));
                toastr.success(t("cloud.save_success"), response.title);
            },
            special: function (error) {
                if (error.error_type == "diagram_exceeded") {
                    g("ErrorPopups", "StorageExceeded");
                    Toast.error(t("cloud.diagram_limit_exceeded", { count: error.current }), t("cloud.diagram_limit_exceeded_title", { limit: error.limit }), { timeOut: 20000, extendedTimeOut: 20000 });
                    // Trigger callback with delay to allow for toastr animation.
                    setTimeout(callbacks.onDiagramsLimitExceeded, 1000);
                    return true;
                }
                return false;
            }
        });
    }
    CloudFiles.save = save;
    function rename(loggedIn, diagram, title, callback) {
        callApi("diagram", "PATCH", loggedIn, {
            id: diagram.id,
            title: title,
        }, {
            success: function (response) {
                var _a;
                // Update the current document if the renamed file matches the currently opened file.
                if (((_a = appStore.getState().currentCloudDiagram) === null || _a === void 0 ? void 0 : _a.id) == diagram.id) {
                    appStore.dispatch(setCloudDiagram({ id: diagram.id, title: title }));
                }
                toastr.success(t("cloud.rename_success"), title);
                callback();
            },
            generic: function () {
                toastr.error(t("cloud.rename_error"));
            }
        });
    }
    CloudFiles.rename = rename;
    function remove(auth, diagram, callback) {
        callApi("diagrams", "DELETE", auth, { id: diagram.id }, {
            success: function () {
                var _a;
                toastr.success(t("cloud.delete_success"));
                // If the current diagram has been deleted, reset the diagram ID.
                if (((_a = appStore.getState().currentCloudDiagram) === null || _a === void 0 ? void 0 : _a.id) == diagram.id) {
                    appStore.dispatch(setCloudDiagram(undefined));
                }
                callback();
            },
            generic: function () {
                toastr.error(t("cloud.delete_error"));
            }
        });
    }
    CloudFiles.remove = remove;
})(CloudFiles || (CloudFiles = {}));
