import { P } from "apparatus/library/common";
import { createTap, Tap as CommonTap } from "apparatus/library/common/tap";
var Tap = /** @class */ (function () {
    function Tap() {
    }
    Tap.prototype.render = function (appearance) {
        var graphic = createTap(appearance);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(10).toShape(),
            pivot: P(0, 0)
        };
    };
    Tap.properties = {
        label: "Tap",
        defaultAppearance: CommonTap.defaultAppearance,
        appearanceSpecs: CommonTap.appearanceSpecsWithFacing,
    };
    return Tap;
}());
export { Tap };
