import { setDefaultStyle } from "apparatus/draw";
import { Path, Rectangle, Shape } from "paper";
import { P, Segments } from "./common";
var Tank = /** @class */ (function () {
    function Tank() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 150, maxX: 600,
                minY: 80, maxY: 300,
                fromAppearance: function (a) { return P(a.width, a.height); },
                toAppearance: function (f, p) { return f({ width: p.x, height: p.y }); },
                scale: 2.0,
            }
        ];
    }
    Tank.prototype.render = function (appearance) {
        var width = appearance.width;
        var height = appearance.height;
        var graphic = new Path(Segments([[0, 0]], [[0, height]], [[width, height]], [[width, 0]]));
        setDefaultStyle(graphic);
        var rect = new Rectangle(0, 0, width, height);
        var hitShape = Shape.Rectangle(rect);
        var liquidMask = new Path.Rectangle(rect);
        return {
            graphic: graphic, hitShape: hitShape, liquidMask: liquidMask,
            liquidMaskOpening: { start: P(0, 0), end: P(width, 0) },
            snapping: graphic.placeable()
        };
    };
    Tank.properties = {
        label: "Tank",
        canContainLiquids: true,
        meniscus: true,
        pourable: true,
        defaultAppearance: {
            width: 400,
            height: 220,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Width",
                min: 150, max: 600
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 80, max: 300
            },
        ],
    };
    return Tank;
}());
export { Tank };
