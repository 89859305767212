import { strokeWidth } from "apparatus/draw";
import { P } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
import { Random } from "random/random";
var Beads = /** @class */ (function () {
    function Beads() {
    }
    Beads.prototype.render = function (appearance) {
        var boundDiameter = appearance.boundDiameter, color = appearance.color, diameter = appearance.diameter;
        var outlineColor = color.color().ladd(-10).hex();
        var radius = diameter / 2;
        var boundRadius = boundDiameter / 2;
        var graphic = new Group();
        var step = 15;
        var r = new Random();
        for (var x = -boundRadius; x < boundRadius; x += step) {
            for (var y = -boundRadius; y < boundRadius; y += step) {
                var position = P(x, y).add(r.randomUniformVector(step / 3));
                if (position.length > boundRadius)
                    continue;
                var bacteria = Shape.Circle(position, radius);
                bacteria.withFill(color).withStroke(strokeWidth("default"), outlineColor);
                graphic.addChild(bacteria);
            }
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: P(0, 0),
            xray: new Path.Circle(P(0, 0), boundRadius + radius),
        };
    };
    Beads.properties = {
        label: "Beads",
        defaultAppearance: {
            diameter: 10,
            boundDiameter: 100,
            color: "#72b52f"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 4, max: 16, unit: "mm",
            },
            {
                spec: "slider", key: "boundDiameter", label: "Contained within",
                min: 25, max: 150, unit: "mm"
            },
            {
                spec: "color", key: "color", label: "Colour",
            }
        ],
    };
    return Beads;
}());
export { Beads };
