var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { SVG } from "apparatus/svg";
import { staticImplements } from "types";
var RECOLORS = {
    white: ["#f8f8f8", "#dddddd", "#444444"],
    grey: ["#dddddd", "#c2c2c2", "#666666"],
    medium: ["#CE813F", "#AC6C35", "#513319"],
    dark: ["#512A13", "#42220F", "#2A160A"],
};
var Hand = /** @class */ (function () {
    function Hand() {
    }
    Hand.prototype.render = function (appearance) {
        var allSvgs = SVG.hand();
        var graphic = allSvgs.children[appearance.index].clone();
        graphic.scale(appearance.scale);
        allSvgs.remove();
        // Remap colours.
        if (appearance.color != "light") {
            for (var _i = 0, _a = graphic.children; _i < _a.length; _i++) {
                var child = _a[_i];
                child.fillColor = recolor(appearance.color, child.fillColor.toCSS(true));
            }
        }
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape()
        };
    };
    Hand.properties = {
        label: "Hand",
        flippable: true,
        defaultAppearance: {
            index: 1,
            scale: 1,
            color: "grey",
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "index", label: "Gesture",
                options: [
                    { value: 1, label: "Hold 1" },
                    { value: 8, label: "Hold 2" },
                    { value: 11, label: "Hold 3" },
                    { value: 10, label: "Hold 4" },
                    { value: 5, label: "Pick" },
                    { value: 3, label: "Point" },
                    { value: 9, label: "Open (side)" },
                ],
            },
            {
                spec: "color-wells", key: "color", label: "Skin",
                colors: [
                    { value: "white", label: "White", hex: RECOLORS.white[0] },
                    { value: "grey", label: "Grey", hex: RECOLORS.grey[1] },
                    { value: "light", label: "Light", hex: "#ffc59b" },
                    { value: "medium", label: "Medium", hex: RECOLORS.medium[0] },
                    { value: "dark", label: "Dark", hex: RECOLORS.dark[0] },
                ],
            },
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.6, max: 1.25, unit: "%", step: 0.01,
            }
        ],
    };
    Hand = __decorate([
        staticImplements()
    ], Hand);
    return Hand;
}());
export { Hand };
function recolor(skinColor, input) {
    switch (input) {
        case "#ffc59b": return RECOLORS[skinColor][0]; // Skin.
        case "#db854b": return RECOLORS[skinColor][1]; // Shade.
        case "#5e3214": return RECOLORS[skinColor][2]; // Outline.
    }
    return input;
}
