var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { strokeWidth } from "apparatus/draw";
import { expandBy, P, Pivot, R, Segments, SharedColors, SharedGradients } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
import { staticImplements } from "types";
var Oven = /** @class */ (function () {
    function Oven() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 100, maxX: 400,
                minY: 200, maxY: 800,
                fromAppearance: function (a) { return P(a.width / 2, a.height); },
                toAppearance: function (f, p) { return f({ width: p.x * 2, height: p.y }); },
                scale: P(1.0, 2.0),
            }
        ];
    }
    Oven.prototype.render = function (appearance) {
        var width = appearance.width, height = appearance.height;
        var inside = new Path.Rectangle(R(0, 0, width, height, Pivot.TOP_CENTER))
            .withFill("#cccccc");
        var shell = new Path.Rectangle(expandBy(inside.bounds, 30, 30, 30, 100), 10.0)
            .withFill("#eeeeee").withStroke("default", SharedColors.stroke);
        var insideStroke = new Path.Rectangle(inside.bounds, 3.0).withStroke("thick", "#777777");
        var snapOffset = P(0, -strokeWidth("thick") / 2);
        var insideShading = [
            ["#00000000", 0.2],
            ["#0000001a", 0.55],
            ["#00000010", 0.6],
            ["#00000000", 1.0],
        ];
        var depth = Math.min(height, width) * 0.1;
        var leftShade = new Path(Segments([inside.bounds.topLeft], [inside.bounds.topLeft.add(P(depth, depth))], [inside.bounds.bottomLeft.add(P(depth, 0))], [inside.bounds.bottomLeft])).withGradientFill("right", insideShading);
        var rightShade = new Path(Segments([inside.bounds.topRight], [inside.bounds.topRight.add(P(-depth, depth))], [inside.bounds.bottomRight.subtract(P(depth, 0))], [inside.bounds.bottomRight])).withGradientFill("left", insideShading);
        var topShade = new Path(Segments([inside.bounds.topLeft], [inside.bounds.topLeft.add(P(depth, depth))], [inside.bounds.topRight.add(P(-depth, depth))], [inside.bounds.topRight])).withGradientFill("down", insideShading);
        var snapping = [
            shell.placeable(),
            {
                type: "placeable_surface",
                start: inside.bounds.bottomLeft.add(snapOffset),
                end: inside.bounds.bottomRight.add(snapOffset)
            },
        ];
        var graphic = new Group([shell, inside, leftShade, topShade, rightShade]);
        // Add shelves.
        for (var i = 0; i < appearance.shelves; i++) {
            var y = (i + 1) / (appearance.shelves + 1) * height;
            var start = P(-width / 2, y);
            var end = P(width / 2, y);
            graphic.addChild(new Path.Line(start, end).withStroke("thick", "#777777"));
            snapping.push({
                type: "placeable_surface", start: start.add(snapOffset), end: end.add(snapOffset)
            });
        }
        graphic.addChild(insideStroke);
        // Add panel doodads.
        graphic.addChildren([
            Shape.Circle(P(0, height + 50), 25.0)
                .withStroke("default", SharedColors.stroke)
                .withGradientFill("right-down", SharedGradients.cylinderMetal),
            Shape.Circle(P(-100, height + 50), 8.0)
                .withFill("#df1818")
                .withStroke("default", "#c9120d")
        ]);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: snapping,
        };
    };
    Oven.properties = {
        label: "Oven",
        defaultAppearance: {
            shelves: 0,
            width: 500,
            height: 450,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Width",
                min: 200, max: 800
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 200, max: 800
            },
            {
                spec: "toggle-buttons", size: "text", key: "shelves", label: "Shelves",
                options: [
                    { label: "0", value: 0 },
                    { label: "1", value: 1 },
                    { label: "2", value: 2, tier: "boost" },
                    { label: "3", value: 2, tier: "boost" },
                ],
            }
        ],
    };
    Oven = __decorate([
        staticImplements()
    ], Oven);
    return Oven;
}());
export { Oven };
