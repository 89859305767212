var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { getFirstAnchorWithName } from "apparatus/common";
import { addMarkers, mirrorX, setDefaultStyle } from "apparatus/draw";
import { createApparatus } from "apparatus/library";
import { P, Pp, Segments } from "apparatus/library/common";
import { t_add } from "i18n_utils";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var HUB_COLORS = {
    "Green": "#519e82",
    "Yellow": "#e8d387",
    "Black": "#414040",
    "Blue": "#2c82a2",
    "Pink": "#be94aa",
    "Purple": "#a185c7",
};
var DEMO_COLOURS = {
    "Green": "#519e82",
    "Yellow": "#e8d387",
    "Black": "#414040",
    "Blue": "#2c82a2",
};
var Syringe = /** @class */ (function () {
    function Syringe() {
    }
    Syringe.prototype.render = function (appearance) {
        var length = appearance.size;
        var diameter = 16;
        var outletLength = 3;
        var radius = diameter / 2;
        var plungerRadius = radius;
        var outletRadius = 3;
        var body = new Path(Segments(
        // Start from the tip
        [[outletRadius, length + outletLength]], [[outletRadius, length]], [[radius, length - 3], [0, 3]], // Slight rounded corner
        [[radius, 0], undefined, [30, -2]]));
        mirrorX(body);
        var liquidMask = body.clone();
        setDefaultStyle(body);
        // Plunger.
        var plunger = new Path(Segments([[plungerRadius, length - 5], [0, 3]], [[plungerRadius, -2], undefined, [20, -1]]));
        mirrorX(plunger);
        plunger.closePath();
        setDefaultStyle(plunger);
        plunger.fillColor = "#eeeeee";
        plunger.position.y -= appearance.plungerSlide * length;
        var needle = createNeedle(appearance.hubColor, appearance.needleSize);
        needle.position.y += length;
        var graphic = new Group([body, plunger, needle]);
        addMarkers(graphic, P(plungerRadius - 5, length - 2), 0.3 * length, P(0, 10));
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.bounds.expand(10).toShape(),
            pivot: body.bounds.center,
            anchors: [
                { name: "tip_adjusted", at: needle.bounds.bottomCenter.subtract(P(0, 12)) }
            ]
        };
    };
    Syringe.properties = {
        label: "Syringe",
        canContainLiquids: true,
        defaultAppearance: {
            plungerSlide: 0.4,
            size: 90,
            needleSize: 30,
            hubColor: "#519e82"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 60, max: 120
            },
            {
                spec: "slider", key: "plungerSlide", label: "Plunger slide",
                min: 0.0, max: 0.7, step: 0.01,
            },
            {
                spec: "slider", key: "needleSize", label: "Needle size",
                min: 20, max: 50
            },
            { spec: "color", key: "hubColor", label: "Hub colour", swatches: HUB_COLORS, tier: "boost", demoSwatches: DEMO_COLOURS },
        ],
        actions: [
            {
                type: "add",
                icon: "plus",
                label: function () { return t_add("syringe filter"); },
                action: function (a, p, r, _a) {
                    var _b, _c;
                    var anchors = _a.anchors;
                    var position = (_c = (_b = getFirstAnchorWithName(anchors, "tip_adjusted")) === null || _b === void 0 ? void 0 : _b.at) !== null && _c !== void 0 ? _c : Pp(p);
                    var apparatus = createApparatus("syringe filter", position);
                    apparatus.rotation = r;
                    return apparatus;
                }
            }
        ]
    };
    return Syringe;
}());
export { Syringe };
var Needle = /** @class */ (function () {
    function Needle() {
    }
    Needle.prototype.render = function (appearance) {
        var graphic = createNeedle(appearance.hubColor, appearance.needleSize);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            pivot: graphic.children[1].bounds.center,
        };
    };
    Needle.properties = {
        label: "Needle",
        defaultAppearance: {
            needleSize: 30,
            hubColor: "#519e82"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "needleSize", label: "Needle size",
                min: 20, max: 50
            },
            { spec: "color", key: "hubColor", label: "Hub colour", swatches: HUB_COLORS, tier: "boost", demoSwatches: DEMO_COLOURS },
        ],
    };
    Needle = __decorate([
        staticImplements()
    ], Needle);
    return Needle;
}());
export { Needle };
function createNeedle(hubColor, needleSize) {
    var hub = new Path(Segments([[5, 2]], [[4, 13]], [[3, 14]], [[2, 22]]));
    mirrorX(hub);
    hub.closePath();
    hub.fillColor = hubColor;
    var needle = new Path(Segments([[0, 2]], [[0, needleSize + 20]]));
    needle.strokeWidth = 2.0;
    needle.strokeColor = "black";
    return new Group([needle, hub]);
}
