// Component for checkbox
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useTranslation } from "react-i18next";
import { TierContext } from "tiers/context";
export var Checkbox = function (props) {
    var _a;
    return (React.createElement("input", { type: "checkbox", className: "custom-control-input", ref: function (el) { return el && (el.indeterminate = (props.value === undefined)); }, id: props.id, checked: (_a = props.value) !== null && _a !== void 0 ? _a : false, disabled: props.disabled, onChange: function (e) { return props.onCheckedChange(e.target.checked); } }));
};
export var LabelledCheckbox = function (props) {
    var tiers = React.useContext(TierContext);
    var t = useTranslation().t;
    var disableCheckbox = !tiers.allow(props) && props.allowWhenLocked !== true;
    var className = "custom-control " + (props.useSwitch ? "custom-switch" : "custom-checkbox");
    var onClick = disableCheckbox ? function () { return tiers.popup("PP:" + props.id); } : undefined;
    return React.createElement("div", { className: className, onClick: onClick },
        React.createElement(Checkbox, __assign({}, props, { id: props.id, key: props.id, disabled: disableCheckbox })),
        React.createElement("label", { key: "label", className: "custom-control-label", htmlFor: props.id },
            props.label ? t(["props." + props.label, props.label], props.label) : "",
            tiers.badge(props, "ml-1") /* Display badge if locked. */));
};
export var LabelledCheckboxGroup = function (props) { return (React.createElement("div", null, props.checkboxes.map(function (c) { return React.createElement(LabelledCheckbox, __assign({}, c, { key: c.id })); }))); };
