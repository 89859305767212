import { mirrorX } from "apparatus/draw";
import { CenterR, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { breakPath, setDefaultStyle } from "../../draw";
var WashBottle = /** @class */ (function () {
    function WashBottle() {
    }
    WashBottle.prototype.render = function (appearance) {
        var size = appearance.size;
        var totalHeight = 30 + size;
        var width = Math.min(40, 15 + size / 5);
        var bottle = new Path(Segments([[12, 0]], [[12, 10], undefined, [0, 10]], [[width, 30], [0, -15]], [[width, totalHeight - 5], undefined, [0, 4]], [[width - 5, totalHeight]]));
        mirrorX(bottle);
        var liquidMask = bottle.clone();
        setDefaultStyle(bottle);
        // Draw the tube.
        var tube = new Path(Segments([[4, totalHeight - 20]], [[4, -40], undefined, [0, -15]], [[-15, -55], [10, -8]], [[-70, -10]], [[-70, 0]], [[-15, -45], undefined, [9, -7]], [[-4, -36], [0, -8]], [[-4, totalHeight - 20]]));
        var newTube = breakPath(tube, 4);
        setDefaultStyle(newTube);
        // Draw the cap.
        var cap = CenterR(0, 0, 25, 15).toShape();
        setDefaultStyle(cap);
        cap.fillColor = "white";
        var graphic = new Group([bottle, newTube, cap]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            liquidMask: liquidMask,
            snapping: graphic.placeable(),
        };
    };
    WashBottle.properties = {
        label: "Wash Bottle",
        canContainLiquids: true,
        defaultAppearance: {
            size: 100
        },
        flippable: true,
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 70, max: 150
            }
        ],
    };
    return WashBottle;
}());
export { WashBottle };
