import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { updateChemMarkConfigStateOfMatterStyle } from "store/actions";
import { appStore } from "store/store";
import { Articles } from "support/beacon";
import { ChemMark } from "text/parse";
import { Badge } from "tiers/badge";
import { TierContext } from "tiers/context";
/**
 * Hint UI element explaning how ChemMark works, to be used in the Property panel.
 * @param props: showUpgradeHint: if set to true, the upgrade hint will be shown if the user is not upgraded.
 */
export var PropertyPanelHint = function (props) {
    var t = useTranslation().t;
    return React.createElement(TierContext.Consumer, null, function (tiers) {
        return React.createElement("div", { className: "boxed-hint" },
            t("chemtext.auto_converts"),
            React.createElement("ul", { className: "mb-1" },
                React.createElement("li", null,
                    React.createElement(Entered, null, "H2SO4"),
                    " ",
                    React.createElement(RightArrow, null),
                    " ",
                    React.createElement(Converted, null, "H\u2082SO\u2084")),
                React.createElement("li", null,
                    React.createElement(Entered, null, "Mg2+"),
                    " ",
                    React.createElement(RightArrow, null),
                    " ",
                    React.createElement(Converted, null, "Mg\u00B2\u207A")),
                React.createElement("li", null,
                    React.createElement(Entered, null, "cm3"),
                    " ",
                    React.createElement(RightArrow, null),
                    " ",
                    React.createElement(Converted, null, "cm\u00B3")),
                React.createElement("li", null,
                    React.createElement(Entered, null, "2H2 + O2 -> 2H2O"),
                    " ",
                    React.createElement(RightArrow, null),
                    " ",
                    React.createElement(Converted, null, "2H\u2082 + O\u2082 \u2192 2H\u2082O")),
                React.createElement("li", null,
                    React.createElement(Entered, null, "[He]2s22p2"),
                    " ",
                    React.createElement(RightArrow, null),
                    " ",
                    React.createElement(Converted, null, "[He]2s\u00B22p\u00B2 \u2026"))),
            React.createElement("a", { href: "#", "data-beacon-article-sidebar": Articles.CHEMTEXT }, t("Learn more")),
            React.createElement("br", null),
            !tiers.tier && props.showUpgradeHint ? React.createElement("div", null,
                React.createElement("hr", null),
                React.createElement(Trans, { i18nKey: "chemtext.free_tier_description", count: ChemMark.FREE_TIER_CHAR_LIMIT },
                    "On free tier, this feature is ",
                    React.createElement("b", null, "limited to first 1"),
                    " letters. After that, \u26A0\uFE0F is added. Upgrade to remove this restriction"),
                " ",
                React.createElement(Badge, { tier: "boost" }),
                React.createElement("button", { className: "ui-button highlight text-center mt-1", onClick: function () { return tiers.popup("ChemText"); } }, t('Upgrade'))) : undefined);
    });
};
var Entered = function (props) { return React.createElement("span", { className: "text-white" }, props.children); };
var Converted = function (props) { return React.createElement("span", { className: "text-info font-weight-bold" }, props.children); };
var RightArrow = function () { return React.createElement("i", { className: "fa fa-arrow-right" }); };
/** Panel elements for configuring ChemMark config. */
export var createChemMarkGlobalConfigElements = function (enabled) {
    if (!enabled)
        return [];
    var highlightIndex = function (state) {
        switch (state) {
            case "normal": return 0;
            case "subscript": return 1;
        }
    }(appStore.getState().chemMark.stateOfMatterStyle);
    return [
        {
            spec: "toggle-horizontal-buttons", label: "States of matter style", key: "cm-state-of-matter",
            size: "text", highlightIndex: highlightIndex, buttons: [
                {
                    buttonLabel: "Normal", icon: "", hint: "ui.states_of_matter_normal_example", onClick: function () {
                        appStore.dispatch(updateChemMarkConfigStateOfMatterStyle("normal"));
                    }
                },
                {
                    buttonLabel: "Subscript", icon: "", hint: "ui.states_of_matter_subscript_example", onClick: function () {
                        appStore.dispatch(updateChemMarkConfigStateOfMatterStyle("subscript"));
                    }
                }
            ]
        }
    ];
};
