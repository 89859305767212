import { setDefaultStyle } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var CellCultureFlask = /** @class */ (function () {
    function CellCultureFlask() {
    }
    CellCultureFlask.prototype.render = function (appearance) {
        var bottle = new Path(Segments([[0, 0]], [[135, -1]], [[137, 5]], [[151, 0]], [[156, 19]], [[128, 30]], [[0, 30]])).close();
        setDefaultStyle(bottle);
        var liquidMask = bottle.clone();
        var cap = Shape.Rectangle(R(155, 9, 28, 15, Pivot.CENTER)).withGradientFill("right", Gradients.cylinder("#2b4fc3".color()));
        cap.rotate(70);
        var fold1 = new Path(Segments([[80, 4]], [[76, 24]])).withStroke("thinner", SharedColors.glassFold);
        var fold2 = new Path(Segments([[135, 9]], [[130, 24]])).withStroke("thinner", SharedColors.glassFold);
        var graphic = new Group([bottle, cap, fold1, fold2]);
        graphic.scale(appearance.scale);
        liquidMask.scale(appearance.scale, graphic.bounds.center);
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.bounds.toShape(),
            snapping: bottle.placeable(),
        };
    };
    CellCultureFlask.properties = {
        label: "Cell Culture Flask",
        canContainLiquids: true,
        defaultAppearance: {
            scale: 1.0
        },
        flippable: true,
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Size",
                min: 0.6, max: 1.4, step: 0.01, unit: "%"
            }
        ],
    };
    return CellCultureFlask;
}());
export { CellCultureFlask };
