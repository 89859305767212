import { P, Pivot, R } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
import { Random } from "random/random";
var MAX_PARTICLES = 500;
var COLOR_SWATCHES = {
    "Default": "#444444",
    "Red": "#E16060",
    "Green": "#5EC63D",
    "Blue": "#68B7D7",
};
var Particles = /** @class */ (function () {
    function Particles() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 10, maxX: 200,
                minY: 10, maxY: 200,
                toAppearance: function (f, p) { return f({ width: p.x, height: p.y }); },
                fromAppearance: function (a) { return P(a.width, a.height); },
                scale: 2.0,
            }
        ];
    }
    Particles.prototype.render = function (appearance) {
        var height = appearance.height, width = appearance.width;
        // Clamping: Maximum number of particles.
        var minSpacing = Math.sqrt(height * width / MAX_PARTICLES);
        var spacing = Math.max(minSpacing, appearance.spacing);
        var rows = Math.floor(height / spacing);
        var particlesPerRow = Math.floor(width / spacing);
        var baseSize = appearance.particleSize * (1.0 - appearance.blend);
        var sizeIncreasePerRow = appearance.particleSize * appearance.blend / rows;
        var r = new Random(123124);
        var graphic = new Group();
        for (var y = 0; y < rows; y++) {
            var size = baseSize + y * sizeIncreasePerRow;
            var xShift = y % 2 == 1 ? 0.5 : 0;
            for (var x = 0.5; x < (particlesPerRow - xShift * 2); x++) {
                var position = P((x + xShift) * spacing, (y + 0.5) * spacing);
                var finalSize = Math.max(0.1, size + r.randomCenteredValue());
                var particle = Shape.Circle(position.add(r.randomUniformVector(spacing * 0.25)), finalSize);
                graphic.addChild(particle);
            }
        }
        graphic.fillColor = appearance.color;
        return {
            graphic: graphic,
            hitShape: new Path.Rectangle(R(0, 0, width, height, Pivot.TOP_LEFT)).rectHitShape(),
            pivot: P(appearance.width / 2, appearance.height / 2)
        };
    };
    Particles.properties = {
        label: "Particles",
        defaultAppearance: {
            width: 60,
            height: 80,
            spacing: 8,
            particleSize: 5,
            color: "#444444",
            blend: 0.7,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Width",
                min: 30, max: 200,
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 30, max: 200,
            },
            {
                spec: "slider", key: "spacing", label: "Spacing",
                min: 6, max: 20, step: 0.01
            },
            {
                spec: "slider", key: "particleSize", label: "Particles size",
                min: 3.0, max: 12.0, step: 0.1,
            },
            {
                spec: "slider", key: "blend", label: "Blending",
                min: 0.0, max: 1.0, step: 0.01,
            },
            {
                spec: "color", key: "color", label: "Colour",
                swatches: COLOR_SWATCHES,
                demoSwatches: COLOR_SWATCHES,
                tier: "boost"
            }
        ],
    };
    return Particles;
}());
export { Particles };
