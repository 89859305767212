var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DEFAULT_SWATCHES } from "components/colorpicker";
var defaultSwatches = __assign({ "Black": "#000000", "Grey 1": "#333333", "Grey 2": "#666666", "Grey 3": "#999999", "Grey 4": "#cccccc", "White": "#ffffff" }, DEFAULT_SWATCHES);
var demoSwatches = {
    "Black": "#333333",
    "Grey": "#666666",
    'Blue': '#80cce6',
    'Red': '#e33d4d',
    'Green': '#3ac433',
};
var DEFAULT_STROKE = "#333333";
var DEFAULT_STROKE_THICKNESS = 4.0;
export var BasicShapes;
(function (BasicShapes) {
    var StrokeOnly;
    (function (StrokeOnly) {
        StrokeOnly.defaultAppearance = {
            stroke: DEFAULT_STROKE,
            strokeThickness: DEFAULT_STROKE_THICKNESS
        };
        StrokeOnly.specs = [
            { spec: "header", header: "Stroke", icon: "r,square" },
            {
                spec: "color", key: "stroke", label: "Colour",
                swatches: defaultSwatches,
                demoSwatches: demoSwatches,
            },
            {
                spec: "slider", key: "strokeThickness", label: "Thickness",
                tier: "boost",
                min: 1, max: 20,
            },
        ];
        function apply(item, appearance) {
            item.strokeWidth = appearance.strokeThickness;
            item.strokeColor = appearance.stroke;
        }
        StrokeOnly.apply = apply;
    })(StrokeOnly = BasicShapes.StrokeOnly || (BasicShapes.StrokeOnly = {}));
    BasicShapes.defaultAppearance = {
        style: "full",
        fill: "#cccccc",
        fillAlpha: 1.0,
        stroke: DEFAULT_STROKE,
        strokeThickness: DEFAULT_STROKE_THICKNESS
    };
    BasicShapes.header = { spec: "header", header: "Options", icon: "shapes" };
    BasicShapes.specs = [
        {
            spec: "toggle-buttons", key: "style", label: "Style", size: "large",
            options: [
                { value: "full", label: "With border", icon: "d,square fa-swap-opacity" },
                { value: "fill-only", label: "No border", icon: "square", },
                // { value: "stroke-only", label: "Border only", icon: "r,square" },
            ],
        },
        { spec: "header", header: "Fill", icon: "square", visible: function (a) { return a.style == "full" || a.style == "fill-only"; } },
        {
            spec: "color", key: "fill", label: "Colour",
            swatches: DEFAULT_SWATCHES,
            demoSwatches: demoSwatches,
            opacity: { key: "fillAlpha", range: [0, 1] },
            visible: function (a) { return a.style == "full" || a.style == "fill-only"; }
        },
        { spec: "header", header: "Border", icon: "r,square", visible: function (a) { return a.style == "full" || a.style == "stroke-only"; } },
        {
            spec: "color", key: "stroke", label: "Colour",
            tier: "boost",
            swatches: DEFAULT_SWATCHES,
            demoSwatches: demoSwatches,
            visible: function (a) { return a.style == "full" || a.style == "stroke-only"; }
        },
        {
            spec: "slider", key: "strokeThickness", label: "Thickness",
            tier: "boost",
            min: 1, max: 10,
            visible: function (a) { return a.style == "full" || a.style == "stroke-only"; }
        },
    ];
    function applyStyle(item, appearance) {
        if (appearance.style == "full" || appearance.style == "stroke-only") {
            // Draw stroke.
            StrokeOnly.apply(item, appearance);
        }
        if (appearance.style == "full" || appearance.style == "fill-only") {
            // Draw fill.
            item.fillColor = fillColor(appearance);
        }
    }
    BasicShapes.applyStyle = applyStyle;
    function applyCustom(appearance, setFill, setStroke) {
        if (appearance.style == "full" || appearance.style == "stroke-only") {
            // Draw stroke.
            setStroke(appearance.strokeThickness, appearance.stroke);
        }
        if (appearance.style == "full" || appearance.style == "fill-only") {
            // Draw fill.
            setFill(fillColor(appearance));
        }
    }
    BasicShapes.applyCustom = applyCustom;
    function fillColor(appearance) {
        return appearance.fill.color().alpha(appearance.fillAlpha).string();
    }
    BasicShapes.fillColor = fillColor;
})(BasicShapes || (BasicShapes = {}));
