import { mirrorX, setDefaultStyle } from "apparatus/draw";
import { Segments } from "apparatus/library/common";
import { Path, Shape } from "paper";
var Tripod = /** @class */ (function () {
    function Tripod() {
    }
    Tripod.prototype.render = function (appearance) {
        var height = appearance.height;
        var width = appearance.width;
        var graphic = new Path(Segments([[-5, height]], [[0, height - 5], [0, 5]], [[0, 5]], [[5, 0], [-5, 0]]));
        mirrorX(graphic, width / 2);
        setDefaultStyle(graphic);
        var hitShape = Shape.Rectangle(graphic.strokeBounds);
        return {
            graphic: graphic,
            hitShape: hitShape,
            snapping: [
                graphic.placeable(),
                graphic.topEdgeAsPlaceableSurface(),
            ]
        };
    };
    Tripod.properties = {
        label: "Tripod",
        defaultAppearance: {
            width: 120,
            height: 190,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Width",
                min: 70, max: 180
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 30, max: 250
            }
        ],
    };
    return Tripod;
}());
export { Tripod };
