var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { INFINITE_BOUNDS } from "apparatus/driver";
import { P } from "apparatus/library/common";
import { Group, Path, PointText } from "paper";
import { staticImplements } from "types";
var LengthLabel = /** @class */ (function () {
    function LengthLabel() {
        this.shouldUseBoundsCenter = true;
        this.drivers = [
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ startX: p.x, startY: p.y }); }, fromAppearance: function (a) { return P(a.startX, a.startY); }, scale: 1.0 }),
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ endX: p.x, endY: p.y }); }, fromAppearance: function (a) { return P(a.endX, a.endY); }, scale: 1.0 }),
        ];
    }
    LengthLabel.prototype.render = function (appearance) {
        var swapLabelPositionSign = appearance.swapLabelPosition ? -1 : 1;
        var start = P(appearance.startX, appearance.startY);
        var end = P(appearance.endX, appearance.endY);
        var angle = end.subtract(start).angle;
        var graphic = new Group([]);
        // Add line.
        graphic.addChild(new Path([start, end]).withStroke("default", "black"));
        // Add label.
        var mid = start.add(end).divide(2.0);
        var textPoint = mid;
        var text = new PointText({
            point: textPoint,
            content: start.getDistance(end).toFixed(2),
            fillColor: 'black',
            fontFamily: 'sans-serif',
            fontSize: 15,
            justification: 'center'
        });
        var textWidth = text.bounds.width;
        var textHeight = text.bounds.height;
        textPoint.x += textWidth * Math.sin(angle.toRadians()) / 2 * swapLabelPositionSign;
        textPoint.y += textHeight * Math.cos(angle.toRadians()) / 2 * -swapLabelPositionSign;
        // Math.sin(x * 2) creates a "hump" profile between [0, 1]
        var paddingOffset = P(10 * Math.sin(Math.sin(angle.toRadians()) * 2) * swapLabelPositionSign, -4 * Math.sin(Math.cos(angle.toRadians()) * 2) * swapLabelPositionSign);
        textPoint = textPoint.add(paddingOffset);
        text.position = textPoint;
        graphic.addChild(text);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            pivot: P(0, 0)
        };
    };
    LengthLabel.properties = {
        label: "Length Label",
        defaultAppearance: {
            startX: 0,
            startY: 0,
            endX: 100,
            endY: 0,
            swapLabelPosition: false,
        },
        appearanceSpecs: [
            { spec: "header", header: "Label", icon: "text" },
            {
                spec: "button",
                label: "Swap position",
                onClick: function (a) { return ({ swapLabelPosition: !a.swapLabelPosition }); }
            }
        ],
    };
    LengthLabel = __decorate([
        staticImplements()
    ], LengthLabel);
    return LengthLabel;
}());
export { LengthLabel };
