var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import { Debug } from "vars";
import { P } from "../../apparatus/library/common";
/**
 * Component to display current state of rotation while
 * an item or collection of items is being rotated.
 * To use this component, instantiate it and update by setting state.
 */
var RotationOverlay = /** @class */ (function (_super) {
    __extends(RotationOverlay, _super);
    function RotationOverlay(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { pivot: P(0, 0), angle: 0 };
        return _this;
    }
    RotationOverlay.prototype.render = function () {
        if (!this.state.type)
            return null;
        if (Debug.PRESENTATION_MODE)
            return null;
        var _a = this.state, pivot = _a.pivot, rotation = _a.angle, type = _a.type;
        var labelOffsetX = 155 * Math.sin(rotation.toRadians()) - 30;
        var labelOffsetY = -135 * Math.cos(rotation.toRadians()) - 12;
        return React.createElement(React.Fragment, null,
            React.createElement("div", { id: "rotation-line", key: "guideLine", style: {
                    left: pivot.x,
                    top: pivot.y - 130,
                    transform: "rotate(" + rotation + "deg)"
                } }),
            type == "delta" ?
                React.createElement("div", { id: "rotation-base", key: "base_line", style: {
                        left: pivot.x,
                        top: pivot.y - 100,
                    } })
                : null,
            React.createElement("div", { id: "rotation-info", key: "rotationInfo", style: {
                    left: pivot.x + labelOffsetX,
                    top: pivot.y + labelOffsetY,
                } }, this.label()));
    };
    /** Determine the label to display based on given state. */
    RotationOverlay.prototype.label = function () {
        // Round the rotation to 1 decimal place.
        var rotation = Math.round(this.state.angle * 10) / 10;
        var rotationString = rotation.toFixed(1);
        switch (this.state.type) {
            case "rotation":
                return rotationString + "\u00B0";
            case "delta":
                return "\u0394=" + (rotation >= 0 ? "+" : "") + rotation + "\u00B0";
            default:
                return ""; // Should not happen
        }
    };
    return RotationOverlay;
}(React.Component));
export { RotationOverlay };
