var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { apparatusClass } from "apparatus/library";
import { Select } from "command/basic";
import { Sequence } from "command/command";
import { BreakGroup, GroupObjects } from "editing/grouping/commands";
import { getRootGroup } from "editing/grouping/operations";
import { Arrays } from "ext/array";
import appStore from "store/store";
import { t } from "i18next";
/**
 * Stores data about currently selected items and decisions about
 * the behaviour of the app based on those items.
 */
var Selected = /** @class */ (function () {
    function Selected(items, arrows) {
        var _a, _b;
        this.items = items;
        this.arrows = arrows;
        // Join all items.
        var allItems = __spreadArray(__spreadArray([], items), arrows);
        // Check for consistent type.
        this.sameApparatusType = (_a = items[0]) === null || _a === void 0 ? void 0 : _a.type;
        for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
            var item = items_1[_i];
            if (item.type != items[0].type) {
                this.sameApparatusType = undefined;
                break;
            }
        }
        this.sameApparatusKind = ((_b = items[0]) === null || _b === void 0 ? void 0 : _b.type) ? apparatusClass(items[0].type).properties.kind : undefined;
        for (var _c = 0, items_2 = items; _c < items_2.length; _c++) {
            var item = items_2[_c];
            if (apparatusClass(item.type).properties.kind !== apparatusClass(items[0].type).properties.kind) {
                this.sameApparatusKind = undefined;
                break;
            }
        }
        this.isItemsWithLiquidOnly = this.arrows.length == 0 && this.items.every(function (i) { return i.liquid !== undefined; });
        this.isItemsSupportingMeniscus = this.arrows.length == 0 && this.items.every(function (i) { var _a; return (_a = apparatusClass(i.type).properties.meniscus) !== null && _a !== void 0 ? _a : false; });
        var itemsNotInGroups = [];
        var arrowsNotInGroups = [];
        var rootGroupIds = [];
        var rootGroups = [];
        for (var _d = 0, allItems_1 = allItems; _d < allItems_1.length; _d++) {
            var item = allItems_1[_d];
            if (item.parentGroup) {
                var rootGroup = getRootGroup(item.parentGroup);
                if (!Arrays.includes(rootGroupIds, rootGroup.id)) {
                    rootGroupIds.push(rootGroup.id);
                    rootGroups.push(rootGroup);
                }
            }
            else {
                switch (item.id.type) {
                    case "apparatus":
                        itemsNotInGroups.push(item);
                        break;
                    case "arrow":
                        arrowsNotInGroups.push(item);
                        break;
                    case "group":
                        continue;
                }
            }
        }
        this.rootGroups = rootGroups;
        this.standaloneItems = itemsNotInGroups;
        this.standaloneArrows = arrowsNotInGroups;
    }
    Selected.prototype.getConsistentItemsLiquidColor = function () {
        var _a, _b, _c;
        var color = (_b = (_a = this.items[0].liquid) === null || _a === void 0 ? void 0 : _a.color) !== null && _b !== void 0 ? _b : "";
        if (this.items.length == 1)
            return color;
        for (var _i = 0, _d = this.items; _i < _d.length; _i++) {
            var item = _d[_i];
            if (((_c = item.liquid) === null || _c === void 0 ? void 0 : _c.color) !== color)
                return;
        }
        return color;
    };
    Object.defineProperty(Selected.prototype, "isEmpty", {
        get: function () {
            return this.items.length == 0 && this.arrows.length == 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Selected.prototype, "standaloneObjectsCount", {
        get: function () {
            return this.standaloneArrows.length + this.standaloneItems.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Selected.prototype, "groupsCount", {
        get: function () {
            return this.rootGroups.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Selected.prototype, "displayString", {
        /** Returns the display string, e.g. "2 groups & 1 item" */
        get: function () {
            if (this.groupsCount == 0) {
                return t("ui.item", { count: this.standaloneObjectsCount });
            }
            if (this.standaloneObjectsCount == 0) {
                return t("ui.group", { count: this.groupsCount });
            }
            return t("ui.group", { count: this.groupsCount }) + " & " + t("ui.item", { count: this.standaloneObjectsCount });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Selected.prototype, "shouldAllowGrouping", {
        get: function () { return this.standaloneObjectsCount + this.groupsCount >= 2; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Selected.prototype, "shouldAllowUngrouping", {
        get: function () { return this.groupsCount == 1 && this.standaloneObjectsCount == 0; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Selected.prototype, "shouldAllowRegrouping", {
        get: function () {
            return (this.items.length == 1)
                && Selected.lastRegroupData.ids.length > 0
                && Arrays.includes(Selected.lastRegroupData.ids, this.items[0].id);
        },
        enumerable: false,
        configurable: true
    });
    /** Returns a command to group selected objects. Returns undefined on no-op. */
    Selected.prototype.createGroupObjectsCommand = function () {
        if (!this.shouldAllowGrouping)
            return;
        Selected.lastRegroupData.ids = [];
        var ids = __spreadArray(__spreadArray(__spreadArray([], this.rootGroups), this.standaloneArrows), this.standaloneItems).map(function (i) { return i.id; });
        return new GroupObjects(ids);
    };
    /** Returns a command to ungroup selected objects. Returns undefined on no-op. */
    Selected.prototype.createUngroupObjectsCommand = function () {
        if (!this.shouldAllowUngrouping)
            return;
        Selected.lastRegroupData.ids = this.rootGroups[0].items;
        return new BreakGroup(this.rootGroups[0]);
    };
    /** Returns a command to re-group selected item. Returns undefined on no-op. */
    Selected.prototype.createRegroupObjectsCommand = function () {
        if (!this.shouldAllowRegrouping)
            return;
        var existingIds = appStore.filterExisting(Selected.lastRegroupData.ids);
        // Remove Ids of objects that have already been selected.
        var selecteIds = __spreadArray(__spreadArray([], this.items), this.arrows).map(function (i) { return i.id; });
        var toSelectIds = existingIds.filter(function (id) { return !Arrays.includes(selecteIds, id); });
        Selected.lastRegroupData.ids = [];
        // Select unselected items and then regroup items.
        return new Sequence([
            new Select(toSelectIds),
            new GroupObjects(existingIds)
        ]);
    };
    Selected.lastRegroupData = { ids: [] };
    return Selected;
}());
export { Selected };
