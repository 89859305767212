var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { INFINITE_BOUNDS } from "apparatus/driver";
import { P } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
import { Ray as CommonRay } from "./common";
var Ray = /** @class */ (function () {
    function Ray() {
        this.drivers = [
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { scale: 1.0, toAppearance: function (f, p) { return f({ s0x: p.x, s0y: p.y }); }, fromAppearance: function (a) { return P(a.s0x, a.s0y); } }),
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { scale: 1.0, toAppearance: function (f, p) { return f({ s1x: p.x, s1y: p.y }); }, fromAppearance: function (a) { return P(a.s1x, a.s1y); } }),
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { scale: 1.0, toAppearance: function (f, p) { return f({ s2x: p.x, s2y: p.y }); }, fromAppearance: function (a) { return P(a.s2x, a.s2y); }, visible: function (a) { return a.segments >= 2; } }),
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { scale: 1.0, toAppearance: function (f, p) { return f({ s3x: p.x, s3y: p.y }); }, fromAppearance: function (a) { return P(a.s3x, a.s3y); }, visible: function (a) { return a.segments >= 3; } }),
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { scale: 1.0, toAppearance: function (f, p) { return f({ s4x: p.x, s4y: p.y }); }, fromAppearance: function (a) { return P(a.s4x, a.s4y); }, visible: function (a) { return a.segments >= 4; } }),
        ];
        this.shouldUseBoundsCenter = true;
    }
    Ray.prototype.render = function (a) {
        var ray = new Path([
            P(a.s0x, a.s0y), P(a.s1x, a.s1y),
        ]);
        if (a.segments >= 2) {
            ray.addSegments([P(a.s2x, a.s2y)]);
        }
        if (a.segments >= 3) {
            ray.addSegments([P(a.s3x, a.s3y)]);
        }
        if (a.segments >= 4) {
            ray.addSegments([P(a.s4x, a.s4y)]);
        }
        ray.withStroke(a.rayThickness, a.rayColor);
        var hitPath = ray.clone();
        var graphic = new Group([ray]);
        CommonRay.maybeAddArrows(a, ray, [0.5], graphic);
        return {
            graphic: graphic,
            hitShape: { type: "stroke", path: hitPath },
            pivot: P(0, 0)
        };
    };
    Ray.properties = {
        label: "Ray",
        defaultAppearance: __assign(__assign(__assign({}, CommonRay.defaultAppearanceRayOn), CommonRay.defaultAppearanceArrow), { segments: 2, s0x: -100, s0y: 0, s1x: 0, s1y: 0, s2x: 50, s2y: 50, s3x: 100, s3y: 50, s4x: 120, s4y: 0 }),
        appearanceSpecs: __spreadArray(__spreadArray([
            {
                spec: "toggle-buttons", key: "segments", label: "Segments", size: "text",
                options: [
                    { value: 1, label: "1" },
                    { value: 2, label: "2" },
                    { value: 3, label: "3" },
                    { value: 4, label: "4" },
                ],
            }
        ], CommonRay.specsRayOnly), CommonRay.arrowSpecs),
    };
    Ray = __decorate([
        staticImplements()
    ], Ray);
    return Ray;
}());
export { Ray };
