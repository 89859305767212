import { mirrorX, setDefaultStyle } from "apparatus/draw";
import { Group, Path, PointText, Shape } from "paper";
import { SVG } from "../svg";
import { CenterR, P, Pivot, R, Segments, SharedColors, SharedGradients } from "./common";
var baseDiameter = 75;
var baseHeight = 15;
var height = 110; // Total height = height + baseHeight
var barrelDiameter = 12;
var largerDiameter = 15;
var BunsenBurner = /** @class */ (function () {
    function BunsenBurner() {
    }
    BunsenBurner.prototype.render = function (appearance) {
        if (appearance.flame == "simple") {
            return this.renderSimple(appearance);
        }
        return this.renderDefault(appearance);
    };
    BunsenBurner.prototype.renderDefault = function (appearance) {
        var base = new Path(Segments([[0, height]], [[barrelDiameter / 2, height], undefined, [2.0, 0.0]], [[baseDiameter / 2, height + baseHeight - 3], [-2.0, 0]], [[baseDiameter / 2, height + baseHeight]]));
        mirrorX(base);
        base.position = P(0, height);
        base.closed = true;
        setDefaultStyle(base);
        base.strokeColor = "#375587";
        base.fillColor = "#477dd9";
        var barrel = this.barrel();
        var collar = this.collar(appearance.collar);
        var tip = Shape.Rectangle(R(0, 0, largerDiameter, 18, Pivot.TOP_CENTER))
            .withStroke("thinner", SharedColors.mediumMetal)
            .withGradientFill("right", SharedGradients.metal);
        var gasInlet = new Path(Segments([[2, 0]], [[2, 10]], [[3, 10]], [[2, 15]], [[3, 15]], [[2, 20]], [[3, 20]], [[1, 25]]));
        mirrorX(gasInlet);
        gasInlet.setGradientFill("left", SharedGradients.metal);
        gasInlet.rotate(-90);
        gasInlet.position = P(15, height - 20);
        gasInlet.withStroke("thinner", SharedColors.mediumMetal);
        gasInlet.closePath();
        if (appearance.gas == "left") {
            gasInlet.scale(-1, 1, P(0, 0));
        }
        var graphic = new Group([gasInlet, barrel, base, collar, tip]);
        var hitShapes = [Shape.Rectangle(graphic.bounds)];
        // Add flame if specified
        var flame = this.flame(appearance);
        if (flame) {
            flame.scale(appearance.scale);
            flame.position.x = 0;
            flame.position.y = 0;
            graphic.addChild(flame);
            hitShapes.push(Shape.Rectangle(flame.bounds));
        }
        return {
            graphic: graphic,
            hitShape: hitShapes,
            pivot: P(0, height / 2),
            snapping: graphic.placeable(),
        };
    };
    BunsenBurner.prototype.renderSimple = function (appearance) {
        var text = new PointText({
            point: P(0, 40),
            content: "HEAT",
            fillColor: 'black',
            fontFamily: 'sans-serif',
            fontSize: 40,
            justification: 'center'
        });
        var arrow = new Path(Segments([[0, -60]], [[22, -22]], [[7, -22]], [[7, 0]], [[-7, 0]], [[-7, -22]], [[-22, -22]]));
        arrow.closePath();
        arrow.fillColor = "#de3a3a";
        var graphic = new Group([arrow, text]);
        graphic.scale(appearance.scale);
        return { graphic: graphic, hitShape: graphic.bounds.toShape() };
    };
    BunsenBurner.prototype.barrel = function () {
        var barrel = Shape.Rectangle(R(0, 0, barrelDiameter, height, Pivot.TOP_CENTER));
        var vent = Shape.Rectangle(CenterR(0, 70, 6, 10), 2);
        vent.setGradientFill("down", [["#222222", 0.0], ["#666666", 1.0]]);
        barrel
            .withStroke("thinner", SharedColors.mediumMetal)
            .setGradientFill("right", SharedGradients.metal);
        var gasJet = Shape.Rectangle(CenterR(0, 73, 2, 4));
        gasJet.fillColor = "#b5ac57";
        return new Group([barrel, vent, gasJet]);
    };
    BunsenBurner.prototype.collar = function (valveOpening) {
        var collar = new Path.Rectangle(CenterR(0, 70, largerDiameter, 20));
        var vent = new Path.Rectangle(CenterR(0, 70, largerDiameter - 6, 14), 4);
        vent.position.x += valveOpening * (largerDiameter / 2 + 7);
        var collarWithVent = collar.subtract(vent);
        collarWithVent
            .withStroke("thinner", SharedColors.mediumMetal)
            .setGradientFill("right", SharedGradients.metal);
        return collarWithVent;
    };
    BunsenBurner.prototype.flame = function (appearance) {
        switch (appearance.flame) {
            case "red":
                switch (appearance.shape) {
                    case "straight":
                        return SVG.redFlame();
                    case "flat":
                    default:
                        return SVG.redFlameFlat();
                }
            case "blue":
                switch (appearance.shape) {
                    case "straight":
                        return SVG.blueFlame();
                    case "flat":
                    default:
                        return SVG.blueFlameFlat();
                }
            case "off":
            case "simple":
                return;
        }
    };
    BunsenBurner.properties = {
        label: "Bunsen Burner",
        defaultAppearance: {
            flame: "red",
            shape: "straight",
            scale: 1.0,
            collar: 0,
            gas: "right",
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "flame", label: "Flame heat",
                options: [
                    { value: "off", label: "Off" },
                    { value: "red", label: "Red flame (Low heat)" },
                    { value: "blue", label: "Blue flame (High heat)" },
                    { value: "simple", label: "Simple" } // LAUNCH_IN_3.2.0
                ]
            },
            {
                spec: "radio", key: "shape", label: "Flame shape",
                options: [
                    { value: "straight", label: "Straight up" },
                    { value: "flat", label: "Flattened" },
                ],
                visible: function (a) { return a.flame != "simple"; }
            },
            {
                spec: "slider", key: "scale", label: "Flame size",
                min: 0.5, max: 1.5, step: 0.05
            },
            {
                spec: "slider", key: "collar", label: "Adjust collar",
                min: -1, max: 1, step: 0.05,
                visible: function (a) { return a.flame != "simple"; },
                tier: "boost",
            },
            {
                spec: "toggle-buttons", key: "gas", label: "Gas", size: "text",
                options: [
                    { value: "left", label: "Left" },
                    { value: "right", label: "Right" },
                ],
                visible: function (a) { return a.flame != "simple"; },
            }
        ],
    };
    return BunsenBurner;
}());
export { BunsenBurner };
