var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { g } from "analytics";
import { AddArrow, DeleteArrows } from "arrows/command";
import { AddApparatus, DeleteApparatus, Deselect } from "command/basic";
import { commandProcessor, Sequence } from "command/command";
import { GroupObjects } from "editing/grouping/commands";
import { getRootGroup } from "editing/grouping/operations";
import { Arrays } from "ext/array";
import { ID } from "store/id";
import store from "store/store";
import { add } from "types";
/** Distance to paste each copy from the previous. */
export var DELTA = 50;
/** Handles clipboard operations for apparatus and other items. */
var Clipboard = /** @class */ (function () {
    function Clipboard() {
        var _this = this;
        /**
         * The contents of the clipboard, i.e. what to create when pasting.
         */
        this.contents = {
            apparatusNotInGroup: [],
            arrowsNotInGroup: [],
            groups: []
        };
        // Number of DELTA steps to paste the new items from the original.
        this.deltaSteps = 0;
        this.createApparatusData = function (a) {
            return __assign(__assign({}, a), { id: ID.mint("apparatus"), x: a.x + DELTA * _this.deltaSteps, y: a.y + DELTA * _this.deltaSteps, parentGroup: undefined });
        };
        this.createArrow = function (a) {
            return __assign(__assign({}, a), { id: ID.mint("arrow"), start: add(a.start, { x: DELTA * _this.deltaSteps, y: DELTA * _this.deltaSteps }), end: add(a.end, { x: DELTA * _this.deltaSteps, y: DELTA * _this.deltaSteps }), parentGroup: undefined });
        };
    }
    /**
     * Inspects the current store state and stores selected items in the clipboard
     * Returns true if there were selected items, false otherwise.
     */
    Clipboard.prototype.fillContentsFromStore = function () {
        if (store.selectedArrows().length + store.selectedItems().length == 0)
            return false;
        var newContents = {
            apparatusNotInGroup: [],
            arrowsNotInGroup: [],
            groups: []
        };
        // Track groups already added.
        var alreadyAddedRootGroupIds = [];
        for (var _i = 0, _a = store.selectedItems(); _i < _a.length; _i++) {
            var apparatus = _a[_i];
            if (apparatus.parentGroup) {
                var rootGroup = getRootGroup(apparatus.parentGroup);
                if (!Arrays.includes(alreadyAddedRootGroupIds, rootGroup.id)) {
                    newContents.groups.push(this.templatizeGroup(rootGroup));
                    alreadyAddedRootGroupIds.push(rootGroup.id);
                }
            }
            else {
                newContents.apparatusNotInGroup.push(apparatus);
            }
        }
        for (var _b = 0, _c = store.selectedArrows(); _b < _c.length; _b++) {
            var arrow = _c[_b];
            if (arrow.parentGroup) {
                var rootGroup = getRootGroup(arrow.parentGroup);
                if (!Arrays.includes(alreadyAddedRootGroupIds, rootGroup.id)) {
                    newContents.groups.push(this.templatizeGroup(rootGroup));
                    alreadyAddedRootGroupIds.push(rootGroup.id);
                }
            }
            else {
                newContents.arrowsNotInGroup.push(arrow);
            }
        }
        this.contents = newContents;
        return true;
    };
    /** Turn a group into a templated group. */
    Clipboard.prototype.templatizeGroup = function (group) {
        var _this = this;
        return {
            items: group.items.map(function (childId) {
                var idf = ID.matching(childId);
                switch (childId.type) {
                    case "apparatus":
                        return { type: "apparatus", data: store.selectedItems().filter(idf)[0] };
                    case "arrow":
                        return { type: "arrow", data: store.selectedArrows().filter(idf)[0] };
                    case "group":
                        return { type: "group", data: _this.templatizeGroup(store.groups().filter(idf)[0]) };
                }
            })
        };
    };
    Clipboard.prototype.cut = function () {
        if (!this.fillContentsFromStore())
            return;
        this.deltaSteps = 0;
        commandProcessor.execute(new Sequence([
            new DeleteApparatus(store.selectedItems()),
            new DeleteArrows(store.selectedArrows()),
        ]));
    };
    Clipboard.prototype.copy = function () {
        if (!this.fillContentsFromStore())
            return;
        this.deltaSteps = 1;
    };
    Clipboard.prototype.paste = function (opts) {
        var _this = this;
        if ((opts === null || opts === void 0 ? void 0 : opts.inPlace) === true) {
            // Remove a step, but don't go below 0.
            this.deltaSteps = Math.max(0, this.deltaSteps - 1);
        }
        commandProcessor.execute(new Sequence(__spreadArray(__spreadArray([
            new AddApparatus(this.contents.apparatusNotInGroup.map(this.createApparatusData)),
            new AddArrow(this.contents.arrowsNotInGroup.map(this.createArrow))
        ], flatten(this.contents.groups.map(function (g) { return _this.createPasteCommandsForGroup(g); }))), [
            // Deselect currently selected items
            new Deselect(__spreadArray(__spreadArray([], store.selectedItems().map(function (a) { return a.id; })), store.selectedArrows().map(function (a) { return a.id; }))),
        ])));
        this.deltaSteps++;
    };
    Clipboard.prototype.createPasteCommandsForGroup = function (group, groupId) {
        var commands = [];
        var childIds = [];
        for (var _i = 0, _a = group.items; _i < _a.length; _i++) {
            var child = _a[_i];
            switch (child.type) {
                case "apparatus":
                    var apparatus = this.createApparatusData(child.data);
                    childIds.push(apparatus.id);
                    commands.push(new AddApparatus([apparatus]));
                    break;
                case "arrow":
                    var arrow = this.createArrow(child.data);
                    childIds.push(arrow.id);
                    commands.push(new AddArrow([arrow]));
                    break;
                case "group":
                    var childGroupId = ID.mint("group");
                    childIds.push(childGroupId);
                    commands.push.apply(commands, this.createPasteCommandsForGroup(child.data, childGroupId));
                    break;
            }
        }
        commands.push(new GroupObjects(childIds, groupId));
        return commands;
    };
    return Clipboard;
}());
function flatten(commands) {
    return commands.reduce(function (acc, els) { acc.push.apply(acc, els); return acc; }, []);
}
/** Clipboard that also logs events to Google Analytics. */
var LoggingClipboard = /** @class */ (function () {
    function LoggingClipboard() {
        this.clipboard = new Clipboard();
    }
    LoggingClipboard.prototype.cut = function (source) {
        g("Cut", source);
        this.clipboard.cut();
    };
    LoggingClipboard.prototype.copy = function (source) {
        g("Copy", source);
        this.clipboard.copy();
    };
    LoggingClipboard.prototype.paste = function (source, opts) {
        var _a;
        var inPlace = (_a = opts === null || opts === void 0 ? void 0 : opts.inPlace) !== null && _a !== void 0 ? _a : false;
        g(inPlace ? "Paste::InPlace" : "Paste", source);
        if (inPlace) {
            // toastr.info("Item pasted in place. It will appear on top of the original item")
        }
        this.clipboard.paste(opts);
    };
    return LoggingClipboard;
}());
export default new LoggingClipboard();
