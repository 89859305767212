var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { setDefaultStyle, smoothCorner } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Tap } from "apparatus/library/common/tap";
import { hsl } from "color";
import { Group, Path, PointText, Shape } from "paper";
import { strokeWidth } from "../../draw";
var MANOMETER_OFFSET = 250;
var SchlenkLines = /** @class */ (function () {
    function SchlenkLines() {
    }
    SchlenkLines.prototype.render = function (appearance) {
        var snaps = [];
        var portGaps = 100;
        var portOffset = 80;
        var ports = +appearance.ports;
        var topPipeworks = pipeworks(80, ports, portGaps, portOffset, 10, true, true);
        tubify(topPipeworks, strokeWidth("default"));
        snaps.push({ type: "connectable", at: P(topPipeworks.bounds.left, 0), facing: -90 }, { type: "connectable", at: P(topPipeworks.bounds.right, 0), facing: 90 });
        var bottomPipeworks = pipeworks(40, ports, portGaps, portOffset, -10, true, false);
        tubify(bottomPipeworks, strokeWidth("default"));
        bottomPipeworks.position.y += 40;
        bottomPipeworks.position.x += 36;
        var graphic = new Group([topPipeworks, bottomPipeworks]);
        if (appearance.coldTrap) {
            var coldTrap = createColdTrap();
            coldTrap.position.x -= 100;
            coldTrap.position.y += 40;
            graphic.addChild(coldTrap);
            // Place at the end of the outlet tube. Compute this from the position of the cold
            // trap's outlet tube (see inside createColdTrap() and then add the position shift above.)
            snaps.push({ type: "connectable", at: P(-230, 40), facing: 180 });
        }
        else {
            snaps.push({ type: "connectable", at: P(bottomPipeworks.bounds.left, 40), facing: -90 });
        }
        // Add taps and outlets.
        for (var i = 0; i < ports; i++) {
            var tap = Tap.create({
                tapFacing: "front",
                tapOpening: appearance["tap" + (i + 1)],
            });
            tap.position.x = portOffset + i * portGaps + 18;
            tap.position.y = 78;
            var outlet = new Path(Segments([[0, 50]], [[0, 0]], [[8, 0]], [[8, 50]]));
            setDefaultStyle(outlet);
            outlet.position.x = tap.position.x;
            outlet.position.y = 115;
            graphic.addChild(outlet);
            graphic.addChild(tap);
            snaps.push({ type: "connectable", at: outlet.bounds.bottomCenter, facing: 180 });
        }
        // Manometer
        if (appearance.manometer) {
            var manometerTube = new Path.Line(P(MANOMETER_OFFSET, 31), P(MANOMETER_OFFSET, -30));
            manometerTube.strokeWidth = 8;
            var manometerGroup = new Group([manometerTube]);
            tubify(manometerGroup, strokeWidth("default"));
            var manometerGraphic = manometer(appearance.reading);
            manometerGraphic.position = P(MANOMETER_OFFSET, -45);
            graphic.addChildren([manometerGroup, manometerGraphic]);
        }
        return {
            graphic: graphic,
            hitShape: graphic.strokeBounds.toShape(),
            snapping: snaps,
        };
    };
    SchlenkLines.properties = {
        label: "Schlenk Lines",
        defaultAppearance: {
            reading: "0.001",
            manometer: true,
            coldTrap: true,
            ports: "4",
            tap1: 1.0, tap2: 1.0, tap3: 1.0, tap4: 1.0, tap5: 1.0,
        },
        appearanceSpecs: [
            {
                spec: "toggle-buttons", key: "ports", label: "Number of ports",
                size: "text",
                options: [
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                ]
            },
            {
                spec: "checkbox-group", label: "Show", checkboxes: [
                    { key: "coldTrap", label: "Cold Trap" },
                    { key: "manometer", label: "Manometer" }
                ]
            },
            {
                spec: "text", key: "reading", label: "Reading",
                maxLength: 5, visible: function (a) { return a.manometer; }
            },
            Tap.appearanceSpecFor("tap1", "Tap 1"),
            Tap.appearanceSpecFor("tap2", "Tap 2"),
            Tap.appearanceSpecFor("tap3", "Tap 3"),
            __assign(__assign({}, Tap.appearanceSpecFor("tap4", "Tap 4")), { visible: function (a) { return +a.ports >= 4; } }),
            __assign(__assign({}, Tap.appearanceSpecFor("tap5", "Tap 5")), { visible: function (a) { return +a.ports >= 5; } }),
        ],
    };
    return SchlenkLines;
}());
export { SchlenkLines };
function pipeworks(length, outlets, outletGaps, outletOffset, bend, leftEnd, rightEnd) {
    var end = (outlets - 1) * outletGaps + outletOffset * 2;
    var graphic = new Group();
    var topPipe = new Path.Line(P(0, 0), P(end, 0));
    topPipe.strokeCap = "round";
    topPipe.strokeWidth = 18;
    graphic.addChild(topPipe);
    for (var i = 0; i < outlets; i++) {
        var x = outletOffset + i * outletGaps;
        var outlet = new Path(Segments([[x, 0]], [[x, length - Math.abs(bend)]], [[x + bend, length], [-bend, 0]]));
        outlet.strokeWidth = 5;
        graphic.addChild(outlet);
    }
    if (leftEnd) {
        var end1 = new Path.Line(P(-20, 0), P(0, 0)).withStroke(8, "white");
        graphic.addChild(end1);
    }
    if (rightEnd) {
        var end2 = new Path.Line(P(end, 0), P(end + 20, 0)).withStroke(8, "white");
        graphic.addChild(end2);
    }
    return graphic;
}
/**
 * Creates tubes by adding for each child a new child.
 * The original child is turned into the outlines,
 * while the latter child is the empty inside.
 */
function tubify(group, thickness) {
    var count = group.children.length;
    for (var i = 0; i < count; i++) {
        var child = group.children[i];
        var clone = child.clone();
        child.strokeWidth = clone.strokeWidth + thickness * 2;
        child.strokeColor = SharedColors.stroke;
        clone.strokeColor = "white";
        group.addChild(clone);
    }
}
function manometer(reading) {
    var casing = Shape.Rectangle(R(0, 0, 60, 40, Pivot.TOP_CENTER), 4.0);
    casing
        .withGradientFill("right", Gradients.box(hsl(0, 0, 90)))
        .withStroke(strokeWidth("default"), hsl(0, 0, 30).string());
    var screen = Shape.Rectangle(R(0, 7, 46, 25, Pivot.TOP_CENTER), 2);
    screen.withStroke(strokeWidth("thin"), "#777777");
    var display = new PointText({
        point: P(0, 25),
        content: reading,
        fillColor: 'black',
        fontFamily: 'sans-serif',
        fontSize: 15,
        justification: "center",
    });
    return new Group([casing, screen, display]);
}
function createColdTrap() {
    var container = new Path(Segments([[120, 6]], [[20, 6]], [[20, 20], , [0, 20]], [[35, 60], [0, -20]], [[35, 220], , [0, 60]], [[-35, 220], [0, 60]], [[-35, 60], , [0, -20]], [[-20, 20], [0, 20]], [[-20, -20], , [0, -30]], [[20, -20], [0, -30]], [[20, -6]], [[120, -6]]));
    setDefaultStyle(container);
    var tubePath = new Path(Segments([[0, 180]], [[0, -90]], [[-130, -90]], [[-130, 0]]));
    smoothCorner(tubePath.segments[2], 30);
    smoothCorner(tubePath.segments[1], 30);
    tubePath.strokeWidth = 8;
    var outletTube = new Group([tubePath, new Path.Line(P(-65, -90), P(-65, -20)).withStroke(4, "black")]);
    tubify(outletTube, strokeWidth("default"));
    var tap = Tap.create({ tapOpening: 0.0, tapFacing: "front" });
    tap.position = P(-65, -65);
    return new Group([container, outletTube, tap]);
}
