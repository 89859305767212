import { P, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var Sun = /** @class */ (function () {
    function Sun() {
    }
    Sun.prototype.render = function (appearance) {
        var sun = Shape.Circle(P(0, 0), 70);
        sun.setRadialGradientFill([
            ["#face75", 0.5],
            ["#face75aa", 0.51],
            ["#face7500", 1.0]
        ]);
        var graphic = new Group([sun]);
        if (appearance.showRays) {
            var arrow = new Group([
                new Path.Line(P(50, 0), P(120, 0)),
                new Path(Segments([[115, -5]], [[120, 0]], [[115, 5]])),
            ]);
            arrow.withStroke(3.0, "#face75");
            var arrow1 = arrow.clone();
            arrow1.position.y += 20;
            var arrow2 = arrow.clone();
            arrow2.position.y -= 20;
            graphic.addChildren([arrow, arrow1, arrow2]);
        }
        graphic.scale(appearance.scale, P(0, 0));
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: P(0, 0)
        };
    };
    Sun.properties = {
        label: "Sun",
        defaultAppearance: {
            showRays: false,
            scale: 1.0,
        },
        appearanceSpecs: [
            { spec: "checkbox", key: "showRays", label: "Rays", subLabel: "Show" },
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.5, max: 1.5, step: 0.01, unit: "%",
            }
        ],
    };
    return Sun;
}());
export { Sun };
