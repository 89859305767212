var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { MoreShapes, strokeWidth } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
import { Group, Shape } from "paper";
export var lampAppearanceSpecs = [
    {
        spec: "color-wells", key: "color", label: "Colour",
        colors: [
            { value: "off", label: "Off", hex: "#111111" },
            { value: "yellow", label: "Yellow" },
            { value: "red", label: "Red" },
            { value: "green", label: "Green" },
            { value: "blue", label: "Blue" },
        ]
    },
    {
        spec: "toggle-buttons", size: "text", key: "glow", label: "Glow",
        options: [
            { value: "off", label: "None" },
            { value: "small", label: "Small" },
            { value: "large", label: "Large" },
        ],
        visible: function (a) { return a.color !== "off"; }
    }
];
var Lamp = /** @class */ (function () {
    function Lamp() {
    }
    Lamp.convertToLatest = function (input) {
        if ("light" in input) {
            switch (input.light) {
                case "off": return { color: "off", glow: "small", scale: 1.0 };
                case "noglow": return { color: "yellow", glow: "off", scale: 1.0 };
                case "small": return { color: "yellow", glow: "small", scale: 1.0 };
                case "large": return { color: "yellow", glow: "large", scale: 1.0 };
            }
        }
        return input;
    };
    Lamp.prototype.render = function (appearance) {
        var graphic = renderLightBulb(appearance);
        graphic.scale(appearance.scale);
        // The svg lamp is the first child
        var svgLamp = graphic.children[0];
        return { graphic: graphic, hitShape: svgLamp.bounds.toShape(), pivot: svgLamp.bounds.center };
    };
    Lamp.lampColors = function (color) {
        switch (color) {
            case "off": return ["#eeeeee", "#bbbbbb", "#ffffff" /* unused */];
            case "yellow": return ["#fce94f", "#c4a000", "#fff189"];
            case "red": return ["#ffb99e", "#fd3939", "#ff957a"];
            case "green": return ["#def779", "#97d435", "#aafc83"];
            case "blue": return ["#75ffff", "#3ea8e6", "#5192f5"];
        }
    };
    Lamp.properties = {
        label: "Bulb",
        defaultAppearance: {
            color: "yellow",
            glow: "small",
            scale: 1.0,
        },
        appearanceSpecs: __spreadArray([
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 2.0, step: 0.01, unit: "%",
            }
        ], lampAppearanceSpecs),
    };
    return Lamp;
}());
export { Lamp };
export function renderLightBulb(appearance, holder) {
    if (holder === void 0) { holder = "default"; }
    var lamp = SVG.bulb();
    lamp.scale(4.0);
    lamp.strokeWidth = strokeWidth("thin");
    var _a = Lamp.lampColors(appearance.color), fill = _a[0], stroke = _a[1], glowColor = _a[2];
    var graphic = new Group([lamp]);
    lamp.children[0].children[0].fillColor = fill;
    lamp.children[0].children[0].strokeColor = stroke;
    if (holder === "3d") {
        // Keep just the bulb bit.
        lamp.children[0].children[2].remove();
        lamp.children[0].children[1].remove();
        // New lamp holder
        var lampHolder = MoreShapes.cylinder3d_shell({ diameter: 20, height: 12 }).body;
        lampHolder
            .withGradientFill("right", Gradients.simple("#666666".color(), 10))
            .withStroke("thin")
            .withPositionDelta({ x: 6, y: 27 });
        graphic.addChild(lampHolder);
    }
    if (appearance.color !== "off" && appearance.glow != "off") {
        // Add glow.
        var radius = appearance.glow == "large" ? 150.0 : 50;
        var glow = Shape.Circle(P(5, -5), radius);
        glow.setRadialGradientFill([[glowColor + "b0", 0.2], [glowColor + "00", 1.0]]);
        graphic.addChild(glow);
    }
    return graphic;
}
