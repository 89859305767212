var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { CommonModal } from "components/common_modal";
import { ExponentLogo } from "components/logo";
import { MiniLanguagePicker, PromoStat, SplashImage } from "layout/modals/welcome";
import * as React from 'react';
import { useTranslation } from "react-i18next";
function loadTwitterWidgets() {
    // (window as any).twttr?.widgets?.load();
}
export var AboutModal2 = function (_a) {
    var version = _a.version, modalState = __rest(_a, ["version"]);
    var t = useTranslation().t;
    return React.createElement(CommonModal, __assign({}, modalState, { title: null, extraClass: "about-modal", onEnter: loadTwitterWidgets }),
        React.createElement(SplashImage, null),
        React.createElement("div", { className: "mx-3 mb-2" },
            React.createElement("div", { style: { marginTop: '-90px' } }),
            React.createElement("div", { style: { marginBottom: '16px', textAlign: "left" } },
                React.createElement(MiniLanguagePicker, null)),
            React.createElement("div", { className: "mb-4" },
                React.createElement(ExponentLogo, null)),
            React.createElement("p", null,
                t('about.part1'),
                " ",
                React.createElement("span", { className: "boost-text-gradient font-weight-bold" }, t('Chemistry')),
                ", ",
                React.createElement("span", { className: "school-text-gradient font-weight-bold" }, t('Biology')),
                " ",
                t('and'),
                " ",
                React.createElement("span", { className: "blue-text-gradient font-weight-bold" }, t('Physics')),
                ". ",
                t('about.part2')),
            React.createElement("p", null,
                React.createElement(PromoStat, null)),
            React.createElement("p", null, t('about.support')),
            React.createElement("p", null,
                React.createElement("a", { href: "https://twitter.com/ChemixLab?ref_src=twsrc%5Etfw", className: "custom-twitter-follow-button", target: "_blank" },
                    React.createElement("i", { className: "fab fa-twitter mr-1" }),
                    "Follow @ChemixLab")),
            React.createElement("p", { className: "text-muted" },
                t('about.developed_by'),
                " ",
                React.createElement("a", { className: "text-muted", href: "https://codelite.org.uk" }, "Codelite Ltd, UK"),
                ". \u00A9 2007-",
                new Date().getFullYear(),
                ". ",
                t('about.version'),
                " ",
                version)));
};
