import { SVG } from "apparatus/svg";
var GHSHazardSymbol = /** @class */ (function () {
    function GHSHazardSymbol() {
    }
    GHSHazardSymbol.prototype.render = function (appearance) {
        var graphic = SVG.GHS[appearance.hazard]();
        graphic.scale(0.2 * appearance.scale);
        return { graphic: graphic, hitShape: graphic.bounds.toShape() };
    };
    GHSHazardSymbol.properties = {
        label: "GHS Hazard Symbol",
        defaultAppearance: {
            hazard: "flammable",
            scale: 1.0,
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "hazard", label: "Hazard",
                options: [
                    { value: "explosion", label: "Explosive" },
                    { value: "flammable", label: "Flammable" },
                    { value: "oxidising", label: "Oxidising" },
                    { value: "gas", label: "Compressed Gas" },
                    { value: "acid", label: "Corrosive" },
                    { value: "toxic", label: "Toxic" },
                    { value: "harm", label: "Harmful" },
                    { value: "health", label: "Health Hazard" },
                    { value: "pollution", label: "Environmental" },
                ],
            },
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.25, max: 3.0, step: 0.01, unit: "%",
                tier: "boost",
            }
        ],
    };
    return GHSHazardSymbol;
}());
export { GHSHazardSymbol };
