import { g } from "analytics";
import { useLoginModal, useSharedModals } from "layout/modals/shared_modals";
import * as React from "react";
import { useTranslation } from "react-i18next";
export var AccountBar = function (_a) {
    var auth = _a.auth;
    var openLogin = useLoginModal().openLogin;
    var openMyAccount = useSharedModals().openMyAccount;
    var t = useTranslation().t;
    return React.createElement("div", null,
        React.createElement("div", { key: "accountBar", className: "accountBar" }, auth.state == "logged_in" ?
            React.createElement("div", { key: "loggedIn", className: "menuBarItem", onClick: function () { g("MyAccount"); openMyAccount(); } },
                React.createElement("img", { src: auth.user.avatarUrl, width: "32", height: "32" }),
                " ",
                t("My account")) :
            React.createElement("div", { key: "loggedOut", className: "menuBarItem", onClick: function () { g("LoginPopup"); openLogin(); } },
                React.createElement("i", { className: "fal fa-2x mb-1 mt-1 fa-square-user" }),
                React.createElement("br", null),
                t('login.sign_in'))));
};
