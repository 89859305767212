import { breakPathClosed, glassFold, mirrorX, setDefaultStyle } from "apparatus/draw";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Path } from "paper";
var CowType = /** @class */ (function () {
    function CowType() {
    }
    CowType.prototype.render = function (appearance) {
        var angle = 35;
        var bulb = new Path.Circle(P(0, 10), 40);
        var inlet = new Path(Segments([[11, -60]], [[9, 0]]));
        mirrorX(inlet);
        var outlet = new Path(Segments([[13, 0]], [[7, 80]]));
        mirrorX(outlet);
        var hoseOutlet = new Path.Rectangle(R(0, 0, 6, 60, Pivot.TOP_CENTER));
        hoseOutlet.rotate(-75, P(0, 10));
        var outletL = outlet.clone();
        outletL.rotate(angle, P(0, 0));
        var outletR = outlet.clone();
        outletR.rotate(-angle, P(0, 0));
        var path = bulb.unite(outlet).unite(outletL).unite(outletR).unite(inlet).unite(hoseOutlet);
        var graphic = breakPathClosed(path, 3, 8, 12, 16, 20);
        setDefaultStyle(graphic);
        graphic.addChild(glassFold(0, -24));
        graphic.addChild(glassFold(0, 44, { rotation: 180 }));
        graphic.addChild(glassFold(24, 35, { rotation: 180 - angle }));
        graphic.addChild(glassFold(-24, 35, { rotation: 180 + angle }));
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: [
                { type: "connectable", at: P(0, -60), facing: 0 },
                { type: "connectable", at: P(0, 80), facing: 180 },
                { type: "connectable", at: P(0, 80).rotate(-angle), facing: 180 - angle },
                { type: "connectable", at: P(0, 80).rotate(angle), facing: 180 + angle },
            ]
        };
    };
    CowType.properties = {
        label: "Cow-type Distillation Receiver",
        flippable: true,
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return CowType;
}());
export { CowType };
