var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createOutlinedGroup } from "apparatus/draw";
import { interpolate, P, Pivot, R, Segments } from "apparatus/library/common";
import { Path } from "paper";
import { staticImplements } from "types";
var SaltBridge = /** @class */ (function () {
    function SaltBridge() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 25, maxX: 300,
                minY: 80, maxY: 400,
                fromAppearance: function (a) { return P(a.length / 2, a.height); },
                toAppearance: function (f, p) { return f({ length: p.x * 2, height: p.y }); },
                scale: P(1, 2),
            }
        ];
    }
    SaltBridge.prototype.render = function (appearance) {
        // Draw this in three parts:
        // - middle bar (horizontal)
        // - turn (left and right)
        // - vertical bar (left and right)
        // The length and height refers to total length and height if the diamter = 0.
        // Origin is at the center of the middle bar
        var length = appearance.length, height = appearance.height, diameter = appearance.diameter;
        var turnRadius = Math.min(40, length / 2);
        var midbarLength = length - turnRadius * 2;
        var vertbarLength = height - turnRadius;
        var gradient = [["#e7ecf6", 0.0], ["#c9cfd8", 0.5], ["#e0e3ee", 1.0]];
        var leftVertbar = new Path.Rectangle(R(-length / 2, height, diameter, vertbarLength + 2, Pivot.BOTTOM_CENTER))
            .withGradientFill("right", gradient);
        // Start at top right corner and go clockwise
        var outerRadius = turnRadius + diameter / 2;
        var innerRadius = turnRadius - diameter / 2;
        var leftTurn = new Path(Segments([[-length / 2 + turnRadius, -diameter / 2], [-outerRadius * 0.6, 0]], [[-length / 2 + turnRadius, diameter / 2], , [-innerRadius * 0.6, 0]], [[-length / 2 + diameter / 2, turnRadius], [0, -innerRadius * 0.6]], [[-length / 2 - diameter / 2, turnRadius], , [0, -outerRadius * 0.6]])).close();
        // Remap the gradient, so that 0 starts at the outer radius and 1.0 ends at the smaller radius.
        var turnGradient = gradient.map(function (_a) {
            var c = _a[0], p = _a[1];
            return [c, interpolate(p, [0, 1], [1.0, innerRadius / outerRadius])];
        });
        turnGradient.reverse();
        leftTurn.setRadialGradientFill(turnGradient, {
            center: P(-length / 2 + turnRadius, turnRadius),
            edge: P(-length / 2 + turnRadius, -diameter / 2)
        });
        var midbar = new Path.Rectangle(R(0, 0, midbarLength + 2, diameter, Pivot.CENTER))
            .withGradientFill("down", gradient);
        // Create the right hand side via cloning.
        var rightTurn = leftTurn.clone();
        rightTurn.scale(-1, 1, P(0, 0));
        var rightVertbar = leftVertbar.clone();
        rightVertbar.scale(-1, 1, P(0, 0));
        var graphic = createOutlinedGroup("default", "#a0b0bc", leftTurn, rightTurn, leftVertbar, rightVertbar, midbar);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape()
        };
    };
    SaltBridge.properties = {
        label: "Salt bridge",
        defaultAppearance: {
            length: 140,
            height: 100,
            diameter: 18,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "length", label: "Length",
                min: 50, max: 600,
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 60, max: 400,
            },
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 5, max: 30,
            }
        ],
    };
    SaltBridge = __decorate([
        staticImplements()
    ], SaltBridge);
    return SaltBridge;
}());
export { SaltBridge };
