import { Consts } from "vars";
/** Is Prod and meets minimum version. */
export var isProdFrom = function (version) { return Consts.isProd && isAtLeastVersion(version); };
/** Is Prod and meets minimum version, or is Dev. */
export var isFrom = function (version, extra) { return Consts.isDev || isProdFrom(version) || ((extra === null || extra === void 0 ? void 0 : extra.beta) == true && Consts.isBeta); };
var Flags;
(function (Flags) {
    /** Enable Physics v2 */
    Flags.isElectricalSymbolsEnabled = Consts.isDev;
    /** Labelling */
    Flags.isLabellingEnabled = Consts.isDev;
    Flags.isShapeSnappingEnabled = Consts.isDev;
    /// New pricing.
    Flags.isNewPricingEnabled = true;
    Flags.isNewPricingEnabledForSchools = true;
    /// Features on ice.
    Flags.isNewTemplatesEnabled = Consts.isDev || Consts.isBeta;
    /** Freehand tool. */
    Flags.isFreeHandToolEnabled = Consts.isDev;
    Flags.isStaffOnlyPricingEnabled = false;
})(Flags || (Flags = {}));
/** Returns true if the current version is at least this version. */
function isAtLeastVersion(minVersion) {
    var current = parseVersion(Consts.version);
    var min = parseVersion(minVersion);
    return current[0] > min[0] || (current[0] == min[0] && current[1] >= min[1]);
}
/** Parses verion as major.minor. */
function parseVersion(versionAsString) {
    var matches = versionAsString.match("([0-9]*)\.([0-9]*)");
    return [+matches[1], +matches[2]];
}
export function currentDateBetween(after, before) {
    var now = new Date().getTime();
    var afterDate = Date.parse(after);
    var beforeDate = Date.parse(before);
    return now > afterDate && now < beforeDate;
}
// @ts-ignore unused
function currentDateAfter(date) {
    var now = new Date().getTime();
    return now > Date.parse(date);
}
export default Flags;
