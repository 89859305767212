var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { breakPath, uniteAll } from "apparatus/draw";
import { LIQUID_OPACITY_RANGE, Pivot, R, RO, Segments, SharedColors } from "apparatus/library/common";
import { DEFAULT_SWATCHES } from "components/colorpicker";
import { Group, Path } from "paper";
import { staticImplements } from "types";
/**
 * https://www.mechanicalbooster.com/2016/06/venturimeter-principle-construction-working-diagram.html
 */
var VenturiTube = /** @class */ (function () {
    function VenturiTube() {
    }
    VenturiTube.prototype.render = function (a) {
        // Origin is at the center of the throat.
        var convergingLength = a.convergingLength, divergingLength = a.divergingLength, pipeDiameter = a.pipeDiameter, throatDiameter = a.throatDiameter, inletDiameter = a.inletDiameter, inletLength = a.inletLength;
        var throatLength = 20;
        var pipeLength = 50;
        // inletLength = length for the center inlet tube.
        var liquidColor = a.liquidColor.color().alpha(a.liquidOpacity).toString();
        var mainTube = new Path(Segments(
        // Pipe
        [[-throatLength / 2 - convergingLength - pipeLength, pipeDiameter / 2]], [[-throatLength / 2 - convergingLength, pipeDiameter / 2]], 
        // Convergence
        [[-throatLength / 2, throatDiameter / 2]], 
        // Throat
        [[throatLength / 2, throatDiameter / 2]], 
        // Divergence
        [[throatLength / 2 + divergingLength, pipeDiameter / 2]], 
        // End Pipe
        [[throatLength / 2 + divergingLength + pipeLength, pipeDiameter / 2]])).mirrorY();
        var center = mainTube.bounds.center;
        var tubeLiquid = mainTube.clone().withFill(liquidColor);
        var graphic = new Group([]);
        if (a.mode == "normal") {
            // Normal mode.
            var outlineParts = breakPath(mainTube, 6);
            outlineParts.withStroke("default");
            graphic.addChildren([tubeLiquid, outlineParts]);
        }
        else {
            var inlet2 = new Path.Rectangle(R(0, -throatDiameter / 2, inletDiameter, inletLength, Pivot.BOTTOM_CENTER));
            var inlet1Length = inletLength - (pipeDiameter - throatDiameter) / 2;
            var inlet1X = -throatLength / 2 - convergingLength - 20;
            var inlet1 = new Path.Rectangle(R(inlet1X, -pipeDiameter / 2, inletDiameter, inlet1Length, Pivot.BOTTOM_CENTER));
            var outline = uniteAll(mainTube, inlet1, inlet2);
            var outlineParts = breakPath(outline, 0, 3, 9, 14);
            outlineParts.withStroke("default");
            var inlet2Liquid = new Path.Rectangle(RO(inlet2.bounds.bottomCenter, inletDiameter, inletLength * a.inlet2AmountRatio, Pivot.BOTTOM_CENTER));
            var inlet1Liquid = new Path.Rectangle(RO(inlet1.bounds.bottomCenter, inletDiameter, inlet1Length * a.inlet1AmountRatio, Pivot.BOTTOM_CENTER));
            if (a.mode == "2 tubes" && a.separateInletLiquidColor) {
                var liquidColor_1 = a.inletLiquidColor.color().alpha(a.inletLiquidOpacity).toString();
                inlet1Liquid.fillColor = liquidColor_1;
                inlet2Liquid.fillColor = liquidColor_1;
            }
            else {
                inlet1Liquid.fillColor = liquidColor;
                inlet2Liquid.fillColor = liquidColor;
            }
            graphic.addChildren([tubeLiquid, inlet1Liquid, inlet2Liquid]);
            if (a.mode == "u-tube") {
                // Flip vertical.
                graphic.scale(1, -1, center);
                // Add a U-shape
                var ROUND_FACTOR = 0.66;
                var tubeEnd = inletLength + throatDiameter / 2;
                var radius = Math.abs(inlet1X);
                var innerU = new Path(Segments([[-inletDiameter / 2, tubeEnd], , [0, (radius - inletDiameter) * ROUND_FACTOR]], [[inlet1X + inletDiameter / 2, tubeEnd], [0, (radius - inletDiameter) * ROUND_FACTOR]])).withStroke("default");
                var outerU = new Path(Segments([[inletDiameter / 2, tubeEnd], , [0, (radius + inletDiameter) * ROUND_FACTOR]], [[inlet1X - inletDiameter / 2, tubeEnd], [0, (radius + inletDiameter) * ROUND_FACTOR]])).withStroke("default");
                var liquidColor_2 = a.uTubeLiquidColor.color().alpha(a.uTubeLiquidOpacity).toString();
                var liquid = new Path(Segments([inlet2Liquid.bounds.bottomCenter], [[0, tubeEnd], , [0, radius * ROUND_FACTOR]], [[inlet1X, tubeEnd], [0, radius * ROUND_FACTOR]], [inlet1Liquid.bounds.bottomCenter])).withStroke(inletDiameter * a.magnify, liquidColor_2);
                // Add outline parts last so that it covers up the liquid.
                outlineParts.scale(1, -1, center);
                graphic.addChildren([liquid, innerU, outerU, outlineParts]);
            }
            else {
                // Add liquid level strokes.
                var liquidStroke = a[!a.separateInletLiquidColor ? "liquidColor" : "inletLiquidColor"];
                var liquidLevel1 = new Path([inlet1Liquid.bounds.topLeft, inlet1Liquid.bounds.topRight])
                    .withStroke("liquid", liquidStroke);
                var liquidLevel2 = new Path([inlet2Liquid.bounds.topLeft, inlet2Liquid.bounds.topRight])
                    .withStroke("liquid", liquidStroke);
                graphic.addChildren([liquidLevel1, liquidLevel2, outlineParts]);
            }
        }
        graphic.scale(a.magnify, center);
        return {
            graphic: graphic,
            pivot: center,
            hitShape: graphic.rectHitShape(),
            watermark: center,
        };
    };
    VenturiTube.properties = {
        label: "Venturi Tube",
        defaultAppearance: {
            magnify: 1,
            mode: "2 tubes",
            convergingLength: 25,
            divergingLength: 40,
            pipeDiameter: 40,
            throatDiameter: 20,
            inletDiameter: 10,
            inletLength: 80,
            liquidColor: SharedColors.defaultLiquid,
            liquidOpacity: SharedColors.defaultLiquidAlpha,
            inlet1AmountRatio: 0.3,
            inlet2AmountRatio: 0.5,
            separateInletLiquidColor: false,
            inletLiquidColor: "#a850f7",
            inletLiquidOpacity: SharedColors.defaultLiquidAlpha,
            uTubeLiquidColor: "#f75e5e",
            uTubeLiquidOpacity: 0.66,
        },
        appearanceSpecs: [
            {
                spec: "toggle-buttons", key: "magnify", label: "Magnify", size: "text",
                options: [
                    { value: 1, label: "1x" },
                    { value: 2, label: "2x" },
                    { value: 3, label: "3x" },
                ],
            },
            {
                spec: "radio", key: "mode", label: "Type", options: [
                    { value: "normal", label: "Normal" },
                    { value: "2 tubes", label: "With measuring tubes" },
                    { value: "u-tube", label: "With U-shaped tube" },
                ]
            },
            {
                spec: "slider", key: "convergingLength", label: "Converging length",
                min: 15, max: 40, unit: "mm",
            },
            {
                spec: "slider", key: "divergingLength", label: "Diverging length",
                min: 30, max: 80, unit: "mm",
            },
            {
                spec: "slider", key: "pipeDiameter", label: "Pipe diameter",
                min: 25, max: 60, unit: "mm",
            },
            {
                spec: "slider", key: "throatDiameter", label: "Throat diameter",
                min: 8, max: 30, unit: "mm",
            },
            {
                spec: "slider", key: "inletDiameter", label: "Inlet diameter",
                min: 5, max: 12, unit: "mm", visible: function (a) { return a.mode !== "normal"; },
            },
            {
                spec: "slider", key: "inletLength", label: "Inlet length",
                min: 30, max: 100, unit: "mm", visible: function (a) { return a.mode !== "normal"; },
            },
            { spec: "header", header: "Liquids", icon: "tint" },
            { spec: "checkbox", label: "Inlets", subLabel: "Separate liquid colour", key: "separateInletLiquidColor", visible: function (a) { return a.mode === "2 tubes"; } },
            {
                spec: "color", key: "liquidColor", label: "Liquid colour",
                swatches: DEFAULT_SWATCHES,
                opacity: { key: "liquidOpacity", range: LIQUID_OPACITY_RANGE }
            },
            {
                spec: "color", key: "inletLiquidColor", label: "Liquid colour #2",
                swatches: DEFAULT_SWATCHES,
                opacity: { key: "inletLiquidOpacity", range: LIQUID_OPACITY_RANGE },
                visible: function (a) { return a.mode == "2 tubes" && a.separateInletLiquidColor; }
            },
            {
                spec: "color", key: "uTubeLiquidColor", label: "U-tube liquid",
                swatches: DEFAULT_SWATCHES,
                opacity: { key: "uTubeLiquidOpacity", range: LIQUID_OPACITY_RANGE },
                visible: function (a) { return a.mode == "u-tube"; },
            },
            {
                spec: "slider", key: "inlet1AmountRatio", label: "Tube 1 Level",
                min: 0.0, max: 1.0, step: 0.05, visible: function (a) { return a.mode == "2 tubes" || a.mode == "u-tube"; },
            },
            {
                spec: "slider", key: "inlet2AmountRatio", label: "Tube 2 Level",
                min: 0.0, max: 1.0, step: 0.05, visible: function (a) { return a.mode == "2 tubes" || a.mode == "u-tube"; },
            },
        ],
    };
    VenturiTube = __decorate([
        staticImplements()
    ], VenturiTube);
    return VenturiTube;
}());
export { VenturiTube };
