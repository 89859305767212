var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { g } from "analytics";
import { DEMO_COLOUR_SWATCHES, FONT_FAMILY_OPTIONS, TEXT_COLOUR_SWATCHES } from "apparatus/text/appearance";
import { UpdateArrowProperties } from "arrows/command";
import { commandProcessor } from "command/command";
import { SENTINEL_INDETERMINATE_RADIO, SENTINEL_TEXT_PLACEHOLDER } from "panel/appearance_spec_converter";
import { sectionsForMultipleItems } from "panel/propertyPanel";
import { convertDemoSwatchesToRadioOptions } from "panel/shared";
import * as React from "react";
import { Articles } from "support/beacon";
import { ChemMark } from "text/parse";
import { createChemMarkGlobalConfigElements, PropertyPanelHint } from "text/ui";
var MIN_FONT_SIZE = 8;
var MAX_FONT_SIZE = 25;
function createUpdateCommand(arrows, key, newValue) {
    return new UpdateArrowProperties(arrows.map(function (a) {
        var _a, _b;
        return ({ id: a.id, value: (_a = {}, _a[key] = newValue, _a), previousValue: (_b = {}, _b[key] = a[key], _b) });
    }));
}
function getConsistentValue(arrows, key) {
    var value = arrows[0][key];
    if (arrows.length == 1)
        return value;
    for (var _i = 0, arrows_1 = arrows; _i < arrows_1.length; _i++) {
        var arrow = arrows_1[_i];
        if (arrow[key] !== value)
            return;
    }
    return value;
}
/** Creates Panel UI for arrows. */
export function arrowPanelUI(selected, arrows, app, tiers) {
    var _a, _b;
    // TODO: Fix this casting.
    var getComponents = function () { return arrows.map(function (a) { return app.getObjectComponent(a.id); }); };
    var styleElements = [
        {
            spec: "slider",
            key: "arrow_font",
            label: "Text size",
            min: MIN_FONT_SIZE,
            max: MAX_FONT_SIZE,
            initialValue: arrows[0].fontSize,
            onStart: function () { return app.setIsInteracting(true); },
            onChange: function (value) {
                getComponents().forEach(function (c) { return c === null || c === void 0 ? void 0 : c.setState({ fontSize: value }); });
            },
            onEnd: function (_, final) {
                app.setIsInteracting(false);
                app.setArrowTemplate({ fontSize: final });
                commandProcessor.execute(createUpdateCommand(arrows, "fontSize", final));
            }
        },
        {
            spec: "select",
            key: "fontFace",
            label: "Font",
            value: arrows[0].fontFamily,
            options: FONT_FAMILY_OPTIONS,
            onSelectChange: function (_, value) {
                app.setArrowTemplate({ fontFamily: value });
                commandProcessor.execute(createUpdateCommand(arrows, "fontFamily", value));
            },
            id: "fontFace",
        },
        tiers.allow("boost") ?
            {
                spec: "color",
                key: "color",
                label: "Colour",
                value: arrows[0].textColor,
                swatches: TEXT_COLOUR_SWATCHES,
                onColorChange: function (value) {
                    getComponents().forEach(function (c) { return c.setState({ textColor: value }); });
                },
                onColorEnd: function (finalValue) {
                    if (finalValue == arrows[0].textColor)
                        return;
                    commandProcessor.execute(createUpdateCommand(arrows, "textColor", finalValue));
                },
            } : {
            spec: "radio",
            id: "labelColor",
            key: "color-radio",
            label: "Colour",
            options: convertDemoSwatchesToRadioOptions(DEMO_COLOUR_SWATCHES),
            value: (_a = getConsistentValue(arrows, "textColor")) !== null && _a !== void 0 ? _a : SENTINEL_INDETERMINATE_RADIO,
            onChange: function (previous, final) {
                commandProcessor.execute(createUpdateCommand(arrows, "textColor", final));
            }
        },
        textAlignToggleButtons(arrows),
    ];
    var consistentShowArrow = getConsistentValue(arrows, "showArrow");
    var consistentEndStyle = getConsistentValue(arrows, "endStyle");
    var arrowSection = [];
    if (consistentShowArrow) {
        arrowSection.push({
            spec: "checkbox-group",
            label: "Options",
            key: "arrow-options",
            checkboxes: [
                {
                    label: "Show head",
                    value: consistentEndStyle !== undefined ? consistentEndStyle == "triangle" : undefined,
                    id: "show_arrow_head",
                    onCheckedChange: function (value) {
                        g("UpdateAppearance", "Arrow::endStyle");
                        var endStyle = value ? "triangle" : "none";
                        commandProcessor.execute(createUpdateCommand(arrows, "endStyle", endStyle));
                        // Update the arrow so that next arrow drawn uses this style.
                        app.setArrowTemplate({ endStyle: endStyle });
                    },
                },
            ],
        }, {
            spec: "slider",
            label: "Thickness",
            key: "arrowThickness",
            min: 1.5, max: 6.0, step: 0.5,
            initialValue: arrows[0].arrowThickness,
            tier: "boost",
            onChange: function (value) {
                getComponents().forEach(function (c) { return c === null || c === void 0 ? void 0 : c.setState({ arrowThickness: value }); });
            },
            onEnd: function (_, final) {
                commandProcessor.execute(createUpdateCommand(arrows, "arrowThickness", final));
                // Update template so next arrow uses this thickness.
                app.setArrowTemplate({ arrowThickness: final });
            }
        });
    }
    var currentLabel = getConsistentValue(arrows, "label");
    var isChemMarkEnabled = getConsistentValue(arrows, "isChemMarkEnabled");
    return {
        title: "Label",
        icon: "font",
        tabs: __spreadArray([
            {
                type: "text",
                sections: [
                    {
                        header: "Label",
                        icon: "font",
                        hideHeaderIfFirst: true,
                        elements: [
                            {
                                spec: "textarea",
                                rows: 3,
                                label: "Text",
                                key: "arrow_text",
                                text: currentLabel !== null && currentLabel !== void 0 ? currentLabel : "",
                                placeholder: SENTINEL_TEXT_PLACEHOLDER.if(currentLabel === undefined),
                                enableReplacements: true,
                                onFocus: function () { app.setIsEditingText(true); },
                                onChange: function (value) {
                                    getComponents().forEach(function (c) { return c === null || c === void 0 ? void 0 : c.setState({ label: value }); });
                                },
                                onEnd: function (previous, final) {
                                    app.setIsEditingText(false);
                                    if (previous == final)
                                        return;
                                    g("UpdateAppearance", "Arrow::text");
                                    commandProcessor.execute(createUpdateCommand(arrows, "label", final));
                                },
                            },
                        ],
                    },
                    {
                        header: "ChemText",
                        icon: ChemMark.faIconName,
                        helpArticleId: Articles.CHEMTEXT,
                        elements: __spreadArray([
                            {
                                spec: "checkbox-group",
                                key: "is_chem_mark_enabled",
                                label: ChemMark.uiDescription(),
                                wideLabel: true,
                                checkboxes: [{
                                        label: "",
                                        useSwitch: true,
                                        id: "is_chem_mark_enabled",
                                        value: isChemMarkEnabled,
                                        tier: "boost",
                                        allowWhenLocked: true,
                                        onCheckedChange: function (value) {
                                            g("UpdateAppearance", "Arrow::ChemMark");
                                            commandProcessor.execute(createUpdateCommand(arrows, "isChemMarkEnabled", value));
                                        }
                                    }]
                            },
                            React.createElement(PropertyPanelHint, { key: "chemmark_hint", showUpgradeHint: (_b = getConsistentValue(arrows, "isChemMarkEnabled")) !== null && _b !== void 0 ? _b : false })
                        ], createChemMarkGlobalConfigElements(isChemMarkEnabled !== null && isChemMarkEnabled !== void 0 ? isChemMarkEnabled : false))
                    }
                ]
            },
            {
                type: "text-style",
                colorDot: getConsistentValue(arrows, "textColor"),
                sections: [
                    {
                        header: "Style",
                        icon: "font",
                        elements: styleElements
                    },
                    {
                        header: "Arrow",
                        icon: "long-arrow-alt-right",
                        toggle: {
                            value: consistentShowArrow, id: "show-arrow",
                            onCheckedChange: function (value) {
                                g("UpdateAppearance", "Arrow::showArrow");
                                commandProcessor.execute(createUpdateCommand(arrows, "showArrow", value));
                            }
                        },
                        elements: arrowSection,
                    },
                ]
            }
        ], (arrows.length > 1 ? sectionsForMultipleItems(selected, app).tabs : []))
    };
}
var TEXT_ALIGN_OPTIONS = ["left", "center", "right"];
function textAlignToggleButtons(arrows) {
    var consistentValue = getConsistentValue(arrows, "textAlign");
    var currentIndex = consistentValue ? TEXT_ALIGN_OPTIONS.indexOf(consistentValue) : -1;
    return {
        spec: "toggle-horizontal-buttons",
        highlightIndex: currentIndex,
        key: "textAlign",
        label: "Text Align",
        size: "icon",
        tier: "boost",
        buttons: TEXT_ALIGN_OPTIONS.map(function (align) {
            return {
                buttonLabel: align.capitalise(),
                icon: "align-" + align,
                onClick: function () {
                    commandProcessor.execute(createUpdateCommand(arrows, "textAlign", align));
                }
            };
        })
    };
}
