var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R } from "apparatus/library/common";
import { Mounted } from "apparatus/library/physics/electric/mounted/utils";
import { Group, Path } from "paper";
/**
 * https://www.researchgate.net/figure/View-of-the-components-that-form-the-electric-motor-under-analysis_fig1_228893825
 */
var Motor = /** @class */ (function () {
    function Motor() {
    }
    Motor.prototype.render = function (appearance) {
        var _a = Mounted.renderPlate({ appearance: appearance, width: 80, height: 50 }), base = _a.base, jacks = _a.jacks, snaps = _a.snaps;
        var motorBody = new Path.Rectangle(R(0, 0, 55, 32, Pivot.CENTER_RIGHT), 2.0)
            .withGradientFill("down", Gradients.cylinder("#444444".color()))
            .withStroke("default");
        var shaft = new Path.Rectangle(R(0, 0, 40, 4, Pivot.CENTER_LEFT), 2.0)
            .withGradientFill("down", Gradients.simple("#aaaaaa".color()))
            .withStroke("thinner");
        var gear = new Path.Rectangle(R(0, 0, 8, 10, Pivot.CENTER_LEFT), 2.0)
            .withGradientFill("down", Gradients.simple("#d0d0d0".color()))
            .withStroke("thinner");
        var leg1 = new Path.Rectangle(R(-5, 16, 12, 8, Pivot.CENTER_RIGHT), 2.0)
            .withGradientFill("up", Gradients.simple("#444444".color(), 10))
            .withStroke("thinner");
        var leg2 = new Path.Rectangle(R(-50, 16, 12, 8, Pivot.CENTER_LEFT), 2.0)
            .withGradientFill("up", Gradients.simple("#444444".color(), 10))
            .withStroke("thinner");
        var motor = new Group([shaft, gear, motorBody, leg1, leg2]);
        motor.pivot = P(0, 0);
        motor.withPosition({ x: 76, y: 10 });
        var graphic = new Group([base, jacks, motor]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: base.bounds.center,
            snapping: __spreadArray([], snaps)
        };
    };
    Motor.properties = {
        label: "Bulb",
        flippable: true,
        defaultAppearance: __assign({}, Mounted.defaultAppearance),
        appearanceSpecs: __spreadArray([], Mounted.appearanceSpecs),
    };
    return Motor;
}());
export { Motor };
