var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { focusMainApp, Keys } from "keyboard";
import * as React from "react";
import { Tip } from "view/tooltips";
export var SEARCH_BOX_ID = "search-box-input";
var SearchBox = /** @class */ (function (_super) {
    __extends(SearchBox, _super);
    function SearchBox() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.inputRef = React.createRef();
        _this.state = { value: "", focused: false };
        _this.onChange = function (e) {
            var value = e.target.value;
            _this.setState({ value: value });
            if (_this.props.throttleMs) {
                // Reset throttle timer.
                if (_this.interval)
                    window.clearTimeout(_this.interval);
                _this.interval = window.setTimeout(function () {
                    _this.props.onChange(value);
                }, _this.props.throttleMs);
            }
            else {
                _this.props.onChange(value);
            }
        };
        _this.isActive = function () { return _this.state.value.length > 0; };
        _this.render = function () {
            var _a;
            return React.createElement("div", { className: "search-box-container " + "active".if(_this.isActive()) + " " + "focused".if(_this.state.focused) + " " + ((_a = _this.props.className) !== null && _a !== void 0 ? _a : ""), key: "box" },
                React.createElement("i", { className: "fa " + (_this.isActive() ? "fa-backspace" : "fa-search"), key: "icon", onClick: function (_) {
                        if (_this.isActive()) {
                            // Erase field.
                            _this.setState({ value: "" });
                            _this.props.onChange("");
                        }
                        _this.inputRef.current.focus();
                    } }),
                React.createElement(Tip, { content: _this.props.tooltip },
                    React.createElement("input", { key: "input", id: SEARCH_BOX_ID, ref: _this.inputRef, value: _this.state.value, onFocus: function (_) { return _this.setState({ focused: true }); }, onChange: _this.onChange, onBlur: function (e) { var _a; _this.setState({ focused: false }); (_a = _this.props.onBlur) === null || _a === void 0 ? void 0 : _a.call(null, e.target.value); }, onKeyUp: function (e) { if (e.keyCode == Keys.ESCAPE)
                            focusMainApp(); }, placeholder: _this.props.placeholder })));
        };
        return _this;
    }
    SearchBox.getDerivedStateFromProps = function (nextProps, state) {
        if (!state.focused) {
            return __assign(__assign({}, state), { value: nextProps.value });
        }
        return state;
    };
    return SearchBox;
}(React.Component));
export { SearchBox };
