// Contains definitions and APIs used throughout the app
export function metricsEqual(a, b) {
    return a.pivot.x == b.pivot.x &&
        a.pivot.y == b.pivot.y &&
        a.bounds.x == b.bounds.x &&
        a.bounds.y == b.bounds.y &&
        a.bounds.width == b.bounds.width &&
        a.bounds.height == b.bounds.height;
}
export function convertFromPaperToHTMLCoordinates(metrics) {
    var offset = paper.project.view.bounds.topLeft;
    var zoom = paper.view.zoom;
    return {
        pivot: {
            x: (metrics.pivot.x - offset.x) * zoom,
            y: (metrics.pivot.y - offset.y) * zoom,
        },
        bounds: {
            x: (metrics.bounds.x - offset.x) * zoom,
            y: (metrics.bounds.y - offset.y) * zoom,
            width: metrics.bounds.width * zoom,
            height: metrics.bounds.height * zoom,
        },
    };
}
export var NULL_SELECTION_METRICS = {
    pivot: { x: 0, y: 0 },
    bounds: { x: 0, y: 0, width: 0, height: 0 },
};
// Helper methods to work with position.
export function add(p1, p2) {
    return { x: p1.x + p2.x, y: p1.y + p2.y };
}
export function staticImplements() {
    return function (constructor) { constructor; };
}
