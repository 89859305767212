import { Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { Gradients } from "../../gradients";
var Pencil = /** @class */ (function () {
    function Pencil() {
    }
    Pencil.prototype.render = function (appearance) {
        var length = appearance.length, scale = appearance.scale;
        var body = new Path(Segments([[0, -5]], [[length, -5]], [[length + 24, 0]], [[length, 5]], [[0, 5]])).close();
        var outline = body.clone();
        outline.withStroke("thinner");
        body.withGradientFill("down", [["#fad368", 0.33], ["#f9c531", 0.34], ["#f9c531", 0.66], ["#e3b134", 0.67]]);
        var tip = new Path(Segments([[length, -5]], [[length + 24, 0]], [[length, 5]])).withFill("#e39763");
        var carbon = new Path(Segments([[length + 12, -2.5]], [[length + 24, 0]], [[length + 12, 2.5]])).withFill("#222222");
        var graphic = new Group([body, tip, carbon]);
        if (appearance.eraser) {
            var metal = new Path.Rectangle(R(8, 0, 5, 10, Pivot.CENTER_LEFT))
                .withGradientFill("down", Gradients.simple("#aaaaaa".color(), 20));
            var eraser = new Path.Rectangle(R(0, 0, 10, 10, Pivot.CENTER_LEFT))
                .withGradientFill("down", Gradients.simple("#d8706f".color(), 20));
            graphic.addChild(eraser);
            graphic.addChild(metal);
        }
        graphic.addChild(outline);
        graphic.scale(scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(10).toShape()
        };
    };
    Pencil.properties = {
        label: "Pencil",
        defaultAppearance: {
            length: 150,
            scale: 1.0,
            eraser: false,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.8, max: 2.0, step: 0.1, unit: "%",
            },
            {
                spec: "slider", key: "length", label: "Length",
                min: 80, max: 180, unit: "mm"
            },
            {
                spec: "checkbox", key: "eraser", label: "Show eraser", tier: "boost", allowWhenLocked: true,
            },
        ],
        isBoostRequired: function (a) { return a.eraser === true; }
    };
    return Pencil;
}());
export { Pencil };
