var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * Information about how this apparatus can snap to
 * other type of apparatus.
 */
export var Snap;
(function (Snap) {
    /** This is ugly but converts a mask to a filter list. */
    function typeFilterFrom(mask) {
        var filter = [];
        Object.keys(mask).forEach(function (k) {
            if (mask[k])
                filter.push(k);
        });
        return filter;
    }
    Snap.typeFilterFrom = typeFilterFrom;
    /** Returns facing in degrees. */
    function getFacing(snap) {
        if (snap.facing === undefined)
            return 0;
        if (snap.facing !== "auto")
            return snap.facing;
        return snap.end.subtract(snap.start).angle;
    }
    Snap.getFacing = getFacing;
})(Snap || (Snap = {}));
/** Method that applies a coordinate transformation on all point-related items. */
export function transform(snapping, flipped, transform) {
    var _a;
    switch (snapping.type) {
        case "placeable":
        case "connectable":
            return __assign(__assign({}, snapping), { facing: ((_a = snapping.facing) !== null && _a !== void 0 ? _a : 0) * (flipped ? -1 : 1), at: transform(snapping.at) });
        case "attachable":
        case "spotting_tile_placeable":
        case "tlc_spot":
        case "pluggable":
        case "wire":
        case "wireport":
        case "shape_end":
            return __assign(__assign({}, snapping), { at: transform(snapping.at) });
        case "placeable_surface":
        case "attachable_on":
        case "tlc_plate":
            return __assign(__assign({}, snapping), { start: transform(snapping.start), end: transform(snapping.end) });
        case "spotting_tile":
            return {
                type: "spotting_tile",
                at: snapping.at.map(transform),
            };
        case "neck":
            return __assign(__assign({}, snapping), { top: transform(snapping.top) });
    }
}
