var KEY_JWT_TOKEN = "jwtToken";
var KEY_RESTORABLE_FILE = "restorableFile";
export function storeToken(token) {
    if (isLocalStorageAllowed()) {
        localStorage.setItem(KEY_JWT_TOKEN, token);
    }
}
export function retrieveToken() {
    if (!isLocalStorageAllowed())
        return null;
    return localStorage.getItem(KEY_JWT_TOKEN);
}
export function clearToken() {
    if (isLocalStorageAllowed()) {
        localStorage.removeItem(KEY_JWT_TOKEN);
    }
}
function isLocalStorageAllowed() {
    try {
        if (localStorage)
            return true;
    }
    catch (e) {
        return false;
    }
    return false;
}
export function saveCrashFile(doc) {
    if (!isLocalStorageAllowed())
        return;
    console.log(doc);
    var data = {
        lastModified: new Date(),
        documentSerialized: JSON.stringify(doc)
    };
    localStorage.setItem(KEY_RESTORABLE_FILE, JSON.stringify(data));
}
export function getCrashedFile() {
    if (!isLocalStorageAllowed())
        return;
    var rawData = localStorage.getItem(KEY_RESTORABLE_FILE);
    if (!rawData)
        return;
    var data = JSON.parse(rawData);
    return data;
}
export function removeCrashedFile() {
    if (!isLocalStorageAllowed())
        return;
    localStorage.removeItem(KEY_RESTORABLE_FILE);
}
