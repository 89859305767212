var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { g } from "analytics";
import { BACKEND_API } from "api/api";
import { reauthFromCurrentLogin } from "api/auth/reauth";
import { HorizontalButtonsSwitcher } from "components/button";
import { LabelledCheckbox } from "components/checkbox";
import { Select } from "components/select";
import { Slider } from "components/slider";
import Flags from "flags";
import { t } from "i18next";
import { useLoginModal } from "layout/modals/shared_modals";
import * as React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Articles, Bcn } from "support/beacon";
import { currencyLabel, currencySymbol } from "tiers/currency";
import { getPricingScheme } from "tiers/pricing";
import { BeaconLink, ChemTextHint, LicenseHint } from "tiers/promo_ui";
import { Toast } from "toast";
import { Nl } from "view/tooltips";
import { releasedAndFreeItemsCount, releasedItemsCount } from "../apparatus/spec_list";
import { BOOST_TOTAL_DIAGRAMS, ITEM_CUSTOMISATIONS, MAX_BOOST_DIAGRAM_LAYERS } from "./promo";
var TRIAL_DAYS = 7;
var SUPPORTED_CURRENCIES = [
    "gbp", "eur", "usd", "cad", "aud", "inr", "sgd", "mxn", "cop", "myr", "nzd"
];
var SCHEME = getPricingScheme(Flags.isNewPricingEnabled ? "2022" : "original");
var SCHOOL_SCHEME = getPricingScheme(Flags.isNewPricingEnabledForSchools ? "2022" : "original");
/** Teacher only is priced based on conversion from teachers to students. */
var TEACHER_TO_STUDENT_MULTIPLIER = 45;
var BillingSwitcher = /** @class */ (function (_super) {
    __extends(BillingSwitcher, _super);
    function BillingSwitcher() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return BillingSwitcher;
}(HorizontalButtonsSwitcher));
var PlansGrid = /** @class */ (function (_super) {
    __extends(PlansGrid, _super);
    function PlansGrid(props) {
        var _this = _super.call(this, props) || this;
        _this.render = function () { return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "text-center mx-2 mb-3" },
                React.createElement("div", { className: "d-inline-block mb-2" },
                    React.createElement("b", null,
                        t('Currency'),
                        ": "),
                    React.createElement(Select, { id: "currency_selector", key: "currency_selector", value: _this.getCurrency(), extraClass: "w-auto d-inline ml-1 mr-4", onSelectChange: function (_, current) { return _this.setState({ currency: current }); }, options: SUPPORTED_CURRENCIES.map(function (c) { return ({ value: c, label: currencyLabel(c) }); }) })),
                React.createElement("div", { className: "d-inline-block mb-2" },
                    React.createElement("b", null,
                        t('Billing'),
                        ": "),
                    React.createElement(BillingSwitcher, { className: "d-inline-block ml-1 prominent", onSwitch: function (b) { return _this.setState({ billing: b }); }, defaultValue: _this.state.billing, extraClass: "narrower", options: [
                            { value: "month", icon: "", buttonLabel: "Monthly" },
                            { value: "year", icon: "", buttonLabel: "Yearly" },
                        ] }))),
            React.createElement("div", { className: "row mx-2" },
                React.createElement(PlanSelector, { currentTier: _this.props.currentTier, onCheckout: function (p) { return _this.props.onCheckout(p, _this.getCurrency(), _this.state.billing); }, onUpdateSchoolPlan: _this.props.onUpdateSchoolPlan, signedIn: _this.props.signedIn, billing: _this.state.billing, currency: _this.getCurrency(), closePopup: _this.props.onClose })))); };
        _this.state = { billing: "year", showTable: true };
        var self = _this;
        _this.checkoutMessageHandler = function (ev) {
            // Filter any messages that are not from our API,
            // don't relate to this provider or while
            // the button is not active.
            if (ev.origin != BACKEND_API)
                return;
            var response = ev.data;
            self.onReceiveCheckoutSuccessMessage(response);
        };
        window.addEventListener("message", _this.checkoutMessageHandler, true);
        return _this;
    }
    PlansGrid.prototype.onReceiveCheckoutSuccessMessage = function (message) {
        var _a, _b;
        if (message.type == "checkout_success") {
            reauthFromCurrentLogin();
            Toast.success(t("promo.checkout_success.description"), t("promo.checkout_success.title", { tier: (_b = (_a = message.tier) === null || _a === void 0 ? void 0 : _a.capitalise()) !== null && _b !== void 0 ? _b : "" }));
            this.props.onClose();
            g("Checkout", "Success");
            if (this.props.analyticsTag) {
                g("Checkout-AnalyticsTag", this.props.analyticsTag);
            }
        }
        else if (message.type == "checkout_error") {
            Toast.error(t("promo.checkout_error.description"), t("promo.checkout_error.title"));
            g("Checkout", "Error");
        }
    };
    PlansGrid.prototype.getCurrency = function () {
        var _a, _b;
        return (_b = (_a = this.state.currency) !== null && _a !== void 0 ? _a : this.props.defaultCurrency) !== null && _b !== void 0 ? _b : "usd";
    };
    return PlansGrid;
}(React.Component));
export { PlansGrid };
export var ExportHighQuality = function () {
    return React.createElement(Item, { icon: "image" },
        React.createElement(Trans, { i18nKey: "promo.image_quality_high" },
            React.createElement("b", null, "High"),
            " image quality"),
        React.createElement(BeaconLink, { hint: React.createElement(Nl, null, t("promo.image_res_high")), article: Articles.IMAGE_QUALITY }));
};
export var BoostFeatureList = function (_a) {
    var _b = _a.skipFirstLine, skipFirstLine = _b === void 0 ? false : _b;
    return React.createElement(React.Fragment, null,
        !skipFirstLine && React.createElement(Item, { icon: "arrow-left" },
            React.createElement(Trans, { i18nKey: "promo.everything_included_in", values: { tier: "Start" } },
                "Everything included in ",
                React.createElement("b", null, "Start"))),
        React.createElement(Item, null,
            React.createElement(Trans, { i18nKey: "promo.n_customisations", values: { n: ITEM_CUSTOMISATIONS } },
                React.createElement("b", null, "250"),
                " more item customisations")),
        React.createElement(Item, null,
            React.createElement(Trans, { i18nKey: "promo.chem_text" },
                "Easily write formulas, units and symbols with ",
                React.createElement("b", null, "ChemText")),
            React.createElement(ChemTextHint, null)),
        React.createElement(Item, null, t("promo.style_options")),
        React.createElement(Item, null,
            React.createElement(Trans, { i18nKey: "promo.n_layers", count: MAX_BOOST_DIAGRAM_LAYERS },
                "Add up to ",
                React.createElement("b", null, "MAX_BOOST_DIAGRAM_LAYERS"),
                " liquid layers")),
        Flags.isNewTemplatesEnabled ? React.createElement(Item, null, "More diagram templates") : undefined,
        React.createElement(Item, null, t('promo.download_as', { format: "SVG" })),
        React.createElement(Item, null,
            t('promo.license'),
            " ",
            React.createElement(LicenseHint, null)),
        React.createElement(Item, null, t('No social widgets')));
};
function mapSliderValueToStudents(value) {
    var students = 100;
    students += Math.min(value, 10) * 25;
    value -= 10;
    if (value <= 0)
        return students;
    students += Math.min(value, 9) * 50;
    value -= 9;
    if (value <= 0)
        return students;
    students += Math.min(value, 12) * 100;
    value -= 12;
    if (value <= 0)
        return students;
    students += value * 500;
    return students;
}
// Computes the total number of diagrams for a school of given student size.
// Derived from https://docs.google.com/spreadsheets/d/1LNK9C4RMyJNWCBTed5ByMtxC3DTq-7JqrJyCvslwzLo/edit#gid=2014288311
function diagramLimit(students) {
    var diagrams = Math.log((students + 1150) / 100) / Math.LN10 * 7000 - 7500 + students * 2;
    return diagrams.round(-2);
}
var PlanSelector = function (props) {
    var _a = React.useState(12), studentSliderValue = _a[0], setStudentSliderValue = _a[1];
    var _b = React.useState(10), staffSliderValue = _b[0], setStaffSliderValue = _b[1];
    var _c = React.useState(false), isStaffOnly = _c[0], setIsStaffOnly = _c[1];
    var students = mapSliderValueToStudents(studentSliderValue);
    var teachers = staffSliderValue;
    var maxDiagrams = isStaffOnly ? teachers * BOOST_TOTAL_DIAGRAMS : diagramLimit(students);
    var _schoolPricing = isStaffOnly
        ? SCHOOL_SCHEME.schoolPrice(teachers * TEACHER_TO_STUDENT_MULTIPLIER, props.currency, props.billing)
        : SCHOOL_SCHEME.schoolPrice(students, props.currency, props.billing);
    React.useEffect(function () {
        isStaffOnly
            ? props.onUpdateSchoolPlan({ students: teachers, maxDiagrams: maxDiagrams })
            : props.onUpdateSchoolPlan({ students: students, maxDiagrams: maxDiagrams });
    }, [students, maxDiagrams, teachers]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Plan, null,
            React.createElement("div", { className: "plan-header" },
                React.createElement(PlanName, { name: "Start", icon: "vial" }),
                React.createElement(Price, { currency: props.currency, billing: props.billing }),
                React.createElement(Nl, null, t("promo.start_description"))),
            React.createElement(SignInButton, __assign({ currentTier: props.currentTier }, props)),
            React.createElement("ul", { className: "fa-ul feature-list" },
                React.createElement(Item, { icon: "flask" },
                    React.createElement(Trans, { i18nKey: "promo.apparatus_count", count: releasedAndFreeItemsCount },
                        React.createElement("b", null, "1"),
                        " available apparatus")),
                React.createElement(Item, { icon: "image" },
                    React.createElement(Trans, { i18nKey: "promo.image_quality_low" },
                        React.createElement("b", null, "Low"),
                        " image quality"),
                    React.createElement(BeaconLink, { hint: React.createElement(Nl, null, t('promo.image_res_low')), article: Articles.IMAGE_QUALITY })),
                React.createElement(Item, { icon: "infinity fas" }, t("promo.unlimited_image_exports")),
                React.createElement(Item, { icon: "cloud" },
                    React.createElement(Trans, { i18nKey: "promo.store_n_cloud", count: 3 },
                        "Store ",
                        React.createElement("b", null, "3"),
                        " diagrams in Cloud")),
                React.createElement("hr", null),
                React.createElement(Item, null, t('promo.download_as', { format: "JPG" })),
                React.createElement(Item, null, t('promo.download_as', { format: "PNG" })),
                React.createElement(Item, null, t('Transparent PNG')),
                React.createElement(Item, null,
                    t('promo.educational_use'),
                    React.createElement(BeaconLink, { hint: React.createElement(Nl, null, t("info.license_hint_2_nl")), article: Articles.LICENSE })))),
        React.createElement(Plan, { extraClass: "boost" },
            React.createElement("div", { className: "plan-header" },
                React.createElement(PlanName, { name: "Boost", icon: "bolt-lightning" }),
                React.createElement(Price, { price: SCHEME.price(props.currency, props.billing), currency: props.currency, billing: props.billing }),
                React.createElement(Nl, null, t("promo.boost_description", { days: TRIAL_DAYS })),
                React.createElement(DiscountTag, { billing: props.billing })),
            React.createElement(SignInButton, __assign({ tier: "boost", currentTier: props.currentTier }, props)),
            React.createElement("ul", { className: "fa-ul feature-list" },
                React.createElement(Item, { icon: "flask" },
                    React.createElement(Trans, { i18nKey: "promo.apparatus_count", count: releasedItemsCount },
                        React.createElement("b", null, "1"),
                        " available apparatus")),
                React.createElement(ExportHighQuality, null),
                React.createElement(Item, { icon: "infinity fas" }, t("promo.unlimited_image_exports")),
                React.createElement(Item, { icon: "cloud" },
                    React.createElement(Trans, { i18nKey: "promo.store_n_cloud", count: BOOST_TOTAL_DIAGRAMS },
                        "Store ",
                        React.createElement("b", null, "3"),
                        " diagrams in Cloud")),
                React.createElement("hr", null),
                React.createElement(BoostFeatureList, null))),
        React.createElement(Plan, { extraClass: "school" },
            React.createElement("div", { className: "plan-header" },
                React.createElement(PlanName, { name: t("School"), icon: "landmark" }),
                React.createElement(Price, { price: _schoolPricing, currency: props.currency, billing: props.billing }),
                React.createElement(Nl, null, t("promo.school_description", { students: students })),
                React.createElement("div", { className: "mt-3 mx-2 mb-1" }, isStaffOnly
                    ? React.createElement(Slider, { key: "staffSlider", min: 6, max: 30, step: 1, initialValue: staffSliderValue, onChange: function (value) { return setStaffSliderValue(value); }, onEnd: function (_, final) { return setStaffSliderValue(final); } })
                    : React.createElement(Slider, { key: "studentSlider", min: 0, max: 37, step: 1, initialValue: studentSliderValue, onChange: function (value) { return setStudentSliderValue(value); }, onEnd: function (_, final) { return setStudentSliderValue(final); } })),
                Flags.isStaffOnlyPricingEnabled ?
                    React.createElement("div", { className: "text-center text-muted" },
                        React.createElement(LabelledCheckbox, { label: "Staff only", useSwitch: true, id: "teachersOnlyQuote", value: isStaffOnly, onCheckedChange: setIsStaffOnly })) : undefined,
                React.createElement(DiscountTag, { billing: props.billing })),
            React.createElement("div", { className: "p-2" },
                React.createElement("div", { className: "d-block ui-button", onClick: function () { return Bcn.openSchoolEnquiry(students, SCHOOL_SCHEME.schoolPrice(students, props.currency, props.billing, props.billing), props.currency, props.billing); } }, t('promo.school_upgrade'))),
            React.createElement("ul", { className: "fa-ul feature-list" },
                React.createElement(Item, { icon: "flask" },
                    React.createElement(Trans, { i18nKey: "promo.apparatus_count", count: releasedItemsCount },
                        React.createElement("b", null, "1"),
                        " available apparatus")),
                React.createElement(ExportHighQuality, null),
                React.createElement(Item, { icon: "infinity fas" }, t("promo.unlimited_image_exports")),
                React.createElement(Item, { icon: "cloud" },
                    React.createElement(Trans, { i18nKey: "promo.store_n_cloud_inf" },
                        "Store ",
                        React.createElement("b", null,
                            React.createElement("i", { className: "fas fa-infinity mx-1" })),
                        " diagrams in Cloud")),
                React.createElement("hr", null),
                React.createElement(Item, { icon: "arrow-left" },
                    React.createElement(Trans, { i18nKey: "promo.everything_included_in", values: { tier: "Boost" } },
                        "Everything included in ",
                        React.createElement("b", null, "Boost"),
                        " ")),
                isStaffOnly
                    ? React.createElement(Item, null,
                        "Access for ",
                        React.createElement("b", null, teachers),
                        " teachers")
                    : React.createElement(Item, null,
                        React.createElement(Trans, { i18nKey: "promo.access_students", count: students },
                            "Access for ",
                            React.createElement("b", null, { students: students }),
                            " students")),
                isStaffOnly ? undefined : React.createElement(Item, null, t("promo.access_teachers")),
                React.createElement(Item, null, t("promo.school_email_sign_in"))))));
};
var DiscountTag = function (props) {
    var t = useTranslation().t;
    return props.billing == "year"
        ? React.createElement("span", { className: "discount-tag" }, t('promo.discount', { count: 20 }))
        : null;
};
var SignInButton = function (props) {
    if (!props.signedIn) {
        // If the user is not signed in, they are automatically on the basic tier.
        if (!props.tier)
            return React.createElement(YourCurrentPlan, null);
        return React.createElement(SignInToUpgrade, { closePopup: props.closePopup });
    }
    if ((!props.tier && !props.currentTier) || props.tier === props.currentTier) {
        return React.createElement(YourCurrentPlan, null);
    }
    else if (props.tier === "boost" && !props.currentTier) {
        return React.createElement(UpgradeButton, { tier: "boost", onClick: function () { return props.onCheckout("boost"); } });
    }
    else if (props.tier === "plus" && !props.currentTier) {
        return React.createElement(UpgradeButton, { tier: "plus", onClick: function () { return props.onCheckout("plus"); } });
    }
    else {
        return React.createElement("div", { className: "invisible" },
            React.createElement(YourCurrentPlan, null));
    }
};
var YourCurrentPlan = function () {
    var t = useTranslation().t;
    return React.createElement("div", { className: "py-2 px-3 text-center" },
        React.createElement("div", { className: "d-block ui-button-inactive" }, t('promo.your_current_plan')));
};
var SignInToUpgrade = function (_a) {
    var closePopup = _a.closePopup;
    var openLogin = useLoginModal().openLogin;
    var t = useTranslation().t;
    function onLoginSuccess(tier) {
        if (tier) {
            closePopup();
        }
    }
    return React.createElement("div", { className: "mt-auto py-2 px-3" },
        React.createElement("div", { className: "d-block ui-button", onClick: function () { return openLogin(onLoginSuccess); } }, t('promo.sign_in')));
};
var UPGRADE_BUTTON_TIMEOUT_MS = 4000;
/**
 * Upgrade button. Is unclickable for few seconds after being clicked,
 * showing a spinner.
 */
var UpgradeButton = function (props) {
    var _a = React.useState(false), pending = _a[0], setPending = _a[1];
    function onClick() {
        if (pending)
            return;
        props.onClick();
        setPending(true);
        setTimeout(function () { setPending(false); }, UPGRADE_BUTTON_TIMEOUT_MS);
    }
    return React.createElement("div", { className: "mt-auto p-2" },
        React.createElement("div", { className: "d-block ui-button", onClick: onClick },
            "\u00A0",
            pending ?
                React.createElement("i", { className: "fas fa-lg fa-circle-notch fa-spin" }) :
                t('promo.upgrade_to', { tier: props.tier.capitalise() }),
            "\u00A0"));
};
var _Plan = function (props) { return (React.createElement("div", { className: "col-12 col-sm-6 col-md-4 mb-3 px-1" },
    React.createElement("div", { className: "plan d-flex flex-column " + props.extraClass }, props.children))); };
var Plan = React.memo(_Plan);
var Price = function (props) {
    var _a;
    return (React.createElement("span", { className: "price" },
        props.from ? React.createElement("sub", null, "from") : null,
        React.createElement("sup", null, currencySymbol(props.currency)),
        ((_a = props.price) !== null && _a !== void 0 ? _a : 0).toLocaleFixed(2),
        React.createElement("sub", null, "/mo")));
};
var PlanName = function (props) { return React.createElement("h3", null,
    React.createElement("i", { className: "fas fa-" + props.icon + " mr-2" }),
    props.name); };
export var Disclaimer = function () {
    var t = useTranslation().t;
    return React.createElement("p", { className: "text-muted text-center" },
        React.createElement("i", null,
            React.createElement(Trans, { i18nKey: "promo.footer_1", t: t },
                "Cancel anytime. Learn more about ",
                React.createElement("a", { "data-beacon-article-modal": Articles.SUBSCRIPTIONS, href: "#" }, "subscriptions")),
            React.createElement("br", null),
            React.createElement(Trans, { i18nKey: "promo.footer_2", t: t },
                "By upgrading, you agree to our ",
                React.createElement("a", { href: "http://help.chemix.org/tos", target: "_blank" }, "Terms and Conditions."))));
};
export var Item = function (props) { return (React.createElement("li", null,
    React.createElement("span", { className: "fa-li" },
        React.createElement("i", { className: "fa fa-" + (props.icon || "check") })),
    props.children)); };
