import { P, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var METAL = [
    ["#dddddd", 0.2],
    ["#aaaaaa", 0.9],
];
var CrocodileClips = /** @class */ (function () {
    function CrocodileClips() {
    }
    CrocodileClips.prototype.render = function (appearance) {
        var topPart = new Path(Segments([[-185, -14], [-4, 0]], [[120, -48], , [10, -1]], [[122, -34], [10, -1]], [[98, -31]], [[35, -16], , [-10, 2]], [[0, 10], [20, 0], [-40, 0]], [[-44, -10], , [-7, -10]], [[-65, -9], [7, -10]], [[-76, 3]], [[-86, -7]], [[-96, 4]], [[-106, -6]], [[-116, 5]], [[-126, -5]], [[-136, 6]], [[-146, -4]], [[-156, 7]], [[-166, -3]], [[-176, 8]], [[-186, -5]]));
        topPart.closePath();
        topPart.setGradientFill("right-down", METAL);
        var bottomPart = new Path(Segments([[-176, 20]], [[-166, 9]], [[-156, 19]], [[-146, 7]], [[-136, 18]], [[-127, 6]], [[-117, 17]], [[-108, 5]], [[-98, 16]], [[-89, 4]], [[-79, 14]], [[-69, 3], , [30, 30]], [[-30, 0], [-5, 10], [25, -50]], [[36, 13], [-5, -20], [2, 8]], [[110, 15]], [[140, 10]], [[200, 8]], [[200, 31]], [[-170, 31], , [-6, 0]]));
        bottomPart.closePath();
        bottomPart.setGradientFill("right-up", METAL);
        topPart.rotate(appearance.opening * 20, P(0, 0));
        var graphic = new Group([topPart, bottomPart]);
        graphic.pivot = P(0, 0);
        graphic.strokeWidth = Math.min(3.0, appearance.scale * 1.5);
        graphic.strokeColor = SharedColors.darkMetal;
        var pivot = Shape.Circle(P(-2, 2), 12);
        pivot.fillColor = SharedColors.mediumMetal;
        graphic.addChild(pivot);
        graphic.scale(0.2 * appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: P(0, 0)
        };
    };
    CrocodileClips.properties = {
        label: "Crocodile Clips",
        flippable: true,
        defaultAppearance: {
            scale: 1.0,
            opening: 0.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 2.0, step: 0.05
            },
            {
                spec: "slider", key: "opening", label: "Opening",
                min: 0, max: 1, step: 0.1
            }
        ],
    };
    return CrocodileClips;
}());
export { CrocodileClips };
