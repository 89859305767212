var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { MoreShapes } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var Diode = /** @class */ (function () {
    function Diode() {
    }
    Diode.prototype.render = function (appearance) {
        var graphic = renderDiode(appearance).graphic;
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: [
                { type: "wire", at: P(0, -50) },
                { type: "wire", at: P(0, 50) },
            ]
        };
    };
    Diode.properties = {
        label: "Diode",
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    Diode = __decorate([
        staticImplements()
    ], Diode);
    return Diode;
}());
export { Diode };
function renderDiode(appearance) {
    var diameter = 16;
    var height = 24;
    var height2 = 5;
    var _a = MoreShapes.cylinder3d({ diameter: diameter, height: height }), body = _a.body, top = _a.top, outline = _a.outline;
    body.withGradientFill("right", Gradients.cylinder("#444444".color()));
    top.withFill("#777777");
    outline.withStroke("default");
    var _b = MoreShapes.cylinder3d({ diameter: diameter, height: height2 }), body2 = _b.body, top2 = _b.top, outline2 = _b.outline;
    top2.remove();
    outline2.remove();
    body2.bounds.top = body.bounds.top;
    body2.withGradientFill("right", Gradients.cylinder("#777777".color()));
    // Combine and add wire
    var graphic = new Group([
        new Path.Line(P(0, 0), P(0, 50)).withStroke("default"),
        body, body2, top, outline,
        new Path.Line(P(0, -50), P(0, -height / 2)).withStroke("default"),
    ]);
    return { graphic: graphic };
}
