var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Gradients } from "apparatus/gradients";
import { Pivot, R, SharedColors } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var CapsulePill = /** @class */ (function () {
    function CapsulePill() {
    }
    CapsulePill.prototype.render = function (appearance) {
        var zoom = appearance.zoom, size = appearance.size, ratio = appearance.ratio;
        var width = size * zoom;
        var height = size * ratio * zoom;
        var shape = new Path.Rectangle(R(0, 0, width, height, Pivot.CENTER), height / 2);
        var _a = this.getColours(appearance), color1 = _a[0], color2 = _a[1];
        var leftMask = new Path.Rectangle(R(0, 0, width, height + 5, Pivot.CENTER_RIGHT));
        var leftSide = leftMask.intersect(shape)
            .withGradientFill("down", Gradients.cylinder(color1.color()));
        var rightMask = new Path.Rectangle(R(0, 0, width, height + 5, Pivot.CENTER_LEFT));
        var rightSide = rightMask.intersect(shape).withFill("blue")
            .withGradientFill("down", Gradients.cylinder(color2.color()));
        shape.withStroke(zoom == 1 ? "thin" : "default", SharedColors.stroke);
        leftMask.remove();
        rightMask.remove();
        var graphic = new Group([leftSide, rightSide, shape]);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape()
        };
    };
    CapsulePill.prototype.getColours = function (appearance) {
        switch (appearance.color) {
            case "blue-white":
                return ["#2a7de0", "#dee0db"];
            case "red-white":
                return ["#d72b18", "#dee0db"];
            case "red-yellow":
                return ["#d72b18", "#fed428"];
            case "custom":
                return [appearance.color1, appearance.color2];
        }
    };
    CapsulePill.properties = {
        label: "Capsule Pill",
        defaultAppearance: {
            zoom: 1,
            size: 28,
            ratio: 0.27,
            color: "red-white",
            color1: "#fed428",
            color2: "#d74144"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 20, max: 45, unit: "mm",
            },
            {
                spec: "slider", key: "ratio", label: "Shape ratio",
                min: 0.2, max: 0.7, step: 0.01,
            },
            {
                spec: "slider", key: "zoom", label: "Zoom",
                min: 1, max: 4, unit: "x"
            },
            {
                spec: "radio", key: "color", label: "Colours", size: "text",
                options: [
                    { value: "red-white", label: "Red + White" },
                    { value: "red-yellow", label: "Red + Yellow" },
                    { value: "blue-white", label: "Blue + White" },
                    { value: "custom", label: "Custom", tier: "boost" },
                ]
            },
            {
                spec: "color", key: "color1", label: "Colour #1", visible: function (a) { return a.color === "custom"; }
            },
            {
                spec: "color", key: "color2", label: "Colour #2", visible: function (a) { return a.color === "custom"; }
            }
        ],
    };
    CapsulePill = __decorate([
        staticImplements()
    ], CapsulePill);
    return CapsulePill;
}());
export { CapsulePill };
