var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Arrays } from "ext/array";
import { ID } from "store/id";
import appStore from "store/store";
var GroupObjects = /** @class */ (function () {
    /** Creates a group from given child IDs. The group's ID can be optionally specified. */
    function GroupObjects(ids, groupId) {
        this.newGroup = {
            id: groupId !== null && groupId !== void 0 ? groupId : ID.mint("group"),
            items: ids,
        };
    }
    GroupObjects.prototype.execute = function () {
        var _this = this;
        /** Pass a shallow copy, not a reference. */
        appStore.dispatchFn("Group", function (store) { return createGroup(store, __assign({}, _this.newGroup)); });
    };
    GroupObjects.prototype.undo = function () {
        var _this = this;
        appStore.dispatchFn("Ungroup", function (store) { return ungroup(store, _this.newGroup); });
    };
    return GroupObjects;
}());
export { GroupObjects };
var BreakGroup = /** @class */ (function () {
    function BreakGroup(group) {
        this.group = group;
    }
    BreakGroup.prototype.execute = function () {
        var _this = this;
        appStore.dispatchFn("Ungroup", function (store) { return ungroup(store, _this.group); });
    };
    BreakGroup.prototype.undo = function () {
        var _this = this;
        appStore.dispatchFn("Group", function (store) { return createGroup(store, _this.group); });
    };
    return BreakGroup;
}());
export { BreakGroup };
function createGroup(store, parent) {
    var childIds = parent.items;
    var newStore = __assign(__assign({}, store), { arrows: store.arrows.map(function (a) { return Arrays.includes(childIds, a.id) ? __assign(__assign({}, a), { parentGroup: parent.id }) : a; }), items: store.items.map(function (i) { return Arrays.includes(childIds, i.id) ? __assign(__assign({}, i), { parentGroup: parent.id }) : i; }), groups: store.groups.map(function (g) { return Arrays.includes(childIds, g.id) ? __assign(__assign({}, g), { parentGroup: parent.id }) : g; }) });
    newStore.groups.push(parent);
    return newStore;
}
function ungroup(store, group) {
    var childIds = group.items;
    return __assign(__assign({}, store), { arrows: store.arrows.map(function (a) { return Arrays.includes(childIds, a.id) ? __assign(__assign({}, a), { parentGroup: undefined }) : a; }), items: store.items.map(function (i) { return Arrays.includes(childIds, i.id) ? __assign(__assign({}, i), { parentGroup: undefined }) : i; }), groups: store.groups.filter(function (g) { return g.id !== group.id; }).map(function (g) { return Arrays.includes(childIds, g.id) ? __assign(__assign({}, g), { parentGroup: undefined }) : g; }) });
}
