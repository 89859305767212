var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { smoothCorner } from "apparatus/draw";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Mounted } from "apparatus/library/physics/electric/mounted/utils";
import { Group, Path, PointText } from "paper";
import { staticImplements } from "types";
var MountedSwitch = /** @class */ (function () {
    function MountedSwitch() {
    }
    MountedSwitch.prototype.render = function (appearance) {
        var _a = Mounted.renderPlate({ appearance: appearance, width: 80, height: 45 }), base = _a.base, jacks = _a.jacks, snaps = _a.snaps;
        // Draw the switch
        var halfWidth = 20;
        var thickness = 4;
        var height = 12;
        var elevation = 7;
        var baseRect = R(0, -height, halfWidth * 2, height + thickness, Pivot.TOP_CENTER);
        var around = new Path.Rectangle(baseRect.expand(7), 4.0)
            .withFill("#777777");
        var side = new Path.Rectangle(baseRect)
            .withFill("#666666");
        var top = new Path(Segments([[-halfWidth, 0]], [[0, 0]], [[halfWidth, -elevation]], [[halfWidth, -elevation - height]], [[0, -height]], [[-halfWidth, -height]])).close();
        var outline = top.clone();
        // Move the bottom side down to "thickness"
        outline.segments[0].point.y = thickness;
        outline.segments[1].point.y = thickness;
        outline.segments[2].point.y = thickness;
        outline.withStroke("default");
        smoothCorner(outline.segments[5], 2);
        smoothCorner(outline.segments[3], 2);
        smoothCorner(outline.segments[2], 2);
        smoothCorner(outline.segments[0], 2);
        var theswitch = new Group([around, side, top, outline]);
        if (appearance.position == "off") {
            top.withGradientFill("right", [
                ["#888888", 0.4],
                ["#aaaaaa", 0.6]
            ]);
        }
        else {
            top.withGradientFill("right", [
                ["#aaaaaa", 0.4],
                ["#888888", 0.6]
            ]);
            theswitch.scale(-1, 1);
        }
        theswitch.withPositionDelta(P(40, 17));
        var graphic = new Group([base, jacks, theswitch]);
        // Label
        if (appearance.showLabels) {
            var plusMinus = new PointText({
                point: P(38, 18),
                content: "O               I",
                fillColor: "white",
                fontFamily: "Rubik",
                fontSize: 14,
                justification: "center"
            });
            plusMinus.opacity = 0.4;
            graphic.addChild(plusMinus);
        }
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: __spreadArray([], snaps)
        };
    };
    MountedSwitch.properties = {
        label: "Switch",
        defaultAppearance: __assign(__assign({}, Mounted.defaultAppearance), { position: "off", showLabels: false }),
        appearanceSpecs: __spreadArray(__spreadArray([], Mounted.appearanceSpecs), [
            {
                spec: "toggle-buttons", key: "position", label: "State", size: "text",
                options: [
                    { value: "off", label: "Off" },
                    { value: "on", label: "On" },
                ]
            },
            {
                spec: "checkbox", key: "showLabels", label: "Labels", subLabel: "Show",
            },
        ]),
    };
    MountedSwitch = __decorate([
        staticImplements()
    ], MountedSwitch);
    return MountedSwitch;
}());
export { MountedSwitch };
