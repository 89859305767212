import * as React from "react";
/** Allows access to current auth state via React context */
export var TierContext = React.createContext({
    tier: undefined,
    popup: function (analyticsLabel) { return alert("Subscribe to Chemix plus for more features."); },
    allow: function (itemOrTier) { return false; },
    badge: function (itemOrTier) { return null; },
});
/**
 * Returns true if the current context meets the given tier, i.e.
 * the current user with given {@param tier} is allowed to use
 * the {@param requested} item based on its tier.
 */
export function meets(requested, current) {
    if (!current)
        return false;
    // If the item is locked to plus, current tier must be plus or higher.
    if (requested == "plus")
        return current == "plus";
    // If the item is locked to boost, current tier must be boost or higher.
    if (requested == "boost")
        return (current == "plus" || current == "boost");
    // All other cases, return true.
    return true;
}
