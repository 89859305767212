import { createApparatus } from "apparatus/library";
import { t_add } from "i18n_utils";
import { Group, Path, Rectangle, Shape } from "paper";
import { metalRod, mirrorX, setDefaultStyle, smoothCorner } from "../draw";
import { P, Pp, Segments, SharedGradients } from "./common";
var RetortStand = /** @class */ (function () {
    function RetortStand() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 0, maxX: 0,
                minY: -600, maxY: -350,
                fromAppearance: function (a) { return P(0, -a.height); },
                toAppearance: function (f, p) { return f({ height: -p.y }); },
                scale: 1.0,
            }
        ];
    }
    RetortStand.prototype.render = function (appearance) {
        var baseWidth = appearance.direction != "front" ? 200 : 125;
        var rodOffset = 40;
        var height = appearance.height;
        var baseHeight = 15;
        var _a = metalRod(P(0, 0), P(0, -height)), rod = _a[0], rodHighlight = _a[1];
        var base = new Path(Segments([[baseWidth / 2, 0]], [[0, 0]], [[0, baseHeight]]));
        smoothCorner(base.segments[1], 5.0);
        mirrorX(base, baseWidth / 2);
        setDefaultStyle(base);
        switch (appearance.direction) {
            case "front":
                base.position.x -= baseWidth / 2;
                break;
            case "left":
                base.position.x += (rodOffset - baseWidth);
                break;
            case "right":
                base.position.x -= rodOffset;
        }
        base.setGradientFill("down", SharedGradients.metal);
        var graphic = new Group([rod, rodHighlight, base]);
        var hitShapes = [
            // Covers the rod.
            Shape.Rectangle(new Rectangle(-20, -height, 40, height)),
            // Covers the base.
            Shape.Rectangle(base.bounds),
        ];
        return {
            graphic: graphic,
            hitShape: hitShapes,
            pivot: P(0, 0),
            snapping: [
                {
                    type: "attachable_on",
                    start: P(0, 0),
                    end: P(0, -height)
                },
                graphic.placeable(),
                base.topEdgeAsPlaceableSurface(),
            ]
        };
    };
    RetortStand.properties = {
        label: "Retort stand",
        defaultAppearance: {
            direction: "right",
            height: 450,
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "direction", label: "Direction",
                options: [
                    { value: "left", label: "Facing left" },
                    { value: "front", label: "Facing front/back" },
                    { value: "right", label: "Facing right" },
                ]
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 350, max: 600
            }
        ],
        actions: [
            {
                type: "add",
                label: function () { return t_add("clamp"); },
                icon: "plus",
                action: function (a, p, r) {
                    var position = P(0, -a.height / 2).rotate(r).add(Pp(p));
                    var apparatus = createApparatus("clamp", position);
                    apparatus.rotation = r;
                    return apparatus;
                }
            }
        ]
    };
    return RetortStand;
}());
export { RetortStand };
