var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P } from "apparatus/library/common";
import { BasicShapes } from "apparatus/library/shapes/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var IsoCuboid = /** @class */ (function () {
    function IsoCuboid() {
    }
    IsoCuboid.prototype.render = function (appearance) {
        var width = appearance.width, height = appearance.height, depth = appearance.depth;
        // Origin is in the center.
        // We need to define seven corners
        /*
                      a
                b           c
                      d


                e           f
                      g

            height: b -> e
            depth: g -> f
            width: e -> g

            atan(1/2) = 26.5650512
        */
        var vHeight = P(0, height);
        var vDepth = P(depth, 0).rotate(-26.5650512);
        var vWidth = P(width, 0).rotate(26.5650512);
        var o = P(0, 0);
        var f = o.add(vHeight).add(vDepth).add(vWidth).divide(2);
        var c = f.subtract(vHeight);
        var a = c.subtract(vWidth);
        var b = a.subtract(vDepth);
        var e = b.add(vHeight);
        var g = e.add(vWidth);
        var d = g.subtract(vHeight);
        // Move the points slightly to cover gaps between the walls.
        var topWall = new Path([a, c.add([0, 1]), d.add([0, 1]), b.add([0, 1])]).close();
        var rightWall = new Path([d.subtract(vDepth.normalize()), c, f, g]).close();
        var leftWall = new Path([b, d, g, e]).close();
        var graphic = new Group([topWall, rightWall, leftWall]);
        BasicShapes.applyCustom(appearance, function (fill) {
            topWall.fillColor = fill;
            leftWall.fillColor = fill.color().ladd(5).string();
            rightWall.fillColor = fill.color().ladd(-5).string();
        }, function (width, stroke) {
            var outline = new Path([a, c, f, g, e, b])
                .close()
                .withStroke(width, stroke);
            graphic.addChild(outline);
        });
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape()
        };
    };
    IsoCuboid.properties = {
        label: "Isometric Cuboid",
        defaultAppearance: __assign(__assign({}, BasicShapes.defaultAppearance), { width: 100, height: 120, depth: 100 }),
        appearanceSpecs: __spreadArray(__spreadArray([], BasicShapes.specs), [
            BasicShapes.header,
            {
                spec: "slider", key: "width", label: "Width",
                min: 10, max: 500,
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 10, max: 500,
            },
            {
                spec: "slider", key: "depth", label: "Depth",
                min: 10, max: 500,
            },
        ]),
    };
    IsoCuboid = __decorate([
        staticImplements()
    ], IsoCuboid);
    return IsoCuboid;
}());
export { IsoCuboid };
