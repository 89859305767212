import { mirrorX } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
// https://www.sonicator.com/products/q2000-sonicator
var Sonicator = /** @class */ (function () {
    function Sonicator() {
        this.drivers = [
            {
                type: "rectangle",
                minX: -500, maxX: 500,
                minY: -600, maxY: 0,
                fromAppearance: function (a) { return P(a.probeX, a.probeY); },
                toAppearance: function (f, p) { return f({ probeX: p.x, probeY: p.y }); },
                scale: 1.0,
            }
        ];
    }
    Sonicator.prototype.render = function (appearance) {
        var probePosition = P(appearance.probeX, appearance.probeY);
        var widthAndHeights = [
            // Wire connector.
            [10, 8],
            // Top part.
            [28, 35],
            [32, 20],
            [18, 15],
            // Bottom part.
            [18, 30],
            [12, 25],
            [16, 32],
            [8, 40],
            [6, 40],
        ];
        var y = 0;
        var parts = widthAndHeights.map(function (_a) {
            var width = _a[0], height = _a[1];
            var shape = new Path.Rectangle(R(0, y, width, height, Pivot.TOP_CENTER));
            y += height;
            return shape;
        });
        parts[0].fillColor = "black";
        for (var i = 1; i < 4; i++)
            parts[i].withGradientFill("right", Gradients.cylinder("#bbbbbb".color(), 10));
        for (var i = 4; i < parts.length; i++)
            parts[i].withGradientFill("right", Gradients.cylinder("#9a8d87".color(), 10));
        // Add together to a group.
        var probe = new Group(parts);
        // Create outline.
        var outline = new Path().withStroke("default");
        outline.moveTo(P(0, 0));
        var totalY = 0;
        for (var _i = 0, widthAndHeights_1 = widthAndHeights; _i < widthAndHeights_1.length; _i++) {
            var _a = widthAndHeights_1[_i], x = _a[0], y_1 = _a[1];
            outline.lineTo(P(x / 2, totalY));
            totalY += y_1;
            outline.lineTo(P(x / 2, totalY));
        }
        mirrorX(outline);
        probe.addChild(outline);
        probe.pivot = P(0, 0);
        probe.position = probePosition;
        if (!appearance.showBase) {
            return {
                graphic: probe,
                hitShape: probe.bounds.toShape(),
            };
        }
        // Panel.
        var panel = new Group([
            Shape.Rectangle(R(0, 0, 100, 100), 3.0)
                .withStroke("default", SharedColors.devicePanel.stroke)
                .withFill(SharedColors.devicePanel.fill),
            // Screen.
            Shape.Rectangle(R(10, 10, 80, 15), 3.0).withStroke(4.0, "white").withFill(SharedColors.lcdScreen)
        ]);
        for (var i = 0; i < 4; i++) {
            for (var j = 0; j < 3; j++) {
                panel.addChild(Shape.Rectangle(R(27 + j * 16, 32 + i * 16, 12, 12), 2.0).withFill("white"));
            }
        }
        // Box.
        var box = new Group([
            // Body.
            Shape.Rectangle(R(0, 0, 240, 120, Pivot.CENTER), 4.0).withStroke("default", "#8e9499").withFill("#c0c6cb"),
            // Panel.
            panel.withPosition(P(60, 0))
        ]);
        var wire = new Path(Segments([[0, 0], , [Math.min(100, probePosition.x), 0]], [[probePosition.x, probePosition.y], [0, -85]])).withStroke(2.0, "black");
        var graphic = new Group([wire, box, probe]);
        return {
            graphic: graphic,
            pivot: box.bounds.center,
            hitShape: [box.bounds.toShape(), probe.bounds.toShape()],
            snapping: box.placeable(),
            watermark: probePosition.add(P(8, 40)),
        };
    };
    Sonicator.properties = {
        label: "Sonicator",
        defaultAppearance: {
            probeX: 250,
            probeY: -260,
            showBase: true,
        },
        appearanceSpecs: [
            { spec: "checkbox", key: "showBase", label: "Show Base" }
        ],
    };
    return Sonicator;
}());
export { Sonicator };
