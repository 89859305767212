import { mirrorX, strokeWidth } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { Interpolator, P, Pivot, R, Segments } from "apparatus/library/common";
import { hsl } from "color";
import { Group, Path, Shape } from "paper";
// Main machine colours.
var OUTLINE_COLOR = hsl(252, 3, 65).string();
var FILL_GRADIENT = Gradients.cylinder(hsl(252, 3, 80));
var GRIP_FILL_GRADIENT = [[hsl(0, 0, 33).string(), 0.0], [hsl(0, 0, 10).string(), 1.0]];
var Rotavap = /** @class */ (function () {
    function Rotavap() {
        this.liftLerp = new Interpolator([0, 1], [220, 350]);
    }
    Rotavap.prototype.render = function (appearance) {
        var bath = new Path(Segments([[0, -140], , [0, 140]], [[30, 0]]));
        mirrorX(bath, 100);
        bath.closePath();
        bath.position.x += 95;
        bath.position.y -= 20;
        bath.withStroke(strokeWidth("default"), OUTLINE_COLOR);
        bath.setGradientFill("right", FILL_GRADIENT);
        if (appearance.translucentBath) {
            bath.opacity = 0.4;
        }
        var base = Shape.Rectangle(R(0, 0, 310, 20, Pivot.BOTTOM_LEFT), 2.0);
        base.withStroke(strokeWidth("default"), hsl(0, 0, 20).string());
        base.fillColor = hsl(0, 0, 35).string();
        var lift = Shape.Rectangle(R(0, 0, 60, 360, Pivot.BOTTOM_LEFT), 5.0);
        lift.position.x += 20;
        lift.position.y -= 10;
        lift.withStroke(strokeWidth("default"), OUTLINE_COLOR);
        lift.setGradientFill("right", FILL_GRADIENT);
        var liftTrack = Shape.Rectangle(R(0, 0, 10, 130, Pivot.BOTTOM_LEFT), 5.0);
        liftTrack.position.x += 45;
        liftTrack.position.y -= 220;
        liftTrack.setGradientFill("right", [["#aaaaaa", 0.0], ["#888888", 1.0]]);
        var powerUnit = new Group([
            Shape.Rectangle(R(0, -23, 60, 20, Pivot.BOTTOM_CENTER), 2.0).withGradientFill("right", GRIP_FILL_GRADIENT),
            Shape.Rectangle(R(0, 23, 40, 20, Pivot.TOP_CENTER), 2.0).withGradientFill("right", GRIP_FILL_GRADIENT),
            Shape.Rectangle(R(0, 0, 80, 50, Pivot.CENTER), 3.0).withStroke(strokeWidth("default"), OUTLINE_COLOR).withGradientFill("right", FILL_GRADIENT),
        ]);
        // Add a shadow
        powerUnit.insertChild(0, powerUnit.children[2].clone());
        powerUnit.children[0].fillColor = "#00000010";
        powerUnit.children[0].strokeWidth = 0;
        powerUnit.children[0].position.y += 10;
        powerUnit.children[0].position.x -= 10;
        // Move into place.
        powerUnit.position.x = 50;
        powerUnit.position.y = -this.liftLerp.interpolate(appearance.lift);
        powerUnit.rotation = -65;
        var graphic = new Group([bath, lift, liftTrack, powerUnit, base]);
        var pivot = base.strokeBounds.bottomCenter;
        // Scale it.
        graphic.scale(appearance.scale, pivot);
        return {
            graphic: graphic,
            hitShape: [bath.bounds.toShape(), lift.bounds.toShape(), base.bounds.toShape()],
            snapping: base.placeable(),
            pivot: pivot,
            watermark: P(100, -140)
        };
    };
    Rotavap.properties = {
        label: "Rotary Evaporator",
        defaultAppearance: {
            scale: 1.0,
            lift: 0.1,
            translucentBath: false
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 1.0, step: 0.01, unit: "%",
            },
            {
                spec: "slider", key: "lift", label: "Lift",
                min: 0, max: 1, step: 0.01, unit: "%"
            },
            {
                spec: "checkbox", key: "translucentBath", label: "Translucent Bath",
            }
        ],
    };
    return Rotavap;
}());
export { Rotavap };
