var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Gradients } from "apparatus/gradients";
import { Pivot, R, Segments, SharedGradients } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var COLORS = {
    "Red": "#eb1200",
    "Orange": "",
    "Green": "",
    "White": "",
    "Bare": "",
};
var SodaCan = /** @class */ (function () {
    function SodaCan() {
    }
    SodaCan.prototype.render = function (appearance) {
        var height = appearance.size;
        var diameter = height / 2 + 5;
        var smallDiameter = 0.8 * diameter;
        var body = new Path(Segments([[smallDiameter / 2, 0], , [0, 5]], [[diameter / 2, 18], [0, -5]], [[diameter / 2, height - 8]])).mirrorX(0).close()
            .withGradientFill("right", Gradients.cylinder(appearance.color.color(), 12));
        var bottom = new Path(Segments([[diameter / 2, height - 8], , [0, 2]], [[smallDiameter / 2, height], [0, -3]])).mirrorX(0).close()
            .withGradientFill("right-down", SharedGradients.cylinderMetal);
        var rim = new Path.Rectangle(R(0, 0, smallDiameter + 2, 4, Pivot.CENTER))
            .withGradientFill("right", SharedGradients.cylinderMetal);
        var outline = new Path(Segments([[smallDiameter / 2 + 1, -2]], [[smallDiameter / 2 + 1, 2]], [[smallDiameter / 2, 3], , [0, 5]], [[diameter / 2, 18], [0, -5]], [[diameter / 2, height - 8], , [0, 2]], [[smallDiameter / 2, height], [0, -3]])).mirrorX(0).close().withStroke("default");
        var graphic = new Group([bottom, body, rim, outline]);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: graphic.placeable(),
        };
    };
    SodaCan.properties = {
        label: "Soda Can",
        defaultAppearance: {
            size: 122,
            color: COLORS["Red"],
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 100, max: 180
            },
            {
                spec: "color-wells", key: "color", label: "Colour",
                colors: [
                    { value: "#eb1200", label: "Red" },
                    { value: "#ff8d1c", label: "Orange" },
                    { value: "#0552e9", label: "Blue" },
                    { value: "#cddbd7", label: "White" },
                    { value: "#878787", label: "Metal" },
                ],
            }
        ],
    };
    SodaCan = __decorate([
        staticImplements()
    ], SodaCan);
    return SodaCan;
}());
export { SodaCan };
