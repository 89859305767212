import { Menu, MenuDivider, MenuItem } from "@szhsin/react-menu";
import { g } from "analytics";
import React from "react";
import { useTranslation } from "react-i18next";
import { Bcn } from "support/beacon";
import { Tip } from "view/tooltips";
export var LanguagePicker = function () {
    var t = useTranslation().t;
    return React.createElement(Menu, { menuButton: React.createElement("div", { className: "menuBarItem" },
            React.createElement("i", { className: "far fa-globe" }),
            " ",
            t("currentLanguage")), portal: true, transition: true },
        React.createElement(LanguageOptions, null));
};
export var LanguageOptions = function () {
    var _a = useTranslation(), i18n = _a.i18n, t = _a.t;
    function changeLanguage(lang) {
        g("ChangeLanguage", lang);
        i18n.changeLanguage(lang);
    }
    return React.createElement(React.Fragment, null,
        React.createElement(MenuItem, { onClick: function () { return changeLanguage("en"); } },
            React.createElement(Globe, null),
            "English"),
        React.createElement(MenuItem, { onClick: function () { return changeLanguage("de"); } },
            React.createElement(Globe, null),
            "Deutsch"),
        React.createElement(MenuItem, { onClick: function () { return changeLanguage("es"); } },
            React.createElement(Globe, null),
            "Espa\u00F1ol"),
        React.createElement(MenuItem, { onClick: function () { return changeLanguage("fr"); } },
            React.createElement(Globe, null),
            "Fran\u00E7ais"),
        React.createElement(MenuDivider, null),
        React.createElement(Tip, { content: t("beacon.translation_bug.menu_hint") },
            React.createElement(MenuItem, { onClick: function () { return Bcn.reportTranslationBug(); } },
                React.createElement("i", { className: "fas fa-pencil-mechanical" }),
                t("beacon.translation_bug.menu"))));
};
var Globe = function () { return React.createElement("i", { className: "far fa-globe" }); };
