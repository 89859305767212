var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { INFINITE_BOUNDS } from "apparatus/driver";
import { createApparatus } from "apparatus/library";
import { P, Segments } from "apparatus/library/common";
import { BasicShapes } from "apparatus/library/shapes/common";
import Flags from "flags";
import { Path, Point } from "paper";
var Line = /** @class */ (function () {
    function Line() {
        this.shouldUseBoundsCenter = true;
        this.drivers = [
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ end1x: p.x, end1y: p.y }); }, fromAppearance: function (a) { return P(a.end1x, a.end1y); }, scale: 1.0, constraints: function (a, modifiers) {
                    if (modifiers.shift) {
                        var delta = new Point(a.end1x, a.end1y).subtract([a.end2x, a.end2y]);
                        var axis = delta.clone();
                        axis.angle = Math.round(delta.angle / 45) * 45;
                        var modifiedDelta = delta.project(axis);
                        return {
                            end1x: a.end2x + modifiedDelta.x,
                            end1y: a.end2y + modifiedDelta.y
                        };
                    }
                    return;
                }, snapping: !Flags.isShapeSnappingEnabled ? undefined
                    : function (_, point) { return ({ type: "shape_end", at: point }); } }),
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ end2x: p.x, end2y: p.y }); }, fromAppearance: function (a) { return P(a.end2x, a.end2y); }, scale: 1.0, constraints: function (a, modifiers) {
                    if (modifiers.shift) {
                        var delta = new Point(a.end2x, a.end2y).subtract([a.end1x, a.end1y]);
                        var axis = delta.clone();
                        axis.angle = Math.round(delta.angle / 45) * 45;
                        var modifiedDelta = delta.project(axis);
                        return {
                            end2x: a.end1x + modifiedDelta.x,
                            end2y: a.end1y + modifiedDelta.y
                        };
                    }
                    return;
                }, snapping: !Flags.isShapeSnappingEnabled ? undefined
                    : function (_, point) { return ({ type: "shape_end", at: point }); } }),
        ];
    }
    Line.prototype.render = function (a) {
        var graphic = new Path(Segments([[a.end1x, a.end1y]], [[a.end2x, a.end2y]]));
        BasicShapes.StrokeOnly.apply(graphic, a);
        if (a.dashed) {
            graphic.dashArray = [10, 8];
        }
        return {
            graphic: graphic,
            hitShape: { type: "stroke", path: graphic.clone() },
            pivot: P(0, 0),
            snapping: !Flags.isShapeSnappingEnabled ? undefined : [
                { type: "shape_end", at: P(a.end1x, a.end1y) },
                { type: "shape_end", at: P(a.end2x, a.end2y) },
            ]
        };
    };
    Line.properties = {
        label: "Line",
        defaultAppearance: __assign({ end1x: -100, end1y: 100, end2x: 100, end2y: -100, dashed: false }, BasicShapes.StrokeOnly.defaultAppearance),
        appearanceSpecs: __spreadArray(__spreadArray([], BasicShapes.StrokeOnly.specs), [
            BasicShapes.header,
            { spec: "checkbox", key: "dashed", label: "Dashed" },
        ]),
        actions: [
            {
                type: "replace", label: "Convert to curve", icon: "wave-sine",
                action: function (a, p) {
                    var p1 = P(a.end1x, a.end1y);
                    var p2 = P(a.end2x, a.end2y);
                    var delta = p2.subtract(p1);
                    delta.length = 50; // Set distance of the handles from their parent node.
                    var pivot1 = p1.add(delta);
                    var pivot2 = p2.subtract(delta);
                    var apparatus = createApparatus("curve", p);
                    var curve = {
                        end1x: a.end1x,
                        end1y: a.end1y,
                        end2x: a.end2x,
                        end2y: a.end2y,
                        stroke: a.stroke,
                        pivot1x: pivot1.x,
                        pivot1y: pivot1.y,
                        pivot2x: pivot2.x,
                        pivot2y: pivot2.y,
                        strokeThickness: a.strokeThickness,
                    };
                    apparatus.appearance = __assign(__assign({}, apparatus.appearance), curve);
                    return apparatus;
                }
            }
        ]
    };
    return Line;
}());
export { Line };
