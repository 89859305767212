var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { g } from "analytics";
import { BACKEND_API } from "api/api";
import { loginProviderDisplayName, loginProviderIcon } from "api/auth/login_button";
import { reauthFromCurrentLogin } from "api/auth/reauth";
import { releasedItemsCount } from "apparatus/spec_list";
import { CommonModal } from "components/common_modal";
import { format } from "date-fns";
import { t } from "i18next";
import { getDateFnsLocale } from "i18n_utils";
import { StaySignedInCheckbox } from "layout/modals/login_modal";
import { clog } from "log";
import * as React from "react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import store from "store/store";
import { Bcn } from "support/beacon";
import { TierContext } from "tiers/context";
import { BOOST_TOTAL_DIAGRAMS } from "tiers/promo";
import { BoostFeatureList, ExportHighQuality, Item } from "tiers/promo_plus";
import { Toast } from "toast";
import { Nl } from "view/tooltips";
import { callApi } from "../../api/api";
import { clearToken, retrieveToken, storeToken } from "../../api/auth/storage";
import { logOut } from "../../store/actions";
import { Badge, SchoolBadge } from "../../tiers/badge";
import { opensWindow } from "../../utils";
var MyAccountModal = /** @class */ (function (_super) {
    __extends(MyAccountModal, _super);
    function MyAccountModal(props) {
        var _this = _super.call(this, props) || this;
        _this.cancelSubscription = function () {
            callApi("plans/cancel", "POST", _this.props.auth, undefined, {
                success: function () {
                    toastr.success(t("my_account.cancel_success"));
                    _this.refreshAccountDetails();
                },
                generic: function () {
                    Toast.error(t("my_account.error_contact"), t("my_account.cancel_error"));
                }
            });
        };
        _this.resumeSubscription = function () {
            g("OpenStripePortal");
            callApi("plans/resume", "POST", _this.props.auth, undefined, {
                success: function () {
                    toastr.success(t("my_account.resume_success"));
                    _this.refreshAccountDetails();
                },
                generic: function () {
                    Toast.error(t("my_account.error_contact"), t("my_account.resume_error"));
                }
            });
        };
        _this.openStripeCustomerPortal = function () {
            opensWindow(function (opener) {
                callApi("account/stripe_customer_portal", "POST", _this.props.auth, undefined, {
                    success: function (response) {
                        opener(response.url);
                    },
                    generic: function () {
                        toastr.error(t("my_account.error_contact"));
                    }
                });
            });
        };
        _this.refreshPurchase = function (loggedIn) {
            var token = loggedIn.jwtToken;
            var headers = new Headers();
            headers.set("Authorization", "Bearer " + token);
            return fetch(BACKEND_API + "/plans/refresh", {
                method: "POST",
                headers: headers,
            }).then(function (r) {
                if (r.status == 200) {
                    toastr.success(t("my_account.refresh_success"));
                    reauthFromCurrentLogin();
                    _this.refreshAccountDetails();
                }
                else if (r.status == 404) {
                    toastr.error(t("my_account.refresh_error"));
                }
            });
        };
        _this.setPassphrase = function (loggedIn) {
            var passphrase = prompt("Enter passphrase", undefined);
            if (!passphrase)
                return;
            var token = loggedIn.jwtToken;
            var headers = new Headers();
            headers.set("Authorization", "Bearer " + token);
            callApi("account/set_passphrase", "POST", _this.props.auth, { passphrase: passphrase }, {
                success: function (response) {
                    toastr.success("Successfully set passphrase.");
                    reauthFromCurrentLogin();
                    _this.refreshAccountDetails();
                },
                generic: function () {
                    toastr.error("Could not set passphrase. If you get this error repeatedly, please report this to support@chemix.org");
                }
            });
        };
        _this.render = function () { return (React.createElement(CommonModal, __assign({}, _this.props, { extraClass: "my-account-modal", title: t("My account"), icon: "fa-square-user", onShow: function () { return _this.refreshAccountDetails(); } }), _this.props.auth.state == "logged_in" ? _this.renderLoggedIn(_this.props.auth) : _this.renderLoggedOut())); };
        _this.renderLoggedIn = function (state) {
            var _a;
            return (React.createElement(React.Fragment, null,
                React.createElement(AccountRow, __assign({}, state, { staySignedIn: _this.state.staySignedIn, toggleStayLoggedIn: function (v) { return _this.toggleStayLoggedIn(v); } })),
                _this.subscriptionRow(state, _this.state.accountDetails),
                (((_a = _this.state.accountDetails) === null || _a === void 0 ? void 0 : _a.type) === "domain" && _this.state.accountDetails.passphraseStatus)
                    ? _this.passphraseRow(state, _this.state.accountDetails.passphraseStatus)
                    : undefined,
                React.createElement("div", { className: "my-1 text-center" },
                    React.createElement("div", { className: "ui-button", onClick: function () { return _this.signOut(); }, key: "logoutButton" },
                        React.createElement("i", { className: "fas fa-sign-out-alt mr-2" }),
                        t('Sign out')),
                    React.createElement("button", { className: "ui-button ml-1", onClick: function () { return _this.close(); }, key: "closeButton" }, t("Close")))));
        };
        _this.subscriptionRow = function (auth, accountDetails) { return (React.createElement(Row, { heading: "Subscription" }, (function () {
            if (!accountDetails)
                return _this.individualAccountDetails(auth, undefined);
            switch (accountDetails.type) {
                case "domain": return _this.domainAccountDetails(auth, accountDetails);
                case "managed": return _this.managedAccountDetails(accountDetails);
                case "individual": return _this.individualAccountDetails(auth, accountDetails);
            }
        })())); };
        _this.passphraseRow = function (auth, passphraseStatus) {
            var buttonText = passphraseStatus === "unset" ? "Set passphrase" : "Change passphrase";
            var explanationText = (function () {
                switch (passphraseStatus) {
                    case "correct": return "Your passphrase is correctly set up.";
                    case "incorrect": return "Your current passphrase is incorrect.";
                    case "unset": return "Your organisation's subscription requires you to have a valid passphrase set up. ";
                }
            })();
            return React.createElement(Row, { heading: "Passphrase" },
                React.createElement("p", null, explanationText),
                React.createElement("button", { className: "ui-button", onClick: function () { return _this.setPassphrase(auth); } }, buttonText));
        };
        _this.individualAccountDetails = function (loggedIn, accountDetails) { return (React.createElement(React.Fragment, null,
            accountDetails ?
                _this.renderCurrentAccountDetails(accountDetails)
                : _this.renderStartAccountDetails(),
            accountDetails && !accountDetails.auto_renew ?
                React.createElement("div", { className: "ui-button mr-1 mb-1", onClick: _this.resumeSubscription },
                    React.createElement("i", { className: "" }),
                    t("Resume plan"))
                : undefined,
            !accountDetails ?
                React.createElement(TierContext.Consumer, null, function (tiers) { return (React.createElement("div", { className: "ui-button mr-1 mb-1 highlight", onClick: function () { return tiers.popup("MyAccount"); }, key: "plan" },
                    React.createElement("i", { className: "" }),
                    t('Upgrade plan'))); })
                : /* Otherwise show a "Manage subscription" button that takes users to Stripe portal. */
                    React.createElement("div", { className: "ui-button mr-1 mb-1", onClick: _this.openStripeCustomerPortal, key: "manage" },
                        React.createElement("i", { className: "" }),
                        t('Manage')),
            React.createElement("div", { className: "ui-button mr-1", onClick: function () { return _this.refreshPurchase(loggedIn); } },
                React.createElement("i", { className: "" }),
                t("Refresh")),
            accountDetails && accountDetails.auto_renew && !_this.state.showCancelDialog ?
                React.createElement("div", { className: "ui-button mr-1 mb-1", onClick: function () { return _this.setState({ showCancelDialog: true }); } },
                    React.createElement("i", { className: "" }),
                    t("Cancel plan"))
                : undefined,
            _this.state.showCancelDialog ?
                React.createElement(CancelPlanConfirmation, { onConfirm: function () { _this.cancelSubscription(); _this.setState({ showCancelDialog: false }); }, onNoop: function () { return _this.setState({ showCancelDialog: false }); } })
                : undefined,
            React.createElement("div", { className: "text-muted my-1" },
                React.createElement(Trans, { i18nKey: "my_account.contact_footer" },
                    "Got a question? ",
                    React.createElement("a", { href: "#", onClick: function () { Bcn.ask(); return false; } }, "Chat to us"),
                    " or email ",
                    React.createElement("a", { href: "mailto:support@chemix.org", target: "_blank" }, "support@chemix.org"),
                    ".")),
            !accountDetails &&
                React.createElement(OrgAccessPassphrase, { loggedIn: loggedIn, auth: _this.props.auth, summaryType: "basic", onSuccess: function () {
                        reauthFromCurrentLogin();
                        _this.refreshAccountDetails();
                    } }))); };
        _this.domainAccountDetails = function (loggedIn, details) { return (React.createElement(React.Fragment, null,
            React.createElement("p", null,
                t('my_account.current_plan'),
                ": ",
                React.createElement(SchoolBadge, null),
                React.createElement("b", null, t("School")),
                " ",
                React.createElement("br", null)),
            React.createElement("p", null, t('my_account.school_plan_description', { domain: details.domain })),
            (details.orgAccessPassphraseStatus === "incorrect")
                ? React.createElement(OrgAccessPassphrase, { loggedIn: loggedIn, auth: _this.props.auth, summaryType: "incorrect", onSuccess: function () {
                        reauthFromCurrentLogin();
                        _this.refreshAccountDetails();
                    } })
                : undefined)); };
        _this.managedAccountDetails = function (details) { return (React.createElement(React.Fragment, null,
            React.createElement("p", null,
                t('my_account.current_plan'),
                ": ",
                React.createElement(Badge, { tier: details.tier, hideHint: true, extraClass: "fa-fw" }),
                React.createElement("b", null,
                    details.tier.capitalise(),
                    " "),
                " ",
                React.createElement("br", null)),
            React.createElement("p", null, t('my_account.managed_group_plan_description', { group: details.managedBy })))); };
        /** Renders the account summary if the user does not have a paid subscription. */
        _this.renderStartAccountDetails = function () { return (React.createElement(React.Fragment, null,
            React.createElement("p", null,
                t('my_account.current_plan'),
                ": ",
                React.createElement("b", null, "Start")))); };
        _this.renderCurrentAccountDetails = function (details) {
            var next_payment_date = format(details.next_payment_date, "d MMM yyyy", { locale: getDateFnsLocale() });
            var payment = details.currency + " " + details.payment;
            return React.createElement("p", null,
                t('my_account.current_plan'),
                ": ",
                React.createElement(Badge, { tier: details.tier, hideHint: true, extraClass: "mr-1" }),
                React.createElement("b", null,
                    details.tier.capitalise(),
                    " "),
                " ",
                React.createElement("br", null),
                details.auto_renew ?
                    details.is_trial ?
                        React.createElement(Nl, null, t("my_account.subscription.trial", { next_payment_date: next_payment_date, payment: payment }))
                        :
                            React.createElement(Nl, null, t("my_account.subscription.active", { next_payment_date: next_payment_date, payment: payment }))
                    : React.createElement(Nl, null, t("my_account.subscription.ending", { next_payment_date: next_payment_date })));
        };
        _this.renderLoggedOut = function () { return (React.createElement("div", null, "You are signed out.")); };
        _this.state = {
            fetchedAccountDetails: false,
            staySignedIn: retrieveToken() != null,
            showCancelDialog: false,
        };
        return _this;
    }
    MyAccountModal.prototype.close = function () {
        this.props.setIsOpen(false);
    };
    MyAccountModal.prototype.signOut = function () {
        store.dispatch(logOut());
        this.close();
    };
    MyAccountModal.prototype.toggleStayLoggedIn = function (staySignedIn) {
        if (this.props.auth.state == "logged_in") {
            if (staySignedIn) {
                // Store token.
                clog("store token to storage");
                storeToken(this.props.auth.jwtToken);
            }
            else {
                // Erase token.
                clog("Clear token");
                clearToken();
            }
            toastr.success(t('my_account.preference_saved'));
            this.setState({
                staySignedIn: staySignedIn
            });
        }
    };
    MyAccountModal.prototype.refreshAccountDetails = function () {
        var _this = this;
        callApi("account/details", "GET", this.props.auth, undefined, {
            success: function (response) {
                // Convert the response to state.
                var accountDetails = response.planDetails;
                _this.setState({ fetchedAccountDetails: true });
                _this.setState((function () {
                    if (!accountDetails) {
                        return { accountDetails: undefined };
                    }
                    switch (accountDetails.type) {
                        case "individual":
                            return {
                                accountDetails: {
                                    type: accountDetails.type,
                                    tier: accountDetails.tier,
                                    payment: accountDetails.payment,
                                    currency: accountDetails.currency,
                                    interval: accountDetails.interval,
                                    next_payment_date: new Date(accountDetails.next_payment_date),
                                    auto_renew: accountDetails.auto_renew,
                                    is_trial: accountDetails.is_trial,
                                }
                            };
                        case "managed":
                        case "domain":
                            return { accountDetails: accountDetails };
                    }
                })());
            },
        });
    };
    return MyAccountModal;
}(React.Component));
export { MyAccountModal };
var AccountRow = function (_a) {
    var staySignedIn = _a.staySignedIn, toggleStayLoggedIn = _a.toggleStayLoggedIn, state = __rest(_a, ["staySignedIn", "toggleStayLoggedIn"]);
    var t = useTranslation().t;
    return React.createElement(Row, { heading: t('Account') },
        React.createElement("div", { className: "account-summary", key: "summary" },
            React.createElement("div", { className: "avatar" },
                React.createElement("img", { height: "48", src: state.user.avatarUrl }),
                React.createElement("span", { className: "provider_icon fa-stack fa-2x" },
                    React.createElement("i", { className: "fas fa-circle fa-stack-2x" }),
                    React.createElement("i", { className: loginProviderIcon(state.provider) + " fa-stack-1x fa-inverse" }))),
            React.createElement(CurrentlySignedIn, { provider: state.provider, handle: state.user.handle })),
        React.createElement(StaySignedInCheckbox, { key: "stayLoggedIn", value: staySignedIn, onChange: function (v) { return toggleStayLoggedIn(v); } }));
};
var CurrentlySignedIn = function (_a) {
    var provider = _a.provider, handle = _a.handle;
    var t = useTranslation().t;
    return React.createElement("span", null, t('login.current_login', { provider: t(loginProviderDisplayName(provider)), handle: handle }));
};
/** A row (or section) containing some settings. */
var Row = function (props) { return React.createElement(React.Fragment, null,
    React.createElement("div", { className: "row" },
        React.createElement("div", { className: "col-12 col-sm-3 text-muted text-sm-right" },
            React.createElement("b", null, props.heading)),
        React.createElement("div", { className: "col-12 col-sm-9 text-left" }, props.children)),
    React.createElement("hr", null)); };
var CancelPlanConfirmation = function (props) {
    var t = useTranslation().t;
    return React.createElement("div", { className: "mt-2 border border-danger rounded text-left p-2" },
        React.createElement("p", { className: "text-danger" },
            React.createElement("b", null, t("my_account.cancel_are_you_sure"))),
        React.createElement("ul", { className: "fa-ul feature-list mt-1" },
            React.createElement(Item, { icon: "flask" },
                React.createElement(Trans, { i18nKey: "promo.apparatus_count", count: releasedItemsCount },
                    React.createElement("b", null, "1"),
                    " available apparatus")),
            React.createElement(ExportHighQuality, null),
            React.createElement(Item, { icon: "infinity fas" }, t("promo.unlimited_image_exports")),
            React.createElement(Item, { icon: "cloud" },
                React.createElement(Trans, { i18nKey: "promo.store_n_cloud", count: BOOST_TOTAL_DIAGRAMS },
                    "Store ",
                    React.createElement("b", null, "3"),
                    " diagrams in Cloud")),
            React.createElement(BoostFeatureList, { skipFirstLine: true })),
        React.createElement("button", { className: "ui-button mr-1", onClick: props.onNoop }, t("my_account.cancel_no")),
        React.createElement("button", { className: "ui-button mr-1 danger", onClick: props.onConfirm }, t("my_account.cancel_confirm")));
};
var OrgAccessPassphrase = function (_a) {
    var loggedIn = _a.loggedIn, auth = _a.auth, onSuccess = _a.onSuccess, summaryType = _a.summaryType;
    var _b = useState(false), show = _b[0], setShow = _b[1];
    var _c = useState(""), name = _c[0], setName = _c[1];
    var _d = useState(""), passphrase = _d[0], setPassphrase = _d[1];
    function onClick() {
        var headers = new Headers();
        headers.set("Authorization", "Bearer " + loggedIn.jwtToken);
        var args = { name: name, passphrase: passphrase };
        callApi("account/set_access_passphrase", "POST", auth, args, {
            success: function (response) {
                toastr.success(t('my_account.org_access_passphrase.update_success'));
                onSuccess();
            },
            special: function (error) {
                if (error.error_type !== "set_access_passphrase_error") {
                    return false;
                }
                toastr.error(t("my_account.org_access_passphrase.error." + error.additional_detail));
                return true;
            },
            generic: function () {
                toastr.error("Could not set passphrase. If you get this error repeatedly, please report this to support@chemix.org");
            }
        });
    }
    if (!show) {
        if (summaryType == "basic") {
            return React.createElement(ClickToShowOrgAccessPassphrase, { show: function () { return setShow(true); } });
        }
        else if (summaryType == "incorrect") {
            return React.createElement(IncorrectOrgAccessPassphrase, { show: function () { return setShow(true); } });
        }
    }
    return React.createElement("div", null,
        React.createElement("b", null, t('my_account.org_access_passphrase.name')),
        React.createElement("div", null, t('my_account.org_access_passphrase.org_name')),
        React.createElement("div", null,
            React.createElement("input", { name: "name", className: "ui-input", value: name, onChange: function (e) { return setName(e.target.value); } })),
        React.createElement("div", { className: "mt-2" }, t('my_account.org_access_passphrase.passphrase')),
        React.createElement("div", null,
            React.createElement("input", { name: "passphrase", className: "ui-input", value: passphrase, onChange: function (e) { return setPassphrase(e.target.value); } })),
        React.createElement("div", { className: "mt-2" },
            React.createElement("button", { className: "ui-button", onClick: onClick }, t('my_account.org_access_passphrase.update_button'))));
};
var ClickToShowOrgAccessPassphrase = function (_a) {
    var show = _a.show;
    return React.createElement("div", { className: "text-muted my-1" },
        React.createElement(Trans, { i18nKey: "my_account.org_access_passphrase.setup_prompt" },
            "If you have an Organisation Access Code, ",
            React.createElement("a", { href: "#", onClick: function () { show(); return false; } }, "click here")));
};
var IncorrectOrgAccessPassphrase = function (_a) {
    var show = _a.show;
    return React.createElement("div", { className: "text-danger my-1" },
        React.createElement(Trans, { i18nKey: "my_account.org_access_passphrase.incorrect_prompt" },
            "Your Organisation Access Code is incorrect. ",
            React.createElement("a", { href: "#", onClick: function () { show(); return false; } }, "Update")));
};
