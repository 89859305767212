import { mirrorX } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedGradients } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var HANDLE_COLOUR = "#132c64".color();
/**
 * See https://solutions.pipette.com/micropipette/
 * https://5.imimg.com/data5/IG/DV/EH/SELLER-10358897/laboratory-ware-500x500.png
 */
var Micropipette = /** @class */ (function () {
    function Micropipette() {
    }
    Micropipette.prototype.render = function (appearance) {
        var handle = new Path(Segments([[-28, -79]], [[15, -71]], [[15, -63]], [[26, -61], [-5, -1], [3, 1]], [[30, -50], [0, -4], [-1, 21]], [[25, 33]], [[-11, 33]], [[-12, -59], [1, 19], [-1, -12]], [[-28, -72]])).close()
            .subtract(new Path.Rectangle(R(0, -65, 15, 25, Pivot.TOP_CENTER), 4.0))
            .withStroke("thin", "#151d2b")
            .withGradientFill("right", Gradients.cylinder(HANDLE_COLOUR));
        var dial1 = Shape.Rectangle(R(0, -65, 15, 15, Pivot.TOP_CENTER))
            .withGradientFill("right", Gradients.cylinder("#333333".color()));
        var dial2 = Shape.Rectangle(R(0, -60, 8, 20, Pivot.TOP_CENTER))
            .withGradientFill("right", Gradients.cylinder("#333333".color()));
        var dial3 = Shape.Rectangle(R(0, 33, 22, 16, Pivot.TOP_CENTER), 2.0)
            .withStroke("thin", "#142852")
            .withGradientFill("right", Gradients.cylinder(HANDLE_COLOUR));
        var ejectorTip = new Path(Segments([[20, -76], , [16, -1]], [[26, -72], [8, -1]], [[25, -52]], [[19, -52]])).close()
            .withGradientFill("right", Gradients.simple("#cccccc".color()));
        var shaft = new Path(Segments([[25, 31], , [-1, 21]], [[10, 68], [6, -11], [-5, 9]], [[3, 157]], [[0, 184]], [[-3, 184]], [[-5, 157]], [[-6, 85], [0, 16], [0, -15]], [[-8, 48], [-1, 18], [-1, -8]], [[-9, 31]])).close().withStroke("thinner", "#bbbbbb")
            .withGradientFill("right", Gradients.reflected(["#cfd4dc", 0.1], ["#e4e5e9", 0.3]));
        var plungerButton = new Path(Segments([[14, -107]], [[14, -102]], [[5, -93], [2, -7], [-5, 0]]));
        mirrorX(plungerButton);
        plungerButton
            .close()
            .withGradientFill("down", [["#d2d2d2", 0.32], ["#bbbbbb", 0.33], ["#d2d2d2", 0.8]]);
        var plungerRod = Shape.Rectangle(R(0, -95, 6, 50, Pivot.TOP_CENTER))
            .withGradientFill("left", SharedGradients.cylinderMetal);
        var plunger = new Group([plungerRod, plungerButton]);
        var graphic = new Group([plunger, ejectorTip, dial2, dial1, shaft, dial3, handle]);
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: P(0, 0)
        };
    };
    Micropipette.properties = {
        label: "Micropipette",
        defaultAppearance: {
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 1.2, step: 0.01, unit: "%",
            }
        ],
    };
    return Micropipette;
}());
export { Micropipette };
