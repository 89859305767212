import { mirrorX } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { drawArrowHead } from "apparatus/library/shapes/curve";
import { Group, Path } from "paper";
var Centrifuge = /** @class */ (function () {
    function Centrifuge() {
    }
    Centrifuge.prototype.render = function (appearance) {
        var wall = new Path(Segments([[-87, 0], , [-73, 89]], [[-114, 160], [-59, -26], [50, 22]])).withStroke("thick").withFill("#ffffffe2");
        mirrorX(wall);
        var lid = new Path.Rectangle(R(0, 0, 87 * 2, 20, Pivot.BOTTOM_CENTER))
            .withGradientFill("right", Gradients.cylinder(SharedColors.lightMetal.color()))
            .withStroke("thin");
        var lid2 = new Path.Rectangle(R(0, -20, 40, 15, Pivot.BOTTOM_CENTER))
            .withGradientFill("right", Gradients.cylinder(SharedColors.lightMetal.color()))
            .withStroke("thin");
        var motor = new Path(Segments([[12, 0]], [[22, 59]], [[62, 100], , [-17, 2]], [[18, 104]], [[31, 123], , [24, 0]], [[79, 151], [0, -30]])).close()
            .withStroke("default", SharedColors.mediumMetal)
            .withGradientFill("right", Gradients.cylinder("#cccccc".color(), 2));
        mirrorX(motor);
        motor.position.y += 72;
        var graphic = new Group([motor, wall, lid, lid2]);
        if (appearance.showArrow) {
            var arrowPath = new Path(Segments([[116, 170], , [-39, 55]]));
            mirrorX(arrowPath);
            var head = drawArrowHead(arrowPath.segments[arrowPath.segments.length - 1], "handleIn", 16, { adjustAngle: -4 });
            var arrow = new Group([arrowPath, head]).withStroke("thicker", "#407af3");
            graphic.addChild(arrow);
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: graphic.placeable(),
            watermark: P(22, 170)
        };
    };
    Centrifuge.properties = {
        label: "Centrifuge",
        flippable: true,
        defaultAppearance: {
            showArrow: false
        },
        appearanceSpecs: [
            { spec: "checkbox", key: "showArrow", label: "Show", subLabel: "Arrow" },
        ],
    };
    return Centrifuge;
}());
export { Centrifuge };
