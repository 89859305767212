import { apparatusClass } from "apparatus/library";
import { itemsThatRequireBoost } from "apparatus/spec_list";
/**
 * This should probably be moved inside the context.
 */
export function isItemUnlocked(item, currentTier) {
    if (currentTier) {
        return true;
    }
    // If here, then user does not have a plan.
    if (itemsThatRequireBoost.indexOf(item.type) !== -1) {
        return false;
    }
    var klass = apparatusClass(item.type);
    if (klass.properties.isBoostRequired && klass.properties.isBoostRequired(item.appearance)) {
        return false;
    }
    return true;
}
