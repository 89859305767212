var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { addEdgeLips, addMarkers, breakPath, mirrorX, setDefaultStyle } from "apparatus/draw";
import { markerSpacing, P, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
import { staticImplements } from "types";
import { createTap, Tap } from "./common/tap";
var Burette = /** @class */ (function () {
    function Burette() {
    }
    Burette.convertToLatest = function (a) {
        if (!("outletDiameter" in a)) {
            return __assign(__assign({}, a), { outletDiameter: 10 });
        }
        return a;
    };
    Burette.prototype.render = function (appearance) {
        var outletDiameter = appearance.outletDiameter, size = appearance.size;
        var glass = new Path(Segments([[0, 0]], [[0, size], undefined, [0, 10]], [[(appearance.diameter - outletDiameter) / 2, size + 15]], [[(appearance.diameter - outletDiameter) / 2, size + 70]]));
        mirrorX(glass, appearance.diameter / 2);
        var liquidMask = glass.clone();
        var isJetEmpty = Tap.isClosed(appearance.tapOpening) && appearance.emptyJet;
        if (isJetEmpty) {
            liquidMask.segments[3].point = liquidMask.segments[5].point;
        }
        addEdgeLips(glass);
        var glassWalls = breakPath(glass, 5);
        setDefaultStyle(glassWalls);
        var stopCock = createTap(appearance);
        stopCock.position.x += appearance.diameter / 2;
        stopCock.position.y = size + 20;
        var graphic = new Group([glassWalls, stopCock]);
        addMarkers(graphic, P(appearance.diameter - 4, size + 15), 8, P(0, markerSpacing * 2));
        var hitShapes = [
            Shape.Rectangle(glass.bounds),
            Shape.Rectangle(stopCock.bounds)
        ];
        return {
            graphic: graphic,
            hitShape: hitShapes,
            liquidMask: liquidMask,
            liquidDrainOpening: isJetEmpty ? undefined : {
                start: liquidMask.segments[3].point,
                end: liquidMask.segments[4].point,
            }
        };
    };
    Burette.properties = {
        label: "Burette",
        canContainLiquids: true,
        meniscus: true,
        drainable: true,
        defaultAppearance: __assign(__assign({ size: 250, diameter: 24, outletDiameter: 6 }, Tap.defaultAppearance), { emptyJet: true }),
        appearanceSpecs: __spreadArray(__spreadArray([
            {
                spec: "slider", key: "size", label: "Size",
                min: 150, max: 600,
            },
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 18, max: 24, step: 0.1,
            },
            {
                spec: "slider", key: "outletDiameter", label: "Outlet diameter",
                min: 4, max: 10, step: 0.5, unit: "mm"
            }
        ], Tap.appearanceSpecsWithFacing), [
            {
                spec: "checkbox", key: "emptyJet", label: "Empty jet",
                visible: function (a) { return Tap.isClosed(a.tapOpening); },
            },
        ]),
    };
    Burette = __decorate([
        staticImplements()
    ], Burette);
    return Burette;
}());
export { Burette };
function ApparatusClass() {
    throw new Error("Function not implemented.");
}
