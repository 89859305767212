import { releasedAndFreeItemsCount, releasedItemsCount } from "apparatus/spec_list";
import { Icon as FaIcon } from "components/icon";
import Flags from "flags";
import { t } from "i18next";
import * as React from "react";
import { Trans } from "react-i18next";
import { Articles } from "support/beacon";
import { ChemMark } from "text/parse";
import { BeaconLink, ChemTextHint, LicenseHint, PermissionHint, Tick } from "tiers/promo_ui";
import { BOOST_TOTAL_DIAGRAMS, ITEM_CUSTOMISATIONS, MAX_BOOST_DIAGRAM_LAYERS } from "./promo";
export var ComparisonTable = function (props) {
    var maxBoostDiagramLayersAsIcons = [];
    for (var i = 0; i < MAX_BOOST_DIAGRAM_LAYERS; i++) {
        maxBoostDiagramLayersAsIcons.push(React.createElement(InlineIcon, { icon: "fa-tint", key: i }));
    }
    return React.createElement("table", { className: "table-responsive-md" },
        React.createElement("tbody", null,
            React.createElement("tr", null,
                React.createElement("th", { style: { minWidth: 260 }, colSpan: 2 },
                    React.createElement("h6", { className: "text-left m-0" }, t("promo.compare"))),
                React.createElement("th", { style: { minWidth: 110 } },
                    React.createElement("div", null,
                        React.createElement("i", { className: "fa fa-vial mr-2" }),
                        "Start")),
                React.createElement("th", { style: { minWidth: 110 } },
                    React.createElement("div", null,
                        React.createElement("i", { className: "fa fa-bolt-lightning mr-2" }),
                        "Boost")),
                React.createElement("th", { style: { minWidth: 110 } },
                    React.createElement("div", null,
                        React.createElement("i", { className: "fa fa-landmark mr-2" }),
                        t("School")))),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-flask", swapOpacity: true })),
                React.createElement("td", null, t("promo.table.apparatus")),
                React.createElement("td", null, releasedAndFreeItemsCount),
                React.createElement("td", null,
                    releasedItemsCount,
                    " "),
                React.createElement("td", null,
                    releasedItemsCount,
                    " ")),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-grid-5" })),
                React.createElement("td", null,
                    t('promo.table.image_quality'),
                    React.createElement(BeaconLink, { hint: t('promo.standard_vs_high_quality'), article: Articles.IMAGE_QUALITY })),
                React.createElement("td", null,
                    "0.5 MP ",
                    React.createElement(Icon, { icon: "fa-grid-2" })),
                React.createElement("td", null,
                    "4.0 MP ",
                    React.createElement(Icon, { icon: "fa-grid-5" })),
                React.createElement("td", null,
                    "4.0 MP ",
                    React.createElement(Icon, { icon: "fa-grid-5" }))),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-image", swapOpacity: true })),
                React.createElement("td", null,
                    t('promo.table.image_size'),
                    React.createElement(BeaconLink, { hint: t('promo.standard_vs_high_quality'), article: Articles.IMAGE_QUALITY })),
                React.createElement("td", null, "800x600"),
                React.createElement("td", null, "2400x1600"),
                React.createElement("td", null, "2400x1600")),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-arrow-down-to-bracket" })),
                React.createElement("td", null, t("promo.table.exports")),
                React.createElement("td", null,
                    React.createElement(InfinityIcon, null)),
                React.createElement("td", null,
                    React.createElement(InfinityIcon, null)),
                React.createElement("td", null,
                    React.createElement(InfinityIcon, null))),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-cloud", swapOpacity: true })),
                React.createElement("td", null, t("promo.table.cloud")),
                React.createElement("td", null, "3"),
                React.createElement("td", null, BOOST_TOTAL_DIAGRAMS),
                React.createElement("td", null, t("Unlimited"))),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-graduation-cap" })),
                React.createElement("td", null,
                    t("promo.table.permission"),
                    " ",
                    React.createElement(PermissionHint, null)),
                React.createElement("td", null,
                    React.createElement(Tick, null)),
                React.createElement("td", null,
                    React.createElement(Tick, null)),
                React.createElement("td", null,
                    React.createElement(Tick, null))),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-file-certificate" })),
                React.createElement("td", null,
                    t("promo.table.permission_commercial"),
                    " ",
                    React.createElement(LicenseHint, null)),
                React.createElement("td", null),
                React.createElement("td", null,
                    React.createElement(Tick, null)),
                React.createElement("td", null,
                    React.createElement(Tick, null))),
            React.createElement("tr", { className: "start-section" },
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-sliders-simple" })),
                React.createElement("td", null,
                    t("promo.table.extra_customisations"),
                    React.createElement("div", { className: "text-muted" }, t("promo.table.extra_customisations_detail"))),
                React.createElement("td", null, "0"),
                React.createElement("td", null, ITEM_CUSTOMISATIONS),
                React.createElement("td", null, ITEM_CUSTOMISATIONS)),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-paint-roller" })),
                React.createElement("td", null, t("promo.table.styling")),
                React.createElement("td", null),
                React.createElement("td", null,
                    React.createElement(Tick, null)),
                React.createElement("td", null,
                    React.createElement(Tick, null))),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(FaIcon, { name: ChemMark.faIconName, fullWidth: true })),
                React.createElement("td", null,
                    React.createElement(Trans, { i18nKey: "promo.chem_text" },
                        "Easily write formulas, units and symbols with ",
                        React.createElement("b", null, "ChemText")),
                    " ",
                    React.createElement(ChemTextHint, null),
                    React.createElement("div", { className: "text-muted" }, t('promo.table.chem_text_detail'))),
                React.createElement("td", null),
                React.createElement("td", null,
                    React.createElement(Tick, null)),
                React.createElement("td", null,
                    React.createElement(Tick, null))),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-fill-drip" })),
                React.createElement("td", null, t("promo.table.liquid_layers")),
                React.createElement("td", null,
                    React.createElement(InlineIcon, { icon: "fa-tint", key: "1" }),
                    React.createElement(InlineIcon, { icon: "fa-tint", key: "2" })),
                React.createElement("td", null, maxBoostDiagramLayersAsIcons),
                React.createElement("td", null, maxBoostDiagramLayersAsIcons)),
            Flags.isNewTemplatesEnabled
                ? React.createElement("tr", null,
                    React.createElement("td", null,
                        React.createElement(Icon, { icon: "fa-sparkles" })),
                    React.createElement("td", null, "More diagram templates to choose from"),
                    React.createElement("td", null),
                    React.createElement("td", null,
                        React.createElement(Tick, null)),
                    React.createElement("td", null,
                        React.createElement(Tick, null)))
                : undefined,
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-file-download", swapOpacity: true })),
                React.createElement("td", null, t('promo.download_as', { format: "JPG" })),
                React.createElement("td", null,
                    React.createElement(Tick, null)),
                React.createElement("td", null,
                    React.createElement(Tick, null)),
                React.createElement("td", null,
                    React.createElement(Tick, null))),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-file-download", swapOpacity: true })),
                React.createElement("td", null, t('promo.download_as', { format: "PNG" })),
                React.createElement("td", null,
                    React.createElement(Tick, null)),
                React.createElement("td", null,
                    React.createElement(Tick, null)),
                React.createElement("td", null,
                    React.createElement(Tick, null))),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-file-download", swapOpacity: true })),
                React.createElement("td", null, t('promo.download_as', { format: "SVG" })),
                React.createElement("td", null),
                React.createElement("td", null,
                    React.createElement(Tick, null)),
                React.createElement("td", null,
                    React.createElement(Tick, null))),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-file-image" })),
                React.createElement("td", null, t('Transparent PNG')),
                React.createElement("td", null,
                    React.createElement(Tick, null)),
                React.createElement("td", null,
                    React.createElement(Tick, null)),
                React.createElement("td", null,
                    React.createElement(Tick, null))),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-ban" })),
                React.createElement("td", null, t('No social widgets')),
                React.createElement("td", null),
                React.createElement("td", null,
                    React.createElement(Tick, null)),
                React.createElement("td", null,
                    React.createElement(Tick, null))),
            React.createElement("tr", { className: "start-section" },
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-users" })),
                React.createElement("td", null, t('promo.table.user_accounts')),
                React.createElement("td", null, "1"),
                React.createElement("td", null, "1"),
                React.createElement("td", null, props.students)),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(Icon, { icon: "fa-at" })),
                React.createElement("td", null, t("promo.school_email_sign_in")),
                React.createElement("td", null),
                React.createElement("td", null),
                React.createElement("td", null,
                    React.createElement(Tick, null)))));
};
var Icon = function (props) {
    return React.createElement("i", { className: "fa-fw fa-duotone " + props.icon + (props.swapOpacity ? " fa-swap-opacity" : "") });
};
var InlineIcon = function (props) {
    return React.createElement("i", { className: "fa fa-sm mr-1 " + props.icon });
};
var InfinityIcon = function () { return React.createElement("i", { className: "fas fa-infinity" }); };
