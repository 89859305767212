import { strokeWidth } from "apparatus/common";
import { P, R } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
import { mirrorX, setDefaultStyle } from "../../draw";
import { Segments } from "../common";
var ClayTriangle = /** @class */ (function () {
    function ClayTriangle() {
    }
    ClayTriangle.prototype.render = function (appearance) {
        var graphic = (function () {
            switch (appearance.view) {
                case "side":
                    return sideView();
                case "top":
                    return topView();
            }
        })();
        return { graphic: graphic, hitShape: graphic.bounds.toShape() };
    };
    ClayTriangle.properties = {
        label: "Clay triangle",
        defaultAppearance: {
            view: "side"
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "view", label: "View",
                options: [
                    { value: "top", label: "From top" },
                    { value: "side", label: "Side" },
                ],
            },
        ],
    };
    return ClayTriangle;
}());
export { ClayTriangle };
function createCeramic() {
    var graphic = Shape.Rectangle(R(-30, -5, 60, 10), 3.0);
    graphic.strokeColor = "#c2a690";
    graphic.fillColor = "#d9c8b9";
    graphic.strokeWidth = strokeWidth("default");
    return graphic;
}
function topView() {
    var ceramic = createCeramic();
    var wire = new Path(Segments([[66, -14]], [[40, 0]]));
    mirrorX(wire);
    setDefaultStyle(wire);
    var part = new Group([wire, ceramic]);
    // Duplicate three times in a triangle arrangement.
    var pivot = P(0, 25);
    var part2 = part.clone();
    part2.rotate(120, pivot);
    var part3 = part.clone();
    part3.rotate(-120, pivot);
    // Assemble together.
    var graphic = new Group([part, part2, part3]);
    return graphic;
}
function sideView() {
    var ceramic = createCeramic();
    var wire = new Path(Segments([[-66, 0]], [[66, 0]]));
    setDefaultStyle(wire);
    var graphic = new Group([wire, ceramic]);
    return graphic;
}
