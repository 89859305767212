import { createOutlinedGroup, mirrorX } from "apparatus/draw";
import { P, Segments, SharedColors } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
import { Color, Gradient, Path } from "paper";
var CANDLE_GRADIENT = [
    ["#deca8b", 0],
    ["#feeab8", 0.31],
    ["#fcedc9", 0.45],
    ["#deca8b", 1.0],
];
var STROKE = "#c3b68e";
var Candle = /** @class */ (function () {
    function Candle() {
    }
    Candle.prototype.render = function (appearance) {
        var width = appearance.diameter, height = appearance.height;
        var base = new Path(Segments([[width / 2, 0], [12, -3]], [[width / 2, height - 5], , [7, -2]], [[width / 2 + 5, height]]));
        mirrorX(base);
        base.close().withGradientFill("right", CANDLE_GRADIENT);
        // Create some melted drops.
        var drop = new Path(Segments([[0, 0]], [[5, 10], [0, -5], [0, 5]])).close();
        mirrorX(drop);
        drop.position = P(width / 2, 8);
        drop.fillColor = new Color(new Gradient([["#fcedc9", 0.4], ["#ddbe61", 1.0]], true), drop.bounds.center, drop.bounds.bottomRight, drop.bounds.topLeft);
        var drop2 = drop.clone();
        drop2.position = P(-width / 2, height / 2);
        var drop3 = drop.clone();
        drop3.position = P(width / 2 - 3, Math.min(height * 0.7, height - 10));
        var graphic = createOutlinedGroup("thin", STROKE, base, drop, drop2, drop3);
        // Add wick.
        graphic.insertChild(0, new Path(Segments([[0, 0], , [5, -5]], [[0, -12], [0, 5]])).withStroke("thick", SharedColors.stroke));
        // Add flame.
        if (appearance.flame) {
            graphic.addChild(SVG.redFlame().withPosition(P(0, -12)).withScale(0.75 * appearance.flameSize));
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: base.bounds.center,
            snapping: base.placeable(),
        };
    };
    Candle.properties = {
        label: "Candle",
        flippable: true,
        defaultAppearance: {
            diameter: 30,
            height: 100,
            flame: true,
            flameSize: 1.0,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 20, max: 40, unit: "mm",
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 20, max: 150, unit: "mm"
            },
            { spec: "checkbox", key: "flame", label: "Show flame" },
            {
                spec: "slider", key: "flameSize", label: "Flame size",
                min: 0.5, max: 1.3, step: 0.1, unit: "%", visible: function (a) { return a.flame; }
            }
        ],
    };
    return Candle;
}());
export { Candle };
