import { StyledTextCreator } from "apparatus/draw";
import { P, SharedColors } from "apparatus/library/common";
import { Group, Path } from "paper";
var SimpleAmmeter = /** @class */ (function () {
    function SimpleAmmeter() {
        this.textStyler = new StyledTextCreator({
            fontFamily: "sans-serif",
            fillColor: SharedColors.stroke
        });
    }
    SimpleAmmeter.prototype.render = function (appearance) {
        var circle = new Path.Circle(P(0, 0), appearance.size / 2)
            .withStroke("default").withFill("white");
        var text = this.textStyler.create("A", P(0, appearance.size * 0.35 * 0.7), { fontSize: appearance.size * 0.7 });
        var graphic = new Group([circle, text]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    SimpleAmmeter.properties = {
        label: "Ammeter",
        defaultAppearance: {
            size: 60
        },
        appearanceSpecs: [],
    };
    return SimpleAmmeter;
}());
export { SimpleAmmeter };
