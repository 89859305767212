var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { MoreShapes } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { SharedColors } from "apparatus/library/common";
import { Group } from "paper";
import { staticImplements } from "types";
var COLORS = {
    "Zinc": "#BAC4C8",
    "Silver": SharedColors.lightMetal,
    "Aluminium": SharedColors.aluminum,
    "Gold": SharedColors.gold,
    "Copper": SharedColors.copper,
    "Impure copper": SharedColors.copper.color().darken(0.2).string(),
    "Iron": SharedColors.darkMetal,
};
var Electrode = /** @class */ (function () {
    function Electrode() {
    }
    Electrode.prototype.render = function (appearance) {
        var diameter = appearance.diameter, length = appearance.length, color = appearance.color;
        var _a = MoreShapes.cylinder3d({ diameter: diameter, height: length }), body = _a.body, top = _a.top, outline = _a.outline;
        var strokeColor = color.color().darken(0.15).string();
        top.withFill(color.color().lighten(0.2).string());
        body.withGradientFill("right", Gradients.simple(color.color(), 15));
        outline.withStroke("default", strokeColor);
        var graphic = new Group([body, top, outline]);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: [
                { type: "wireport", at: top.bounds.center }
            ]
        };
    };
    Electrode.properties = {
        label: "Electrode",
        defaultAppearance: {
            diameter: 20,
            length: 140,
            color: COLORS["Silver"],
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "length", label: "Length",
                min: 50, max: 250, unit: "mm"
            },
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 10, max: 50, unit: "mm"
            },
            {
                spec: "color", key: "color", label: "Material", tier: "boost",
                demoSwatches: COLORS,
                swatches: COLORS,
            }
        ],
    };
    Electrode = __decorate([
        staticImplements()
    ], Electrode);
    return Electrode;
}());
export { Electrode };
