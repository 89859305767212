import { Pivot, R, UIColors } from "apparatus/library/common";
import { Interactable } from "editing/interaction";
import { Path } from "paper";
/**
 * Creates an interactable handle (a golden diamond) that can be dragged,
 * to adjust a property.
 */
export function createInteractionHandleFor(interaction) {
    var handleKlass = Interactable(Path.Rectangle, interaction);
    var handle = new handleKlass(R(0, 0, 12, 12, Pivot.CENTER));
    handle.rotate(45);
    handle.fillColor = UIColors.hintFill;
    handle.strokeColor = UIColors.hintOutline;
    handle.strokeWidth = 3.0;
    handle.applyMatrix = false;
    return handle;
}
