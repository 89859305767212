import { P } from "apparatus/library/common";
import { Group, Path } from "paper";
var JACK_STROKE = "#333333";
export var createJack = function (color) { return new Group([
    new Path.Circle(P(0, 0), 6).withStroke("thinner", JACK_STROKE).withFill(jackColor(color)),
    new Path.Circle(P(0, 0), 3).withFill(JACK_STROKE)
]); };
export function jackColor(color) {
    switch (color) {
        case "black": return "#414141";
        case "red": return "#f61f2f";
        case "yellow": return "#dbc222";
    }
}
