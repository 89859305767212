import { mirrorX, strokeWidth } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { hsl } from "color";
import { Group, Path, Shape } from "paper";
var NichromeLoop = /** @class */ (function () {
    function NichromeLoop() {
    }
    NichromeLoop.prototype.render = function (appearance) {
        var loop = new Path(Segments([[0, 80]], [[-0.5, 15], , [0, -5]], [[5, 5], [0, 4], [0, -3]], [[0, 0], [3, 0]]));
        mirrorX(loop);
        loop.withStroke(strokeWidth("thin"), SharedColors.mediumMetal);
        var graphic = new Group([loop]);
        if (appearance.handle) {
            var handle = Shape.Rectangle(R(0, 80, 10, 140, Pivot.TOP_CENTER), 3.0)
                .withGradientFill("right", Gradients.cylinder(hsl(39, 65, 64)))
                .withStroke(strokeWidth("thin"), hsl(39, 45, 40).string());
            graphic.addChild(handle);
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(20).toShape(),
            watermark: P(0, 80)
        };
    };
    NichromeLoop.properties = {
        label: "Nichrome Loop",
        defaultAppearance: {
            handle: true
        },
        appearanceSpecs: [
            {
                spec: "checkbox", key: "handle", label: "Handle", subLabel: "Show"
            }
        ],
    };
    return NichromeLoop;
}());
export { NichromeLoop };
