var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { breakPath, setDefaultStyle } from "apparatus/draw";
import { createApparatus } from "apparatus/library";
import { P, Pp, Segments, SharedGradients } from "apparatus/library/common";
import { t_add } from "i18n_utils";
import { Group, Path, Shape } from "paper";
/** Filter paper stroke color */
var strokeColor = "#cccccc";
/** Used by "folded" and "folded-simple". Lower results in narrower paper. */
var FOLD_DRAW_RATIO = 0.8;
var FilterFunnel = /** @class */ (function () {
    function FilterFunnel() {
    }
    FilterFunnel.prototype.render = function (appearance) {
        var ratio = 0.8;
        var height = appearance.funnelSize, stem = appearance.stemLength, stemD = appearance.stemDiameter;
        var shape = new Path(Segments([[-height * ratio, -height]], [[0, 0]], [[0, stem]], [[stemD, stem + 5]], [[stemD, 0]], [[stemD + height * ratio, -height]]));
        var hitShape = shape.clone();
        var liquidMask = shape.clone();
        var graphic = breakPath(shape, 3);
        setDefaultStyle(graphic);
        return {
            graphic: graphic, hitShape: hitShape, liquidMask: liquidMask,
            liquidDrainOpening: { start: liquidMask.segments[2].point, end: liquidMask.segments[3].point }
        };
    };
    FilterFunnel.properties = {
        label: "Filter Funnel",
        canContainLiquids: true,
        drainable: true,
        defaultAppearance: {
            funnelSize: 55,
            stemLength: 45,
            stemDiameter: 10,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "funnelSize", label: "Funnel size",
                min: 30, max: 90,
            },
            {
                spec: "slider", key: "stemLength", label: "Stem length",
                min: 30, max: 80,
            },
            {
                spec: "slider", key: "stemDiameter", label: "Stem diameter",
                min: 7, max: 35, unit: "mm"
            },
        ],
        actions: [
            {
                type: "add",
                label: function () { return t_add("filter paper"); },
                icon: "plus",
                action: function (a, p, r) {
                    var position = P(0, -12 - a.stemLength / 2 + a.stemDiameter * 0.4).rotate(r).add(Pp(p));
                    var diameter = Math.round(1.666 * a.funnelSize) + a.stemDiameter;
                    var apparatus = createApparatus("filter paper", position);
                    apparatus.rotation = r;
                    apparatus.appearance.diameter = diameter;
                    return apparatus;
                }
            }
        ]
    };
    return FilterFunnel;
}());
export { FilterFunnel };
var FilterPaper = /** @class */ (function () {
    function FilterPaper() {
    }
    FilterPaper.prototype.render = function (appearance) {
        var graphic = createGraphic(appearance);
        graphic.scale(appearance.diameter / 90);
        var hitShape = Shape.Rectangle(graphic.bounds);
        return { graphic: graphic, hitShape: hitShape };
    };
    FilterPaper.properties = {
        label: "Filter paper",
        defaultAppearance: {
            style: "folded",
            folding: "simple",
            diameter: 90.0,
            residue: false,
            residueColor: "#9c735d",
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "style", label: "View and style",
                options: [
                    { value: "top", label: "Top view" },
                    { value: "folded", label: "Folded, side view" },
                    { value: "folded-simple", label: "Simple, side view" },
                ]
            },
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 60, max: 150,
            },
            {
                spec: "checkbox", key: "residue", label: "Residue",
            },
            {
                visible: function (a) { return a.residue; },
                spec: "color", key: "residueColor", label: "Residue color",
            },
        ],
    };
    return FilterPaper;
}());
export { FilterPaper };
var FlutedFilterPaper = /** @class */ (function () {
    function FlutedFilterPaper() {
    }
    FlutedFilterPaper.prototype.render = function (appearance) {
        var graphic = createGraphic(appearance);
        graphic.scale(appearance.diameter / 90);
        var hitShape = Shape.Rectangle(graphic.bounds);
        return { graphic: graphic, hitShape: hitShape };
    };
    FlutedFilterPaper.properties = {
        label: "Fluted Filter paper",
        defaultAppearance: __assign(__assign({}, FilterPaper.properties.defaultAppearance), { folding: "fluted" }),
        appearanceSpecs: FilterPaper.properties.appearanceSpecs
    };
    return FlutedFilterPaper;
}());
export { FlutedFilterPaper };
function createGraphic(appearance) {
    switch (appearance.style) {
        case "top":
            return function () {
                var graphic = new Group();
                var filterPaper = Shape.Circle(P(0, 0), 45)
                    .withStroke("thinner", strokeColor);
                filterPaper.setRadialGradientFill(SharedGradients.filterPaper);
                graphic.addChild(filterPaper);
                if (appearance.folding == "fluted") {
                    var valleyFold = createValleyFold([[0, 0]], [[5, 45]], [[-5, 45]]);
                    graphic.addChild(valleyFold);
                    var mountainFold = createMountainFold("aa", [[0, 0]], [[5, 45]], [[-5, 45]]);
                    mountainFold.rotate(15, P(0, 0));
                    graphic.addChild(mountainFold);
                    for (var i = 1; i < 12; i++) {
                        valleyFold.clone();
                        valleyFold.rotate(30, P(0, 0));
                        mountainFold.clone();
                        mountainFold.rotate(30, P(0, 0));
                        graphic.addChild(valleyFold);
                        graphic.addChild(mountainFold);
                    }
                }
                if (!appearance.residue) {
                    return graphic;
                }
                // Add residue.
                var residue = new Path(Segments([[10, 10], [-5, -5], [6, 6]], [[-10, 10], [-5, 3], [-3, -8]], [[-10, -10], [7, 9], [-4, -11]], [[10, -10], [4, 4], [15, -5]]));
                residue.closePath();
                residue.fillColor = appearance.residueColor + "aa";
                graphic.addChild(residue);
                return graphic;
            }();
        case "folded":
            var graphic_1 = new Group();
            return function () {
                var filterPaper = new Path(Segments([[0, 45]], [[45 * FOLD_DRAW_RATIO, 0], undefined, [3, -5]], [[-45 * FOLD_DRAW_RATIO, 0], [-3, -5]]));
                filterPaper.close()
                    .withStroke("thinner", strokeColor)
                    .setGradientFill("right-down", SharedGradients.filterPaper);
                var topLine = new Path(Segments([[45 * FOLD_DRAW_RATIO, 0], undefined, [0, 5]], [[-45 * FOLD_DRAW_RATIO, 0], [0, 5]]));
                topLine.strokeColor = strokeColor;
                // Add shading for the inside.
                var insideShading = new Path(Segments([[-36, 0], [0, -5], [0, 5]], [[36, 0], [0, 5], [0, -5]]));
                insideShading.closePath();
                insideShading.fillColor = "#00001028";
                graphic_1.addChildren([filterPaper, insideShading, topLine]);
                if (appearance.folding == "fluted") {
                    var valley = createValleyFold([[0, 45]], [[-5, -5]], [[5, -5]]);
                    graphic_1.addChild(valley);
                    var mountain1 = createMountainFold("30", [[0, 45]], [[-5, -4]], [[5, -5]]);
                    mountain1.rotate(8, P(0, 45));
                    graphic_1.addChild(mountain1);
                    var valley1 = valley.clone();
                    valley1.rotate(17, P(0, 45));
                    valley1.scale(1.0, 1.03, P(0, 45));
                    var valley2 = valley.clone();
                    valley2.rotate(34, P(0, 45));
                    valley2.scale(1.0, 1.19, P(0, 45));
                    var valley_1 = valley1.clone();
                    valley1.scale(-1.0, 1.0, P(0, 45));
                    var valley_2 = valley2.clone();
                    valley2.scale(-1.0, 1.0, P(0, 45));
                    var mountain2 = mountain1.clone();
                    mountain1.rotate(17, P(0, 45));
                    mountain1.scale(1.0, 1.06, P(0, 45));
                    var mountain_1 = mountain1.clone();
                    mountain_1.scale(-1.0, 1.0, P(0, 45));
                    var mountain_2 = mountain2.clone();
                    mountain_2.scale(-1.0, 1.0, P(0, 45));
                    graphic_1.addChildren([valley1, valley2, valley_1, valley_2, mountain1, mountain2, mountain_1, mountain_2]);
                }
                else {
                    var foldedPart = new Path(Segments([[-45 * FOLD_DRAW_RATIO, 0], undefined, [3, 5]], [[-20 * FOLD_DRAW_RATIO, 8]], [[0, 45]]));
                    foldedPart.strokeColor = strokeColor;
                    graphic_1.addChild(foldedPart);
                }
                if (!appearance.residue) {
                    return graphic_1;
                }
                var residue = new Path(Segments([[0, 45]], [[16, 25], undefined, [-8, -8]], [[-16, 25], [8, 8]]));
                residue.closePath();
                residue.fillColor = appearance.residueColor + "60";
                graphic_1.addChild(residue);
                return graphic_1;
            }();
        case "folded-simple":
            return foldedSimple(appearance);
    }
}
function foldedSimple(appearance) {
    var graphic = new Group();
    var shape = new Path(Segments([[-45 * FOLD_DRAW_RATIO, 0]], [[0, 45]], [[45 * FOLD_DRAW_RATIO, 0]]));
    setDefaultStyle(shape);
    graphic.addChild(shape);
    if (appearance.residue) {
        var residue = new Path(Segments([[0, 45]], [[16, 25], undefined, [-8, -8]], [[-16, 25], [8, 8]]));
        residue.closePath();
        residue.fillColor = appearance.residueColor + "60";
        graphic.addChild(residue);
    }
    return graphic;
}
function createValleyFold() {
    var segments = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        segments[_i] = arguments[_i];
    }
    var valleyFold = new Path(Segments.apply(void 0, segments));
    valleyFold.setGradientFill("right", [["#00000000", 0.0], ["#00000015", 0.5], ["#00000000", 1.0]]);
    return valleyFold;
}
function createMountainFold(opacity) {
    var segments = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        segments[_i - 1] = arguments[_i];
    }
    var mountainFold = new Path(Segments.apply(void 0, segments));
    mountainFold.setGradientFill("right", [["#ffffff00", 0.0], ["#ffffff" + opacity, 0.5], ["#ffffff00", 1.0]]);
    return mountainFold;
}
