export var Arrays;
(function (Arrays) {
    function includes(arr, item) {
        return arr.indexOf(item) > -1;
    }
    Arrays.includes = includes;
    function last(arr) {
        return arr[arr.length - 1];
    }
    Arrays.last = last;
    function maybeExtend(arr, condition, item) {
        if (condition) {
            arr.push(item);
        }
        return arr;
    }
    Arrays.maybeExtend = maybeExtend;
    function shallowStrictEquals(a, b) {
        return a.length === b.length &&
            a.every(function (val, index) { return val == b[index]; });
    }
    Arrays.shallowStrictEquals = shallowStrictEquals;
})(Arrays || (Arrays = {}));
