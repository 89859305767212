import { setDefaultStyle, strokeWidth } from "apparatus/draw";
import { P, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path, Symbol } from "paper";
import { condenserCaseShape } from "../condensers";
var Vigreux = /** @class */ (function () {
    function Vigreux() {
    }
    Vigreux.prototype.render = function (appearance) {
        var _a = condenserCaseShape(230, 15), graphic = _a.group, snap = _a.snap;
        setDefaultStyle(graphic);
        var perf = new Symbol(perforation());
        for (var y = 40; y < 200; y += 30) {
            graphic.addChild(perf.place(P(0, y)));
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: snap,
        };
    };
    Vigreux.properties = {
        label: "Vigreux Column",
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return Vigreux;
}());
export { Vigreux };
function perforation() {
    // Straight triangles.
    var triangle1l = new Path(Segments([[-18, 0]], [[-8, 5]], [[-18, 10]]));
    var triangle1r = triangle1l.clone();
    triangle1r.scale(-1, 1, P(0, 0));
    var circle = new Path.Circle(P(0, 5), 4);
    // Bent triangles
    var triangle2l = new Path(Segments([[-18, 15]], [[-8, 25], [0, -9], [0, -6]], [[-18, 25]]));
    var triangle2r = triangle2l.clone();
    triangle2r.scale(-1, 1, P(0, 0));
    var drop = new Path(Segments([[0, 15], [-8, 0], [8, 0]], [[0, 25]])).close();
    var group = new Group([triangle1l, triangle1r, triangle2l, triangle2r, circle, drop]);
    group.withStroke(strokeWidth("thinner"), SharedColors.glassFold);
    return group;
}
