import { DEFAULT_LIQUID_DATA, P, SharedColors } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var PetriTop = /** @class */ (function () {
    function PetriTop() {
    }
    PetriTop.prototype.render = function (appearance) {
        var diameter = appearance.diameter, compartments = appearance.compartments, diffColor = appearance.diffColor;
        var colors = [appearance.color, appearance.color2, appearance.color3, appearance.color4];
        var opacities = [appearance.opacity, appearance.opacity2, appearance.opacity3, appearance.opacity4];
        var radius = diameter / 2;
        var graphic = new Group([]);
        if (compartments > 1) {
            var angleStep = 360 / compartments;
            for (var i = 0; i < compartments; i++) {
                var path = new Path();
                path.moveTo(P(0, 0));
                path.lineTo(P(0, -radius));
                var to = P(0, -radius).rotate(angleStep, P(0, 0));
                var through = P(0, -radius).rotate(angleStep / 2, P(0, 0));
                path.arcTo(through, to);
                path.close();
                path.fillColor = colors[diffColor ? i : 0].color().alpha(opacities[diffColor ? i : 0]).string();
                path.rotate(i * angleStep, P(0, 0));
                graphic.addChild(path);
            }
            for (var i = 0; i < compartments; i++) {
                var divider = new Path.Line(P(0, 0), P(0, -radius))
                    .withStroke("default", SharedColors.stroke);
                divider.rotate(i * angleStep, P(0, 0));
                graphic.addChild(divider);
            }
        }
        else {
            var fill = Shape.Circle(P(0, 0), radius);
            fill.fillColor = appearance.color.color().alpha(appearance.opacity).string();
            graphic.addChild(fill);
        }
        var outline = Shape.Circle(P(0, 0), radius).withStroke("default", SharedColors.stroke);
        graphic.addChild(outline);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            watermark: P(0, 0)
        };
    };
    PetriTop.properties = {
        label: "Petri Dish (Top view)",
        defaultAppearance: {
            compartments: 1,
            diameter: 100,
            diffColor: true,
            color: DEFAULT_LIQUID_DATA.color,
            opacity: DEFAULT_LIQUID_DATA.alpha,
            color2: SharedColors.defaultLiquid2,
            opacity2: DEFAULT_LIQUID_DATA.alpha,
            color3: SharedColors.defaultLiquid3,
            opacity3: DEFAULT_LIQUID_DATA.alpha,
            color4: "#aa66a4",
            opacity4: DEFAULT_LIQUID_DATA.alpha,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 35, max: 150, step: 5, unit: "mm",
            },
            {
                spec: "toggle-buttons", key: "compartments", label: "Compartments", size: "text",
                allowSelectingTierLockedOptions: true,
                options: [
                    { label: "1", value: 1 },
                    { label: "2", value: 2 },
                    { label: "3", value: 3, tier: "boost" },
                    { label: "4", value: 4, tier: "boost" },
                ]
            },
            {
                spec: "checkbox", key: "diffColor", label: "Colours",
                subLabel: "Customize each compartment",
                visible: function (a) { return a.compartments > 1; },
            },
            // Colours
            {
                spec: "color", key: "color", label: "Contents colour",
            },
            {
                spec: "slider", key: "opacity", label: "Contents opacity",
                min: 0.2, max: 0.9, step: 0.01, unit: "%",
            },
            {
                spec: "color", key: "color2", label: "Contents colour",
                visible: function (a) { return a.compartments > 1 && a.diffColor; }
            },
            {
                spec: "slider", key: "opacity2", label: "Contents opacity",
                min: 0.2, max: 0.9, step: 0.01, unit: "%",
                visible: function (a) { return a.compartments > 1 && a.diffColor; }
            },
            {
                spec: "color", key: "color3", label: "Contents colour",
                visible: function (a) { return a.compartments > 2 && a.diffColor; }
            },
            {
                spec: "slider", key: "opacity3", label: "Contents opacity",
                min: 0.2, max: 0.9, step: 0.01, unit: "%",
                visible: function (a) { return a.compartments > 2 && a.diffColor; }
            },
            {
                spec: "color", key: "color4", label: "Contents colour",
                visible: function (a) { return a.compartments > 3 && a.diffColor; }
            },
            {
                spec: "slider", key: "opacity4", label: "Contents opacity",
                min: 0.2, max: 0.9, step: 0.01, unit: "%",
                visible: function (a) { return a.compartments > 3 && a.diffColor; }
            },
        ],
        isBoostRequired: function (a) { return a.compartments > 2; }
    };
    return PetriTop;
}());
export { PetriTop };
