import { setDefaultStyle } from "apparatus/draw";
import { lerp, P, R } from "apparatus/library/common";
import { SharedAppearance } from "apparatus/shared/appearance";
import { Group, Path, Shape } from "paper";
var TLCPlate = /** @class */ (function () {
    function TLCPlate() {
    }
    TLCPlate.prototype.render = function (appearance) {
        var zoom = appearance.view == "zoom" ? 4 : 1;
        var baselineOffset = appearance.view == "zoom" ? 40 : 15;
        var maxFrontOffset = appearance.view == "zoom" ? 25 : 10;
        var spots = appearance.lanes;
        var widthPerSpot = 15 * zoom;
        var height = appearance.height * zoom;
        var totalWidth = spots * widthPerSpot;
        var frontOffset = lerp(height, maxFrontOffset, appearance.solventFrontAdvance);
        var plate = Shape.Rectangle(R(0, 0, totalWidth, height));
        setDefaultStyle(plate);
        plate.fillColor = "#f2f2f2";
        var baseline = new Path.Line(P(2, height - baselineOffset), P(totalWidth - 2, height - baselineOffset));
        baseline.withStroke(2.0, "#888888");
        var graphic = new Group([plate, baseline]);
        if (appearance.solventFront) {
            var solventFront = new Path.Line(P(2, frontOffset), P(totalWidth - 2, frontOffset));
            solventFront.withStroke(3.0, "#c1dcfb");
            // const soak = new Path.Rectangle(R(2, frontOffset, totalWidth - 4, height - frontOffset))
            // soak.fillColor = "#c1dcfb40"
            graphic.addChild(solventFront);
        }
        // Compute the snapping lines.
        var snapping = [];
        if (appearance.view == "zoom") {
            for (var i = 0; i < spots; i++) {
                var x = (i + 0.5) * widthPerSpot;
                snapping.push({ type: "tlc_plate", start: P(x, height - baselineOffset), end: P(x, frontOffset) });
            }
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: snapping
        };
    };
    TLCPlate.properties = {
        label: "Chromatography paper",
        defaultAppearance: {
            view: "zoom",
            height: 80,
            lanes: 1,
            solventFront: true,
            solventFrontAdvance: 1.0,
        },
        appearanceSpecs: [
            SharedAppearance.viewRealZoom,
            {
                spec: "slider", key: "height", label: "Size",
                min: 60, max: 120, unit: "mm"
            },
            {
                spec: "slider", key: "lanes", label: "Lanes",
                min: 1, max: 8, pipsStep: 1,
            },
            {
                spec: "checkbox", key: "solventFront", label: "Solvent front", subLabel: "Show",
            },
            {
                spec: "slider", key: "solventFrontAdvance", label: "Solvent front distance",
                min: 0.0, max: 1.0, step: 0.01, unit: "%", tier: "boost", visible: function (a) { return a.solventFront; }
            }
        ],
        isBoostRequired: function (a) { return a.lanes >= 4; }
    };
    return TLCPlate;
}());
export { TLCPlate };
