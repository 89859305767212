import { mirrorX, setDefaultStyle } from "apparatus/draw";
import { R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var Eye = /** @class */ (function () {
    function Eye() {
    }
    Eye.prototype.render = function (appearance) {
        // The eye is drawn facing upwards
        var width = 40;
        var halfWidth = width / 2;
        var height = 30;
        var outside = new Path(Segments([[0, 0]], [[halfWidth, height]]));
        mirrorX(outside, halfWidth);
        setDefaultStyle(outside);
        var cornea = new Path(Segments([[5, 5], undefined, [5, -5]]));
        mirrorX(cornea, halfWidth);
        setDefaultStyle(cornea);
        var lens = Shape.Ellipse(R(10, 2, width - 20, 8));
        lens.fillColor = SharedColors.stroke;
        var graphic = new Group([outside, cornea, lens]);
        if (appearance.goggles) {
            var goggles = new Path(Segments([[0, -6], [7, -6], [5, -10]])).withFill("#307ed1").close();
            mirrorX(goggles, halfWidth);
            graphic.addChild(goggles);
        }
        var pivot = outside.bounds.center;
        var hitShapes = [graphic.bounds.toShape()];
        // Line of sight
        var lineOfSight = appearance.line;
        if (lineOfSight > 0) {
            var line = new Path(Segments([[halfWidth, 0]], [[halfWidth, -lineOfSight]]));
            line.strokeColor = "#808080";
            line.strokeWidth = 2.0;
            line.dashArray = [10, 12];
            graphic.addChild(line);
            hitShapes.push(Shape.Rectangle(R(halfWidth - 10, -lineOfSight, 20, lineOfSight)));
        }
        return { graphic: graphic, hitShape: hitShapes, pivot: pivot };
    };
    Eye.properties = {
        label: "Eye",
        defaultAppearance: {
            line: 0,
            goggles: false,
        },
        appearanceSpecs: [
            {
                spec: "slider", label: "Line of sight", key: "line",
                min: 0, max: 600, step: 10,
            },
            { spec: "checkbox", label: "Safety goggles", key: "goggles", subLabel: "Show" },
        ],
        defaultRotation: 90
    };
    return Eye;
}());
export { Eye };
