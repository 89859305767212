import { setDefaultStyle } from "apparatus/draw";
import { P, Segments } from "apparatus/library/common";
import { Group, Path, PointText } from "paper";
var Ramp = /** @class */ (function () {
    function Ramp() {
        this.shouldUseBoundsCenter = true;
    }
    Ramp.prototype.render = function (appearance) {
        var lengthM = appearance.length, angle = appearance.angle;
        var length = lengthM * 1000;
        var height = length * Math.sin(angle.toRadians());
        var width = length * Math.cos(angle.toRadians());
        var ramp = new Path(Segments([[0, 0]], [[width, height]], [[0, height]])).close()
            .withStroke("default")
            .withFill("#aaaaaa");
        setDefaultStyle(ramp);
        var pivot = P(width, height);
        var graphic;
        if (appearance.showLabel) {
            graphic = new Group([ramp]);
            // Calculate where to place the label
            var labelRadius = Math.max(70, 30 / Math.tan(angle.toRadians()));
            var start = P(width - labelRadius, height);
            var mid = start.rotate(angle / 2, pivot);
            var end = start.rotate(angle, pivot);
            graphic.addChild(new Path.Arc(start, mid, end).withStroke("default", "black"));
            var label = new PointText({
                point: start.add(P(-20, 0)).rotate(angle / 2, pivot).add(P(0, 5)),
                content: angle + "\u00B0",
                fontFamily: 'sans-serif',
                fontSize: 15,
                fillColor: "black",
                justification: "center"
            });
            if (appearance.flipped) {
                label.scale(-1, 1);
            }
            graphic.addChild(label);
        }
        else {
            graphic = ramp;
        }
        if (appearance.flipped) {
            graphic.scale(-1, 1, pivot);
        }
        var hitShape = ramp.clone();
        return {
            graphic: graphic,
            hitShape: hitShape,
            pivot: pivot,
            snapping: [
                { type: "placeable_surface", start: P(0, 0), end: P(width, height), facing: "auto" },
                { type: "placeable", at: ramp.strokeBounds.bottomCenter }
            ]
        };
    };
    Ramp.properties = {
        label: "Ramp",
        defaultAppearance: {
            length: 1.0,
            angle: 25,
            showLabel: true,
            flipped: false,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "length", label: "Length",
                min: 0.5, max: 2.5, step: 0.05, unit: "m"
            },
            {
                spec: "slider", key: "angle", label: "Angle",
                min: 5, max: 45, step: 1, unit: "°",
            },
            {
                spec: "checkbox-group", label: "Options", checkboxes: [
                    { key: "showLabel", label: "Label incline" },
                    { key: "flipped", label: "Flip horizontally" },
                ]
            }
        ],
    };
    return Ramp;
}());
export { Ramp };
