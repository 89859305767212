import { setItemsRotation } from "store/actions";
import { appStore } from "store/store";
var RotateItems = /** @class */ (function () {
    function RotateItems(values) {
        var _this = this;
        this.executeRotations = {};
        this.undoRotations = {};
        values.forEach(function (value) {
            _this.executeRotations[value.id.id] = value.rotation;
            _this.undoRotations[value.id.id] = value.originalRotation;
        });
    }
    RotateItems.prototype.execute = function () {
        appStore.dispatch(setItemsRotation(this.executeRotations));
    };
    RotateItems.prototype.undo = function () {
        appStore.dispatch(setItemsRotation(this.undoRotations));
    };
    return RotateItems;
}());
export { RotateItems };
