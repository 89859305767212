import { mirrorY } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { uniteAll } from "../../draw";
var DEMO_SWATCHES = {
    'Black': "#555555",
    'Blue': '#307ed1',
    'Red': '#e33d4d',
    'Green': '#3ac433',
};
var FeltTipPen = /** @class */ (function () {
    function FeltTipPen() {
    }
    FeltTipPen.prototype.render = function (appearance) {
        var color = appearance.color, scale = appearance.scale;
        var body = new Path.Rectangle(R(4, 0, 130, 20, Pivot.CENTER_RIGHT), 4.0)
            .withGradientFill("down", Gradients.cylinder("#dddddd".color())).close();
        var top = new Path(Segments([[-1, 10]], [[6, 10]], [[10, 6]], [[18, 6]]));
        mirrorY(top);
        top.close()
            .withGradientFill("down", Gradients.cylinder(color.color().ladd(0.1)));
        var tip = new Path(Segments([[17, -5], , [6, 0]], [[28, 0]], [[17, 5], [6, 0]])).withGradientFill("down", Gradients.simple(color.color(), 15));
        var outline = uniteAll(body, top, tip).withStroke("thinner");
        var graphic = new Group([body, top, tip, outline]);
        graphic.scale(scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(5).toShape()
        };
    };
    FeltTipPen.properties = {
        label: "Felt Tip Pen",
        defaultAppearance: {
            color: DEMO_SWATCHES['Red'],
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.8, max: 2.0, step: 0.1, unit: "%"
            },
            {
                spec: "color", key: "color", label: "Colour",
                swatches: DEMO_SWATCHES,
                demoSwatches: DEMO_SWATCHES,
                tier: "boost",
            }
        ],
    };
    return FeltTipPen;
}());
export { FeltTipPen };
