var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { g } from "analytics";
import { apparatusClass } from "apparatus/library";
import { AddApparatus, DeleteApparatus, DeselectAndSelect, Flip, UpdateAppearanceProperties } from "command/basic";
import { commandProcessor, Sequence } from "command/command";
import { chemMarkPropsSection, convertAppearanceSpecs } from "panel/appearance_spec_converter";
import { createLiquidPropsSection } from "panel/liquid_panel_ui";
import { createOrderingButtons } from "panel/ordering_ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { isItemUnlocked } from "tiers/apparatus";
import { Badge } from "tiers/badge";
import { RotateItems } from "../editing/rotation/rotate_command";
export function sectionsForOneApparatus(item, app, tiers, selected) {
    var sections = [];
    var apparatusKlass = apparatusClass(item.type);
    // Obtain the appearance spec.
    var appearanceSpecs = apparatusKlass.properties.appearanceSpecs;
    // Convert to element props for building UI.
    var elements = convertAppearanceSpecs(appearanceSpecs, item, [item], app, tiers);
    // Check if this item is allowed. If not show an upgrade banner.
    if (!isItemUnlocked(item, tiers.tier)) {
        elements.unshift(React.createElement(UpgradeHint, { key: "UpgradeHint", onClick: function () { return tiers.popup(item.type); } }));
    }
    sections.push({
        header: "Style",
        icon: "sliders-simple",
        hideHeaderIfFirst: true,
        elements: elements
    });
    // Does the apparatus support ChemMark?
    if (apparatusKlass.properties.chemMarkAppearanceKey) {
        sections.push(chemMarkPropsSection([item], apparatusKlass.properties.chemMarkAppearanceKey));
    }
    // Custom action buttons.
    if (apparatusKlass.properties.actions) {
        var buttons = apparatusKlass.properties.actions.map(function (action) {
            return {
                buttonLabel: action.label,
                icon: action.icon,
                onClick: function () {
                    g('AppearanceAction', item.type + "::" + action.label);
                    var command = getCommandForAction(action, item, app);
                    commandProcessor.execute(command);
                }
            };
        });
        sections.push({
            header: "Assistant",
            icon: "wand-magic-sparkles",
            elements: [{
                    spec: "vertical-buttons",
                    key: "custom-actions",
                    label: "Actions",
                    buttons: buttons
                }]
        });
    }
    var toolSectionElements = [];
    var appearanceButtons = [];
    if (apparatusKlass.properties.appearanceSpecs.length > 0) {
        appearanceButtons.push({
            buttonLabel: "Reset to default", icon: "undo",
            onClick: function () {
                var defaultAppearance = apparatusKlass.properties.defaultAppearance;
                var currentAppearance = item.appearance;
                // If the appearances are the same, don't execute.
                if (JSON.stringify(defaultAppearance) == JSON.stringify(currentAppearance)
                    && !item.flipped
                    && item.rotation == 0)
                    return;
                g("UpdateAppearance", item.type + "::resetAppearance");
                var commands = [
                    new RotateItems([{ id: item.id, rotation: 0, originalRotation: item.rotation }]),
                    new UpdateAppearanceProperties(item.id, defaultAppearance, currentAppearance),
                ];
                if (item.flipped) {
                    // Must be first otherwise it overwrites rotation.
                    commands.unshift(new Flip(item));
                }
                commandProcessor.execute(new Sequence(commands));
            },
        });
    }
    if (apparatusKlass.properties.flippable) {
        appearanceButtons.push({
            buttonLabel: "Flip horizontally", icon: "arrows-alt-h",
            onClick: function () {
                g("UpdateAppearance", item.type + "::flipHorizontally");
                commandProcessor.execute(new Flip(item, { adjustRotation: true, vertical: false }));
            },
        }, {
            buttonLabel: "Flip vertically", icon: "arrows-alt-v",
            onClick: function () {
                g("UpdateAppearance", item.type + "::flipVertically");
                commandProcessor.execute(new Flip(item, { adjustRotation: true, vertical: true }));
            },
        });
    }
    if (appearanceButtons.length > 0) {
        toolSectionElements.push({
            spec: "vertical-buttons",
            key: "appearance",
            label: "Appearance",
            buttons: appearanceButtons
        });
    }
    toolSectionElements.push(createOrderingButtons([item.id]));
    sections.push({
        header: "Tools",
        icon: "wrench",
        elements: toolSectionElements
    });
    if (selected.shouldAllowRegrouping) {
        sections.push({
            header: "Grouping",
            icon: "object-group",
            elements: [
                {
                    spec: "button",
                    key: "regroupObjects",
                    label: "Regroup object",
                    buttonLabel: "Regroup object",
                    icon: "object-group",
                    onClick: function () {
                        var command = selected.createRegroupObjectsCommand();
                        if (command) {
                            g("Grouping", "Regroup");
                            commandProcessor.execute(command);
                        }
                    }
                }
            ]
        });
    }
    var props = {
        title: item.type,
        icon: "flask",
        tabs: [
            {
                type: "property",
                sections: sections,
            },
        ]
    };
    // If the apparatus can contain liquids, add options for those.
    if (apparatusKlass.properties.canContainLiquids && item.liquid) {
        props.tabs.push({
            type: "liquid",
            colorDot: item.liquid.color,
            sections: createLiquidPropsSection(item, item.liquid, app, selected.isItemsSupportingMeniscus, tiers)
        });
    }
    return props;
}
function getCommandForAction(action, item, app) {
    switch (action.type) {
        case "appearance": {
            var newAppearance = action.action(item.appearance);
            return new UpdateAppearanceProperties(item.id, newAppearance, item.appearance);
        }
        case "replace": {
            var newItem = action.action(item.appearance, { x: item.x, y: item.y });
            return new Sequence([
                new DeleteApparatus([item]),
                new AddApparatus([newItem]),
                new DeselectAndSelect({ select: [newItem.id], deselect: [item.id] })
            ]);
        }
        case "add": {
            // Look up object.
            var object = app.getApparatusComponent(item.id);
            var anchors = void 0;
            if (object && object.anchors) {
                var o_1 = object;
                anchors = object.anchors.map(function (a) { return (__assign(__assign({}, a), { at: o_1.localToGlobal(a.at) })); });
            }
            else {
                anchors = [];
            }
            var extras = {
                anchors: anchors
            };
            var newItem = action.action(item.appearance, { x: item.x, y: item.y }, item.rotation, extras);
            return new Sequence([
                new AddApparatus([newItem]),
            ]);
        }
    }
}
/**
 * Element shown if the user does not have a subscription for given
 * item.
 */
var UpgradeHint = function (props) {
    var t = useTranslation().t;
    return React.createElement("div", { className: "boxed-hint border-success text-success mt-2" },
        t("promo.apparatus_hint"),
        " ",
        React.createElement(Badge, { tier: "boost", hideHint: true }),
        React.createElement("button", { className: "ui-button highlight text-center mt-1", onClick: props.onClick }, t('Upgrade')));
};
