// Utilities for ensuring an uniform visual style across all apparatus.
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { strokeWidth as commonStrokeWidth } from "apparatus/common";
import * as color from "color";
import * as paper from "paper";
import { Path } from "paper";
import * as common from "./library/common";
import { markerSize, P, Pivot, R, Segments, SharedColors } from "./library/common";
var MIN_BOUNDS = 40;
export var defaultStrokeWidth = 4.0;
/** Sets the default style. */
export function setDefaultStyle() {
    var items = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        items[_i] = arguments[_i];
    }
    setDefaultStyleWithStroke.apply(void 0, __spreadArray(["default"], items));
}
/**
 * Breaks a continuous path into multiple parts and returns them as a group.
 * Note this does not transfer any styles (e.g. stroke colour or width).
 * It is therefore recommended to break a path that has not yet been
 * styled. It also deletes the original path.
 */
export function breakPath(path) {
    var indices = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        indices[_i - 1] = arguments[_i];
    }
    return breakPathInternal.apply(void 0, __spreadArray([path, false], indices));
}
/** Same as breakPath, but the path is closed. */
export function breakPathClosed(path) {
    var indices = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        indices[_i - 1] = arguments[_i];
    }
    return breakPathInternal.apply(void 0, __spreadArray([path, true], indices));
}
function breakPathInternal(path, closePath) {
    var indices = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        indices[_i - 2] = arguments[_i];
    }
    var newGroup = new paper.Group();
    var previousIndex = 0;
    for (var _a = 0, indices_1 = indices; _a < indices_1.length; _a++) {
        var index = indices_1[_a];
        newGroup.addChild(new paper.Path(path.segments.slice(previousIndex, index)));
        previousIndex = index;
    }
    // Add the final segment.
    var lastSegment = new paper.Path(path.segments.slice(previousIndex, path.segments.length));
    if (closePath) {
        lastSegment.segments.push(path.segments[0]);
    }
    newGroup.addChild(lastSegment);
    // Delete the original path.
    path.remove();
    return newGroup;
}
/** Sets the default style but with a custom stroke width. */
export function setDefaultStyleWithStroke(style) {
    var items = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        items[_i - 1] = arguments[_i];
    }
    for (var _a = 0, items_1 = items; _a < items_1.length; _a++) {
        var item = items_1[_a];
        item.strokeColor = common.SharedColors.stroke;
        item.strokeWidth = strokeWidth(style);
        item.strokeCap = "round";
    }
}
export function strokeWidth(style) {
    return commonStrokeWidth(style);
}
function addEdgeLipsInternal(path, lipSize, reverse, spout) {
    addEdgeLipsInternalOpen(path, lipSize, reverse, spout);
}
// @ts-ignore: alternative edge lips implementation.
function addEdgeLipsInternalClosed(path, lipSize, reverse) {
    var lipAnchor = P(-1.0, 0).multiply(lipSize);
    var lipRaise = -lipSize * 0.8;
    var left = !reverse ? path.firstSegment : path.lastSegment;
    var leftLip = new paper.Segment(P(left.point.x, left.point.y), !reverse ? undefined : lipAnchor, !reverse ? lipAnchor : undefined);
    left.point.y += 10;
    left[!reverse ? "handleIn" : "handleOut"] = lipAnchor.add([0, lipRaise]);
    var rightLipAnchor = lipAnchor.multiply([-1, 1]);
    var right = !reverse ? path.lastSegment : path.firstSegment;
    var rightLip = new paper.Segment(P(right.point.x, right.point.y), !reverse ? rightLipAnchor : undefined, !reverse ? undefined : rightLipAnchor);
    right.point.y += 10;
    right[reverse ? "handleIn" : "handleOut"] = rightLipAnchor.add([0, lipRaise]);
    path.insertSegments(0, !reverse ? [leftLip] : [rightLip]);
    path.addSegments(!reverse ? [rightLip] : [leftLip]);
    path.closed = true;
}
// @ts-ignore: alternative edge lips implementation.
function addEdgeLipsInternalOpen(path, lipSize, reverse, spout) {
    var lipAnchor = P(0.6, 0.5).multiply(lipSize);
    var left = !reverse ? path.firstSegment : path.lastSegment;
    var leftLip = new paper.Segment(new paper.Point(left.point.x - lipSize, left.point.y), !reverse ? undefined : lipAnchor, !reverse ? lipAnchor : undefined);
    left.point.y += lipSize * 2.5;
    left[!reverse ? "handleIn" : "handleOut"] = P(0, -6);
    var rightLip;
    var right = !reverse ? path.lastSegment : path.firstSegment;
    if (spout) {
        var rightLipAnchor_1 = P(-2, 0);
        rightLip = new paper.Segment(new paper.Point(right.point.x + lipSize * 3.5, right.point.y), !reverse ? rightLipAnchor_1 : undefined, !reverse ? undefined : rightLipAnchor_1);
        right.point.y += lipSize * 4.5;
        right[reverse ? "handleIn" : "handleOut"] = P(0, -3);
    }
    else {
        var rightLipAnchor = lipAnchor.multiply([-1, 1]);
        rightLip = new paper.Segment(new paper.Point(right.point.x + lipSize, right.point.y), !reverse ? rightLipAnchor : undefined, !reverse ? undefined : rightLipAnchor);
        right.point.y += lipSize * 2.5;
        right[reverse ? "handleIn" : "handleOut"] = P(0, -6);
    }
    path.insertSegments(0, !reverse ? [leftLip] : [rightLip]);
    path.addSegments(!reverse ? [rightLip] : [leftLip]);
}
/** Add "lips" to the apparatus. Use on e.g beakers, test tubes etc. */
export function addEdgeLips(path, opts) {
    var _a, _b;
    addEdgeLipsInternal(path, (_a = opts === null || opts === void 0 ? void 0 : opts.lipSize) !== null && _a !== void 0 ? _a : common.lipSize, false, (_b = opts === null || opts === void 0 ? void 0 : opts.spout) !== null && _b !== void 0 ? _b : false);
}
export function addEdgeLipsReversed(path, opts) {
    var _a;
    addEdgeLipsInternal(path, (_a = opts === null || opts === void 0 ? void 0 : opts.lipSize) !== null && _a !== void 0 ? _a : common.lipSize, true, false);
}
/** Mirrors existing segments, along an x= line. */
export function mirrorX(path, x) {
    if (x === void 0) { x = 0; }
    var newSegments = path.segments.map(function (segment) {
        return new paper.Segment(segment.point.multiply([-1, 1]).add([x * 2, 0]), segment.handleOut ? segment.handleOut.multiply([-1, 1]) : undefined, segment.handleIn ? segment.handleIn.multiply([-1, 1]) : undefined);
    });
    newSegments.reverse();
    path.addSegments(newSegments);
}
/** Extrudes existing path segments and adds them to the path. */
export function extrude(path, direction) {
    var newSegments = path.segments.map(function (segment) {
        return new paper.Segment(segment.point.add(direction), segment.handleOut ? segment.handleOut : undefined, segment.handleIn ? segment.handleIn : undefined);
    });
    newSegments.reverse();
    path.addSegments(newSegments);
}
/** Mirrors existing segments, along an y= line. */
export function mirrorY(path, y) {
    if (y === void 0) { y = 0; }
    var newSegments = path.segments.map(function (segment) {
        return new paper.Segment(segment.point.multiply([1, -1]).add([0, y * 2]), segment.handleOut ? segment.handleOut.multiply([1, -1]) : undefined, segment.handleIn ? segment.handleIn.multiply([1, -1]) : undefined);
    });
    newSegments.reverse();
    path.addSegments(newSegments);
}
/**
 * Smoothes out a corner into a rounded corner. Works for
 * all corners, not just 90 degree ones.
 */
export function smoothCorner(segment, size) {
    if (size === void 0) { size = common.cornerSize; }
    // Determine directions to which to shift the points
    // to create a rounded corner.
    var previousVector = segment.previous.point.subtract(segment.point).normalize();
    var nextVector = segment.next.point.subtract(segment.point).normalize();
    // Create new points to replace this segment.
    var segment1 = new paper.Segment(segment.point.add(previousVector.multiply(size)), undefined, new paper.Point(previousVector.multiply(-size * 0.5)));
    var segment2 = new paper.Segment(segment.point.add(nextVector.multiply(size)), new paper.Point(nextVector.multiply(-size * 0.5)));
    // Find position of the current segment in the path.
    var path = segment.path;
    var segmentIndexToRemove = findSegmentIndexInPath(segment);
    path.removeSegment(segmentIndexToRemove);
    path.insertSegments(segmentIndexToRemove, [segment1, segment2]);
}
export function smoothCornersIndividual(path, corners) {
    var indices = Object.keys(corners).map(Number);
    var sortedIndices = indices.sort(function (a, b) { return a - b; }).reverse();
    for (var _i = 0, sortedIndices_1 = sortedIndices; _i < sortedIndices_1.length; _i++) {
        var index = sortedIndices_1[_i];
        smoothCorner(path.segments[index], corners[index]);
    }
}
export function removeLastSegment(path) {
    path.removeSegment(path.segments.length - 1);
}
function findSegmentIndexInPath(segment) {
    var path = segment.path;
    for (var i = 0; i < path.segments.length; i++) {
        if (segment == path.segments[i]) {
            return i;
        }
    }
    return -1;
}
/** Adds markers (from the bottom) at regular intervals. The first one is skipped. */
export function addMarkers(group, start, stopY, step, size) {
    if (step.y == 0)
        return;
    // If the step is specified as positive, invert it.
    if (step.y > 0) {
        step.x *= -1;
        step.y *= -1;
    }
    var point = start.add(step); // Skip the first one, as it's usually the bottom of the apparatus.
    var mark = markSymbol();
    var count = 0;
    while (point.y > stopY && count < 80) {
        var markPlaced = mark.place(point);
        if (size) {
            markPlaced.scale(size / markerSize, 1.0, markPlaced.bounds.rightCenter);
        }
        group.addChild(markPlaced);
        point = point.add(step);
        count++;
    }
}
export function addMarkers2(group, opts) {
    var _a;
    var start = opts.start, step = opts.step, count = opts.count;
    var majorEvery = (_a = opts.majorEvery) !== null && _a !== void 0 ? _a : 1;
    var mark = markSymbol();
    var point = new paper.Point(start);
    for (var i = 0; i < count; i++) {
        var isMajor = i % majorEvery === 0;
        var symbol = group.addChild(mark.place(point));
        if (!isMajor) {
            symbol.scale(0.5, 1.0);
        }
        point.x += step.x;
        point.y += step.y;
    }
}
/** A u-shape visual hint to indicate 3-D-ness. */
export function glassFold(x, y, props) {
    var _a, _b;
    var size = (_a = props === null || props === void 0 ? void 0 : props.size) !== null && _a !== void 0 ? _a : 10;
    var rotation = (_b = props === null || props === void 0 ? void 0 : props.rotation) !== null && _b !== void 0 ? _b : 0;
    var fold = new paper.Path(Segments([[-0.5 * size, 0], , [0.2 * size, 0.2 * size]], [[0.5 * size, 0], [-0.2 * size, 0.2 * size]]));
    fold.rotate(rotation);
    fold.strokeWidth = strokeWidth("thinner");
    fold.position = P(x, y);
    fold.strokeColor = SharedColors.glassFold;
    return fold;
}
var _markSymbol;
export function markSymbol() {
    if (_markSymbol)
        return _markSymbol;
    // Create mark
    var mark = paper.Shape.Rectangle(P(-markerSize, 0), new paper.Size(markerSize, strokeWidth("thinner")));
    mark.fillColor = SharedColors.mark;
    _markSymbol = new paper.Symbol(mark, true);
    return _markSymbol;
}
export function disposeMarkSymbol() {
    if (_markSymbol)
        _markSymbol.definition.remove();
    _markSymbol = undefined;
}
export function metalRod(from, to) {
    var normal = from.subtract(to).rotate(90).normalize().multiply(2.0);
    var rod = new paper.Path.Line(from, to);
    rod.strokeWidth = 12.0;
    rod.strokeColor = new paper.Color(new paper.Gradient([
        [common.SharedColors.darkMetal.color().ladd(20).hex(), 0.0],
        [common.SharedColors.darkMetal.color().ladd(10).hex(), 1.0]
    ]), from.add(normal), from.subtract(normal));
    rod.strokeCap = "round";
    var rodHighlight = new paper.Path.Line(from.add(normal), to.add(normal));
    rodHighlight.strokeWidth = 2.0;
    rodHighlight.strokeColor = "#aaaaaa";
    rodHighlight.strokeCap = "round";
    return [rod, rodHighlight];
}
// Creates a simple gradient from a single color.
export function simpleGradient(colorString) {
    var baseColor = color(colorString);
    var lightColor = baseColor.lighten(0.3);
    return [[lightColor.hex(), 0.0], [baseColor.hex(), 1.0]];
}
/** Returns a unit vector pointing in given direction. */
export function unitVector(direction) {
    switch (direction) {
        case "down": return P(0, 1);
        case "up": return P(0, -1);
        case "left": return P(-1, 0);
        case "right": return P(1, 0);
    }
}
/**
 * Creates a new path, containing all the paths, united. The ordering should ensure
 * that at every step a single shape is created.
 */
export function uniteAll() {
    var items = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        items[_i] = arguments[_i];
    }
    var path = items[0].unite(items[1], { insert: false });
    for (var i = 2; i < items.length; i++) {
        var newPath = path.unite(items[i], { insert: false });
        path.remove();
        path = newPath;
    }
    path.fillColor = null;
    return path;
}
export function createOutlinedGroup(width, strokeColor) {
    var items = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        items[_i - 2] = arguments[_i];
    }
    var group = new paper.Group(items);
    var outline = uniteAll.apply(void 0, items);
    outline.strokeColor = strokeColor;
    outline.strokeWidth = typeof (width) === "string" ? strokeWidth(width) : width;
    group.addChild(outline);
    return group;
}
export function setLiquidStyle(item, color, opacity) {
    if (opacity === void 0) { opacity = SharedColors.defaultLiquidAlpha; }
    item.strokeWidth = strokeWidth("liquid");
    item.strokeColor = color;
    item.fillColor = color.color().alpha(opacity).string();
}
/**
 * Creates a soft-shaded effect. It can either be "edge" (one-sided, used for edges) or "full"
 * for internal shading. Best described as a "blurred pill" shaped.
 *
 * Specify offsetThickness to offset "edge" shades by the thickness
 */
export function softShade(type, offsetThickness, from, to, color, radius, strength) {
    if (strength === void 0) { strength = 0.0; }
    var delta = from.subtract(to);
    var length = delta.length;
    // Clamp radius.
    radius = Math.min(radius, length / 2 - 1);
    var width = type == "edge" ? radius : radius * 2;
    // Render a group of 3 parts.
    var gradient = [[color, strength], [color.substr(0, 7) + "00", 1.0]];
    var topPart = new paper.Path.Rectangle(R(radius, 0, width, radius, common.Pivot.TOP_RIGHT));
    topPart.fillColor = new paper.Color(new paper.Gradient(gradient, true), P(0, radius), P(0, 0));
    var middlePart = new paper.Path.Rectangle(R(radius, radius, width, length - 2 * radius, common.Pivot.TOP_RIGHT));
    if (type == "edge") {
        middlePart.withGradientFill("right", gradient);
    }
    else {
        middlePart.withGradientFill("right", [
            [color.substr(0, 7) + "00", 0.0], [color, 0.5 - strength / 2], [color, 0.5 + strength / 2], [color.substr(0, 7) + "00", 1.0],
        ]);
    }
    var bottomPart = new paper.Path.Rectangle(R(radius, length, width, radius, common.Pivot.BOTTOM_RIGHT));
    bottomPart.fillColor = new paper.Color(new paper.Gradient(gradient, true), P(0, length - radius), P(0, length));
    var group = new paper.Group([topPart, middlePart, bottomPart]);
    group.pivot = P(0, 0);
    group.rotate(delta.angle - 90);
    group.position = to;
    // Offset by thickness.
    if (offsetThickness && type == "edge") {
        var distance = strokeWidth(offsetThickness) / 2;
        group.position = group.position.add(delta.rotate(-90).normalize(distance));
    }
    return group;
}
var StyledTextCreator = /** @class */ (function () {
    function StyledTextCreator(style) {
        this.style = style;
    }
    StyledTextCreator.prototype.create = function (content, position, override, align) {
        if (override === void 0) { override = {}; }
        if (align === void 0) { align = "center"; }
        return new paper.PointText(__assign(__assign({ point: position, content: content, style: this.style }, override), { justification: align }));
    };
    return StyledTextCreator;
}());
export { StyledTextCreator };
export var MoreShapes;
(function (MoreShapes) {
    function diamond(x, y, size) {
        var path = new paper.Path(Segments([[-size / 2, 0]], [[0, size / 2]], [[size / 2, 0]], [[0, -size / 2]]));
        path.position.x = x;
        path.position.y = y;
        path.closePath();
        return path;
    }
    MoreShapes.diamond = diamond;
    function cylinder3d(opts) {
        var diameter = opts.diameter, height = opts.height;
        var top = new Path.Ellipse(R(0, 0, diameter, diameter / 2, Pivot.CENTER));
        var bottom = top.clone();
        top.position.y -= height / 2;
        bottom.position.y += height / 2;
        var body = new Path.Rectangle(R(0, 0, diameter, height, Pivot.CENTER));
        var body3d = body.unite(bottom);
        var outline = body.unite(bottom).unite(top);
        bottom.remove();
        body.remove();
        return { body: body3d, top: top, outline: outline };
    }
    MoreShapes.cylinder3d = cylinder3d;
    /** Draw the outside only. Remove top. */
    function cylinder3d_shell(opts) {
        var diameter = opts.diameter, height = opts.height;
        var top = new Path.Ellipse(R(0, 0, diameter, diameter / 2, Pivot.CENTER));
        var bottom = top.clone();
        top.position.y -= height / 2;
        bottom.position.y += height / 2;
        var body = new Path.Rectangle(R(0, 0, diameter, height, Pivot.CENTER));
        var bodyAndBottom = body.unite(bottom);
        var finalShape = bodyAndBottom.subtract(top);
        body.remove();
        bodyAndBottom.remove();
        return { body: finalShape };
    }
    MoreShapes.cylinder3d_shell = cylinder3d_shell;
})(MoreShapes || (MoreShapes = {}));
var LazyRaster = /** @class */ (function () {
    function LazyRaster(url) {
        this.url = url;
    }
    LazyRaster.prototype.clone = function (callback) {
        if (!this.raster) {
            var raster_1 = new paper.Raster(this.url);
            this.raster = raster_1;
            raster_1.onLoad = function () {
                callback(raster_1.clone());
            };
            // Remove from scene.
            this.raster.remove();
        }
        else {
            callback(this.raster.clone());
        }
    };
    return LazyRaster;
}());
export { LazyRaster };
export function minBounds(item) {
    var bounds = item.strokeBounds;
    var diff = MIN_BOUNDS - Math.min(bounds.height, bounds.width);
    if (diff > 0) {
        bounds = bounds.expand(diff);
    }
    return bounds.toShape();
}
/**
 * Creates a ground joint inlet.
 *  - Assumes the left side is index and right side is index + 1 (when facing up)
 *  - 0 is up, 90 is right
 *  - facing not yet implemented.
 */
export function addGroundJoint(path, _a) {
    var type = _a.type, index = _a.index, facing = _a.facing, _b = _a.tighten, tighten = _b === void 0 ? 0 : _b;
    // Points on existing path.
    var left = path.segments[index];
    var right = path.segments[index + 1];
    // Paths for the ground joint.
    var leftSide = createGroundJointShape(type, tighten);
    var rightSide = common.cloneSegments(leftSide);
    // Move them in place.
    common.rotateSegments(leftSide, P(0, 0), facing);
    common.moveSegments(leftSide, left.point);
    common.mirrorSegmentsX(rightSide);
    common.rotateSegments(rightSide, P(0, 0), facing);
    common.moveSegments(rightSide, right.point);
    path.insertSegments(index + 1, __spreadArray(__spreadArray([], leftSide), rightSide));
    var midPoint = left.point.add(right.point).divide(2);
    return { snapping: { type: "connectable", at: midPoint.add(P(0, -18).rotate(facing)), facing: facing } };
}
function createGroundJointShape(type, tighten) {
    switch (type) {
        case "inlet": return Segments([[2, -3], [-(2), 0]], [[-1, -25]]);
        case "outlet": return Segments([[-2, -3], [0, 3]], [[1, -25]]);
    }
}
