import { P } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
var Snail = /** @class */ (function () {
    function Snail() {
    }
    Snail.prototype.render = function (appearance) {
        var allBugs = SVG.bugs();
        var graphic = allBugs.children[11].clone();
        graphic.scale(appearance.scale);
        allBugs.remove();
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            watermark: graphic.bounds.bottomRight.add(P(-5, -5).multiply(appearance.scale)),
        };
    };
    Snail.properties = {
        label: "Snail",
        flippable: true,
        defaultAppearance: {
            type: "0,0",
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.5, max: 3, step: 0.01, unit: "%"
            },
        ],
    };
    return Snail;
}());
export { Snail };
