import { R } from "apparatus/library/common";
import { Shape } from "paper";
var Splint = /** @class */ (function () {
    function Splint() {
    }
    Splint.prototype.render = function (appearance) {
        var width = appearance.width;
        var length = appearance.length;
        var graphic = Shape.Rectangle(R(0, 0, width, length), width / 2)
            .withFill("#ddc292")
            .withStroke("thinner", "#b69660");
        return { graphic: graphic, hitShape: graphic.bounds.expand(15).toShape() };
    };
    Splint.properties = {
        label: "Wooden Splint",
        defaultAppearance: {
            length: 140,
            width: 8
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "length", label: "Length",
                min: 100, max: 180
            },
            {
                spec: "slider", key: "width", label: "Width",
                min: 5, max: 12,
            }
        ],
    };
    return Splint;
}());
export { Splint };
