import { P, S, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var ARC_SIZE = 6;
var Colors;
(function (Colors) {
    Colors.snapOutline = "#68a343";
    Colors.snapFill = "#fc03c2";
})(Colors || (Colors = {}));
export function visualiseSnappings(layer, snappings, filter) {
    for (var _i = 0, snappings_1 = snappings; _i < snappings_1.length; _i++) {
        var snapping = snappings_1[_i];
        if (filter.length == 0 || filter.indexOf(snapping.type) !== -1) {
            layer.addChild(visualiseSnapping(snapping));
        }
    }
}
function visualiseSnapping(snap) {
    switch (snap.type) {
        case "placeable":
        case "spotting_tile_placeable":
        case "attachable":
        case "tlc_spot":
        case "wire":
        case "wireport":
        case "shape_end":
            return createPoint(snap.at);
        case "neck":
            return createTriangle(snap.top, snap.facing);
        case "pluggable":
            return createTriangle(snap.at, 0);
        case "placeable_surface":
        case "attachable_on":
        case "tlc_plate":
            return createLine(snap.start, snap.end);
        case "connectable":
            return createHalfCircle(snap.at, snap.facing);
        case "spotting_tile":
            return createGrid(snap.at);
    }
}
function createLine(start, end) {
    var line = new Path([S(start), S(end)]);
    line.strokeWidth = 2.0;
    line.strokeColor = Colors.snapFill;
    line.strokeScaling = false;
    return line;
}
function createPoint(at) {
    var circle = Shape.Circle(at, 1.0);
    circle.strokeWidth = 4.0;
    circle.strokeColor = Colors.snapFill;
    circle.strokeScaling = false;
    return circle;
}
function createTriangle(at, rotation) {
    var triangle = new Path([S(P(at.x - 2, at.y)), S(P(at.x + 2, at.y)), S(P(at.x, at.y + 7))]);
    triangle.close();
    triangle.strokeWidth = 4.0;
    triangle.strokeColor = Colors.snapFill;
    triangle.strokeScaling = false;
    triangle.rotate(rotation, at);
    return triangle;
}
function createHalfCircle(at, rotation) {
    var arc = new Path(Segments([[at.x - ARC_SIZE, at.y], undefined, [0, ARC_SIZE * 1.4]], [[at.x + ARC_SIZE, at.y], [0, ARC_SIZE * 1.4]]));
    arc.closePath();
    arc.fillColor = Colors.snapFill;
    arc.rotate(rotation, at);
    return arc;
}
function createGrid(at) {
    return new Group(at.map(function (p) { return createPoint(p); }));
}
