import { setDefaultStyle } from "apparatus/draw";
import { P, R, Segments } from "apparatus/library/common";
import { Path } from "paper";
import { addEdgeLipsReversed, breakPathClosed, mirrorX } from "../../draw";
import { Interpolator } from "../common";
/** Interpolator, converts height to corresponding bulb size. */
var bulbDiameterLerp = new Interpolator([100, 300], [40, 136]);
/** Interpolator, converts height to corresponding neck diameter. */
var neckSizeLerp = new Interpolator([100, 300], [18, 28]);
/**
 * Based on https://scienceequip.com.au/products/distilling-flasks-fused-quartz
 */
var DistillationFlask = /** @class */ (function () {
    function DistillationFlask() {
    }
    DistillationFlask.prototype.render = function (appearance) {
        var height = appearance.height;
        var bulbDiameter = bulbDiameterLerp.interpolate(height);
        var neckWidth = neckSizeLerp.interpolate(height);
        var bulb = new Path.Circle(P(0, -bulbDiameter / 2), bulbDiameter / 2);
        var neck = new Path(Segments([[neckWidth / 2, -height]], [[neckWidth / 2, -20]]));
        mirrorX(neck);
        addEdgeLipsReversed(neck);
        neck.closePath();
        var outletPivot = P(0, -(height + bulbDiameter) / 2 - 10);
        var outletAngle = 17;
        var outletLength = bulbDiameter / 2 + 50;
        var outlet = new Path.Rectangle(R(0, outletPivot.y, outletLength, 12));
        outlet.rotate(outletAngle, outletPivot);
        var shape = bulb.unite(neck).unite(outlet);
        var liquidMask = shape.clone();
        var graphic = breakPathClosed(shape, 4, 8);
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.bounds.toShape(),
            snapping: [
                { type: "connectable", at: P(outletLength, outletPivot.y + 6).rotate(outletAngle, outletPivot), facing: 90 + outletAngle }
            ]
        };
    };
    DistillationFlask.properties = {
        label: "Distillation Flask",
        canContainLiquids: true,
        flippable: true,
        defaultAppearance: {
            height: 180
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "height", label: "Height",
                min: 100, max: 300
            }
        ],
    };
    return DistillationFlask;
}());
export { DistillationFlask };
