import { breakPath, mirrorX, setDefaultStyle } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { interpolate, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Arrays } from "ext/array";
import { Group, Path, Shape } from "paper";
/**
 * Pourable options:
 *                  Cap on      Cap off
 *   Non-cut          N            Y
 *   Cut top          N            N
 *   Cut bottom       Y            Y
 */
var Bottle = /** @class */ (function () {
    function Bottle() {
    }
    Bottle.prototype.render = function (appearance) {
        var scaleFactor = appearance.height / 210;
        var shape = new Path(Segments([[9, 2]], [[9, 15], , [0, 7]], [[22, 32], [-7, -9], [8, 11]], [[30, 65], , [-2, 0]], [[30, 71], [-2, 0]], [[30, 106], , [-2, 0]], [[30, 112], [-2, 0]], [[30, 200], , [-4, 14]], [[9, 207], [4, 4], [-4, 5]]));
        mirrorX(shape);
        var shapeCenter = shape.bounds.center;
        // Apply cuts, if any.
        if (appearance.cut) {
            var cutY = interpolate(appearance.cutPosition, [0, 1], [30, 180]);
            var maskShape = new Path(Segments([[-50, cutY]], [[50, cutY]])).close();
            if (appearance.cutKeep == "bottom") {
                maskShape.addSegments(Segments([[50, 210]], [[-50, 210]]));
            }
            else {
                maskShape.addSegments(Segments([[50, 0]], [[-50, 0]]));
            }
            var newShape = shape.intersect(maskShape);
            shape.remove();
            shape = newShape;
        }
        shape.scale(scaleFactor, shapeCenter);
        var liquidMask = shape.clone();
        var graphic;
        setDefaultStyle(shape);
        if (appearance.cut) {
            shape.closed = false;
            if (appearance.cutKeep == "bottom") {
                // Shift the last point to first.
                shape.insertSegments(0, [Arrays.last(shape.segments)]);
                shape.removeSegment(shape.segments.length - 1);
                graphic = new Group([shape]);
            }
            else {
                // Need to split the shape.
                var shapes = breakPath(shape, shape.segments.length / 2);
                setDefaultStyle(shapes);
                graphic = new Group([shapes]);
            }
        }
        else {
            graphic = new Group([shape]);
        }
        // Add a cap.
        if (!appearance.cut || appearance.cutKeep == "top") {
            var undercap = Shape.Rectangle(R(0, 10, 24, 3, Pivot.CENTER), 2.0)
                .withFill("#ffffff80")
                .withStroke("thinner", SharedColors.stroke);
            var capStuff = new Group([undercap]);
            if (appearance.cap) {
                var capColor = "#44a8d4".color();
                var cap = Shape.Rectangle(R(0, 0, 24, 12, Pivot.CENTER), 2.0)
                    .withGradientFill("right", Gradients.cylinder(capColor))
                    .withStroke("default", capColor.ladd(-10).hex());
                capStuff.addChild(cap);
            }
            capStuff.scale(scaleFactor, shapeCenter);
            graphic.addChild(capStuff);
        }
        // Determine liquid opening. Not all options are supported.
        var liquidMaskOpening = undefined;
        if (!appearance.cut) {
            if (!appearance.cap) {
                liquidMaskOpening = { end: liquidMask.segments[0].point, start: Arrays.last(liquidMask.segments).point };
            }
        }
        else {
            if (appearance.cutKeep == "bottom") {
                liquidMaskOpening = {
                    start: liquidMask.segments[liquidMask.segments.length - 2].point,
                    end: liquidMask.segments[liquidMask.segments.length - 1].point,
                };
            }
        }
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            liquidMaskOpening: liquidMaskOpening,
            hitShape: graphic.bounds.toShape(),
            pivot: shapeCenter,
            snapping: [
                shape.placeable(),
                { type: "placeable", at: graphic.strokeBounds.topCenter, facing: 180 },
            ]
        };
    };
    Bottle.properties = {
        label: "Plastic Bottle",
        canContainLiquids: true,
        pourable: true,
        defaultAppearance: {
            height: 210,
            cap: true,
            cut: false,
            cutKeep: "top",
            cutPosition: 0.5
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "height", label: "Size",
                min: 150, max: 330
            },
            { spec: "checkbox", key: "cap", label: "Show cap", visible: function (a) { return !a.cut || a.cutKeep != "bottom"; } },
            { spec: "header", header: "Cut", icon: "cut", toggleKey: "cut" },
            {
                spec: "toggle-buttons", size: "text", key: "cutKeep", label: "Keep", options: [
                    { value: "top", label: "Top" },
                    { value: "bottom", label: "Bottom" },
                ],
                visible: function (a) { return a.cut; }
            },
            {
                spec: "slider", key: "cutPosition", label: "Position",
                min: 0, max: 1.0, step: 0.01, visible: function (a) { return a.cut; }
            }
        ],
    };
    return Bottle;
}());
export { Bottle };
