import { g } from "analytics";
import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { clog } from "log";
import { initReactI18next } from "react-i18next";
import { Consts } from "vars";
var missingKeysLogged = {};
var ignoreList = [
    "0", "1", "2", "Google", "Microsoft", "Twitter", "L", "M", "XS", "S", "XL", "3D",
    "3", "4", "5", "6", "7", "8", "9", "0", "ChemText",
];
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
    backend: {
        loadPath: "/locales/{{lng}}.json",
    },
    fallbackLng: "en",
    interpolation: {
        escapeValue: false // react already safes from xss
    },
    returnEmptyString: false,
    saveMissing: true,
    missingKeyHandler: function (lngs, ns, key, fallbackValue, updateMissing, options) {
        if (key in missingKeysLogged) {
            return;
        }
        if (ignoreList.indexOf(key) > -1) {
            return;
        }
        // Ignore search terms
        if (key.indexOf(".search") > -1 && key.indexOf("apparatus.") > -1) {
            return;
        }
        if (Consts.isDev) {
            clog("Missing key: " + key);
        }
        else {
            g("MissingTranslationKey", key);
        }
        missingKeysLogged[key] = true;
    }
});
export default i18n;
