import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, SharedColors } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
import { Group, Path, Shape } from "paper";
var RED = "#d93021";
var RED_STROKE = "#992d23";
var TorchBurner = /** @class */ (function () {
    function TorchBurner() {
    }
    TorchBurner.prototype.render = function (appearance) {
        var body = Shape.Rectangle(R(0, 0, 50, 130, Pivot.BOTTOM_CENTER))
            .withStroke("default", "#888888")
            .withGradientFill("right", Gradients.cylinder("#aaaaaa".color()));
        var mount = Shape.Rectangle(R(0, -130, 30, 30, Pivot.BOTTOM_CENTER))
            .withStroke("default", SharedColors.stroke)
            .withGradientFill("right", Gradients.box("#444444".color()));
        var button = new Path.Rectangle(R(-15, -144, 12, 18, Pivot.CENTER_RIGHT))
            .withStroke("thin", RED_STROKE)
            .withGradientFill("right", Gradients.box(RED.color(), { delta: 15, shift: 0.3 }));
        var nozzleCasing = new Path.Rectangle(R(0, 0, 50, 18, Pivot.CENTER_LEFT))
            .withStroke("default", SharedColors.stroke)
            .withGradientFill("down", Gradients.cylinder("#444444".color()));
        var dial = new Path.Rectangle(R(48, 0, 18, 22, Pivot.CENTER_LEFT))
            .withStroke("default", RED_STROKE)
            .withGradientFill("down", Gradients.box(RED.color(), { delta: 15, shift: 0.3 }));
        var nozzle = new Path.Rectangle(R(0, 0, 50, 16, Pivot.CENTER_RIGHT))
            .withGradientFill("down", Gradients.cylinder("#aaaaaa".color(), 15));
        var topPart = new Group([nozzle, nozzleCasing, dial]);
        topPart.pivot = P(25, 0);
        if (appearance.flame) {
            var flame = SVG.blueFlame();
            flame.rotate(-90);
            flame.position = P(-49, 0);
            flame.scale(appearance.flameScale);
            topPart.insertChild(0, flame);
        }
        topPart.position = P(0, -160);
        topPart.rotate(15);
        var graphic = new Group([button, mount, body, topPart]);
        return {
            graphic: graphic,
            pivot: body.bounds.center,
            hitShape: graphic.bounds.toShape(),
            snapping: body.placeable(),
        };
    };
    TorchBurner.properties = {
        label: "Torch Burner",
        flippable: true,
        defaultAppearance: {
            flame: true,
            flameScale: 1.0
        },
        appearanceSpecs: [
            { spec: "checkbox", key: "flame", label: "Flame", subLabel: "Show" },
            {
                spec: "slider", key: "flameScale", label: "Flame Size",
                min: 0.7, max: 1.5, step: 0.05, visible: function (a) { return a.flame; }
            }
        ],
    };
    return TorchBurner;
}());
export { TorchBurner };
