import { P } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
import { Random } from "random/random";
var RENDER_AS_POINTS = true;
/**
 * - http://microbiology4us.blogspot.com/2019/01/confirmed-test-for-coliform-bacteria.html
 */
var StreakedBacteria = /** @class */ (function () {
    function StreakedBacteria() {
    }
    StreakedBacteria.prototype.render = function (appearance) {
        var diameter = appearance.diameter;
        var radius = diameter / 2;
        var random = new Random();
        var graphic = new Group([
        // Shape.Circle(P(0,0), 50).withFill("#eeeeee")
        ]);
        var colourGradient = [
            [appearance.color, 0.1],
            [appearance.color.color().alpha(0.3).string(), 1.0],
        ];
        for (var quadrant = 0; quadrant < appearance.stage; quadrant++) {
            var startAngle = -180 + quadrant * 72;
            var endAngle = startAngle + 75;
            for (var i = 0; i < 4; i++) {
                var distance = 0.9;
                var from = Po(startAngle - i * 10, distance * radius);
                var to = Po(endAngle + i * 10, (distance - streakSpread(quadrant, i)) * radius);
                graphic.addChildren(streak(from, to, random, bacteriaStepMultiplier(quadrant, i), randomFactor(quadrant, i), colourGradient));
            }
        }
        var raster = graphic.rasterize();
        graphic.remove();
        return {
            graphic: raster,
            hitShape: raster.bounds.toShape(),
            pivot: P(0, 0),
        };
    };
    StreakedBacteria.properties = {
        label: "Bacteria (Streaked)",
        defaultAppearance: {
            stage: 4,
            color: "#cccccc",
            diameter: 100,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 35, max: 150, step: 5, unit: "mm"
            },
            {
                spec: "slider", key: "stage", label: "Streaks",
                min: 1, max: 4, pipsStep: 1,
            },
            { spec: "color", key: "color", label: "Colour" }
        ],
    };
    return StreakedBacteria;
}());
export { StreakedBacteria };
/* Parameters for different lines and quadrants. */
function streakSpread(quadrant, line) {
    if (quadrant < 3)
        return 0;
    return line * 0.15;
}
function bacteriaStepMultiplier(quadrant, line) {
    switch (quadrant) {
        case 0: return 1.0;
        case 1: return 1.5;
        case 2: return 2.5;
        case 3:
        default:
            return 4.0;
    }
}
function randomFactor(quadrant, line) {
    switch (quadrant) {
        default:
        case 0:
        case 1: return 1.0;
        case 2: return 0.5;
        case 3: return 0.5;
    }
}
/* Helper methods */
// Returns a Point, in polar coordinates.
function Po(angleInDegrees, length) {
    var p = P(0, length);
    p.angle = angleInDegrees;
    return p;
}
function streak(from, to, random, bacteriaStepMultiplier, randomFactor, gradientStops) {
    var bacteriaRadius = 1.5;
    if (RENDER_AS_POINTS) {
        var stepLength = bacteriaRadius * bacteriaStepMultiplier;
        var items = [];
        var start = from;
        var delta = to.subtract(from);
        var step = delta.normalize(stepLength);
        for (var i = 0; i < delta.length / stepLength; i++) {
            start = start.add(step);
            var position = (randomFactor > 0) ? start.add(random.randomUniformVector(randomFactor)) : start;
            var shape = Shape.Circle(position, bacteriaRadius);
            shape.setRadialGradientFill(gradientStops);
            items.push(shape);
        }
        return items;
    }
    else {
        var line = new Path.Line(from, to);
        line.strokeWidth = 1.0;
        line.strokeColor = "#40404080";
        return [line];
    }
}
