var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P } from "apparatus/library/common";
import { BasicShapes } from "apparatus/library/shapes/common";
import { Color, Gradient, Path } from "paper";
var Sphere = /** @class */ (function () {
    function Sphere() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 5, maxX: 400,
                minY: 0, maxY: 0,
                toAppearance: function (f, p) { return f({
                    radius: p.x
                }); },
                fromAppearance: function (a) { return P(a.radius, 0); },
                scale: 1.0,
            }
        ];
    }
    Sphere.prototype.render = function (appearance) {
        var radius = appearance.radius, shading = appearance.shading;
        var graphic = new Path.Circle(P(0, 0), radius);
        BasicShapes.applyCustom(appearance, function (fill) {
            graphic.fillColor = new Color(new Gradient(shadingGradient(shading, fill), true), P(-radius / 2, -radius / 2), P(0, 1.5 * radius).rotate(45));
        }, function (width, stroke) {
            graphic.withStroke(width, stroke);
        });
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
        };
    };
    Sphere.properties = {
        label: "Sphere",
        defaultAppearance: __assign(__assign({}, BasicShapes.defaultAppearance), { shading: "smooth", radius: 50 }),
        appearanceSpecs: __spreadArray(__spreadArray([], BasicShapes.specs), [
            BasicShapes.header,
            {
                spec: "toggle-buttons", key: "shading", label: "Shading", size: "text",
                options: [
                    { value: "smooth", label: "Smooth" },
                    { value: "hard", label: "Hard" },
                ],
            }
        ]),
    };
    return Sphere;
}());
export { Sphere };
function shadingGradient(shading, fill) {
    switch (shading) {
        case "smooth":
            return [
                [fill.color().ladd(35).hex(), 0.0],
                [fill, 0.9],
                [fill.color().ladd(-10).hex(), 1.0],
            ];
        case "hard":
            return [
                [fill.color().ladd(35).hex(), 0.3],
                [fill, 0.7],
                [fill.color().ladd(-10).hex(), 0.8],
            ];
    }
}
