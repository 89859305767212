import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, Shape, Symbol } from "paper";
var WireGauze = /** @class */ (function () {
    function WireGauze() {
    }
    WireGauze.prototype.render = function (appearance) {
        switch (appearance.view) {
            case "side":
                return sideView(appearance);
            case "top":
                return topView(appearance);
            case "3d":
                return threeDView(appearance);
        }
    };
    WireGauze.properties = {
        label: "Wire Gauze",
        defaultAppearance: {
            size: 100,
            view: "side",
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 50, max: 150, step: 10, unit: "mm"
            },
            {
                spec: "toggle-buttons", key: "view", label: "View", size: "text",
                options: [
                    { value: "top", label: "Top" },
                    { value: "3d", label: "3D" },
                    { value: "side", label: "Side" },
                ]
            }
        ],
    };
    return WireGauze;
}());
export { WireGauze };
function sideView(appearance) {
    var squiggle = new Path(Segments([[0, 0], undefined, [5, 9]], [[10, 0], [-5, -9], [5, 9]]));
    squiggle.strokeWidth = 2.5;
    squiggle.strokeColor = "#5c6c84";
    var count = appearance.size / 10;
    var graphic = new Group([squiggle]);
    for (var i = 2; i < count; i++) {
        squiggle.addSegments(Segments([[i * 10, 0], [-5, -9], [5, 9]]));
    }
    return {
        graphic: graphic,
        hitShape: graphic.strokeBounds.expand(10).toShape(),
        snapping: [{
                type: "placeable",
                at: graphic.bounds.bottomCenter.add(P(0, 5)),
            }, {
                type: "placeable_surface",
                start: graphic.bounds.topLeft.add(P(0, -5)),
                end: graphic.bounds.topRight.add(P(0, -5))
            }]
    };
}
function topView(appearance) {
    var line = Shape.Rectangle(R(0, 0, appearance.size, 2.0));
    line.fillColor = "#5c6c84";
    var lineSymbol = new Symbol(line, true);
    var count = appearance.size / 10;
    var offset = appearance.size / 2 + 5;
    var graphic = new Group();
    for (var i = 1; i < count + 1; i++) {
        graphic.addChild(lineSymbol.place(P(0, 10 * i - offset)));
        var orthogonalPlaced = lineSymbol.place(P(i * 10 - offset, 0));
        orthogonalPlaced.rotate(90);
        graphic.addChild(orthogonalPlaced);
    }
    var pad = Shape.Circle(graphic.bounds.center, appearance.size / 3);
    pad.fillColor = "#dbcfbaee";
    graphic.addChild(pad);
    var hitShape = Shape.Rectangle(graphic.strokeBounds.expand(10));
    return { graphic: graphic, hitShape: hitShape };
}
function threeDView(appearance) {
    var count = appearance.size / 10;
    var rStep = 0.2;
    var yStep = 3;
    var graphic = new Group();
    for (var i = 0; i < count; i++) {
        var j = (1 - count) / 2 + i;
        var xf = j * (10 + rStep * (count - 1) / 2);
        var xb = j * (10 - rStep * (count - 1) / 2);
        graphic.addChild(new Path.Line(P(xb, 0), P(xf, yStep * (count - 1))).withStroke(2.0, "#5c6c84"));
    }
    // Vertical ones.
    for (var i = 0; i < count; i++) {
        var x = (10 + ((1 - count) / 2 + i) * rStep) * (count - 1) / 2;
        var y = i * yStep;
        graphic.addChild(new Path.Line(P(-x, y), P(x, y)).withStroke(2.0, "#5c6c84"));
    }
    var pad = Shape.Ellipse(R(graphic.bounds.center.x, graphic.bounds.center.y, appearance.size * 0.65, count * yStep * 0.65, Pivot.CENTER));
    pad.fillColor = "#dbcfbaee";
    graphic.addChild(pad);
    var hitShape = Shape.Rectangle(graphic.strokeBounds.expand(10));
    return { graphic: graphic, hitShape: hitShape };
}
