var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DEFAULT_LIQUID_DATA, Segments } from "apparatus/library/common";
import { Path } from "paper";
import { breakPath, mirrorX, setDefaultStyle } from "../../draw";
// Based on https://www.jaytecglass.co.uk/laboratory-glassware/industry-glassware/thistle-funnels/
var ThistleTube = /** @class */ (function () {
    function ThistleTube() {
    }
    ThistleTube.prototype.render = function (appearance) {
        var height = appearance.height;
        var baseShape = new Path(Segments([[16, 0]], [[10, 10], undefined, [10, 4]], [[23, 30], [0, -8], [0, 10]], [[4, 50], [8, -1]], [[4, height]]));
        mirrorX(baseShape);
        var liquidMask = baseShape.clone();
        var hitShape = baseShape.clone();
        var graphic = breakPath(baseShape, 5);
        setDefaultStyle(graphic);
        return { graphic: graphic, liquidMask: liquidMask, hitShape: hitShape };
    };
    ThistleTube.properties = {
        label: "Thistle tube",
        canContainLiquids: true,
        defaultAppearance: {
            height: 250,
        },
        defaultLiquidData: __assign(__assign({}, DEFAULT_LIQUID_DATA), { amountRatio: 0.9 }),
        appearanceSpecs: [
            {
                spec: "slider", key: "height", label: "Height",
                min: 180, max: 300
            }
        ],
    };
    return ThistleTube;
}());
export { ThistleTube };
