// Hacky wraper for jquery slider pips.
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
var Slider = /** @class */ (function (_super) {
    __extends(Slider, _super);
    function Slider(props) {
        var _this = _super.call(this, props) || this;
        _this.ref = React.createRef();
        _this.suspendUpdates = false;
        _this.state = { value: props.initialValue };
        return _this;
    }
    // Called when slider changed by setting a value.
    Slider.prototype.onChange = function (value) {
        if (this.suspendUpdates)
            return;
        if (this.state.value == value)
            return;
        this.props.onEnd(this.props.initialValue, value);
        this.setState({ value: value });
    };
    Slider.prototype.onSlideStart = function (_) {
        var _a;
        if (this.suspendUpdates)
            return;
        (_a = this.props.onStart) === null || _a === void 0 ? void 0 : _a.call(null);
    };
    // Called when slider changed by sliding
    Slider.prototype.onSlide = function (value) {
        if (this.suspendUpdates)
            return;
        this.props.onChange(value);
    };
    /** Called when the user has stopped interacting with slider by sliding. */
    Slider.prototype.onSlideStop = function (value) {
        if (this.suspendUpdates)
            return;
        // Component could have died, check that state is valid.
        if (this.state) {
            this.setState({ value: value });
            this.props.onEnd(this.props.initialValue, value);
        }
    };
    Slider.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (prevProps.initialValue != this.props.initialValue) {
            if (prevState.value != this.props.initialValue) {
                // Props updated externally. Update current state but don't invoke any callbacks.
                this.setState({
                    value: this.props.initialValue
                });
                // Update the slider.
                this.suspendUpdates = true;
                var slider = $(this.ref.current);
                slider.slider({ value: this.props.initialValue });
                this.suspendUpdates = false;
            }
        }
    };
    Slider.prototype.componentDidMount = function () {
        // Unfortunately pips does not have typedefs so without cast to <any>
        // the rest would not compile.
        var slider = $(this.ref.current);
        // jQuery will override this in components
        var self = this;
        this.setState({ value: this.props.initialValue });
        slider.slider({
            min: this.props.min,
            max: this.props.max,
            range: "min",
            value: this.props.initialValue,
            step: this.props.step || 1.0,
            animate: "fast"
            // orientation: "vertical"
            // }).slider("float", {
            //     formatLabel: this.props.formatLabel || (v => v)
        }).on("slidechange", function (_, ui) {
            self.onChange(ui.value);
        }).on("slidestart", function (_, ui) {
            self.onSlideStart(ui.value);
        }).on("slide", function (_, ui) {
            self.onSlide(ui.value);
        }).on("slidestop", function (_, ui) {
            self.onSlideStop(ui.value);
        });
        if (this.props.pipsStep) {
            slider.slider("pips", {
                first: false,
                last: false,
                rest: "pip",
                step: this.props.pipsStep,
            });
        }
    };
    Slider.prototype.componentWillUnmount = function () {
        // Permanently suspend updates.
        this.suspendUpdates = true;
        var slider = $(this.ref.current);
        slider.off("slidestart");
        slider.off("slidechange");
        slider.off("slide");
        slider.off("slidestop");
    };
    Slider.prototype.render = function () {
        var _a;
        return React.createElement("div", { className: "slider " + ((_a = this.props.extraClass) !== null && _a !== void 0 ? _a : ""), ref: this.ref });
    };
    return Slider;
}(React.Component));
export { Slider };
