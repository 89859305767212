var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P } from "apparatus/library/common";
import { BasicShapes } from "apparatus/library/shapes/common";
import { Group, Path, Segment } from "paper";
var Cuboid = /** @class */ (function () {
    function Cuboid() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 6, maxX: 600,
                minY: 8, maxY: 600,
                toAppearance: function (f, p) { return f({
                    height: p.y,
                    width: p.x
                }); },
                fromAppearance: function (a) { return P(a.width, a.height); },
                scale: 2.0,
            }
        ];
    }
    /**
            P6               P5
            +----------------+
            |                |
      P3+---+-----------+P2  |
        |               |    |
        |               |    |
        |               +----+ P4
        |               |
        |               |
        +---------------+
      PO                 P1

     */
    Cuboid.prototype.render = function (appearance) {
        var width = appearance.width, height = appearance.height, depth = appearance.depth;
        var depthVector = P(depth, -depth / 2);
        var p3 = P(0, 0);
        var p0 = P(0, height);
        var p1 = P(width, height);
        var p2 = P(width, 0);
        var p6 = p3.add(depthVector);
        var p5 = p2.add(depthVector);
        var p4 = p1.add(depthVector);
        var frontWall = new Path([
            new Segment(p0),
            new Segment(p1),
            new Segment(p2),
            new Segment(p3),
        ]).close();
        var topWall = new Path([
            new Segment(p3),
            new Segment(p2),
            new Segment(p5),
            new Segment(p6),
        ]).close();
        var sideWall = new Path([
            new Segment(p1),
            new Segment(p4),
            new Segment(p5),
            new Segment(p2),
        ]).close();
        var graphic = new Group([frontWall, topWall, sideWall]);
        BasicShapes.applyCustom(appearance, function (fill) {
            frontWall.fillColor = fill;
            topWall.fillColor = fill.color().ladd(5).string();
            sideWall.fillColor = fill.color().ladd(-5).string();
        }, function (width, stroke) {
            var outline = new Path([p0, p1, p4, p5, p6, p3].map(function (p) { return new Segment(p); }))
                .close()
                .withStroke(width, stroke);
            graphic.addChild(outline);
        });
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            pivot: P(width / 2, height / 2)
        };
    };
    Cuboid.properties = {
        label: "Cuboid",
        flippable: true,
        defaultAppearance: __assign(__assign({}, BasicShapes.defaultAppearance), { width: 120, height: 100, depth: 50 }),
        appearanceSpecs: __spreadArray(__spreadArray([], BasicShapes.specs), [
            BasicShapes.header,
            {
                spec: "slider", key: "depth", label: "Depth",
                min: 0, max: 200
            }
        ]),
    };
    return Cuboid;
}());
export { Cuboid };
