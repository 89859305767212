var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { Arrays } from "ext/array";
import { createMoveForwardIndices, createMoveToFrontIndices, createSendBackwardIndices, createSendToBackIndices, reorder } from "ordering/reorder";
import appStore from "store/store";
var ReorderItems = /** @class */ (function () {
    function ReorderItems(from, to) {
        this.from = from;
        this.to = to;
    }
    ReorderItems.create = function (ids, op) {
        var indices = getIndices(ids);
        var destIndices = (function () {
            switch (op) {
                case "sendBackward": return createSendBackwardIndices(indices);
                case "sendToBack": return createSendToBackIndices(indices);
                case "bringForward": return createMoveForwardIndices(indices, appStore.items().length);
                case "bringToFront": return createMoveToFrontIndices(indices, appStore.items().length);
            }
        })();
        // If this results in no changes, dont' return a command.
        if (Arrays.shallowStrictEquals(indices, destIndices))
            return;
        return new ReorderItems(indices, destIndices);
    };
    ReorderItems.prototype.execute = function () {
        var _this = this;
        appStore.dispatchFn("Reorder", function (store) {
            return __assign(__assign({}, store), { items: reorder(store.items, _this.from, _this.to) });
        });
    };
    ReorderItems.prototype.undo = function () {
        var _this = this;
        appStore.dispatchFn("Reorder", function (store) {
            return __assign(__assign({}, store), { items: reorder(store.items, _this.to, _this.from) });
        });
    };
    return ReorderItems;
}());
export { ReorderItems };
var SendToBack = /** @class */ (function () {
    function SendToBack(index) {
        this.index = index;
    }
    SendToBack.createFor = function (id) {
        // Determine the index of the element.
        var index = getIndex(id, 0);
        if (index == 0)
            return;
        return new SendToBack(index);
    };
    SendToBack.prototype.execute = function () {
        var _this = this;
        appStore.dispatchFn("SendToBack", function (state) {
            var newItems = __spreadArray([], state.items);
            var toMove = newItems.splice(_this.index, 1);
            newItems.unshift(toMove[0]);
            return __assign(__assign({}, state), { items: newItems });
        });
    };
    SendToBack.prototype.undo = function () {
        var _this = this;
        appStore.dispatchFn("Undo-SendToBack", function (state) {
            var newItems = __spreadArray([], state.items);
            // Pop from the front and insert into original index position.
            var toMove = newItems.shift();
            newItems.splice(_this.index, 0, toMove);
            return __assign(__assign({}, state), { items: newItems });
        });
    };
    return SendToBack;
}());
export { SendToBack };
var BringToFront = /** @class */ (function () {
    function BringToFront(index) {
        this.index = index;
    }
    BringToFront.createFor = function (id) {
        // Determine the index of the element.
        var highestIndex = appStore.items().length - 1;
        var index = getIndex(id, highestIndex);
        if (index == highestIndex)
            return;
        return new BringToFront(index);
    };
    BringToFront.prototype.execute = function () {
        var _this = this;
        appStore.dispatchFn("BringToFront", function (state) {
            var newItems = __spreadArray([], state.items);
            var toMove = newItems.splice(_this.index, 1);
            newItems.push(toMove[0]);
            return __assign(__assign({}, state), { items: newItems });
        });
    };
    BringToFront.prototype.undo = function () {
        var _this = this;
        appStore.dispatchFn("Undo-BrintToFront", function (state) {
            var newItems = __spreadArray([], state.items);
            // Pop from the front and insert into original index position.
            var toMove = newItems.pop();
            newItems.splice(_this.index, 0, toMove);
            return __assign(__assign({}, state), { items: newItems });
        });
    };
    return BringToFront;
}());
export { BringToFront };
var BringForward = /** @class */ (function () {
    function BringForward(index) {
        this.index = index;
    }
    BringForward.createFor = function (id) {
        // Determine the index of the element.
        var highestIndex = appStore.items().length - 1;
        var index = getIndex(id, highestIndex);
        if (index == highestIndex)
            return;
        return new BringForward(index);
    };
    BringForward.prototype.execute = function () {
        var _this = this;
        appStore.dispatchFn("BringForward", function (state) {
            var newItems = __spreadArray([], state.items);
            var toMove = newItems.splice(_this.index, 1);
            newItems.splice(_this.index + 1, 0, toMove[0]);
            return __assign(__assign({}, state), { items: newItems });
        });
    };
    BringForward.prototype.undo = function () {
        var _this = this;
        appStore.dispatchFn("Undo-BringForward", function (state) {
            var newItems = __spreadArray([], state.items);
            // Pop from the front and insert into original index position.
            var toMove = newItems.splice(_this.index + 1, 1);
            newItems.splice(_this.index, 0, toMove[0]);
            return __assign(__assign({}, state), { items: newItems });
        });
    };
    return BringForward;
}());
export { BringForward };
var SendBackward = /** @class */ (function () {
    function SendBackward(index) {
        this.index = index;
    }
    SendBackward.createFor = function (id) {
        // Determine the index of the element.
        var index = getIndex(id, 0);
        if (index == 0)
            return;
        return new SendBackward(index);
    };
    SendBackward.prototype.execute = function () {
        var _this = this;
        appStore.dispatchFn("SendBackwards", function (state) {
            var newItems = __spreadArray([], state.items);
            var toMove = newItems.splice(_this.index, 1);
            newItems.splice(_this.index - 1, 0, toMove[0]);
            return __assign(__assign({}, state), { items: newItems });
        });
    };
    SendBackward.prototype.undo = function () {
        var _this = this;
        appStore.dispatchFn("Undo-SendBackwards", function (state) {
            var newItems = __spreadArray([], state.items);
            // Pop from the front and insert into original index position.
            var toMove = newItems.splice(_this.index - 1, 1);
            newItems.splice(_this.index, 0, toMove[0]);
            return __assign(__assign({}, state), { items: newItems });
        });
    };
    return SendBackward;
}());
export { SendBackward };
function getIndex(id, notFound) {
    var items = appStore.items();
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (item.id == id) {
            return i;
        }
    }
    // This should never happen.
    return notFound;
}
function getIndices(ids) {
    var items = appStore.items();
    var indices = [];
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (ids.indexOf(item.id) > -1) {
            indices.push(i);
        }
    }
    return indices;
}
