import { R } from "apparatus/library/common";
import { Shape } from "paper";
var IceCube = /** @class */ (function () {
    function IceCube() {
    }
    IceCube.prototype.render = function (appearance) {
        var size = appearance.size;
        var graphic = Shape.Rectangle(R(0, 0, size, size), 5).withStroke("thin", "#bbf0f2");
        graphic.setRadialGradientFill([["#f2ffffee", 0.2], ["#e6fcfcaa", 1]]);
        var hitShape = Shape.Rectangle(R(0, 0, size, size).expand(10));
        return { graphic: graphic, hitShape: hitShape };
    };
    IceCube.properties = {
        label: "Ice cube",
        defaultAppearance: {
            size: 24
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 15, max: 40
            }
        ],
    };
    return IceCube;
}());
export { IceCube };
