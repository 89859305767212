var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { clog } from "log";
// Implementation of commands - undoable operations on the application store. */
var DEBUG = false;
var MAX_UNDOS = 50;
var CommandProcessor = /** @class */ (function () {
    function CommandProcessor() {
        /** Undo stack. Items should be pushed and popped. */
        this.undoStack = [];
        /** Red stack. Items should be pushed and popped. */
        this.redoStack = [];
        /** Whether current change are dirty. */
        this.isDirty = false;
    }
    /**
     * Executes a command. The command will be placed onto the undo stack.
     * Redo stack is cleared. If a sequence is being recorded, the command
     * is executed and added to the current sequence.
     */
    CommandProcessor.prototype.execute = function (command) {
        command.execute();
        if (DEBUG)
            clog("Command:", command);
        if (this.recordingCommands) {
            this.recordingCommands.push(command);
        }
        else {
            this.pushToUndoStack(command);
        }
        // Destroy the redo stack.
        this.redoStack.length = 0;
        this.markDirty();
    };
    /**
     * Executes a command, potentially replacing last command on the undo stack.
     * @param replaceCommmand Returns a command to replace the last command, {@code false) otherwise
     * @param newCommand Default new command if there is no command to replace.
     */
    CommandProcessor.prototype.executeReplacingLastCommand = function (replaceCommand, newCommand) {
        if (this.undoStack.length == 0) {
            this.execute(newCommand);
            return;
        }
        var lastCommand = this.undoStack[this.undoStack.length - 1];
        var maybeNewCommand = replaceCommand(lastCommand);
        if (maybeNewCommand) {
            // Replace the last command on the undo stack.
            lastCommand.undo();
            this.undoStack.pop();
            this.execute(maybeNewCommand);
        }
        else {
            this.execute(newCommand);
        }
    };
    /**
     * Starts recording a sequence of commands. All commands executed using execute() will be
     * added to the sequence. The sequence is then put on the undo queue when calling finishRecording()
     */
    CommandProcessor.prototype.startRecording = function () {
        if (this.recordingCommands) {
            throw new Error("Command processor: already recording a sequence");
        }
        this.recordingCommands = [];
    };
    /**
     * Finishes recording current sequence and places all commands executed while recording onto
     * the undo stack as a single command.
     */
    CommandProcessor.prototype.finishRecording = function () {
        if (!this.recordingCommands) {
            // TODO: We used to throw an error but this is too spammy. Reinvestigate if needed.
            // throw new Error("Command processor: not currently recording a sequence")
            return;
        }
        switch (this.recordingCommands.length) {
            case 0:
                break;
            case 1:
                this.pushToUndoStack(this.recordingCommands[0]);
                break;
            default:
                var sequence = new Sequence(this.recordingCommands);
                this.pushToUndoStack(sequence);
        }
        this.recordingCommands = undefined;
    };
    /**
     * Performs an undo.
     * @returns false if the undo stack is empty.
     */
    CommandProcessor.prototype.undo = function () {
        var command = this.undoStack.pop();
        if (!command)
            return false;
        command.undo();
        this.redoStack.push(command);
        return true;
    };
    /**
     * Performs a redo.
     * @returns false if the redo stack is empty
     */
    CommandProcessor.prototype.redo = function () {
        var command = this.redoStack.pop();
        if (!command)
            return false;
        command.execute();
        this.undoStack.push(command);
        return true;
    };
    /** Clears both undo and redo stacks. */
    CommandProcessor.prototype.clear = function () {
        if (this.recordingCommands) {
            this.recordingCommands.length = 0;
        }
        this.redoStack.length = 0;
        this.undoStack.length = 0;
        this.markClean();
    };
    /**
     * Returns true if there are "dirty" (i.e unsaved) changes. Not always reliable,
     * may return true even when clean.
     */
    CommandProcessor.prototype.hasDirtyChanges = function () {
        return this.isDirty;
    };
    /** Mark changes as clean. */
    CommandProcessor.prototype.markClean = function () {
        this.isDirty = false;
    };
    CommandProcessor.prototype.markDirty = function () {
        this.isDirty = true;
    };
    /** Only use this to push new commands. Pushing as a result of e.g. redo should push directly. */
    CommandProcessor.prototype.pushToUndoStack = function (command) {
        this.undoStack.push(command);
        // Restrict the number of undo commands.
        if (this.undoStack.length > MAX_UNDOS) {
            this.undoStack.splice(0, this.undoStack.length - MAX_UNDOS);
        }
        this.markDirty();
    };
    return CommandProcessor;
}());
export { CommandProcessor };
// Returns a command class that does the inverse of another command class.
export function invert(commandClass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        class_1.prototype.execute = function () {
            _super.prototype.undo.call(this);
        };
        class_1.prototype.undo = function () {
            _super.prototype.execute.call(this);
        };
        return class_1;
    }(commandClass));
}
/** A command consisting of a sequence of commands. */
var Sequence = /** @class */ (function () {
    function Sequence(commands) {
        // Process the incoming commands, hoisting up any singleton sequences.
        this.commands = commands.map(function (command) {
            if (command instanceof Sequence && command.commands.length == 1) {
                return command.commands[0];
            }
            else {
                return command;
            }
        });
    }
    Sequence.prototype.execute = function () {
        this.commands.forEach(function (command) { return command.execute(); });
    };
    Sequence.prototype.undo = function () {
        for (var i = this.commands.length - 1; i >= 0; i--) {
            this.commands[i].undo();
        }
    };
    return Sequence;
}());
export { Sequence };
export var commandProcessor = new CommandProcessor();
