import { mirrorX, strokeWidth } from "apparatus/draw";
import { clamp, P, Segments, SharedColors } from "apparatus/library/common";
import { Path } from "paper";
export var Caps;
(function (Caps) {
    Caps.specs = [
        {
            spec: "color-wells", key: "capColor", label: "Cap colour",
            colors: [
                { value: "blue", label: "Blue", hex: "#5668e3" },
                { value: "plain", label: "Plain", hex: "#ffffff" },
            ]
        }
    ];
    Caps.defaultAppearance = {
        capColor: "blue"
    };
    function createCap(props) {
        var radius = props.radius, _a = props.appearance, appearance = _a === void 0 ? Caps.defaultAppearance : _a;
        var extraLipRadius = clamp(radius / 5, 2, 5);
        var cap = new Path(Segments([[radius - 2, 0], , [3, 0]], [[radius, 14], [0, -10]], [[radius + extraLipRadius, 15]], [[radius + extraLipRadius, 22]]));
        mirrorX(cap);
        cap.closePath();
        var c = colors(appearance);
        cap.withStroke(strokeWidth("default"), c[0]);
        if (c[1].type === "gradient") {
            cap.setGradientFill("right", [["#5668e3", 0.0], ["#7e9cfc", 0.3], ["#5668e3", 1.0]]);
        }
        else if (c[1].type === "solid") {
            cap.withFill(c[1].color);
        }
        cap.pivot = P(0, 20);
        return cap;
    }
    Caps.createCap = createCap;
    function colors(_a) {
        var color = _a.capColor;
        switch (color) {
            case "blue": return ["#2b3aa3", { type: "gradient", gradient: [["#5668e3", 0.0], ["#7e9cfc", 0.3], ["#5668e3", 1.0]] }];
            case "plain": return [SharedColors.stroke, { type: "solid", color: "#ffffff" }];
        }
    }
})(Caps || (Caps = {}));
