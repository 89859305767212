import { mirrorX, smoothCornersIndividual } from "apparatus/draw";
import { P, Pivot, R, Segments, SharedGradients } from "apparatus/library/common";
import { Group, Path } from "paper";
var PISTON_THICKNESS = 12;
var PISTON_COLUMN = 18;
var WALL_THICKNESS = 20;
var PistonChamber = /** @class */ (function () {
    function PistonChamber() {
    }
    PistonChamber.prototype.render = function (appearance) {
        var width = appearance.width, height = appearance.height, pistonRatio = appearance.pistonRatio, gasColor = appearance.gasColor;
        var pistonYShift = pistonRatio * (height - PISTON_THICKNESS);
        var chamber = new Path(Segments([[width / 2, height]], [[width / 2, 0]], [[width / 2 + WALL_THICKNESS, 0]], [[width / 2 + WALL_THICKNESS, height + WALL_THICKNESS]])).close();
        mirrorX(chamber);
        smoothCornersIndividual(chamber, { 2: 3, 3: 5, 4: 5, 5: 3 });
        chamber.withFill("#cccccc").withStroke("default");
        var piston = new Path.Rectangle(R(0, 0, width, PISTON_THICKNESS, Pivot.TOP_CENTER))
            .withGradientFill("right", SharedGradients.cylinderDarkMetal)
            .withStroke("default");
        var column = new Path.Rectangle(R(0, 0, PISTON_COLUMN, height / 2, Pivot.BOTTOM_CENTER))
            .withGradientFill("right", SharedGradients.cylinderDarkMetal)
            .withStroke("default");
        piston.position.y += pistonYShift;
        column.position.y += pistonYShift;
        var gas = new Path.Rectangle(R(0, height, width, height - pistonYShift, Pivot.BOTTOM_CENTER)).withFill(gasColor);
        var graphic = new Group([gas, piston, column, chamber]);
        return {
            graphic: graphic,
            pivot: P(0, height / 2),
            hitShape: graphic.bounds.toShape()
        };
    };
    PistonChamber.properties = {
        label: "Piston Chamber",
        defaultAppearance: {
            width: 150,
            height: 220,
            pistonRatio: 0.5,
            gasColor: "#a4e1f9"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Width",
                min: 30, max: 200, unit: "mm"
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 60, max: 400, unit: "mm"
            },
            {
                spec: "slider", key: "pistonRatio", label: "Piston position",
                min: 0.0, max: 1, step: 0.01,
            },
            {
                spec: "color", key: "gasColor", label: "Gas colour",
            }
        ],
    };
    return PistonChamber;
}());
export { PistonChamber };
