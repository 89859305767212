import { setDefaultStyle } from "apparatus/draw";
import { P, R } from "apparatus/library/common";
import { Raster, Shape } from "paper";
/** Loads a raster image. Currently used only for development. */
var RasterImage = /** @class */ (function () {
    function RasterImage() {
        this.loadedUrl = "";
        this.hitShape = Shape.Rectangle(R(0, 0, 10, 10));
    }
    RasterImage.prototype.render = function (appearance) {
        var _this = this;
        var _a;
        if (appearance.source == "") {
            var dummy = Shape.Rectangle(R(0, 0, 100, 100));
            setDefaultStyle(dummy);
            return {
                graphic: dummy,
                hitShape: dummy.clone()
            };
        }
        var raster;
        if (appearance.source != this.loadedUrl) {
            (_a = this.currentRaster) === null || _a === void 0 ? void 0 : _a.remove();
            raster = new Raster(appearance.source);
            raster.onLoad = function () {
                _this.hitShape.bounds = raster.bounds;
            };
            this.currentRaster = raster;
            this.loadedUrl = appearance.source;
        }
        else {
            raster = this.currentRaster;
        }
        raster.scaling = P(appearance.size, appearance.size);
        return {
            graphic: raster,
            hitShape: this.hitShape,
        };
    };
    RasterImage.properties = {
        label: "Image",
        defaultAppearance: {
            source: "",
            size: 1,
        },
        appearanceSpecs: [
            {
                spec: "text", key: "source", label: "Image URL",
            },
            {
                spec: "slider", key: "size", label: "Size",
                min: 0.15, max: 2.0, step: 0.005,
            }
        ],
    };
    return RasterImage;
}());
export { RasterImage };
