export var DEFAULT_FONT_SIZE = 12;
export var Arrow;
(function (Arrow) {
    Arrow.defaultArrow = {
        selected: false,
        label: "Label",
        fontSize: DEFAULT_FONT_SIZE,
        endStyle: "triangle",
        isChemMarkEnabled: false,
        fontFamily: "sans-serif",
        textColor: "#000000",
        showArrow: true,
        arrowThickness: 3.0,
        textAlign: "left",
    };
})(Arrow || (Arrow = {}));
