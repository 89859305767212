import { createOutlinedGroup, smoothCorner } from "apparatus/draw";
import { R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path } from "paper";
var LightGate = /** @class */ (function () {
    function LightGate() {
    }
    LightGate.prototype.render = function (appearance) {
        var color = appearance.color;
        var darker = appearance.color.color().darken(0.3).hex();
        var side = new Path.Rectangle(R(0, 0, 8, 120)).withFill(darker);
        smoothCorner(side.segments[1], 2.0);
        var side2 = new Path.Rectangle(R(20, 0, 8, 120)).withFill(darker);
        var front = new Path(Segments([[7, 0]], [[35, 0]], [[35, 120]], [[27, 120]], [[27, 20]], [[15, 20]], [[15, 120]], [[7, 120]])).close().withFill(color);
        smoothCorner(front.segments[1], 2.0);
        var gate = createOutlinedGroup("default", SharedColors.stroke, side, side2, front);
        var graphic = new Group([gate]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    LightGate.properties = {
        label: "Light Gate",
        flippable: true,
        defaultAppearance: {
            color: "#666666"
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "color", label: "Colour",
                options: [
                    { value: "#666666", label: "Black" },
                    { value: "#cccccc", label: "White" },
                ],
            }
        ],
    };
    return LightGate;
}());
export { LightGate };
