var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createOutlinedGroup } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedColors, SharedGradients } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var BLUE = "#1f67a4".color();
/**
 * - https://shop.vacuubrand.com/en/diaphragm-pump-md-1-20696080.html
 * - https://i.ebayimg.com/images/g/pLUAAOSwjhdfr~vF/s-l1600.jpg
 * - https://www.amazon.co.uk/YUEWO-Equipment-Filter-Suction-Solvent/dp/B07Y2SDMPZ
 * - https://www.amazon.co.uk/dp/B08YR161TW/
 * - https://www.youtube.com/watch?v=Y4PD0VgnqdU
 */
var VacuumPump = /** @class */ (function () {
    function VacuumPump() {
    }
    VacuumPump.prototype.render = function (appearance) {
        var back = new Path.Rectangle(R(0, 0, 130, 80, Pivot.CENTER_LEFT))
            .withGradientFill("down", Gradients.simple(BLUE, 15));
        var mid = new Path.Rectangle(R(130, 0, 80, 100, Pivot.CENTER_LEFT))
            .withGradientFill("down", SharedGradients.cylinderMetal);
        var cap = new Path.Rectangle(R(200, 0, 40, 100, Pivot.CENTER_LEFT), 4.0)
            .withGradientFill("down", Gradients.cylinder(BLUE));
        var topCover = new Path.Rectangle(R(0, -35, 130, 20, Pivot.BOTTOM_LEFT), 3.0)
            .withGradientFill("down", Gradients.simple(SharedColors.lightMetal.color(), 20));
        var bottomCover = new Path.Rectangle(R(0, 35, 130, 25, Pivot.TOP_LEFT), 3.0)
            .withGradientFill("up", Gradients.simple(SharedColors.lightMetal.color(), 20));
        var onOff = new Path.Rectangle(R(95, 25, 28, 16, Pivot.CENTER), 2.0)
            .withGradientFill("right", [["#333333", 0.0], ["#333333", 0.48], ["#555555", 0.52], ["#666666", 0.9]])
            .withStroke("default", "#222222");
        var leg = new Path(Segments([[200, 50]], [[200, 60]], [[210, 60]])).withStroke("default", SharedColors.mediumMetal);
        var outlinedGroup = createOutlinedGroup("default", SharedColors.stroke, topCover, bottomCover, back, cap, mid);
        var handle = new Path(Segments([[0, 0]], [[0, -30]], [[40, -70], , [0, -3]], [[58, -70], [0, -3]], [[20, -30]], [[20, 0]])).withGradientFill("down", Gradients.box(BLUE, { shift: 0.2 }))
            .withStroke("default", SharedColors.stroke);
        handle.pivot = P(0, 0);
        handle.position = P(100, -55);
        var graphic = new Group([leg, handle, outlinedGroup, onOff]);
        // Grilles.
        for (var y = -45; y <= 45; y += 10) {
            var top_1 = new Path.Line(P(130, y - 2), P(210, y - 2)).withStroke(2.0, "#ffffff40");
            var bottom = new Path.Line(P(130, y), P(210, y)).withStroke(2.0, "#00000040");
            graphic.addChildren([top_1, bottom]);
        }
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: [
                graphic.placeable(),
                { type: "connectable", at: P(240, 0), facing: 90 },
            ]
        };
    };
    VacuumPump.properties = {
        label: "Vacuum Pump",
        flippable: true,
        defaultAppearance: {
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.75, max: 1.15, step: 0.01, unit: "%"
            }
        ],
    };
    VacuumPump = __decorate([
        staticImplements()
    ], VacuumPump);
    return VacuumPump;
}());
export { VacuumPump };
