var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { setDefaultStyle } from "apparatus/draw";
import { P, R, S, Segments } from "apparatus/library/common";
import { CompoundPath, Group, Path, Shape } from "paper";
import { breakPath } from "../draw";
export var Tubes;
(function (Tubes) {
    Tubes.MIN_DIAMETER = 8;
    Tubes.MAX_DIAMETER = 50;
})(Tubes || (Tubes = {}));
var MIN_DIAMETER = Tubes.MIN_DIAMETER;
var MAX_DIAMETER = Tubes.MAX_DIAMETER;
export var TUBE_DIAMETER_SPEC = {
    spec: "slider", key: "diameter", label: "Diameter",
    min: MIN_DIAMETER, max: MAX_DIAMETER
};
var bendSpecs = {
    min: -135, max: 135, step: 5,
    pipsStep: 9, unit: "°"
};
var lengthSpecs = {
    label: "Length", min: 0, max: 300
};
var defaultDiameter = 15;
var TubeBend = /** @class */ (function () {
    function TubeBend() {
    }
    TubeBend.prototype.render = function (appearance) {
        var diameter = appearance.diameter;
        var semilength = 25;
        var onesidelength = appearance.length;
        var angle = appearance.angle;
        var _a = tubeBend(diameter, angle, semilength, onesidelength), topWall = _a.topWall, bottomWall = _a.bottomWall, snapping = _a.snapping;
        var graphic = new Group([topWall, bottomWall]);
        setDefaultStyle(graphic);
        var hitShape = new Path(__spreadArray(__spreadArray([], topWall.segments), bottomWall.segments));
        // let pivot = angle != 0 ? P(semilength, 0).add([rotationPivotDistance * Math.tan(angle.toRadians() / 2), 0]) : P(semilength + tubeBendLength / 2, 0)
        return {
            graphic: graphic,
            hitShape: hitShape,
            snapping: [
                { type: "connectable", at: P(0, 0), facing: -90 },
                snapping,
            ]
        };
    };
    TubeBend.properties = {
        label: "Tube bend",
        kind: "tube",
        defaultAppearance: {
            angle: -75,
            diameter: defaultDiameter,
            length: 25
        },
        appearanceSpecs: [
            __assign({ spec: "slider", key: "angle", label: "Bend Angle" }, bendSpecs),
            TUBE_DIAMETER_SPEC,
            __assign({ spec: "slider", key: "length" }, lengthSpecs),
        ],
    };
    return TubeBend;
}());
export { TubeBend };
var Tube = /** @class */ (function () {
    function Tube() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 30, maxX: 600,
                minY: 20, maxY: 20,
                fromAppearance: function (a) { return P(a.length, 20); },
                toAppearance: function (f, p) { return f({ length: p.x }); },
                scale: 2.0,
            }
        ];
    }
    Tube.prototype.render = function (appearance) {
        var radius = appearance.diameter / 2;
        var length = appearance.length;
        var topWall = new Path(Segments([[0, -radius]], [[length, -radius]]));
        var bottomWall = new Path(Segments([[0, radius]], [[length, radius]]));
        var graphic = new Group([topWall, bottomWall]);
        setDefaultStyle(graphic);
        var hitShape = Shape.Rectangle(R(0, -radius, length, radius * 2));
        return {
            graphic: graphic,
            hitShape: hitShape,
            snapping: [{
                    type: "connectable", at: P(0, 0), facing: -90,
                }, {
                    type: "connectable", at: P(length, 0), facing: 90,
                }]
        };
    };
    Tube.properties = {
        label: "Straight tube",
        kind: "tube",
        defaultAppearance: {
            length: 100,
            diameter: defaultDiameter,
        },
        appearanceSpecs: [
            TUBE_DIAMETER_SPEC,
            {
                spec: "slider", key: "length", label: "Length",
                min: 30, max: 600
            },
        ],
    };
    return Tube;
}());
export { Tube };
var FourWayCrossing = /** @class */ (function () {
    function FourWayCrossing() {
    }
    FourWayCrossing.prototype.render = function (appearance) {
        var diameter = appearance.diameter;
        var radius = diameter / 2;
        var outer = radius + 20;
        var path = new CompoundPath({});
        path.moveTo(P(radius, -outer));
        path.lineTo(P(radius, -radius));
        path.lineTo(P(outer, -radius));
        path.moveBy(P(0, diameter));
        path.lineTo(P(radius, radius));
        path.lineTo(P(radius, outer));
        path.moveBy(P(-diameter, 0));
        path.lineTo(P(-radius, radius));
        path.lineTo(P(-outer, radius));
        path.moveBy(P(0, -diameter));
        path.lineTo(P(-radius, -radius));
        path.lineTo(P(-radius, -outer));
        setDefaultStyle(path);
        return {
            graphic: path, hitShape: path.bounds.toShape(),
            snapping: [
                { type: "connectable", at: P(0, outer), facing: 180 },
                { type: "connectable", at: P(outer, 0), facing: 90 },
                { type: "connectable", at: P(0, -outer), facing: 0 },
                { type: "connectable", at: P(-outer, 0), facing: -90 },
            ]
        };
    };
    FourWayCrossing.properties = {
        label: "4-Way Crossing",
        kind: "tube",
        defaultAppearance: {
            diameter: defaultDiameter
        },
        appearanceSpecs: [
            TUBE_DIAMETER_SPEC
        ],
    };
    return FourWayCrossing;
}());
export { FourWayCrossing };
var ThreeWayCrossing = /** @class */ (function () {
    function ThreeWayCrossing() {
    }
    ThreeWayCrossing.prototype.render = function (appearance) {
        var diameter = appearance.diameter;
        var radius = diameter / 2;
        var outer = radius + 20;
        var path = new CompoundPath({});
        path.moveTo(P(radius, -outer));
        path.lineTo(P(radius, -radius));
        path.lineTo(P(outer, -radius));
        path.moveBy(P(0, diameter));
        path.lineTo(P(radius, radius));
        path.lineTo(P(radius, outer));
        path.moveBy(P(-diameter, 0));
        path.lineTo(P(-radius, -outer));
        setDefaultStyle(path);
        return {
            graphic: path, hitShape: path.bounds.toShape(),
            snapping: [
                { type: "connectable", at: P(0, outer), facing: 180 },
                { type: "connectable", at: P(0, -outer), facing: 0 },
                { type: "connectable", at: P(outer, 0), facing: 90 },
            ]
        };
    };
    ThreeWayCrossing.properties = {
        label: "3-Way Crossing",
        kind: "tube",
        flippable: true,
        defaultAppearance: {
            diameter: defaultDiameter
        },
        appearanceSpecs: [
            TUBE_DIAMETER_SPEC
        ],
    };
    return ThreeWayCrossing;
}());
export { ThreeWayCrossing };
var UTube = /** @class */ (function () {
    function UTube() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 0, maxX: 0,
                minY: 30, maxY: 150,
                fromAppearance: function (a) { return P(0, a.size); },
                toAppearance: function (f, p) { return f({ size: p.y }); },
                scale: 2.0,
            }
        ];
    }
    UTube.prototype.render = function (appearance) {
        var bendRadius = appearance.size;
        var diameter = appearance.diameter;
        var outerRadius = bendRadius + diameter;
        var path = new Path(Segments([[-bendRadius, -50]], [[-bendRadius, 0], undefined, [0, bendRadius * 1.4]], [[bendRadius, 0], [0, bendRadius * 1.4]], [[bendRadius, -50]], [[outerRadius, -50]], [[outerRadius, 0], undefined, [0, outerRadius * 1.4]], [[-outerRadius, 0], [0, outerRadius * 1.4]], [[-outerRadius, -50]]));
        var graphic = breakPath(path, 4);
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            hitShape: path,
            snapping: [
                { type: "connectable", at: P(-bendRadius - diameter / 2, -50), facing: 0 },
                { type: "connectable", at: P(bendRadius + diameter / 2, -50), facing: 0 },
            ]
        };
    };
    UTube.properties = {
        label: "U-Bent Tube",
        kind: "tube",
        defaultAppearance: {
            diameter: defaultDiameter,
            size: 60
        },
        appearanceSpecs: [
            TUBE_DIAMETER_SPEC,
            {
                spec: "slider", key: "size", label: "Size",
                min: 30, max: 150,
            },
        ],
    };
    return UTube;
}());
export { UTube };
var STube = /** @class */ (function () {
    function STube() {
    }
    STube.prototype.render = function (appearance) {
        var diameter = appearance.diameter;
        var leftAngle = appearance.angle1;
        var rightAngle = appearance.angle2;
        var semilength = 15;
        // Length of the section connecting the two end in the middle.
        var middleLength = appearance.length;
        var rightEnd = tubeBend(diameter, rightAngle, semilength, semilength);
        var leftEnd = tubeBend(diameter, leftAngle, semilength, semilength);
        // reflect the left end.
        leftEnd.topWall.scale(-1, P(0, 0));
        leftEnd.bottomWall.scale(-1, P(0, 0));
        // Shift the segments of the right end.
        rightEnd.topWall.segments.forEach(function (s) { return s.point.x += middleLength; });
        rightEnd.bottomWall.segments.forEach(function (s) { return s.point.x += middleLength; });
        // Assembe the top walls and bottom walls separately.
        var graphic = new Group([
            new Path(__spreadArray(__spreadArray([], leftEnd.bottomWall.segments), rightEnd.topWall.segments)),
            new Path(__spreadArray(__spreadArray([], rightEnd.bottomWall.segments), leftEnd.topWall.segments))
        ]);
        setDefaultStyle(graphic);
        // Join everything together to create the hit shape.
        var hitShape = new Path(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], leftEnd.bottomWall.segments), rightEnd.topWall.segments), rightEnd.bottomWall.segments), leftEnd.topWall.segments));
        return {
            graphic: graphic,
            hitShape: hitShape,
            snapping: [
                __assign(__assign({}, rightEnd.snapping), { at: rightEnd.snapping.at.add([middleLength, 0]) }),
                __assign(__assign({}, leftEnd.snapping), { at: leftEnd.snapping.at.multiply(-1), facing: leftEnd.snapping.facing + 180 })
            ]
        };
    };
    STube.properties = {
        label: "S-Bent Tube",
        kind: "tube",
        flippable: true,
        defaultAppearance: {
            diameter: defaultDiameter,
            angle1: 90,
            angle2: 90,
            length: 80
        },
        appearanceSpecs: [
            TUBE_DIAMETER_SPEC,
            __assign({ spec: "slider", key: "angle1", label: "First angle" }, bendSpecs),
            __assign({ spec: "slider", key: "angle2", label: "Second angle" }, bendSpecs),
            {
                spec: "slider", key: "length", label: "Length",
                min: 0, max: 120
            }
        ],
    };
    return STube;
}());
export { STube };
var AdapterTube = /** @class */ (function () {
    function AdapterTube() {
    }
    AdapterTube.prototype.render = function (appearance) {
        var radius1 = appearance.diameter1 / 2;
        var radius2 = appearance.diameter2 / 2;
        var hitShape = new Path(Segments(
        // Top
        [[-30, -radius1]], [[-15, -radius1], undefined, [5, 0]], [[15, -radius2], [-5, 0]], [[30, -radius2]], 
        // Bottom
        [[30, radius2]], [[15, radius2], undefined, [-5, 0]], [[-15, radius1], [5, 0]], [[-30, radius1]]));
        var graphic = breakPath(hitShape, 4);
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            hitShape: hitShape,
            snapping: [
                { type: "connectable", at: P(-30, 0), facing: -90 },
                { type: "connectable", at: P(30, 0), facing: 90 },
            ]
        };
    };
    AdapterTube.properties = {
        label: "Adapter Tube",
        defaultAppearance: {
            diameter1: 8,
            diameter2: defaultDiameter,
        },
        appearanceSpecs: [
            {
                spec: "slider", min: MIN_DIAMETER, max: MAX_DIAMETER,
                key: "diameter1", label: "First diameter",
            },
            {
                spec: "slider", min: MIN_DIAMETER, max: MAX_DIAMETER,
                key: "diameter2", label: "Second diameter",
            }
        ],
    };
    return AdapterTube;
}());
export { AdapterTube };
/**
 * Helper method to draw a tube bend.
 *
 *               r     D   D2
 *                   /   /
 *           e     C   C2
 *   A ------- B /   /    e = semi length
 *   o            p /     r = rotary pivot
 *   A2 ------ B2 -/      o = origin, p = pivot
 * Parameters:
 *  - diameter: The thickness of the tube
 *  - angle: The angle the tube should bend
 *  - semilength: length from A -> B and C -> D
 * Returns:
 *  - topWall: (A,B,C,D)
 *  - bottomWall: (D2,C2,B2,A2)
 *  - snapPoint: The snappoint for the D-end. A-end is simply P(0, 0)
 */
function tubeBend(diameter, angle, semilength, onesidelength) {
    var radius = diameter / 2;
    // Length dedicated to drawing the bend.
    var tubeBendLength = diameter * 3;
    // Set up the pivot around which the tube bends
    var rotationPivotDistance = angle != 0 ? tubeBendLength / angle.toRadians() : 100;
    var r = P(semilength, rotationPivotDistance);
    // Unit direction vector that determines where to draw
    // point D from C and also the (inverse) handleIn for C.
    var directionC = P(1, 0).rotate(angle);
    // Same, but for point B, from A to B
    var directionB = P(1, 0);
    // Set up the points that define the bend. Watch out for angle = 0
    var a = P(0, -radius);
    var b = P(semilength, -radius);
    var c = angle != 0 ? b.rotate(angle, r) : b.add([tubeBendLength, 0]);
    var d = c.add(directionC.multiply(onesidelength));
    var a2 = P(0, radius);
    var b2 = P(semilength, radius);
    var c2 = angle != 0 ? b2.rotate(angle, r) : b2.add([tubeBendLength, 0]);
    var d2 = c2.add(directionC.multiply(onesidelength));
    // Factor by which to scale handle points in the bend.
    var handlesFactor = tubeBendLength * 0.4;
    var outerFactor = handlesFactor * (1 + radius / rotationPivotDistance);
    var innerFactor = handlesFactor * (1 - radius / rotationPivotDistance);
    var topHandlesFactor = outerFactor;
    var bottomHandleFactor = innerFactor;
    var topWall = new Path([
        S(a),
        S(b, undefined, directionB.multiply(topHandlesFactor)),
        S(c, directionC.multiply(-topHandlesFactor)),
        S(d)
    ]);
    // Constructed in reverse.
    var bottomWall = new Path([
        S(d2),
        S(c2, undefined, directionC.multiply(-bottomHandleFactor)),
        S(b2, directionB.multiply(bottomHandleFactor)),
        S(a2),
    ]);
    return {
        topWall: topWall,
        bottomWall: bottomWall,
        snapping: {
            type: "connectable",
            at: angle != 0
                ? P(semilength, 0).rotate(angle, r).add(directionC.multiply(onesidelength))
                : P(d.x, 0),
            facing: 90 + angle
        }
    };
}
