import { strokeWidth } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var TapeMeasure = /** @class */ (function () {
    function TapeMeasure() {
    }
    TapeMeasure.prototype.render = function (appearance) {
        var cmlength = appearance.length;
        var length = cmlength * 10;
        var tape = Shape.Rectangle(R(-5, 0, length + 5, 25));
        tape.withStroke(strokeWidth("thinner"), "#8c6d2e")
            .withFill("#f9cb01");
        var tapeEnd = Shape.Rectangle(R(length + 7, 5, 20, 15, Pivot.TOP_RIGHT), 2)
            .withFill("#222222");
        var casing = Shape.Rectangle(R(-71, -3, 70, 31), 2.0);
        casing.withStroke(strokeWidth("default"), "#333333")
            .withGradientFill("right", Gradients.box("#444444".color()));
        var graphic = new Group([tape]);
        // Add markings
        var mark = 1;
        var markX = length - 10;
        while (markX > 0) {
            var color = mark % 10 == 0 ? "red" : "black";
            graphic.addChild(new Path.Line(P(markX, 0), P(markX, 10)).withStroke(strokeWidth("thinner"), color));
            markX -= 10;
            mark += 1;
        }
        graphic.addChild(tapeEnd);
        graphic.addChild(casing);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: casing.bounds.center,
        };
    };
    TapeMeasure.properties = {
        label: "Tape measure",
        defaultAppearance: {
            length: 30,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "length", label: "Length",
                min: 0, max: 90, unit: "cm"
            }
        ],
    };
    return TapeMeasure;
}());
export { TapeMeasure };
