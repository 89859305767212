import { mirrorX } from "apparatus/draw";
import { SVG } from "apparatus/svg";
import { Group, Path } from "paper";
import { setDefaultStyle } from "../../draw";
import { CenterR, Segments } from "../common";
var SpiritLamp = /** @class */ (function () {
    function SpiritLamp() {
    }
    SpiritLamp.prototype.render = function (appearance) {
        var bottle = new Path(Segments([[10, 0]], [[10, 10], undefined, [30, 20]], [[20, 70], [35, -15]], [[25, 78], [5, -5]]));
        var wick = new Path(Segments([[0, -12]], [[0, 20], undefined, [0, 30]], [[-13, 66], [70, 0]]));
        mirrorX(bottle);
        var liquidMask = bottle.clone();
        setDefaultStyle(bottle);
        wick.strokeWidth = 5.0;
        wick.strokeColor = "#cccc3f"; // Yellowish.
        var cap = CenterR(0, 3, 24, 10).toShape();
        setDefaultStyle(cap);
        cap.fillColor = "#eeeeee"; // Light grey.
        var graphic = new Group([bottle, wick, cap]);
        if (appearance.flame == "red") {
            var flame = SVG.redFlame();
            flame.scale(appearance.flameScale);
            graphic.addChild(flame);
            flame.position.x = 0;
            flame.position.y = -10;
        }
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.bounds.toShape(),
            pivot: bottle.bounds.center,
            snapping: graphic.placeable(),
        };
    };
    SpiritLamp.properties = {
        label: "Spirit Lamp",
        canContainLiquids: true,
        defaultAppearance: {
            flame: "red",
            flameScale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "flame", label: "Flame",
                options: [
                    { value: "off", label: "Off" },
                    { value: "red", label: "Red flame" },
                ],
            },
            {
                spec: "slider", key: "flameScale", label: "Flame size",
                min: 0.5, max: 1.5, step: 0.05, tier: "boost",
            }
        ],
    };
    return SpiritLamp;
}());
export { SpiritLamp };
