import { addEdgeLipsReversed, mirrorX, setDefaultStyle } from "apparatus/draw";
import * as p from "paper";
import { Segments } from "./common";
var ReagentBottle = /** @class */ (function () {
    function ReagentBottle() {
    }
    ReagentBottle.prototype.render = function (appearance) {
        var height = appearance.height;
        var width = appearance.width;
        var neck = width / 2.333;
        var bottle = new p.Path(Segments([[neck, 0]], [[neck - 2, 25], undefined, [neck, 0]], [[width, 40], [0, -10]], [[width, height]]));
        mirrorX(bottle);
        var liquidMask = bottle.clone();
        addEdgeLipsReversed(bottle);
        var graphic = new p.Group([bottle]);
        if (appearance.lid != "none") {
            var lid1 = new p.Path(Segments([[neck + 3, -5]], [[neck + 3, 0]]));
            mirrorX(lid1);
            lid1.closePath();
            var lid2 = new p.Path(Segments([[neck - 3, 0]], [[neck - 6, 20]]));
            mirrorX(lid2);
            var lid = new p.Group([lid1, lid2]);
            switch (appearance.lid) {
                case "closed":
                    lid.position.y -= 5;
                    break;
                case "lifted":
                    lid.position.y -= 40;
                    break;
                case "tilted":
                    lid.position.y -= 30;
                    lid.position.x += 30;
                    lid.rotation = 30;
                    break;
            }
            graphic.addChild(lid);
        }
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            liquidMask: liquidMask,
            snapping: graphic.placeable(),
        };
    };
    ReagentBottle.properties = {
        label: "Reagent bottle",
        canContainLiquids: true,
        flippable: true,
        defaultAppearance: {
            lid: "closed",
            height: 131,
            width: 35,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "height", label: "Height",
                min: 90, max: 150,
            },
            {
                spec: "slider", key: "width", label: "Diameter",
                min: 25, max: 40,
            },
            {
                spec: "radio", key: "lid", label: "Lid appearance",
                options: [
                    { value: "none", label: "No lid" },
                    { value: "closed", label: "Closed" },
                    { value: "lifted", label: "Lifted above" },
                    { value: "tilted", label: "Lift tilted" },
                ]
            }
        ],
    };
    return ReagentBottle;
}());
export { ReagentBottle };
