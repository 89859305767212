var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P } from "apparatus/library/common";
import { BasicShapes } from "apparatus/library/shapes/common";
import { CompoundPath } from "paper";
var Star = /** @class */ (function () {
    function Star() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 0, maxX: 0,
                minY: -150, maxY: -8,
                toAppearance: function (f, p) { return f({
                    radius: -p.y,
                }); },
                fromAppearance: function (a) { return P(0, -a.radius); },
                scale: 1.0,
            }
        ];
    }
    Star.prototype.render = function (appearance) {
        var points = appearance.points;
        var radius = appearance.radius;
        var strideAngle = 360 / points;
        var thickness = appearance.thickness;
        var origin = P(0, 0);
        var outerPoint = P(0, -radius);
        var innerPoint = P(0, -radius * thickness).rotate(strideAngle / 2, origin);
        var graphic = new CompoundPath({});
        graphic.moveTo(outerPoint);
        graphic.lineTo(innerPoint);
        for (var i = 0; i < points - 1; i++) {
            outerPoint = outerPoint.rotate(strideAngle, origin);
            innerPoint = innerPoint.rotate(strideAngle, origin);
            graphic.lineTo(outerPoint);
            graphic.lineTo(innerPoint);
        }
        graphic.closePath();
        BasicShapes.applyStyle(graphic, appearance);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            watermark: outerPoint,
        };
    };
    Star.properties = {
        label: "Star",
        defaultAppearance: __assign({ radius: 50, thickness: 0.55, points: 5 }, BasicShapes.defaultAppearance),
        appearanceSpecs: __spreadArray(__spreadArray([], BasicShapes.specs), [
            BasicShapes.header,
            {
                spec: "slider", key: "points", label: "Points",
                min: 4, max: 8, step: 1,
            },
            {
                spec: "slider", key: "radius", label: "Radius",
                min: 8, max: 150
            },
            {
                spec: "slider", key: "thickness", label: "Thickness",
                min: 0.2, max: 0.8, step: 0.05,
            },
        ]),
    };
    return Star;
}());
export { Star };
