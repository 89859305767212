var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { setDefaultStyle } from "apparatus/draw";
import { DEFAULT_LIQUID_DATA, LIQUID_OPACITY_RANGE, P, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
import { setLiquidStyle } from "../../draw";
var Manometer = /** @class */ (function () {
    function Manometer() {
    }
    Manometer.convertToLatest = function (input) {
        if ("size" in input) {
            // Convert v0
            return {
                gap: input.size,
                length: input.size * 4,
                color: input.color,
                opacity: DEFAULT_LIQUID_DATA.alpha,
                leftLevel: 0.5 - input.balance / 2,
                leftDiameter: 20,
                leftExtraLength: 0,
                rightLevel: 0.5 + input.balance / 2,
                rightDiameter: 20,
                rightExtraLength: 0,
            };
        }
        return input;
    };
    Manometer.prototype.render = function (appearance) {
        // Length above the bend.
        var length = appearance.length;
        var innerRadius = appearance.gap;
        var outerRadiusL = innerRadius + appearance.leftDiameter;
        var outerRadiusR = innerRadius + appearance.rightDiameter;
        var radiusForHandles = Math.min(outerRadiusL, outerRadiusR);
        var lengthL = length + appearance.leftExtraLength;
        var lengthR = length + appearance.rightExtraLength;
        var outerShell = new Path(Segments([[-outerRadiusL, -lengthL]], [[-outerRadiusL, 0], undefined, [0, radiusForHandles * 1.4]], [[outerRadiusR, 0], [0, radiusForHandles * 1.4]], [[outerRadiusR, -lengthR]]));
        var innerShell = new Path(Segments([[-innerRadius, -lengthL]], [[-innerRadius, 0], undefined, [0, innerRadius * 1.4]], [[innerRadius, 0], [0, innerRadius * 1.4]], [[innerRadius, -lengthR]]));
        setDefaultStyle(outerShell, innerShell);
        innerShell.reverse();
        var fluid = new (Path.bind.apply(Path, __spreadArray(__spreadArray([void 0], outerShell.segments.map(function (s) { return s.clone(); })), innerShell.segments.map(function (s) { return s.clone(); }))))();
        fluid.closePath();
        // Choose levels on either side. Keep it below length.
        var rightLevel = appearance.rightLevel * lengthR;
        var leftLevel = appearance.leftLevel * lengthL;
        fluid.segments[0].point.y = -leftLevel;
        fluid.segments[7].point.y = -leftLevel;
        fluid.segments[3].point.y = -rightLevel;
        fluid.segments[4].point.y = -rightLevel;
        setLiquidStyle(fluid, appearance.color, appearance.opacity);
        var graphic = new Group([fluid, innerShell, outerShell]);
        return {
            graphic: graphic,
            pivot: P(0, 0),
            snapping: [
                { type: "connectable", at: P(-(outerRadiusL + innerRadius) / 2, -lengthL), facing: 0 },
                { type: "connectable", at: P((outerRadiusR + innerRadius) / 2, -lengthR), facing: 0 },
            ],
            hitShape: graphic.bounds.toShape(),
            watermark: graphic.bounds.bottomCenter,
        };
    };
    Manometer.properties = {
        label: "Manometer",
        defaultAppearance: {
            gap: 40,
            length: 160,
            color: DEFAULT_LIQUID_DATA.color,
            opacity: DEFAULT_LIQUID_DATA.alpha,
            leftLevel: 0.4,
            leftDiameter: 20,
            leftExtraLength: 0,
            rightLevel: 0.6,
            rightDiameter: 20,
            rightExtraLength: 0,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "gap", label: "Gap",
                min: 20, max: 60,
            },
            {
                spec: "slider", key: "length", label: "Length",
                min: 30, max: 300,
            },
            {
                spec: "color", key: "color", label: "Colour",
                opacity: { key: "opacity", range: LIQUID_OPACITY_RANGE },
            },
            {
                spec: "header", icon: "arrow-to-left", header: "Left arm"
            },
            {
                spec: "slider", key: "leftLevel", label: "Liquid level",
                min: 0, max: 1, step: 0.01, unit: "%"
            },
            {
                spec: "slider", key: "leftDiameter", label: "Diameter",
                min: 8, max: 100, unit: "mm"
            },
            {
                spec: "slider", key: "leftExtraLength", label: "Extra length",
                min: 0, max: 200, unit: "mm"
            },
            {
                spec: "header", icon: "arrow-to-right", header: "Right arm"
            },
            {
                spec: "slider", key: "rightLevel", label: "Liquid level",
                min: 0, max: 1, step: 0.01, unit: "%"
            },
            {
                spec: "slider", key: "rightDiameter", label: "Diameter",
                min: 8, max: 100, unit: "mm"
            },
            {
                spec: "slider", key: "rightExtraLength", label: "Extra length",
                min: 0, max: 200, unit: "mm"
            },
        ],
    };
    Manometer = __decorate([
        staticImplements()
    ], Manometer);
    return Manometer;
}());
export { Manometer };
