import { P, Pivot, R, SharedColors, SharedGradients } from "apparatus/library/common";
import { Group, Path } from "paper";
var MagnifyingGlass = /** @class */ (function () {
    function MagnifyingGlass() {
    }
    MagnifyingGlass.prototype.render = function (appearance) {
        var loupeRadius = 50;
        var glass = new Path.Circle(P(0, -8 - loupeRadius), loupeRadius)
            .withGradientFill("right-down", SharedGradients.glass)
            .withStroke("thick");
        var handle = new Path.Rectangle(R(0, 0, 18, 100, Pivot.TOP_CENTER), 2.0)
            .withStroke("default")
            .withGradientFill("right", SharedGradients.cylinderDarkMetal);
        var handle2 = new Path.Rectangle(R(0, -8, 10, 8, Pivot.TOP_CENTER)).withFill(SharedColors.stroke);
        var graphic = new Group([handle, glass, handle2]);
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    MagnifyingGlass.properties = {
        label: "Magnifying Glass",
        defaultAppearance: {
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 1.2, step: 0.01, unit: "%",
            }
        ],
    };
    return MagnifyingGlass;
}());
export { MagnifyingGlass };
