var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { Path, Rectangle, Shape } from "paper";
import { addEdgeLips, breakPath, mirrorX, setDefaultStyle } from "../../draw";
import { P, Segments } from "../common";
var BoilingTube = /** @class */ (function () {
    function BoilingTube() {
    }
    BoilingTube.prototype.render = function (appearance) {
        var width = appearance.width;
        var height = appearance.height;
        var graphic = new Path();
        graphic.addSegments(Segments([[0, 0]], [[0, height - width / 2], undefined, [0, width / 1.55]]));
        mirrorX(graphic, width / 2);
        addEdgeLips(graphic, { lipSize: 2.0 });
        var liquidMask = graphic.clone();
        setDefaultStyle(graphic);
        var hitShape = Shape.Rectangle(new Rectangle(0, 0, width, height));
        return {
            graphic: graphic,
            hitShape: hitShape,
            liquidMask: liquidMask,
            liquidMaskOpening: { start: P(0, 0), end: P(width, 0) },
            snapping: [
                { type: "neck", top: P(width / 2, 0), diameter: width, facing: 0 }
            ]
        };
    };
    BoilingTube.properties = {
        label: "Boiling tube",
        canContainLiquids: true,
        meniscus: true,
        pourable: true,
        defaultAppearance: {
            width: 40,
            height: 180,
        },
        appearanceSpecs: [
            {
                spec: "slider",
                key: "width",
                label: "Width",
                min: 20,
                max: 60,
            },
            {
                spec: "slider",
                key: "height",
                label: "Height",
                min: 75,
                max: 300,
            },
        ]
    };
    return BoilingTube;
}());
export { BoilingTube };
var BoilingTubeWithSideArm = /** @class */ (function () {
    function BoilingTubeWithSideArm() {
    }
    BoilingTubeWithSideArm.prototype.render = function (appearance) {
        var width = appearance.width;
        var height = appearance.height;
        var offset = 30;
        var holeRadius = appearance.holeDiameter / 2;
        var sideArmLength = 15;
        var tube = new Path();
        tube.addSegments(Segments([[0, 0]], [[0, height - width / 2], undefined, [0, width / 1.55]]));
        mirrorX(tube, width / 2);
        tube.insertSegments(3, Segments([[width, offset + holeRadius]], [[width + sideArmLength, offset + holeRadius]], [[width + sideArmLength, offset - holeRadius]], [[width, offset - holeRadius]]));
        addEdgeLips(tube, { lipSize: 2.0 });
        var liquidMask = tube.clone();
        var graphics = breakPath(tube, 6);
        setDefaultStyle(graphics);
        var hitShape = Shape.Rectangle(new Rectangle(0, 0, width + sideArmLength, height));
        return {
            graphic: graphics,
            hitShape: hitShape,
            liquidMask: liquidMask,
            snapping: [
                { type: "connectable", at: P(width + sideArmLength, offset), facing: 90, },
                { type: "neck", top: P(width / 2, 0), diameter: width, facing: 0 }
            ]
        };
    };
    BoilingTubeWithSideArm.properties = {
        label: "Boiling Tube with side arm",
        canContainLiquids: true,
        flippable: true,
        defaultAppearance: __assign(__assign({}, BoilingTube.properties.defaultAppearance), { holeDiameter: 10 }),
        appearanceSpecs: __spreadArray(__spreadArray([], BoilingTube.properties.appearanceSpecs), [
            {
                spec: "slider", key: "holeDiameter", label: "Hole diameter",
                min: 8, max: 20,
            }
        ]),
    };
    return BoilingTubeWithSideArm;
}());
export { BoilingTubeWithSideArm };
