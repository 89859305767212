import { P, Segments } from "apparatus/library/common";
import { Path } from "paper";
import { breakPath, mirrorX, setDefaultStyle } from "../../draw";
var WaterlessCondenser = /** @class */ (function () {
    function WaterlessCondenser() {
    }
    WaterlessCondenser.prototype.render = function (appearance) {
        var outline = new Path(Segments([[7.5, 0]], [[7.5, 30], undefined, [10, 5]], [[16, 50]]));
        for (var i = 0; i < appearance.height; i++) {
            outline.cubicCurveBy(P(8, 0), P(8, 13), P(0, 13));
            outline.cubicCurveBy(P(-3, 0), P(-3, 10), P(0, 10));
        }
        // Remove last segment
        outline.lastSegment.remove();
        var lastY = outline.lastSegment.point.y;
        outline.lastSegment.handleOut = P(0, 7);
        outline.lineTo(P(7.5, lastY + 15));
        outline.lineBy(P(0, 20));
        var maxY = outline.lastSegment.point.y;
        mirrorX(outline);
        var liquidMask = outline.clone();
        var graphic = breakPath(outline, outline.segments.length / 2);
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.bounds.toShape(),
            snapping: [
                { type: "connectable", at: P(0, 0), facing: 0 },
                { type: "connectable", at: P(0, maxY), facing: 180 },
            ]
        };
    };
    WaterlessCondenser.properties = {
        label: "Waterless condenser",
        canContainLiquids: true,
        defaultAppearance: {
            height: 10
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "height", label: "Height",
                min: 6, max: 12
            }
        ],
    };
    return WaterlessCondenser;
}());
export { WaterlessCondenser };
