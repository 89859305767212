var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { mirrorX, mirrorY } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
var SF_APPEARANCE_SPEC = {
    spec: "select",
    options: [
        { value: "black", label: "0 (Black)" },
        { value: "brown", label: "1 (Brown)" },
        { value: "red", label: "2 (Red)" },
        { value: "orange", label: "3 (Orange)" },
        { value: "yellow", label: "4 (Yellow)" },
        { value: "green", label: "5 (Green)" },
        { value: "blue", label: "6 (Blue)" },
        { value: "violet", label: "7 (Violet)" },
        { value: "grey", label: "8 (Grey)" },
        { value: "white", label: "9 (White)" },
    ]
};
var Resistor = /** @class */ (function () {
    function Resistor() {
    }
    Resistor.prototype.render = function (appearance) {
        var shape = new Path(Segments([[25, 10]], [[30, 12]], [[40, 12], , [8, 0]]))
            .withFill("#E3E6CE")
            .close();
        mirrorY(shape);
        mirrorX(shape);
        var outline = shape.clone().withStroke("default").withFill(null);
        var shade = shape.clone().withGradientFill("down", [["#ffffffaa", 0.1], ["#ffffff00", 0.4], ["#00000000", 0.65], ["#00000040", 0.9]]);
        shade.blendMode = "hard-light";
        var wireStart = P(-70, 0);
        var wireEnd = P(70, 0);
        // Wire also used for snapping.
        var wire = new Path.Line(wireStart, wireEnd).withStroke("default");
        var graphic = new Group([wire, shape, shade]);
        graphic.addChild(applyColour(bandAtPosition(0), appearance.sf1));
        graphic.addChild(applyColour(bandAtPosition(1), appearance.sf2));
        if (appearance.bands >= 5) {
            graphic.addChild(applyColour(bandAtPosition(2), appearance.sf3));
        }
        graphic.addChild(applyColour(bandAtPosition(appearance.bands >= 5 ? 3 : 2), appearance.multiplier));
        if (appearance.bands >= 4) {
            graphic.addChild(applyColour(bandAtPosition(4), appearance.tolerance));
        }
        if (appearance.bands >= 6) {
            graphic.addChild(applyColour(bandAtPosition(5), appearance.tempCoeff));
        }
        graphic.addChildren([outline]);
        graphic.scale(appearance.scale * appearance.size / 850);
        var hitShape = graphic.bounds.expand(10).toShape();
        return {
            graphic: graphic,
            hitShape: hitShape,
            snapping: [
                { type: "wire", at: wire.segments[0].point },
                { type: "wire", at: wire.segments[1].point },
            ]
        };
    };
    Resistor.properties = {
        label: "Resistor",
        defaultAppearance: {
            size: 85,
            scale: 10.0,
            bands: 4,
            sf1: "yellow",
            sf2: "violet",
            sf3: "red",
            multiplier: "silver",
            tolerance: "gold",
            tempCoeff: "red",
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 60, max: 100, unit: "mm"
            },
            {
                spec: "slider", key: "scale", label: "Zoom",
                min: 5.0, max: 20.0, step: 1, unit: "x"
            },
            {
                spec: "toggle-buttons", key: "bands", label: "Bands", size: "text", options: [
                    { value: 3, label: "3" },
                    { value: 4, label: "4" },
                    { value: 5, label: "5" },
                    { value: 6, label: "6" },
                ]
            },
            __assign({ key: "sf1", label: "Digit 1" }, SF_APPEARANCE_SPEC),
            __assign({ key: "sf2", label: "Digit 2" }, SF_APPEARANCE_SPEC),
            __assign(__assign({ key: "sf3", label: "Digit 3" }, SF_APPEARANCE_SPEC), { visible: function (a) { return a.bands >= 5; } }),
            { key: "multiplier", label: "Multiplier", spec: "select", options: [
                    { value: "black", label: "× 1 (Black)" },
                    { value: "brown", label: "× 10 (Brown)" },
                    { value: "red", label: "× 100 (Red)" },
                    { value: "orange", label: "× 1k (Orange)" },
                    { value: "yellow", label: "× 10k (Yellow)" },
                    { value: "green", label: "× 100k (Green)" },
                    { value: "blue", label: "× 1M (Blue)" },
                    { value: "violet", label: "× 10M (Violet)" },
                    { value: "grey", label: "× 100M (Grey)" },
                    { value: "white", label: "× 1G (White)" },
                    { value: "gold", label: "× 0.1 (Gold)" },
                    { value: "silver", label: "× 0.01 (Silver)" },
                ] },
            { key: "tolerance", label: "Tolerance (%)", spec: "select", visible: function (a) { return a.bands >= 4; }, options: [
                    { value: "brown", label: "1 (F) (Brown)" },
                    { value: "red", label: "2 (G) (Red)" },
                    { value: "green", label: "0.5 (D) (Green)" },
                    { value: "blue", label: "0.25 (C) (Blue)" },
                    { value: "violet", label: "0.1 (B) (Violet)" },
                    { value: "grey", label: "0.05 (A) (Grey)" },
                    { value: "gold", label: "5 (J) (Gold)" },
                    { value: "silver", label: "10 (K) (Silver)" },
                    { value: "none", label: "20 (M) (None)" },
                ] },
            { key: "tempCoeff", label: "Temp. Coeff. (ppm/K)", spec: "select", visible: function (a) { return a.bands >= 6; }, options: [
                    { value: "black", label: "250 (U) (Black)" },
                    { value: "brown", label: "100 (S) (Brown)" },
                    { value: "red", label: "50 (R) (Red)" },
                    { value: "orange", label: "15 (P) (Orange)" },
                    { value: "yellow", label: "25 (Q) (Yellow)" },
                    { value: "green", label: "20 (Z) (Green)" },
                    { value: "blue", label: "10 (Z) (Blue)" },
                    { value: "violet", label: "5 (M) (Violet)" },
                    { value: "grey", label: "1 (K) (Grey)" },
                ] },
        ],
    };
    return Resistor;
}());
export { Resistor };
var BAND_WIDTH = 4;
function bandAtPosition(position) {
    switch (position) {
        case 0:
            return new Path.Rectangle(R(-32, 0, BAND_WIDTH, 24, Pivot.CENTER));
        case 1:
            return new Path.Rectangle(R(-20, 0, BAND_WIDTH, 20, Pivot.CENTER));
        case 2:
            return new Path.Rectangle(R(-10, 0, BAND_WIDTH, 20, Pivot.CENTER));
        case 3:
            return new Path.Rectangle(R(0, 0, BAND_WIDTH, 20, Pivot.CENTER));
        case 4:
            return new Path.Rectangle(R(20, 0, BAND_WIDTH, 20, Pivot.CENTER));
        default:
        case 5:
            return new Path.Rectangle(R(32, 0, BAND_WIDTH, 24, Pivot.CENTER));
    }
}
function applyColour(item, color) {
    switch (color) {
        case "black":
            return item.withFill("#111111");
        case "brown":
            return item.withFill("#7F3B0F");
        case "red":
            return item.withFill("#F81E1E");
        case "orange":
            return item.withFill("#F8B043");
        case "yellow":
            return item.withFill("#F6D739");
        case "green":
            return item.withFill("#49d430");
        case "blue":
            return item.withFill("#4295E3");
        case "violet":
            return item.withFill("#BD2ECE");
        case "grey":
            return item.withFill("#6f6f6f");
        case "white":
            return item.withFill("#fdfdfd");
        case "gold":
            return item.withGradientFill("left-down", Gradients.cylinder("#f6c33f".color()));
        case "silver":
            return item.withGradientFill("left-down", Gradients.cylinder("#bbc1c6".color()));
        case "none":
            return item;
    }
}
