var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { setDefaultStyle } from "apparatus/draw";
import { LIQUID_OPACITY_RANGE, P, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path } from "paper";
import { setLiquidStyle } from "../../draw";
import { DEFAULT_LIQUID_DATA } from "../common";
/** Handle ratio to create a circular shape. */
export var CIRCULAR_RATIO = 0.55;
var OsmosisTube = /** @class */ (function () {
    function OsmosisTube() {
    }
    OsmosisTube.prototype.render = function (appearance) {
        // Length above the bend.
        var length = appearance.size * 2;
        var diameter = 30;
        var innerRadius = appearance.size;
        var outerRadius = innerRadius + diameter;
        var outerShell = new Path(uShapeSegment(outerRadius, length));
        var innerShell = new Path(uShapeSegment(innerRadius, length));
        setDefaultStyle(outerShell, innerShell);
        innerShell.reverse();
        var leftFluid = new (Path.bind.apply(Path, __spreadArray(__spreadArray([void 0], innerShell.segments.slice(2).map(function (s) { return s.clone(); })), outerShell.segments.slice(0, 3).map(function (s) { return s.clone(); }))))();
        var rightFluid = new (Path.bind.apply(Path, __spreadArray(__spreadArray([void 0], outerShell.segments.slice(2).map(function (s) { return s.clone(); })), innerShell.segments.slice(0, 3).map(function (s) { return s.clone(); }))))();
        // Choose levels on either side. Keep it below length.
        var rightLevel = appearance.rightLevel * length;
        var leftLevel = appearance.leftLevel * length;
        leftFluid.segments[2].point.y = -leftLevel;
        leftFluid.segments[3].point.y = -leftLevel;
        rightFluid.segments[2].point.y = -rightLevel;
        rightFluid.segments[3].point.y = -rightLevel;
        // Set colours.
        setLiquidStyle(leftFluid, appearance.leftColor, appearance.leftOpacity);
        setLiquidStyle(rightFluid, appearance.rightColor, appearance.rightOpacity);
        // Add a barrier.
        var barrier = new Path.Line(P(0, innerRadius), P(0, outerRadius));
        barrier.withStroke(4.0, SharedColors.stroke);
        barrier.dashArray = [3, 3];
        var graphic = new Group([leftFluid, rightFluid, barrier, innerShell, outerShell]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            watermark: graphic.bounds.bottomCenter,
        };
    };
    OsmosisTube.properties = {
        label: "Osmosis Tube",
        defaultAppearance: {
            size: 60,
            leftColor: SharedColors.defaultLiquid,
            leftOpacity: DEFAULT_LIQUID_DATA.alpha,
            leftLevel: 0.5,
            rightColor: SharedColors.defaultLiquid,
            rightOpacity: DEFAULT_LIQUID_DATA.alpha,
            rightLevel: 0.5,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 40, max: 80
            },
            { spec: "header", icon: "tint", header: "Left liquid" },
            {
                spec: "color", key: "leftColor", label: "Colour",
                opacity: { key: "leftOpacity", range: LIQUID_OPACITY_RANGE }
            },
            {
                spec: "slider", key: "leftLevel", label: "Level",
                min: 0.0, max: 1.0, step: 0.01
            },
            { spec: "header", icon: "tint", header: "Right liquid" },
            {
                spec: "color", key: "rightColor", label: "Colour",
                opacity: { key: "rightOpacity", range: LIQUID_OPACITY_RANGE }
            },
            {
                spec: "slider", key: "rightLevel", label: "  Level",
                min: 0.0, max: 1.0, step: 0.01
            }
        ],
    };
    return OsmosisTube;
}());
export { OsmosisTube };
var uShapeSegment = function (radius, length) { return Segments([[-radius, -length]], [[-radius, 0], undefined, [0, radius * CIRCULAR_RATIO]], [[0, radius], [-radius * CIRCULAR_RATIO, 0], [radius * CIRCULAR_RATIO, 0]], [[radius, 0], [0, radius * CIRCULAR_RATIO]], [[radius, -length]]); };
