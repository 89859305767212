import { mirrorX, setDefaultStyle, smoothCorner } from "apparatus/draw";
import { Segments } from "apparatus/library/common";
import { Path } from "paper";
var WeightBoat = /** @class */ (function () {
    function WeightBoat() {
    }
    WeightBoat.prototype.render = function (appearance) {
        var size = appearance.size;
        var lipSize = 7;
        var height = 15 + size * 0.15;
        var baseSize = size - height * 0.8;
        var graphic = new Path(Segments([[size / 2 + lipSize, 0]], [[size / 2, 0]], [[baseSize / 2, height]]));
        mirrorX(graphic);
        setDefaultStyle(graphic);
        if (appearance.spout) {
            graphic.segments[2].point.x -= height * 0.8;
            if (graphic.segments[2].point.x < 10) {
                graphic.segments[2].point.x = 10;
            }
        }
        smoothCorner(graphic.segments[3], 3);
        smoothCorner(graphic.segments[2], 3);
        var liquidMask = graphic.clone();
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.bounds.toShape(),
            snapping: graphic.placeable(),
        };
    };
    WeightBoat.properties = {
        label: "Weight boat",
        canContainLiquids: true,
        flippable: true,
        defaultAppearance: {
            size: 60,
            spout: false,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 45, max: 140, unit: "mm",
            },
            { spec: "checkbox", key: "spout", label: "Spout" }
        ],
    };
    return WeightBoat;
}());
export { WeightBoat };
