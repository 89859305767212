import { mirrorX, smoothCornersIndividual } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
function getColours(a) {
    switch (a.color) {
        case "black": return { fill: "#444444".color(), stroke: "#333333" };
        case "white": return { fill: "#eeeeee".color(), stroke: "#666666" };
    }
}
var Camera = /** @class */ (function () {
    function Camera() {
    }
    Camera.prototype.render = function (appearance) {
        var _a = getColours(appearance), fill = _a.fill, stroke = _a.stroke;
        var body = new Path(Segments([[5, -60]], [[10, -50]], [[22, -50]], [[22, 50]]));
        mirrorX(body);
        body.closePath();
        smoothCornersIndividual(body, {
            0: 2.0,
            2: 3.0,
            3: 3.0,
            4: 3.0,
            5: 3.0,
            7: 2.0,
        });
        body.withStroke("default", stroke)
            .withGradientFill("right", Gradients.box(fill, { delta: 4 }));
        var graphic = new Group([
            Shape.Rectangle(R(22, 5, 50, 60, Pivot.CENTER_LEFT), 2.0)
                .withGradientFill("down", Gradients.cylinder(fill, 2))
                .withStroke("default", stroke),
            body,
        ]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    Camera.properties = {
        label: "Camera",
        flippable: true,
        defaultAppearance: {
            color: "black"
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "color", label: "Colour",
                options: [
                    { value: "black", label: "Black" },
                    { value: "white", label: "White", tier: "boost" },
                ],
            }
        ],
    };
    return Camera;
}());
export { Camera };
var VideoCamera = /** @class */ (function () {
    function VideoCamera() {
    }
    VideoCamera.prototype.render = function (appearance) {
        var _a = getColours(appearance), fill = _a.fill, stroke = _a.stroke;
        var out = new Path(Segments([[140, 20]], [[180, 10]], [[180, 70]], [[140, 60]])).withStroke("default", stroke)
            .withGradientFill("down", Gradients.cylinder(fill, 3));
        var graphic = new Group([
            out,
            Shape.Rectangle(R(0, 15, 12, 20, Pivot.CENTER_RIGHT), 2.0)
                .withFill(stroke),
            Shape.Rectangle(R(0, 0, 140, 80), 2.0)
                .withGradientFill("down", Gradients.box(fill, { delta: 6 }))
                .withStroke("default", stroke),
            Shape.Circle(P(125, 15), 5.0)
                .withStroke("thinner", "#fc5c51")
                .withFill("#eb4034")
        ]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    VideoCamera.properties = {
        label: "Video Camera",
        flippable: true,
        defaultAppearance: Camera.properties.defaultAppearance,
        appearanceSpecs: Camera.properties.appearanceSpecs,
    };
    return VideoCamera;
}());
export { VideoCamera };
