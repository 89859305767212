var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { ApparatusComponent } from "apparatus/component";
import { P, RP } from "apparatus/library/common";
import ArrowComponent from "arrows/component";
import { commandProcessor } from "command/command";
import { exportFromCanvas } from "editing/image_export";
import { Move } from "editing/move";
import { RenderAreaBox } from "editing/render_area";
import { clampZoomValue } from "editing/zoom";
import { focusMainApp } from "keyboard";
import { EditorContextMenu } from "layout/editor/context_menu";
import { setUpInitialDiagram } from "layout/editor/initial_diagram";
import { clog } from "log";
import { Size } from "paper";
import * as React from "react";
import { Component, createElement } from "react";
import { ID } from "store/id";
import store from "store/store";
import { NULL_SELECTION_METRICS } from "types";
import { Consts } from "vars";
import { isMac } from "view/keyboard_shortucts";
import { ZoomCommand } from "view/zoom";
import { exportFromCanvasJPGThumbnail } from "../../editing/image_export";
import { isItemUnlocked } from "../../tiers/apparatus";
import { toolCursors } from "../../tools";
var MAC_DRAG_FACTOR = 0.5;
var MAC_PINCH_FACTOR = 0.01;
var DEBOUNCE_DURATION_MS = 40;
var maxPixels = function (tier) {
    if (!tier)
        return Consts.pngMaxRenderPixelsMedium;
    switch (tier) {
        case "boost":
        case "plus":
            return Consts.pngMaxRenderPixels;
    }
};
/**
 * Component that holds the canvas and everything that's drawn
 * inside it, including apparatus components.
 */
var EditorCanvasComponent = /** @class */ (function (_super) {
    __extends(EditorCanvasComponent, _super);
    function EditorCanvasComponent(props) {
        var _this = _super.call(this, props) || this;
        /** Canvas element ref. */
        _this.canvasElementRef = React.createRef();
        // TODO: Avoid this by using states?
        /** Refs to drawable components (apparatus, arrows..). */
        _this.objectRefs = {};
        _this.renderAreaRef = React.createRef();
        _this.state = { isPaperReady: false };
        return _this;
    }
    EditorCanvasComponent.prototype.getApparatusComponent = function (id) {
        return this.objectRefs[ID.string(id)];
    };
    EditorCanvasComponent.prototype.getObjectComponent = function (id) {
        return this.objectRefs[ID.string(id)];
    };
    EditorCanvasComponent.prototype.componentDidMount = function () {
        // Set up canvas
        var canvas = this.canvasElementRef.current;
        paper.setup(canvas);
        this.setState({ isPaperReady: true });
        clog("Paper.js set up");
        this.props.onPaperReady();
        if (!Consts.isDev) {
            setUpInitialDiagram();
        }
        // Set up layers
        var layers = {
            exportOnlyBacking: new paper.Layer(),
            apparatus: new paper.Layer(),
            arrows: new paper.Layer(),
            overlay: new paper.Layer(),
        };
        this.layers = layers;
        // Set the apparatus layer as default.
        layers.apparatus.activate();
    };
    EditorCanvasComponent.prototype.setZoom = function (zoom) {
        paper.project.view.zoom = zoom;
    };
    EditorCanvasComponent.prototype.getProjectBounds = function () {
        var _this = this;
        // Compute bounds of the diagram from individual object bounds.
        var ids = __spreadArray(__spreadArray([], this.props.arrows.map(function (arrow) { return arrow.id; })), this.props.items.map(function (item) { return item.id; }));
        var items = ids.map(function (id) { return _this.objectRefs[ID.string(id)]; });
        if (items.length == 0)
            return new paper.Rectangle(P(0, 0), new Size(0, 0));
        var bounds = new paper.Rectangle(items[0].bounds);
        for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
            var item = items_1[_i];
            bounds.united(item.bounds);
        }
        return bounds;
    };
    EditorCanvasComponent.prototype.export = function () {
        var pixels = maxPixels(this.props.tier);
        var bounds;
        if (this.props.renderArea) {
            var renderArea = this.props.renderArea;
            bounds = RP(renderArea.left, renderArea.top, renderArea.right, renderArea.bottom).expand(2);
        }
        else {
            bounds = this.getProjectBounds();
        }
        return exportFromCanvas(paper.project, this.layers, bounds, pixels);
    };
    EditorCanvasComponent.prototype.exportThumbnail = function () {
        return exportFromCanvasJPGThumbnail(paper.project, this.layers, this.getProjectBounds());
    };
    EditorCanvasComponent.prototype.componentDidUpdate = function () {
        // Reorder drawn items to match the order in the data store.
        this.ensureVisualOrder();
    };
    EditorCanvasComponent.prototype.onCanvasMouseDownOrTouchStart = function () {
        // Return focus to the app.
        focusMainApp();
    };
    EditorCanvasComponent.prototype.render = function () {
        var _this = this;
        var _a;
        // Set zoom on paper project.
        if (this.state.isPaperReady) {
            this.setZoom(this.props.zoom);
        }
        // Render main canvas.
        var canvas = createElement("canvas", {
            key: "MainCanvas",
            className: "editor",
            style: {
                "cursor": (_a = (this.props.tool == "select" ? this.state.renderAreaBoxCursorHint : null)) !== null && _a !== void 0 ? _a : toolCursors(this.props.tool),
            },
            resize: "true",
            ref: this.canvasElementRef,
            onMouseDown: function (e) { return _this.onCanvasMouseDownOrTouchStart(); },
            onTouchStart: function (e) { return _this.onCanvasMouseDownOrTouchStart(); },
            onWheel: function (e) { return _this.onWheel(e); },
            onContextMenu: function (e) {
                e.preventDefault();
                _this.setState({ contextMenu: { position: { x: e.clientX, y: e.clientY } } });
                return false;
            }
        });
        // Render apparatus components.
        var apparatusComponents = this.props.items.map(function (item) {
            return React.createElement(ApparatusComponent
            // Use a key that includes the ID as well as type.
            // ApparatusComponent once set up for a specific
            // apparatus type can't be reused for another type.
            , __assign({ 
                // Use a key that includes the ID as well as type.
                // ApparatusComponent once set up for a specific
                // apparatus type can't be reused for another type.
                key: ID.string(item.id) + "/" + item.type, isWatermarkEnabled: !isItemUnlocked(item, _this.props.tier), zoom: _this.props.zoom, isInteracting: _this.props.isInteracting, ref: function (r) { return r
                    ? _this.objectRefs[ID.string(item.id)] = r
                    : delete _this.objectRefs[ID.string(item.id)]; } }, item));
        });
        // Render arrows.
        var arrows = this.props.arrows.map(function (arrow) {
            return React.createElement(ArrowComponent, __assign(__assign({}, arrow), { key: ID.string(arrow.id), ref: function (r) { return _this.objectRefs[ID.string(arrow.id)] = r; }, zoom: _this.props.zoom, showAdjustables: _this.props.tool == "select"
                    && arrow.selected
                    && !(_this.props.isInteracting == "zoom") }));
        });
        // Temporary arrow, one that the user is currently drawing.
        var currentlyDrawnArrow = this.state.arrow
            ? React.createElement(ArrowComponent, __assign(__assign({ key: "__DrawnArrow" }, this.state.arrow), { showAdjustables: false, zoom: this.props.zoom }))
            : null;
        // Wait until paper is ready to draw everything. Otherwise, only draw the canvas
        // so that paper can initialise itself.
        if (!this.state.isPaperReady) {
            return [canvas];
        }
        return __spreadArray(__spreadArray([
            canvas,
            React.createElement(EditorContextMenu, { key: "ContextMenu", contextMenu: this.state.contextMenu, onClose: function () { return _this.setState({ contextMenu: undefined }); }, selected: this.props.selected }),
            this.props.renderArea ?
                React.createElement(RenderAreaBox, __assign({ key: "Renderbox", layer: this.layers.overlay }, this.props.renderArea, { interactable: this.props.tool == "select", ref: this.renderAreaRef, zoom: this.props.zoom, setCursorHint: function (h) { _this.setState({ renderAreaBoxCursorHint: h }); } })) :
                undefined,
            // The following are "virtual components", they don't
            // contain any DOM, because the drawing is handled
            // within the components, via paper.
            currentlyDrawnArrow
        ], apparatusComponents), arrows);
    };
    EditorCanvasComponent.prototype.onWheel = function (e) {
        var _this = this;
        // Different behaviour depending on platform:
        if (isMac() || true) {
            if (e.ctrlKey) {
                // Zoom. Pinch in => Positive. Pinch out => negative
                var newZoom_1 = clampZoomValue(this.props.zoom - e.deltaY * MAC_PINCH_FACTOR);
                commandProcessor.executeReplacingLastCommand(function (lastCommand) {
                    if (!(lastCommand instanceof ZoomCommand))
                        return false;
                    return new ZoomCommand(lastCommand.from, newZoom_1);
                }, new ZoomCommand(this.props.zoom, newZoom_1));
            }
            else {
                // Drag
                // Natural: Drag Down => negative, drag (left to) right => negative
                var delta = P(-e.deltaX, -e.deltaY).multiply(MAC_DRAG_FACTOR);
                if (this.debounceTimeoutHandle) {
                    this.debouncedDragDelta = this.debouncedDragDelta.add(delta);
                }
                else {
                    this.debouncedDragDelta = delta;
                    this.debounceTimeoutHandle = setTimeout(function () {
                        var allIds = __spreadArray(__spreadArray([], store.items().map(function (i) { return i.id; })), store.arrows().map(function (a) { return a.id; }));
                        // TODO: Support Render area Frames
                        commandProcessor.executeReplacingLastCommand(function (lastCommand) {
                            if (!(lastCommand instanceof Move))
                                return false;
                            return new Move(allIds, lastCommand.delta.add(_this.debouncedDragDelta));
                        }, new Move(allIds, _this.debouncedDragDelta));
                        clearTimeout(_this.debounceTimeoutHandle);
                        _this.debounceTimeoutHandle = undefined;
                    }, DEBOUNCE_DURATION_MS);
                }
            }
        }
        else {
            // Scroll wheel zooms only. Up => negative deltaY
            var newZoom_2 = clampZoomValue(this.props.zoom - e.deltaY / 1000);
            commandProcessor.executeReplacingLastCommand(function (lastCommand) {
                if (!(lastCommand instanceof ZoomCommand))
                    return false;
                return new ZoomCommand(lastCommand.from, newZoom_2);
            }, new ZoomCommand(this.props.zoom, newZoom_2));
        }
    };
    /**
     * Ensure that paper items order matches the order in data store. Higher index
     * in the data store implies the apparatus is drawn later/above lower index
     * apparatus. (index 0 => drawn first | at the bottom)
     */
    EditorCanvasComponent.prototype.ensureVisualOrder = function () {
        // Reorder Paper elements according to position.
        for (var i = 1; i < this.props.items.length; i++) {
            var id = ID.string(this.props.items[i].id);
            var prevId = ID.string(this.props.items[i - 1].id);
            var component = this.objectRefs[id];
            var previousComponent = this.objectRefs[prevId];
            component.item.moveAbove(previousComponent.item);
        }
    };
    /** Compute the bounds on the current active selection. */
    EditorCanvasComponent.prototype.computeSelectionMetrics = function () {
        var _this = this;
        var _a;
        var ids = __spreadArray(__spreadArray([], this.props.selected.arrows.map(function (arrow) { return arrow.id; })), this.props.selected.items.map(function (item) { return item.id; }));
        var items = ids.map(function (id) { return _this.getObjectComponent(id); });
        if (items.length == 0) {
            return NULL_SELECTION_METRICS;
        }
        if (items.length == 1) {
            var item = items[0];
            // For certain items, calculate the bounds center and use that as the pivot.
            var shouldUseBoundsCenter = (item instanceof ApparatusComponent) ? (_a = item.apparatus.shouldUseBoundsCenter) !== null && _a !== void 0 ? _a : false : false;
            // Only one selected apparatus, take its natural position as pivot
            // and bounds.
            return {
                pivot: shouldUseBoundsCenter ? items[0].bounds.center : items[0].currentPosition,
                bounds: items[0].bounds
            };
        }
        else {
            // Multiple items. Calculate the encompassing bounds
            // and take its center as pivot.
            var encompassingBounds = new paper.Rectangle(items[0].bounds);
            for (var _i = 0, items_2 = items; _i < items_2.length; _i++) {
                var item = items_2[_i];
                encompassingBounds.united(item.bounds);
            }
            return {
                pivot: encompassingBounds.center,
                bounds: encompassingBounds,
            };
        }
    };
    return EditorCanvasComponent;
}(Component));
export { EditorCanvasComponent };
