import { Group, Path, Shape } from "paper";
import { Random } from "random/random";
import { P, Segments } from "../common";
var Powder = /** @class */ (function () {
    function Powder() {
    }
    Powder.prototype.render = function (appearance) {
        var fillColor = appearance.color;
        var strokeColor = fillColor.color().darken(0.3).hex();
        var outline = createOutline(appearance);
        outline.closePath();
        if (appearance.shape != "round" && appearance.bottom == "round") {
            // Smooth the bottom by adding handles
            // on the first and last segment.
            outline.firstSegment.handleIn.set(0, 5);
            outline.lastSegment.handleOut.set(0, 5);
        }
        outline.withGradientFill("right-down", [
            [fillColor, 0.0],
            [fillColor.color().darken(0.1).hex(), 1.0]
        ]).withStroke("thinner", strokeColor);
        outline.scale(appearance.size / 1.5);
        var graphic;
        if (appearance.particles) {
            // Add particles by creating dots randomly within
            // the bounds of the graphic.
            graphic = new Group([outline]);
            // Particle color: If the fill color is dark enough,
            // choose a light colour, otherwise choose the same
            // color as the stroke colour.
            var particleColor = fillColor.color().luminosity() > 0.1
                ? strokeColor
                : fillColor.color().lighten(0.2).hex();
            var r = new Random();
            var bounds = outline.bounds;
            for (var y = bounds.top; y <= bounds.bottom; y += 5) {
                for (var x = bounds.left; x <= bounds.right; x += 5) {
                    var position = P(x, y).add(r.randomCenteredValue(1.5));
                    if (!outline.contains(position))
                        continue;
                    var particle = Shape.Circle(position, 1.0);
                    particle.fillColor = particleColor;
                    graphic.addChild(particle);
                }
            }
        }
        else {
            graphic = outline;
        }
        return { graphic: graphic, hitShape: graphic.bounds.expand(10).toShape() };
    };
    Powder.properties = {
        label: "Powder pile",
        defaultAppearance: {
            size: 1.5,
            color: "#90cc89",
            shape: "smooth",
            bottom: "round",
            particles: true,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 1.0, max: 5.0, step: 0.1,
            },
            {
                spec: "color", key: "color", label: "Colour",
            },
            {
                spec: "radio", key: "shape", label: "Shape",
                options: [
                    { value: "smooth", label: "Smooth" },
                    { value: "flat", label: "Flat" },
                    { value: "lumpy", label: "Lumpy" },
                    { value: "round", label: "Round" },
                ],
            },
            {
                spec: "radio", key: "bottom", label: "Bottom shape",
                options: [
                    { value: "round", label: "Round" },
                    { value: "flat", label: "Flat" },
                ],
                visible: function (a) { return a.shape === "lumpy" || a.shape === "smooth" || a.shape === "flat"; }
            },
            {
                spec: "checkbox", key: "particles", label: "Add particles"
            },
        ],
    };
    return Powder;
}());
export { Powder };
function createOutline(apperance) {
    switch (apperance.shape) {
        case "lumpy":
            return createLumpyOutline();
        case "smooth":
            return createSimplePileOutline(10);
        case "flat":
            return createSimplePileOutline(5);
        case "round":
            return new Path.Circle(P(0, -10), 10);
    }
}
function createSimplePileOutline(height) {
    var outline = new Path(Segments([[0, 0], undefined, [0, -height / 2]], [[10, -height], [-5, 0], [5, 0]], [[20, 0], [0, -height / 2]]));
    return outline;
}
function createLumpyOutline() {
    var outline = new Path(Segments([[0, 0], undefined, [-5, -3]], [[3, -8], [-4, -2], [1, -7]], [[10, -10], [-2, -3], [2, -3]], [[17, -8], [-1, -7], [4, -2]], [[20, 0], [5, -3]]));
    return outline;
}
