var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { INFINITE_BOUNDS } from "apparatus/driver";
import { P } from "apparatus/library/common";
import { drawCurve } from "apparatus/library/shapes/curve";
import { Tubes } from "apparatus/library/tubes";
import { Group } from "paper";
import { staticImplements } from "types";
var RubberTube = /** @class */ (function () {
    function RubberTube() {
        this.drivers = [
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { 
                // Move pivot with end.
                toAppearance: function (f, p, a) { return f({
                    end1x: p.x,
                    end1y: p.y,
                    pivot1x: p.x + (a.pivot1x - a.end1x),
                    pivot1y: p.y + (a.pivot1y - a.end1y)
                }); }, fromAppearance: function (a) { return P(a.end1x, a.end1y); }, scale: 1.0, snapping: function (a, p) { return ({
                    type: "connectable", at: p, facing: P(a.pivot1x, a.pivot1y).subtract(p).angle - 90,
                }); } }),
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ pivot1x: p.x, pivot1y: p.y }); }, fromAppearance: function (a) { return P(a.pivot1x, a.pivot1y); }, scale: 1.0 }),
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p, a) { return f({
                    end2x: p.x,
                    end2y: p.y,
                    pivot2x: p.x + (a.pivot2x - a.end2x),
                    pivot2y: p.y + (a.pivot2y - a.end2y)
                }); }, fromAppearance: function (a) { return P(a.end2x, a.end2y); }, scale: 1.0, snapping: function (a, p) { return ({
                    type: "connectable", at: p, facing: P(a.pivot2x, a.pivot2y).subtract(p).angle - 90,
                }); } }),
            __assign(__assign({ type: "rectangle" }, INFINITE_BOUNDS), { toAppearance: function (f, p) { return f({ pivot2x: p.x, pivot2y: p.y }); }, fromAppearance: function (a) { return P(a.pivot2x, a.pivot2y); }, scale: 1.0 }),
        ];
        this.shouldUseBoundsCenter = true;
    }
    RubberTube.prototype.render = function (a) {
        var diameter = a.diameter, colorString = a.color;
        var _a = drawCurve(a, { joinPivots: false }), path = _a.path, xray = _a.xray;
        var shade1 = path.clone();
        var shade2 = path.clone();
        var shade3 = path.clone();
        var color = colorString.color();
        path.withStroke(diameter, color.ladd(-10).hex());
        shade1.withStroke(diameter * 0.84, color.ladd(-5).hex());
        shade2.withStroke(diameter * 0.60, color.ladd(1).hex());
        shade3.withStroke(diameter * 0.28, color.ladd(6).hex());
        var graphic = new Group([path, shade1, shade2, shade3]);
        return {
            graphic: graphic,
            hitShape: { type: "stroke", path: path.clone() },
            xray: xray,
            pivot: P(0, 0),
            snapping: [
                { type: "connectable", at: P(a.end1x, a.end1y), facing: P(a.pivot1x, a.pivot1y).subtract(P(a.end1x, a.end1y)).angle - 90 },
                { type: "connectable", at: P(a.end2x, a.end2y), facing: P(a.pivot2x, a.pivot2y).subtract(P(a.end2x, a.end2y)).angle - 90 }
            ]
        };
    };
    RubberTube.properties = {
        label: "Rubber Tube",
        defaultAppearance: {
            diameter: 15,
            color: "#cc7564",
            end1x: -150,
            end1y: 0,
            pivot1x: -40,
            pivot1y: -80,
            end2x: 200,
            end2y: 0,
            pivot2x: 140,
            pivot2y: 40,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: Tubes.MIN_DIAMETER, max: 30, unit: "mm", tier: "boost"
            },
            {
                spec: "color-wells", key: "color", label: "Color",
                colors: [
                    { label: "Natural", value: "#cc7564", },
                    { label: "White", value: "#d4d4d4", },
                    { label: "Black", value: "#3c4a4d", tier: "boost" },
                    { label: "Blue", value: "#1266c4", tier: "boost" },
                    { label: "Yellow", value: "#e1cf79", tier: "boost" },
                ]
            }
        ],
    };
    RubberTube = __decorate([
        staticImplements()
    ], RubberTube);
    return RubberTube;
}());
export { RubberTube };
