import { P } from "apparatus/library/common";
var Random = /** @class */ (function () {
    function Random(seed) {
        if (seed === void 0) { seed = 1238167; }
        this.generator = sfc32(seed, 871238, 61237, 301189);
    }
    /** Returns a random vector sampled uniformly from a square, from (-x, -x) to (x, x) */
    Random.prototype.randomUniformVector = function (x) {
        if (x === void 0) { x = 1.0; }
        return P(this.randomCenteredValue(x), this.randomCenteredValue(x));
    };
    /** Returns random value from -x to x */
    Random.prototype.randomCenteredValue = function (x) {
        if (x === void 0) { x = 1.0; }
        return (this.generator() - 0.5) * 2 * x;
    };
    Random.prototype.randomBetween = function (min, max) {
        return min + (max - min) * this.generator();
    };
    /** Random value between 0 and x */
    Random.prototype.randomUniform = function (x) {
        return this.randomBetween(0, x);
    };
    Random.prototype.randomInBounds = function (r) {
        var x = this.randomBetween(r.left, r.right);
        var y = this.randomBetween(r.top, r.bottom);
        return P(x, y);
    };
    return Random;
}());
export { Random };
function sfc32(a, b, c, d) {
    return function () {
        a >>>= 0;
        b >>>= 0;
        c >>>= 0;
        d >>>= 0;
        var t = (a + b) | 0;
        a = b ^ b >>> 9;
        b = c + (c << 3) | 0;
        c = (c << 21 | c >>> 11);
        d = d + 1 | 0;
        t = t + d | 0;
        c = c + t | 0;
        return (t >>> 0) / 4294967296;
    };
}
