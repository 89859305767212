var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Pivot, R, RO, SharedColors } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var Computer = /** @class */ (function () {
    function Computer() {
    }
    Computer.prototype.render = function (appearance) {
        var stand = new Path.Rectangle(R(0, 0, 150, 10, Pivot.BOTTOM_CENTER), 3.0)
            .withFill("#aaaaaa").withStroke("default", "#666666");
        var column = new Path.Rectangle(R(0, -10, 20, 40, Pivot.BOTTOM_CENTER))
            .withFill("#aaaaaa").withStroke("default", "#666666");
        var body = new Path.Rectangle(R(0, -50, 400, 250, Pivot.BOTTOM_CENTER), 7.0)
            .withFill("#aaaaaa").withStroke("default", "#666666");
        var screen = new Path.Rectangle(RO(body.bounds.center, 370, 220, Pivot.CENTER), 3.0)
            .withFill(SharedColors.devicePanel.fill)
            .withStroke("default", SharedColors.devicePanel.stroke);
        var graphic = new Group([stand, column, body, screen]);
        graphic.scale(appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: graphic.placeable(),
        };
    };
    Computer.properties = {
        label: "Computer",
        defaultAppearance: {
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.4, max: 1.2, step: 0.01, unit: "%"
            }
        ],
    };
    Computer = __decorate([
        staticImplements()
    ], Computer);
    return Computer;
}());
export { Computer };
