import { mirrorX, smoothCorner } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedGradients } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var metalGradient = SharedGradients.cylinderMetal;
var bracketMetalGradient = Gradients.simple("#aaaaaa".color(), 20);
var softMetalGradient = Gradients.simple("#aaaaaa".color(), 10);
var HoffmanClamp = /** @class */ (function () {
    function HoffmanClamp() {
    }
    HoffmanClamp.prototype.render = function (appearance) {
        var graphic = (function () {
            switch (appearance.view) {
                case "front": return frontView;
                case "side": return sideView;
            }
        })()(appearance);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: P(0, 5),
        };
    };
    HoffmanClamp.properties = {
        label: "Hoffman Clamp",
        defaultAppearance: {
            view: "front",
            clamping: 0.5,
            size: 30,
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "view", label: "View",
                options: [
                    { value: "side", label: "Side" },
                    { value: "front", label: "Front" },
                ],
            },
            {
                spec: "slider", key: "size", label: "Size",
                min: 25, max: 50, step: 0.1, unit: "mm"
            },
            {
                spec: "slider", key: "clamping", label: "Clamping",
                min: 0.0, max: 1.0, step: 0.01, unit: "%",
            },
        ],
    };
    return HoffmanClamp;
}());
export { HoffmanClamp };
function createScrew(height) {
    return new Group([
        Shape.Rectangle(R(0, height - 5, 8, height, Pivot.BOTTOM_CENTER)).withGradientFill("right", metalGradient),
        Shape.Rectangle(R(0, -5, 20, 8, Pivot.BOTTOM_CENTER), 2.0).withGradientFill("right", metalGradient),
    ]);
}
function frontView(appearance) {
    var height = appearance.size;
    var halfWidth = height / 3 * 2;
    var slide = (height - 10) * (1 - appearance.clamping);
    var bracket = new Path(Segments([[-halfWidth, height]], [[-halfWidth, 0]]));
    mirrorX(bracket);
    smoothCorner(bracket.segments[2], 3);
    smoothCorner(bracket.segments[1], 3);
    bracket.strokeWidth = 5.0;
    bracket.setGradientStroke("right-down", bracketMetalGradient);
    var bottom = Shape.Rectangle(R(0, height, halfWidth * 2 + 10, 5, Pivot.CENTER), 2.0).withGradientFill("down", softMetalGradient);
    var slidingPart = Shape.Rectangle(R(0, height - 5 - slide, halfWidth * 2 + 10, 5, Pivot.CENTER), 2.0).withGradientFill("down", softMetalGradient);
    var screw = createScrew(height);
    screw.position.y -= slide;
    return new Group([screw, bracket, bottom, slidingPart]);
}
function sideView(appearance) {
    var height = appearance.size;
    var slide = (height - 10) * (1 - appearance.clamping);
    var bracket = Shape.Rectangle(R(0, 0, 16, height, Pivot.TOP_CENTER), 2.0);
    bracket.setGradientFill("right-down", bracketMetalGradient);
    var bottom = Shape.Rectangle(R(0, height, 18, 5, Pivot.CENTER), 2.0).withGradientFill("down", softMetalGradient);
    var slidingPart = Shape.Rectangle(R(0, height - 5 - slide, 18, 5, Pivot.CENTER), 2.0).withGradientFill("down", softMetalGradient);
    var screw = createScrew(height);
    screw.position.y -= slide;
    return new Group([screw, bracket, bottom, slidingPart]);
}
