import { g } from "analytics";
import { apparatusCategories, moduleDisplayIcon } from "apparatus/specs";
import { Icon } from "components/icon";
import { SearchBox } from "components/searchbox";
import { ApparatusList } from "layout/sidebar/sidebar";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Hint } from "view/tooltips";
export var TabbedSidebar = function (_a) {
    var onHidePanel = _a.onHidePanel;
    var _b = useState("chemistry"), currentModule = _b[0], setCurrentModule = _b[1];
    var _c = useState(""), searchTerm = _c[0], setSearchTerm = _c[1];
    var t = useTranslation().t;
    var isInSearchMode = searchTerm.length > 0;
    var tabName = isInSearchMode ? t("Search results") : t("apparatus_module." + currentModule);
    return React.createElement("div", { id: "sidebar" },
        React.createElement("div", { id: "apparatus-search" },
            React.createElement(SearchBox, { value: searchTerm, onChange: function (v) { return setSearchTerm(v); }, onBlur: function (v) { return g("SearchApparatus", v); }, placeholder: t("search.apparatus"), tooltip: React.createElement(Hint, { text: t("search.apparatus_hint"), shortcut: { key: "F", ctrl: true } }), throttleMs: 100 })),
        React.createElement("div", { className: "sidebar-tab-header" }, tabName),
        React.createElement(ApparatusList, { key: "sideBar", searchTerm: searchTerm, module: currentModule, onSwitchModule: function (m) { setCurrentModule(m); setSearchTerm(""); } }),
        React.createElement("div", { className: "sidebar-tabs" },
            React.createElement("span", { className: "line" }),
            React.createElement("ul", { "data-simplebar": true },
                Object.keys(apparatusCategories).map(function (module) {
                    return React.createElement(Tab, { active: !isInSearchMode && module == currentModule, hint: t("apparatus_module." + module), icon: moduleDisplayIcon(module), key: module, onClick: function () { setCurrentModule(module); setSearchTerm(""); } });
                }),
                React.createElement("div", { style: { flexGrow: 1 } }),
                React.createElement(Tab, { icon: "arrow-left-to-line", hint: "Hide sidebar", key: "_hide_", active: false, onClick: onHidePanel }))));
};
var Tab = function (props) { return (React.createElement("li", { className: props.active ? "active" : "", onClick: props.onClick },
    React.createElement(Icon, { name: props.icon, fullWidth: false }),
    props.hint,
    props.badge !== undefined ? React.createElement("div", { className: "badge badge-" + props.badge.color + " ml-1" }, props.badge.text) : undefined)); };
