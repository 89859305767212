// Shared ui elements for Promo popup
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Articles } from "support/beacon";
import { Nl, Tip } from "view/tooltips";
export var Tick = function () { return (React.createElement("i", { className: "fa fa-check" })); };
export var Info = function (props) { return (React.createElement(Tip, { content: props.hint },
    React.createElement("i", { className: "info_chip fa fa-info-circle ml-1" }))); };
export var InfoLink = function (props) { return (React.createElement("a", { href: props.link, target: "_blank" },
    React.createElement(Info, { hint: props.hint }))); };
export var BeaconLink = function (props) { return (React.createElement(Tip, { content: props.hint },
    React.createElement("a", { className: "info_chip fa fa-info-circle ml-1", "data-beacon-article-sidebar": props.article }))); };
export var ChemTextHint = function () {
    var t = useTranslation().t;
    return React.createElement(BeaconLink, { hint: React.createElement(Nl, null, t("info.chem_text_hint_nl")), article: Articles.CHEMTEXT });
};
export var LicenseHint = function () {
    var t = useTranslation().t;
    return React.createElement(BeaconLink, { hint: React.createElement(Nl, null, t("info.license_hint_nl")), article: Articles.LICENSE });
};
export var PermissionHint = function () {
    var t = useTranslation().t;
    return React.createElement(BeaconLink, { hint: React.createElement(Nl, null, t("info.commercial_use_hint_nl")), article: Articles.LICENSE });
};
