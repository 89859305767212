var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { mirrorX, setDefaultStyle } from "apparatus/draw";
import { R, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
var GasJar = /** @class */ (function () {
    function GasJar() {
    }
    GasJar.prototype.render = function (appearance) {
        var height = appearance.height;
        var width = 30 + appearance.height / 10; // 50x200, 60x300
        var bottomLid = new Path(Segments([[0, 0]], [[-6, 10]]));
        mirrorX(bottomLid, width / 2);
        bottomLid.closePath();
        var wall1 = new Path(Segments([[0, 0]], [[0, -height]]));
        var wall2 = new Path(Segments([[width, 0]], [[width, -height]]));
        var liquiMask = new Path.Rectangle(R(0, 0, width, -height));
        var graphic = new Group([bottomLid, wall1, wall2]);
        var pivot = graphic.bounds.center;
        var hitShapes = [graphic.bounds.clone().toShape()];
        // Top lid.
        if (appearance.lid != "none") {
            var topLid = new Path(Segments([[0, 0]], [[-6, -10]]));
            mirrorX(topLid, width / 2);
            topLid.closePath();
            switch (appearance.lid) {
                case "lifted":
                    topLid.position.y -= height + 40;
                    break;
                case "edge":
                    topLid.position.y -= height;
                    topLid.position.x += width / 1.7;
                    break;
                case "closed":
                    topLid.position.y -= height;
                    break;
                case "tilted":
                    topLid.position.y -= height + 10;
                    topLid.position.x += width * 2;
                    topLid.rotate(30);
                    break;
                case "side":
                    topLid.position.y -= height - 10;
                    topLid.position.x += width + 20;
                    topLid.rotate(180);
            }
            graphic.addChild(topLid);
            hitShapes.push(topLid.bounds.toShape());
        }
        var snapping = [bottomLid.placeable()];
        if (appearance.lid == "none" || appearance.lid == "closed" || appearance.lid == "side") {
            snapping.push(__assign(__assign({}, graphic.placeable()), { facing: 180 }));
        }
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            hitShape: hitShapes,
            liquidMask: liquiMask,
            pivot: pivot,
            snapping: snapping
        };
    };
    GasJar.properties = {
        label: "Gas Jar",
        canContainLiquids: true,
        meniscus: true,
        flippable: true,
        defaultAppearance: {
            lid: "closed",
            height: 200,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "height", label: "Height",
                min: 200, max: 300,
            },
            {
                spec: "radio", key: "lid", label: "Lid",
                options: [
                    { value: "none", label: "No lid" },
                    { value: "closed", label: "Closed" },
                    { value: "edge", label: "On the edge" },
                    { value: "lifted", label: "Lifted above" },
                    { value: "tilted", label: "Lift tilted" },
                    { value: "side", label: "To one side" },
                ]
            }
        ],
    };
    return GasJar;
}());
export { GasJar };
