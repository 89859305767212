import { setDefaultStyleWithStroke } from "apparatus/draw";
import { SVG } from "apparatus/svg";
import { Shape } from "paper";
var ColdFinger = /** @class */ (function () {
    function ColdFinger() {
    }
    ColdFinger.prototype.render = function (appearance) {
        var graphic = SVG.coldFinger();
        graphic.scale(1.8 * appearance.scale);
        setDefaultStyleWithStroke("thinner", graphic);
        var hitShape = Shape.Rectangle(graphic.bounds);
        return { graphic: graphic, hitShape: hitShape };
    };
    ColdFinger.properties = {
        label: "Cold Finger",
        canContainLiquids: true,
        flippable: true,
        defaultAppearance: {
            scale: 1.0
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.7, max: 1.3, step: 0.05,
            },
        ],
    };
    return ColdFinger;
}());
export { ColdFinger };
