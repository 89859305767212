import { SVG } from "apparatus/svg";
var PlantShoot = /** @class */ (function () {
    function PlantShoot() {
    }
    PlantShoot.prototype.render = function (appearance) {
        var graphic = SVG.plant(appearance.display - 1);
        graphic.scale(appearance.scale);
        // Recolor it.
        if (appearance.color === "dead") {
            for (var _i = 0, _a = graphic.children[1].children; _i < _a.length; _i++) {
                var path = _a[_i];
                var color = path.fillColor.toCSS(true);
                path.fillColor = DEAD_RECOLOR[color];
            }
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    PlantShoot.properties = {
        label: "Plant shoot",
        flippable: true,
        defaultAppearance: {
            display: 3,
            scale: 1.0,
            color: "alive"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "display", label: "Growth phase",
                min: 1, max: 6
            },
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.25, max: 2.0, step: 0.01, unit: "%"
            },
            {
                spec: "radio", key: "color", label: "Style",
                options: [
                    { value: "alive", label: "Alive" },
                    { value: "dead", label: "Dead" },
                ],
            }
        ],
    };
    return PlantShoot;
}());
export { PlantShoot };
var DEAD_RECOLOR = {
    "#579b3c": "#8c8069",
    "#8fc444": "#ccc389"
};
// function renderOld(appearance: PlantShootAppearance): RenderParts {
//     const stem = new Path(Segments(
//         [[-17, 31],[7, 10],[5, 1]],
//         [[-2, 37],[-8, -2],[-6, -9]],
//         [[8, -2],[-1, -9],[6, -3]],
//         [[4, 31],[-3, -19],[2, 13]],
//         [[29, 31],[-2, 0],[8, -4]],
//         [[11, 41],[20, 0],[-9, 8]],
//         [[10, 66]],
//         [[4, 66]],
//         [[1, 46],[-1, 14],[2, -4]]
//     ))
//     stem.closePath()
//     stem.position = P(1, 9)
//     stem.fillColor = DEFAULT_COLOR.color().ladd(-10).string()
//     const graphic = new Group([stem])
//     const leaf1 = Leaf.createLeaf(DEFAULT_COLOR, 60)
//     const leaf2 = leaf1.clone()
//     const leaf3 = leaf1.clone()
//     leaf1.rotate(-110)
//     leaf2.rotate(-180)
//     leaf2.scale(0.8)
//     leaf3.scale(0.7, -0.7)
//     leaf2.position = P(-50, 0)
//     leaf1.position = P(0, -60)
//     leaf3.position = P(50, 0)
//     graphic.addChildren([leaf1, leaf2, leaf3])
//     return {
//         graphic: graphic,
//         hitShape: graphic.bounds.toShape()
//     }
// }
