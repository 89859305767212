import { strokeWidth } from "apparatus/common";
import { mirrorX, simpleGradient, smoothCorner } from "apparatus/draw";
import { Path, Size } from "paper";
import { clamp, P, Pivot, R, Segments } from "./common";
var HOLE_GAP = 10;
var MIN_GAP = 2;
var MIN_DIAMETER = 8;
var MAX_DIAMETER = 100;
var TAPERING = 0.9;
var Bung = /** @class */ (function () {
    function Bung() {
    }
    Bung.prototype.render = function (appearance) {
        var diameter = appearance.diameter;
        var height = 25;
        var bung = new Path(Segments([[diameter / 2, 0]], [[diameter / 2 * TAPERING, height]]));
        mirrorX(bung);
        smoothCorner(bung.segments[2], 3.0);
        smoothCorner(bung.segments[1], 3.0);
        bung.closed = true;
        var graphic = bung;
        if (appearance.hole == "one") {
            var holeDiameter = Math.min(appearance.holeDiameter, diameter * TAPERING - 5);
            var topLeft = P(-holeDiameter / 2, -10);
            var size = new Size(holeDiameter, 50);
            var holeSubtract = new Path.Rectangle(topLeft, size);
            graphic = bung.subtract(holeSubtract);
        }
        else if (appearance.hole == "two") {
            var space = diameter * TAPERING - 5;
            var holeDiameter = appearance.holeDiameter, holeDiameter2 = appearance.holeDiameter2;
            var excess = holeDiameter + holeDiameter2 + HOLE_GAP - space;
            var gap = HOLE_GAP;
            if (excess > 0) {
                // Too cramped. Need to distribute the excess evenly.
                // First, absorb it in the gap.
                if (excess < (HOLE_GAP - MIN_GAP)) {
                    // All absorbed in the gap.
                    gap -= excess;
                }
                else {
                    // Absorb in the gap and distribute between the two holes evenly.
                    gap = MIN_GAP;
                    excess -= (HOLE_GAP - MIN_GAP);
                    var totalDiameter = holeDiameter + holeDiameter2;
                    holeDiameter -= excess * (holeDiameter / totalDiameter);
                    holeDiameter2 -= excess * (holeDiameter2 / totalDiameter);
                }
            }
            var totalSpace = holeDiameter + holeDiameter2 + gap;
            var leftHoleSubtract = new Path.Rectangle(R(-totalSpace / 2, -10, holeDiameter, 50, Pivot.TOP_LEFT));
            var rightHoleSubtract = new Path.Rectangle(R(totalSpace / 2, -10, holeDiameter2, 50, Pivot.TOP_RIGHT));
            graphic = bung.subtract(leftHoleSubtract).subtract(rightHoleSubtract);
        }
        graphic
            .withGradientFill("right", simpleGradient(appearance.color))
            .withStroke("thinner", appearance.color.color().darken(0.1).hex());
        return {
            graphic: graphic, hitShape: graphic.bounds.expand(5).toShape(),
            snapping: {
                type: "pluggable", at: P(0, height / 2), requestDiameter: requestDiameter
            }
        };
    };
    Bung.properties = {
        label: "Bung / Stopper",
        defaultAppearance: {
            diameter: 22,
            color: "#dd7d2e",
            hole: "none",
            holeDiameter: 15,
            holeDiameter2: 15,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: MIN_DIAMETER, max: MAX_DIAMETER, unit: "mm",
            },
            {
                spec: "radio", key: "color", label: "Material",
                options: [
                    { value: "#dd7d2e", label: "Orange Rubber", i18nLabel: "ui.props.appearance.bung.orange" },
                    { value: "#aaaaaa", label: "White Rubber" },
                    { value: "#824b20", label: "Cork" },
                    { value: "#393939", label: "Black" },
                ]
            },
            {
                spec: "toggle-buttons", key: "hole", label: "Holes", size: "text",
                options: [
                    { value: "none", label: "0" },
                    { value: "one", label: "1" },
                    { value: "two", label: "2", tier: "boost" },
                ]
            },
            {
                spec: "slider", key: "holeDiameter", label: "Hole diameter",
                min: 5, max: 40,
                visible: function (a) { return a.hole == "one"; },
            },
            {
                spec: "slider", key: "holeDiameter", label: "#1 diameter",
                min: 5, max: 40,
                visible: function (a) { return a.hole == "two"; },
            },
            {
                spec: "slider", key: "holeDiameter2", label: "#2 diameter",
                min: 5, max: 40,
                visible: function (a) { return a.hole == "two"; },
            }
        ],
    };
    return Bung;
}());
export { Bung };
export function requestDiameter(diameter) {
    var newDiameter = diameter / (0.5 + TAPERING / 2) - strokeWidth("default");
    return { diameter: clamp(newDiameter, MIN_DIAMETER, MAX_DIAMETER).round(0) };
}
