import { Pivot, R } from "apparatus/library/common";
import { Group, Shape } from "paper";
var DEFAULT_DIFFUSION_COLOUR = "#eacdd2";
var Agar = /** @class */ (function () {
    function Agar() {
    }
    Agar.prototype.render = function (appearance) {
        var width = appearance.width, height = appearance.height, color = appearance.color, diffused = appearance.diffused, diffusion = appearance.diffusion, diffusionColor = appearance.diffusionColor;
        var core = Shape.Rectangle(R(0, 0, width, height)).withFill(color);
        var graphic = new Group([core]);
        // Distance of blending
        var blend;
        var gradient;
        if (diffused) {
            blend = Math.min(height, width) * diffusion * 0.5;
            gradient = [[diffusionColor, 0.6], [diffusionColor + "00", 1.0]];
        }
        else {
            blend = 5;
            gradient = [["#ffffff40", 0.0], ["#ffffff00", 1.0]];
        }
        var top = Shape.Rectangle(R(0, 0, width, blend)).withGradientFill("down", gradient);
        var right = Shape.Rectangle(R(width, 0, blend, height, Pivot.TOP_RIGHT)).withGradientFill("left", gradient);
        var bottom = Shape.Rectangle(R(0, height, width, blend, Pivot.BOTTOM_LEFT)).withGradientFill("up", gradient);
        var left = Shape.Rectangle(R(0, 0, blend, height)).withGradientFill("right", gradient);
        graphic.addChildren([top, right, bottom, left]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(15).toShape()
        };
    };
    Agar.properties = {
        label: "Agar cube",
        defaultAppearance: {
            width: 40,
            height: 40,
            color: "#b21f2a",
            diffused: false,
            diffusion: 0.2,
            diffusionColor: DEFAULT_DIFFUSION_COLOUR,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Width",
                min: 15, max: 80, unit: "mm"
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 15, max: 80, unit: "mm"
            },
            {
                spec: "color", key: "color", label: "Colour",
            },
            {
                spec: "checkbox", key: "diffused", label: "Diffused",
            },
            {
                spec: "slider", key: "diffusion", label: "Diffusion amount",
                min: 0.1, max: 0.9, step: 0.01, unit: "%",
                visible: function (a) { return a.diffused; }
            },
            {
                spec: "color", key: "diffusionColor", label: "Diffusion colour",
                visible: function (a) { return a.diffused; }
            },
        ],
    };
    return Agar;
}());
export { Agar };
