var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P, Pivot, R } from "apparatus/library/common";
import { BasicShapes } from "apparatus/library/shapes/common";
import { Shape } from "paper";
var Ellipse = /** @class */ (function () {
    function Ellipse() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 8, maxX: 300,
                minY: 8, maxY: 300,
                fromAppearance: function (a) { return P(a.width / 2, a.height / 2); },
                toAppearance: function (f, p) { return f({ width: p.x * 2, height: p.y * 2 }); },
                scale: 1.0,
                constraints: function (a, modifiers) {
                    if (modifiers.shift) {
                        // Force square.
                        var size = Math.max(a.width, a.height);
                        return { width: size, height: size };
                    }
                    return;
                }
            }
        ];
    }
    Ellipse.prototype.render = function (appearance) {
        var width = appearance.width;
        var height = appearance.height;
        if (appearance.forceCircle) {
            var max = Math.max(width, height);
            width = max;
            height = max;
        }
        var graphic = Shape.Ellipse(R(0, 0, width, height, Pivot.CENTER));
        BasicShapes.applyStyle(graphic, appearance);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
        };
    };
    Ellipse.properties = {
        label: "Ellipse",
        defaultAppearance: __assign({ width: 120, height: 80, forceCircle: false }, BasicShapes.defaultAppearance),
        appearanceSpecs: __spreadArray(__spreadArray([], BasicShapes.specs), [
            BasicShapes.header,
            {
                spec: "checkbox", key: "forceCircle", label: "Force circle shape",
            }
        ]),
    };
    return Ellipse;
}());
export { Ellipse };
