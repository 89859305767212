import { P } from "apparatus/library/common";
import { MoveIndividual } from "editing/move";
export var Distribute;
(function (Distribute) {
    function distributeCenters(components, axis) {
        if (components.length <= 2)
            return;
        // Sort components by their centers.
        components.sort(function (a, b) { return a.alignmentBounds.center[axis] - b.alignmentBounds.center[axis]; });
        // Get the extremities and determine step.
        var high = components[components.length - 1].alignmentBounds.center[axis];
        var low = components[0].alignmentBounds.center[axis];
        var step = (high - low) / (components.length - 1);
        // Move each component to desired new location.
        return new MoveIndividual(components.map(function (c, i) {
            var moveTo = low + step * i;
            var delta = P(0, 0);
            delta[axis] = moveTo - c.alignmentBounds.center[axis];
            return { id: c.id, delta: delta };
        }));
    }
    Distribute.distributeCenters = distributeCenters;
    function distributeGaps(components, axis) {
        if (components.length <= 2)
            return;
        var lowEdge = axis == "x" ? "left" : "top";
        var highEdge = axis == "x" ? "right" : "bottom";
        var sizeProperty = axis == "x" ? "width" : "height";
        // Sort components by their centers.
        components.sort(function (a, b) { return a.alignmentBounds.center[axis] - b.alignmentBounds.center[axis]; });
        // Calculate total gap.
        var totalGap = 0;
        for (var i = 0; i < components.length - 1; i++) {
            totalGap += components[i + 1].alignmentBounds[lowEdge] - components[i].alignmentBounds[highEdge];
        }
        var gapStep = totalGap / (components.length - 1);
        // Move each component to desired new location.
        var desiredLowEdge = components[0].alignmentBounds[lowEdge];
        return new MoveIndividual(components.map(function (c, _) {
            var delta = P(0, 0);
            delta[axis] = desiredLowEdge - c.alignmentBounds[lowEdge];
            // Compute the next desired edge.
            desiredLowEdge += c.alignmentBounds[sizeProperty] + gapStep;
            return { id: c.id, delta: delta };
        }));
    }
    Distribute.distributeGaps = distributeGaps;
})(Distribute || (Distribute = {}));
