import { P, Segments } from "apparatus/library/common";
import { Lamp } from "apparatus/library/lamp";
import { Group, Path, Shape } from "paper";
var Led = /** @class */ (function () {
    function Led() {
    }
    Led.prototype.render = function (appearance) {
        var _a = Lamp.lampColors(appearance.color), fill = _a[0], stroke = _a[1], glowColor = _a[2];
        var led = new Path(Segments([[6, -5], [0, -8]], [[6, 4], , [10, 7]])).mirrorX().close().withStroke("thin", stroke + "80")
            .withGradientFill("right", [["#ffffff80", 0.2], [fill + "60", 0.5]]);
        var reflect = new Path.Line(P(-3, 5), P(2, 5)).withStroke(1.0, stroke + "cc");
        var graphic = new Group([
            new Path.Line(P(3, 8), P(7, 50)).withStroke("default"),
            new Path.Line(P(-3, 8), P(-7, 50)).withStroke("default"),
            reflect, led,
        ]);
        if (appearance.on) {
            var glow = Shape.Circle(P(0, 0), 30)
                .setRadialGradientFill([["#ffffff80", 0.1], [glowColor + "60", 0.4], [glowColor + "00", 1.0]]);
            graphic.addChild(glow);
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(10).toShape(),
            pivot: P(0, 0),
            snapping: [
                { type: "wire", at: P(7, 50) },
                { type: "wire", at: P(-7, 50) },
            ]
        };
    };
    Led.properties = {
        label: "Diode (LED)",
        defaultAppearance: {
            color: "red",
            on: true,
        },
        appearanceSpecs: [
            {
                spec: "color-wells", key: "color", label: "Colour",
                colors: [
                    { value: "yellow", label: "Yellow" },
                    { value: "red", label: "Red" },
                    { value: "green", label: "Green" },
                    { value: "blue", label: "Blue", tier: "boost" },
                ]
            },
            { spec: "checkbox", label: "On", key: "on" },
        ],
    };
    return Led;
}());
export { Led };
