var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { SharedColors } from "apparatus/library/common";
import { Deselect, DeselectAndSelect, Select, UpdateAppearanceProperties } from "command/basic";
import { commandProcessor, Sequence } from "command/command";
import { getDescendantIDs, getRootGroup } from "editing/grouping/operations";
import { InteractableMatcher } from "editing/interaction";
import { RotateItems } from "editing/rotation/rotate_command";
import { evaluatePrecomputedSnappings, precomputeSnapping } from "editing/snapping/compute";
import { Key, Point, project, Rectangle, Shape, Size, Tool } from "paper";
import { ID } from "store/id";
import appStore from "store/store";
import { Move } from "./move";
/** Stronger snap distance for zoom level 1. */
var STRONG_SNAP_DISTANCE = 20.0;
var MultiTool = /** @class */ (function (_super) {
    __extends(MultiTool, _super);
    function MultiTool(layers, app) {
        var _this = _super.call(this) || this;
        // Selection box (visual).
        _this.selectionBox = (function () {
            var box = Shape.Rectangle(new Point(0, 0), new Size(0, 0));
            // Style the selection box.
            box.strokeColor = SharedColors.selectionGreen;
            box.fillColor = SharedColors.selectionGreen + "40"; // With alpha
            box.strokeWidth = 1.0;
            box.radius = 0;
            box.visible = false;
            return box;
        })();
        _this.onMouseDown = function (event) {
            // On mouse down, there are 2 options:
            // A) Down on an apparatus
            //    a) Apparatus already selected.
            //       1) If moved, then move current selection.
            //       2) Else, no change (apparatus remains selected)
            //    b) Apparatus not already selected
            //       1) If moved, then move this apparatus only
            //       2) Else, select this apparatus
            // b) Down on empty space
            //    Draw selection box.
            commandProcessor.startRecording();
            _this.operation = _this.startOperation(event);
            if (_this.operation.type == "moving" || _this.operation.type == "adjusting") {
                // We hide the HTLM-based selection box, but only when moving or adjusting
                _this.appInterface.setIsInteracting(true);
                // Set the state to moving.
                _this.appInterface.setIsMovingItems(true);
            }
        };
        _this.onMouseDrag = function (event) {
            if (!_this.operation)
                return;
            // Vector from downPoint to current point.
            var deltaVector = event.point.subtract(event.downPoint);
            switch (_this.operation.type) {
                case "moving": {
                    var adjustedDelta = _this.deltaVector(deltaVector, _this.operation.snapping, { lock90degrees: true });
                    var moveVector_1 = adjustedDelta.delta;
                    _this.operation.movedComponents.forEach(function (c) { return c.setPositionDelta(moveVector_1); });
                    break;
                }
                case "adjusting": {
                    var adjustedDelta = _this.deltaVector(deltaVector, _this.operation.snapping, { lock90degrees: false });
                    _this.operation.adjustable.setDelta(adjustedDelta.delta);
                    break;
                }
                case "selection":
                case "selection_on_top":
                    // Draw the selection box.
                    _this.selectionBox.visible = true;
                    _this.selectionBox.size = new Size(deltaVector).abs();
                    _this.selectionBox.position = event.downPoint.add(deltaVector.divide(2.0));
            }
        };
        _this.onMouseUp = function (event) {
            _this.selectionBox.visible = false;
            if (!_this.operation) {
                _this.appInterface.setIsInteracting(false);
                _this.appInterface.setIsMovingItems(false);
                commandProcessor.finishRecording();
                return;
            }
            switch (_this.operation.type) {
                case "moving": {
                    // Apparatus have been moved. Commit this change as a command.
                    // Vector from downPoint to current point. If it's a zero vector, skip
                    var deltaVector = event.point.subtract(event.downPoint);
                    if (deltaVector.isZero())
                        break;
                    var adjustedDelta = _this.deltaVector(deltaVector, _this.operation.snapping, { lock90degrees: true });
                    deltaVector = adjustedDelta.delta;
                    // Create a move command for each moved apparatus.
                    var moveCommand = new Move(_this.operation.movedComponents.map(function (c) { return c.id; }), deltaVector);
                    // Check if there are appearance changes.
                    var appearanceChangeCommands = [];
                    if (adjustedDelta.appearanceChange) {
                        var id = adjustedDelta.appearanceChange.id;
                        var item = appStore.items().filter(ID.matching(id))[0];
                        if (item) {
                            var prevSpecs = item.appearance;
                            var newSpecs = adjustedDelta.appearanceChange.partialSpecs;
                            appearanceChangeCommands = [
                                new UpdateAppearanceProperties(id, newSpecs, prevSpecs),
                                new RotateItems([{ id: id, rotation: adjustedDelta.appearanceChange.rotation, originalRotation: item.rotation }])
                            ];
                        }
                    }
                    if (appearanceChangeCommands.length > 0) {
                        commandProcessor.execute(new Sequence(__spreadArray([moveCommand], appearanceChangeCommands)));
                    }
                    else {
                        commandProcessor.execute(moveCommand);
                    }
                    break;
                }
                case "adjusting": {
                    var delta = event.point.subtract(event.downPoint);
                    if (delta.isZero()) {
                        if (_this.operation.adjustable.onNoop) {
                            var command_1 = _this.operation.adjustable.onNoop();
                            if (command_1) {
                                commandProcessor.execute(command_1);
                            }
                        }
                        break;
                    }
                    var adjustedDelta = _this.deltaVector(delta, _this.operation.snapping, { lock90degrees: false });
                    var command = _this.operation.adjustable.onEnd(adjustedDelta.delta);
                    commandProcessor.execute(command);
                    break;
                }
                case "selection":
                case "selection_on_top":
                    // Complete selection.
                    var selectionHitRectangle = new Rectangle(event.downPoint, event.point);
                    if (selectionHitRectangle.isEmpty() && _this.operation.type == "selection")
                        break;
                    // If a hit rectangle is available, use getItems, otherwise hit test 1 item only.
                    var interactableItems = void 0;
                    if (!selectionHitRectangle.isEmpty()) {
                        interactableItems = project.getItems({
                            overlapping: selectionHitRectangle,
                            match: InteractableMatcher,
                        });
                    }
                    else {
                        var hitResult = project.hitTest(event.downPoint, {
                            fill: true,
                            bounds: true,
                            stroke: true,
                            match: InteractableMatcher,
                        });
                        if (hitResult) {
                            interactableItems = [hitResult.item];
                        }
                        else {
                            interactableItems = [];
                        }
                    }
                    var components = interactableItems.filter(function (i) { return i.interaction.type == "selectAndMove"; }).map(function (i) { return i.interaction.component; });
                    if (components.length > 0) {
                        if (_this.operation.type == "selection") {
                            var ids = convertToIdsExpandingGroups(components);
                            commandProcessor.execute(new Select(ids));
                        }
                        else {
                            // Check if the newly selected items are all already selected. If so,
                            // remove them from the selection. Otherwise select them.
                            var notSelectedComponents = components.filter(function (c) { return !c.isSelected; });
                            if (notSelectedComponents.length > 0) {
                                // Select these items.
                                var notSelectedIds = convertToIdsExpandingGroups(notSelectedComponents);
                                commandProcessor.execute(new Select(notSelectedIds));
                            }
                            else {
                                var ids = convertToIdsExpandingGroups(components);
                                commandProcessor.execute(new Deselect(ids));
                            }
                        }
                    }
                    break;
            }
            // Disable visualisation of snap points.
            for (var _i = 0, _a = appStore.items(); _i < _a.length; _i++) {
                var data = _a[_i];
                var apparatus = _this.appInterface.getApparatusComponent(data.id);
                apparatus === null || apparatus === void 0 ? void 0 : apparatus.setState({ visualiseSnapping: undefined });
            }
            _this.appInterface.setIsInteracting(false);
            _this.appInterface.setIsMovingItems(false);
            commandProcessor.finishRecording();
            _this.operation = undefined;
        };
        _this.appInterface = app;
        layers.overlay.addChild(_this.selectionBox);
        return _this;
    }
    // Private helpers.
    MultiTool.prototype.startOperation = function (event) {
        var _this = this;
        if (Key.modifiers.shift) {
            return { type: "selection_on_top" };
        }
        var foundTarget = project.hitTest(event.downPoint, {
            fill: true,
            bounds: true,
            stroke: true,
            match: InteractableMatcher,
        });
        // Resolve the apparatus.
        if (foundTarget == null) {
            // Down event landed on empty space.
            // Deselect currently selected items.
            var deselectIds = appStore.selectedObjectsID();
            if (deselectIds.length > 0) {
                commandProcessor.execute(new Deselect(deselectIds));
            }
            return { type: "selection" };
        }
        // Otherwise down event landed on an item.
        var foundInteractable = foundTarget.item;
        // First determine what type of interaction is associated with this component.
        var interaction = foundInteractable.interaction;
        switch (interaction.type) {
            case "selectAndMove":
                // Determine if this apparatus is already selected.
                var component = interaction.component;
                if (!component.isSelected) {
                    // Deselect currently selected items, if any.
                    var deselectIds = appStore.selectedObjectsID();
                    // Determine if this item is part of a group.
                    var selectIds = void 0;
                    if (component.parentGroup) {
                        // This component is part of a group. Select all other
                        // grouped components.
                        var rootGroup = getRootGroup(component.parentGroup);
                        selectIds = getDescendantIDs(rootGroup);
                    }
                    else {
                        // Select just this apparatus.
                        selectIds = [component.id];
                    }
                    commandProcessor.execute(new DeselectAndSelect({
                        select: selectIds,
                        deselect: deselectIds
                    }));
                }
                // Retrieve components of currently selected items.
                var selectedItems = appStore.selectedObjectsID()
                    .map(function (id) { return _this.appInterface.getObjectComponent(id); });
                // Snapping:
                // 1) Get non-selected apparatus
                // 2) For every snap point in the selection, find the delta to every snap point
                //    in the non-selected apparatus.
                var _a = this.getSelectedAndOtherApparatusComponents(), selectedApparatus = _a.selected, other = _a.other;
                var snapping = [];
                snapping = precomputeSnapping(selectedApparatus, other);
                return {
                    type: "moving",
                    movedComponents: selectedItems,
                    snapping: snapping,
                };
            case "adjust":
                {
                    var precomputedSnapping = [];
                    if (interaction.component.onStart) {
                        var snappableComponent = interaction.component.onStart().snappableComponent;
                        if (snappableComponent) {
                            var other_1 = this.getSelectedAndOtherApparatusComponents().other;
                            precomputedSnapping = precomputeSnapping([snappableComponent], other_1);
                        }
                    }
                    return {
                        type: "adjusting",
                        adjustable: interaction.component,
                        snapping: precomputedSnapping,
                    };
                }
        }
    };
    /**
     * Returns the delta vector to use for movement, taking into account
     * potential snapping points. Potentially, other properties may also be changed.
     */
    MultiTool.prototype.deltaVector = function (rawDeltaVector, snapping, options) {
        // If Shift is held down, snap the vector to horizontal/vertical
        if (options.lock90degrees && Key.modifiers.shift) {
            rawDeltaVector.angle = Math.round(rawDeltaVector.angle / 90) * 90;
        }
        // Snapping is disabled by holding down Ctrl.
        if (Key.modifiers.control || Key.modifiers.command) {
            return { delta: rawDeltaVector };
        }
        // Evaluate snapping.
        if (snapping.length > 0) {
            var snappedData = evaluatePrecomputedSnappings(rawDeltaVector, snapping, STRONG_SNAP_DISTANCE / appStore.zoom());
            if (snappedData) {
                return snappedData;
            }
        }
        return { delta: rawDeltaVector };
    };
    MultiTool.prototype.getSelectedAndOtherApparatusComponents = function () {
        var allApparatus = [];
        var selectedApparatus = [];
        for (var _i = 0, _a = appStore.items(); _i < _a.length; _i++) {
            var data = _a[_i];
            var apparatus = this.appInterface.getApparatusComponent(data.id);
            if (!apparatus)
                continue;
            allApparatus.push(apparatus);
            // Don't use apparatus.isSelected, it can be stale compared to the state.
            if (data.selected) {
                selectedApparatus.push(apparatus);
            }
        }
        return { selected: selectedApparatus, other: allApparatus };
    };
    return MultiTool;
}(Tool));
/**
 * Turns a list of components into IDs. If any components are part of a group,
 * the group is expanded fully, including descednant IDs in the return value.
 */
function convertToIdsExpandingGroups(components) {
    var ids = [];
    components.forEach(function (c) {
        ids.push.apply(ids, (c.parentGroup ? getDescendantIDs(getRootGroup(c.parentGroup)) : [c.id]));
    });
    return ids;
}
export default MultiTool;
