import { Consts } from "./vars";
/** Shortcut for logging events via Google analytics. */
export function g(action, label, value) {
    var data = { 'event_category': Consts.googleAnalyticsCategory };
    if (label) {
        data['event_label'] = label;
    }
    if (value) {
        data['value'] = value;
    }
    gtag('event', action, data);
}
// Make it globally available as "GLog"
;
window.GLog = g;
