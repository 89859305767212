var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { setDefaultStyle } from "apparatus/draw";
import { P, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var HalfCellTube = /** @class */ (function () {
    function HalfCellTube() {
    }
    HalfCellTube.prototype.render = function (_a) {
        var diameter = _a.diameter, totalHeight = _a.height, sideArmDiameter = _a.sideArmDiameter;
        // Length of the main tube
        var height = totalHeight - 30;
        var width = diameter;
        var sideArmRadius = sideArmDiameter / 2;
        var sideArmLength = 20;
        var left = new Path(Segments([[-width / 2 - 10, height + 15]], [[-width / 2 - 10, height + 10], , [0, -5]], [[-width / 2, height], [0, 5]], [[-width / 2, 0], , [0, -width * 0.6]], [[width / 2, 0], [0, -width * 0.6]], [[width / 2, 25 - sideArmRadius]], [[width / 2 + sideArmLength, 25 - sideArmRadius]]));
        var right = new Path(Segments([[width / 2 + sideArmLength, 25 + sideArmRadius]], [[width / 2, 25 + sideArmRadius]], [[width / 2, height], , [0, 5]], [[width / 2 + 10, height + 10], [0, -5]], [[width / 2 + 10, height + 15]]));
        var leftBit = new Path(Segments([[-width / 2 - 10, height + 20]], [[-width / 2 - 10, height + 30]]));
        var rightBit = new Path(Segments([[width / 2 + 10, height + 20]], [[width / 2 + 10, height + 30]]));
        var graphic = new Group([left, right, leftBit, rightBit]);
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: {
                type: "connectable",
                at: P(width / 2 + sideArmLength, 25),
                facing: 90,
            },
        };
    };
    HalfCellTube.properties = {
        label: "Half-cell tube",
        defaultAppearance: {
            diameter: 20,
            height: 120,
            sideArmDiameter: 10
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 14, max: 28, unit: "mm"
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 90, max: 150
            },
            {
                spec: "slider", key: "sideArmDiameter", label: "Side arm diameter",
                min: 8, max: 16, unit: "mm"
            }
        ],
        flippable: true,
    };
    HalfCellTube = __decorate([
        staticImplements()
    ], HalfCellTube);
    return HalfCellTube;
}());
export { HalfCellTube };
