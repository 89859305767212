import { strokeWidth } from "apparatus/draw";
import { Interpolator, P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
import { Group, Path, PointText, Shape } from "paper";
var wavelengthInterpolator = new Interpolator([400, 640], [280, 0]);
/**
 * For converting from absorbance to transmittance:
 * https://www.sigmaaldrich.com/GB/en/technical-documents/technical-article/analytical-chemistry/photometry-and-reflectometry/transmittance-to-absorbance
 */
var SpetrophotometerSchematic = /** @class */ (function () {
    function SpetrophotometerSchematic() {
    }
    SpetrophotometerSchematic.prototype.render = function (appearance) {
        var bulb = SVG.bulb();
        bulb.scale(4.0);
        bulb.position = P(0, 16);
        bulb.strokeWidth = strokeWidth("thin");
        var collimator = new Path.Circle(P(70, 0), 50);
        collimator.setRadialGradientFill([["#ffffff", 0.5], ["#777777", 1.0]]);
        collimator.opacity = 0.5;
        collimator.applyMatrix = false;
        collimator.scale(0.25, 1.0);
        var light = new Path(Segments([[15, -20]], [[70, -20]], [[170, 0]], [[70, 20]], [[15, 20]])).withGradientFill("right", [["#fff18900", 0.0], ["#fff18980", 0.2]]);
        var prism = new Path(Segments([[-50, 43]], [[0, -43]], [[50, 43]], [[48, 50]], [[-48, 50]])).close()
            .withStroke("default", "#aaaaaa")
            .withGradientFill("right-down", [["#f0f0f0", 0.5], ["#e6e6e6", 0.6], ["#f0f0f0", 0.7]]);
        prism.position.x += 170;
        var prismLine = new Path.Line(P(50, 43), P(-50, 43)).withStroke("thinner", "#bbbbbb");
        prismLine.position.x += 170;
        var rayHue = wavelengthInterpolator.interpolate(appearance.rayNm);
        var base = "hsl(280, 90%, 60%)".color();
        var shift = rayHue / 280 * 50;
        var rainbow = new Path(Segments([[10, 0]], [[90, -shift - 2.5]], [[90, -shift + 50 + 2.5]])).withGradientFill("down", [
            [base.hue(0).hex(), 0.0],
            [base.hue(70).hex(), 0.25],
            [base.hue(140).hex(), 0.5],
            [base.hue(210).hex(), 0.75],
            [base.hue(280).hex(), 1.0],
        ]);
        rainbow.position.x += 170;
        var _a = [
            new Path.Line(P(260, -60), P(260, -2.5)).withStroke(6.0),
            new Path.Line(P(260, 60), P(260, 2.5)).withStroke(6.0)
        ], slitUp = _a[0], slitDown = _a[1];
        var ray = new Path.Line(P(260, 0), P(400, 0)).withStroke(5.0, base.hue(rayHue).string());
        if (appearance.fade) {
            // Simulate transmittance.
            var opacity = void 0;
            if (appearance.measure == "trans") {
                opacity = appearance.trans;
            }
            else {
                // Calculate transmittance from absorbance.
                var floatAbsorbance = parseFloat(appearance.absorbance);
                if (floatAbsorbance !== NaN && floatAbsorbance >= 0 && floatAbsorbance <= 2) {
                    var transmittance = 1 / Math.pow(10, floatAbsorbance);
                    opacity = transmittance;
                }
            }
            if (opacity) {
                ray.setGradientStroke("right", [
                    [base.hue(rayHue).alpha(1.0).string(), 0.45],
                    [base.hue(rayHue).alpha(opacity).string(), 0.55],
                ]);
            }
        }
        var detector = Shape.Rectangle(R(400, 0, 20, 100, Pivot.CENTER), 2.0).withFill("#444444");
        var wire = new Path(Segments([[410, 0], , [20, 20]], [[440, 0], [-20, -20]])).withStroke(2.0, "#888888");
        var casing = Shape.Rectangle(R(440, 0, 120, 50, Pivot.CENTER_LEFT), 6.0)
            .withStroke("default", SharedColors.devicePanel.stroke)
            .withFill(SharedColors.devicePanel.fill);
        var screen = Shape.Rectangle(R(450, 0, 100, 30, Pivot.CENTER_LEFT), 3.0)
            .withStroke(5.0, "white")
            .withFill(SharedColors.lcdScreen);
        var content;
        if (appearance.measure == "abs") {
            content = appearance.absorbance;
        }
        else {
            content = (appearance.trans * 100).toFixed(0) + " %";
        }
        var textAbs = new PointText({
            point: P(540, 8),
            content: content,
            fillColor: "black",
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            fontSize: 20,
            justification: "right",
        });
        var graphic = new Group([
            bulb, light, collimator, rainbow, prism, prismLine, ray, slitUp, slitDown, wire, detector, casing, screen, textAbs
        ]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            watermark: rainbow.segments[0].point.add([10, 0]),
            pivot: P(260, 0),
        };
    };
    SpetrophotometerSchematic.properties = {
        label: "Spectrophotometer (Schematic)",
        defaultAppearance: {
            measure: "abs",
            absorbance: "0.042",
            trans: 0.5,
            rayNm: 590,
            fade: false
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "rayNm", label: "Wavelength",
                min: 400, max: 640
            },
            {
                spec: "toggle-buttons", key: "measure", label: "Display", size: "text",
                options: [
                    { value: "abs", label: "Absorbance" },
                    { value: "trans", label: "Transmittance" },
                ]
            },
            { spec: "text", key: "absorbance", label: "Absorbance", maxLength: 5, visible: function (a) { return a.measure == "abs"; } },
            {
                spec: "slider", key: "trans", label: "Transmittance",
                min: 0.01, max: 1.0, step: 0.01, unit: "%",
                visible: function (a) { return a.measure == "trans"; },
            },
            { spec: "checkbox", key: "fade", label: "Ray", subLabel: "Fade with transmittance" },
        ],
    };
    return SpetrophotometerSchematic;
}());
export { SpetrophotometerSchematic };
