var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { mirrorX } from "apparatus/draw";
import { P, Pivot, Polar, R, RP, S, Segments } from "apparatus/library/common";
import { Arrays } from "ext/array";
import { CompoundPath, Group, Path, Shape } from "paper";
var Solenoid = /** @class */ (function () {
    function Solenoid() {
    }
    Solenoid.prototype.render = function (appearance) {
        var coilBack = new CompoundPath([]).withStroke("thick");
        coilBack.strokeCap = "round";
        var coilFront = new CompoundPath([]).withStroke("thick");
        coilFront.strokeCap = "round";
        var coilStep = 20;
        var coilHeight = 50;
        var coils = appearance.coils;
        var width = coilStep * coils;
        var coilBackStep = P(coilStep / 2, coilHeight);
        var coilBackStepHandle = coilBackStep.add(P(10, 0));
        var coilFrontStep = P(coilStep / 2, -coilHeight);
        var coilFrontStepHandle = coilFrontStep.add(P(-10, 0));
        for (var i = 0; i < coils; i++) {
            coilBack.moveTo(P(i * coilStep, -coilHeight / 2));
            coilBack.cubicCurveBy(P(10, 0), coilBackStepHandle, coilBackStep);
            coilFront.moveTo(P((i + 0.5) * coilStep, coilHeight / 2));
            coilFront.cubicCurveBy(P(-10, 0), coilFrontStepHandle, coilFrontStep);
        }
        var leftFrontWire = new Path(Segments([[-10, 80]], [[0, -coilHeight / 2], [-15, 0]])).withStroke("thick");
        var rightBackWire = new Path(Segments([[0, -coilHeight / 2], , [15, 0]], [[10, 80]])).withStroke("thick");
        rightBackWire.position.x += width;
        var bar = new Path.Rectangle(R(0, 0, width, coilHeight * 0.74, Pivot.CENTER_LEFT))
            .withStroke("default").withFill("#d7a558");
        var pivot = bar.bounds.center;
        var magneticLines = new Group();
        if (appearance.fieldLines) {
            var magneticLinesCount = 2;
            for (var i = 0; i < magneticLinesCount; i++) {
                // Calculate position within core.
                var y = (magneticLinesCount - i - 0.5) * coilHeight / (2 * (magneticLinesCount + 1));
                var line = new Path([
                    S(P(width / 2, y)),
                    S(P(0, y), P(10, 0), P(-20, 0)),
                    // Point outside the core.
                    S(P(-width * 0.2 * (i + 1), coilHeight * (0.5 + 0.2 * (i + 1) * (coils / 10))), Polar(coilHeight * 0.3 * (coils / 10), -20 - 90).multiply(i + 1), // down
                    Polar(coilHeight * 0.7 * (coils / 10), -20 + 90).multiply(i + 1))
                ]);
                mirrorX(line, width / 2);
                var mirrored = line.clone();
                line.scale(1, -1, P(0, 0));
                magneticLines.addChildren([line, mirrored]);
                var middleOfTheLine = line.getPointAt(line.length * 0.5);
                var arrow = new Path([
                    S(middleOfTheLine),
                    S(middleOfTheLine.add(P(-5, 3))),
                    S(middleOfTheLine.add(P(-5, -3))),
                ]).close().withFill("#3020ff");
                var mirroredArrow = arrow.clone();
                arrow.scale(1, -1, P(0, 0));
                magneticLines.addChildren([arrow, mirroredArrow]);
            }
            magneticLines.withStroke("thin", "#3020ff");
        }
        var graphic = new Group(__spreadArray(__spreadArray([
            coilBack, rightBackWire, magneticLines
        ], (appearance.core ? [bar] : [])), [
            coilFront, leftFrontWire
        ]));
        if (!appearance.core)
            bar.remove();
        return {
            graphic: graphic,
            hitShape: Shape.Rectangle(RP(-10, -coilHeight / 2, coils * coilStep + 10, 60)),
            pivot: pivot,
            snapping: [
                { type: "wire", at: leftFrontWire.segments[0].point },
                { type: "wire", at: Arrays.last(rightBackWire.segments).point },
            ]
        };
    };
    Solenoid.properties = {
        label: "Solenoid",
        flippable: true,
        defaultAppearance: {
            coils: 10,
            fieldLines: false,
            core: true,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "coils", label: "Coils",
                min: 5, max: 15, step: 1,
            },
            { spec: "checkbox-group", label: "Show", checkboxes: [
                    { key: "fieldLines", label: "Field lines" },
                    { key: "core", label: "Core" },
                ] }
        ],
    };
    return Solenoid;
}());
export { Solenoid };
