var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var Match = /** @class */ (function () {
    function Match() {
    }
    Match.prototype.render = function (appearance) {
        var stick = new Path.Rectangle(R(0, 0, 8, appearance.length, Pivot.TOP_CENTER), 2.0)
            .withFill("#ddc292")
            .withStroke("thinner", "#b69660");
        var head = new Path(Segments([[-4.5, 8], , [0, -5]], [[0, -4], [-7, 0], [7, 0]], [[4.5, 8], [0, -5]])).close();
        var graphic = new Group([stick, head]);
        var hitShape = graphic.rectHitShape();
        var ignoreRotation = [];
        var _a = headColor(appearance), unlit = _a[0], burning = _a[1], stroke = _a[2];
        switch (appearance.state) {
            case "unlit":
                head
                    .withGradientFill("right-down", Gradients.simple(unlit.color()))
                    .withStroke("thinner", stroke);
                break;
            case "burning":
                head
                    .withGradientFill("right-down", Gradients.simple(burning.color()))
                    .withStroke("thinner", stroke);
                var flame = SVG.redFlame();
                flame.pivot = P(7, 43);
                flame.withPosition(P(0, 2));
                flame.opacity = 0.9;
                flame.scale(appearance.flameScale);
                ignoreRotation.push(flame);
                graphic.addChild(flame);
                break;
            case "burnt":
                head
                    .withGradientFill("right-down", Gradients.simple("#322727".color()))
                    .withStroke("thinner", "#100c0c");
                stick
                    .withGradientFill("up", [["#ddc292", 0.5], ["#312b20", 0.9]])
                    .setGradientStroke("up", [["#b69660", 0.5], ["#312b20", 0.9]]);
                break;
        }
        return {
            graphic: graphic,
            hitShape: hitShape,
            pivot: stick.bounds.center,
            ignoreRotation: ignoreRotation,
            watermark: P(0, 40)
        };
    };
    Match.properties = {
        label: "Match",
        defaultAppearance: {
            length: 85,
            state: "burning",
            headColor: "red",
            flameScale: 1.0,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "length", label: "Length",
                min: 60, max: 150, unit: "mm",
            },
            {
                spec: "toggle-buttons", size: "text", key: "state", label: "State",
                options: [
                    { value: "unlit", label: "New" },
                    { value: "burning", label: "Burning" },
                    { value: "burnt", label: "Burnt" },
                ]
            },
            {
                spec: "toggle-buttons", size: "text", key: "headColor", label: "Head colour",
                options: [
                    { value: "red", label: "Red" },
                    { value: "blue", label: "Blue" },
                ]
            },
            {
                spec: "slider", key: "flameScale", label: "Flame size",
                min: 0.5, max: 1.3, step: 0.05, unit: "%", visible: function (a) { return a.state == "burning"; }
            }
        ],
    };
    Match = __decorate([
        staticImplements()
    ], Match);
    return Match;
}());
export { Match };
function headColor(appearance) {
    switch (appearance.headColor) {
        case "red": return ["#b12548", "#c74916", "#7c0c13"];
        case "blue": return ["#2954b1", "#1a6dc7", "#0c3a7c"];
    }
}
