import { strokeWidth } from "apparatus/draw";
import { SVG } from "apparatus/svg";
var Mortar = /** @class */ (function () {
    function Mortar() {
    }
    Mortar.prototype.render = function (appearance) {
        var graphic = SVG.mortar();
        graphic.scale(2.0 * appearance.diameter / 160);
        graphic.strokeWidth = strokeWidth("default");
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: graphic.placeable(),
        };
    };
    Mortar.properties = {
        label: "Mortar",
        defaultAppearance: {
            diameter: 160,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 100, max: 220
            }
        ],
    };
    return Mortar;
}());
export { Mortar };
var Pestle = /** @class */ (function () {
    function Pestle() {
    }
    Pestle.prototype.render = function (appearance) {
        var graphic = SVG.pestle();
        graphic.scale(2.5 * appearance.length / 95);
        graphic.strokeWidth = strokeWidth("default");
        return { graphic: graphic, hitShape: graphic.bounds.toShape() };
    };
    Pestle.properties = {
        label: "Pestle",
        flippable: true,
        defaultAppearance: {
            length: 95
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "length", label: "Diameter",
                min: 80, max: 150,
            }
        ],
    };
    return Pestle;
}());
export { Pestle };
