import { breakPathClosed, setDefaultStyle } from "apparatus/draw";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Path } from "paper";
var OilBubbler = /** @class */ (function () {
    function OilBubbler() {
    }
    OilBubbler.prototype.render = function (appearance) {
        var height = appearance.size;
        var width = height / 3;
        var body = new Path.Rectangle(R(0, 0, width, height, Pivot.TOP_CENTER), width / 2);
        var inlet = new Path(Segments([[-8, 10]], [[-8, -20], , [0, -8]], [[-20, -32], [8, 0]], [[-50, -32]], [[-50, -47]], [[-20, -47], , [18, 0]], [[8, -20], [0, -18]], [[8, 10]]));
        inlet.closePath();
        var outletY = appearance.size / 3;
        var outlet = new Path.Rectangle(R(0, outletY, 60, 12, Pivot.CENTER_LEFT));
        var liquidMaskSubtract = new Path.Rectangle(R(0, 0, 100, 70, Pivot.TOP_CENTER));
        var liquidMask = body.subtract(liquidMaskSubtract);
        var graphic = breakPathClosed(inlet.unite(body).unite(outlet), 3, 10);
        // Inner funnel
        var funnelR = new Path(Segments([[30, 40], , [0, 15]], [[5, 70], [0, -15]], [[5, 145]]));
        var funnelL = funnelR.clone();
        funnelL.scale(-1, 1, P(0, 0));
        // Scale down for size. The above is scaled for size = 180
        var scaling = appearance.size / 180;
        funnelR.scale(scaling, P(0, 0));
        funnelL.scale(scaling, P(0, 0));
        graphic.addChildren([funnelR, funnelL]);
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.bounds.toShape(),
            snapping: [
                { type: "connectable", at: P(-50, -39.5), facing: -90 },
                { type: "connectable", at: P(60, 60), facing: 90 },
            ]
        };
    };
    OilBubbler.properties = {
        label: "Oil Bubbler",
        canContainLiquids: true,
        flippable: true,
        defaultAppearance: {
            size: 180
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 120, max: 200
            }
        ],
    };
    return OilBubbler;
}());
export { OilBubbler };
