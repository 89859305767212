import { g } from "analytics";
import { commandProcessor } from "command/command";
import { t } from "i18next";
// This method is just a wrapper around the
// command processors's undo/redo operations.
export function undo(source) {
    if (commandProcessor.undo()) {
        g("Undo", source);
    }
    else {
        g("Undo-noop", source);
        toastr.info(t("info.undo_max"));
    }
}
export function redo(source) {
    if (commandProcessor.redo()) {
        g("Redo", source);
    }
    else {
        g("Redo-noop", source);
        toastr.info(t("info.redo_max"));
    }
}
