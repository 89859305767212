var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { P, Pivot, R } from "apparatus/library/common";
import { BasicShapes } from "apparatus/library/shapes/common";
import { Shape } from "paper";
var Rectangle = /** @class */ (function () {
    function Rectangle() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 8, maxX: 250,
                minY: 8, maxY: 250,
                toAppearance: function (f, p) { return f({
                    width: p.x * 2,
                    height: p.y * 2
                }); },
                fromAppearance: function (a) { return P(a.width / 2, a.height / 2); },
                scale: 1.0,
                constraints: function (a, modifiers) {
                    if (modifiers.shift) {
                        // Force square.
                        var size = Math.max(a.width, a.height);
                        return { width: size, height: size };
                    }
                    return;
                }
            }
        ];
    }
    Rectangle.prototype.render = function (appearance) {
        var cornerRadius = 0;
        if (appearance.roundedCorner) {
            cornerRadius = Math.min(Math.min(appearance.width / 2, appearance.height / 2), appearance.cornerRadius);
        }
        var graphic = Shape.Rectangle(R(0, 0, appearance.width, appearance.height, Pivot.CENTER), cornerRadius);
        BasicShapes.applyStyle(graphic, appearance);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
        };
    };
    Rectangle.properties = {
        label: "Rectangle",
        defaultAppearance: __assign({ width: 200, height: 100, roundedCorner: false, cornerRadius: 10 }, BasicShapes.defaultAppearance),
        appearanceSpecs: __spreadArray(__spreadArray([], BasicShapes.specs), [
            BasicShapes.header,
            {
                spec: "checkbox", key: "roundedCorner", label: "Rounded corners",
            },
            {
                spec: "slider", key: "cornerRadius", label: "Corner size",
                min: 1, max: 30,
                visible: function (a) { return a.roundedCorner; }
            }
        ]),
    };
    return Rectangle;
}());
export { Rectangle };
