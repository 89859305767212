import { P } from "apparatus/library/common";
import { Color, Gradient, Group, Rectangle, Shape } from "paper";
import { setDefaultStyle } from "../draw";
var Table = /** @class */ (function () {
    function Table() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 400, maxX: 1500,
                minY: 0, maxY: 20,
                fromAppearance: function (a) { return P(a.width, 20); },
                toAppearance: function (f, p) { return f({ width: p.x }); },
                scale: 2.0,
            }
        ];
    }
    Table.prototype.render = function (appearance) {
        var width = appearance.width, thickness = appearance.thickness;
        var corner = thickness / 4;
        var legOffset = appearance.legOffset;
        var legHeight = getLegHeight(appearance);
        var tableTopRect = new Rectangle(0, 0, width, thickness);
        var leftLegRect = new Rectangle(legOffset, corner, thickness, legHeight);
        var rightLegRect = new Rectangle(width - thickness - legOffset, corner, thickness, legHeight);
        var tableTop = Shape.Rectangle(tableTopRect, corner);
        var leftLeg = Shape.Rectangle(leftLegRect);
        var rightLeg = Shape.Rectangle(rightLegRect);
        setDefaultStyle(tableTop, leftLeg, rightLeg);
        switch (appearance.color) {
            case "white":
                tableTop.fillColor = "white";
                leftLeg.fillColor = "white";
                rightLeg.fillColor = "white";
                break;
            case "brown":
            case "fireproof":
                var gradient = new Gradient([
                    ["#ce9158", 0.2],
                    ["#ad7847", 0.21],
                    ["#704e2f", 1.0]
                ]);
                if (appearance.color == "brown") {
                    tableTop.fillColor = new Color(gradient, tableTopRect.topCenter, tableTopRect.bottomCenter);
                }
                else { // Fireproof
                    tableTop.withGradientFill("down", [["#665e57", 0.1], ["#312f2d", 0.3]]);
                }
                leftLeg.fillColor = new Color(gradient, leftLegRect.leftCenter, leftLegRect.rightCenter);
                rightLeg.fillColor = new Color(gradient, rightLegRect.leftCenter, rightLegRect.rightCenter);
        }
        var graphic = new Group();
        var legs = new Group();
        var hitShapes = [tableTopRect];
        if (appearance.legs == "left") {
            legs.addChild(leftLeg);
            hitShapes.push(leftLegRect);
            rightLeg.remove();
        }
        else if (appearance.legs == "right") {
            legs.addChild(rightLeg);
            hitShapes.push(rightLegRect);
            leftLeg.remove();
        }
        else if (appearance.legs == "both") {
            legs.addChild(leftLeg);
            legs.addChild(rightLeg);
            hitShapes.push(rightLegRect);
            hitShapes.push(leftLegRect);
        }
        else {
            rightLeg.remove();
            leftLeg.remove();
        }
        graphic.addChild(legs);
        graphic.addChild(tableTop);
        return {
            graphic: graphic,
            pivot: tableTopRect.center,
            hitShape: hitShapes.map(Shape.Rectangle),
            snapping: tableTop.topEdgeAsPlaceableSurface(),
        };
    };
    Table.properties = {
        label: "Table",
        defaultAppearance: {
            height: "short",
            width: 700,
            thickness: 40,
            legs: "both",
            color: "brown",
            legOffset: 100,
        },
        appearanceSpecs: [
            {
                spec: "slider",
                key: "width",
                label: "Width",
                min: 400,
                max: 1500,
            },
            {
                spec: "slider", key: "thickness", label: "Thickness",
                min: 20, max: 40, step: 5
            },
            {
                spec: "radio",
                key: "color",
                label: "Colour",
                options: [
                    { value: "white", label: "White" },
                    { value: "brown", label: "Brown" },
                    { value: "fireproof", label: "Fireproof" },
                ]
            },
            {
                spec: "radio",
                key: "legs",
                label: "Legs",
                options: [
                    { value: "none", label: "No legs" },
                    { value: "both", label: "Both legs" },
                    { value: "left", label: "Left only" },
                    { value: "right", label: "Right only" },
                ]
            },
            {
                spec: "radio",
                key: "height",
                label: "Legs display",
                options: [
                    { value: "short", label: "Very short" },
                    { value: "medium", label: "Short" },
                    { value: "full", label: "Full height" },
                ],
                visible: function (a) { return a.legs != "none"; },
            },
            {
                spec: "slider",
                key: "legOffset",
                label: "Leg offset",
                min: 0,
                max: 100,
                visible: function (a) { return a.legs != "none"; },
            }
        ],
    };
    return Table;
}());
export { Table };
function getLegHeight(appearance) {
    switch (appearance.height) {
        case "short": return 75;
        case "medium":
            return 150;
        case "full": return 400;
    }
}
