var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { breakPathClosed, setDefaultStyle, uniteAll } from "apparatus/draw";
import { P, Pivot, R } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
import { Path, Shape } from "paper";
export function condenserCaseShape(height, sideletRadius, sidelets) {
    if (sidelets === void 0) { sidelets = "none"; }
    var inletRadius = 15;
    var body = new Path.Rectangle(R(0, 0, 36, height, Pivot.TOP_CENTER), 20);
    var inlet = new Path.Rectangle(R(0, 3, inletRadius, 23, Pivot.BOTTOM_CENTER));
    var outlet = new Path.Rectangle(R(0, height - 3, inletRadius, 23, Pivot.TOP_CENTER));
    var combinedShape = uniteAll(body, outlet, inlet);
    var snap = [
        { type: "connectable", at: P(0, height + 20), facing: 180 },
        { type: "connectable", at: P(0, -20), facing: 0 },
    ];
    var graphic;
    if (sidelets == "none") {
        graphic = breakPathClosed(combinedShape, 4, 10);
        return { group: graphic, snap: snap };
    }
    // Create sidelets.
    var sidelet = new Path.Rectangle(R(0, 30, 50, sideletRadius, Pivot.CENTER_LEFT));
    combinedShape = uniteAll(combinedShape, sidelet);
    snap.push({ type: "connectable", at: P(50, 30), facing: 90 });
    if (sidelets == "top-only") {
        graphic = breakPathClosed(combinedShape, 4, 9, 14);
    }
    else if (sidelets == "same-top") {
        var sidelet2 = new Path.Rectangle(R(0, 55, 50, sideletRadius, Pivot.CENTER_LEFT));
        combinedShape = uniteAll(combinedShape, sidelet2);
        graphic = breakPathClosed(combinedShape, 4, 9, 13, 18);
        snap.push({ type: "connectable", at: P(50, 55), facing: 90 });
    }
    else {
        // Same or opposite.
        var sidelet2 = new Path.Rectangle(R(0, height - 30, 50, sideletRadius, sidelets == "same" ? Pivot.CENTER_LEFT : Pivot.CENTER_RIGHT));
        combinedShape = uniteAll(combinedShape, sidelet2);
        if (sidelets == "same") {
            graphic = breakPathClosed(combinedShape, 4, 9, 13, 18);
            snap.push({ type: "connectable", at: P(50, height - 30), facing: 90 });
        }
        else { // == opposite
            graphic = breakPathClosed(combinedShape, 3, 8, 13, 18);
            snap.push({ type: "connectable", at: P(-50, height - 30), facing: 270 });
        }
    }
    return {
        group: graphic,
        snap: snap,
    };
}
export var CommonCondenser;
(function (CommonCondenser) {
    var NoPorts;
    (function (NoPorts) {
        NoPorts.defaultAppearance = {
            height: 240
        };
        NoPorts.appearanceSpecs = [
            {
                spec: "slider", key: "height", label: "Height",
                min: 200, max: 350, unit: "mm"
            }
        ];
    })(NoPorts = CommonCondenser.NoPorts || (CommonCondenser.NoPorts = {}));
    CommonCondenser.defaultAppearance = {
        ports: "same",
        height: 240,
    };
    CommonCondenser.appearanceSpecs = __spreadArray(__spreadArray([], NoPorts.appearanceSpecs), [
        {
            spec: "radio", key: "ports", label: "Ports",
            options: [
                { value: "same", label: "Same side" },
                { value: "opposite", label: "Opposite sides", tier: "boost" },
            ],
            allowSelectingTierLockedOptions: true,
        }
    ]);
    function isBoostRequired(appearance) {
        return appearance.ports === "opposite";
    }
    CommonCondenser.isBoostRequired = isBoostRequired;
})(CommonCondenser || (CommonCondenser = {}));
var FractionatingColumn = /** @class */ (function () {
    function FractionatingColumn() {
    }
    FractionatingColumn.prototype.render = function (appearance) {
        var graphic = SVG.fractionating();
        graphic.scale(1.5);
        setDefaultStyle(graphic);
        var hitShape = Shape.Rectangle(graphic.bounds);
        var snapping = { type: "connectable", at: P(77, 10.5), facing: 90 };
        return { graphic: graphic, hitShape: hitShape, snapping: snapping };
    };
    FractionatingColumn.properties = {
        label: "Fractionating Column",
        flippable: true,
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return FractionatingColumn;
}());
export { FractionatingColumn };
var AllihnCondenser = /** @class */ (function () {
    function AllihnCondenser() {
    }
    AllihnCondenser.prototype.render = function (appearance) {
        var graphic = SVG.allihn();
        graphic.scale(1.5);
        setDefaultStyle(graphic);
        var hitShape = Shape.Rectangle(graphic.bounds);
        return { graphic: graphic, hitShape: hitShape, snapping: LIEBIG_GRAHAM_ALLIHN_CONDENSER_SNAPPINGS };
    };
    AllihnCondenser.properties = {
        label: "Allihn Condenser",
        flippable: true,
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return AllihnCondenser;
}());
export { AllihnCondenser };
var GrahamCondenser = /** @class */ (function () {
    function GrahamCondenser() {
    }
    GrahamCondenser.prototype.render = function (appearance) {
        var graphic = SVG.graham();
        graphic.scale(1.5);
        setDefaultStyle(graphic);
        var hitShape = Shape.Rectangle(graphic.bounds);
        return { graphic: graphic, hitShape: hitShape, snapping: LIEBIG_GRAHAM_ALLIHN_CONDENSER_SNAPPINGS };
    };
    GrahamCondenser.properties = {
        label: "Graham Condenser",
        flippable: true,
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return GrahamCondenser;
}());
export { GrahamCondenser };
export var LIEBIG_GRAHAM_ALLIHN_CONDENSER_SNAPPINGS = [
    { type: "connectable", at: P(51, 26.5), facing: 90 },
    { type: "connectable", at: P(51, 200.5), facing: 90 },
    { type: "connectable", at: P(8, -27), facing: 0 },
    { type: "connectable", at: P(9.25, 247), facing: 180 },
];
