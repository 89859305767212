import { mirrorX, softShade } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var BeamBalance = /** @class */ (function () {
    function BeamBalance() {
    }
    BeamBalance.prototype.render = function (appearance) {
        var tilt = appearance.tilt, size = appearance.size;
        var plateWidth = size / 2.5 - 8; // (120)
        var height = size; // (320)
        var width = size / 2 + 126; // (286)
        var halfWidth = width / 2; // (143)
        var base = Shape.Rectangle(R(0, height, width + plateWidth / 2 + 20, 20, Pivot.CENTER), 2.0)
            .withStroke("default", SharedColors.stroke)
            .withFill("#878c91");
        var pillar = Shape.Rectangle(R(0, 0, 25, height, Pivot.TOP_CENTER))
            .withGradientFill("right", Gradients.cylinder("#aaaaaa".color()))
            .withStroke("default", SharedColors.stroke);
        var beam = new Path(Segments([[halfWidth - 64, -24], [-10, -8]], [[halfWidth - 31, -3], [-6, 0]], [[halfWidth, -3], , [2, 0]], [[halfWidth, 8], [3, 0]], [[halfWidth - 30, 8], , [-10, 0]], [[halfWidth - 70, -10], [7, -2]], [[3, 3]], [[0, 15]])).close().withStroke("default", SharedColors.stroke).withGradientFill("right-down", Gradients.cylinder("#777777".color()));
        mirrorX(beam);
        beam.rotate(tilt, P(0, 0));
        var plate = Shape.Rectangle(R(0, height - 70, plateWidth, 10, Pivot.CENTER), 2.0)
            .withGradientFill("right", Gradients.cylinder("#cccccc".color()))
            .withStroke("thin", "#888888");
        var wire = new Path(Segments([[-plateWidth / 2, height - 70]], [[-plateWidth / 2, plateWidth * 0.6]], [[0, 0]], [[plateWidth / 2, plateWidth * 0.6]], [[plateWidth / 2, height - 70]])).withStroke("thin", "#888888");
        var scaleL = new Group([wire, plate]);
        scaleL.pivot = P(0, 0);
        scaleL.position = P(-halfWidth + 18, 2).rotate(tilt, P(0, 0));
        var scaleR = scaleL.clone();
        scaleR.position = P(halfWidth - 18, 2).rotate(tilt, P(0, 0));
        // add markings.
        var line_0 = new Path.Line(P(0, 23), P(0, 30)).withStroke(1.0, "#555555");
        var line_1 = line_0.clone();
        line_1.rotate(-10, P(0, 0));
        var line_2 = line_0.clone();
        line_2.rotate(10, P(0, 0));
        var graphic = new Group([
            pillar,
            softShade("full", null, P(0, -20), P(0, 25), "#777777", 10.0),
            base, scaleL, scaleR, beam, line_0, line_1, line_2
        ]);
        return {
            graphic: graphic,
            hitShape: [
                base.bounds.toShape(),
                beam.bounds.toShape(),
                pillar.bounds.toShape(),
                plate.bounds.toShape(),
                scaleR.children[1].bounds.toShape(),
            ],
            pivot: pillar.bounds.center,
            snapping: [
                base.placeable(),
                scaleL.children[1].topEdgeAsPlaceableSurface(),
                scaleR.children[1].topEdgeAsPlaceableSurface(),
            ]
        };
    };
    BeamBalance.properties = {
        label: "Beam Balance",
        defaultAppearance: {
            tilt: 0,
            size: 240
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 180, max: 320,
            },
            {
                spec: "slider", key: "tilt", label: "Tilt",
                min: -10, max: 10, step: 0.1,
            }
        ],
    };
    return BeamBalance;
}());
export { BeamBalance };
