var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Snap } from "editing/snapping/type";
import { areRotationsClose } from "math";
/** Allowed tolerance of rotation differences to allow a placeable to be snapped onto a surface. */
var PLACEABLE_SURFACE_ROTATION_TOLERANCE = 5.0;
/** Allowed tolerance in angle difference for 2 connectables to snap to each other. */
var CONNECTABLE_ROTATION_TOLERANCE = 15.0;
/**
 * Precomputes snapping information.
 *  Selected - components that are currently selected
 *  others - Other components, excluding the Selected ones, against which snap can occur.
 */
export function precomputeSnapping(selected, others) {
    var _a;
    var precomputed = [];
    // First categorise the currently selected apparatus by snap type.
    var placeables = [];
    var attachables = [];
    var connectables = [];
    var spottingTilePlaceable = [];
    var tlcSpots = [];
    var pluggables = [];
    var wires = [];
    var wirePorts = [];
    var shapeEnds = [];
    for (var _i = 0, selected_1 = selected; _i < selected_1.length; _i++) {
        var a = selected_1[_i];
        for (var _b = 0, _c = a.globalSnapping; _b < _c.length; _b++) {
            var snapping = _c[_b];
            if (snapping.type == "placeable") {
                placeables.push({ snapping: snapping, rotation: a.rotation + ((_a = snapping.facing) !== null && _a !== void 0 ? _a : 0) });
            }
            else if (snapping.type == "attachable") {
                attachables.push(snapping);
            }
            else if (snapping.type == "connectable") {
                // Modify the "facing" attribute to take into account the rotation.
                var adjustedFacing = (a.rotation + snapping.facing + 360) % 360;
                connectables.push(__assign(__assign({}, snapping), { facing: adjustedFacing }));
            }
            else if (snapping.type == "spotting_tile_placeable") {
                spottingTilePlaceable.push(__assign({}, snapping));
            }
            else if (snapping.type == "tlc_spot") {
                tlcSpots.push(snapping);
            }
            else if (snapping.type == "pluggable") {
                pluggables.push({ id: a.id, snapping: snapping });
            }
            else if (snapping.type == "wire") {
                wires.push(snapping);
            }
            else if (snapping.type == "wireport") {
                wirePorts.push(snapping);
            }
            else if (snapping.type == "shape_end") {
                shapeEnds.push(snapping);
            }
            a.setSnapVisualisation([
                "placeable", "attachable", "connectable", "spotting_tile_placeable",
                "tlc_spot", "pluggable", "wireport", "wire", "shape_end"
            ]);
        }
    }
    // Sort remaining items by type, depending on the snap types
    // of currently selected apparatus, e.g. find "placeable_surface"
    // for "placeable" items, but only if there are currently selected
    // items with snap type "placeable".
    for (var _d = 0, others_1 = others; _d < others_1.length; _d++) {
        var other = others_1[_d];
        var snapTypeMask = {};
        for (var _e = 0, _f = other.globalSnapping; _e < _f.length; _e++) {
            var snapping = _f[_e];
            if (placeables.length > 0 && snapping.type === "placeable_surface") {
                for (var _g = 0, placeables_1 = placeables; _g < placeables_1.length; _g++) {
                    var placeable = placeables_1[_g];
                    // Rotation of the placeable surface.
                    var surfaceRotation = other.rotation + Snap.getFacing(snapping);
                    if (areRotationsClose(placeable.rotation, surfaceRotation, PLACEABLE_SURFACE_ROTATION_TOLERANCE)) {
                        var potentialSnapping = precomputePlaceable(placeable.snapping, snapping);
                        if (potentialSnapping) {
                            precomputed.push(potentialSnapping);
                            snapTypeMask.placeable_surface = true;
                        }
                    }
                }
            }
            if (attachables.length > 0 && snapping.type === "attachable_on") {
                for (var _h = 0, attachables_1 = attachables; _h < attachables_1.length; _h++) {
                    var attachable = attachables_1[_h];
                    precomputed.push(precomputePointToLine(attachable, snapping));
                    snapTypeMask.attachable_on = true;
                }
            }
            if (connectables.length > 0 && snapping.type === "connectable") {
                // For connectables, evaluate them on the spot.
                var otherAngle = (other.rotation + snapping.facing + 540) % 360; // 540 = 360 + 180. Additioal 360 because modulo can return negative.
                for (var _j = 0, connectables_1 = connectables; _j < connectables_1.length; _j++) {
                    var connectable = connectables_1[_j];
                    if (areRotationsClose(connectable.facing, otherAngle, CONNECTABLE_ROTATION_TOLERANCE)) {
                        precomputed.push({ type: "point_to_point", delta: snapping.at.subtract(connectable.at) });
                        snapTypeMask.connectable = true;
                    }
                }
            }
            if (spottingTilePlaceable.length > 0 && snapping.type === "spotting_tile") {
                var _loop_1 = function (placeable) {
                    var snaps = snapping.at.map(function (at) { return ({ type: "point_to_point", delta: at.subtract(placeable.at) }); });
                    precomputed.push.apply(precomputed, snaps);
                };
                for (var _k = 0, spottingTilePlaceable_1 = spottingTilePlaceable; _k < spottingTilePlaceable_1.length; _k++) {
                    var placeable = spottingTilePlaceable_1[_k];
                    _loop_1(placeable);
                }
                snapTypeMask.spotting_tile = true;
            }
            if (tlcSpots.length > 0 && snapping.type === "tlc_plate") {
                for (var _l = 0, tlcSpots_1 = tlcSpots; _l < tlcSpots_1.length; _l++) {
                    var spot = tlcSpots_1[_l];
                    precomputed.push(precomputePointToLine(spot, snapping));
                }
                snapTypeMask.tlc_plate = true;
            }
            if (pluggables.length > 0 && snapping.type === "neck") {
                for (var _m = 0, pluggables_1 = pluggables; _m < pluggables_1.length; _m++) {
                    var pluggable = pluggables_1[_m];
                    // Check rotation of neck.
                    var neckRotation = other.rotation + snapping.facing;
                    precomputed.push({
                        type: "point_to_point",
                        delta: snapping.top.subtract(pluggable.snapping.at),
                        // Also set the appearance specs on the pluggable.
                        appearanceChange: {
                            id: pluggable.id,
                            partialSpecs: pluggable.snapping.requestDiameter(snapping.diameter),
                            rotation: neckRotation,
                        }
                    });
                    snapTypeMask.neck = true;
                }
            }
            // Snap wires and wireports to wires.
            if (wires.length > 0 && (snapping.type == "wire" || snapping.type == "wireport")) {
                for (var _o = 0, wires_1 = wires; _o < wires_1.length; _o++) {
                    var wire = wires_1[_o];
                    precomputed.push({ type: "point_to_point", delta: snapping.at.subtract(wire.at) });
                }
                snapTypeMask.wire = true;
                snapTypeMask.wireport = true;
            }
            // Snap wires to wireports.
            if (wirePorts.length > 0 && snapping.type == "wire") {
                snapTypeMask.wire = true;
                for (var _p = 0, wirePorts_1 = wirePorts; _p < wirePorts_1.length; _p++) {
                    var wire = wirePorts_1[_p];
                    precomputed.push({ type: "point_to_point", delta: snapping.at.subtract(wire.at) });
                }
            }
            // Snap shape ends to other shape ends.
            if (shapeEnds.length > 0 && snapping.type == "shape_end") {
                snapTypeMask.shape_end = true;
                for (var _q = 0, shapeEnds_1 = shapeEnds; _q < shapeEnds_1.length; _q++) {
                    var shapeEnd = shapeEnds_1[_q];
                    precomputed.push({ type: "point_to_point", delta: snapping.at.subtract(shapeEnd.at) });
                }
            }
        }
        var snapTypeFilter = Snap.typeFilterFrom(snapTypeMask);
        if (snapTypeFilter.length > 0) {
            other.setSnapVisualisation(snapTypeFilter);
        }
    }
    return precomputed;
}
function precomputePlaceable(placeable, surface) {
    // Compute start and end positions in Move Space.
    var start = surface.start.subtract(placeable.at);
    var end = surface.end.subtract(placeable.at);
    var surfaceVector = end.subtract(start);
    return {
        type: "point_to_line",
        start: start,
        surfaceVector: surfaceVector,
        surfaceVectorNorm: surfaceVector.normalize()
    };
}
function precomputePointToLine(attachable, on) {
    // Compute start and end positions in Move Space.
    var start = on.start.subtract(attachable.at);
    var end = on.end.subtract(attachable.at);
    var surfaceVector = end.subtract(start);
    return {
        type: "point_to_line",
        start: start,
        surfaceVector: surfaceVector,
        surfaceVectorNorm: surfaceVector.normalize()
    };
}
/**
 * Evaluates an array of precomputed snappings and returns the vector it should snap to
 * or returns undefined if the point should not be snapped.
 */
export function evaluatePrecomputedSnappings(rawDeltaVector, snappings, snapDistance) {
    for (var _i = 0, snappings_1 = snappings; _i < snappings_1.length; _i++) {
        var snapping = snappings_1[_i];
        switch (snapping.type) {
            case "point_to_line":
                var fromStartToDelta = rawDeltaVector.subtract(snapping.start);
                var project = fromStartToDelta.dot(snapping.surfaceVectorNorm);
                // Allow overhang by snapDistance.
                if (project < 0 && project > -snapDistance) {
                    project = 0;
                }
                else if (project > snapping.surfaceVector.length && project - snapDistance < snapping.surfaceVector.length) {
                    project = snapping.surfaceVector.length;
                }
                // Check the projection lies on the surface vector.
                if (project >= 0 && project <= snapping.surfaceVector.length) {
                    // Compute the nearest point on line.
                    var nearestPoint = snapping.start.add(snapping.surfaceVectorNorm.multiply(project));
                    // Measure the distance.
                    if (rawDeltaVector.isClose(nearestPoint, snapDistance)) {
                        return { delta: nearestPoint };
                    }
                }
                break;
            case "point_to_point":
                if (rawDeltaVector.isClose(snapping.delta, snapDistance)) {
                    return { delta: snapping.delta, appearanceChange: snapping.appearanceChange };
                }
                break;
        }
    }
    return;
}
