import { Group, Path, Shape } from "paper";
import { setDefaultStyle } from "../draw";
import { R, Segments } from "./common";
var StirringRod = /** @class */ (function () {
    function StirringRod() {
    }
    StirringRod.prototype.render = function (appearance) {
        var width = 8;
        var height = appearance.height;
        var bulbWidth = 15;
        var bulbHeight = 25;
        var rod = new Path.Rectangle(R(-width / 2.0, 0, width, height), width / 2);
        var finalRod;
        if (appearance.style == "bulb") {
            var bulb = new Path.Rectangle(R(-bulbWidth / 2, height - bulbHeight, bulbWidth, bulbHeight), bulbWidth / 2);
            finalRod = bulb.unite(rod);
        }
        else {
            finalRod = rod;
        }
        setDefaultStyle(finalRod);
        finalRod.fillColor = "#ffffffaa";
        var graphic = new Group();
        graphic.addChild(finalRod);
        if (appearance.showArrow) {
            graphic.insertChild(0, new Path(Segments([[-20, -2]], [[-15, 0], undefined, [-50, 20]], [[15, 0], [50, 20]], [[20, -2]])).withStroke(3.0, "#777777").withPosition({ y: 20 }));
        }
        var hitShape = Shape.Rectangle(R(-bulbWidth / 2.0, 0, bulbWidth, height));
        return { graphic: graphic, hitShape: hitShape };
    };
    StirringRod.properties = {
        label: "Stirring rod",
        defaultAppearance: {
            style: "bulb",
            height: 220,
            showArrow: false,
        },
        appearanceSpecs: [
            {
                spec: "radio", key: "style", label: "Style",
                options: [
                    { value: "bulb", label: "Normal" },
                    { value: "simple", label: "Simple" },
                ],
            },
            {
                spec: "slider", key: "height", label: "Length",
                min: 100, max: 300
            },
            {
                spec: "checkbox", key: "showArrow", label: "Stirring arrow", subLabel: "Show",
            }
        ],
    };
    return StirringRod;
}());
export { StirringRod };
