import { setDefaultStyle } from "apparatus/draw";
import { SVG } from "apparatus/svg";
import { Group } from "paper";
var Desiccator = /** @class */ (function () {
    function Desiccator() {
    }
    Desiccator.prototype.render = function (appearance) {
        var desiccator = SVG.desiccator();
        var graphic = new Group([desiccator]);
        if (appearance.lid == "on") {
            var lid = SVG.desiccator_lid();
            graphic.addChild(lid);
            lid.position.y -= 25;
        }
        graphic.scale(2.0 * appearance.scale);
        setDefaultStyle(graphic);
        return { graphic: graphic, hitShape: graphic.bounds.toShape() };
    };
    Desiccator.properties = {
        label: "Desiccator",
        defaultAppearance: {
            scale: 1.0,
            lid: "none"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.8, max: 2.0, step: 0.05
            },
            {
                spec: "radio", key: "lid", label: "Lid",
                options: [
                    { value: "none", label: "No lid" },
                    { value: "on", label: "Lid on" },
                ]
            }
        ],
    };
    return Desiccator;
}());
export { Desiccator };
