// Hacky wraper for jquery slider pips.
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { clamp, quantize } from "apparatus/library/common";
import { focusMainApp, Keys } from "keyboard";
import * as React from "react";
import { Slider } from "./slider";
var ComboSlider = /** @class */ (function (_super) {
    __extends(ComboSlider, _super);
    function ComboSlider(props) {
        var _this = _super.call(this, props) || this;
        _this.onSlideStart = function () {
            var _a;
            (_a = _this.props.onStart) === null || _a === void 0 ? void 0 : _a.call(null);
        };
        // Called when slider changed by sliding
        _this.onSlideChange = function (value) {
            _this.setState({ inputText: _this.inputText(value) });
            _this.props.onChange(value);
        };
        // Called when slider changed by setting a value.
        _this.onSlideEnd = function (initialValue, value) {
            _this.setState({ value: value, inputText: _this.inputText(value) });
            _this.props.onEnd(initialValue, value);
        };
        _this.state = { value: props.initialValue, inputText: _this.inputText(props.initialValue) };
        return _this;
    }
    /** Calculates input value to show in the input field. */
    ComboSlider.prototype.inputText = function (value) {
        if (this.props.unit === "%") {
            return Math.round(value * 100).toString();
        }
        return value.toString();
    };
    ComboSlider.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (prevProps.initialValue != this.props.initialValue) {
            if (prevState.value != this.props.initialValue) {
                this.setState({
                    value: this.props.initialValue,
                    inputText: this.inputText(this.props.initialValue)
                });
            }
        }
    };
    /** Handle change when the input is typed into. */
    ComboSlider.prototype.onInputChange = function (event) {
        var value = event.target.value;
        this.setState({ inputText: value });
    };
    /** Handle change when the input loses focus. */
    ComboSlider.prototype.onInputBlur = function (event) {
        var _a;
        var value = event.target.value;
        var convertedValue = +value;
        if (isNaN(convertedValue)) {
            // Invalid value, reset back to original value and return.
            this.setState({ inputText: this.props.initialValue.toString() });
            return;
        }
        // Special handling based on unit.
        if (this.props.unit === "%") {
            convertedValue = convertedValue / 100;
        }
        // Clamp the value to range.
        convertedValue = clamp(convertedValue, this.props.min, this.props.max);
        // Round it to expected steps.
        convertedValue = quantize(convertedValue, this.props.min, (_a = this.props.step) !== null && _a !== void 0 ? _a : 1);
        // Invoke callback.
        this.props.onEnd(this.props.initialValue, convertedValue);
        // Set value for input.
        this.setState({ inputText: this.inputText(convertedValue) });
    };
    ComboSlider.prototype.render = function () {
        var _this = this;
        var comboClass = this.props.unit ? "combo-3" : "combo-2";
        return React.createElement(React.Fragment, null,
            React.createElement(Slider, { key: "slider", initialValue: this.state.value, min: this.props.min, max: this.props.max, step: this.props.step, pipsStep: this.props.pipsStep, formatLabel: this.props.formatLabel, onChange: this.onSlideChange, onStart: this.onSlideStart, onEnd: this.onSlideEnd, extraClass: "combo " + comboClass }),
            React.createElement("input", { key: "input", className: "slider-input ui-input " + comboClass, value: this.state.inputText, onChange: function (e) { return _this.onInputChange(e); }, onBlur: function (e) { return _this.onInputBlur(e); }, onKeyDown: function (e) { if (e.keyCode == Keys.ENTER)
                    focusMainApp(); } }),
            this.props.unit ? React.createElement("span", { className: "slider-input-unit" }, this.props.unit) : undefined);
    };
    return ComboSlider;
}(React.Component));
export { ComboSlider };
