// Basic set of commands.
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { addItems, addItemsAtIndices, addToGroup, removeFromGroup, removeItems, updateAppearance, updateAppearanceProperties, updateItemsAppearance, updateItemsAppearanceProperties } from "store/actions";
import appStore, { GroupsOperationData } from "store/store";
/** Changes the selection state of several items. */
var DeselectAndSelect = /** @class */ (function () {
    function DeselectAndSelect(ids) {
        var _a, _b;
        this.selectIds = (_a = ids.select) !== null && _a !== void 0 ? _a : [];
        this.deselectIds = (_b = ids.deselect) !== null && _b !== void 0 ? _b : [];
    }
    DeselectAndSelect.prototype.execute = function () {
        appStore.dispatchFn("SelectDeselect", DeselectAndSelect.mutator(this.selectIds, this.deselectIds));
    };
    DeselectAndSelect.prototype.undo = function () {
        // Swap select and deselect around.
        appStore.dispatchFn("SelectDeselect", DeselectAndSelect.mutator(this.deselectIds, this.selectIds));
    };
    DeselectAndSelect.mutator = function (select, deselect) {
        return function (state) {
            var newState = __assign({}, state);
            newState.items = state.items.map(function (a) {
                if (select.indexOf(a.id) > -1) {
                    return __assign(__assign({}, a), { selected: true });
                }
                else if (deselect.indexOf(a.id) > -1) {
                    return __assign(__assign({}, a), { selected: false });
                }
                return a;
            });
            newState.arrows = state.arrows.map(function (a) {
                if (select.indexOf(a.id) > -1) {
                    return __assign(__assign({}, a), { selected: true });
                }
                else if (deselect.indexOf(a.id) > -1) {
                    return __assign(__assign({}, a), { selected: false });
                }
                return a;
            });
            return newState;
        };
    };
    return DeselectAndSelect;
}());
export { DeselectAndSelect };
// Changes the selection state of apparatus to selected.
// Currently assumes apparatus ID.
var Select = /** @class */ (function (_super) {
    __extends(Select, _super);
    function Select(ids) {
        return _super.call(this, { select: ids }) || this;
    }
    return Select;
}(DeselectAndSelect));
export { Select };
var Deselect = /** @class */ (function (_super) {
    __extends(Deselect, _super);
    function Deselect(ids) {
        return _super.call(this, { deselect: ids }) || this;
    }
    return Deselect;
}(DeselectAndSelect));
export { Deselect };
var Flip = /** @class */ (function () {
    function Flip(apparatus, opts) {
        this.apparatus = apparatus;
        this.oldRotation = apparatus.rotation;
        if (opts) {
            this.newRotation = opts.vertical ? 180 - this.oldRotation : -this.oldRotation;
        }
        else {
            this.newRotation = this.oldRotation;
        }
    }
    Flip.prototype.execute = function () {
        var _this = this;
        appStore.dispatchFn("Flip", function (store) { return (__assign(__assign({}, store), { items: store.items.map(function (i) { return i.id == _this.apparatus.id ? __assign(__assign({}, i), { flipped: !_this.apparatus.flipped, rotation: _this.newRotation }) : i; }) })); });
    };
    Flip.prototype.undo = function () {
        var _this = this;
        appStore.dispatchFn("Undo-Flip", function (store) { return (__assign(__assign({}, store), { items: store.items.map(function (i) { return i.id == _this.apparatus.id ? __assign(__assign({}, i), { flipped: _this.apparatus.flipped, rotation: _this.oldRotation }) : i; }) })); });
    };
    return Flip;
}());
export { Flip };
var AddApparatus = /** @class */ (function () {
    function AddApparatus(apparatuses) {
        this.apparatuses = apparatuses;
        this.groupInformation = new GroupsOperationData();
        for (var _i = 0, apparatuses_1 = apparatuses; _i < apparatuses_1.length; _i++) {
            var apparatus = apparatuses_1[_i];
            if (apparatus.parentGroup) {
                this.groupInformation.add(apparatus.parentGroup, apparatus.id);
            }
        }
    }
    AddApparatus.prototype.execute = function () {
        if (this.groupInformation.hasData()) {
            appStore.dispatch(addToGroup(this.groupInformation));
        }
        appStore.dispatch(addItems(this.apparatuses));
    };
    AddApparatus.prototype.undo = function () {
        appStore.dispatch(removeItems(this.apparatuses.map(function (a) { return a.id; })));
        if (this.groupInformation.hasData()) {
            appStore.dispatch(removeFromGroup(this.groupInformation));
        }
    };
    return AddApparatus;
}());
export { AddApparatus };
var DeleteApparatus = /** @class */ (function () {
    function DeleteApparatus(apparatuses) {
        this.apparatuses = apparatuses;
        this.groupInformation = new GroupsOperationData();
        this.itemsWithIndex = [];
        for (var _i = 0, apparatuses_2 = apparatuses; _i < apparatuses_2.length; _i++) {
            var apparatus = apparatuses_2[_i];
            if (apparatus.parentGroup) {
                this.groupInformation.remove(apparatus.parentGroup, apparatus.id);
            }
            this.itemsWithIndex.push({ item: apparatus, index: appStore.items().indexOf(apparatus) });
        }
    }
    DeleteApparatus.prototype.execute = function () {
        appStore.dispatch(removeItems(this.apparatuses.map(function (a) { return a.id; })));
        if (this.groupInformation.hasData()) {
            appStore.dispatch(removeFromGroup(this.groupInformation));
        }
    };
    DeleteApparatus.prototype.undo = function () {
        // Add the group first. If the item is added first, it will
        // contain group information referring to a group that does
        // not yet exist.
        if (this.groupInformation.hasData()) {
            appStore.dispatch(addToGroup(this.groupInformation));
        }
        appStore.dispatch(addItemsAtIndices(this.itemsWithIndex));
    };
    return DeleteApparatus;
}());
export { DeleteApparatus };
var UpdateAppearance = /** @class */ (function () {
    function UpdateAppearance(id, key, value, previousValue) {
        this.id = id;
        this.key = key;
        this.value = value;
        this.previousValue = previousValue;
    }
    UpdateAppearance.prototype.execute = function () {
        appStore.dispatch(updateAppearance(this.id, this.key, this.value));
    };
    UpdateAppearance.prototype.undo = function () {
        appStore.dispatch(updateAppearance(this.id, this.key, this.previousValue));
    };
    return UpdateAppearance;
}());
export { UpdateAppearance };
var UpdateItemsAppearance = /** @class */ (function () {
    function UpdateItemsAppearance(key, values) {
        this.key = key;
        this.values = values;
        this.previousValues = values.map(function (v) { return ({ id: v.id, value: v.previousValue }); });
    }
    UpdateItemsAppearance.prototype.execute = function () {
        appStore.dispatch(updateItemsAppearance(this.key, this.values));
    };
    UpdateItemsAppearance.prototype.undo = function () {
        appStore.dispatch(updateItemsAppearance(this.key, this.previousValues));
    };
    return UpdateItemsAppearance;
}());
export { UpdateItemsAppearance };
/**
 * Update multiple appearance properties on one or multiple items of the same type.
 * Note that this command only makes shallow copies!
 */
var UpdateAppearanceProperties = /** @class */ (function () {
    function UpdateAppearanceProperties(id_or_ids, newAppearance, previousAppearance) {
        this.id_or_ids = id_or_ids;
        this.newAppearance = __assign({}, newAppearance);
        this.prevAppearance = __assign({}, previousAppearance);
    }
    UpdateAppearanceProperties.prototype.execute = function () {
        if (Array.isArray(this.id_or_ids)) {
            appStore.dispatch(updateItemsAppearanceProperties(this.id_or_ids, this.newAppearance));
        }
        else {
            appStore.dispatch(updateAppearanceProperties(this.id_or_ids, this.newAppearance));
        }
    };
    UpdateAppearanceProperties.prototype.undo = function () {
        if (Array.isArray(this.id_or_ids)) {
            appStore.dispatch(updateItemsAppearanceProperties(this.id_or_ids, this.newAppearance));
        }
        else {
            appStore.dispatch(updateAppearanceProperties(this.id_or_ids, this.prevAppearance));
        }
    };
    return UpdateAppearanceProperties;
}());
export { UpdateAppearanceProperties };
/**
 * Base class for commands that affect multiple apparatus items the same way.
 * Works for apparatus items only!
 */
var MultiApparatusEditCommand = /** @class */ (function () {
    function MultiApparatusEditCommand(values) {
        this.valuesMap = {};
        this.previousValuesMap = {};
        for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
            var value = values_1[_i];
            this.valuesMap[value.id.id] = value.value;
            this.previousValuesMap[value.id.id] = value.previousValue;
        }
    }
    /** Do not override. */
    MultiApparatusEditCommand.prototype.execute = function () {
        appStore.dispatchFn(this.name, this.mutator(this.valuesMap));
    };
    /** Do not override. */
    MultiApparatusEditCommand.prototype.undo = function () {
        appStore.dispatchFn("UpdateLiquid", this.mutator(this.previousValuesMap));
    };
    MultiApparatusEditCommand.prototype.mutator = function (mutations) {
        var _this = this;
        return (function (store) {
            var newStore = __assign({}, store);
            newStore.items = store.items.map(function (i) { return mutations[i.id.id] !== undefined
                ? _this.mutateItem(i, mutations[i.id.id])
                : i; });
            return newStore;
        });
    };
    /** Class factory. */
    MultiApparatusEditCommand.createClass = function (name, mutateItem) {
        return /** @class */ (function (_super) {
            __extends(_C, _super);
            function _C() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.name = "UpdateLiquidAmount";
                _this.mutateItem = mutateItem;
                return _this;
            }
            return _C;
        }(MultiApparatusEditCommand));
    };
    return MultiApparatusEditCommand;
}());
export { MultiApparatusEditCommand };
export var UpdateLiquidAmount = MultiApparatusEditCommand.createClass("UpdateLiquidAmount", function (i, value) {
    return i.liquid ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { amountRatio: value }) }) : i;
});
export var UpdateLiquidColor = MultiApparatusEditCommand.createClass("UpdateLiquidColor", function (i, value) {
    return i.liquid ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { color: value }) }) : i;
});
export var UpdateLiquidAlpha = MultiApparatusEditCommand.createClass("UpdateLiquidAlpha", function (i, value) {
    return i.liquid ? __assign(__assign({}, i), { liquid: __assign(__assign({}, i.liquid), { alpha: value }) }) : i;
});
