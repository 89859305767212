var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Menu } from "@szhsin/react-menu";
import { g } from "analytics";
import { releasedItemsCount } from "apparatus/spec_list";
import { Cookie } from "browser/cookie";
import { CommonModal } from "components/common_modal";
import { ExponentLogo } from "components/logo";
import "ext/js";
import { LanguageOptions } from "layout/top_bar/lang";
import * as React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { runTutorial } from "tutorial";
var MAX_SHOW_WELCOME_SCREEN = 3;
/** Welcome modal dialog. */
export var WelcomeModal2 = function (modalState) {
    var t = useTranslation().t;
    return React.createElement(CommonModal, __assign({ extraClass: "welcome-modal", title: null, center: true }, modalState),
        React.createElement(SplashImage, null),
        React.createElement("div", { style: { marginTop: '-90px' } }),
        React.createElement("div", { style: { marginBottom: '16px', textAlign: "left" }, className: "ml-4" },
            React.createElement(MiniLanguagePicker, null)),
        React.createElement("div", { className: "ml-4 mb-4 text-left" },
            React.createElement(ExponentLogo, null)),
        React.createElement("h1", null,
            t('welcome.title.part1'),
            " ",
            React.createElement("span", { className: "boost-text-gradient font-weight-bold" }, t('Chemistry')),
            ", ",
            React.createElement("span", { className: "school-text-gradient font-weight-bold" }, t('Biology')),
            " & ",
            React.createElement("span", { className: "blue-text-gradient font-weight-bold" }, t('Physics')),
            " ",
            t('welcome.title.part2')),
        React.createElement("div", { className: "mx-3 chemix-green" },
            React.createElement("div", { className: "d-inline-block mr-3" },
                React.createElement("i", { className: "fas fa-vial mr-1" }),
                t('welcome.items', { count: releasedItemsCount })),
            React.createElement("div", { className: "d-inline-block mr-3" },
                React.createElement("i", { className: "fas fa-image mr-1" }),
                t('welcome.downloads')),
            React.createElement("div", { className: "d-inline-block mr-3" },
                React.createElement("i", { className: "fas fa-cloud-upload mr-1" }),
                t('welcome.cloud'))),
        React.createElement("div", { className: "button-group my-4" },
            React.createElement("div", { className: "ui-button highlight mb-2 hide-if-too-narrow", onClick: function () { writeCookieToNotShowAgain(); g("Welcome:Tutorial"); runTutorial(); modalState.setIsOpen(false); }, "data-remodal-action": "confirm" },
                React.createElement("i", { className: "fas fa-wand-magic-sparkles mr-2" }),
                React.createElement("b", null, t("welcome.tour"))),
            React.createElement("div", { className: "ui-button", onClick: function () { writeCookieToNotShowAgain(); g("Welcome:Skip"); modalState.setIsOpen(false); } }, t("welcome.skip"))),
        React.createElement("div", { className: "endorse" },
            React.createElement("p", null,
                React.createElement(PromoStat, null)),
            React.createElement(Endorsements, null)));
};
export var MiniLanguagePicker = function () {
    var t = useTranslation().t;
    return React.createElement(Menu, { menuButton: React.createElement("div", { className: "btn btn-sm btn-outline-secondary", style: { opacity: 0.7 } },
            React.createElement("span", { style: { fontSize: "81%" } },
                React.createElement("i", { className: "far fa-globe" }),
                " ",
                t("currentLanguageShort").toLocaleUpperCase())), transition: true },
        React.createElement(LanguageOptions, null));
};
export function shouldShowWelcome() {
    var _a;
    var welcomeScreenShown = +((_a = Cookie.read("WELCOME_SCREEN_SHOWN")) !== null && _a !== void 0 ? _a : "0");
    if (welcomeScreenShown < MAX_SHOW_WELCOME_SCREEN || (location.hostname == "localhost" && location.hash == "#public")) {
        Cookie.write("WELCOME_SCREEN_SHOWN", "" + (welcomeScreenShown + 1));
        return true;
    }
    return false;
}
function writeCookieToNotShowAgain() {
    Cookie.write("WELCOME_SCREEN_SHOWN", "" + MAX_SHOW_WELCOME_SCREEN);
}
export var SplashImage = function () { return React.createElement("div", { className: "splash-image" }); };
/**
 * ExportToImage:
 * https://analytics.google.com/analytics/web/#/report/content-event-events/a7716816w108521673p113089288/_u.date00=20210101&_u.date01=20211231&explorer-table.plotKeys=%5B%5D&_r.drilldown=analytics.eventAction:ExportToImage
 *
 * Users:
 * https://analytics.google.com/analytics/web/#/report/visitors-overview/a7716816w108521673p113089288/_u.date00=20210101&_u.date01=20211231&overview-graphOptions.primaryConcept=analytics.totalVisitors&_.useg=userdeRWhpY9ThSmpxM9QKpKlA/
 *
 * 31536000 seconds in a year
 *
 * In 2022, we helped&nbsp;
    <span className="boost-text-gradient font-weight-bold">{ (159722).toLocaleFixed(0) }&nbsp;people</span> create&nbsp;
    <span className="boost-text-gradient font-weight-bold">{ (349418).toLocaleFixed(0) }&nbsp;diagrams</span>.<br/>
    That's a&nbsp;diagram every&nbsp;90&nbsp;seconds</>
 */
export var PromoStat = function () {
    // const { t } = useTranslation();
    // const year = 2022
    // const people = (159722).toLocaleFixed(0)
    // const diagrams = (349418).toLocaleFixed(0)
    // const seconds = 90
    // return <>
    //     <Trans i18nKey="welcome.stats1" t={t} values={{ year, people, diagrams }}>
    //         In 2022, we helped&nbsp;
    //         <span className="boost-text-gradient font-weight-bold">{ (159722).toLocaleFixed(0) }&nbsp;people</span> create&nbsp;
    //         <span className="boost-text-gradient font-weight-bold">{ (349418).toLocaleFixed(0) }&nbsp;diagrams</span>.
    //     </Trans><br/>
    //     {t("welcome.stats2", { seconds })}.
    // </>
    var t = useTranslation().t;
    var people = (250000).toLocaleFixed(0);
    var institutions = (50).toLocaleFixed(0);
    return React.createElement(React.Fragment, null,
        React.createElement(Trans, { i18nKey: "welcome.stats3", t: t, values: { people: people, institutions: institutions } },
            "Trusted by ",
            React.createElement("span", { className: "boost-text-gradient font-weight-bold" },
                people,
                "+\u00A0people"),
            " and ",
            React.createElement("span", { className: "boost-text-gradient font-weight-bold" },
                institutions,
                "+\u00A0institutions"),
            " world-wide."));
};
export var EndorseLogo = function (_a) {
    var filename = _a.filename, _b = _a.size, size = _b === void 0 ? 1.0 : _b;
    return React.createElement("img", { height: 40 * size, src: "./images/endorse-assets/" + filename + ".png", srcSet: "./images/endorse-assets/" + filename + ".png 1x, ./images/endorse-assets/" + filename + "@2x.png 2x" });
};
export var Endorsements = function () { return React.createElement("div", { className: "logo-container" },
    React.createElement(EndorseLogo, { filename: "eton", size: 0.8 }),
    React.createElement(EndorseLogo, { filename: "mu" }),
    React.createElement(EndorseLogo, { filename: "tud" }),
    React.createElement(EndorseLogo, { filename: "upr" }),
    React.createElement(EndorseLogo, { filename: "mri" }),
    React.createElement(EndorseLogo, { filename: "nsw" })); };
