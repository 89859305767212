import * as React from "react";
import { useTranslation } from "react-i18next";
import { Articles } from "support/beacon";
import { TierContext } from "tiers/context";
import { Nl } from "view/tooltips";
/** Clickable Chemix Logo. */
export var Logo = function (props) { return (React.createElement("a", { key: "logo_wrapper", id: "logo" },
    React.createElement("img", { key: "logo", src: "./images/192.png", height: 48, width: 48, onClick: function () { return props.onClick(); }, title: "About Chemix" }))); };
/** Social links. */
export var SocialWidgets = function () {
    var t = useTranslation().t;
    return React.createElement("span", { id: "socialWidgets" },
        React.createElement("a", { href: "https://twitter.com/ChemixLab?ref_src=twsrc%5Etfw", target: "_blank", className: "small custom-twitter-follow-button" },
            React.createElement("i", { className: "fab fa-twitter mr-1" }),
            t("ui.follow", { account: "@ChemixLab" })));
};
/**
 * Screen-too-small warning.
 */
export var ScreenTooSmallWarning = function () {
    var popup = React.useContext(TierContext).popup;
    var t = useTranslation().t;
    return React.createElement("div", { id: "screen-too-small" },
        React.createElement("span", { className: "fa-stack fa-2x d-block mx-auto mb-2" },
            React.createElement("i", { className: "fal fa-desktop fa-stack-2x" }),
            React.createElement("i", { className: "fal fa-arrows-alt-h fa-stack-1x fa-lg mt-n2" })),
        React.createElement("br", null),
        React.createElement("h3", null,
            React.createElement(Nl, null, t("small_screen.title", { count: 650 }))),
        React.createElement("div", { className: "my-4 mx-auto", style: { width: "100%", maxWidth: "400px" } },
            React.createElement("ul", { className: "fa-ul text-left" },
                React.createElement("li", null,
                    React.createElement("span", { className: "fa-li" },
                        React.createElement("i", { className: "fas fa-desktop" })),
                    t('small_screen.use_larger')),
                React.createElement("li", null,
                    React.createElement("span", { className: "fa-li" },
                        React.createElement("i", { className: "fas fa-mobile-android-alt fa-rotate-90" })),
                    t('small_screen.rotate')),
                React.createElement("li", null,
                    React.createElement("span", { className: "fa-li" },
                        React.createElement("i", { className: "fas fa-info-circle" })),
                    React.createElement("a", { href: "#", "data-beacon-article": Articles.ABOUT }, t('small_screen.learn_more'))),
                React.createElement("li", null,
                    React.createElement("span", { className: "fa-li" },
                        React.createElement("i", { className: "fas fa-dollar-sign" })),
                    React.createElement("a", { href: "#pricing", onClick: function () { return popup("BlockingScreen"); } }, t('small_screen.pricing'))),
                React.createElement("li", null,
                    React.createElement("span", { className: "fa-li" },
                        React.createElement("i", { className: "fas fa-question-circle" })),
                    React.createElement("a", { href: "#", "data-beacon-article": Articles.SCREEN_TOO_SMALL }, t('small_screen.screen_too_small'))))));
};
