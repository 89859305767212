var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Gradients } from "apparatus/gradients";
import { P, Pivot, Polar, R, Segments, SharedGradients } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
/**
 * https://vevor.co.uk/products/variable-transformer-1000va-power-supply-voltage-built-in-copper-coil-one-plug?gclid=CjwKCAjwyvaJBhBpEiwA8d38vDHCngJXsLVZTA0bUEmZs23dUFqUU5FyDdxqan9udEy9Dldchuv9URoChbgQAvD_BwE
 */
var Autotransformer = /** @class */ (function () {
    function Autotransformer() {
    }
    Autotransformer.prototype.render = function (appearance) {
        if (appearance.view == "top") {
            return renderTop(appearance);
        }
        else {
            return renderSide(appearance);
        }
    };
    Autotransformer.properties = {
        label: "Variable Autotransformer",
        defaultAppearance: {
            view: "side"
        },
        appearanceSpecs: [
            {
                spec: "toggle-buttons", size: "text",
                key: "view", label: "View", options: [
                    // { value: "top", label: "Top" }, // Unfinished.
                    { value: "side", label: "Side" },
                    { value: "front", label: "Front" },
                ]
            }
        ],
    };
    Autotransformer = __decorate([
        staticImplements()
    ], Autotransformer);
    return Autotransformer;
}());
export { Autotransformer };
/** Unfinished. */
function renderTop(a) {
    var color = "#E22021".color();
    var panel = new Path.Rectangle(R(0, 0, 70, 140))
        .withFill(color.string());
    var body = new Path.Circle(P(160, 70), 120)
        .withFill(color.string());
    var shade1 = body.clone().setRadialGradientFill([["#ffffff00", 0.94], ["#ffffff60", 1.0]], { center: P(165, 75), edge: P(160, 70).add(Polar(120, -135)) });
    var shade2 = body.clone().setRadialGradientFill([["#00000000", 0.92], ["#00000040", 1.0]], { center: P(155, 65), edge: P(160, 70).add(Polar(120, 45)) });
    var graphic = new Group([
        panel, body, shade1, shade2
    ]);
    return {
        graphic: graphic,
        hitShape: graphic.rectHitShape(),
        snapping: graphic.placeable(),
    };
}
function renderSide(a) {
    var color = "#E22021".color();
    var body = new Path.Rectangle(R(70, 0, 200, 180), 1.0)
        .withGradientFill("right", Gradients.cylinder(color));
    var graphic = new Group([body]);
    if (a.view == "side") {
        var shade = new Path.Rectangle(R(70, 0, 20, 180))
            .withGradientFill("right", Gradients.shade());
        graphic.addChild(shade);
    }
    // Add grills.
    for (var x = 45; x < 260; x += 45) {
        if (a.view == "front" && x == 45) {
            // If front, skip first set of grills.
            x -= 3;
            continue;
        }
        for (var y = 30; y <= 150; y += 20) {
            if (y == 90)
                continue;
            var line = new Path.Line(P(x, y), P(x + 35, y)).withStroke(5.0, "#333333");
            line.strokeCap = "round";
            graphic.addChild(line);
        }
    }
    if (a.view == "side") {
        var panel = new Path.Rectangle(R(0, 0, 71, 180))
            .withGradientFill("right", Gradients.box(color, { delta: 10 }));
        graphic.addChild(panel);
    }
    var leg1 = new Path.Rectangle(R(170 - 70, 180, 40, 10, Pivot.TOP_CENTER))
        .withGradientFill("right", Gradients.cylinder("#333333".color(), 5));
    var leg2 = leg1.clone();
    leg2.position.x += 140;
    var dial = new Path(Segments([[50, 0]], [[50, -8]], [[40, -8]], [[35, -30]])).close().mirrorX().withGradientFill("right", Gradients.cylinder("#333333".color(), 7));
    dial.position.x += 170;
    dial.position.y -= 10;
    var dialRod = new Path.Rectangle(R(170, 0, 15, 10, Pivot.BOTTOM_CENTER))
        .withGradientFill("right", SharedGradients.cylinderMetal);
    graphic.addChildren([leg1, leg2, dialRod, dial]);
    return {
        graphic: graphic,
        watermark: P(70, 0),
        hitShape: graphic.rectHitShape()
    };
}
