var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { addEdgeLips, breakPath, setDefaultStyle } from "apparatus/draw";
import { P, Segments } from "apparatus/library/common";
import { createTap, Tap } from "apparatus/library/common/tap";
import { Path } from "paper";
var SchlenkFlask = /** @class */ (function () {
    function SchlenkFlask() {
    }
    SchlenkFlask.prototype.render = function (appearance) {
        var shape = shapePath(appearance);
        addEdgeLips(shape);
        var hitShape = shape.bounds.toShape();
        var liquidMask = shape.clone();
        var graphic;
        switch (appearance.shape) {
            case "cylinder":
                graphic = breakPath(shape, 10);
                break;
            case "sphere":
                graphic = breakPath(shape, 9);
                break;
        }
        setDefaultStyle(graphic);
        var tap = createTap(appearance);
        graphic.addChild(tap);
        tap.scale(0.9);
        if (appearance.shape == "sphere" || appearance.angle == 45) {
            tap.rotate(-55);
            tap.position = P(52, 33);
        }
        else {
            tap.position = P(67, 69);
        }
        return {
            graphic: graphic,
            hitShape: hitShape,
            liquidMask: liquidMask,
        };
    };
    SchlenkFlask.properties = {
        label: "Schlenk Flask",
        canContainLiquids: true,
        flippable: true,
        defaultAppearance: __assign({ shape: "sphere", angle: 45 }, Tap.defaultAppearance),
        appearanceSpecs: [
            {
                spec: "radio", key: "shape", label: "Shape",
                options: [
                    { value: "sphere", label: "Spherical" },
                    { value: "cylinder", label: "Cylindrical", tier: "boost" },
                ],
                allowSelectingTierLockedOptions: true,
            },
            {
                spec: "radio", key: "angle", label: "Angle",
                options: [
                    { value: 45, label: "45°" },
                    { value: 90, label: "90°" },
                ],
                visible: function (a) { return a.shape == "cylinder"; }
            },
            Tap.appearanceSpec,
        ],
        isBoostRequired: function (a) { return a.shape === "cylinder"; }
    };
    return SchlenkFlask;
}());
export { SchlenkFlask };
function shapePath(a) {
    switch (a.shape) {
        case "sphere":
            return new Path(Segments([[-13, 0]], [[-13, 40], , [-3, 0]], [[-15, 60], , [-18, 5]], [[-44, 104], [0, -18], [0, 18]], [[0, 146], [-28, 0], [28, 0]], [[47, 100], [0, 22], [0, -14]], [[45, 70], [-2, 6], [5, -15]], [[90, -6]], [[85, -10]], [[30, 63], [9, 0], [-9, 0]], [[15, 55], [0, 5]], [[13, 40], [3, 0]], [[13, 0]]));
        case "cylinder":
            switch (a.angle) {
                case 45:
                    return new Path(Segments([[-13, 0]], [[-13, 40], , [-3, 0]], [[-15, 60], , [-18, 10]], [[-30, 104]], // Bottom of flask
                    [[-30, 260], , [0, 35]], [[30, 260], [0, 35]], [[30, 100], [0, 22], [0, -14]], [[45, 70], [-2, 6], [5, -15]], [[90, -6]], [[85, -10]], [[30, 63], [9, 0], [-9, 0]], [[15, 55], [0, 5]], [[13, 40], [3, 0]], [[13, 0]]));
                case 90:
                    return new Path(Segments([[-13, 0]], [[-13, 40], , [-3, 0]], [[-15, 60], , [-18, 10]], [[-30, 104]], [[-30, 260], , [0, 35]], [[30, 260], [0, 35]], [[30, 100], [0, 22], [0, -14]], [[37, 81], [-4, 0], [11, -1]], [[111, 80]], [[111, 74]], [[31, 71], [4, 3], [-3, -10]], [[15, 58], [0, 5]], [[13, 40], [3, 0]], [[13, 0]]));
            }
    }
}
