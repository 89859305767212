var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { mirrorX, setDefaultStyle } from "apparatus/draw";
import { DEFAULT_LIQUID_DATA, P, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var Crucible = /** @class */ (function () {
    function Crucible() {
    }
    Crucible.prototype.render = function (appearance) {
        var width = appearance.diameter;
        var height = appearance.height;
        var graphic = new Path(Segments([[0, 0]], [[width / 5, height], [-width / 5, 0]], [[width / 2, height]]));
        mirrorX(graphic, width / 2);
        var liquidMask = graphic.clone();
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            liquidMask: liquidMask,
            snapping: graphic.placeable()
        };
    };
    Crucible.properties = {
        label: "Crucible",
        canContainLiquids: true,
        defaultAppearance: {
            diameter: 39, height: 43
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 25, max: 70,
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 35, max: 70,
            }
        ],
        defaultLiquidData: __assign(__assign({}, DEFAULT_LIQUID_DATA), { amountRatio: 0.3, color: "#aaaaaa" })
    };
    return Crucible;
}());
export { Crucible };
var CrucileCover = /** @class */ (function () {
    function CrucileCover() {
    }
    CrucileCover.prototype.render = function (appearance) {
        var width = appearance.diameter;
        var cover = new Path(Segments([[-5, 5], undefined, [0, -3]], [[0, 0]], [[width / 2, -5], [-width / 4, 0]]));
        mirrorX(cover, width / 2);
        var handle = Shape.Circle(P(width / 2, -10), 5);
        var graphic = new Group([cover, handle]);
        setDefaultStyle(graphic);
        return { graphic: graphic, hitShape: graphic.bounds.toShape() };
    };
    CrucileCover.properties = {
        label: "Crucible Cover",
        defaultAppearance: {
            diameter: Crucible.properties.defaultAppearance.diameter
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 25, max: 70,
            },
        ],
    };
    return CrucileCover;
}());
export { CrucileCover };
