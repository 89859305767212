var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { mirrorX } from "apparatus/draw";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Ray } from "apparatus/library/physics/optical/common";
import { CompoundPath, Group, Path, Shape } from "paper";
var SLIT_PLATE_COLOR = "#555555";
var RayBox = /** @class */ (function () {
    function RayBox() {
    }
    RayBox.prototype.render = function (appearance) {
        var slits = appearance.slits;
        var slitGap = 10;
        var box = new Path(Segments([[30, 0]], [[30, -80]], [[35, -80]], [[35, 60]])).withStroke("default").withFill("#444444").close();
        mirrorX(box);
        var graphic = new Group([box]);
        if (slits !== "none") {
            var slitPlate = new CompoundPath([]).withStroke("default", SLIT_PLATE_COLOR);
            slitPlate.moveTo(P(-30, -65));
            for (var i = 0; i < slits; i++) {
                var dx = ((slits - 1) / -2 + i) * slitGap;
                slitPlate.lineTo(P(dx - 1, -65));
                slitPlate.moveBy(P(2, 0));
            }
            slitPlate.lineTo(P(30, -65));
            graphic.insertChild(0, slitPlate);
        }
        var rayLength = 200;
        if (appearance.showRay) {
            // First draw the glow.
            var hexColor = appearance.rayColor.color().darken(0.1).hex();
            if (slits === "none") {
                graphic.insertChild(0, Shape.Rectangle(R(0, 0, 60, 200, Pivot.BOTTOM_CENTER)).withGradientFill("up", [[hexColor + "aa", 0.5], [hexColor + "00", 1.0]]));
            }
            else {
                graphic.insertChild(0, Shape.Rectangle(R(0, 0, 60, 65, Pivot.BOTTOM_CENTER)).setRadialGradientFill([[hexColor + "aa", 0.5], [hexColor + "40", 1.0]], { center: P(0, 0), edge: P(30, -65) }));
                // Draw rays
                for (var i = 0; i < slits; i++) {
                    var dx = ((slits - 1) / -2 + i) * slitGap;
                    graphic.insertChild(0, new Path.Line(P(dx, -64), P(dx, -(64 + rayLength))).withStroke(appearance.rayThickness, appearance.rayColor));
                }
            }
        }
        return {
            graphic: graphic,
            pivot: box.bounds.center,
            hitShape: box.bounds.toShape()
        };
    };
    RayBox.properties = {
        label: "Ray Box",
        defaultAppearance: __assign({ slits: "none" }, Ray.defaultAppearanceRayOff),
        appearanceSpecs: __spreadArray([
            {
                spec: "toggle-buttons",
                size: "text",
                label: "Slits",
                key: "slits",
                options: [
                    { value: "none", label: "None" },
                    { value: 1, label: "1" },
                    { value: 3, label: "3" },
                ]
            }
        ], Ray.specs),
    };
    return RayBox;
}());
export { RayBox };
