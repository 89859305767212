import { strokeWidth } from "apparatus/draw";
import { P, Segments } from "apparatus/library/common";
import { CompoundPath, Group, Path, Shape } from "paper";
var GREEN = "#31c458";
var YELLOW = "#e6de49";
var Scissors = /** @class */ (function () {
    function Scissors() {
    }
    Scissors.prototype.render = function (appearance) {
        var blade = new Path(Segments([[-74, 10]], [[-65, 17], [-10, 1], [20, -3]], [[5, 7]], [[19, -5]], [[15, -13]]));
        blade.closePath();
        var handleOuter = new Path(Segments([[15, -15]], [[36, -22], [-13, 6], [23, -11]], [[83, -55], [-16, 4], [15, -3]], [[113, -38], [-4, -12], [3, 10]], [[101, -16], [9, -2], [-38, 7]], [[20, -3]]));
        handleOuter.closePath();
        var handleInner = new Path(Segments([[88, -46], [-14, 1], [15, -1]], [[100, -25], [13, -4], [-13, 4]], [[66, -25], [6, 8], [-4, -6]]));
        handleInner.closePath();
        var handle = new CompoundPath({ children: [handleOuter, handleInner] });
        handle.fillRule = "evenodd";
        var handleStroke = appearance.handleColor.color().desaturate(0.15).darken(0.2).hex();
        blade.withStroke(strokeWidth("thin"), "#777777").withFill("#dddddd");
        handle.withStroke(strokeWidth("thin"), handleStroke).withFill(appearance.handleColor);
        var part1 = new Group([blade, handle]);
        part1.rotate(8 - appearance.opening, P(0, 0));
        var part2 = part1.clone();
        part2.children[0].fillColor = "#bbbbbb";
        part2.scale(1.0, -1.0, P(0, 0));
        if (appearance.handleColor === GREEN) {
            part2.children[1].fillColor = YELLOW;
            part2.children[1].strokeColor = YELLOW.color().desaturate(0.15).darken(0.2).hex();
        }
        var graphic = new Group([part2, part1, Shape.Circle(P(0, 0), 2.0).withFill("#777777")]);
        if (appearance.leftie) {
            graphic.scale(1.0, -1.0);
        }
        graphic.scale(appearance.scale, P(0, 0));
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: P(0, 0),
        };
    };
    Scissors.properties = {
        label: "Scissors",
        defaultAppearance: {
            opening: 20,
            handleColor: "#c44231",
            leftie: false,
            scale: 1.0,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "opening", label: "Opening",
                min: 0, max: 40, unit: "°"
            },
            {
                spec: "radio", key: "handleColor", label: "Handle colour",
                options: [
                    { value: "#c44231", label: "Red" },
                    { value: "#5395db", label: "Blue" },
                    { value: "#444444", label: "Black" },
                    { value: GREEN, label: "Green/Yellow" },
                ],
            },
            {
                spec: "checkbox", key: "leftie", label: "Left-handed"
            },
            {
                spec: "slider", key: "scale", label: "Size",
                min: 0.6, max: 1.2, step: 0.01,
            }
        ],
    };
    return Scissors;
}());
export { Scissors };
