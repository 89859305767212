import { P, Pivot, R, SharedColors } from "apparatus/library/common";
import { Group, Path, PointText } from "paper";
var LABEL_COLOR = "#333333";
/**
 * https://proscientific.com/well-plates-tct/96-well-tissue-culture-plate-0.33cm%C2%B2-area-100-qty/
 */
var Microplate = /** @class */ (function () {
    function Microplate() {
    }
    Microplate.prototype.render = function (appearance) {
        var _a;
        var graphic = new Group();
        var snapping = { type: "spotting_tile", at: [] };
        var specs = specsFor(appearance["layout"]);
        graphic.addChild(new Path.Rectangle(specs.frame, 4.0)
            .withStroke("default", SharedColors.stroke)
            .withFill("white"));
        for (var j = 0; j < specs.rows; j++) {
            var y = j * specs.spacing;
            graphic.addChild(new PointText({
                point: P(specs.rowLabelXOffset, y + 2),
                content: String.fromCharCode(65 + j),
                fillColor: LABEL_COLOR,
                fontFamily: 'sans-serif',
                fontSize: 5,
                justification: "center",
            }));
            for (var i = 0; i < specs.columns; i++) {
                var x = i * specs.spacing;
                if (j === 0) {
                    graphic.addChild(new PointText({
                        point: P(x + ((_a = specs.columnLabelXOffset) !== null && _a !== void 0 ? _a : 0), specs.columnLabelYOffset),
                        content: (i + 1),
                        fillColor: LABEL_COLOR,
                        fontFamily: 'sans-serif',
                        fontSize: 5,
                        justification: "center",
                    }));
                }
                var circle = new Path.Circle(P(x, y), specs.radius).withStroke("thinner", "#666666");
                graphic.addChild(circle);
                if (appearance.zoomed) {
                    snapping.at.push(P(x * 4, y * 4));
                }
            }
        }
        if (appearance.zoomed) {
            graphic.scale(4.0, P(0, 0));
        }
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: snapping,
        };
    };
    Microplate.properties = {
        label: "Microplate",
        defaultAppearance: {
            zoomed: false,
            layout: "8x12",
        },
        appearanceSpecs: [
            { spec: "toggle-buttons", size: "text", key: "layout", label: "Layout", buttonsPerRow: 3, options: [
                    { value: "3x4", label: "3x4" },
                    { value: "4x6", label: "4x6" },
                    { value: "6x8", label: "6x8" },
                    { value: "8x12", label: "8x12" },
                    { value: "3x8", label: "3x8" },
                ] },
            { spec: "checkbox", key: "zoomed", label: "Zoomed" },
        ],
    };
    return Microplate;
}());
export { Microplate };
function specsFor(layout) {
    switch (layout) {
        case "8x12": return {
            rows: 8,
            columns: 12,
            radius: 4,
            spacing: 9,
            frame: R(-15, -15, 123, 87, Pivot.TOP_LEFT),
            rowLabelXOffset: -9,
            columnLabelYOffset: -7,
        };
        case "4x6": return {
            rows: 4,
            columns: 6,
            radius: 8,
            spacing: 18,
            frame: R(-18, -19, 123, 87, Pivot.TOP_LEFT),
            rowLabelXOffset: -12,
            columnLabelYOffset: -11,
        };
        case "3x4": return {
            rows: 3,
            columns: 4,
            radius: 12,
            spacing: 25,
            frame: R(-26, -22, 123, 87, Pivot.TOP_LEFT),
            rowLabelXOffset: -19,
            columnLabelYOffset: -15,
        };
        case "6x8": return {
            rows: 6,
            columns: 8,
            radius: 6,
            spacing: 13,
            frame: R(-19, -14, 123, 87, Pivot.TOP_LEFT),
            rowLabelXOffset: -11,
            columnLabelYOffset: -8,
        };
        case "3x8": return {
            rows: 3,
            columns: 8,
            radius: 8,
            spacing: 18,
            frame: R(-18, -19, 158, 68, Pivot.TOP_LEFT),
            rowLabelXOffset: -12,
            columnLabelYOffset: -11,
        };
    }
}
