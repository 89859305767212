var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { commonSpecsForApparatusKind } from "apparatus";
import { apparatusClass } from "apparatus/library";
import { chemMarkPropsSection, convertAppearanceSpecs } from "panel/appearance_spec_converter";
import { createSingleLayerLiquidPropsSections } from "panel/liquid_panel_ui";
import { sectionsForMultipleItems } from "panel/propertyPanel";
import { t } from "i18next";
export function sectionsForMultipleItemsOfSameType(selected, app, tiers) {
    var sections = [];
    var apparatusKlass = apparatusClass(selected.items[0].type);
    var specs;
    if (selected.sameApparatusType) {
        specs = apparatusKlass.properties.appearanceSpecs;
    }
    else if (selected.sameApparatusKind) {
        specs = commonSpecsForApparatusKind(selected.sameApparatusKind);
    }
    else {
        // This should not happen.
        specs = apparatusKlass.properties.appearanceSpecs;
    }
    sections.push({
        header: "Style",
        icon: "sliders-simple",
        hideHeaderIfFirst: true,
        elements: convertAppearanceSpecs(specs, selected.items[0], selected.items, app, tiers)
    });
    // Does the apparatus support ChemMark?
    if (selected.sameApparatusType && apparatusKlass.properties.chemMarkAppearanceKey) {
        sections.push(chemMarkPropsSection(selected.items, apparatusKlass.properties.chemMarkAppearanceKey));
    }
    // Extract tools section. Extracting only from first tab.
    var multipleItemsPanelProps = sectionsForMultipleItems(selected, app);
    sections.push.apply(sections, multipleItemsPanelProps.tabs[0].sections);
    return {
        title: function () { return t("apparatus." + selected.items[0].type + ".name") + " (" + selected.items.length + ")"; },
        icon: "mouse-pointer",
        tabs: __spreadArray([
            {
                type: "property",
                sections: sections,
            }
        ], (apparatusKlass.properties.canContainLiquids ? [{
                type: "liquid",
                colorDot: selected.getConsistentItemsLiquidColor(),
                sections: createSingleLayerLiquidPropsSections(selected.items, app, selected.isItemsSupportingMeniscus)
            }] : []))
    };
}
