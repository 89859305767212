var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { glassFold, setDefaultStyle } from "apparatus/draw";
import { DEFAULT_LIQUID_DATA, P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { addMarkers, breakPathClosed } from "../../draw";
import { createTap, Tap } from "../common/tap";
/**
 * See https://en.wikipedia.org/wiki/Dropping_funnel
 * TODO: Add markers
 */
var DroppingFunnel = /** @class */ (function () {
    function DroppingFunnel() {
    }
    DroppingFunnel.prototype.render = function (appearance) {
        var height = appearance.size;
        var topInlet = new Path(Segments([[-11, -20]], [[11, -20]], [[9, 10]], [[-9, 10]]));
        topInlet.closePath();
        var body = new Path.Rectangle(R(0, 0, 50, height, Pivot.TOP_CENTER), 25);
        var spout = new Path.Rectangle(R(0, height - 5, 10, 100, Pivot.TOP_CENTER));
        // Create parts for the liquid mask.
        var liquidMask;
        if (Tap.isClosed(appearance.tapOpening)) {
            // Tap is closed. Create a very thin shape for the spout.
            liquidMask = body.unite(new Path.Rectangle(R(0, 125, 0.001, 100, Pivot.TOP_CENTER)));
        }
        else {
            liquidMask = body.unite(spout);
        }
        // Outline shape.
        var shape;
        if (appearance.type == "simple") {
            var combinedShape = body.unite(topInlet);
            shape = breakPathClosed(combinedShape, 4);
        }
        else { // With pressure-equalising arm.
            var arm = pressureEqualisingArm({ height: height });
            var combinedShape = body.unite(topInlet).unite(arm);
            shape = breakPathClosed(combinedShape, 4, 12);
        }
        setDefaultStyle(shape);
        // "spout" is only used to create the liquid mask.
        // Visible spout is drawn separately, using 2 lines.
        var spoutLeftWall = new Path(Segments([[-5, 0]], [[-5, 90]]));
        var spoutRightWall = new Path(Segments([[5, 0]], [[5, 90]]));
        setDefaultStyle(spoutLeftWall, spoutRightWall);
        spoutLeftWall.position.y += height + 5;
        spoutRightWall.position.y += height + 5;
        // Create stop cock.
        var stopCock = createTap(appearance);
        stopCock.position.y += height + 5;
        var graphic = new Group([shape, spoutLeftWall, spoutRightWall, stopCock]);
        addMarkers(graphic, P(20, height - 10), 10, P(0, 13));
        graphic.addChild(glassFold(0, 4));
        return {
            graphic: graphic,
            liquidMask: liquidMask,
            hitShape: graphic.bounds.toShape(),
        };
    };
    DroppingFunnel.properties = {
        label: "Dropping Funnel",
        canContainLiquids: true,
        flippable: true,
        defaultLiquidData: __assign(__assign({}, DEFAULT_LIQUID_DATA), { amountRatio: 0.65 }),
        defaultAppearance: __assign({ size: 130, type: "simple" }, Tap.defaultAppearance),
        appearanceSpecs: [
            {
                spec: "radio", key: "type", label: "Type",
                options: [
                    { value: "simple", label: "Simple" },
                    { value: "pressure-equalising", label: "Pressure-equalising", tier: "boost" },
                ],
                allowSelectingTierLockedOptions: true,
            },
            {
                spec: "slider", key: "size", label: "Height",
                min: 100, max: 200,
            },
            Tap.appearanceSpec,
        ],
        isBoostRequired: function (a) { return a.type == "pressure-equalising"; }
    };
    return DroppingFunnel;
}());
export { DroppingFunnel };
function pressureEqualisingArm(_a) {
    var h = _a.height;
    return new Path(Segments([[0, 5]], [[35, 5], , [7, 0]], [[45, 15]], [[45, h + 10], , [0, 20]], [[15, h + 55]], [[11, h + 90]], [[-11, h + 90]], [[-14, h + 50], , [-5, -18]], [[17, h + 44], [1, -8]], [[37, h + 13], [0, 10]], [[37, 20]], [[30, 12], [7, 0]], [[0, 12]]));
}
