var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedColors, SharedGradients } from "apparatus/library/common";
import { createJack, jackColor } from "apparatus/library/physics/electric/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
/**
 * https://nationalmaglab.org/magnet-academy/history-of-electricity-magnetism/museum/rheostat-1820/
 * https://www.britannica.com/technology/rheostat
 * https://www.schoolphysics.co.uk/age14-16/Electricity%20and%20magnetism/Current%20electricity/text/Rheostat_/index.html
 * https://www.philipharris.co.uk/product/physics/electricity-and-electromagnetism/current-electricity/rheostat-standard-range-5a,-85-ohm/b8a49116
 */
var Rheostat = /** @class */ (function () {
    function Rheostat() {
    }
    Rheostat.prototype.render = function (_a) {
        var slideRatio = _a.slide, terminals = _a.terminals;
        var width = 240;
        // Width of the black part of the coil.
        var coilWidth = width - 30;
        var diameter = 40;
        var radius = diameter * 0.5;
        var slidingRange = width - 66;
        var coilSupport = new Path.Rectangle(R(0, 0, width, diameter, Pivot.CENTER))
            .withGradientFill("down", Gradients.cylinder("#aaaaaa".color()));
        var coil = new Path.Rectangle(R(0, 0, coilWidth, diameter, Pivot.CENTER))
            .withGradientFill("down", Gradients.cylinder("#444444".color()))
            .withStroke("default", SharedColors.stroke);
        var coilGroup = new Group([coilSupport, coil]);
        for (var x = -coilWidth / 2; x < coilWidth / 2; x += 5) {
            var line = new Path.Line(P(x, radius), P(x + 7, -radius)).withStroke(1.0, "#333333");
            coilGroup.addChild(line);
        }
        var legGroupL = new Group([
            // Top part
            new Path.Rectangle(R((-width / 2) - 20, -radius - 2, 10, 18, Pivot.BOTTOM_LEFT))
                .withGradientFill("up", Gradients.cylinder("#0177c6".color())),
            // Leg
            new Path.Rectangle(R((-width / 2) - 20, radius + 2, 15, 25, Pivot.TOP_LEFT))
                .withGradientFill("up", Gradients.cylinder("#0177c6".color())),
            // Middle part
            new Path.Rectangle(R((-width / 2) - 20, 0, 24, diameter + 6, Pivot.CENTER_LEFT))
                .withGradientFill("down", Gradients.cylinder("#0177c6".color())),
        ]);
        var legGroupR = legGroupL.clone();
        legGroupR.scale(-1.0, 1.0, P(0, 0));
        var slidingBar = new Path.Rectangle(R(0, 0, width + 22, 8, Pivot.CENTER))
            .withGradientFill("down", SharedGradients.cylinderMetal);
        var handle = new Path.Rectangle(R(0, 0, 34, 14, Pivot.CENTER), 2.0)
            .withGradientFill("right", Gradients.box("#333333".color()));
        var contact = new Path.Rectangle(R(0, 7, 10, radius + 10, Pivot.TOP_CENTER))
            .withGradientFill("right-down", SharedGradients.cylinderMetal);
        var handleGroup = new Group([contact, handle]);
        handleGroup.withPositionDelta({ x: (slideRatio - 0.5) * slidingRange });
        var slidingGroup = new Group([slidingBar, handleGroup]);
        slidingGroup.withPositionDelta({ y: -radius - 13 });
        // Contacts
        var inputJackColor = terminals == "-+" ? "red" : "black";
        var contactPositionX = (-width / 2) + 18;
        var cylinder = new Path.Rectangle(R(contactPositionX, -radius - 2, 16, diameter + 22, Pivot.TOP_CENTER), 2.0)
            .withGradientFill("down", SharedGradients.cylinderMetal)
            .withStroke("thin", "#777777");
        var jack = createJack(inputJackColor).withPosition(P(contactPositionX, radius + 10));
        var contactGroupL = new Group([cylinder, jack]);
        var contactGroupR = contactGroupL.clone();
        contactGroupR.scale(-1.0, 1.0, P(0, 0));
        // Output terminal
        var outputJackColor = jackColor(terminals == "-+" ? "black" : "red");
        var terminal = new Path(Segments([[0, -4]], [[20, -5]]))
            .mirrorY(0)
            .close()
            .withStroke("thin", outputJackColor.color().darken(0.25).hex())
            .withGradientFill("down", Gradients.simple(outputJackColor.color()))
            .withPosition({ x: -width / 2 - 30, y: -radius - 13 });
        // Assemble
        var graphic = new Group([coilGroup, slidingGroup, terminal, legGroupL, legGroupR, contactGroupL, contactGroupR]);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
            snapping: [
                graphic.placeable(),
                { type: "wireport", at: jack.position },
                { type: "wireport", at: contactGroupR.children[1].position },
            ]
        };
    };
    Rheostat.properties = {
        label: "Rheostat",
        flippable: true,
        defaultAppearance: {
            slide: 0.4,
            terminals: "-+"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "slide", label: "Sliding contact",
                min: 0.0, max: 1.0, step: 0.01
            },
            {
                spec: "toggle-buttons",
                key: "terminals",
                label: "Terminals",
                size: "text",
                options: [
                    { value: "-+", label: "- +" },
                    { value: "+-", label: "+ -" },
                ]
            }
        ],
    };
    Rheostat = __decorate([
        staticImplements()
    ], Rheostat);
    return Rheostat;
}());
export { Rheostat };
