var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments, SharedColors } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var BOTTOM_EDGE_BIT = [
    [[130, 0]],
    [[128, 4], [2, 0]],
    [[115, 4]],
    [[115, 0]],
    [[85, 0]],
    [[85, 4]],
    [[72, 4], , [-2, 0]],
    [[70, 2]],
    [[70, 0]],
    [[8, 0], , [0, -3]],
    [[-8, 0], [0, -3]],
    [[-20, 0]],
];
var DropCounter = /** @class */ (function () {
    function DropCounter() {
    }
    DropCounter.prototype.render = function (appearance) {
        var top = new Path(Segments.apply(void 0, __spreadArray([[[-15, -5]],
            [[130, -5]]], BOTTOM_EDGE_BIT))).withFill("#777777").close();
        var shade = new Path(Segments.apply(void 0, __spreadArray(__spreadArray([[[-15, -5]],
            [[130, -5]]], BOTTOM_EDGE_BIT.map(function (s) { return [[s[0][0], s[0][1] + 10], s[1], s[2]]; })), [[[-20, 0]]]))).close();
        var outline = shade.clone();
        outline.withStroke("default", SharedColors.stroke);
        shade.withGradientFill("right", [
            ["#444444", 0.08],
            ["#333333", 0.081],
            ["#555555", 0.18],
            ["#444444", 0.181],
            ["#444444", 0.59],
            ["#555555", 0.61],
            // first bit sticking out.
            ["#555555", 0.69],
            ["#444444", 0.70],
            // depression bit.
            ["#444444", 0.89],
            ["#555555", 0.9],
            // second bit sticking out.
        ]);
        var screw = Shape.Rectangle(R(-15, 3, 20, 5, Pivot.CENTER_RIGHT), 0.0).withFill("#aaaaaa");
        var screwTop = Shape.Rectangle(R(-32, 3, 8, 16, Pivot.CENTER_RIGHT), 2.0)
            .withStroke("thin", SharedColors.stroke)
            .withGradientFill("down", Gradients.simple("#555555".color()));
        var graphic = new Group([screw, screwTop, shade, top, outline]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: [
                { type: "attachable", at: P(0, 0) }
            ]
        };
    };
    DropCounter.properties = {
        label: "Drop Counter",
        flippable: true,
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return DropCounter;
}());
export { DropCounter };
