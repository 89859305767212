import { strokeWidth } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Segments } from "apparatus/library/common";
import { Color, Gradient, Group, Path } from "paper";
var Scalpel = /** @class */ (function () {
    function Scalpel() {
    }
    Scalpel.prototype.render = function (appearance) {
        var handle = new Path(Segments([[169, -3], [-13, 0], [10, 0]], [[169, 14], [9, 0], [-22, 0]], [[63, 10]], [[55, -1]]));
        handle.withGradientFill("right", Gradients.simple("#eeeeee".color()))
            .withStroke(strokeWidth("thinner"), "#cccccc");
        handle.closePath();
        var blade = new Path(Segments([[1, 1], [2, 8], [5, -3]], [[60, 0]], [[63, 6]], [[57, 9], , [-5, -2]], [[40, 10], [4, -2], [-20, 0]], [[15, 10]]));
        blade.closePath();
        blade.fillColor = new Color(new Gradient(Gradients.cylinder("#dddddd".color(), 3)), P(0, 0), P(40, 40));
        blade.withStroke(strokeWidth("thinner"), "#aaaaaa");
        var graphic = new Group([blade, handle]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    Scalpel.properties = {
        label: "Scalpel",
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return Scalpel;
}());
export { Scalpel };
