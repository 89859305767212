var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { MoreShapes } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P } from "apparatus/library/common";
import { BasicShapes } from "apparatus/library/shapes/common";
import "ext/color";
import { Group } from "paper";
var Cylinder = /** @class */ (function () {
    function Cylinder() {
        this.drivers = [
            {
                type: "rectangle",
                minX: 6, maxX: 250,
                minY: 8, maxY: 300,
                toAppearance: function (f, p) { return f({
                    diameter: p.x * 2,
                    height: p.y * 2
                }); },
                fromAppearance: function (a) { return P(a.diameter / 2, a.height / 2); },
                scale: 1.0,
            }
        ];
    }
    Cylinder.prototype.render = function (appearance) {
        var diameter = appearance.diameter, height = appearance.height, stroke = appearance.stroke, style = appearance.style, strokeThickness = appearance.strokeThickness, shading = appearance.shading;
        var _a = MoreShapes.cylinder3d({ diameter: diameter, height: height }), body = _a.body, top = _a.top, outline = _a.outline;
        var graphic = new Group([body, top]);
        if (style == "full" || style == "stroke-only") {
            outline.withStroke(strokeThickness, stroke);
            graphic.addChild(outline);
        }
        else {
            outline.remove();
        }
        var fillColor = BasicShapes.fillColor(appearance).color();
        if (shading == "3d") {
            top.withGradientFill("right", Gradients.simple(fillColor.ladd(10)));
            body.withGradientFill("left", Gradients.cylinder(fillColor, 3));
        }
        else {
            top.fillColor = fillColor.ladd(10).string();
            body.withFill(fillColor.string());
        }
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape(),
        };
    };
    Cylinder.properties = {
        label: "Cylinder",
        defaultAppearance: __assign(__assign({}, BasicShapes.defaultAppearance), { diameter: 120, height: 150, shading: "flat" }),
        appearanceSpecs: __spreadArray(__spreadArray([], BasicShapes.specs), [
            BasicShapes.header,
            {
                spec: "toggle-buttons", key: "shading", label: "Shading", size: "text",
                options: [
                    { value: "flat", label: "Flat" },
                    { value: "3d", label: "3D", tier: "boost" },
                ],
                allowSelectingTierLockedOptions: true,
            }
        ]),
        isBoostRequired: function (a) { return a.shading === "3d"; }
    };
    return Cylinder;
}());
export { Cylinder };
