import { P } from "apparatus/library/common";
import { project } from "paper";
import bulb_svg from "./accessories/bulb.svg";
import glove_svg from "./accessories/glove.svg";
import goggles_svg from "./accessories/goggles.svg";
import labcoat_svg from "./accessories/labcoat.svg";
import mouse_side_svg from "./animal/mouse_side.svg";
import tree_svg from "./bio/tree.svg";
import bugs_svg from "./bugs1_o.svg";
import desiccator_svg from "./desiccator.svg";
import desiccator_lid_svg from "./desiccator_lid.svg";
import allihn_svg from "./distillation/allihn.svg";
import cold_finger_svg from "./distillation/cold_finger.svg";
import fractionating_svg from "./distillation/fractionating.svg";
import graham_svg from "./distillation/graham.svg";
import holder_svg from "./equipment/holder-o.svg";
import mantle_svg from "./equipment/mantle-o.svg";
import microscope_svg from "./equipment/microscope.svg";
import peg_svg from "./equipment/peg-o.svg";
import peg_side_svg from "./equipment/peg-side-o.svg";
import flame_blue_svg from "./flame/blue.svg";
import flame_blue_flat_svg from "./flame/blue_flat.svg";
import flame_red_svg from "./flame/red.svg";
import flame_red_flat_svg from "./flame/red_flat.svg";
import ghs_acid_svg from "./ghs/acid.svg";
import ghs_explosion_svg from "./ghs/explosion.svg";
import ghs_flammable_svg from "./ghs/flammable.svg";
import ghs_gas_svg from "./ghs/gas.svg";
import ghs_harm_svg from "./ghs/harm.svg";
import ghs_health_svg from "./ghs/health.svg";
import ghs_oxidising_svg from "./ghs/oxidising.svg";
import ghs_pollution_svg from "./ghs/pollution.svg";
import ghs_toxic_svg from "./ghs/toxic.svg";
import glass_wool_svg from "./glass_wool.svg";
import hot_plate_svg from "./hot_plate.svg";
import magnetic_stirrer_svg from "./magnetic_stirrer.svg";
import mortar_svg from "./mortar.svg";
import boost_badge_svg from "./other/boost.svg";
import hand_svg from "./other/hand.svg";
import pestle_svg from "./pestle.svg";
import plant1 from "./plant/1.svg";
import plant2 from "./plant/2.svg";
import plant3 from "./plant/3.svg";
import plant4 from "./plant/4.svg";
import plant5 from "./plant/5.svg";
import plant6 from "./plant/6.svg";
import root_svg from "./plant/root_o.svg";
import pump_body_svg from "./pump_body.svg";
import pump_cog_svg from "./pump_cog.svg";
import pump_insert_svg from "./pump_insert.svg";
import stirbar_svg from "./stirbar.svg";
import stopCock_svg from "./stop_cock.svg";
import timer_svg from "./timer.svg";
var plants_svgs = [plant1, plant2, plant3, plant4, plant5, plant6];
export var SVG;
(function (SVG) {
    /** @deprecated */
    function stopCock() {
        var stopCock = project.importSVG(stopCock_svg);
        stopCock.scale(1.5);
        stopCock.position.y = 9;
        stopCock.position.x = 7;
        stopCock.strokeWidth = 3.0;
        return stopCock;
    }
    SVG.stopCock = stopCock;
    function redFlame() {
        var flame = project.importSVG(flame_red_svg);
        flame.pivot = P(7, 48);
        flame.scale(1.5);
        return flame;
    }
    SVG.redFlame = redFlame;
    function blueFlame() {
        var flame = project.importSVG(flame_blue_svg);
        flame.pivot = P(7, 48);
        flame.scale(1.5);
        return flame;
    }
    SVG.blueFlame = blueFlame;
    function blueFlameFlat() {
        var flame = project.importSVG(flame_blue_flat_svg);
        flame.pivot = P(30, 12);
        flame.scale(1.5);
        return flame;
    }
    SVG.blueFlameFlat = blueFlameFlat;
    function redFlameFlat() {
        var flame = project.importSVG(flame_red_flat_svg);
        flame.pivot = P(47, 10);
        flame.scale(1.5);
        return flame;
    }
    SVG.redFlameFlat = redFlameFlat;
    SVG.glove = function () { return project.importSVG(glove_svg); };
    SVG.goggles = function () { return project.importSVG(goggles_svg); };
    SVG.labcoat = function () { return project.importSVG(labcoat_svg); };
    SVG.bulb = function () { return project.importSVG(bulb_svg); };
    // Distillation.
    SVG.coldFinger = function () { return project.importSVG(cold_finger_svg); };
    SVG.allihn = function () { return project.importSVG(allihn_svg); };
    SVG.fractionating = function () { return project.importSVG(fractionating_svg); };
    SVG.graham = function () { return project.importSVG(graham_svg); };
    SVG.stirbar = function () { return project.importSVG(stirbar_svg); };
    SVG.hotPlate = function () { return project.importSVG(hot_plate_svg); };
    SVG.magneticStirrer = function () { return project.importSVG(magnetic_stirrer_svg); };
    SVG.desiccator = function () { return project.importSVG(desiccator_svg); };
    SVG.desiccator_lid = function () { return project.importSVG(desiccator_lid_svg); };
    SVG.timer = function () { return project.importSVG(timer_svg); };
    SVG.glassWool = function () { return project.importSVG(glass_wool_svg); };
    SVG.pestle = function () { return project.importSVG(pestle_svg); };
    SVG.mortar = function () { return project.importSVG(mortar_svg); };
    function pump() {
        return {
            body: project.importSVG(pump_body_svg),
            insert: project.importSVG(pump_insert_svg),
            cog: project.importSVG(pump_cog_svg)
        };
    }
    SVG.pump = pump;
    function mantle() {
        return project.importSVG(mantle_svg);
    }
    SVG.mantle = mantle;
    var Peg;
    (function (Peg) {
        Peg.top = function () { return project.importSVG(peg_svg); };
        Peg.side = function () { return project.importSVG(peg_side_svg); };
    })(Peg = SVG.Peg || (SVG.Peg = {}));
    SVG.testTubeHolder = function () { return project.importSVG(holder_svg); };
    SVG.microscrope = function () { return project.importSVG(microscope_svg); };
    var GHS;
    (function (GHS) {
        GHS.acid = function () { return project.importSVG(ghs_acid_svg).withScale(0.79); };
        GHS.explosion = function () { return project.importSVG(ghs_explosion_svg); };
        GHS.flammable = function () { return project.importSVG(ghs_flammable_svg); };
        GHS.gas = function () { return project.importSVG(ghs_gas_svg); };
        GHS.harm = function () { return project.importSVG(ghs_harm_svg); };
        GHS.health = function () { return project.importSVG(ghs_health_svg).withScale(0.63); };
        GHS.oxidising = function () { return project.importSVG(ghs_oxidising_svg); };
        GHS.toxic = function () { return project.importSVG(ghs_toxic_svg).withScale(0.79); };
        GHS.pollution = function () { return project.importSVG(ghs_pollution_svg).withScale(0.79); };
    })(GHS = SVG.GHS || (SVG.GHS = {}));
    var rasterizedBadge;
    SVG.boostBadge = function () {
        if (!rasterizedBadge) {
            var svg = project.importSVG(boost_badge_svg).withScale(0.02, P(0, 0));
            rasterizedBadge = svg.rasterize();
            rasterizedBadge.remove();
            svg.remove();
        }
        var clone = rasterizedBadge.clone();
        return clone;
    };
    SVG.plant = function (sizeIndex) { return project.importSVG(plants_svgs[sizeIndex]); };
    SVG.root = function () { return project.importSVG(root_svg); };
    SVG.bugs = function () { return project.importSVG(bugs_svg).withScale(0.2); };
    SVG.mouse = function () { return project.importSVG(mouse_side_svg).withScale(0.3); };
    SVG.hand = function () { return project.importSVG(hand_svg).withScale(0.3); };
    SVG.tree = function () { return project.importSVG(tree_svg).withScale(0.5); };
})(SVG || (SVG = {}));
