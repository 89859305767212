import { mirrorX } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Gradient, Group, Path, Shape } from "paper";
var StickLighter = /** @class */ (function () {
    function StickLighter() {
    }
    StickLighter.prototype.render = function (appearance) {
        var casing = new Path(Segments([[6, 0]], [[8, 15]], [[23, 46], [2, -13], [-3, 20]], [[16, 93], [0, -7], [1, 20]], [[17, 130], [3, -9], [-5, 17]]));
        mirrorX(casing);
        casing.close()
            .withStroke("default", "#999999")
            .withGradientFill("right", Gradients.cylinder("#dddddd".color()));
        var nozzle = Shape.Rectangle(R(0, 0, 10, 90, Pivot.BOTTOM_CENTER))
            .withGradientFill("right", Gradients.cylinder("#444444".color()));
        var button = Shape.Rectangle(R(0, 40, 24, 30, Pivot.TOP_CENTER), 12);
        button.fillColor = new paper.Color(new Gradient([["#eeeeee", 0.6], ["#ffffff", 0.63], ["#bbbbbb", 1.0]], true), P(0, 40 + 30 - 12), P(0, 40 + 3));
        button.withStroke("thinner", "#aaaaaa");
        var graphic = new Group([nozzle, casing, button]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape()
        };
    };
    StickLighter.properties = {
        label: "Stick Lighter",
        defaultAppearance: {},
        appearanceSpecs: [],
    };
    return StickLighter;
}());
export { StickLighter };
