import { strokeWidth } from "apparatus/draw";
import { P, R } from "apparatus/library/common";
import { Path, Shape } from "paper";
var CoverSlip = /** @class */ (function () {
    function CoverSlip() {
    }
    CoverSlip.prototype.render = function (appearance) {
        var width = appearance.width, height = appearance.height;
        if (appearance.side) {
            var graphic = new Path.Line(P(0, 0), P(width, 0));
            graphic.withStroke(strokeWidth("thin"), "#999999aa");
            return {
                graphic: graphic,
                hitShape: graphic.bounds.expand(20).toShape(),
            };
        }
        else {
            var graphic = Shape.Rectangle(R(0, 0, width, height));
            graphic.withStroke(strokeWidth("thinner"), "#999999aa").withFill("#dddddd40");
            return {
                graphic: graphic,
                hitShape: graphic.bounds.expand(15).toShape()
            };
        }
    };
    CoverSlip.properties = {
        label: "Cover slip",
        defaultAppearance: {
            width: 20,
            height: 20,
            side: false,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Width",
                min: 20, max: 50, unit: "mm"
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 20, max: 50, unit: "mm"
            },
            {
                spec: "checkbox", key: "side", label: "View from side"
            }
        ],
    };
    return CoverSlip;
}());
export { CoverSlip };
