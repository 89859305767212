import { Keys } from "keyboard";
export var TextReplacements;
(function (TextReplacements) {
    function onKeyPress(input, event) {
        var _a;
        input.undoReplacement = null;
        var text = input.ref.current.value;
        var selectionEnd = (_a = input.ref.current.selectionEnd) !== null && _a !== void 0 ? _a : 0;
        var r = textReplacement(event.key, text, selectionEnd);
        if (r) {
            switch (r.action) {
                case "replace":
                    var pre = text.substring(0, selectionEnd - r.length);
                    var post = text.substring(selectionEnd, text.length);
                    var newCursorPosition = selectionEnd - r.length + r.text.length;
                    input.manuallySetText(pre + r.text + post, newCursorPosition);
                    input.undoReplacement = { original: text.substring(0, selectionEnd) + r.originalKey + post, position: selectionEnd + 1 };
                    event.preventDefault();
            }
        }
    }
    TextReplacements.onKeyPress = onKeyPress;
    function onKeyDown(input, event) {
        if (input.undoReplacement && event.keyCode == Keys.BACKSPACE) {
            var _a = input.undoReplacement, original = _a.original, position = _a.position;
            input.manuallySetText(original, position);
            input.undoReplacement = null;
            event.preventDefault();
            return;
        }
    }
    TextReplacements.onKeyDown = onKeyDown;
})(TextReplacements || (TextReplacements = {}));
function textReplacement(key, text, selection) {
    if (key == ")") {
        var startBracketLocation = text.substring(0, selection).lastIndexOf("(");
        if (startBracketLocation === -1)
            return;
        var toReplace = text.substring(startBracketLocation + 1, selection);
        // Check replacements.
        var capitalGreek = replaceGreek(toReplace);
        if (capitalGreek) {
            return { action: "replace", text: capitalGreek, length: toReplace.length + 1, originalKey: key };
        }
    }
    else if (key == "-") {
        // Replace "+-" -> "±"
        if (selection > 1 && text.substring(selection - 1, selection) == "+") {
            return { action: "replace", text: "±", length: 1, originalKey: key };
        }
    }
    return;
}
function replaceGreek(replace) {
    var _a;
    replace = (_a = REMAPPINGS[replace]) !== null && _a !== void 0 ? _a : replace;
    var found = CAPITAL_GREEK_LETTERS.indexOf(replace);
    if (found > -1)
        return String.fromCharCode(CAPITAL_GREEK_ALPHA + found);
    var found2 = SMALL_GREEK_LETTERS.indexOf(replace);
    if (found2 > -1)
        return String.fromCharCode(SMALL_GREEK_ALPHA + found2);
}
var REMAPPINGS = {
    "micro": "mu",
};
var CAPITAL_GREEK_ALPHA = 913;
var CAPITAL_GREEK_LETTERS = [
    "Alpha",
    "Beta",
    "Gamma",
    "Delta",
    "Epsilon",
    "Zeta",
    "Eta",
    "Theta",
    "Iota",
    "Kappa",
    "Lambda",
    "Mu",
    "Nu",
    "Xi",
    "Omicron",
    "Pi",
    "Rho",
    "___GAP___",
    "Sigma",
    "Tau",
    "Upsilon",
    "Phi",
    "Chi",
    "Psi",
    "Omega",
];
var SMALL_GREEK_ALPHA = 945;
var SMALL_GREEK_LETTERS = [
    "alpha",
    "beta",
    "gamma",
    "delta",
    "epsilon",
    "zeta",
    "eta",
    "theta",
    "iota",
    "kappa",
    "lambda",
    "mu",
    "nu",
    "xi",
    "omicron",
    "pi",
    "rho",
    "___GAP___",
    "sigma",
    "tau",
    "upsilon",
    "___phi___",
    "chi",
    "psi",
    "omega",
    "__iotadialytika__",
    "__upsilondialytika__",
    "__omicrontonos__",
    "__upsilontonos__",
    "__omegatonos__",
    "__kai__",
    "beta2",
    "theta2",
    "__upsilonhook__",
    "__upsilonhookacute__",
    "__upsilondiaeresis__",
    "phi",
];
