import { Arrays } from "ext/array";
import { Group, Path, Shape } from "paper";
import { addEdgeLipsReversed, addMarkers, mirrorX, setDefaultStyle, smoothCorner } from "../draw";
import { P, R, Segments } from "./common";
var Cylinder = /** @class */ (function () {
    function Cylinder() {
    }
    Cylinder.prototype.render = function (appearance) {
        // let width = 30
        // let height = 200
        // let baseRadius = 35
        // let baseHeight = 10
        var height = appearance.height;
        var width = 14 + height / 12.5;
        var baseRadius = width * 1;
        var baseHeight = width / 4;
        var cylinder = new Path(Segments([[0, 0]], [[0, height]], [[(baseRadius - width / 2), height + baseHeight]]));
        smoothCorner(cylinder.segments[1], 2.0);
        mirrorX(cylinder, -width / 2);
        addEdgeLipsReversed(cylinder);
        setDefaultStyle(cylinder);
        var graphic = new Group([cylinder]);
        addMarkers(graphic, P(-5.0, height), 0, P(0, 10.0));
        var hitShape = [
            Shape.Rectangle(R(-width, 0, width, height)),
            Shape.Rectangle(R(-width / 2 - baseRadius, height, baseRadius * 2, baseHeight))
        ];
        var liquidMask = new Path.Rectangle(R(-width, 0, width, height));
        return {
            graphic: graphic,
            hitShape: hitShape,
            liquidMask: liquidMask,
            liquidMaskOpening: { end: cylinder.segments[0].point, start: Arrays.last(cylinder.segments).point },
            snapping: graphic.placeable(),
        };
    };
    Cylinder.properties = {
        label: "Graduated cylinder",
        canContainLiquids: true,
        meniscus: true,
        pourable: true,
        defaultAppearance: {
            height: 200,
        },
        appearanceSpecs: [
            {
                spec: "slider", label: "Size", key: "height",
                min: 80, max: 300,
            }
        ],
    };
    return Cylinder;
}());
export { Cylinder };
