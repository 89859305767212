import { Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
import { setDefaultStyleWithStroke, smoothCorner } from "../../draw";
var GlassStopper = /** @class */ (function () {
    function GlassStopper() {
    }
    GlassStopper.prototype.render = function (appearance) {
        var d = appearance.diameter - 3.0;
        var handle = new Path.Rectangle(R(0, -5, d + 15, 10, Pivot.BOTTOM_CENTER), 2.0);
        var handle2 = new Path.Rectangle(R(0, 3, d / 2 + 4, 10, Pivot.BOTTOM_CENTER));
        var stopper = new Path(Segments([[-d / 2 - 2, 0], , [2, -2]], [[d / 2 + 2, 0], [-2, -2]], [[d / 2 + 1, 30]], [[-d / 2 - 1, 30]]));
        stopper.closePath();
        smoothCorner(stopper.segments[3], 2.0);
        smoothCorner(stopper.segments[2], 2.0);
        var groundJoint = stopper.clone();
        var outline = handle.unite(handle2).unite(stopper);
        setDefaultStyleWithStroke("thin", outline);
        outline.strokeColor = "#666666";
        outline.fillColor = "#ffffff60";
        groundJoint.setGradientFill("right", [["#888888", 0.0], ["#ffffff", 0.5], ["#888888", 1.0]]);
        groundJoint.opacity = 0.5;
        groundJoint.strokeWidth = 0;
        var graphic = new Group([groundJoint, outline]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(20).toShape(),
        };
    };
    GlassStopper.properties = {
        label: "Glass Stopper",
        defaultAppearance: {
            diameter: 15
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 10, max: 35, step: 0.1,
            }
        ],
    };
    return GlassStopper;
}());
export { GlassStopper };
