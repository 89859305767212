var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { mirrorX, strokeWidth } from "apparatus/draw";
import { Interpolator, Segments } from "apparatus/library/common";
import { Caps } from "apparatus/library/shared/cap";
import { Group, Path } from "paper";
var PlasticReagentBottle = /** @class */ (function () {
    function PlasticReagentBottle() {
        this.radiusInterpolator = new Interpolator([60, 180], [30, 60]);
    }
    PlasticReagentBottle.prototype.render = function (appearance) {
        var radius = this.radiusInterpolator.interpolate(appearance.size);
        var height = appearance.size;
        var bottleBackPart = new Path(Segments([[radius, 0], [0, radius * -0.75]], [[radius, height]]));
        mirrorX(bottleBackPart);
        bottleBackPart.closePath();
        var outline = bottleBackPart.clone().withStroke(strokeWidth("default"), "#888888");
        bottleBackPart.setGradientFill("right", [["#dddddd", 0.0], ["#eeeeee", 0.15], ["#eeeeee", 0.85], ["#dddddd", 1.0]]);
        // const bottleFrontPart = new Path(Segments(
        //     [[radius, 10],[-20, radius * -0.5]],
        //     [[radius, height]],
        // ))
        // mirrorX(bottleFrontPart)
        // bottleFrontPart.closePath()
        // bottleFrontPart.setGradientFill("right", [["#dddddd", 0.0], ["#eeeeee", 0.15], ["#eeeeee", 0.85], ["#dddddd", 1.0]])
        var lightLine = new Path(Segments([[-radius, height - 20]], [[radius, height - 20]])).withStroke(2.0, "#f6f6f6");
        var darkLine = new Path(Segments([[-radius, 10 + height / 10]], [[radius, 10 + height / 10]])).withStroke(2.0, "#cccccc");
        var cap = Caps.createCap({ radius: radius - 10, appearance: appearance });
        cap.position.y -= 30 + height / 10;
        var graphic = new Group([bottleBackPart, darkLine, lightLine, outline, cap]);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: graphic.placeable(),
        };
    };
    PlasticReagentBottle.properties = {
        label: "Plastic Reagent Bottle",
        defaultAppearance: __assign({ size: 100 }, Caps.defaultAppearance),
        appearanceSpecs: [
            {
                spec: "slider", key: "size", label: "Size",
                min: 60, max: 180
            }
        ],
    };
    return PlasticReagentBottle;
}());
export { PlasticReagentBottle };
