var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import Flags from "flags";
export var APPARATUS_MODULES = ["chemistry", "heating", "various", "shapes", "biology", "physics", "distillation", "measurement", "transport"];
export var moduleDisplayIcon = function (module) {
    switch (module) {
        case "chemistry":
            return "flask";
        case "various":
            return "box-open";
        case "shapes":
            return "shapes";
        case "physics":
            return "atom";
        case "biology":
            return "leaf";
        case "distillation":
            return "tint";
        case "measurement":
            return "ruler";
        case "heating":
            return "flame";
        case "transport":
            return "pipe-section";
    }
};
/** List all categories, in the desired order. */
export var apparatusCategories = {
    "chemistry": [
        "Containers",
        "Filtering",
        "Metallic",
        "Ceramic",
        "Devices",
        "Chromatography",
        "Storage",
    ],
    "biology": [
        "_Bio_Equipment",
        "Organic",
        "_Bio_Other",
    ],
    "physics": __spreadArray(__spreadArray([
        "Electric & Magnetic"
    ], (Flags.isElectricalSymbolsEnabled ? ["Electrical symbols"] : [])), [
        "Optics",
        "Dynamics",
    ]),
    "heating": [
        "Heating",
    ],
    "measurement": [
        "Measurement",
        "_Mea_Extraction",
    ],
    "transport": [
        "Tubes",
    ],
    "distillation": [
        "Condensers",
        "Distillation",
    ],
    "various": [
        "Substances",
        "Labelling",
        "Medical",
        "Accessories",
    ],
    "shapes": [
        "Basic shapes",
        "Arrows",
        "3D",
    ],
};
export var categoryToModuleMap = {};
for (var _i = 0, APPARATUS_MODULES_1 = APPARATUS_MODULES; _i < APPARATUS_MODULES_1.length; _i++) {
    var module = APPARATUS_MODULES_1[_i];
    for (var _a = 0, _b = apparatusCategories[module]; _a < _b.length; _a++) {
        var category = _b[_a];
        categoryToModuleMap[category] = module;
    }
}
