var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { setDefaultStyle } from "apparatus/draw";
import { P } from "apparatus/library/common";
import { CompoundPath, Group } from "paper";
import { CommonCondenser, condenserCaseShape } from "../condensers";
var FractionatingColumn2 = /** @class */ (function () {
    function FractionatingColumn2() {
    }
    FractionatingColumn2.prototype.render = function (appearance) {
        var height = appearance.height;
        var _a = condenserCaseShape(height, 15, "top-only"), casing = _a.group, snapping = _a.snap;
        var inside = new CompoundPath({});
        inside.moveTo(P(0, 45));
        inside.lineTo(P(0, height - 20));
        var width = 20;
        for (var y = 55; y < height - 25; y += 18) {
            inside.moveTo(P(-width / 2, y));
            inside.lineTo(P(width / 2, y));
        }
        var graphic = new Group([casing, inside]);
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: snapping,
            pivot: P(0, height / 2)
        };
    };
    FractionatingColumn2.properties = {
        label: "Fractionating Column",
        flippable: true,
        defaultAppearance: __assign(__assign({}, CommonCondenser.NoPorts.defaultAppearance), { height: 270 }),
        appearanceSpecs: __spreadArray([], CommonCondenser.NoPorts.appearanceSpecs),
    };
    return FractionatingColumn2;
}());
export { FractionatingColumn2 };
