var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { strokeWidth } from "apparatus/draw";
import { P, Segments, SharedColors } from "apparatus/library/common";
import { Ray } from "apparatus/library/physics/optical/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var Prism = /** @class */ (function () {
    function Prism() {
    }
    Prism.prototype.render = function (appearance) {
        var graphic = new Group([]);
        var prism = new Path(Segments([[-50, 43]], [[0, -43]], [[50, 43]], [[48, 50]], [[-48, 50]])).close()
            .withStroke("default", SharedColors.glassEdge)
            .withGradientFill("right-down", [["#f0f0f080", 0.5], ["#cdcdcd40", 0.6], ["#f0f0f080", 0.7]]);
        var prismLine = new Path.Line(P(50, 43), P(-50, 43)).withStroke("thinner", SharedColors.glassEdge);
        if (appearance.showRay) {
            var ray = new Path.Line(P(-120, 20), P(-25, 0))
                .withStroke("default", "#333333");
            var spectrum1 = generateSpectrum(10, 80);
            spectrum1.position = P(-30, 0);
            spectrum1.rotate(2);
            // Mask
            spectrum1.insertChild(0, new Path([[-50, 43], [0, -43], [50, 43]]).close());
            spectrum1.children[0].clipMask = true;
            spectrum1.opacity = 0.4;
            var spectrum2 = generateSpectrum(20, 150);
            spectrum2.position = P(5, -6);
            spectrum2.rotate(20);
            // Mask
            spectrum2.insertChild(0, new Path([[0, -43], [50, 43], [140, 70], [160, 0]]).withFill("red").close());
            spectrum2.children[0].clipMask = true;
            graphic.addChildren([ray, spectrum1, spectrum2]);
            Ray.maybeAddArrows(__assign(__assign({}, appearance), { rayColor: "#333333", rayThickness: strokeWidth("default") }), ray, [0.5], graphic);
        }
        graphic.addChildren([prism, prismLine]);
        return {
            graphic: graphic,
            pivot: prism.bounds.center,
            hitShape: prism.rectHitShape(),
        };
    };
    Prism.properties = {
        label: "Prism",
        defaultAppearance: {
            showRay: true,
            showRayArrow: false,
        },
        appearanceSpecs: [
            {
                spec: "checkbox-group", label: "Ray", checkboxes: [
                    { key: "showRay", label: "Show" },
                    { key: "showRayArrow", label: "Show arrow" },
                ]
            },
        ],
    };
    Prism = __decorate([
        staticImplements()
    ], Prism);
    return Prism;
}());
export { Prism };
var SPECTRUM_RANGE = 345;
function generateSpectrum(totalAngle, length) {
    var group = new Group([]);
    var color = "hsl(90,100%,50%)".color();
    var steps = 8;
    var angle = totalAngle / steps;
    var triangle = new Path([[0, 0], P(length, 0).rotate(-angle), P(length, 0).rotate(angle)]).close();
    for (var i = 0; i < steps; i++) {
        var t = triangle.clone();
        if (i == 0) {
            t.segments[1].point = P(length, 0);
            t.withGradientFill("down", [
                [color.hue(SPECTRUM_RANGE / steps * i).hex(), 0.0],
                [color.hue(SPECTRUM_RANGE / steps * (i + 0.8)).hex() + "00", 1.0],
            ]);
        }
        else if (i == steps - 1) {
            t.segments[2].point = P(length, 0);
            t.withGradientFill("down", [
                [color.hue(SPECTRUM_RANGE / steps * (i - 0.8)).hex() + "00", 0.0],
                [color.hue(SPECTRUM_RANGE / steps * i).hex(), 1.0],
            ]);
        }
        else {
            t.withGradientFill("down", [
                [color.hue(SPECTRUM_RANGE / steps * (i - 0.8)).hex() + "00", 0.0],
                [color.hue(SPECTRUM_RANGE / steps * i).hex(), 0.5],
                [color.hue(SPECTRUM_RANGE / steps * (i + 0.8)).hex() + "00", 1.0],
            ]);
        }
        t.rotate(i * angle, P(0, 0));
        group.addChild(t);
    }
    triangle.remove();
    group.pivot = P(0, 0);
    group.rotate(-totalAngle / 2);
    return group;
}
