import { setDefaultStyle } from "apparatus/draw";
import { Segments } from "apparatus/library/common";
import { Color, Gradient, Path } from "paper";
var Seed = /** @class */ (function () {
    function Seed() {
    }
    Seed.prototype.render = function (appearance) {
        var graphic = new Path(Segments([[34, -22], [4, -15], [-5, 19]], [[29, 31], [-2, -15], [2, 21]], [[-33, 28], [12, 36], [-13, -40]], [[-8, -45], [-16, 8], [19, -9]]));
        graphic.closePath();
        setDefaultStyle(graphic);
        graphic.fillColor = new Color(new Gradient([
            ["#c5d872", 0.0],
            ["#83a622", 1.0],
        ], true), graphic.bounds.leftCenter, graphic.bounds.rightCenter);
        graphic.strokeColor = "#609a3a";
        graphic.scale(0.2 * appearance.scale);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.expand(10.0).toShape()
        };
    };
    Seed.properties = {
        label: "Seed",
        defaultAppearance: {
            scale: 1.0
        },
        flippable: true,
        appearanceSpecs: [
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 1.0, max: 5.0, step: 0.1, unit: "%",
            }
        ],
    };
    return Seed;
}());
export { Seed };
