import { addEdgeLipsReversed, mirrorX, setDefaultStyle } from "apparatus/draw";
import { Arrays } from "ext/array";
import * as p from "paper";
import { Segments, SharedColors } from "./common";
var VolumetricFlask = /** @class */ (function () {
    function VolumetricFlask() {
    }
    VolumetricFlask.prototype.render = function (appearance) {
        var neckRadius = 10;
        var flask = new p.Path(Segments([[neckRadius, 0]], [[neckRadius, 160], undefined, [0, 5]], [[55, 225], [-6, -24], [4, 16]], [[32, 260], [10, 0]]));
        mirrorX(flask);
        flask.scale(appearance.size / 260);
        var hitShape = flask.clone();
        var liquidMask = flask.clone();
        addEdgeLipsReversed(flask);
        setDefaultStyle(flask);
        var spiritLine = new p.Path(Segments([[-7, 100]], [[7, 100]]));
        spiritLine.strokeColor = SharedColors.mark;
        spiritLine.strokeWidth = 2.0;
        spiritLine.strokeCap = "butt";
        var scale = appearance.size / 260;
        spiritLine.scale(scale, flask.bounds.center);
        var graphic = new p.Group([flask, spiritLine]);
        return {
            graphic: graphic,
            hitShape: hitShape,
            liquidMask: liquidMask,
            liquidMaskOpening: { end: flask.segments[0].point, start: Arrays.last(flask.segments).point },
            snapping: [
                { type: "placeable", at: graphic.bounds.bottomCenter },
                { type: "neck", top: graphic.bounds.topCenter, diameter: neckRadius * 2 * scale, facing: 0 }
            ]
        };
    };
    VolumetricFlask.properties = {
        label: "Volumetric Flask",
        canContainLiquids: true,
        meniscus: true,
        pourable: true,
        defaultAppearance: {
            size: 260
        },
        appearanceSpecs: [{
                spec: "slider", key: "size", label: "Size",
                min: 180, max: 500,
            }],
    };
    return VolumetricFlask;
}());
export { VolumetricFlask };
