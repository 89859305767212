var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var colorClipboard = "";
/** Registers extensions for bootstrap color picker */
export function registerBootstrapColorpickerExtensions() {
    var ExtensionClass = $.colorpicker.Extension;
    $.colorpicker.extensions["extrabuttons"] = /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(a, b) {
            var _this = _super.call(this, a, b) || this;
            _this.element = null;
            return _this;
        }
        class_1.prototype.onCreate = function (e) {
            var colorpicker = this.colorpicker;
            this.element = $("<div class='mt-2 clearfix'>" +
                "<div class='horizontal-button-group d-block float-left fs-16'>" +
                "<div class='ui-button squarer' title='Copy colour'><i class='far fa-copy fa-fw' />&#8203;</div>" +
                "<div class='ui-button squarer' title='Paste colour'><i class='far fa-clipboard fa-fw' />&#8203;</div>" +
                "</div>" +
                "<div class='ui-button squarer d-block float-right fs-16' title='Close'>" +
                "<i class='fas fa-times fa-fw' />&#8203;" +
                "</div>" +
                "</div>");
            colorpicker.picker.append(this.element);
            // Copy.
            this.element.get(0).childNodes[0].childNodes[0].onclick = function (e) {
                colorClipboard = colorpicker.getValue();
            };
            // Paste.
            this.element.get(0).childNodes[0].childNodes[1].onclick = function (e) {
                if (colorClipboard !== "") {
                    colorpicker.setValue(colorClipboard);
                }
            };
            // Close.
            this.element.get(0).childNodes[1].onclick = function (e) {
                // First call to hide does not stick. Have to call it twice.
                colorpicker.hide();
                colorpicker.hide();
            };
        };
        class_1.prototype.resolveColor = function () { };
        return class_1;
    }(ExtensionClass));
    $.colorpicker.extensions["manualinput"] = /** @class */ (function (_super) {
        __extends(class_2, _super);
        function class_2(a, b) {
            var _this = _super.call(this, a, b) || this;
            _this.element = null;
            return _this;
        }
        class_2.prototype.onCreate = function (e) {
            var colorpicker = this.colorpicker;
            this.element = $("<div class='mt-2'>" +
                "<input type='text' class='ui-input w-100' />" +
                "</div>");
            colorpicker.picker.append(this.element);
            // Input.
            this.inputField = this.element.get(0).childNodes[0];
            this.inputField.value = colorpicker.getValue();
            this.inputField.onchange = function (e) {
                colorpicker.setValue(e.target.value);
            };
        };
        class_2.prototype.onUpdate = function () {
            var colorpicker = this.colorpicker;
            if (this.inputField) {
                this.inputField.value = colorpicker.getValue();
            }
        };
        return class_2;
    }(ExtensionClass));
}
