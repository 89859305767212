var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { g } from "analytics";
import { CommonModal } from "components/common_modal";
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { appStore } from "store/store";
import { Articles } from "support/beacon";
import { Badge } from "tiers/badge";
import { TierContext } from "tiers/context";
import { isIEOrEdge, saveBlobHack } from "utils";
import { Consts } from "vars";
/** Export image modal dialog. */
export var ExportImage2 = function (_a) {
    var exportedImages = _a.exportedImages, modalState = __rest(_a, ["exportedImages"]);
    var t = useTranslation().t;
    var isExportInProgress = !exportedImages;
    var title = isExportInProgress ? t("export.in_progress") : t("export.done");
    return React.createElement(CommonModal, __assign({}, modalState, { extraClass: "export-modal", title: title, icon: isExportInProgress ? "loading" : "fa-image", iconSwapOpacity: true, center: true }), exportedImages
        ? React.createElement(ExportedResults, __assign({}, exportedImages))
        : React.createElement(InProgress, null));
};
var InProgress = function () {
    var t = useTranslation().t;
    return React.createElement("p", null, t("export.in_progress_message"));
};
var ExportedResults = function (output) {
    var _a;
    var imageContainerRef = React.useRef(null);
    var filename = (_a = appStore.getState().currentCloudDiagram) === null || _a === void 0 ? void 0 : _a.title;
    var t = useTranslation().t;
    // Append the SVG image element if set.
    React.useEffect(function () {
        var imageContainer = imageContainerRef.current;
        if (!imageContainer)
            return;
        if (output) {
            if (imageContainer.children.length == 0) {
                var img = document.createElement("img");
                img.src = output.pngDataUrl;
                if (!Consts.isDev) {
                    img.oncontextmenu = function () { return false; };
                }
                imageContainer.appendChild(img);
            }
        }
        else {
            // Remove children.
            if (imageContainer.firstChild) {
                while (imageContainer.firstChild) {
                    imageContainer.removeChild(imageContainer.firstChild);
                }
            }
        }
    }, [output]);
    return React.createElement(TierContext.Consumer, null, function (tiers) { return ([
        // Upgrade notice for higher quality.
        !tiers.tier ? React.createElement(ImageQualityUpgrade, { key: "image_quality_upgrade", tiers: tiers }) : null,
        // Container to hold the exported image, as a preview.
        // This is empty and filled with an SVG in componentDidUpdate
        React.createElement("div", { key: "imageContainer", id: "exported-image-container", ref: imageContainerRef }),
        // On IE or Edge browser, show a notice.
        isIEOrEdge() ? React.createElement(InternetExplorerNotice, { key: "ieNotice" }) : null,
        // Instructions.
        React.createElement("p", { key: "a" }, t("export.select_format")),
        // Export buttons.
        React.createElement("p", { key: "export_buttons", className: "horizontal-button-group exportImageButtons" },
            React.createElement(DownloadButton, { key: "jpg", label: "JPG", mime: "image/jpeg", filename: filename, suffix: "jpg", data: output.jpgDataUrl, extraCss: "highlight" }),
            React.createElement(DownloadButton, { key: "png", label: "PNG", mime: "image/png", filename: filename, suffix: "png", data: output.pngDataUrl }),
            React.createElement(DownloadButton, { key: "svg", label: "SVG", mime: "image/svg", filename: filename, suffix: "svg", data: output.svgDataUrl, tiers: tiers })),
        // Hint on which format to pick.
        React.createElement("p", { key: "hint", className: "hint" },
            React.createElement("a", { href: "#", "data-beacon-article-sidebar": Articles.IMAGE_FORMATS },
                React.createElement("i", { className: "fa fa-question-circle mr-1" }),
                t("export.format_recommendation"),
                " ",
                React.createElement("i", { className: "fa fa-arrow-down-to-bracket" }),
                " JPG"))
    ]); });
};
/** Download button for a particular image format. */
var DownloadButton = function (props) {
    var _a;
    var isLocked = props.tiers && !props.tiers.allow("boost");
    return (React.createElement("a", { className: "remodal-confirm ui-button " + props.extraCss || "", href: !isLocked ? props.data : undefined, onClick: function (e) { return !isLocked ? downloadImage(e.target, props.mime, props.label) : props.tiers.popup("SVG"); }, download: ((_a = props.filename) !== null && _a !== void 0 ? _a : "chemix") + "." + props.suffix },
        React.createElement("i", { className: "fa fa-arrow-down-to-bracket" }),
        " ",
        props.label,
        " \u00A0",
        isLocked ? React.createElement(Badge, { tier: "boost" }) : null));
};
/** IE has a rendering bug. Notify users of this. */
var InternetExplorerNotice = function () { return (React.createElement("p", null,
    React.createElement("b", null, "Internet Explorer/EDGE users:"),
    " The preview may appear incorrect. ",
    React.createElement("br", null),
    "Do not worry, this does not affect the downloaded file.")); };
/** Handles downloading an image. */
function downloadImage(element, mime, actionLog) {
    g("SaveImage", actionLog);
    saveBlobHack(element, mime);
}
var ImageQualityUpgrade = function (props) {
    var t = useTranslation().t;
    return React.createElement("div", { className: "inline-toast toast toast-info d-flex mb-3" },
        t('export.promo.title'),
        React.createElement("div", { className: "horizontal-button-group ml-auto pl-2", style: { marginTop: "-6px", marginBottom: "-6px" } },
            React.createElement("a", { className: "ui-button px-3 py-1", href: "#", "data-beacon-article-sidebar": Articles.IMAGE_QUALITY }, t("Learn more")),
            React.createElement("div", { className: "ui-button px-3 py-1", onClick: function () { return props.tiers.popup("ImageExport"); } },
                t('export.promo.button'),
                " ",
                React.createElement(Badge, { tier: "boost", extraClass: "ml-1" }))));
};
