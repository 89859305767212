import { P, Segments } from "apparatus/library/common";
import { Group, Path } from "paper";
var Shoot = /** @class */ (function () {
    function Shoot() {
    }
    Shoot.prototype.render = function (appearance) {
        var height = appearance.height, bend = appearance.bend;
        var cap = appearance.cut ? "butt" : "round";
        var base = new Path(Segments([[0, 0], , [0, -height * 0.7]], [[0, -height]]));
        var pivot = P(0, -0.6 * height);
        base.segments[1].point = base.segments[1].point.rotate(bend, pivot);
        base.strokeWidth = 20;
        base.strokeCap = cap;
        var highlight = base.clone();
        highlight.position.x -= 5;
        if (appearance.cut && bend < 0) {
            var delta = pivot.subtract(base.segments[1].point).normalize();
            highlight.segments[1].point = highlight.segments[1].point.add(delta.multiply(5));
        }
        base.strokeColor = "#90bb79";
        highlight.strokeWidth = 6;
        highlight.strokeColor = "#b4d3a3";
        highlight.strokeCap = cap;
        var graphic = new Group([base, highlight]);
        return {
            graphic: graphic,
            hitShape: graphic.strokeBounds.expand(10).toShape(),
            pivot: P(0, 0)
        };
    };
    Shoot.properties = {
        label: "Straight shoot",
        defaultAppearance: {
            bend: 0,
            height: 150,
            cut: false,
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "height", label: "Height",
                min: 50, max: 180, unit: "mm"
            },
            {
                spec: "slider", key: "bend", label: "Bending",
                min: -40, max: 40
            },
            {
                spec: "checkbox", key: "cut", label: "Slice cap"
            }
        ],
    };
    return Shoot;
}());
export { Shoot };
