import { mirrorX } from "apparatus/draw";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var BLUE_COLOR = "#1828d6";
var WHITE_COLOR = "#eeeeee";
var BuretteClamp = /** @class */ (function () {
    function BuretteClamp() {
    }
    BuretteClamp.prototype.render = function (appearance) {
        var color = appearance.color.color();
        var clamp = new Path(Segments([[16, 12], [0, 4]], [[16, -49]], [[45, -80], [-1, 16]], [[45, -104]], [[32, -104]], [[32, -77], , [0, 5]], [[26, -72]]));
        mirrorX(clamp);
        clamp.closePath();
        clamp.withFill(color.hex()).withStroke("default", color.ladd(-20).hex());
        var pad = Shape.Rectangle(R(29, -102, 19, 16), 2.0).withFill("#333333");
        var pad2 = Shape.Rectangle(R(-29, -102, 19, 16, Pivot.TOP_RIGHT), 2.0).withFill("#333333");
        var pad3 = Shape.Rectangle(R(0, -72, 18, 25, Pivot.BOTTOM_CENTER), 2.0).withFill("#333333");
        var clamp2 = Shape.Rectangle(R(0, -35, 12, 65, Pivot.BOTTOM_CENTER), 2.0)
            .withFill(color.hex()).withStroke("default", color.ladd(-20).hex());
        var nut = Shape.Circle(P(0, 0), 6).withStroke("thin", "#cccccc").withFill("#aaaaaa");
        var graphic = new Group([clamp, clamp2, pad, pad2, pad3, nut]);
        graphic.rotate(90, P(0, 0));
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: { type: "attachable", at: P(0, 0) }
        };
    };
    BuretteClamp.properties = {
        label: "Burette Clamp",
        canContainLiquids: true,
        defaultAppearance: {
            color: BLUE_COLOR
        },
        appearanceSpecs: [
            {
                spec: "color-wells", key: "color", label: "Colour",
                colors: [
                    { value: BLUE_COLOR, label: "Blue" },
                    { value: WHITE_COLOR, label: "White" },
                ],
            }
        ],
    };
    return BuretteClamp;
}());
export { BuretteClamp };
