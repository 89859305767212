var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { setDefaultStyle } from "apparatus/draw";
import { Pivot, R, Segments } from "apparatus/library/common";
import { condenserCaseShape } from "apparatus/library/condensers";
import { Group, Path, Shape } from "paper";
import { staticImplements } from "types";
var DimorthCondenser = /** @class */ (function () {
    function DimorthCondenser() {
    }
    DimorthCondenser.prototype.render = function (appearance) {
        var height = appearance.height;
        var _a = condenserCaseShape(height, 15, "same-top"), casing = _a.group, snapping = _a.snap;
        var topInlet = new Path(Segments([[18, 0]], [[-7, 4], [27, 1], [-6, 0]], [[-13, 11]], [[-13, 44], , [0, 8]], [[10, 57], [-14, -6], [4, 2]], [[11, 52], [5, 2], [-16, -6]], [[-8, 42], [0, 6]], [[-8, 11], , [0, -1]], [[-5, 9], [-2, 0], [25, -1]], [[18, 15]])).withFill("white");
        topInlet.position.y += 23;
        var graphic = new Group([
            casing,
            Shape.Rectangle(R(0, 79, 25, 5, Pivot.CENTER), 3).withRotation(-10),
            topInlet,
        ]);
        var STEP = 10;
        var y = 74 + STEP;
        for (; y < height - 36; y += STEP) {
            var turn = Shape.Rectangle(R(0, y, 27, 5, Pivot.CENTER), 3).withFill("white");
            var turn2 = Shape.Rectangle(R(0, y, 25, 5, Pivot.CENTER), 3);
            turn.rotate(16);
            turn2.rotate(-10);
            turn2.position.y += STEP / 2;
            graphic.addChild(turn);
            graphic.insertChild(1, turn2);
        }
        var upTube = new Path(Segments([[18, 48]], [[3, 52], [15, 0], [-5, 0]], [[-3, 58]], [[-3, y]], [[3, y]], [[3, 63]], [[8, 59], [-6, 0], [13, 0]], [[18, 63]])).withFill("white");
        graphic.insertChild(0, upTube);
        var loop = new Path(Segments([[6, 11], [-1, -5], [0, 6]], [[-2, 16], [3, 2], [-3, -3]], [[1, 7], [-5, 4]], [[-4, 4], , [-5, 3]], [[-6, 20], [-7, -6], [5, 4]], [[12, 12], [0, 12], [0, -6]], [[-9, -3], [16, 5], [-5, -2]], [[-10, 2], [-4, 0]])).close().withFill("white");
        loop.position.y += y - 2;
        graphic.addChild(loop);
        setDefaultStyle(graphic);
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            snapping: snapping,
        };
    };
    DimorthCondenser.properties = {
        label: "Dimroth Condenser",
        flippable: true,
        defaultAppearance: {
            height: 200
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "height", label: "Height",
                min: 160, max: 250, tier: "boost",
            }
        ],
    };
    DimorthCondenser = __decorate([
        staticImplements()
    ], DimorthCondenser);
    return DimorthCondenser;
}());
export { DimorthCondenser };
