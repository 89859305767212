String.prototype.formattedAsDate = function () {
    var date = new Date(this);
    return date.formattedAsDate();
};
String.prototype.capitalise = function () {
    return this.charAt(0).toUpperCase() + this.substring(1);
};
String.prototype.last = function (n) {
    n = Math.min(n, this.length);
    if (n == 1)
        return this.charAt(this.length - 1);
    return this.substr(this.length - n);
};
String.prototype.first = function (n) {
    n = Math.min(n, this.length);
    if (n == 1)
        return this.charAt(0);
    return this.substr(0, n);
};
String.prototype.replaceLast = function (n, append) {
    return this.substr(0, this.length - n) + append;
};
String.prototype.if = function (condition) {
    return condition ? this : "";
};
Date.prototype.formattedAsDate = function () {
    return this.toLocaleString();
};
Number.prototype.toRadians = function () {
    return this * Math.PI / 180;
};
Number.prototype.toDegrees = function () {
    return this * 180 / Math.PI;
};
Number.prototype.round = function (decimals) {
    if (decimals === void 0) { decimals = 0; }
    if (decimals == 0)
        return Math.round(this);
    var factor = Math.pow(10, decimals);
    return Math.round((this + 0.000001) * factor) / factor;
};
Number.prototype.toLocaleFixed = function (places) {
    return this.toLocaleString(undefined, { minimumFractionDigits: places, maximumFractionDigits: places });
};
/** Returns angle in (-180, 180] */
Number.prototype.anglify = function () {
    var angle = this % 360 + (this < 0 ? 360 : 0);
    return angle > 180 ? angle - 360 : angle;
};
export {};
