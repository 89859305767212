var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Icon } from "components/icon";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { TierContext } from "tiers/context";
import { Tip } from "view/tooltips";
/** Used for sizing icon buttons. */
var ZERO_WIDTH_SPACE = "\u200B";
/** Custom translation helper. */
var useTt = function () {
    var t = useTranslation().t;
    return function tt(key) {
        // For pre-translated strings, pass a function.
        if (typeof key === "function")
            return key();
        return key ? t(["ui." + key, "props." + key, key]) : undefined;
    };
};
/**
 * A regular button that can optionally display an icon, on the left.
 */
export var Button = function (props) {
    var _a;
    var tiers = React.useContext(TierContext);
    var allowClick = tiers.allow(props) || props.allowWhenTierLocked;
    var tt = useTt();
    var label = tt(props.buttonLabel);
    return React.createElement(Tip, { content: tt(props.hint) },
        React.createElement("button", { className: "ui-button " + ((_a = props.extraClass) !== null && _a !== void 0 ? _a : ""), onClick: allowClick ? props.onClick : function () { return tiers.popup(label); }, style: props.widthCss ? { width: props.widthCss } : undefined },
            props.icon ? React.createElement(Icon, { name: props.icon, spacing: true }) : null,
            label,
            tiers.badge(props, "ml-1")));
};
/**
 * A button that displays an icon on the top, with text below.
 */
export var StackButton = function (props) {
    var _a;
    var tiers = React.useContext(TierContext);
    var allowClick = tiers.allow(props) || props.allowWhenTierLocked;
    var tt = useTt();
    var label = tt(props.buttonLabel);
    return React.createElement(Tip, { content: tt(props.hint) },
        React.createElement("button", { className: "ui-button ui-stack-button " + ((_a = props.extraClass) !== null && _a !== void 0 ? _a : ""), style: { width: props.widthCss }, onClick: allowClick ? props.onClick : function () { return tiers.popup(label); } },
            React.createElement(Icon, { name: props.icon, extraClass: "my-1 fa-2x", spacing: false }),
            React.createElement("br", null),
            label,
            tiers.badge(props)));
};
/**
 * A button that consists of an icon only.
 */
export var IconButton = function (props) {
    var _a;
    var tiers = React.useContext(TierContext);
    var allowClick = tiers.allow(props) || props.allowWhenTierLocked;
    var tt = useTt();
    var label = tt(props.buttonLabel);
    return React.createElement(Tip, { content: props.hint ? React.createElement(React.Fragment, null,
            React.createElement("b", null, label),
            " - ",
            tt(props.hint)) : label },
        React.createElement("button", { className: "ui-button " + ((_a = props.extraClass) !== null && _a !== void 0 ? _a : ""), onClick: allowClick ? props.onClick : function () { return tiers.popup(label); }, style: { width: props.widthCss } },
            React.createElement("span", { className: "fs-16" },
                React.createElement(Icon, { name: props.icon, spacing: false })),
            ZERO_WIDTH_SPACE,
            tiers.badge(props, "ml-1")));
};
/**
 * Horizontal row of {@link IconButton}s.
 */
export var HorizontalIconButtons = function (props) {
    var items = props.buttons.length;
    var widthCss = (100 / items).round(3) + "%";
    return React.createElement("div", { className: "horizontal-button-group" }, props.buttons.map(function (b, i) { return React.createElement(IconButton, __assign({ key: i, widthCss: widthCss }, b)); }));
};
/**
 * Horizontal row of button, that behave like a toggle, i.e. exactly one item is active.
 * Various sizes are supported.
 */
export var ToggleHorizontalButtons = function (props) {
    var _a;
    var items = (_a = props.buttonsPerRow) !== null && _a !== void 0 ? _a : props.buttons.length;
    var widthCss = (100 / items).round(3) + "%";
    switch (props.size) {
        case "icon":
            return React.createElement("div", { className: "horizontal-toggle-group" }, props.buttons.map(function (b, i) {
                return React.createElement(IconButton, __assign({ key: i, widthCss: widthCss, extraClass: i == props.highlightIndex ? "highlight" : undefined, allowWhenTierLocked: props.allowSelectingTierLockedOptions }, b));
            }));
        case "large":
            return React.createElement("div", { className: "horizontal-toggle-group" }, props.buttons.map(function (b, i) {
                return React.createElement(StackButton, __assign({ key: i, widthCss: widthCss, extraClass: i == props.highlightIndex ? "highlight" : undefined, allowWhenTierLocked: props.allowSelectingTierLockedOptions }, b));
            }));
        case "text":
        case "small":
            return React.createElement("div", { className: "horizontal-toggle-group" }, props.buttons.map(function (b, i) {
                return React.createElement(Button, __assign({ key: i, widthCss: widthCss, extraClass: i == props.highlightIndex ? "highlight" : undefined, allowWhenTierLocked: props.allowSelectingTierLockedOptions }, b, { icon: props.size == "small" ? b.icon : undefined, hint: b.hint }));
            }));
    }
};
/**
 * Vertically arranged {@link Buttons}s.
 */
export var VerticalButtons = function (props) { return (React.createElement("div", { className: "verticalButtonGroup" }, props.buttons.map(function (b, i) { return React.createElement(Button, __assign({ key: i }, b)); }))); };
/**
 * Horizontal buttons where 1 item is highlighted. Basically behaves
 * like a radio button group but using buttons.
 */
var HorizontalButtonsSwitcher = /** @class */ (function (_super) {
    __extends(HorizontalButtonsSwitcher, _super);
    function HorizontalButtonsSwitcher(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { value: props.defaultValue };
        return _this;
    }
    HorizontalButtonsSwitcher.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: (_a = "horizontal-toggle-group " + this.props.className) !== null && _a !== void 0 ? _a : "" }, this.props.options.map(function (o) {
            var _a;
            return React.createElement(Button, { key: o.value, icon: o.icon, onClick: function () { _this.setState({ value: o.value }); _this.props.onSwitch(o.value); }, extraClass: ((_a = _this.props.extraClass) !== null && _a !== void 0 ? _a : "") + (_this.state.value == o.value ? " highlight" : ""), buttonLabel: o.buttonLabel });
        }));
    };
    return HorizontalButtonsSwitcher;
}(React.Component));
export { HorizontalButtonsSwitcher };
