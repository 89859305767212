import { setDefaultStyle } from "apparatus/draw";
import { Group, Path, Rectangle, Shape } from "paper";
import { Random } from "random/random";
import { P, R, Segments } from "../common";
var DISPERSE = 7;
var SandBath = /** @class */ (function () {
    function SandBath() {
    }
    SandBath.prototype.render = function (appearance) {
        var width = appearance.width;
        var height = appearance.height;
        var container = new Path(Segments([[0, 0]], [[0, height]], [[width, height]], [[width, 0]]));
        setDefaultStyle(container);
        var sand = Shape.Rectangle(R(0, height * (1 - appearance.sandRatio), width, height * appearance.sandRatio));
        sand.strokeColor = "#e0cc4a";
        sand.strokeWidth = 3.0;
        sand.fillColor = "#ebdc7c";
        // Create sand grains.
        var grains = new Group();
        var r = new Random();
        for (var y = height * (1 - appearance.sandRatio) + DISPERSE; y <= height - DISPERSE; y += DISPERSE * 2) {
            for (var x = DISPERSE; x <= width - DISPERSE; x += DISPERSE * 2) {
                var grain = Shape.Circle(P(x, y).add(r.randomUniformVector(DISPERSE * 0.7)), 2.0);
                grains.addChild(grain);
            }
        }
        grains.fillColor = "#e0cc4a";
        var graphic = new Group([sand, grains, container]);
        var rect = new Rectangle(0, 0, width, height);
        var hitShape = Shape.Rectangle(rect);
        return {
            graphic: graphic,
            hitShape: hitShape,
            snapping: graphic.placeable(),
        };
    };
    SandBath.properties = {
        label: "Sand bath",
        defaultAppearance: {
            width: 200,
            height: 80,
            sandRatio: 0.6
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "width", label: "Width",
                min: 120, max: 300
            },
            {
                spec: "slider", key: "height", label: "Height",
                min: 40, max: 150
            },
            {
                spec: "slider", key: "sandRatio", label: "Amount of sand",
                min: 0.0, max: 1.0, step: 0.01
            },
        ],
    };
    return SandBath;
}());
export { SandBath };
